<template>
	<div class="d-flex flex-column flex-sm-row">
		<template v-if="counts">
			<span>
				Internal Projects: {{ counts.internalProjects.total }}
			</span>
			<span class="mx-sm-4">
				Active Projects: {{ counts.activeProjects.total }}
			</span>
			<span>
				Hosting Projects: {{ counts.hostingProjects.total }}
			</span>
		</template>
	</div>
</template>

<script>
import { timeInterval } from '../utils/timeInterval';
	export default {
		name: "projectCounts",

		apollo: {
			counts: {
				query: gql`
					query GetProjectCategoryCounts(
						$projectCountParams: QueryFilter!
						$internalProjectCountParams: QueryFilter!
						$hostingProjectCountParams: QueryFilter!
					) {
						counts: root {
							internalProjects: projects @filter(param: $internalProjectCountParams) {
								total
							}

							activeProjects: projects @filter(param: $projectCountParams) {
								total
							}

							hostingProjects: projects @filter(param: $hostingProjectCountParams) {
								total
							}
						}
					}
				`,
				variables() {
					return {
						internalProjectCountParams: {
							filters: [
								{
									field: "status",
									op: "in",
									value: ["active"],
								},
								{
									field: "category",
									op: "in",
									value: ["Internal"],
								},
							],
						},
						projectCountParams: {
							filters: [
								{
									field: "status",
									op: "in",
									value: ["active"],
								},
							],
						},
						hostingProjectCountParams: {
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "in",
									value: ["active", "hidden"],
								},
								{
									field: "name",
									op: "regexp",
									value: "^Website\\s*\\((?:[a-z]+-)?[1-9][0-9]*\\).*$",
								},
							],
						}
					};
				},
				pollInterval: timeInterval('1 hour'),
			}
		},
	};
</script>

<style scoped>
</style>
