<template>
	<div>

		<!-------------GENERAL------------->
		<!-- Boolean -->
		<template v-if="field.type === 'boolean'">
			<v-checkbox :false-value="false" :true-value="true" v-model="myValue" :readonly="readonly" :disabled="disabled" :label="field.name" hide-details class="my-3"></v-checkbox>
		</template>

		<!-- Date -->
		<template v-if="field.type === 'date'">
			<v-menu v-model="calendarMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y ref="calendarMenu" min-width="200px">
				<template v-slot:activator="{ on, attrs }">
					<v-text-field v-model="myValue" type="date" :label="field.name" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details outlined dense class="my-3" :disabled="disabled"></v-text-field>
				</template>
				<v-date-picker v-model="myValue" @input="calendarMenu = false" header-color="primary" color="primary" show-adjacent-months no-title :disabled="disabled"></v-date-picker>
			</v-menu>
		</template>

		<!-- Email -->
		<template v-if="field.type === 'email'">
			<v-text-field :label="field.name" v-model="myValue" :readonly="readonly" :disabled="disabled" type="email" dense hide-details outlined class="my-3"></v-text-field>
		</template>

		<!-- Link -->
		<template v-if="field.type === 'link'">
			<v-text-field :label="field.name" v-model="myValue" :readonly="readonly" :disabled="disabled" type="url" dense hide-details outlined class="my-3" placeholder="https://"></v-text-field>
		</template>
		
		<!-- Phone -->
		<template v-if="field.type === 'phone'">
			<v-text-field :label="field.name" v-model="myValue" :readonly="readonly" :disabled="disabled" type="tel" dense hide-details outlined class="my-3" placeholder="555-555-5555"></v-text-field>
		</template>
		
		<!-- Text -->
		<template v-if="field.type === 'text'">
			<v-text-field :label="field.name" v-model="myValue" :readonly="readonly" :disabled="disabled" dense hide-details outlined class="my-3"></v-text-field>
		</template>

		<!-- Textarea-->
		<template v-if="field.type === 'textarea'">
			<v-textarea :label="field.name" v-model="myValue" :disabled="disabled" :readonly="readonly" rows="3" auto-grow outlined dense hide-details class="mt-3"></v-textarea>
		</template>

		<!-------------NUMBER------------->
		<!-- Decimal-->
		<template v-if="field.type === 'decimal'">
			<v-text-field :label="field.name" v-model="myValue" :readonly="readonly" :disabled="disabled" type="number" step="0.01" dense hide-details outlined class="my-3"></v-text-field>
		</template>

		<!-- Integer -->
		<template v-if="field.type === 'integer'">
			<v-text-field :label="field.name" v-model="myValue" :readonly="readonly" :disabled="disabled" type="number" dense hide-details outlined class="my-3"></v-text-field>
		</template>

		<!-------------MULTIPLE------------->
		<!-- Checkboxes -->
		<template v-if="field.type === 'checkboxes'">
			<fieldset class="pa-2 my-3">
				<legend class="ml-2">{{field.name}}</legend>
				<v-checkbox v-for="item in options" :key="item.value" v-model="myValue" :label="item.text" :value="item.value" hide-details :disabled="disabled"></v-checkbox>
			</fieldset>
		</template>

		<!-- Radios -->
		<div v-if="field.type === 'radio'" class="d-flex align-center my-3">
			{{field.name}}: 
			<v-radio-group v-model="myValue" row dense :readonly="readonly" :disabled="disabled" class="ml-3 my-0" hide-details>
				<v-radio v-for="item in options" :key="item.value" :label="item.text" :value="item.value"></v-radio>
			</v-radio-group>
		</div>

		<!-- Dropdown -->
		<template v-if="field.type === 'dropdown'">
			<v-select :label="field.name" :items="options" v-model="myValue" :disabled="disabled || readonly" class="my-3" hide-details outlined dense>
			</v-select>
		</template>

		<!-------------REFERENCE------------->
		<!-- People,Client,Project -->
		<template v-if="'person client project'.indexOf(field.type)>=0">
			<v-autocomplete :label="field.name" v-model="myValue" :items="getReferenceOptions(field.type)" :disabled="disabled || readonly" hide-details outlined dense class="my-3"></v-autocomplete>
		</template>

	</div>
</template>
<script>
	export default {
		name: "metaInput",

		props: {
			layout: {
				type: String,
				default: "",
			},
			readonly: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			value: [String, Object, Array, Number, Boolean],
			// unsure how to make this work so will probably never be used
			placeholder: String,
			field: {
				// type of input this will be
				type: Object,
				validator: (item) => {
					if (
						!item.options ||
						typeof item.options.length === "undefined"
					) {
						item.options = [];
					}
					return true;
				},
			},
		},

		data() {
			return {
				date: new Date().toISOString().substr(0, 10),
				calendarMenu: false,
				internalValue: ''
			};
		},

		computed: {
			myValue: {
				get() {
					return this.internalValue;
				},
				set(v) {
					this.internalValue = this.getValue(v);
				}
			},
			// seemingly, this never worked in the preview
			/* persons() {
				let users = this.$db
					.findModels("person", (person) => {
						return parseInt(person.inactive, 10) !== 1;
					})
					.map((person) => {
						let projectNames = null,
							clientNames = null;
						let text = this.$options.filters.FullName(person);
						if (person.project_links) {
							projectNames = person.project_links.map(
								(projectId) =>
									this.$db.getModel("project", projectId).name
							);
							if (projectNames.length) {
								text += " (" + projectNames.join(") (") + ")";
							}
						}
						if (person.client_links) {
							clientNames = person.client_links.map(
								(clientId) =>
									this.$db.getModel("client", clientId).name
							);
							if (clientNames.length) {
								text += ` (${clientNames.join(") (")})`;
							}
						}
						if (person.employee) {
							text += ` (Digital Attic Employee - ${person.department})`;
						}
						return {
							value: person.id,
							text: text,
						};
					});

				users.sort((a, b) => {
					return a.text.localeCompare(b.text);
				});

				return users;
			}, */
			/* clients() {
				return this.$db
					.getModels("client")
					.map((item) => {
						return {
							value: item.id,
							text: item.name,
						};
					})
					.sort((a, b) => a.text.localeCompare(b.text));
			}, */
			/* projects() {
				return this.$db
					.getModels("project")
					.map((item) => {
						return {
							value: item.id,
							text: item.name,
						};
					})
					.sort((a, b) => a.text.localeCompare(b.text));
			}, */
			options() {
				let result = (this.field.options || [])
					.map(v => ({ text: v.replace(/_/g, ' '), value: v }));

				if (this.field.type === 'dropdown') {
					result.unshift({ text: 'Select One...', value: '' });
				}
				
				return result;
			}
		},

		methods: {
			getValue(value) {
				switch (this.field.type) {
					case 'date':
						value = value && value.split(" ")[0];
						break;
					case 'boolean':
						if (value == 'yes' || value == 'no') {
							value = value == 'yes';
						}

						value = !!value;
						break;
					case 'checkboxes':
						value = Array.isArray(value)
							? value
							: [value];

						value = value.filter(i => i != null);
						break;
					case 'decimal':
						value = parseFloat(value);
						if (isNaN(value)) {
							value = null;
						}
						break;
				}

				return value;
			},
			getReferenceOptions(type) {
				return this[`${type}s`];
			},
		},
		watch: {
			value: {
				immediate: true,
				handler(to) {
					this.internalValue = this.getValue(to);
				}
			},
			internalValue(to) {
				if (JSON.stringify(to) != JSON.stringify(this.getValue(this.value))) {
					this.$emit("input", to);
				}
			}
		},
	};
</script>