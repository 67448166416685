<!--
This template shows on projectDetails
-->
<template>
	<v-card>
		<v-card-title>Tasks - {{taskCounts.total}}</v-card-title>
		<v-card-text>
			<div class="status text-uppercase mb-2 d-flex align-center caption flex-wrap">
				<template v-for="status in $options.keys">
					<div v-if="status != 'total'" :key="status" class="mr-4 my-1 d-flex align-center text-no-wrap">
						<v-sheet width="10" height="10" class="status mr-2" :color="status | lowercase"></v-sheet>
						{{status}}
					</div>
				</template>
			</div>
			<div class="d-flex">
				<template v-for="status in $options.keys">
					<v-sheet v-if="status != 'total'" dark :key="status" :color="status | lowercase" class="progress-bar text-center" role="progressbar"
							 :width="(taskCounts[status] / taskCounts.total * 100) + '%'" height="15" :aria-valuenow="taskCounts[status]" aria-valuemin="0" aria-valuemax="100">
						<span v-if="taskCounts[status] != 0">{{taskCounts[status]}}</span>
					</v-sheet>
				</template>
			</div>
		</v-card-text>
	</v-card>
</template>


<script>
	export default {
		keys: [
			'unassigned',
			'assigned',
			'completed'
		],
		props: {
			// a detailed array of tasks for which a summary will be displayed
			taskCounts: {
				type: Object
			}
		},
		data() {
			return {};
		},

	};
</script>


<style lang="scss" scoped>
	.status {
		> div {
			line-height: 1;
		}
	}
	.progress-bar {
		float: left;
		width: 0;
		font-size: 14px;
		line-height: 1;
		background-color: map-get($grey, "darken-1");
		transition: width 0.6s ease;
	}
</style>