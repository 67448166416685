<template>
	<v-card :loading="$apollo.queries.contacts.loading">
		<v-card-title>
			<div v-html="caption"></div>
			<v-btn small title="Add Contact" min-width="0" @click="addContact" class="ml-auto mr-2">
				<div class="fa-stack">
					<v-icon small>fad fa-address-card</v-icon>
					<v-icon small color="primary">fal fa-plus</v-icon>
				</div>
			</v-btn>
			<v-btn small @click="$apollo.queries.contacts.refetch()"><v-icon small>fad fa-sync</v-icon></v-btn>
		</v-card-title>
		<v-card-text>
			<v-data-table v-if="contacts && contacts.person_links" :headers="headers" :items="contacts.person_links" dense disable-pagination disable-sort hide-default-footer>

				<template v-slot:item.name="{ item }">
					<div>{{item.person.first_name}} {{item.person.last_name}}</div>
				</template>

				<template v-slot:item.phone_primary="{ item }">
					<div v-if="item.person.phone_primary" class="text-no-wrap phone-number-wrap">
						Cell: <a v-if="item.person.phone_primary" :href="item.person.phone_primary | getPhoneLink" class="text-no-wrap">{{ item.person.phone_primary }}</a>
					</div>
					<div v-if="item.person.phone_secondary" class="text-no-wrap phone-number-wrap">
						Phone: <a :href="item.person.phone_secondary | getPhoneLink" class="text-no-wrap">{{ item.person.phone_secondary }}</a>
					</div>
					<div v-if="item.person.phone_office" class="text-no-wrap phone-number-wrap">
						Office: <a :href="item.person.phone_office | getPhoneLink" class="text-no-wrap">{{ item.person.phone_office }}</a>
					</div>
					<div v-if="item.person.phone_fax" class="text-no-wrap phone-number-wrap">
						Fax: <a :href="item.person.phone_fax | getPhoneLink" class="text-no-wrap">{{ item.person.phone_fax }}</a>
					</div>
				</template>

				<template v-slot:item.email_primary="{ item }">
					<div v-if="item.person.email_primary" class="text-no-wrap">
						<a :href="`mailto:${item.person.email_primary}`" class="text-no-wrap">{{ item.person.email_primary }}</a>
					</div>
					<div v-if="item.person.email_secondary" class="text-no-wrap">
						<a :href="`mailto:${item.person.email_secondary}`" class="text-no-wrap">{{ item.person.email_secondary }}</a>
					</div>
				</template>

				<template v-slot:item.view_user="{ item }">
					<div class="text-right d-flex" :data-id="item.person.id">
						<v-btn v-if="item.person.meta_person_hubspot_link" :href="item.person.meta_person_hubspot_link" target="_blank" small min-width="0" title="View on Hubspot">
							<v-icon small>fab fa-hubspot</v-icon>
						</v-btn>

						<v-btn small min-width="0" class="mx-1" @click="editUser(item.person.id)" title="Edit Contact">
							<v-icon small>fad fa-pencil-alt</v-icon>
						</v-btn>
					</div>
				</template>

			</v-data-table>
			<div v-else>There are no contacts for this client.</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import { EventBus } from "../../utils/EventBus";
	export default {
		name: "ClientContactList",
		props: {
			caption: {
				type: String,
				default: "Contacts",
			},
			id: {
				type: [Number, String],
				required: true
			},
		},
		apollo: {
			contacts: {
				query: gql`
					query getContacts($clientId: ID!) {
						queries
						contacts: client(id: $clientId) {
							id
							person_links {
								id

								person {
									id
									first_name
									last_name
									phone_primary
									phone_secondary
									phone_office
									phone_fax
									email_primary
									email_secondary
									meta_person_hubspot_link
								}
							}
						}
					}
				`,
				variables() {
					return {
						clientId: this.id,
					};
				},
			},
		},
		data() {
			return {
				headers: [
					{
						value: "name",
						text: "Name",
					},
					{
						value: "phone_primary",
						text: "Phone",
					},
					{
						value: "email_primary",
						text: "Email",
					},
					{
						value: "view_user",
						text: "",
					},
				],
			};
		},
		methods: {
			addContact() {
				EventBus.$emit("app/menu/add-contact", {
					clientLinks: [this.id],
					redirect: false
				});
			},
			editUser(id) {
				this.$modalService
					.create("contactAddEdit", {
						personId: id,
					})
					.on("save", (e, person) => {
						this.$snotify.success("Success!", `Created contact.`);
						this.$apollo.queries.contacts.refetch();
					});
			},
		},
	};
</script>

<style scoped lang="scss">
</style>