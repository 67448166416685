import moment from 'moment';
import { dbInst } from "../db/simple-db";
import userData from '../utils/user-data';
import { EventBus } from '../utils/EventBus';
import { getEnumValues } from '../utils/EnumValues';

export default {
    methods: {
		isAllowed(perm) {
			let me = this.$store.getters.userData;

			return me
				? me.permissions.includes(perm)
				: false;
        },

        isToday(time) {
            if (!moment.isMoment(time)) {
                time = moment(time);
            }

            return this.$store.state.app.currentDay.isSame(time, 'day');
        },

        isLate(time) {
            if (!moment.isMoment(time)) {
                time = moment(time);
            }

            return time.isBefore(this.$store.state.app.currentDay, scale);
		},
		
		getEnumValues(enumName) {
			return getEnumValues(enumName);
		},

        // /**
        //  * Restrict minimum time between invocations of method. Good for input filtering.
        //  * @param id an instance identifier
        //  * @param time ms
        //  * @param method function
        //  */
        // throttle(id, time, method) {
        //     if (!this.throttling) {
        //         this.throttling = {};
        //     }

        //     if (this.throttling[id]) {
        //         clearTimeout(this.throttling[id]);
        //     }

        //     this.throttling[id] = setTimeout(() => {
        //         this.throttling[id] = false;

        //         let params = Array.prototype.slice.call(arguments, 3);
        //         method.apply(this, params);
        //     }, time);
        // },

        // clearThrottle(id) {
        //     if (!this.throttling) {
        //         return;
        //     }

        //     if (this.throttling[id]) {
        //         clearTimeout(this.throttling[id]);
        //     }
        // },

        safe() {
            let args = Array.prototype.slice.call(arguments),
                obj  = args.shift();

            for (let i = 0; i < args.length; i++) {
                if (typeof obj[args[i]] === 'object') {
                    obj = obj[args[i]];
                }
                else {
                    return null;
                }
            }

            return obj;
        },

        /**
         * Retrieves client-specific options for the specified task.
         * @param task
         * @returns {*}
         */
        getTaskOptions(task) {
            let options = this.getUserSetting(`task-options:${task.id}`) || {};
            return Object.assign({
                hideUntil:         null,
                timeline_due_date: null,
                pinToCurrent: null
            }, options);
        },

        /**
         * Sets client-specific options for the specified task. These are not currently
         * persistent although perhaps in the future we will make them.
         * In future, store this data along with other user metadata, ex: user.meta.preferences
         * @param task
         * @param options
         * @returns {*}
         */
        setTaskOptions(task, options) {
            let existing = this.getTaskOptions(task);
            let newOptions = Object.assign(existing, options);
            this.setUserSetting(`task-options:${task.id}`, newOptions);
            // force a refresh of the UI, even though nothing has changed
            console.log('saving new options:', newOptions);
            dbInst.addModels('task', task);
        },

        /**
         * Retrieve a browser-local piece of data.
         * @param key
         * @param def
         * @param storage
         * @returns {any}
         */
		getUserSetting(key, def, storage = 'local') {
			return userData.getSetting(key, def, storage);
            /* storage = window[storage + 'Storage'];
            if (!(storage instanceof Storage)) {
                throw new Exception('Invalid storage option provided');
            }

			let val = storage.getItem(key);
			
            if (val === null) {
                val = def;
			}
			else if (val === 'undefined') {
				val = undefined;
			}
            else {
                val = JSON.parse(val);
            }

            return val; */
        },

        setUserSetting(key, value, storage = 'local') {
            /* storage = window[storage + 'Storage'];
            if (!(storage instanceof Storage)) {
                throw new Exception('Invalid storage option provided');
            }

            if (value === null) {
                storage.removeItem(key);
            }
            else {
                storage.setItem(key, JSON.stringify(value));
			} */
			
			userData.setSetting(key, value, storage);
        },

        copiedToClipboard(data) {
            this.$snotify.success(`Copied to clipboard: ${data.text}`);
		},
		
		searchClientProjects(item, searchTxt, itemText) {
			if (!item) {
				// No clue where veutify is getting these undefined rows from
				return false;
			}

			if (item.header) {
				if (item.header.toLocaleLowerCase().indexOf(searchTxt.toLocaleLowerCase()) > -1) {
					return true;
				}

				if (item.projects.some(p => p.name.toLocaleLowerCase().indexOf(searchTxt.toLocaleLowerCase()) > -1)) {
					return true;
				}

				return false;
			}
			else if (item.divider) {
				return this.searchClientProjects(item._nextEl, searchTxt);
			}
			else {
				if (item._clientName.toLocaleLowerCase().indexOf(searchTxt.toLocaleLowerCase()) > -1) {
					return true;
				}
				else {
					return item.name.toLocaleLowerCase().indexOf(searchTxt.toLocaleLowerCase()) > -1;
				}
			}
		}

    },
    computed:{
	},
	
	created() {
		if (this.$options.apollo) {
			this.__apolloRefetchers = [];

			for (let i in this.$options.apollo) {
				if (this.$options.apollo[i].refetchEvents) {
					let events = this.$options.apollo[i].refetchEvents;
					let handler = this.$options.apollo[i].refetchHandler || (() => {
						this.$apollo.queries[i].refetch();
					});

					if (!Array.isArray(events)) {
						events = [events];
					}

					events.forEach(evtName => {
						let unsub = EventBus.$on(evtName, handler);
						this.__apolloRefetchers.push(unsub);
					});
				}
			}
		}
	},

	beforeDestroy() {
		if (this.__apolloRefetchers) {
			for (let i = 0; i < this.__apolloRefetchers.length; i++) {
				if (typeof this.__apolloRefetchers[i] != 'function') {
					console.log(this.__apolloRefetchers);
				}
				this.__apolloRefetchers[i]();
			}

			this.__apolloRefetchers = null;
		}
	}
};
