<script>
	import { objectWithoutKeys } from '../../helpers';
	import VAutocomplete from 'vuetify/lib/components/VAutocomplete';

	export default {
		model: {
			prop: 'value',
			event: 'input'
		},

		props: {
			...objectWithoutKeys(VAutocomplete.options.props, ['items', 'item-text', 'item-value', 'item-disabled'])
		},

		apollo: {
			clients: {
				query: gql`
					query GetClients($clientSort: [SortField]!) {
						clients
							@sort(param: $clientSort)
						{
							nodes {
								node {
									id
									name
									meta_status
								}
							}
						}
					}
				`,
				variables() {
					return {
						clientSort: [{
							field: 'name',
							order: 'asc'
						}]
					}
				}
			}
		},

		computed: {
			items() {
				if (!this.clients) {
					return [];
				}

				return this.clients.nodes
					.map(row => row.node);
			}
		},

		render(h) {
			return h(
				VAutocomplete,
				{
					props: {
						...this.$props,
						label: 'Client',
						items: this.items,
						itemText: 'name',
						itemValue: 'id',
						loading: this.loading || this.$apollo.queries.clients.loading
					},
					scopedSlots: this.$scopedSlots,
					on: this.$listeners
				}
			);
		}
	}
</script>