<template>
	<!-- 
TODO
pinned notes, maybe
note color
-->
	<v-container fluid>
		<v-toolbar dense class="mb-3">
			<h3>Dashboard (Beta)</h3>
		</v-toolbar>

		<v-row dense>

			<!-- Col: Personal Notes -->
			<v-col cols="12" lg="6" class="fill-height">
				<v-toolbar class="sticky-toolbar mb-1" dense>
					<h3>Personal Notes</h3>
					<debounce-events v-if="personalNotes" :events="{ input: 500 }" v-slot="{ input }">
						<v-text-field :value="searchTxt" @input="input(() => searchTxt = $event)" clearable hide-details
									  label="Search Notes"
									  class="mx-5"></v-text-field>
					</debounce-events>
					<add-note-button obj-type="person" :obj-id="me.id" @save="$apollo.queries.notes.refetch()" class="ml-auto" tinyColor="primary"></add-note-button>
				</v-toolbar>

				<v-row v-if="personalNotes" v-masonry="'containerId'" transition-duration="0.3s" item-selector=".item" class="ma-0" dense>
					<v-col v-for="(note, i) in personalNotes" :key="i" cols="12" sm="6" class="child item" v-masonry-tile>
						<v-card>
							<v-card-title>
								<div class="grey--text text--lighten-1 caption mr-2" title="Note Created">
									{{note.modified_date | shortDate }} {{note.modified_date | shortTime}}
								</div>
								<v-btn @click="editNote(note)" class="ml-auto mr-2" x-small min-width="0">
									<v-icon x-small>fad fa-pencil</v-icon>
								</v-btn>
								<v-btn @click="deleteNote(note)" x-small min-width="0">
									<v-icon x-small>fad fa-trash-alt</v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<div v-html="content(note.content)" class="note-content"></div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-sheet v-if="personalNotes && !personalNotes.length" class="d-flex pa-5">
					You have no notes yet.
					<div class="ml-auto">Add one <v-icon right left>fal fa-arrow-up</v-icon>
					</div>
				</v-sheet>
			</v-col>

			<!-- late tasks for projects I manage -->
			<v-col cols="12" sm="6" lg="3">
				<template>
					<v-toolbar dense class="mb-1">
						<h3>My Projects: Late Tasks<span v-if="lateTasks" class="primary--text ml-1">{{lateTasks.total}}</span></h3>
					</v-toolbar>
					<div class="late-tasks" v-if="lateTasks && lateTasks.nodes.length">
						<v-card v-for="(t, k) in lateTasks.nodes" :key="k" class="my-1">
							<v-card-title class="body-2 flex-nowrap pt-2">
								<div class="text-truncate">{{t.node.name}}</div>
								<v-chip small label class="ml-auto">Due {{t.node.timeline_due_date | fromNow}}</v-chip>
							</v-card-title>
							<v-card-subtitle>
								<router-link class="text--disabled" :to="{ name: 'client.details', params: { id: t.node.project.client.id } }">{{ t.node.project.client.name }}</router-link>:
								<router-link class="text--disabled ml-1" :to="{name: 'project.details', params: {id: t.node.project.id}}">{{ t.node.project.name }}</router-link>
							</v-card-subtitle>
							<v-card-text>
								<div v-if="t.node.assigned_to">Assigned to: <span class="primary--text">{{t.node.assigned_to.full_name}}</span></div>
								<v-chip v-else label small color="error">Unassigned</v-chip>
							</v-card-text>
						</v-card>
					</div>
					<v-sheet v-if="lateTasks && !lateTasks.nodes.length" class="pa-5">All is well!</v-sheet>
				</template>
			</v-col>

			<!-- my own late tasks -->
			<v-col cols="12" sm="6" lg="3">
				<v-toolbar dense class="mb-1">
					<h3>My Late Tasks <span v-if="myLateTasks" class="primary--text ml-1">{{myLateTasks.total}}</span></h3>
				</v-toolbar>
				<div class="late-tasks" v-if="myLateTasks && myLateTasks.nodes.length">
					<v-card v-for="t in myLateTasks.nodes" :key="t.id" class="my-1">
						<v-card-title class="body-2 pt-2">
							<div class="text-truncate">{{t.node.name}}</div><br>
						</v-card-title>
						<v-card-subtitle>
							<router-link class="text--disabled" :to="{ name: 'client.details', params: { id: t.node.project.client.id } }">{{ t.node.project.client.name }}</router-link>:
							<router-link class="text--disabled ml-1" :to="{name: 'project.details', params: {id: t.node.project.id}}">{{ t.node.project.name }}</router-link>
							<div class="primary--text text-right">Due {{t.node.timeline_due_date | fromNow}}</div>
						</v-card-subtitle>
					</v-card>
				</div>
				<v-sheet v-if="myLateTasks && !myLateTasks.nodes.length" class="pa-5">Congrats! You have no late tasks</v-sheet>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>
	import addNoteButton from "@/components/addNoteButton";
	import noteListItem from "@/components/noteListItem";
	import { markdownRenderer } from "@/components/tiptap/markdown-it";
	import NoteModel from "@/models/note";
	import moment from "moment";

	export default {
		name: "dashboard",
		components: {
			addNoteButton,
			noteListItem,
		},
		data() {
			return {
				searchTxt: "",
				noteTypes: [],
				itemsPerPage: 30,
				page: 1,
			};
		},
		apollo: {
			notes: {
				query: gql`
					query getNotes($filterParam: QueryFilter!) {
						notes
							@filter(param: $filterParam)
							@sort(param: [{ field: "date", order: desc }]) {
							total
							nodes {
								node {
									id
									content
									modified_date
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "author_id",
							op: "=",
							value: this.me.id,
						},
						{
							field: "object_type",
							op: "=",
							value: "Person",
						},
					];

					if (this.searchTxt) {
						filters.push({
							field: "content",
							op: "like",
							value: `%${this.searchTxt}%`,
						});
					}

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
			myLateTasks: {
				query: gql`
					query myLateTasks($filterParam: QueryFilter!) {
						myLateTasks: tasks
							@filter(param: $filterParam)
							@sort(
								param: [{ field: "timeline_due_date", order: asc }]
							) {
							total
							nodes {
								node {
									id
									name
									timeline_due_date
									project {
										id
										name
										client {
											id
											name
										}
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "assigned_to_user_id",
							op: "=",
							value: this.me.id,
						},
						{
							field: "status",
							op: "=",
							value: "current",
						},
						{
							field: "timeline_due_date",
							op: "<",
							value: this.$store.getters.currentDay.format("YYYY-MM-DD"),
						},
						{
							field: "project.status",
							op: "in",
							value: ["active", "hidden"],
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
			lateTasks: {
				query: gql`
					query lateTasks($filterParam: QueryFilter!) {
						lateTasks: tasks
							@filter(param: $filterParam)
							@sort(
								param: [{ field: "timeline_due_date", order: asc }]
							) {
							total
							nodes {
								node {
									id
									name
									timeline_due_date
									assigned_to {
										id
										full_name
									}
									project {
										id
										name
										client {
											id
											name
										}
										account_executive_user_id
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "project.account_executive_user_id",
							op: "=",
							value: this.me.id,
						},
						{
							field: "status",
							op: "=",
							value: "current",
						},
						{
							field: "timeline_due_date",
							op: "<=",
							value: this.$store.getters.currentDay.format("YYYY-MM-DD"),
						},
						{
							field: "project.category",
							op: "not in",
							value: ["Internal", "Audit", "Hosting"],
						},
						{
							field: "project.status",
							op: "in",
							value: ["active", "hidden"],
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
		},
		computed: {
			me() {
				// let me = { id: 13 };
				// return me;
				return this.$store.getters.userData;
			},
			personalNotes() {
				if (!this.notes) {
					return [];
				}

				return this.notes.nodes
					.map(row => row.node);
			}
		},
		methods: {
			content(content) {
				return markdownRenderer.render(content);
			},
			deleteNote(note) {
				this.$swal({
					title: `Delete this note?`,
					text: "You cannot undo this!",
					icon: "warning",
					buttons: true,
					dangerMode: true,
				}).then((willDelete) => {
					if (willDelete) {
						NoteModel.delete({ id: note.id }).then((response) => {
							if (response.json.errors) {
								this.$snotify.error(
									Object.values(response.json.errors).join(". "),
									"Error"
								);
								return;
							}
							// this.$db.removeModels("note", note);
							this.$snotify.success(`note deleted.`, "Success");
							this.$emit("update");

							this.$apollo.queries.notes.refetch();
							// this.refetchRedraw();
						});
					}
				});
			},
			editNote(note) {
				this.$modalService
					.create("personalNoteAddEdit", {
						note: note,
						showType: false,
						objType: "person",
					})
					.on("save", (e, note) => {
						// this.$db.addModels("note", note);
						this.$apollo.queries.notes.refetch();
						// this.refetchRedraw();
					});
			},
			refetchRedraw() {
				this.$apollo.queries.notes.refetch().then(() => {
					this.$nextTick(() => {
						this.$redrawVueMasonry("containerId");
					});
				});
			},
		},

		watch: {
			notes() {
				this.$nextTick(() => {
					this.$redrawVueMasonry("containerId");
				});
			},
		},
	};
</script>


<style scoped lang="scss">
@media(min-width: 1264px) {
	.my-projects, .late-tasks {
		max-height: calc(100vh - 254px);
		overflow-y: auto;
	}
}
</style>