<template>
	<v-row v-if="project" dense>
		<v-col cols="12" md="6">

			<v-card class="mb-2">
				<v-card-title>
					Project Information
				</v-card-title>
				<v-card-text>
					<v-row class="row">
						<v-col cols="12" sm="6" class="mb-2">
							<div>
								<strong>Client:</strong>
								<router-link :to="project.client.app_url_raw" class="ml-1">{{ project.client.name }}</router-link>
							</div>
							<div><strong>Name:</strong> {{ project.name }}</div>
							<div><strong>Job:</strong> {{ project.job }}</div>
							<div><strong>Type:</strong> {{ project.type }}</div>
							<div><strong>Category:</strong> {{ project.category }}</div>
							<div><strong>Status:</strong> {{ project.status }}</div>

							<div v-if="taskGroupsEtc && taskGroupsEtc.task_groups" class="my-2">
								<strong>Estimated Completion Dates: </strong>
								<div v-for="group in taskGroupsEtc.task_groups" :key="group.id">
									<strong><template v-if="group.is_default">*</template>{{group.name}} - </strong>
									{{group.estimated_completion_date | shortDate}}
								</div>
							</div>

						</v-col>
						<v-col cols="12" sm="6">
							<div>
								<strong title="Project Manager">Project Manager: </strong>
								<object-link :uid="project.account_executive_user && project.account_executive_user.uid">{{ accountExecutiveName }}</object-link>
							</div>
							<div><strong>Department:</strong> {{ project.department || "Not Set" }}</div>
							<div><strong>Progress:</strong> {{ project.progress || "Not set" }}</div>
							
							<div v-if="projectExtra">
								<strong title="Current Task Assigned To:">Current:</strong>
								<div v-if="projectExtra.current_tasks.length">
									<template v-for="(team, ndx) in projectExtra.current_tasks">
										<v-chip v-if="team.assigned_to" :key="ndx" dark x-small label :color="team.assigned_to.department" v-html="team.assigned_to.full_name" class="ma-1"></v-chip>
									</template>
								</div>
								<v-chip v-else x-small label class="mx-1">Nobody</v-chip>
							</div>

							<div v-if="teamMembers">
								<strong>Team:</strong>
								<v-chip v-for="(team, ndx) in teamMembers.nodes" :key="ndx" dark x-small label :color="team.node.assigned_to.department" v-html="team.node.assigned_to.full_name" class="ma-1"></v-chip>
							</div>
							
						</v-col>
					</v-row>

					<v-divider class="mb-2"></v-divider>
					<!-- additional meta data -->
					<meta-list v-if="projectExtra" :model="projectExtra"></meta-list>

				</v-card-text>
			</v-card>

			<v-row>
				<v-col cols="12">
					<check-list-tree v-if="projectExtra" :items="projectExtra.meta_project_checklist" @save="saveProjectChecklist"></check-list-tree>
					<note-list v-if="deadlinesAndReminders" caption="Deadlines & Reminders" :notes="deadlinesAndReminders.nodes" @save="$apollo.queries.deadlinesAndReminders.refetch()"></note-list>
				</v-col>
			</v-row>

		</v-col>

		<v-col cols="12" md="6">

			<v-row dense>
				<!-- Tasks -->
				<v-col cols="12" sm="6" class="">
					<tasks-summary v-if="projectExtra" :taskCounts="projectExtra.task_counts" class="fill-height"></tasks-summary>
				</v-col>

				<!-- Hours -->
				<v-col cols="12" sm="6" class="">
					<hours class="fill-height"></hours>
				</v-col>
			</v-row>

			<v-row dense>
				<v-col cols="12" md="6">
					<v-card class="fill-height">
						<v-card-title>
							Project Description
						</v-card-title>
						<v-card-text class="text-pre-line" v-html="project.description || 'No description set'"></v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" md="6">
					<v-card class="fill-height">
						<v-card-title>
							Status <add-note-button
								class="ml-auto"
								objType="project"
								:objId="project.id"
								noteType="status"
								:showType="false"
								db="project-details"
								@save="$emitBus('project:new_status')"
							></add-note-button>
						</v-card-title>
						<v-card-text v-html="lastStatusHTML || 'There is no status message yet'"></v-card-text>
					</v-card>
					<div class="item-extra-item card item-extra-item-status h-100">
					</div>
				</v-col>
			</v-row>

			<contact-list caption="Project Contacts" :uid="project.uid" class="my-2"></contact-list>

			<v-card class="mb-2">
				<v-card-title>Today's Timers</v-card-title>
				<v-card-text v-if="todaysTimers && todaysTimers.nodes.length">
					<div v-for="timer in todaysTimers.nodes" :key="timer.node.id" class="d-flex justify-content-between">
						<div>
							<strong class="mr-2">{{ timer.node.user.full_name }}</strong>
						</div>
						<div class="ml-1">
							<timer-clock :timer="timer.node"></timer-clock>
							<v-icon small class="ml-1 mt-n1" :color="timer.node.status == 'Active' ? 'green' : 'dividers'">fad fa-circle-dot</v-icon>
						</div>
					</div>
				</v-card-text>
				<v-card-text v-else>
					No timers for today
				</v-card-text>
			</v-card>

			<chart-project-milestone :pid="project.id" class="mb-2"></chart-project-milestone>

		</v-col>
	</v-row>
	<v-row v-else>
		no data
	</v-row>
</template>

<script>
	import assignedUsers from "@/components/assignedUsers";
	import hours from "../components/hours";
	import tasksSummary from "../components/tasksSummary";
	import addNoteButton from "@/components/addNoteButton";
	import NoteList from "@/components/noteList";
	import Insight from "@/utils/Insight";
	import metaList from "@/components/metaList";
	import TimerClock from "@/components/timerClock";
	import CheckListTree from "@/components/checkListTree/checkListTree";
	import ContactList from "@/components/contactList";
	import chartProjectMilestone from "../components/chartProjectMilestone.vue";
	import moment from "moment";
	import ObjectLink from "../../../components/objectLink.vue";
	import { timeInterval } from "../../../utils/timeInterval";

	export default {
		name: "projectDetailsLegacyView",

		components: {
			assignedUsers,
			hours,
			tasksSummary,
			addNoteButton,
			NoteList,
			metaList,
			TimerClock,
			CheckListTree,
			ContactList,
			chartProjectMilestone,
			ObjectLink,
		},

		data() {
			return {
				team: [],
			};
		},

		inject: ["projectContext"],

		apollo: {
			projectExtra: {
				query: gql`
					query GetProject_LegacyView($id: ID!) {
						projectExtra: project(id: $id) {
							id
							task_counts {
								total
								assigned
								unassigned
								completed
							}
							_meta_types
							_meta_values
							meta_project_checklist
							current_tasks {
								assigned_to {
									full_name
									department @lowercase
								}
							}
							last_status {
								id
								content
							}
						}
					}
				`,
				variables() {
					return {
						id: this.project.id,
					};
				},
				skip() {
					return !this.project;
				},
				refetchEvents: ['project:new_status']
			},
			todaysTimers: {
				query: gql`
					query getTodaysProjectTimers($filterParam: QueryFilter!) {
						todaysTimers: timers @filter(param: $filterParam) {
							nodes {
								node {
									id
									status
									start
									stop
									description
									user {
										id
										full_name
									}
								}
							}
						}
					}
				`,
				pollInterval: timeInterval("2 minutes"),
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "creation_date",
									op: ">=",
									value: this.$store.getters.currentDay.format(
										"YYYY-MM-DD"
									),
								},
								{
									field: "project_id",
									op: "=",
									value: this.project.id,
								},
							],
						},
					};
				},
				skip() {
					return !this.project;
				},
			},
			deadlinesAndReminders: {
				query: gql`
					query getDeadlinesAndReminders($filterParam: QueryFilter!) {
						deadlinesAndReminders: notes
							@filter(param: $filterParam)
							@sort(param: [{ field: "date", order: desc }]) {
							total
							nodes {
								node {
									id
									content
									modified_date
									date
									note_type
									hidden
									project {
										id
										name
										account_executive_user_id
										account_executive_user {
											id
											full_name
										}
										client {
											id
											name
										}
									}
									author {
										id
										full_name
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "project.id",
							op: "=",
							value: this.project.id,
						},
						{
							field: "note_type",
							op: "in",
							value: ["deadline", "reminder"],
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				skip() {
					return !this.project;
				},
			},
			teamMembers: {
				query: gql`
					query getTeamMembers($filterParam: QueryFilter!) {
						teamMembers: tasks
							@filter(param: $filterParam)
							@sort(param: [{  field: "assigned_to.department" order: asc}, { field: "assigned_to.first_name", order: asc }])
							@groupBy(fields: ["assigned_to.id"]) {
							nodes {
								node {
									assigned_to {
										full_name
										department @lowercase
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "project_id",
									op: "=",
									value: this.project.id,
								},
								{
									field: 'assigned_to.id',
									op: '!=',
									value: null
								}
							],
						},
					};
				},
				skip() {
					return !this.project;
				},
			},
			taskGroupsEtc: {
				query: gql`
					query taskTreeTaskGroups($projectId: ID! $showArchived: Boolean) {
						taskGroupsEtc: project (id: $projectId) {
							id
							task_groups(show_archived: $showArchived) {
								id
								name
								type
								is_default
								estimated_completion_date
							}
						}
					}
				`,
				variables() {
					return {
						projectId: this.project.id,
						showArchived: false
					};
				}
			}
		},

		computed: {
			project() {
				return this.projectContext.project;
			},

			accountExecutiveName() {
				if (this.project.account_executive_user) {
					return this.project.account_executive_user.full_name;
				}

				return "[NO AE]";
			},

			globalProject() {
				let p = this.$db.getModel("project", this.project.id);
				return p;
			},
			// client() {
			// 	return this.$db.getModel("client", this.project.client_id);
			// },

			teamMemberNames() {
				let assignedTasks = this.$db.findModels("task", (task) => {
					return (
						task.project_id == this.project.id &&
						task.assigned_to_user_id > 0
					);
				});

				return Object.values(
					assignedTasks.reduce((names, task) => {
						let userId = task.assigned_to_user_id;
						if (typeof names[userId] === "undefined") {
							let person = this.$db.getModel("person", userId);
							names[userId] = {
								name: this.$options.filters.FullName(person),
								department: person.department.toLowerCase(),
							};
						}
						return names;
					}, {})
				);
			},
			currentlyAssignedTo() {
				if (!this.currentTask) {
					return null;
				}
				return this.$db.getModel(
					"person",
					this.currentTask.assigned_to_user_id
				);
			},
			currentTask() {
				return this.$db
					.findModels(
						"task",
						(task) =>
							task.project_id === this.project.id &&
							task.status === "current"
					)
					.pop();
				// return currentTasks[0];
			},

			lastStatusHTML() {
				if (this.projectExtra && this.projectExtra.last_status) {
					return this.projectExtra.last_status.content;
				}
				return null;
			},
		},

		updated() {},
		methods: {
			/**
			 * Recompute total height of notes component
			 * note: disabled the window resize watcher because the projectDetails.vue handles this now
			 * @param e
			 */
			/* resize(e) {
															let height = $(window).height();
															height -= $(this.$refs.itemTaskSummary).outerHeight();
															height -= $("header").outerHeight();
															height -= $(".item-details > .card-header").outerHeight();
															height -= 60; // margin-top of elements
															height = Math.max(500, height || 0);
															this.notesComponentHeight = height;
														}, */
			// updateProject() {
			// 	this.$modalService
			// 		.create("projectAddEdit", {
			// 			project: this.globalProject,
			// 		})
			// 		.on("save", (e, project) => {
			// 			this.$emit("updateProject", project);
			// 		});
			// },

			saveProjectChecklist(items) {
				let api = `/api/project/${this.project.id}`,
					data = {
						meta_project_checklist: items
					};
				this.$xhrRequest.send("put", api, data).then(
					(project) => {
						this.$apollo.queries.projectExtra.refetch();
					},
					(xhr) => {
						// todo handle errors
					}
				);
			},

			duplicateProject() {
				console.info("duplicating project");
				this.$modalService
					.create("projectAddEdit", {
						duplicateProject: this.globalProject,
					})
					.on("save", (e, project) => {
						this.$db.addModels("project", project);
						this.$snotify.success("Success!", "Duplicate Project");
					});
			},
		},
	};
</script>
