import Vue from 'vue';
import graphqlFetch from '../graphql/fetch';

export function getEnumValues(enumName) {
	if (getEnumValues.cache[enumName]) {
		return getEnumValues.cache[enumName];
	}

	graphqlFetch(
		`#graphql
		query GetEnumValues_${enumName} ($name: String!) {
			enumValues(name: $name) {
				text
				value
			}
		}
		`,
		{ name: enumName }
	)
		.then(resp => {
			if (resp.errors) {
				console.error('Unknown enum:', enumName);
				return;
			}

			Vue.set(getEnumValues.cache, enumName, resp.data.enumValues);
		});
	
	return [];
}

Vue.util.defineReactive(getEnumValues, 'cache', {});