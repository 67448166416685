<template>
	<v-container fluid>
		<v-toolbar flat dense class="mb-4">
			<v-toolbar-title>Communication Analytics</v-toolbar-title>
		</v-toolbar>

		<!-- My projects -->
		<template v-if="myTotalProjects && myTotalProjects.total > 0">
			<v-card class="mb-4" :loading="$apollo.queries.myProjects.loading">
				<v-card-title>My Projects <v-chip class="ml-2">{{myTotalProjects.total}}</v-chip></v-card-title>
				<v-card-subtitle>For on-going projects that don't need frequent communication, consider setting the project status to hidden.</v-card-subtitle>
				<template v-if="!$apollo.queries.myProjects.loading">
					<!-- if myprojects no comm > 7, congrats -->
					<v-card-text v-if="myProjects && !myProjects.nodes.length">
						Congrats, you are all caught up!
					</v-card-text>
					<!-- else, talk to your peeps -->
					<v-data-table v-if="myProjects && myProjects.nodes.length" :headers="headers" :items="myProjects.nodes" dense sort-by="node.last_communication" :sort-desc="true" disable-pagination hide-default-footer>
						<template v-slot:item.node.last_communication="{ item }">
							{{item.node.last_communication || "never"}}
						</template>

						<template v-slot:item.node.account_executive_user.full_name="{ item }">
							<div :title="item.node.account_executive_user.full_name">
								<user-avataaar class="avatar mr-2" :uid="item.node.account_executive_user.uid" style="max-width:35px;"></user-avataaar>
							</div>
						</template>

						<template v-slot:item.node.id="{ item }">
							<router-link :to="{name:'project.details', params:{id: item.node.id}}" target="_blank" v-html="item.node.name"></router-link>
						</template>

						<template v-slot:item.node.client.name="{ item }">
							<router-link :to="{name:'client.details', params:{id: item.node.client.id}}" target="_blank" v-html="item.node.client.name"></router-link>
						</template>
					</v-data-table>
				</template>
			</v-card>
		</template>

		<!-- Not my projects -->
		<v-card class="mb-4" :loading="$apollo.queries.otherProjects.loading">
			<v-card-title>
				<span v-if="myTotalProjects && myTotalProjects.total > 0">Other</span> Projects <v-chip v-if="otherProjects && otherProjects.nodes.length" class="ml-2">{{otherProjects.nodes.length}}</v-chip>
			</v-card-title>
			<template v-if="!$apollo.queries.otherProjects.loading">
				<v-card-text v-if="otherProjects && !otherProjects.nodes.length">
					All is well.
				</v-card-text>
				<v-data-table v-if="otherProjects && otherProjects.nodes.length" :headers="headers" :items="otherProjects.nodes" dense sortBy="node.last_communication" :sortDesc="true" disable-pagination hide-default-footer>
					<template v-slot:item.node.last_communication="{ item }">
						{{item.node.last_communication || "never"}}
					</template>

					<template v-slot:item.node.account_executive_user.full_name="{ item }">
							<div :title="item.node.account_executive_user.full_name">
								<user-avataaar class="avatar mr-2" :uid="item.node.account_executive_user.uid" style="max-width:35px;"></user-avataaar>
							</div>
						</template>

					<template v-slot:item.node.name="{ item }">
						<router-link :to="{name:'project.details', params:{id: item.node.id}}" target="_blank" v-html="item.node.name"></router-link>
					</template>

					<template v-slot:item.node.client.name="{ item }">
						<router-link :to="{name:'client.details', params:{id: item.node.client.id}}" target="_blank" v-html="item.node.client.name"></router-link>
					</template>
				</v-data-table>
			</template>
		</v-card>

	</v-container>
</template>

<script>
	
	import promiseResolver from "../../components/renderless/promiseResolver";
	import UserAvataaar from "@/components/avataaars/user-avataaar";

	export default {
		name: "analytics-comms",
		components: { promiseResolver, UserAvataaar },
		data() {
			return {
				me: this.$store.getters.userData.id,
				headers: [
					{
						text: "Days ago",
						value: "node.last_communication",
						width: "100px",
					},
					{
						text: "Person",
						value: "node.account_executive_user.full_name",
						width: "80px",
					},
					{
						text: "Client",
						value: "node.client.name",
						width: "45%",
					},
					{
						text: "Project",
						value: "node.name",
					},
				],
			};
		},

		apollo: {
			myTotalProjects: {
				query: gql`
					query MyProjectsCount($filterParam: QueryFilter!) {
						myTotalProjects: projects @filter(param: $filterParam) {
							total
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "account_executive_user_id",
							op: "=",
							value: this.me,
						},
						{
							field: "status",
							op: "=",
							value: "active",
						},
						{
							field: "client.id",
							op: "!=",
							value: 595,
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
			myProjects: {
				query: gql`
					query MyProjects(
						$filterParam: QueryFilter!
						$lastCommFilter: QueryFilter!
					) {
						myProjects: projects @filter(param: $filterParam) {
							nodes @filter(param: $lastCommFilter) {
								node {
									id
									name
									last_communication
									account_executive_user {
										uid
										id
										full_name
										meta_avatar
										department
									}
									client {
										id
										name
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "account_executive_user_id",
							op: "=",
							value: this.me,
						},
						{
							field: "status",
							op: "=",
							value: "active",
						},
						{
							field: "client.id",
							op: "!=",
							value: 595,
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
						lastCommFilter: {
							joinOp: 'or',
							filters: [
								{
									field: "last_communication",
									op: ">",
									value: 7,
								},
								{
									field: "last_communication",
									op: "=",
									value: null,
								}
							]
						},
					};
				},
			},
			otherProjects: {
				query: gql`
					query otherProjects(
						$filterParam: QueryFilter!
						$lastCommFilter: QueryFilter!
					) {
						otherProjects: projects @filter(param: $filterParam) {
							nodes @filter(param: $lastCommFilter) {
								node {
									id
									name
									last_communication
									account_executive_user {
										uid
										id
										full_name
										meta_avatar
										department
									}
									client {
										id
										name
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "account_executive_user_id",
							op: "!=",
							value: this.me,
						},
						{
							field: "status",
							op: "=",
							value: "active",
						},
						{
							field: "client.id",
							op: "!=",
							value: 595,
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
						lastCommFilter: {
							joinOp: 'or',
							filters: [
								{
									field: "last_communication",
									op: ">",
									value: 7,
								},
								{
									field: "last_communication",
									op: "=",
									value: null,
								}
							]
						},
					};
				},
			},
		},
	};
</script>
