<script>
	import settings from '@/utils/settings';
	import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
	import { ActiveEmployees } from "@/graphql/queries";

	function objectWithoutKeys(obj, keys) {
		let result = {};
		for (let i in obj) {
			if (keys.indexOf(i) < 0) {
				result[i] = obj[i];
			}
		}

		return result;
	}
	
	export default {
		model: {
			prop: 'value',
			event: 'input'
		},

		props: {
			...objectWithoutKeys(VAutocomplete.options.props, ['items', 'item-text', 'item-value', 'item-disabled'])
		},

		apollo: {
			getActiveEmployees: {
				query: ActiveEmployees
			}
		},

		computed: {
			items() {
				if (!this.getActiveEmployees) {
					return [];
				}

				let departments = Linq.from(settings.noteRecipients)
				.select(i => ({
					full_name: i.text,
					id: i.value,
					initials: i.initials
				}))
				.orderBy(i => i.full_name)
				.toArray();

				return [
					{header: "Departments"},
					...departments,
					{header: "Employees"},
					...this.getActiveEmployees.nodes
					.map(row => row.node)
				];
			}
		},

		watch: {

		},

		render(h) {
			return h(
				VAutocomplete,
				{
					props: {
						...this.$props,
						items: this.items,
						itemText: 'full_name',
						itemValue: 'id',
						loading: this.loading || this.$apollo.queries.getActiveEmployees.loading
					},
					scopedSlots: this.$scopedSlots,
					on: this.$listeners
				}
			);
		}
	}
</script>