<template>
<div>
	<div v-if="outToday.length" class="d-flex">
		Out: {{outToday}}
	</div>
</div>
</template>
<script>
	import { EventBus } from '../utils/EventBus';
	import { timeInterval } from '../utils/timeInterval';

	const Query = gql`
		query GetWhoIsOut($filterParam: QueryFilter!) {
			persons @filter(param: $filterParam) {
				nodes {
					node {
						id
						first_name
					}
				}
			}
		}
	`;

	const QueryVariables = {
		filterParam: {
			joinOp: "and",
			filters: [
				{
					field: "is_employee",
					op: "=",
					value: true,
				},
				{
					field: "meta_out_today",
					op: "=",
					value: true
				},
				{
					field: "inactive",
					op: "=",
					value: false,
				}
			]
		}
	};

	export default {
		name: "whoIsOut",
		apollo: {
			persons: {
				query: Query,
				variables: QueryVariables,
				pollInterval: timeInterval('10 minutes'),
				refetchEvents: 'employee:out_today'
			},
		},
		
		computed: {
			outToday() {
				if(!this.persons) {
					return [];
				}
				return this.persons.nodes.map((person) => {
					return person.node.first_name
				}).join(', ');
			},
		},

	};
</script>
