<template>
	<v-container fluid>

		<v-card :loading="$apollo.queries.clients.loading">
			<v-card-title>
				Clients <v-chip v-if="clients" class="ml-3">{{clients.total}}</v-chip>
				<v-btn-toggle v-model="showStatuses" class="ml-auto" multiple>
					<v-btn small v-for="item in statusOptions" :key="item.value" :value="item.value">{{ item.text }}</v-btn>
				</v-btn-toggle>
			</v-card-title>
			<v-card-text>
				<v-text-field v-model="searchTxt" label="Search" single-line hide-details clearable></v-text-field>
			</v-card-text>
		</v-card>

		<v-card class="my-4">
			<v-card-text>
				<v-row>
					<v-col cols="3">Name</v-col>
					<v-col cols="3">Status</v-col>
					<v-col cols="3">Authorized Contact Form<br>Date Sent</v-col>
					<v-col cols="3">Authorized Contact Form<br>Date Received</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<div v-if="clientNodes">
			<v-lazy v-for="row in clientNodes" :key="row.id" :options="{threshold: 0.2}" :min-height="20">
				<v-card class="mb-4">
					<v-card-text class="position-relative">
						<v-row>
							<v-col cols="12" sm="3" class="pb-0">
								<v-btn small @click="updateClient(row.id)" text title="edit" min-width="0" class="mr-2">
									<v-icon small>fad fa-edit</v-icon>
								</v-btn>
								<router-link :to="{ name: 'client.details', params: { id: row.id } }" class="listing-title client-title">{{row.name}}</router-link>
							</v-col>
							<v-col cols="3" sm="3">{{row.meta_status || "Active"}}</v-col>
							<v-col cols="3" sm="3">
								<template v-if="row.meta_auth_contact_form_sent">{{row.meta_auth_contact_form_sent}}</template>
								<template v-else><v-icon small color="error" title="never">fad fa-exclamation</v-icon></template>
								</v-col>
							<v-col cols="3" sm="3">{{row.meta_auth_contact_form_received}}</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-lazy>
		</div>

	</v-container>
</template>

<script>
	import addNoteButton from "../components/addNoteButton";

	export default {
		name: "clients",

		components: { addNoteButton },

		data() {
			return {
				searchTxt: "",
				showStatuses: ["Active"],
			};
		},

		apollo: {
			clients: {
				query: gql`
					query getClients($filterParam: QueryFilter!) {
						clients @filter(param: $filterParam) {
							total
							# sql
							nodes {
								node {
									id
									name
									meta_status
									meta_auth_contact_form_sent @formatDate(format: "m/d/Y")
									meta_auth_contact_form_received @formatDate(format: "m/d/Y")
								}
							}
						}
					}
				`,
				variables() {
					let subFilters = [
						{
							field: "meta_status",
							op: "in",
							value: this.showStatuses,
						},
					];
					if (this.showStatuses.includes("Active")) {
						subFilters.push({
							field: "meta_status",
							op: "=",
							value: null,
						});
					}
					
					let filters = [
						{
							joinOp: "or",
							filters: subFilters,
						},
					];

					if (this.searchTxt) {
						filters.push({
							field: "name",
							op: "like",
							value: `%${this.searchTxt}%`,
						});
					}

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
		},

		computed: {
			statusOptions() {
				return [
					{ text: "Active", value: "Active" },
					{ text: "Inactive", value: "Inactive" },
				];
			},

			clientNodes() {
				if(!this.clients) {
					return [];
				}

				return this.clients.nodes
					.map(row => row.node)
			}
		},

		methods: {
			updateClient(id) {
				this.$modalService
					.create("clientAddEdit", {
						clientId: id,
					})
					.on("save", (e, client) => {
						// todo remove after caching/mutations are done
						this.$db.addModels("client", client);
						this.$snotify.success("Updated Client", "Success!");
						this.$apollo.queries.clients.refetch();
					});
			},
		},
	};
</script>