var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.$apollo.queries.projects.loading}},[_c('v-card-title',[_vm._v(" Retainers "),(_vm.aes)?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","close-on-content-click":false,"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto mr-3",attrs:{"small":"","color":_vm.ae.length ? 'primary' : 'button'}},'v-btn',attrs,false),on),[_vm._v(" AE "),_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v("fad fa-caret-down")])],1)]}}],null,false,3638751559)},[(_vm.aes)?_c('v-list',_vm._l((_vm.aes),function(item){return _c('v-list-item',{key:item.person.id},[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","value":item.person.id},model:{value:(_vm.ae),callback:function ($$v) {_vm.ae=$$v},expression:"ae"}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(item.person ? item.person.full_name : 'Not Set')+" "),_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":""}},[_vm._v(_vm._s(item.total))])],1)])],1)}),1):_vm._e()],1):_vm._e(),_c('v-btn',{attrs:{"small":"","min-width":"0"},on:{"click":function($event){return _vm.$apollo.queries.projects.refetch()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-sync")])],1)],1),_c('v-card-subtitle',[_c('p',[_vm._v("Shows time ran for this month only.")]),_c('ul',[_c('li',[_vm._v("SH-30 - \"Start-Up\" hosting plan w/ 30 minutes maintenance a month")]),_c('li',[_vm._v("EH-60 - \"Enhanced\" hosting plan w/ 1 hour maintenance a month")]),_c('li',[_vm._v("AH-120 - \"Advanced\" hosting plan w/ 2 hours maintenance a month")])])]),(_vm.projects)?_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects.nodes,"sort-by":"percent","sort-desc":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.node.time_spent",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"w-100 text-center justify-center",attrs:{"label":"","dark":"","color":parseInt(item.node.time_spent) > parseInt(_vm.retainerInfo(item.node.name)[2]) ? 'magenta' : ''}},[_c('span',{attrs:{"title":"Minutes used this month"}},[_vm._v(_vm._s(item.node.time_spent))]),_vm._v(" "),_c('span',{staticClass:"mx-2"},[_vm._v("/")]),_vm._v(" "),_c('span',{attrs:{"title":"Free minutes per month"}},[_vm._v(_vm._s(_vm.retainerInfo(item.node.name)[2]))]),_c('br')])]}},{key:"item.node.account_executive_user.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('user-avataaar',{staticClass:"avatar mr-2",staticStyle:{"max-width":"35px"},attrs:{"uid":item.node.account_executive_user.uid}}),_vm._v(" "+_vm._s(item.node.account_executive_user.full_name)+" ")],1)]}}],null,false,1637298515)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }