import moment from 'moment'
import UserModel from '../../models/user'
import { dbInst } from "../../db/simple-db";
import { XHRRequest } from '../../utils/xhr-request';
import Insight from '../../utils/Insight'

export default {
	state: {
		searchTxt: '',
		users: false,
		// fetchResourceError: false,
		savedScrollPosition: 0,
		supportsNotifications: ("Notification" in window),
		currentTime: moment(),
		currentMinute: moment(),
		currentDay: moment().startOf('day'),

		ctrlKeyHeld: false,
		altKeyHeld: false,
		metaKeyHeld: false,

		mainHeight: 0,
		appDataLoaded:false
	},
	mutations: {
		setAppDataLoaded(state, value=true){
			console.log('[VueX] setAppDataLoaded=',value);
		state.appDataLoaded=value;
		},
		updateNow(state) {
			state.currentTime = moment();
			if (!state.currentMinute.isSame(state.currentTime, 'minute')) {
				state.currentMinute = state.currentTime;

				if (!state.currentDay.isSame(state.currentTime, 'day')) {
					state.currentDay = state.currentTime.clone().startOf('day');
				}
			}
		},
		setSearchTxt(state, txt) {
			state.searchTxt = txt;
		},
		setUsers(state, users) {
			state.users = users;
		},
		// setFetchResourceError(state, msg) {
		// 	state.fetchResourceError = msg;
		// },
		setSavedScrollPosition(state, pos) {
			state.savedScrollPosition = pos;
		}
	},
	getters: {
		appDataLoaded(state){
			return state.appDataLoaded;
		},
		currentTime(state) {
			return state.currentTime;
		},
		currentMinute(state) {
			return state.currentMinute;
		},
		currentDay(state) {
			return state.currentDay;
		},

        /**
		 * Returns a map of person.id => department name
		 * Includes non-employees because 1) it seemed safe to do so and 2) you see 'undefined' in the Project Details/Hours by Department section when work was performed by a former employee.
		 *
         * @param state
         */
		userDepartmentList(state) {
			let departmentMap = {};
			
			dbInst
				// .findModels('person', (person) => {
				// 	return person.employee;
				// })
				.getModels('person')
				.forEach((user) => {
					departmentMap[user.id] = user.department;
				});
			
			return departmentMap;
		}
	},
	actions: {
		/* STORE_INIT({state}, store) {
			store.subscribe((mutation) => {
				if (mutation.type === 'updateNow') {
					if (!state.currentMinute.isSame(state.currentTime, 'minute')) {
						state.currentMinute = state.currentTime;
					}
				}
			});
		} */
	}
}
