<script>
	export default {
		props: {
			input: {
				required: true
			},

			type: {
				type: String,
				required: true
			},

			handle: {
				type: String,
				required: true
			}
		},

		destroyed() {
			this.$db.removeHandle(this.handle);
		},

		render(h) {
			return this.$scopedSlots.default({
				models: this.$db.findModels(this.type, (_, handles) => handles[this.handle])
			});
		},

		watch: {
			input: {
				deep: true,
				immediate: true,
				handler(to) {
					this.$db.removeHandle(this.handle);
					this.$db.addModels(this.type, this.input, this.handle);
				}
			}
		}
	}
</script>