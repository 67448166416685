export default function (model) {
	let capitalizedModel = model[0].toUpperCase() + model.substr(1);

	return {
		props: {
			modal: {
				type: Object,
				required: true,
			},

			[model]: {
				type: Object,
				default: null
			}
		},

		computed: {
			['isNew' + capitalizedModel]() {
				return this[model]
					? !this[model].id
					: true;
			}
		},

		methods: {
			getObjectProp(name) {
				if (this[model] && this[model][name]) {
					return this[model][name];
				}

				return "";
			},
			/* isAllowed(perm, obj) {
				if (!obj) {
					if (this['isNew' + capitalizedModel]) {
						return true;	// Going to be the owner, so yes
					}

					if (this[model] && this[model]._permissions) {
						obj = this[model];
					}
					else {
						obj = this.$store.getters.userData;
					}
				}

				return obj._permissions.includes(perm);
			} */
		}
	}
};