$.fn.serializeObject = (function() {

	function convertArrToObj(obj, key)
	{
		var newObj = {};
		for (var i in obj[key])
			newObj[i] = obj[key][i];
		obj[key] = newObj;
	}

	function setDeep(obj, props, val)
	{
		var last = props.pop(),
			lastObj;

		for (var i = 0; i < props.length; i++)
		{
			if (typeof obj[props[i]] == 'undefined')
			{
				if (i > 0 && !$.isNumeric(props[i]) && $.isArray(obj))
				{
					convertArrToObj(lastObj, props[i - 1]);
					obj = lastObj[props[i - 1]];
				}

				obj[props[i]] = [];
			}

			lastObj = obj;
			obj = obj[props[i]];
		}

		if (props.length && !$.isNumeric(last) && $.isArray(obj))
		{
			convertArrToObj(lastObj, props[props.length - 1]);
			obj = lastObj[props[props.length - 1]];
		}

		obj[last] = val;
	}

	return function() {
		var arr = $(this).serializeArray(),
			result = {},
			inc = {},
			tmp;

		for (var i = 0; i < arr.length; i++)
		{
			var props = [],
				name = arr[i].name;

			while ((tmp = name.indexOf('[]')) >= 0)
			{
				var shortName = name.substr(0, tmp);
				if (typeof inc[shortName] == 'undefined')
					inc[shortName] = 0;

				name = shortName + '[' + (inc[shortName]++) + ']' + name.substr(tmp + 2);
			}

			name = name.replace(/[\[\]]+/g, function() {
				return ' ';
			}).trim();

			setDeep(result, name.split(' '), arr[i].value);
		}

		return result;
	};

})();