<template>
	<v-container v-if="noProject" fluid fill-height class="align-center justify-center flex-column">
		<p class="text-h4">This project does not exist</p>
		<v-icon size="150px">
			fad fa-meteor
		</v-icon>
	</v-container>
	<v-container v-else fluid :fill-height="$vuetify.breakpoint.mdAndUp ? true : false" class="d-md-flex flex-column align-md-stretch">
		<v-card v-if="project" class="mb-2 sticky">
			<v-card-title class="py-2">
				<object-link :uid="project.client.uid">{{project.client.name}}</object-link> : {{project.name}}

				<div class="ml-4">
					<v-btn small min-width="0" v-clipboard:copy="`${project.client.name} - ${project.job} - ${project.name}`" v-clipboard:success="copiedToClipboard" title="Copy project name to clipboard">
						<v-icon small>fad fa-clipboard</v-icon>
					</v-btn>

					<!-- <v-btn small min-width="0" class="mx-1" title="Duplicate this Project" @click="duplicateProject()" disabled>
						<v-icon small>fad fa-copy</v-icon>
					</v-btn> -->

					<v-btn small min-width="0" type="button" title="Edit Project" class="ml-1" @click="updateProject()">
						<v-icon small>fad fa-pencil</v-icon>
					</v-btn>

					<!-- <object-link uid="UGVyc29uOjEwMDpDb250YWN0">asdf</object-link> -->
				</div>
				<!-- extra icons MD + only -->
				<div v-if="$vuetify.breakpoint.mdAndUp" class="ml-auto icon-links">
					<!-- <v-btn v-if="project.meta_demo_link" :href="project.meta_demo_link" target=" _blank" min-width="0" text title="demo link" small>
						<v-icon>fad fa-space-station-moon-alt</v-icon>
					</v-btn> -->
					<v-btn v-if="project.meta_xd_link_design" :href="project.meta_xd_link_design" target=" _blank" text min-width="0" small>
						<img :src="require('@/assets/images/xd.svg')" alt="" width="30" height="29">
					</v-btn>
				</div>
			</v-card-title>
		</v-card>

		<div v-if="project" class="mb-2">
			<v-tabs justified show-arrows style="width:100%">
				<v-tab :to="{name: 'project.legacy' }">Details</v-tab>
				<!-- <v-tab :to="{name:'project.details', params:{id:id, view:'projectInfoView'}}" :active="isProjectInfoView">Project Info</v-tab>
				<v-tab :to="{name:'project.details', params:{id:id, view:'projectTasksNotes'}}" :active="isTasksAndNotesView">Tasks & Notes</v-tab>
				<v-tab :to="{name:'project.details', params:{id:id, view:'projectAnalytics'}}" :active="isProjectAnalyticsView">Analytics</v-tab>
				<v-tab :to="{name:'project.details', params:{id:id, view:'projectComms'}}" :active="isCommsView">Comms</v-tab>
				<v-tab v-if="hasProjectSitemap" :to="{name:'project.details', params:{id:id, view:'projectSitemap'}}" :active="isSitemapView">Sitemap</v-tab>
				<v-tab :to="{name:'project.details', params:{id:id, view:'projectCalendar'}}" :active="isCalendarView">Calendar</v-tab>
				<v-tab :to="{name:'project.details', params:{id:id, view:'timesheet'}}" :active="isTimesheetView">Timesheet</v-tab>
				<v-tab :to="{name:'project.details', params:{id:id, view:'notepad'}}" :active="isNotesView" :title="notepadNeedsSavingFn ? 'Edited' : ''">
					Notepad<span v-if="notepadNeedsSavingFn">*</span>
				</v-tab> -->
				<v-tab :to="{name: 'project.info' }">Info</v-tab>
				<v-tab :to="{name: 'project.tree' }">
					<v-icon small class="mr-2">fad fa-layer-group</v-icon>
					Task Groups
				</v-tab>
				<v-tab :to="{name: 'project.tasksnotes' }">Tasks &amp; Notes</v-tab>
				<v-tab :to="{name: 'project.analytics' }">Analytics</v-tab>
				<v-tab :to="{name: 'project.comms' }">Comms</v-tab>
				<v-tab :to="{name: 'project.calendar' }">Calendar</v-tab>
				<v-tab v-if="project && /web/i.test(project.category)" :to="{name: 'project.sitemap' }">Sitemap</v-tab>
				<v-tab :to="{name: 'project.timesheet' }">Timesheet</v-tab>
				<v-tab :to="{name: 'project.notepad' }">Notepad<template v-if="notepadNeedsSavingFn">*</template></v-tab>

				<div v-if="panels.length" class="fill-height" style="width:100%">
					<v-divider vertical inset></v-divider>
					<v-tab v-for="(panel, ndx) in panels"
						   :key="ndx"
						   :to="{name:'project.details', params:{id:id, view: 'panel-' + (ndx + 1)}}"
						   :active="ndx == panelViewIndex">
						{{panel.label}}
					</v-tab>
				</div>
			</v-tabs>
		</div>

		<v-sheet v-if="$apollo.queries.project.loading" class="text-center my-1 py-5" style="width:100%">
			<p>Loading Project</p>
			<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
		</v-sheet>

		<div v-else-if="project" ref="content" class="flex-grow-1" style="width:100%">
			<div ref="panelContainer" class="iframe-container" style="display: none"></div>
			<router-view></router-view>
			<!-- <keep-alive> -->
			<!-- <component v-if="tabContent" :is="tabContent.component" v-bind="tabContent.props" v-on="tabContent.events"></component> -->
			<!-- <project-details-legacy-view v-if="isLegacyView" @updateProject="onProjectUpdated" :project="project"></project-details-legacy-view>
				<project-info-view v-else-if="isProjectInfoView" @updateProject="onProjectUpdated" @editProject="showProjectEditor" :project="project"></project-info-view>
				<project-tasks-notes v-else-if="isTasksAndNotesView" @updateTasks="updateProjectTasks" :project="project"></project-tasks-notes>
				<project-analytics v-else-if="isProjectAnalyticsView" :project="project"></project-analytics>
				<project-comms v-else-if="isCommsView"></project-comms>
				<project-calendar v-else-if="isCalendarView"></project-calendar>
				<project-sitemap-view v-else-if="isSitemapView" @updateSitemap="updateGlobalProject" :project="project"></project-sitemap-view>
				<project-timesheet v-else-if="isTimesheetView"></project-timesheet>
				<project-notepad v-else-if="isNotesView" :project="project" @needs-saving="notepadNeedsSavingFn = $event"></project-notepad>
				<project-panel v-else-if="panelViewIndex >= 0" :url="panels[panelViewIndex].url" @show-panel="showPanelIframe" @hide-panel="hidePanelIframe"></project-panel>
				<div v-else>View doesn't exist or has been changed. Please select a tab above</div> -->
			<!-- </keep-alive> -->

			<!-- can't yet include the graphs because it will destroy the responsiveness of the page-->
			<!-- <project-graph v-show="isProjectGraphView"></project-graph>-->
			<!-- <component :is="viewComponent" :project="project" @updateProject="onProjectUpdated">{{view}}</component>-->
		</div>

	</v-container>
</template>

<script>
	import assignedUsers from "@/components/assignedUsers";
	import addNoteButton from "@/components/addNoteButton";
	import NoteList from "@/components/noteList";
	import metaList from "@/components/metaList";
	import TimerClock from "@/components/timerClock";
	import CheckListTree from "@/components/checkListTree/checkListTree";
	import ContactList from "@/components/contactList";
	import objectLink from "@/components/objectLink";
	import ObjectLink from "../../components/objectLink.vue";
	import { EventBus } from "../../utils/EventBus";
	// import projectDetailsLegacyView from "@/components/projectDetailsLegacyView";
	// import projectTasksNotes from "@/components/projectTasksNotes";
	// import projectInfoView from "@/components/projectInfoView";
	// import projectAnalytics from "@/components/projectAnalyticsView";
	// import projectCalendar from "@/components/projectCalendar";
	// import projectComms from "@/components/projectComms";
	// import projectSitemapView from "@/components/projectSitemapView";
	// import projectNotepad from "@/components/project/projectNotepad";
	// import projectTimesheet from "@/components/project/projectTimesheet";
	// import projectPanel from "@/components/project/projectPanel";

	const PROJECT_QUERY = gql`
		query GetProjectById($id: ID!) {
			project(id: $id) {
				id
				uid
				name
				type
				client {
					id
					uid
					name
					app_url_raw
				}
				status
				due_date
				account_executive_user {
					id
					uid
					email
					title
					full_name
					initials
					timezone
					department
					meta_avatar
					app_url_raw
				}
				meta_panels
				job
				category
				progress
				description
				team
				project_started
				estimated_completion_date
				hours_allocated
				budget_alerts
				time_spent
				task_counts {
					uid
				}
				modified_date
				creation_date
				meta_xd_link_design
				meta_client_quoted_hours
				# meta_demo_link
			}
		}
	`;

	export default {
		name: "projectDetails",

		components: {
			objectLink,
			assignedUsers,
			addNoteButton,
			NoteList,
			metaList,
			TimerClock,
			CheckListTree,
			ContactList
			// projectDetailsLegacyView,
			// projectTasksNotes,
			// projectInfoView,
			// projectAnalytics,
			// projectSitemapView,
			// projectNotepad,
			// projectCalendar,
			// projectComms,
			// projectTimesheet,
			// projectPanel
		},

		props: {
			id: String,
			view: String,
		},
		
		data() {
			return {
				noProject: false,
				team: [],
				cachedPanels: [],
				notepadNeedsSavingFn: false
			};
		},

		apollo: {
			project: {
				query: PROJECT_QUERY,
				variables() {
					return {
						id: this.$route.params.id,
					};
				},
				skip() {
					return !this.$route.params.id;
				},
				error() {
					this.noProject = true;
				}
			},
		},

		reactiveProvide: {
			name: 'projectContext',
			include: ['project']
		},

		created() {
			// TODO: Make notepad component do this stuff
			EventBus.$on('projectNotepad/needs-saving', (fn) => {
				this.notepadNeedsSavingFn = fn;
			})
		},

		beforeDestroy() {
			EventBus.$off('projectNotepad/needs-saving');
		},

		// updated() {
		// 	this.$nextTick(function () {
		// 		// Code that will run only after the entire view has been re-rendered
		// 		// console.log('view', this.$route.params.view)
		// 		if(this.$route.params.view) {
		// 			this.setUserSetting('project_detail_view', this.$route.params.view)
		// 		}
		// 	})
		// },

		// asyncComputed: {
		// 	projectFromServer() {
		// 		return new Promise((resolve, reject) => {
		// 			if (this.$store.getters.isNotLoggedIn) {
		// 				reject();
		// 				return;
		// 			}

		// 			return this.$xhrRequest
		// 				.send("get", `/api/project/${this.id}`, {
		// 					fields:
		// 						"*,client,client_contacts,confirmed_time,confirmed_time_by_user,contacts,department,tasks,deadlinesAndReminders,last_status_note,modified_date"
		// 				})
		// 				.then((projectData) => {
		// 					// default value for a metafield that is undefined for new projects and projects
		// 					// that existed before the project_checklist meta field was brought into existence
		// 					if (!projectData.meta_project_checklist) {
		// 						projectData.meta_project_checklist = {
		// 							children: [],
		// 						};
		// 					}

		// 					if (!projectData.meta_project_sitemap) {
		// 						projectData.meta_project_sitemap = {
		// 							data: [{ _indent: 0 }],
		// 							fields: [
		// 								// {
		// 								// key:   '_indent',
		// 								// label: '',
		// 								// },
		// 								{
		// 									key: "0",
		// 									label: "Pagename",
		// 								},
		// 							],
		// 						};
		// 					}

		// 					// addModels is required, because other components such as the Notes component reference
		// 					// the notes from the global object store, -and- addModels doesn't just add
		// 					// the project data, it also adds the notes and tasks and anything else, too.

		// 					// SimpleDB seems to remove the 'contacts' property from our data
		// 					projectData.project_contacts = projectData.contacts;
		// 					this.$db.addModels(
		// 						"project",
		// 						projectData,
		// 						"project-details"
		// 					);
		// 					resolve(projectData);
		// 				});
		// 		});
		// 	},
		// },

		computed: {
			/* tabContent() {
					switch (this.viewComponent) {
						case 'projectDetailsLegacyView':
							return {
								component: 'projectDetailsLegacyView',
								props: {
									project: this.project,
								},
								events: {
									'updateProject': this.onProjectUpdated,
									'hook:mounted': this.resize
								}
							};
						default:
							return false;
					}
				}, */
			// project() {
			// 	return this.$db.getModel("project", this.id);
			// },
			// viewComponent() {
			// 	let v = this.view;
			// 	if (!v) {
			// 		v = this.getUserSetting(
			// 			"project-details-default-view",
			// 			"projectDetailsLegacyView"
			// 		);
			// 	}
			// 	return v;
			// },
			// isProjectGraphView() {
			// 	return this.viewComponent === "projectGraph";
			// },
			// isLegacyView() {
			// 	return this.viewComponent === "projectDetailsLegacyView";
			// },
			// isTasksAndNotesView() {
			// 	return this.viewComponent === "projectTasksNotes";
			// },
			// isProjectInfoView() {
			// 	return this.viewComponent === "projectInfoView";
			// },
			// isProjectAnalyticsView() {
			// 	return this.viewComponent === "projectAnalytics";
			// },
			// isCalendarView() {
			// 	return this.viewComponent === "projectCalendar";
			// },
			// isCommsView() {
			// 	return this.viewComponent === "projectComms";
			// },
			// isSitemapView() {
			// 	return this.viewComponent === "projectSitemap";
			// },
			// isNotesView() {
			// 	return this.viewComponent === "notepad";
			// },
			// isTimesheetView() {
			// 	return this.viewComponent === "timesheet";
			// },
			panelViewIndex() {
				let match = this.viewComponent.match(/^panel-(\d+)$/);

				if (match) {
					return match[1] - 1;
				}

				return -1;
			},
			panels() {
				if (!this.project) {
					return [];
				}

				return this.project.meta_panels || [];
			},
			// client() {
			// 	return this.$db.getModel("client", this.project.client_id);
			// },
			// hasProjectSitemap() {
			// 	return (
			// 		this.isSitemapView ||
			// 		(this.project && /web/i.test(this.project.category))
			// 	);
			// },
			// globalProject() {
			// 	return this.$db.getModel("project", this.project.id);
			// },
		},

		// destroyed() {
		// 	this.$db.removeHandle("project-details");
		// },

		beforeRouteLeave(to, from, next) {
			let doWork = () => {
				this.cachedPanels.forEach((el) => el.remove());
				this.cachedPanels = [];
				next();
			};

			if (this.notepadNeedsSavingFn) {
				this.$swal({
					title: "Notes not saved",
					text: `Are you sure you wish to leave the page?`,
					icon: "warning",
					buttons: {
						cancel: "Cancel",
						save: "Save",
						yes: "Continue",
					},
					dangerMode: true,
				}).then((action) => {
					switch (action) {
						case "cancel":
							next(false);
							break;

						case "save":
							this.notepadNeedsSavingFn()
								.then(() => doWork())
								.catch(() => next(false));
							break;

						case "yes":
							doWork();
							break;
					}
				});
			} else {
				doWork();
			}
		},

		methods: {
			refreshProjectContacts() {
				this.$xhrRequest
					.send("get", `/api/project/${this.id}`, {
						fields: "client_contacts,contacts",
					})
					.then((projectData) => {
						projectData.project_contacts = projectData.contacts;
						this.$db.addModels(
							"project",
							projectData,
							"project-details"
						);
					});
			},

			onProjectUpdated(projectData) {
				console.log("[projectDetails] onProjectUpdated", projectData);
				this.$db.addModels("project", projectData);
				this.$snotify.success("Success!", "Update Project");
			},

			/* updateGlobalProject(project) {
				console.log("[projectDetails] updateGlobalProject");
				this.$db.addModels("project", project);
			}, */

			duplicateProject() {
				console.info("duplicating project");
				this.$modalService
					.create("projectAddEdit", {
						duplicateProjectId: this.project.id,
					})
					.on("save", (e, project) => {
						this.$db.addModels("project", project);
						this.$snotify.success("Duplicate Project", "Success!");

						this.$router.push({
							name: "project.details",
							params: { id: project.id },
						});
					});
			},
			showProjectEditor() {
				this.$modalService
					.create("projectAddEdit", {
						projectId: this.project.id,
					})
					.on("save", (e, project) => {
						this.onProjectUpdated(project);
					});
			},
			updateProjectTasks() {
				setTimeout(() => {
					this.$xhrRequest
						.send("get", `/api/project/${this.project.id}`, {
							fields: "tasks,meta",
						})
						.then((project) => {
							this.$db.addModels("task", project.tasks);
						});
				}, 1);
			},
			updateProject() {
				this.$modalService
					.create("projectAddEdit", {
						projectId: this.$route.params.id,
					})
					.on('save', (e) => {
						// TODO: Research cache updating
						this.$apollo.queries.project.refetch();
					});
			},
			showPanelIframe(iframe) {
				if (iframe.parentNode == null) {
					this.cachedPanels.push(iframe);
					this.$refs.panelContainer.appendChild(iframe);
				}

				this.$refs.panelContainer.style.display = "block";
				iframe.style.display = "block";
			},
			hidePanelIframe(iframe) {
				if (this.$refs.panelContainer) {
					this.$refs.panelContainer.style.display = "none";
				}
				iframe.style.display = "none";
			},
		},

		watch: {
			$route: {
				immediate: true,
				handler(to) {
					this.setUserSetting("project_detail_view", to.name);
					this.noProject = false;
				}
			},
			// id(to) {
			// 	this.provideData.projectId = to;
			// }
			// view(to) {
			// 	// console.log("set view to", to);
			// 	// todo remember the view preference
			// },
		},
	};
</script>

<style lang="scss" scoped>
	.sticky {
		position: sticky;
		top: 90px;
		z-index: 4;
	}
</style>
