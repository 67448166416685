import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
// import { HttpLink } from 'apollo-link-http';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import store from '../store';

import introspectionQueryResultData from './fragmentTypes.json';
import settings from '../utils/settings';

export const apolloClient = new ApolloClient({
	shouldBatch: true,

	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
					),
				);
			if (networkError) console.log(`[Network error]: ${networkError}`);
		}),
		new ApolloLink((operation, forward) => {
			// add the authorization to the headers
			operation.setContext((context) => {
				context.headers = {
					...context.headers,
					'X-Auth': store.state.auth.sessionId
				};

				return context;
			});
	
			return forward(operation);
		}),
		new BatchHttpLink({
			uri: settings.url + '/graphql',
			batchMax: 10,
			batchInterval: 10,
			batchDebounce: true
		})
	]),

	cache: new InMemoryCache({
		fragmentMatcher: new IntrospectionFragmentMatcher({
			introspectionQueryResultData
		})
	}),

	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'cache-and-network'
		},
		query: {
			fetchPolicy: 'no-cache'
		}
	}
});