<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12">
				<project-progress-bar class="mb-4"></project-progress-bar>
				<v-row>
					<v-col cols="12" lg="4">
						<active-timers></active-timers>
					</v-col>
					<v-col cols="12" lg="4">
						<div v-if="todaysProjects">
							<v-card>
								<v-card-title>Active Projects</v-card-title>
							</v-card>
							<v-card v-for="(t, key) in todaysProjects" :key="key" class="my-1">
								<v-card-title>{{t.client}}</v-card-title>
								<v-card-text>

									<div v-for="project in t.projects" :key="project.id" class="d-flex align-center">
										<span :class="{'active': projectActiveMap[project.id]}" class="timer-status mr-2"></span>
										<template v-if="project">{{project.name}}</template>
										<template v-else>NOT SET</template>
									</div>

								</v-card-text>
							</v-card>
						</div>
					</v-col>
					<v-col cols="12" lg="4" class="activity-container">
						<v-card>
							<v-card-title>Latest Activity</v-card-title>
							<v-card-text>
								<activity :max="10"></activity>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import projectProgressBar from "../components/analytics/projectProgressBar";
	import Activity from "./activity";
	import TimerClock from "@/components/timerClock";
	import ActiveTimers from "@/components/activeTimers";

	export default {
		name: "analytics-dashboard",
		components: { Activity, projectProgressBar, TimerClock, ActiveTimers},
		data() {
			return {};
		},
		apollo: {
			timerProjects: {
				query: gql`
					query getTodaysProjects($filterParam: QueryFilter!) {
						timerProjects: timers @filter(param: $filterParam) {
							nodes {
								node {
									id
									uid
									status
									client {
										id
										name
									}
									project {
										id
										name
									}
								}
							}
						}
					}
				`,
				pollInterval: timeInterval("2 minutes"),
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "creation_date",
									op: ">=",
									value: this.$store.getters.currentDay.format("YYYY-MM-DD"),
								},
								{
									field: "client.name",
									op: "!=",
									value: null,
								},
							],
						},
					};
				},
			},
		},
		computed: {
			todaysProjects() {
				if (!this.timerProjects || !this.timerProjects.nodes.length) {
					return [];
				}

				let projects = Linq.from(this.timerProjects.nodes)
					.select((row) => row.node)
					.groupBy(
						(i) => {
							if (i.client) {
								return i.client.name;
							}
							return null;
						},
						(client, timers) => {
							return {
								client,
								projects: timers
									.select(t => t.project)
									.distinct(p => p.id)
									.orderByDescending(p => p.name.startsWith("_"))
									.thenBy(p => p.name)
									.toArray()
							};
						}
					)
					
					.toArray();

				return projects;
			},

			projectActiveMap() {
				if (!this.timerProjects) {
					return {};
				}

				let projectActiveMap = {};

				 Linq.from(this.timerProjects.nodes)
					.select(row => row.node)
					.select(timer => {
						if(!projectActiveMap[timer.project.id]) {
							projectActiveMap[timer.project.id] = false;
						}
						projectActiveMap[timer.project.id] = projectActiveMap[timer.project.id] || timer.status == "Active";
					})
					.toArray();

				return projectActiveMap;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.activity-container ::v-deep .activity-list {
		padding-top: 0;
		font-size: 11px;
		.hide-on-panel {
			display: none !important;
		}
		.activity-log-item {
			display: flex;
			flex-direction: column;
			> div {
				padding: 0;
				text-align: left !important;
			}
		}
		.activity-log-item {
			padding: 10px 0;
		}
		.btn,
		.loading-row {
			display: none;
		}
		.department-indicator {
			top: 2px;
			position: relative;
		}
	}

	.timer-status {
		width: 10px;
		height: 10px;
		border-radius: 100%;
		background-color: map-get($grey, "darken-1");
		&.active {
			background-color: var(--v-status-active-base);
			box-shadow: 0 0 5px 1px var(--v-status-active-base);
		}
	}
</style>