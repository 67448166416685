import { render, staticRenderFns } from "./taskCardMini.vue?vue&type=template&id=4d0c25f7&scoped=true&"
import script from "./taskCardMini.vue?vue&type=script&lang=js&"
export * from "./taskCardMini.vue?vue&type=script&lang=js&"
import style0 from "./taskCardMini.vue?vue&type=style&index=0&id=4d0c25f7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d0c25f7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_0f7bc6efe1949d16b55ed303eb0ec848/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VList,VListItem,VListItemTitle,VMenu})
