import Vue from 'vue';
import {dbInst} from '../db/simple-db';

export function XHRRequest(parent) {
    this._parent = parent;
    this._eventContainer = $({});
};

Object.assign(XHRRequest.prototype, {
	_SpecialMethods: ['POST', 'PUT'],

    on: function(evt, cb) {
        return XHRRequest.on(evt, cb, this);
    },

    one: function(evt, cb) {
        return XHRRequest.one(evt, cb, this);
    },

    off: function(evt, cb) {
        return XHRRequest.off(evt, cb, this);
    },

    /**
     * Returns a Promise'ified request
     * @returns {Promise<any>}
     */
    then: function(success, error) {
        return new Promise((resolve, reject) => {
            this.one('success', (e, body) => {
                resolve(success(body));
            });
            this.one('error', (e, xhr) => {
                if (error) {
                    resolve(error(xhr));
                }
                else {
                    reject(xhr);
                }
            });
        });
    },

    /**
     * Returns a Promise'ified request
     * @returns {Promise<any>}
     */
    catch: function(error) {
        return new Promise((resolve) => {
            this.one('error', (e, xhr) => {
                resolve(error(xhr));
            });
        });
	},
	
	/**
     * Returns a Promise'ified request
     * @returns {Promise<any>}
     */
	toPromise() {
		return new Promise((resolve, reject) => {
			this.one('success', (e, body) => {
                resolve(body);
            });
            this.one('error', (e, xhr) => {
                reject(xhr);
            });
		});
	},

    trigger: function(evts) {
        let payload = Array.prototype.slice.call(arguments, 1);
        evts = evts.split(' ').map(evt => evt.trim()).filter(evt => evt);

        evts.forEach((evt) => {
            let e = $.Event(evt);
            e.request = this;

            this._eventContainer.trigger(e, payload);
            // let parent = this;
            // while (parent = parent._parent) {
            //     parent._eventContainer.trigger(e, payload);
            //     if (e.isPropagationStopped()) {
            //         break;
            //     }
            // }
            // if (!e.isPropagationStopped()) {
            //     XHRRequest._eventContainer.trigger(e, payload);
            // }
            if (!this.isSubRequest() && !e.isPropagationStopped()) {
                XHRRequest._eventContainer.trigger(e, payload);
            }
        });

        return this;
    },

    isSubRequest() {
        return !!this._parent;
    },

    chainStart: function() {
        if (this._parent) {
            throw 'Only one chain can be going at once';
        }

        this.chaining = [];
        return this;
    },

    chainEnd: function() {
        if (!this.chaining) {
            throw 'No chain is running';
        }

        let chain = this.chaining;
        delete this.chaining;

        if (chain.length) {
            let body = [];
            for (let i = 0; i < chain.length; i++) {
                body.push({
                    api: chain[i].url,
                    method: chain[i].method,
                    payload: chain[i].body
                });
            }

            return this
                .send('POST', `${XHRRequest.baseUrl}/api/chain`, { chain: body })
                .one('success', (e, responses, mainXhr) => {
                    for (let j = 0; j < responses.length; j++) {
                        let xhr = {
                            status: responses[j].code,
                            responseJSON: responses[j].content || undefined,
                            readyState: 4
                        };

                        if (xhr.status != 200 || typeof xhr.responseJSON == 'undefined') {
                            chain[j].trigger('error', xhr, chain[j]);
                        }
                        else {
                            chain[j].trigger('success', xhr.responseJSON, xhr, chain[j]);
                        }
    
                        chain[j].trigger('done', xhr, chain[j]);
                    }

                    this.trigger('chainSuccess chainDone', mainXhr, this);
                })
                .one('error', (e, mainXhr, request) => {
                    if (request.url == this.url) {
                        this.trigger('chainError chainDone', mainXhr, this);
                    }
                });
        }

        return this.trigger('success done');
    },

    send: function(method, url, body) {
        if (this._parent) {
            throw 'Please issue all requests from the parent chain instance';
        }

        if (url.indexOf(XHRRequest.baseUrl) === 0) {
            url = url.substr(XHRRequest.baseUrl.length);
		}

        // Transform dates to server time
        body = dbInst.transformModel(body);
        method = method.toUpperCase();

        if (this.chaining) {
            let request = new XHRRequest(this);

            request.method = method;
            request.url = url;
            request.body = body;

            this.chaining.push(request);

            return request;
        }
        else {
            this.method = method;
            this.url = url;
			this.body = body;

            Vue.nextTick(() => {
                let that = this,
                    xhrFields = {};
				this.trigger('xhrFields', xhrFields, this);

                $.ajax({
                    url: `${XHRRequest.baseUrl}${url}`,
                    type: method,
					dataType: 'json',
					contentType: this._SpecialMethods.includes(method) ? 'application/json' : 'application/x-www-form-urlencoded; charset=UTF-8',
                    data: this._SpecialMethods.includes(method) ? JSON.stringify(body) : body,
                    xhrFields: xhrFields,
                    beforeSend: function(request) {
                        that.trigger('beforeSend', request, that);
                    },
                    complete: function(xhr) {
                        if (xhr.status != 200 || typeof xhr.responseJSON == 'undefined') {
                            that.trigger('error', xhr, that);
                        }
                        else {
                            that.trigger('success', xhr.responseJSON, xhr, that);
                        }

                        that.trigger('done', xhr);
                    }
                });
            });
        }

        return this;
    }
});

Object.assign(XHRRequest, {
    _eventContainer: $({}),

    on: function(evt, cb, bound) {
        bound = bound || XHRRequest;
        let args = [evt];
        if (cb) {
            args.push(cb);
        }
        bound._eventContainer.on.apply(bound._eventContainer, args);
        return bound;
    },

    one: function(evt, cb, bound) {
        bound = bound || XHRRequest;
        let args = [evt];
        if (cb) {
            args.push(cb);
        }
        bound._eventContainer.one.apply(bound._eventContainer, args);
        return bound;
    },

    off: function(evt, cb, bound) {
        bound = bound || XHRRequest;
        let args = [evt];
        if (cb) {
            args.push(cb);
        }
        bound._eventContainer.off.apply(bound._eventContainer, args);
        return bound;
    },

    chainStart() {
        return new XHRRequest().chainStart();
    },

    send: function(method, url, body) {
        return new XHRRequest().send(method, url, body);
    }
});