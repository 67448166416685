<template>
	<!-- mobile -->
	<v-tabs v-if="$vuetify.breakpoint.smAndDown" v-model="tab" fixed-tabs>
		<v-tab>Tasks</v-tab>
		<v-tab>Notes</v-tab>
		<v-tabs-items v-model="tab" class="mt-2">
			<v-tab-item>
				<task-list-view @update="onUpdateTasks"></task-list-view>
			</v-tab-item>
			<v-tab-item>
				<note-list-view :project="projectDetails"></note-list-view>
			</v-tab-item>
		</v-tabs-items>
	</v-tabs>
	<!-- desktop -->
	<div v-else class="fill-height">
		<v-row dense class="fill-height">
			<v-col cols="12" md="6">
				<task-list-view @update="onUpdateTasks"></task-list-view>
			</v-col>
			<v-col cols="12" md="6">
				<note-list-view :project="projectDetails"></note-list-view>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import taskListView from "../components/taskListView";
	import noteListView from "../components/noteListView";
	import { timeInterval } from "../../../utils/timeInterval";

	export default {
		components: {
			taskListView,
			noteListView,
		},
		inject: ["projectContext"],
		data() {
			return {
				tab: null,
			};
		},
		computed: {
			projectDetails() {
				return this.projectContext.project;
			},
		},

		methods: {
			onUpdateTasks() {
				// this.$emit("updateTasks");
				console.log("[Tasks & Notes] emit updateTasks");
			},
		},
	};
</script>

<style scoped lang="scss">
</style>
