<template>
	<v-card :loading="loading" class="mb-4">
		<v-card-title class="align-start">
			Sitemap
			<div class="d-flex ml-auto">
				<v-btn :disabled="loading" :loading="loading" small text color="primary" class="mr-2" @click="reset">Clear All</v-btn>
				<template-data :data="{ value: false }" v-slot="showEdit">
					<div>
						<v-menu
							v-model="showEdit.value"
							:close-on-content-click="false"
							:offset-x="true"
							:left="true"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn :loading="loading" small v-on="on" v-bind="attrs">
									Edit columns
									<v-icon small class="ml-2">fal {{showEdit.value ? 'fa-chevron-down': 'fa-chevron-right'}}</v-icon>
								</v-btn>
							</template>
							<v-card :loading="loading">
								<v-card-text>
									<v-lazy>
										<v-textarea
											:disabled="loading"
											auto-grow
											v-model="columnTitlesText"
											outlined
											label="Columns"
											hint="try adding '|text' to a column name"
										></v-textarea>
									</v-lazy>
								</v-card-text>
							</v-card>
						</v-menu>
					</div>
				</template-data>
			</div>
		</v-card-title>
		<v-card-subtitle class="d-flex align-center mt-1">
			<div>
				<kbd>Ctrl + left</kbd> / <kbd>Ctrl + right</kbd> to adjust indent, <kbd>Ctrl + up</kbd> / <kbd>Ctrl + down</kbd> to move up / down<br>
				Drag and Drop the left handle to reorder
			</div>
			<v-btn :disabled="loading" :loading="loading" small @click="printSitemap" class="ml-auto" title="Download PDF" min-width="0">
				<v-icon small>fad fa-file-export</v-icon>
			</v-btn>
		</v-card-subtitle>
		<v-card-text v-if="sitemap">
			<v-alert color="primary" class="d-sm-none" border="top">
				In order to make this table fully responsive, we have to get rid of the drag and drop feature.
			</v-alert>

			<v-data-table :items="sitemap.data" :headers="dataTableColumns" :show-select="false" disable-sort disable-pagination hide-default-footer dense calculate-widths class="table-sitemap" fixed-header>
				<template v-slot:body="props">
					<draggable :list="props.items" tag="tbody" @change="onChange">
						<tr v-for="(item, index) in props.items" :key="index">
							<td class="text-center cursor-pointer handle">
								<v-icon small color="grey">fal fa-expand-arrows</v-icon>
							</td>
							<td v-for="(field, ndx) in dataColumns" :key="field.key" class="text-center">
								<v-text-field v-if="field.type == 'text'"
											  v-model="sitemap.data[index][ndx]"
											  :class="ndx == 0 ? 'pagename-input' : ''" background-color="transparent"
											  hide-details solo flat
											  :style=" `--indent: ${(item['_indent']||0)}rem` "
											  @keydown="onKeyDown($event, index)"
											  @input="startSave"
								></v-text-field>
								<v-checkbox v-else-if="field.type == 'checkbox'" class="mt-0 d-inline-block"
											v-model="sitemap.data[index][ndx]" hide-details dense
											value="1" false-value="0"
											@change="startSave"
								></v-checkbox>
							</td>
							<td>
								<v-btn small min-width="0" text color="primary" @click="deleteRow(index)">
									<v-icon small>fad fa-trash</v-icon>
								</v-btn>
							</td>
						</tr>
					</draggable>
				</template>
			</v-data-table>
		</v-card-text>
		<v-card-actions>
			<v-btn @click="newRow" class="ml-auto"> add row <v-icon small class="ml-2">fal fa-plus</v-icon>
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import draggable from "vuedraggable";
	import PDFGenerator from "../../../utils/PDFGenerator";

	export default {
		name: "projectSitemapView",
		components: {
			draggable
		},
		data() {
			return {
				sitemap: null,
				saveDebounce: false
			};
		},
		inject: ["projectContext"],

		apollo: {
			projectSitemap: {
				query: gql`
					query GetProjectSitemap($id: ID!) {
						projectSitemap: project(id: $id) {
							id
							meta_project_sitemap
						}
					}
				`,
				variables() {
					return {
						id: this.project.id,
					};
				},
				skip() {
					return !this.project;
				},
			},
		},

		computed: {
			project() {
				return this.projectContext.project;
			},
			loading() {
				return this.$apollo.queries.projectSitemap.loading;
			},
			columnTitlesText: {
				get() {
					if (!this.sitemap) {
						return '';
					}

					return this.sitemap.fields
						.map((field) => {
							let line = field.label;

							if (field.type) {
								line += "|" + field.type;
							}

							return line;
						})
						.join("\n");
				},
				set(value) {
					let input = value.split("\n");
					let output = [];
					input
						.filter((i) => i)
						.forEach((fieldTitle, index) => {
							let parts = fieldTitle.split(/\s*\|\s*/);
							let obj = {
								key: `${index}`,
								label: parts[0],
							};

							if (parts.length > 1) {
								obj.type = parts[1];
							}

							output.push(obj);
						});
					this.sitemap.fields = output;
				},
			},

			dataColumns() {
				if (!this.sitemap) {
					return [];
				}

				return this.sitemap.fields.map((field, ndx) => {
					let inferredType;
					if ((ndx == 0 && !field.type) || field.type == "text") {
						inferredType = "text";
					} else if (
						(ndx > 0 && !field.type) ||
						field.type == "checkbox"
					) {
						inferredType = "checkbox";
					}

					return {
						value: field.key + "",
						text: field.label,
						type: inferredType,
						width: inferredType == "checkbox" ? "100px" : "",
						align: inferredType == "checkbox" ? "center" : "start",
						class: "type-" + inferredType,
						dataField: true,
					};
				});
			},

			dataTableColumns() {
				return [
					{
						value: "drag",
						text: "",
						type: "meta_drag",
						width: "30px",
					},
					...this.dataColumns,
					{
						value: "delete",
						text: "",
						type: "meta_delete",
						width: "40px",
						align: "center",
					},
				];
			},
		},
		methods: {
			printSitemap() {
				if (!this.sitemap) {
					return;
				}

				PDFGenerator.projectSitemapReport(
					`${this.project.name} - Sitemap`,
					this.dataColumns,
					this.sitemap.data
				);
			},

			onChange({ moved }) {
				if (moved) {
					this.sitemap.data.splice(
						moved.newIndex,
						0,
						...this.sitemap.data.splice(moved.oldIndex, 1)
					);

					this.startSave();
				}
			},
			/**
			 * Effectively expands the clickable area of the checkboxes to the entire table cell.
			 * Click events to the first column are ignored.
			 * Use .self modifier to prevent a click on the checkbox elements from bubbling up to this event handler.
			 */
			cellClick(row, cellIndex) {
				if (cellIndex > 0) {
					// toggle value
					let v = this.sitemap.data[row.index][cellIndex] || "0";
					v = v === "0" ? "1" : "0";
					this.$set(this.sitemap.data[row.index], cellIndex, v);
					this.startSave();
					return false;
				}
			},

			/**
			 * Erase everything
			 */
			reset() {
				if (!this.sitemap) {
					return;
				}

				if (confirm("Are you sure? You can't undo this.")) {
					this.$set(this.sitemap, "data", [
						{
							_indent: 0,
							0: "",
						},
					]);
					this.$set(this.sitemap, "fields", [
						{
							key: "0",
							label: "Pagename",
						},
					]);

					this.startSave();
				}
			},

			startSave() {
				if (this.saveDebounce) {
					clearTimeout(this.saveDebounce);
				}

				this.saveDebounce = setTimeout(() => {
					this.saveDebounce = false;
					this.save();
				}, 1000);
			},

			/**
			 * In response to a change event, this will save the updated sitemap
			 */
			save() {
				if (!this.sitemap) {
					return;
				}

				this.$apollo
					.mutate({
						mutation: gql`
							mutation ($data: UpdateProjectArgs!) {
								updateProject(data: $data) {
									id
									meta_project_sitemap
								}
							}
						`,
						variables: {
							data: {
								id: this.project.id,
								meta_project_sitemap: this.sitemap
							}
						}
					})
					.catch((error) => {
						this.$debugLog('Error updating sitemap', error);
						this.$snotify.error("Sitemap could not be saved", "Error");
					});
			},
			onKeyDown(event, row) {
				let amount = null;
				if (event.key === "Enter") {
					return this.newRow();
				}
				if (event.ctrlKey) {
					let matches = event.code.match(/(Bracket|Arrow)(Left|Right)/);
					if (matches) {
						amount = matches[2] === "Right" ? 1 : -1;
						return this.adjustIndent(row, amount);
					}

					let matchesUpDown = event.code.match(/(Arrow)(Up|Down)/);
					if (matchesUpDown) {
						amount = matchesUpDown[2] === "Up" ? -1 : 1;
						return this.swapRows(row, amount);
					}
				}
			},

			swapRows(row, indexOffset) {
				let i1 = row,
					i2 = row + indexOffset;
				if (i2 < 0 || i2 >= this.sitemap.data.length) {
					return;
				}
				let t = { ...this.sitemap.data[i2] };
				this.$set(this.sitemap.data, i2, { ...this.sitemap.data[i1] });
				this.$set(this.sitemap.data, i1, t);
				this.$nextTick(() => {
					// focus input corresponding to row i2. otherwise the table will update but the "wrong"
					// text input will continue to have focus
					let i3 = parseInt(i2, 10) + 1;
					let el = document.querySelector(
						`.table-sitemap tr:nth-child(${i3}) input[type="text"]`
					);
					el && el.focus();
				});

				this.startSave();
			},

			adjustIndent(row, amount) {
				this.$set(
					this.sitemap.data[row],
					"_indent",
					Math.max(0, this.sitemap.data[row]._indent + amount)
				);

				this.startSave();
			},

			/**
			 * Create new data row, optionally auto-focusing the newly-created input field
			 */
			newRow(autofocus = true) {
				let newRow = {};
				let lastItemIndex = this.sitemap.data.length - 1;
				if (lastItemIndex >= 0) {
					let lastItem = this.sitemap.data[lastItemIndex];
					for (var i in lastItem) {
						newRow[i] = i === "_indent" ? lastItem[i] : null;
					}
				} else {
					newRow = { _indent: 0 };
				}
				this.sitemap.data.push(newRow);
				if (autofocus) {
					this.$nextTick(() => {
						let el = document.querySelector(
							'.table-sitemap tr:last-child input[type="text"]'
						);
						el && el.focus();
					});
				}

				this.startSave();
			},

			deleteRow(index) {
				this.sitemap.data.splice(index, 1);
				this.startSave();
			},
		},
		watch: {
			projectSitemap(to) {
				let sitemap = to.meta_project_sitemap;
				
				if (!sitemap) {
					sitemap = {
						data: [],
						fields: []
					};
				}
				else {
					sitemap = JSON.parse(JSON.stringify(sitemap));
				}

				if (!sitemap.fields.length) {
					sitemap.fields.push({
						key: "0",
						label: "Pagename",
					});
				}

				this.sitemap = sitemap;
			},
			dataColumns(to, from) {
				if (!from.length || JSON.stringify(to) == JSON.stringify(from)) {
					return;
				}

				if (to.length > from.length) {
					for (let i = 0; i < from.length; i++) {
						if (to[i].text != from[i].text) {
							for (let j = i + 1; j < to.length; j++) {
								if (to[j].text == from[i].text) {
									// Shift values from i -> j
									for (
										let k = 0;
										k < this.sitemap.data.length;
										k++
									) {
										for (let l = from.length; l > i; l--) {
											this.sitemap.data[k][l] =
												this.sitemap.data[k][l - 1];
										}
										this.sitemap.data[k][i] = "";
									}
									break;
								}
							}
							break;
						}
					}
				} else if (to.length < from.length) {
					for (let i = 0; i < to.length; i++) {
						if (to[i].text != from[i].text) {
							// this is the removed column
							for (let k = 0; k < this.sitemap.data.length; k++) {
								for (let l = i; l < to.length; l++) {
									this.sitemap.data[k][l] =
										this.sitemap.data[k][l + 1];
								}
								this.$delete(this.sitemap.data[k], to.length);
							}
							break;
						}
					}
				}

				this.startSave();
			},
		},
	};
</script>

<style scoped lang="scss">

	.table-sitemap ::v-deep {
        // to make headers stickable
        .v-data-table__wrapper {
            overflow: unset;
        }
        th {
            top: 135px !important;
        }

        tr {
			&:hover {
				background-color: var(--v-accent-base) !important;
			}
		}

		td {
			border-right: 1px solid var(--v-dividers-base);
			&:last-child {
				border-right: none;
			}
			&:first-child {
				padding: 0;
			}
		}
		border: 1px solid var(--v-dividers-darken3);
	}

	.pagename-input ::v-deep input {
		text-indent: var(--indent);
	}
</style>
