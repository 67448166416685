<!--
This template shows on projectDetails, clientDetails:
- projectDetails shows hours for that project
- clientDetails shows total hours for ALL projects under that client
-->
<template>
	<v-card :loading="$apollo.queries.projectHours.loading">

		<v-card-title class="flex-column">
            <div class="w-100 d-flex">
                <div class="mr-auto">Client Quoted Hours:</div>
                <div>{{ project.meta_client_quoted_hours }}</div>
            </div>
            <div class="w-100 d-flex">
                <div class="mr-auto">Hours:</div>
                <template v-if="projectHours">
                    <div v-if="dataRange == 0" title="time spent / time allocated" >{{minutesToHours(projectHours.time_spent)}} &frasl; {{project.hours_allocated}}</div>
                    <div v-else title="time spent" >{{minutesToHours(projectHours.time_spent)}}</div>
                </template>
            </div>
		</v-card-title>

		<v-card-text>
			<div class="d-flex justify-space-between flex-wrap">
				<v-btn-toggle mandatory v-model="dataStyle" class="mb-2">
					<v-btn v-for="option in $options.departmentOptions" :key="option.value" x-small :value="option.value" class="text-none">
						{{option.text}}
					</v-btn>
				</v-btn-toggle>

				<v-btn-toggle mandatory v-model="dataRange" class="mb-2 flex-wrap">
					<v-btn v-for="(option, key) in $options.dateRangeOptions" :key="key" x-small :value="option.value" class="text-none">
						{{option.text}}
					</v-btn>
				</v-btn-toggle>
			</div>
			<div v-if="projectHours" class="mt-2">
				<template v-if="dataStyle == 'departments'">
					<div v-if="projectHours.analytics.time_spent_by_department && projectHours.analytics.time_spent_by_department.length">
						<div v-for="item in projectHours.analytics.time_spent_by_department" :key="item.department" class="mb-1">
							{{minutesToHours(item.time_spent)}} - {{ percent(minutesToHours(item.time_spent), total_hours) }} {{item.department}}
							<v-progress-linear :value="percent(minutesToHours(item.time_spent), total_hours)" :color="item.department.toLowerCase()"></v-progress-linear>
						</div>
					</div>
					<div v-else>No time spent</div>
				</template>
				<template v-else>
					<div v-if="projectHours.analytics.time_spent_by_user && projectHours.analytics.time_spent_by_user.length">
						<div v-for="item in projectHours.analytics.time_spent_by_user" :key="item.department" class="mb-1">
							{{minutesToHours(item.time_spent)}} - {{ percent(minutesToHours(item.time_spent), total_hours) }} {{item.user.full_name}}
							<v-progress-linear :value="percent(minutesToHours(item.time_spent), total_hours)" :color="item.user.department.toLowerCase()"></v-progress-linear>
						</div>
					</div>
					<div v-else>No time spent</div>
				</template>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import moment from "moment";
	import { timeInterval } from '../../../utils/timeInterval';
	export default {
		name: "hours",

		inject: ['projectContext'],

		data() {
			return {
				onProject: this.$route.name === "project.details",
				onClient: this.$route.name === "client.details",

				dataStyle: "departments",
				dataRange: null,
			};
		},

		apollo: {
			projectHours: {
				query() {
					if (this.dataStyle == "departments") {
						return gql`
							query ProjectHoursByDepartment(
								$id: ID!
								$after: DateTime
							) {
								projectHours: project(id: $id) {
									id
									time_spent(after: $after)
									analytics {
										time_spent_by_department(after: $after) {
											department
											time_spent
										}
									}
								}
							}
						`;
					}

					return gql`
						query ProjectHoursByUsers($id: ID!, $after: DateTime) {
							projectHours: project(id: $id) {
								id
								time_spent(after: $after)
								analytics {
									time_spent_by_user(after: $after)
										@sort(
											param: [
												{ field: "time_spent", order: desc }
											]
										) {
										user {
											id
											full_name
											department
										}
										time_spent
									}
								}
							}
						}
					`;
				},
				variables() {
					return {
						id: this.project.id,
						after: this.dataRange || null,
					};
				},
				skip() {
					return !this.project;
				},
				// pollInterval: timeInterval('5 minutes'),
			},
		},

		departmentOptions: [
			{
				value: "departments",
				text: "Departments",
			},
			{
				value: "users",
				text: "Users",
			},
		],

		dateRangeOptions: [
			{
				value: "",
				text: "All Time",
			},
			{
				value: moment().startOf("week").format("YYYY-MM-DD"),
				text: "This Week",
			},
			{
				value: moment().startOf("month").format("YYYY-MM-DD"),
				text: "This Month",
			},
		],

		computed: {
			project() {
				return this.projectContext.project;
			},

			// timeData() {
			// 	return [];
			// 	let methodCat = this.onClient ? "clients" : "projects",
			// 		method =
			// 			this.dataStyle == "departments"
			// 				? "hoursByDepartment"
			// 				: "hoursByUser";
			// 	return this.$insight[methodCat][method](
			// 		this.model,
			// 		this.dataRange,
			// 		true
			// 	);
			// },

			total_hours() {
				if (!this.project.time_spent) {
					return 0;
				}

				let time_spent = this.project.time_spent / 3600;

				if (time_spent >= 100) {
					return time_spent.toFixed(0);
				}

				return time_spent.toFixed(1);
			},

			// percentOfAllocatedTimeUsed() {
			// 	if (parseFloat(this.model.hours_allocated) > 0) {
			// 		return (
			// 			Math.round(
			// 				(this.total_hours / this.model.hours_allocated) * 100
			// 			) + "%"
			// 		);
			// 	} else {
			// 		return "inf";
			// 	}
			// },
			// hoursAllocated() {
			// 	let hoursAllocated = parseFloat(this.model.hours_allocated);
			// 	if (hoursAllocated > 0) {
			// 		return hoursAllocated.toFixed(0);
			// 	} else {
			// 		return 0;
			// 	}
			// },
			// hasHoursAllocated() {
			// 	return parseFloat(this.model.hours_allocated) > 0;
			// },
		},

		methods: {
			minutesToHours(time) {
				return (time / 3600).toFixed(1);
			},

			percent(amount, total, suffix = "%", decimals = 0) {
				let percent = 0;
				if (total !== 0) {
					percent = (100 * parseFloat(amount)) / parseFloat(total);
				}

				return percent.toFixed(decimals) + suffix;
			},
		},
	};
</script>
