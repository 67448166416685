<template>
	<div>
		<v-card class="mb-4" :loading="loading">
			<v-card-title>Project Calendar</v-card-title>
			<v-card-text>
				<v-toolbar flat>
					<v-btn fab text small color="primary" @click="$refs.calendar.prev()">
						<v-icon small>
							fas fa-chevron-left
						</v-icon>
					</v-btn>
					<v-btn fab text small color="primary" @click="$refs.calendar.next()">
						<v-icon small>
							fas fa-chevron-right
						</v-icon>
					</v-btn>
					<v-toolbar-title class="ml-5">
						{{ calendarTitle }}
					</v-toolbar-title>
				</v-toolbar>

				<v-calendar ref="calendar" type="month" color="primary" v-model="value" :events="getNotes" :event-overlap-mode="mode" @click:event="showEvent" @change="calendarChange">
					<template v-slot:event="{ event }">
						<v-icon v-if="event.type == 'Task' && event.status != 'complete' && event.estimated_completion_date > event.timeline_due_date" dark x-small class="ml-2 mb-1">fad fa-fire</v-icon>
						<v-icon v-if="event.type == 'Task' && event.status == 'complete'" dark x-small class="ml-2 mb-1">fad fa-check</v-icon>
						<span class="ml-1">{{event.name}}</span>
					</template>
				</v-calendar>

				<v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x max-width="400">
					<v-card v-if="selectedEvent" color="button" min-width="350px" flat>
						<v-toolbar dark :color="selectedEvent.color">
							<v-toolbar-title v-if="selectedEvent.type == 'Note'">
								{{selectedEvent.note_type.charAt(0).toUpperCase() + selectedEvent.note_type.slice(1)}}
							</v-toolbar-title>
							<v-toolbar-title v-else>
								{{selectedEvent.type}}: {{selectedEvent.status}}
							</v-toolbar-title>
						</v-toolbar>
						<v-card-text>
							<h2 v-html="selectedEvent.name"></h2>
							<p v-if="selectedEvent.description" v-html="'Description: ' + selectedEvent.description" class="body-1 mt-1"></p>
							<div v-if="selectedEvent.type == 'Task'">
								<v-alert v-if="pastDue(selectedEvent)" border="left" type="error" icon="fad fa-fire">{{pastDue(selectedEvent)}}</v-alert>
								<p>Required Days: {{selectedEvent.required_days}}</p>
								<p>Assigned to: 
									<template v-if="selectedEvent.assigned_to">{{selectedEvent.assigned_to.full_name}}</template>
									<template v-else>[NONE]</template>
								</p>
							</div>
						</v-card-text>
						<v-card-actions>
							<v-btn @click="selectedOpen = false" class="ml-auto">
								Close
							</v-btn>
							<v-btn v-if="selectedEvent.type == 'Note'" color="primary" @click="editNote(selectedEvent.id)">
								Edit {{selectedEvent.note_type}}
							</v-btn>
							<v-btn v-if="selectedEvent.type == 'Task'" color="primary" @click="editTask(selectedEvent.id)">
								Edit Task
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>

			</v-card-text>
		</v-card>

		<v-card class="mb-4">
			<v-card-title>Project List View <v-switch v-model="showPastItems" label="Show Past Items" class="ml-auto mt-1 mb-0"></v-switch></v-card-title>
			<v-card-text>

				<div v-for="(day, i) in timelineData" :key="i">
					<v-row flat class="mb-6">
						<v-col cols="auto" class="d-flex flex-column justify-start align-end">
							<div class="date-box pa-2 px-sm-3 text-center d-flex flex-column justify-center">
								<div class="text-h5 text-uppercase">{{day.key | formatDate('MMM')}}</div>
								<div class="text-h3">{{day.key | formatDate('DD')}}</div>
								<div class="text-h6">{{day.key | formatDate('YYYY')}}</div>
							</div>
						</v-col>
						<v-col>
							<v-card v-for="event in day.group" :key="event.id" class="mb-2" :color="event.color" flat dark>
								<v-card-title>
									<span v-if="event.type == 'Note'" class="mr-2">{{event.note_type.toUpperCase()}}: </span>
									<v-icon v-if="event.icon" small class="mr-2">{{event.icon}}</v-icon>
									{{event.name}}
									<v-btn :light="!$vuetify.theme.dark" v-if="event.type == 'Note'" @click="editNote(event.id)" class="ml-auto" min-width="0">
										<v-icon small>fad fa-edit</v-icon>
									</v-btn>
									<v-btn :light="!$vuetify.theme.dark" v-if="event.type == 'Task'" @click="editTask(event.id)" class="ml-auto" min-width="0">
										<v-icon small>fad fa-edit</v-icon>
									</v-btn>
								</v-card-title>
								<v-card-subtitle v-if="event.type == 'Task'">
									Assigned to: {{event.assigned_to ? event.assigned_to.full_name : 'NOT SET'}}
								</v-card-subtitle>
								<v-card-text v-if="event.description || event.type == 'Task'">
									<p v-if="event.description" v-html="event.description"></p>
									<div v-if="event.type == 'Task'">
										<v-alert v-if="pastDue(event)" border="left" type="error" icon="fad fa-fire">{{pastDue(event)}}</v-alert>
									</div>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</div>

			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import moment from "moment";

	export default {
		name: "projectCalendar",

		components: {},
		data() {
			return {
				calendarTitle: '',
				calendarStart: null,
				calendarEnd: null,

				mode: "column", // stack || column
				value: "",
				selectedEvent: null,
				selectedElement: null,
				selectedOpen: false,
				showPastItems: false,
			};
		},
		inject: ['projectContext'],

		apollo: {
			deadlinesAndReminders: {
				query: gql`
					query getCalendarDeadlinesAndReminders($filterParam: QueryFilter!) {
						deadlinesAndReminders: notes
							@filter(param: $filterParam)
						{
							nodes {
								node {
									id
									content
									modified_date
									date
									note_type
									author {
										id
										uid
										full_name
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: 'linked_object.uid',
									op: '=',
									value: this.project.uid
								},
								{
									field: "note_type",
									op: "in",
									value: ["deadline", "reminder"],
								},
								// {
								// 	field: 'date',
								// 	op: '>=',
								// 	value: this.calendarStart
								// },
								// {
								// 	field: 'date',
								// 	op: '<',
								// 	value: this.calendarEnd
								// }
							]
						},
					};
				},
				skip() {
					return !this.project || !this.calendarStart || !this.calendarEnd;
				}
			},
			tasks: {
				query: gql`
					query getProjectCalendarTasks($filterParam: QueryFilter!) {
						tasks
							@filter(param: $filterParam)
						{
							nodes {
								node {
									id
									uid
									name
									complete_date
									estimated_completion_date
									required_days
									assigned_by {
										id
										uid
										full_name
										department
									}
									assigned_to {
										id
										uid
										full_name
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: 'project.uid',
									op: '=',
									value: this.project.uid
								},
								// {
								// 	joinOp: 'or',
								// 	filters: [
								// 		{
								// 			joinOp: 'and',
								// 			filters: [
								// 				{
								// 					field: 'status',
								// 					op: '=',
								// 					value: 'complete'
								// 				},
								// 				{
								// 					field: 'complete_date',
								// 					op: '>=',
								// 					value: this.calendarStart
								// 				},
								// 				{
								// 					field: 'complete_date',
								// 					op: '<',
								// 					value: this.calendarEnd
								// 				}
								// 			]
								// 		},
								// 		{
								// 			joinOp: 'and',
								// 			filters: [
								// 				{
								// 					field: 'status',
								// 					op: '!=',
								// 					value: 'complete'
								// 				},
								// 				{
								// 					field: 'estimated_completion_date',
								// 					op: '>=',
								// 					value: this.calendarStart
								// 				},
								// 				{
								// 					field: 'estimated_completion_date',
								// 					op: '<',
								// 					value: this.calendarEnd
								// 				}
								// 			]
								// 		}
								// 	]
								// }
							]
						},
					};
				},
				skip() {
					// return !this.project || !this.calendarStart || !this.calendarEnd;
					return !this.project;
				}
			}
		},
		/* props: {
			project: {
				type: Object,
				default() {
					return this.injectedProject;
				},
			},
		}, */

		mounted() {
			this.$refs.calendar.checkChange();

			this.$watch(
				"$refs.calendar.title",
				(title) => {
					this.calendarTitle = title;
				},
				{ immediate: true }
			);
		},
		methods: {
			calendarChange({ start, end }) {
				this.calendarStart = moment(start.date).day(0).format('YYYY-MM-DD');
				this.calendarEnd = moment(end.date).day(7).format('YYYY-MM-DD');
			},
			showEvent({ nativeEvent, event }) {
				const open = () => {
					this.selectedEvent = event;
					this.selectedElement = nativeEvent.target;

					setTimeout(() => {
						this.selectedOpen = true;
					}, 10);
				};
				if (this.selectedOpen) {
					this.selectedOpen = false;
					setTimeout(open, 10);
				} else {
					open();
				}
				nativeEvent.stopPropagation();
			},
			editNote(note_id) {
				this.$modalService
					.create("noteAddEdit", {
						title: (note) => `Update ${note.note_type}`,
						noteId: note_id,
					})
					.on("save", (e, note) => {
						this.$db.addModels("note", note, "timeline");
					});
			},
			editTask(task_id) {
				this.$xhrRequest
					.send("get", `/api/task/${task_id}`)
					.then((task) => {
						this.$modalService
							.create("taskAddEdit", {
								taskId: task.id,
							})
							.on("save", (e, task) => {
								this.$db.addModels("task", task, "timeline");
							});
					});
			},
			pastDue(task) {
				let days = this.$insight.tasks.taskOverdueDays(task);
				return days > 0
					? `Past due ${days + (days === 1 ? " day" : " days")}`
					: null;
			},
		},
		computed: {
			project() {
				return this.projectContext.project;
			},
			loading() {
				return !this.deadlinesAndReminders || !this.tasks;
			},
			// getNotes() {
			// 	let notes = this.$db
			// 		.findModels("note", (note) => {
			// 			return (
			// 				note.object_type === "Project" &&
			// 				note.object_id === this.project.id &&
			// 				["deadline", "reminder"].includes(note.note_type)
			// 			);
			// 		})
			// 		.map((item) => {
			// 			let newitem = {
			// 				name: item.content,
			// 				start: item.date,
			// 				color: item.note_type,
			// 				timed: false,
			// 				type: "Note",
			// 			};
			// 			return { ...newitem, ...item };
			// 		});

			// 	let taskIds = this.tasks.map((task) => task.id);
			// 	let tasks = this.$db
			// 		.findModels("task", (task) => {
			// 			return taskIds.includes(task.id);
			// 		})
			// 		.map((item) => {
			// 			let icon = "";
			// 			if (
			// 				item.status != "complete" &&
			// 				item.estimated_completion_date > item.timeline_due_date
			// 			) {
			// 				icon = "fad fa-fire";
			// 			} else if (item.status == "complete") {
			// 				icon = "fad fa-check";
			// 			}
			// 			let newitem = {
			// 				start:
			// 					item.complete_date ||
			// 					item.estimated_completion_date,
			// 				color: "secondary", //item.status == 'complete' ? 'completed' : 'grey darken-2',
			// 				timed: false,
			// 				type: "Task",
			// 				icon: icon,
			// 			};
			// 			return { ...newitem, ...item };
			// 		});
			// 	// sorting in case we want to list by day later
			// 	let calendarData = [...notes, ...tasks]
			// 		// some tasks dont have dates. HOW?
			// 		.filter((task) => task.start != null)
			// 		.sort((a, b) => {
			// 			return a.start.localeCompare(b.start);
			// 		});
			// 	return calendarData;
			// },
			getNotes() {
				if (this.loading) {
					return [];
				}

				let notes = Linq.from(this.deadlinesAndReminders.nodes)
					.select(row => row.node)
					.select(note => ({
						name: note.content,
						start: note.date,
						color: note.note_type,
						timed: false,
						type: 'Note',
						...note
					}));

				let tasks = Linq.from(this.tasks.nodes)
					.select(row => row.node)
					.select(task => {
						let icon = "";
						if (task.status != "complete" && task.estimated_completion_date > task.timeline_due_date) {
							icon = "fad fa-fire";
						} else if (task.status == "complete") {
							icon = "fad fa-check";
						}


						return {
							start:
								task.complete_date ||
								task.estimated_completion_date,
							color: "secondary", //task.status == 'complete' ? 'completed' : 'grey darken-2',
							timed: false,
							type: "Task",
							icon,
							...task
						};
					});

				return notes.concat(tasks).toArray();
			},
			timelineData() {
				let data = Linq.from(this.getNotes);

				if (!this.showPastItems) {
					let today = moment().format("YYYY-MM-DD");
					data = data.where(
						(i) =>
							i.start >= today ||
							(i.type == "Task" && i.status != "complete")
					);
				}

				data = data.groupBy((i) => i.start.substr(0, 10)).toArray();

				return data;
			},
			// tasks() {
			// 	return this.$db.findModels("task", (task) => {
			// 		return task.project_id == this.project.id;
			// 	});
			// },
		},
	};
</script>

<style scoped lang="scss">
	::v-deep .v-calendar-weekly__day {
		min-height: 120px;
	}
	::v-deep .v-event {
		margin-left: auto;
		margin-right: auto;
	}
	.date-box {
		border: 1px solid gray;
	}
</style>