<template>

	<v-list nav dense>
		<template v-for="(item, key) in items">
			<v-divider v-if="item == 'divider'" :key="key"></v-divider>
			<div v-else :key="key">
				<template v-for="(i, k) in item">
					<v-list-item v-if="!i.condition || i.condition()" :key="k" :to="i.to">
						<v-list-item-icon>
							<v-icon>{{i.icon}}</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							{{i.title}}
						</v-list-item-title>
					</v-list-item>
				</template>
			</div>
		</template>
	</v-list>

</template>

<script>
	export default {
		name: "mobileNav",

		data() {
			return {
				fab: false,
				seePermissions: false,
			};
		},

		props: {
			items: Array,
		},

		methods: {},
	};
</script>