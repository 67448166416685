<template>
	<v-btn small @click="showNoteModal" :title="`${noteId ? 'Edit' : 'Add'} ${noteType2}`" min-width="0" class="pl-1 pr-2" :color="color">
		<slot>
			<span class="fa-stack">
				<v-icon small>fas fa-comment</v-icon>
				<v-icon v-if="noteId" class="tiny" color="grey">fas fa-pencil</v-icon>
				<v-icon v-else class="tiny" :color="tinyColor">fas fa-plus</v-icon>
			</span>
			<span class="sr-only">{{noteId ? 'Edit' : 'Add'}} {{noteType2}}</span>
		</slot>
	</v-btn>
</template>

<script>
	import { IdType } from '../utils/IdType';

    export default {
        name: 'addNoteButton',

        props: {
            objType: {
                type: String,
                validator(val) {
                    return ['person', 'client', 'project', 'task'].indexOf(val) !== -1;
                }
            },

            objId: {
                type:    [Number, String],
                default: 0
            },

            // showType: {
            //     type:    Boolean,
            //     default: false
            // },

            noteType: {
                type:    String,
                default: 'internal'
            },

            noteId: {
                type: IdType,
				default: 0
            },

            db: {
                type:    String,
                default: ''
			},
			
			color: {
				type: String,
				default: ''
			},
			
			tinyColor: {
				type: String,
				default: 'grey'
			}
        },

        data() {
            return {}
        },
        computed: {
            noteType2() {
                if (this.noteType == 'deadline') {
                    return 'Deadline';
                }
                if (this.noteType == 'reminder') {
                    return 'Reminder';
                }
                return 'Note';
            },
        },
        methods:  {
            showNoteModal() {
                let options = {
                    objType:  this.objType,
                    objId:    this.objId,
                    noteType: this.noteType,
					noteId:   this.noteId
                };
                if (this.noteType === 'deadline') {
                    options.title = 'Add Deadline';
                }
                if (this.noteType === 'reminder') {
                    options.title = 'Add Reminder';
                }

				let modal = "noteAddEdit";
				if(this.objType == 'person') {
					modal = "personalNoteAddEdit";
				}

                this.$modalService.create(modal, options).on('save', (e, note) => {
                    if (this.db) {
                        this.$db.addModels('note', note, this.db);
                    }

                    this.$emit('save', note);
                });
            }
        }
    }
</script>
