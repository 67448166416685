<template>
	<v-card class="my-3" :loading="$apollo.queries.tasks.loading">
		<v-card-title class="justify-space-between">
			Tasks Analytics
			<v-btn text @click="$apollo.queries.tasks.refetch()">
				<v-icon small>fal fa-sync</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text v-if="allTasks">

			<v-toolbar class="mb-3" flat>
				<debounce-events :events="{ change: 750 }" v-slot="{ change }">
					<v-text-field  @input="change(() => searchTxt = $event)" label="Search" single-line hide-details clearable class="mr-3" ref="searchInput"></v-text-field>
				</debounce-events>
				<v-btn-toggle v-model="status" multiple class="ml-3">
					<v-btn v-for="item in projectStatusOptions" small :key="item" :value="item" min-width="0">
						{{ item }}
					</v-btn>
				</v-btn-toggle>
			</v-toolbar>

			<v-row dense class="faux-headers d-none d-md-flex" >
				<v-col md="3" class="pl-2">Client: Project</v-col>
				<v-col md="3">Task Name</v-col>
				<v-col md="1">Time Spent</v-col>
				<v-col md="1">First Timer</v-col>
				<v-col md="1">Last Timer</v-col>
				<v-col md="1">Days Worked On</v-col>
				<v-col md="1">Days Current</v-col>
				<v-col md="1">User(s)</v-col>
			</v-row>

			<v-row dense v-for="i in allTasks" :key="i.id" class="align-md-center pl-2">
				<v-col cols="12" md="3">
					<object-link :uid="i.project.client.uid">{{i.project.client.name}}</object-link>: 
					<object-link :uid="i.project.uid">{{i.project.name}}</object-link>
				</v-col>
				<v-col cols="12" md="3"><strong class="d-md-none">Task: </strong>{{i.name}}</v-col>
				<v-col cols="12" md="1"><strong class="d-md-none">Time Spent: </strong>{{i.time_spent}}</v-col>
				<v-col cols="12" md="1"><strong class="d-md-none">Start: </strong>{{i.first_timer.creation_date | shortDate}}</v-col>
				<v-col cols="12" md="1"><strong class="d-md-none">Last: </strong>{{i.last_timer.creation_date | shortDate}}</v-col>
				<v-col cols="12" md="1"><strong class="d-md-none">Days Worked On: </strong>{{i.num_days_worked_on}}</v-col>
				<v-col cols="12" md="1">
					<strong class="d-md-none">Days Current: </strong>
					<v-tooltip v-if="i.days_current == 'unknown'" left>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on" class="d-block">
								{{i.days_current}}
							</span>
						</template>
						<span>Unable to calculate due to missing data</span>
					</v-tooltip>
					<template v-else>
						{{i.days_current}}
					</template>
				</v-col>
				<v-col cols="12" md="1">
					<template v-for="user in i.users_worked_on">
						<v-tooltip :key="user.uid" left>
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									<user-avataaar :uid="user.uid" style="max-width: 30px"></user-avataaar>
								</div>
							</template>
							<span>{{user.full_name}}</span>
						</v-tooltip>
					</template>
				</v-col>
			</v-row>

			<pager-buttons v-if="tasks" class="my-3 mr-3 d-flex align-center justify-end" v-model="page" :numberOfPages="numberOfPages"></pager-buttons>

		</v-card-text>
	</v-card>
</template>

<script>
	import PagerButtons from "../pager-buttons.vue";
	import objectLink from '@/components/objectLink';
	import userAvataaar from '@/components/avataaars/user-avataaar.vue';
	import moment from "moment";

	export default {
		name: "tasksAnalytics",
		components: { PagerButtons, objectLink, userAvataaar },
		data() {
			return {
				page: 1,
				projectStatusOptions: ["current", "complete"],
				itemsPerPage: 10,
				page: 1,
				searchTxt: "",
				status: ["current"],
			};
		},
		apollo: {
			tasks: {
				query: gql`
					query getTasks(
						$filterParam: QueryFilter!
						$limit: Int
						$offset: Int
					) {
						tasks
							@filter(param: $filterParam)
							@page(
								limit: $limit
								offset: $offset
							) # @sort(param: [{ field: "last_timer.creation_date", order: asc }])
						{
							total
							nodes {
								node {
									id
									uid
									name
									meta_milestone
									timeline_due_date
									status
									complete_date
									current_status_date
									first_timer {
										creation_date @formatDate(format: "Y-m-d")
									}
									last_timer {
										creation_date @formatDate(format: "Y-m-d")
									}
									num_days_worked_on
									time_spent @formatDuration(format: "H.2")
									users_worked_on {
										uid
										full_name
									}
									project {
										uid
										name
										client {
											uid
											name
										}
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "status",
							op: "in",
							value: this.status,
						},
						{
							field: "timers.id",
							op: "!=",
							value: null,
						},
					];

					if (this.searchTxt) {
						filters.push({
							joinOp: 'or',
							filters: [
								{
									field: "name",
									op: "like",
									value: `%${this.searchTxt}%`,
								},
								{
									field: "project.name",
									op: "like",
									value: `%${this.searchTxt}%`,
								},
								{
									field: "project.client.name",
									op: "like",
									value: `%${this.searchTxt}%`,
								},
								{
									field: "timers.user.full_name",
									op: "like",
									value: `%${this.searchTxt}%`,
								},
							]
						});
					}

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
						limit: this.itemsPerPage,
						offset: (this.page - 1) * this.itemsPerPage,
					};
				},
			},
		},
		computed: {
			numberOfPages() {
				if (!this.tasks) {
					return 1;
				}

				return Math.ceil(this.tasks.total / this.itemsPerPage);
			},

			allTasks() {
				if(!this.tasks || !this.tasks.nodes.length) {
					return [];
				}

				return Linq.from(this.tasks.nodes)
					.select((row) => row.node)
					.select(row => {
						let days_current = 'unknown';

						if(row.current_status_date) {
							let first = moment(row.current_status_date);

							if(row.status == 'complete') {
								
								days_current = moment(row.complete_date).diff(first, 'days')
								console.log('dff', days_current)
							} else {
								days_current = moment().diff(first, 'days')
							}
						}

						return {
							days_current,
							...row
						}
					})
					.toArray();
			}
		},
		watch: {
			status() {
				this.page = 1;
			},

			searchTxt() {
				this.page = 1;
			}
		}
	};
</script>