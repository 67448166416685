<template functional>
    <div class="item-extra-item mt-4 d-none d-md-block">
        <div class="card">
            <div class="card-header d-flex align-items-stretch">
                <div class="card-title">Assigned Users</div>
            </div>
            <div class="card-body p-0">
                <ul class="item-details-list list-group">
                    <li v-for="user in users" class="list-group-item d-flex align-items-sm-center flex-column flex-sm-row justify-content-between">
                        <div class="user-meta user-meta-account-executive d-sm-none d-md-inline" title="User Name">
                            <!--<router-link :to="{name:'employee.details', params:{id: user.id}}" >{{user | FullName}}</router-link>-->
                        </div>
                        <div class="user-meta user-meta-open-tasks" title="User Phone">
                            <a :href="user.phone | getPhoneLink">{{user.phone}}</a>
                        </div>
                        <div class="user-meta user-meta-email" title="User Email">
                            <a v-if="user.email" :href="'mailto:'+ user.email">{{user.email}}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'assignedUsers',

        props: {
            users: Array
        }
    }
</script>
