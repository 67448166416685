<template>
	<v-container fluid>
		<v-card :loading="$apollo.queries.persons.loading">
			<v-card-title>User Permissions 
				<v-btn small @click="$apollo.queries.persons.refetch()" class="ml-10">
					<v-icon small>fad fa-sync</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>

				<v-simple-table v-if="persons" dense>
					<template v-slot:default>
						<thead>
							<tr>
								<th>User</th>
								<th>Permissions</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(person, i) in persons.nodes" :key="i">
								<td>{{person.node.full_name}}</td>
								<td>
									<div v-if="person.node.permissions.length">
										<v-chip v-for="(perm, n) in person.node.permissions" :key="n" label small class="ma-1" :class="perm">
											{{perm}}
										</v-chip>
									</div>
									<div v-else>

									</div>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>

			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
	
	export default {
		name: "permissions",
		data() {
			return {
			};
		},
		apollo: {
			persons: {
				query: gql`
					query GetPersons($filterParam: QueryFilter!) {
						persons
							@filter(param: $filterParam)
							@sort(param: [{ field: "first_name", order: asc }]) {
							nodes {
								node {
									first_name
									full_name
									is_employee
									... on Employee {
										permissions
										inactive
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "is_employee",
							op: "=",
							value: true,
						},
					];

					filters.push({
						field: "inactive",
						op: "=",
						value: false,
					});

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.chuckit_dev {
		background-color: var(--v-developer-base) !important;
	}
</style>
