import moment from 'moment';
import store from '../store';

export function getDuration(timer, now = null) {
	if (timer.status != 'Active' && timer.time_spent) {
		return timer.time_spent;
	}

	let stop;
	if (timer.stop) {
		stop = moment(timer.stop);
	}
	else {
		if (!now) {
			now = store.state.app.currentTime;
		}

		stop = now;
	}

	return stop.diff(moment(timer.start), 'seconds');
}