import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import auth from './modules/auth'
import timers from './modules/timers'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
	},
	mutations: {
	},
	actions: {
		STORE_INIT() {

		}
	},
	modules: {
		app,
		auth,
		timers
	},
	plugins: [
		store => {
			store.dispatch('STORE_INIT', store);
		}
	],
	getters: {
		appReady(state) {
			return state.app.users && state.auth.user;
		}
	}
});

window.store = store;

export default store;