import Vue from 'vue'
import settings from "../../utils/settings";
import graphqlFetch from '../../graphql/fetch';

const startingSessionId = localStorage.getItem(settings.sessionIdCacheKey) || '';
if (startingSessionId) {
	$.ajaxSetup({
		headers: {
			'X-Auth': startingSessionId
		}
	});
}

const AUTH_PERSON_FIELDS_FRAGMENT = `
	id
	first_name
	last_name
	full_name
	email
	user_access
	theme
	animations
	department
	meta_quicktimer_options
	used_jobs
	custom_home
	permissions
	meta_avatar
`;

export default {
	state: {
		user: false,
		sessionId: startingSessionId,
		checking: true
	},
	mutations: {
		setUser(state, user) {
			state.user = user || false;
			state.checking = false;
		},

		patchUser(state, user) {
			if (!user || typeof user != 'object') {
				return;
			}

			Object.assign(state.user, user);
		},

		setSessionId(state, id) {
			state.sessionId = id;
			let headers = {};

			if (id) {
				headers['X-Auth'] = id;
				localStorage.setItem(settings.sessionIdCacheKey, id);
			}
			else {
				localStorage.removeItem(settings.sessionIdCacheKey);
			}

			$.ajaxSetup({
				headers
			});
		},

		updateUser(state, data) {
			for (let i in data) {
				Vue.set(state.user, i, data[i]);
			}
		}
	},
	actions: {
		checkAuth(context) {
			return new Promise((resolve) => {
				graphqlFetch(
					`#graphql
					query {
						currentUser {
							${AUTH_PERSON_FIELDS_FRAGMENT}
						}
					}`
				)
					.then(resp => {
						if (resp.data.currentUser) {
							context.commit('setUser', resp.data.currentUser);
							return resolve(context.state.user);
						}

						throw 'Not logged in';
					})
					.catch(e => {
						context.dispatch('handleLogin');
						resolve(false);
					});
			});
		},

		loginUser(context, data) {
			return new Promise((resolve, reject) => {
				const loginMethod = data.google_token
					? 'Google Login'
					: 'Email/Pass';
				
				graphqlFetch(
					`#graphql
					mutation LoginUser ($email: String $password: String $google_token: String) {
						loginUser(email: $email password: $password google_token: $google_token) {
							session_id,
							person {
								${AUTH_PERSON_FIELDS_FRAGMENT}
							}
						}
					}`,
					data
				)
					.then(resp => {
						if (resp.data.loginUser) {
							gtag('event', 'login', { 'method': loginMethod });
							// testing
							gtag('event', 'custom-login', {
								'event_category': loginMethod,
								'event_label': `User ${resp.data.loginUser.person.id}`
							});
							context.dispatch('handleLogin', resp.data.loginUser);
							return resolve(context.state.user);
						}
						else if (resp.errors) {
							return reject(resp.errors);
						}

						throw 'Unknown error';
					})
					.catch(e => {
						reject([{ type: 'error', message: e.message }]);
					});
			});
		},

		logoutUser(context) {
			return new Promise((resolve, reject) => {
				graphqlFetch(
					`#graphql
					mutation LogoutUser {
						logoutUser
					}
					`
				)
					.then(() => {
						google.accounts.id.disableAutoSelect();
						context.dispatch('handleLogin');
						resolve();
					})
					.catch(e => {
						reject([{ type: 'error', message: e.message }]);
					});
			});
		},

		handleLogin({ commit }, loginContext = {}) {
			commit('setUser', loginContext.person);
			commit('setSessionId', loginContext.session_id);
		},

		setTheme({ state, commit }, theme) {
			return new Promise((resolve, reject) => {
				let oldTheme = state.user.theme;
				state.user.theme = theme;

				graphqlFetch(
					`#graphql
					mutation UpdateTheme ($payload: UpdateEmployeeArgs!) {
						updateEmployee(data: $payload) {
							${AUTH_PERSON_FIELDS_FRAGMENT}
						}
					}`,
					{
						payload: {
							id: state.user.id,
							theme
						}
					}
				)
					.then(resp => {
						// TODO: Validate all graphql responses better
						if (resp.data.updateEmployee) {
							commit('patchUser', resp.data.updateEmployee);
						}
						else {
							state.user.theme = oldTheme;
						}
						resolve();
					})
					.catch(e => {
						reject(e);
					});
			});
		},

		setAnimationStatus({ state, commit }, animations) {
			return new Promise((resolve, reject) => {
				state.user.animations = animations;

				graphqlFetch(
					`#graphql
					mutation UpdateAnimationStatus ($payload: UpdateEmployeeArgs!) {
						updateEmployee(data: $payload) {
							${AUTH_PERSON_FIELDS_FRAGMENT}
						}
					}`,
					{
						payload: {
							id: state.user.id,
							animations
						}
					}
				)
					.then(resp => {
						// TODO: Validate all graphql responses better
						if (resp.data.updateEmployee) {
							commit('patchUser', resp.data.updateEmployee);
						}
						else {
							state.user.animations = !animations;
						}

						resolve();
					})
					.catch(e => {
						reject(e);
					});
			});
		}
	},
	getters: {
		isLoggedIn(state) {
			return !!state.user;
		},

		isNotLoggedIn(state) {
			return !state.user;
		},

		userData(state) {
			return state.user;
		},

		userId(state) {
			return state.user
				? state.user.id
				: 0;
		},

		userFirstName(state, getters) {
			return getters.isLoggedIn ? getters.userData.first_name : '';
		},

		userFullName(state, getters) {
			return getters.isLoggedIn ? getters.userData.full_name : '';
		},

		/**
		 * Returns email address if current user is an active employee.
		 * @param state
		 * @param getters
		 */
		employeeEmail(state, getters) {
			return state.user
				? state.user.email
				: '';
		},

		authReady(state) {
			return !state.checking;
		},

		showAuthForm(state, getters) {
			return getters.isNotLoggedIn && !state.checking;
		},

		isAdminUser(state, getters) {
			return getters.isLoggedIn ? getters.userData.user_access === 'Admin' : '';
		},

		userCustomHome(state, getters) {
			return getters.isLoggedIn ? getters.userData.custom_home || '/tasks' : '/tasks';
		},

		// TODO: Re-think this
		/**
		 * Permissions
		 * @param state
		 * @param getters
		 * @returns {boolean}
		 */
		hasPermission(state, getters) {
			let obj = getters.userData;
			return (permission, defaultPermission = false) => {
				if (!obj || !obj.permissions) {
					return defaultPermission;
				}

				return obj.permissions.indexOf(permission) >= 0;
			}
		}
	}
}
