<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card v-if="personData">
			<v-card-title>{{ isUpdate ? 'Edit' : 'Add' }} Employee</v-card-title>
			<v-card-text>

				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<v-row>
					<v-col>
						<v-text-field label="Login Name (email)" v-model="personData.login_name" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="First Name" v-model="personData.first_name" :disabled="saving" required dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field label="Last Name" v-model="personData.last_name" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="Cell Phone" v-model="personData.phone_primary" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field label="Phone Secondary" v-model="personData.phone_secondary" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="Office Phone" v-model="personData.phone_office" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field label="Fax" v-model="personData.phone_fax" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field type="email" label="Email" v-model="personData.email_primary" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
						<div class="search-indicator pr-2" :class="{searching:searching}">
							<v-icon class="my-2">fad fa-cog fa-spin</v-icon>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field type="email" label="Email Secondary" v-model="personData.email_secondary" required :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-select label="Custom Home" :items="homeOptions" v-model="personData.custom_home" :disabled="saving" dense hide-details outlined class="mt-3"></v-select>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="Department" v-model="personData.department" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-select label="Gender" :items="genderOptions" v-model="personData.gender" :disabled="saving" dense hide-details outlined class="mt-3"></v-select>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<template>
							<v-menu v-model="hiredCalendarMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y ref="hiredCalendarMenu" min-width="200px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="personData.hired_date" type="date" label="Hired Date" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details outlined dense class="mt-3"></v-text-field>
								</template>
								<v-date-picker v-model="personData.hired_date" @input="hiredCalendarMenu = false" header-color="primary" color="primary" show-adjacent-months no-title></v-date-picker>
							</v-menu>
						</template>
					</v-col>
					<v-col cols="12" sm="6">
						<template>
							<v-menu v-model="exitCalendarMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y ref="exitCalendarMenu" min-width="200px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="personData.exit_date" type="date" label="Exit Date" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details outlined dense class="mt-3"></v-text-field>
								</template>
								<v-date-picker v-model="personData.exit_date" @input="exitCalendarMenu = false" header-color="primary" color="primary" show-adjacent-months no-title></v-date-picker>
							</v-menu>
						</template>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="QuickBooks Name" v-model="personData.quickbooks_name" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field label="QuickBooks Vendor Name" v-model="personData.quickbooks_vendor_name" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
				</v-row>

				<v-textarea label="Note" v-model="personData.note" :disabled="saving" rows="3" auto-grow outlined dense hide-details class="mt-3"></v-textarea>

				<v-divider></v-divider>
				<h2 class="my-3">Meta Fields</h2>
				<meta-input-list ref="metaFieldList" :model="personData" model-type="Person" :disabled="saving"></meta-input-list>

			</v-card-text>

			<v-card-actions>
				<div>Required field <sup class="error--text">*</sup></div>
				<div class="ml-auto">
					<v-btn @click="modal.trigger('close')" :disabled="saving" class="mr-3">Close</v-btn>
					<v-btn color="primary" @click="submit" :loading="saving">{{ isUpdate ? 'Update' : 'Create' }}</v-btn>
				</div>
			</v-card-actions>
		</v-card>

	</v-dialog>
</template>

<script>
	import MetaInputList from "../metaInputList";
	import { IdType } from "../../utils/IdType";

	const getSinglePersonQuery = gql`
		query GetSinglePerson($personId: ID!) {
			personDetails: person(id: $personId) {
				id
				login_name
				first_name
				last_name
				title
				is_employee
				note
				_meta_values
				phone
				phone_office
				phone_fax
				phone_primary
				phone_secondary
				email_primary
				email_secondary
				... on Employee {
					department
					custom_home
					hired_date
					exit_date
					gender
					quickbooks_name
					quickbooks_vendor_name
				}
			}
		}
	`;

	export default {
		name: "employeeAddEdit",
		components: { MetaInputList },
		props: {
			modal: {
				type: Object,
				required: true,
			},
			personId: {
				type: IdType,
				default: 0,
			},
			populate: {
				type: Object,
				default: () => ({}),
			},
		},
		apollo: {
			personDetails: {
				query: getSinglePersonQuery,
				variables() {
					return this.getQueryVariables();
				},
				skip() {
					return !this.realPersonId;
				},
			},
		},

		data() {
			return {
				modalErrorMessage: false,
				saving: false,
				existingContacts: [],
				hiredCalendarMenu: false,
				exitCalendarMenu: false,
				searching: false,
				homeOptions: [
					"/dashboard",
					"/tasks",
					"/client/list",
					"/tasks/overview",
					"/tasks/cards/",
					"/timeline",
					"/timers/overview",
					"/timers/weekly",
				],
				genderOptions: ["male", "female", "na"],
				realPersonId: this.personId,
				personData: null,
			};
		},

		computed: {
			isUpdate() {
				return this.realPersonId;
			},
			existingContactsFields() {
				return [
					{ text: "Full Name", value: "full_name" },
					{ text: "Phone", value: "phone_primary" },
					{ text: "Office Phone", value: "phone_office" },
					{ text: "", value: "id" },
				];
			},
		},
		watch: {
			personDetails(to) {
				this.copyData(to);
			},
			personId: {
				immediate: true,
				handler(to) {
					if (!to) {
						this.copyData(this.populate);
					}
				},
			},
		},

		methods: {
			copyData(graphQlData = {}) {
				let copyManyFn = (props, subKey = "") => {
					let source = graphQlData;
					if (subKey) {
						source = source[subKey] || {};
					}

					let result = {};
					for (let i of props) {
						result[i] = source.hasOwnProperty(i) ? source[i] : null;
					}

					return result;
				};

				let extraInfo = {
						...copyManyFn([
							"department",
							"custom_home",
							"hired_date",
							"exit_date",
							"gender",
							"quickbooks_name",
							"quickbooks_vendor_name",
						]),
					};

				this.personData = {
					...copyManyFn([
						"login_name",
						"login_password",
						"first_name",
						"last_name",
						"title",
						"is_employee",
						"note",
						"_meta_values",
						"phone",
						"phone_office",
						"phone_fax",
						"phone_primary",
						"phone_secondary",
						"email_primary",
						"email_secondary",
					]),
					...extraInfo,
				};
			},

			formModel() {
				let model = {
					title: this.personData.title,
					login_name: this.personData.login_name.trim(), 
					login_password: "", 
					first_name: this.personData.first_name.trim(),
					last_name: this.personData.last_name.trim(),
					phone_office: this.personData.phone_office,
					phone_fax: this.personData.phone_fax,
					phone_primary: this.personData.phone_primary,
					phone_secondary: this.personData.phone_secondary,
					email_primary: this.personData.email_primary,
					email_secondary: this.personData.email_secondary,
					note: this.personData.note,
					custom_home: this.personData.custom_home,
					department: this.personData.department,
					hired_date: this.personData.hired_date,
					exit_date: this.personData.exit_date,
					gender: this.personData.gender,
					quickbooks_name: this.personData.quickbooks_name,
					quickbooks_vendor_name: this.personData.quickbooks_vendor_name,
				};

				if (this.isUpdate) {
					Object.assign(model, { id: this.realPersonId });
				}

				Object.assign(model, this.$refs.metaFieldList.getFields());

				return model;
			},

			getQueryVariables() {
				return {
					personId: this.realPersonId,
				};
			},

			submit() {
				this.saving = true;

				if (this.isUpdate) {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: UpdateEmployeeArgs!) {
									updateEmployee(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: this.formModel(),
							},
							update: (store, { data: { updateEmployee } }) => {
								this.saving = false;
								this.modal.trigger("save close");
							},
						})
						.catch((error) => {
							console.error("error updating employee", error);
							this.modalErrorMessage = "There was a problem updating the data.";
						});
				} else {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: CreateEmployeeArgs!) {
									createEmployee(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: this.formModel(),
							},
							update: (store, { data: { createEmployee } }) => {
								this.saving = false;
								this.modal.trigger("save close", createEmployee);
							},
						})
						.catch((error) => {
							console.error("error adding employee", error);
							this.modalErrorMessage =
								"There was a problem creating the employee.";
						});
				}
			},

			existingContactSelected(row) {
				this.realPersonId = row.id;
			},
		},
	};
</script>

<style scoped lang="scss">
	.search-indicator {
		position: absolute;
		z-index: 9999;
		top: 0;
		bottom: 0;
		right: 0;

		i {
			visibility: hidden;
		}

		&.searching {
			i {
				visibility: visible;
			}
		}
	}
</style>
