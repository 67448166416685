const intervals = {
    'year': [365, 'day'],
    'month': [30, 'day'],
    'week': [7, 'day'],
    'day': [24, 'hour'],
    'hour': [60, 'minute'],
    'minute': [60, 'second'],
    'second': [1000, 'ms'],
    'millisecond': 1,
    'ms': 1
};

for (let i in intervals) {
    let key = i,
        result = 1;
    while (Array.isArray(intervals[key])) {
        result *= intervals[key][0];
        key = intervals[key][1];
    }

    if (key != i) {
        intervals[i] = result;
    }
}

const aliases = {
    'years': 'year',
    'months': 'month',
    'weeks': 'week',
    'days': 'day',
    'hours': 'hour',
    'minutes': 'minute',
    'tup': 'minute',
    'seconds': 'second',
    'milliseconds': 'millisecond'
};

let replaceRegex = new RegExp(
    Object.keys(aliases)
        .sort((a, b) => b.length - a.length)
        .join('|'),
    'gi'
);

let workRegex = new RegExp(
    '((?:-\\s*)?\\d+(?:\\.\\d+)?) +(' +
    Object.keys(intervals)
        .sort((a, b) => b.length - a.length)
        .join('|') +
    ')',
    'gi'
)

export function timeInterval(naturalLanguage) {
    let result = 0;
    naturalLanguage = naturalLanguage.replace(replaceRegex, (m) => {
        return aliases[m];
    });

    let match;
    while (match = workRegex.exec(naturalLanguage)) {
        result += parseFloat(match[1].replace(/\s/g, '')) * intervals[match[2]];
    }

    return result;
}

window.timeInterval = timeInterval;