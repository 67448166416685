<template>

	<div v-if="employees">

		<v-toolbar dense class="mb-6">
			<v-switch v-model="includePastEmployees" hide-details label="Include Past Employees"></v-switch>
			<v-chip class="ml-3" color="primary darken-1">{{employees.nodes.length}}</v-chip>
			<span v-if="includePastEmployees" class="ml-3">Only shows employees that existed post 2016 for now</span>
		</v-toolbar>

		<v-row class="mb-3">

			<v-col cols="12" lg="4">
				<!-- < 1 year, 1-3 years, 3-5 years, > 5 years -->
				<v-card class="fill-height">
					<v-card-title>Employee Average Years</v-card-title>
					<v-card-text>

						<v-row>
							<v-col cols="12" sm="5" class="d-flex flex-column align-center justify-center">
								<svg width="100%" height="100%" viewBox="0 0 42 42" class="donut" style="max-width:300px;">
									<circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>
									<circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="var(--v-cards-lighten1)" stroke-width="7"></circle>
									<!-- offset = All preceding segments’ total length = Current segment offset -->
									<circle v-if="employeeYears[0]" class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" :stroke="donutColors[0]" stroke-width="5"
											:stroke-dasharray="`${employeeYears[0].percentage} ${100 - employeeYears[0].percentage}`" stroke-dashoffset="0"></circle>
									<circle v-if="employeeYears[1]" class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" :stroke="donutColors[1]" stroke-width="5"
											:stroke-dasharray="`${employeeYears[1].percentage} ${100 - employeeYears[1].percentage}`" :stroke-dashoffset="- employeeYears[0].percentage"></circle>
									<circle v-if="employeeYears[2]" class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" :stroke="donutColors[2]" stroke-width="5"
											:stroke-dasharray="`${employeeYears[2].percentage} ${100 - employeeYears[2].percentage}`" :stroke-dashoffset="- employeeYears[0].percentage - employeeYears[1].percentage"></circle>
									<circle v-if="employeeYears[3]" class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" :stroke="donutColors[3]"  stroke-width="5"
											:stroke-dasharray="`${employeeYears[3].percentage} ${100 - employeeYears[3].percentage}`" :stroke-dashoffset="- employeeYears[0].percentage - employeeYears[1].percentage - employeeYears[2].percentage"></circle>
								</svg>
							</v-col>
							<v-col cols="12" sm="7" class="d-flex flex-column align-center justify-center">
								<div v-for="(item, index) in employeeYears" :key="index" class="mb-6">
									<v-chip dark class="mr-3" :style="`background-color: ${donutColors[index]}`">{{item.employees.length}}</v-chip> {{item.group.label}}
									<!-- <pre>{{item}}</pre> -->
								</div>
							</v-col>
						</v-row>

					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="8">
				<!-- department headocount -->
				<v-card class="fill-height">
					<v-card-title>Department Headcount</v-card-title>
					<v-card-text>
						<div class="dep-grid d-flex flex-wrap justify-space-around text-center">
							<div v-for="(item, index) in departmentHeadcount" :key="index" class="d-flex flex-column">
								<div class="body-1">{{item.department}}</div>
								<div class="flex-grow-1 d-flex align-center">
									<v-sheet dark :color="item.department | lowercase" class="hc pa-3 text-center d-flex flex-column align-center justify-center ma-3" :style="`--da-border: ${(item.total / employees.nodes.length * 100)}px`">
										<div class="text-h5 font-weight-bold">{{(item.total / employees.nodes.length * 100).toFixed()}}%</div>
										<div class="body-1">({{item.total}})</div>
									</v-sheet>
								</div>
							</div>
						</div>
					</v-card-text>
				</v-card>

			</v-col>

		</v-row>

		<v-card class="mb-6">
			<v-card-title>
				Gender
			</v-card-title>
			<v-card-text>
				<v-sheet class="d-flex mb-2" dark>
					<template v-for="(g, index) in gender">
						<v-sheet :key="index" :color="g.gender" role="progressbar" :width="(g.total / gender.length * 100) + '%'" class="py-3 d-flex flex-column text-center justify-content-center">
							<div class="text-sm-h3 font-weight-bold">{{g.total}}</div>
							<div class="label text-uppercase">
								<v-icon left small>
									<template v-if="g.gender == 'male'">
										fas fa-male
									</template>
									<template v-else-if="g.gender == 'female'">
										fas fa-female
									</template>
									<template v-else>
										fas fa-robot
									</template>
								</v-icon>
								{{g.gender}}
							</div>
						</v-sheet>
					</template>
				</v-sheet>
				<v-row dense>
					<v-col v-for="(d, i) in departmentGender" :key="i">
						<v-sheet class="col fill-height">
							<h2 class="mb-3">{{d.department}}</h2>
							<v-sheet dark class="d-flex mb-2">
								<template v-for="g in d.data">
									<v-sheet :key="g.gender" :color="g.gender" role="progressbar" :width="(g.total / d.total * 100) + '%'" class="py-3 text-center d-flex justify-center align-center">
										<div class="text-sm-h6 font-weight-bold">{{g.total}}</div>
										<div class="label text-uppercase">
											<v-icon x-small right>
												<template v-if="g.gender == 'male'">
													fas fa-male
												</template>
												<template v-else-if="g.gender == 'female'">
													fas fa-female
												</template>
												<template v-else>
													fas fa-robot
												</template>
											</v-icon>
										</div>
									</v-sheet>
								</template>
							</v-sheet>
						</v-sheet>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

	</div>
	<div v-else></div>

</template>

<script>
	
	import moment from "moment";
	import UserAvataaar from "@/components/avataaars/user-avataaar";

	export default {
		name: "overtime",
		components: { UserAvataaar },

		data() {
			return {
				includePastEmployees: false,
				donutColors: [
					"var(--v-admin-base)",
					"var(--v-developer-base)",
					"var(--v-error-base)",
					"var(--v-video-base)",
				],
			};
		},

		apollo: {
			employees: {
				query: gql`
					query getEmployees($filterParam: QueryFilter!) {
						employees: persons @filter(param: $filterParam) {
							sql
							nodes {
								node {
									id
									full_name
									... on Employee {
										inactive
										department
										hired_date #@formatDate(format: "YY")
										exit_date
										gender
									}
									# meta_avatar
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "is_employee",
							op: "=",
							value: true,
						},
						{
							field: "hired_date",
							op: "!=",
							value: null,
						},
					];

					if (!this.includePastEmployees) {
						filters.push({
							field: "inactive",
							op: "=",
							value: false,
						});
					}

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
		},

		

		employmentLengthGroups: [
			{ label: "< 1 year", min: 0, max: 1 },
			{ label: "1-3 years", min: 1, max: 3 },
			{ label: "3-5 years", min: 3, max: 5 },
			{ label: "> 5 years", min: 5, max: 100000 },
		],

		computed: {
			gender() {
				if (!this.employees) {
					return [];
				}

				return Linq.from(this.employees.nodes)
					.select(row => row.node)
					.groupBy(
						(item) => item.gender,
						(gender, data) => ({
							total: data.count(),
							gender: gender,
						})
					)
					.orderBy((i) => i.gender == "na")
					.thenBy((i) => i.gender == "female")
					.toArray();
			},

			employeeYears() {
				if (!this.employees) {
					return [];
				}

				let today = this.$store.getters.currentDay;

				// < 1 year, 1-3 years, 3-5 years, > 5 years
				return Linq.from(this.employees.nodes)
					.select(row => row.node)
					.where((e) => e.hired_date)
					.groupBy(
						(e) => {
							let exitDate = today;
							if (e.exit_date) {
								exitDate = moment(e.exit_date);
							}

							let years = exitDate.diff(
								moment(e.hired_date),
								"years",
								true
							);
							for (
								let i = 0;
								i < this.$options.employmentLengthGroups.length;
								i++
							) {
								if (
									years >=
										this.$options.employmentLengthGroups[i]
											.min &&
									years <
										this.$options.employmentLengthGroups[i].max
								) {
									return i;
								}
							}
						},
						(g, items) => ({
							groupNdx: g,
							group: this.$options.employmentLengthGroups[g],
							percentage: (
								(items.count() / this.employees.nodes.length) *
								100
							).toFixed(),
							employees: items.toArray(),
						})
					)
					.orderBy((r) => r.groupNdx)
					.toArray();
			},
			departmentGender() {
				if (!this.employees) {
					return [];
				}

				return Linq.from(this.employees.nodes)
					.select(row => row.node)
					.groupBy(
						(item) => item.department,
						(department, data) => ({
							department: department,
							total: data.count(),
							data: data
								.groupBy(
									(i) => i.gender,
									(gender, data) => ({
										total: data.count(),
										gender: gender,
									})
								)
								.orderBy((i) => i.gender == "na")
								.thenBy((i) => i.gender == "female")
								.toArray(),
						})
					)
					.orderBy((i) => i.department)
					.toArray();
			},
			departmentHeadcount() {
				if (!this.employees) {
					return [];
				}

				return Linq.from(this.employees.nodes)
					.select(row => row.node)
					.groupBy(
						(item) => item.department,
						(department, data) => ({
							department: department,
							total: data.count(),
						})
					)
					.orderBy((i) => i.department)
					.toArray();
			},
		},

		methods: {},
	};
</script>

<style lang="scss" scoped>
	.dep-grid {
		.hc {
			width: calc(90px + var(--da-border));
			height: calc(90px + var(--da-border));
			border-radius: 50%;
			position: relative;
			z-index: 3;
			&:hover {
				&:before {
					opacity: 0.5;
				}
			}
		}
	}

	.male {
		background-color: var(--v-developer-base);
		// background: linear-gradient(90deg, var(--v-developer-base) 20%, var(--v-developer-darken2) 100%);
	}
	.female {
		background-color: var(--v-completed-base);
		// background: linear-gradient(90deg, var(--v-completed-base) 20%, var(--v-completed-darken2) 100%);
	}
	.na {
		background-color: var(--v-primary-darken1);
	}
</style>