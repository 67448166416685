<template>
	<v-card class="mb-4">
		<v-card-title>
			Assigned Tasks
			<div class="ml-auto d-flex align-center">
				<div class="d-flex align-center caption">
					<v-sheet width="10" height="10" class="mr-2" color="assigned"></v-sheet> Incomplete
				</div>
				<div class="d-flex align-center caption ml-3">
					<v-sheet width="10" height="10" class="mr-2" color="completed"></v-sheet> Complete
				</div>
			</div>
		</v-card-title>
		<v-card-text>
			<GChart type="ColumnChart" v-if="chartData" :data="chartData" :options="chartOptions" class="chart fill-height" />
			<div v-else>There are no tasks for this project.</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import colors from "../../../plugins/vuetify/theme-dark";
	import { IdType } from "../../../utils/IdType";

	export default {
		// @see https://developers-dot-devsite-v2-prod.appspot.com/chart/interactive/docs/gallery/columnchart.html#configuration-options
		name: "chartAssignedTasksByDepartment",
		props: {
			pid: IdType,
		},
		data() {
			return {
				chartOptions: {
					chartArea: {
						width: "100%",
						height: "90%",
						top: 0,
					},
					backgroundColor: { fillOpacity: 0 },

					//isStacked = true|percent|relative
					isStacked: true,
					height: "100%",
					// width: 887,
					legend: { position: "none" },
					// bar: {groupWidth: '90%'},
					hAxis: {
						textPosition: "out",

						textStyle: {
							color: colors["text-color"],
							bold: true,
						},
					},
					vAxis: {
						baselineColor: colors.dividers,
						textPosition: "none",
						gridlines: {
							minSpacing: 25,
							color: colors.dividers,
						},
						minorGridlines: {
							// deprecated but will force gridlines to disappear
							count: 0,
							color: colors.dividers,
						},
					},
					series: {
						0: { color: colors.completed },
						1: { color: colors.assigned },
					},
				},
			};
		},
		apollo: {
			taskDepartmentCounts: {
				fetchPolicy: 'no-cache',
				query: gql`
					query GetTaskDepartmentCounts ($filterParam: QueryFilter! $groupFields: [String!]!) {
						taskDepartmentCounts: tasks
							@filter(param: $filterParam)
							@groupBy(fields: $groupFields)
						{
							nodes {
								group_total
								node {
									status
									assigned_to {
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							field: 'project.id',
							op: '=',
							value: this.pid
						},
						groupFields: [
							'assigned_to.department',
							'status'
						]
					}
				},
				skip() {
					return !this.pid;
				},
				pollInterval: timeInterval("5 minutes"),
			},
		},
		computed: {
			chartData() {
				if(!this.taskDepartmentCounts || !this.taskDepartmentCounts.nodes.length) {
					return null;
				}

				return Linq.from(this.taskDepartmentCounts.nodes)
					.groupBy(
						(i) => i.node.assigned_to
							? i.node.assigned_to.department
							: 'NOT ASSIGNED',
						(department, items) => {
							let statuses = items.aggregate((i, acc) => {
								let key = i.node.status == 'complete'
									? 'complete'
									: 'incomplete';

								acc[key] += i.group_total;
								return acc;
							}, { complete: 0, incomplete: 0 });

							return [
								department,
								...Linq.from(statuses)
									.orderBy(i => i.key)
									.select(i => i.value)
									.toArray()
							];
						}
					)
					.orderBy(i => i[0] == 'NOT ASSIGNED')
					.thenBy(i => i[0])
					.prepend(['Department', 'Completed', 'Incomplete'])
					.toArray();
			},
			// return [
			//   ['Developer', 10, 1],
			//   ['IT', 2, 4],
			//   ['Design', 1, 2]
			// ]
		},
	};
</script>

<style lang="scss" scoped>
	.chart >>> {
		text {
			transform: translateY(5px);
		}
	}
</style>
