<template>
	<v-card>
		<v-card-title>
			Milestones
		</v-card-title>
		<v-card-subtitle>
			Showing <v-chip label dark x-small color="tertiary">{{taskWithMilestoneCount}}</v-chip> tasks with milestones out of
			<v-chip label dark x-small color="tertiary">{{taskCount}}</v-chip> total tasks.
		</v-card-subtitle>
		<v-card-text>
			<GChart v-if="tasks.length" :settings="{ packages: ['timeline'] }" type="Timeline" :data="chartData" :options="chartOptions" />
			<div v-else>Please set some task milestones.</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import { IdType } from "../../../utils/IdType";
	import { timeInterval } from "../../../utils/timeInterval";
	export default {
		name: "chartProjectMilestone",
		data() {
			return {
				taskWithMilestoneCount: 0,
				taskCount: 0,
			};
		},
		props: {
			pid: IdType,
		},
		inject: ["projectContext"],
		apollo: {
			projectMilestones: {
				query: gql`
					query GetProject_Milestone($filterParam: QueryFilter!) {
						projectMilestones: tasks @filter(param: $filterParam) {
							nodes {
								node {
									id
									name
									meta_milestone
									metatype_milestone {
										options
									}
									start_date
									estimated_completion_date
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "project_id",
									op: "=",
									value: this.pid,
								},
								{
									field: "meta_milestone",
									op: "!=",
									value: null,
								},
							],
						},
					};
				},
				skip() {
					return !this.pid;
				},
				pollInterval: timeInterval("5 minutes"),
			},
			milestoneOptions: {
				query: gql`
					query getMilestoneOptions($machine_name: String!) {
						milestoneOptions: metaField(machine_name: $machine_name) {
							id
							options
						}
					}
				`,
				variables() {
					return {
						machine_name: "meta_milestone",
					};
				},
			},
		},

		computed: {
			milestones() {
				if (!this.milestoneOptions) {
					return [];
				}

				return this.milestoneOptions.options;
			},
			chartOptions() {
				return {
					chart: {
						title: "Milestone",
					},
					colors: [
						this.$vuetify.theme.currentTheme["admin"],
						this.$vuetify.theme.currentTheme["designer"],
						this.$vuetify.theme.currentTheme["designer"],
						this.$vuetify.theme.currentTheme["developer"],
						this.$vuetify.theme.currentTheme["developer"],
						this.$vuetify.theme.currentTheme["it"],
						this.$vuetify.theme.currentTheme["admin"],
					],
					backgroundColor: {
						fill: this.$vuetify.theme.currentTheme["background"],
						opacity: 0.5,
					},
					height: 400,
					timeline: {
						colorByRowLabel: true,
						rowLabelStyle: {
							color: this.$vuetify.theme.currentTheme["anchor"],
						},
					},
				};
			},
			chartData() {
				let headers = [
					[
						{ type: "string", label: "Milestone" },
						{ type: "string", label: "Task" },
						{ type: "date", label: "Start" },
						{ type: "date", label: "End" },
					],
				];

				let tasks = this.tasks;
				return headers.concat(tasks);
			},
			tasks() {
				if (!this.projectMilestones) {
					return [];
				}
				let tasks = this.projectMilestones.nodes;

				this.taskCount = this.projectMilestones.nodes.length;

				// get only tasks with milestones set
				tasks = tasks
					.map((row) => row.node)
					.filter((task) => task.meta_milestone)
					// sort in the order specified by the options IE Project Setup, Wireframe, Design etc...
					.sort((a, b) => {
						return (
							this.milestones.indexOf(a.meta_milestone) -
							this.milestones.indexOf(b.meta_milestone)
						);
					})
					.map((task) => {
						let newTask = [
							task.meta_milestone,
							task.name,
							new Date(task.start_date),
							new Date(task.estimated_completion_date),
						];
						if (newTask[3] < newTask[2]) {
							newTask[3] = newTask[2];
						}
						return newTask;
					});
				this.taskWithMilestoneCount = tasks.length;
				return tasks;
			},
		},
	};
</script>

<style lang="scss" scoped>
	// Horizontal Labels
	// ::v-deep g:nth-child(3),
	// ::v-deep g:nth-child(2) {
	// 	text {
	// 		fill: var(--v-anchor-base);
	// 	}
	// }

	::v-deep {
		text {
			fill: var(--v-anchor-base);
		}
	}
</style>