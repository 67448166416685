<template>
	<v-container fluid>

		<v-card class="mb-4" :loading="$apollo.queries.persons.loading">
			<v-card-title class="d-flex flex-wraps">
				People / Contacts <template v-if="persons">({{persons.total}})</template>
				<v-btn small @click="$apollo.queries.persons.refetch()" class="ml-auto">
					<v-icon small>fad fa-sync</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<debounce-events :events="{ input: 500 }" v-slot="{ input }">
					<v-text-field :value="searchTxt" @input="input(() => searchTxt = $event)" label="Search" dense single-line hide-details clearable></v-text-field>
				</debounce-events>
			</v-card-text>
		</v-card>

		<v-data-table v-if="persons" :headers="headers" hide-default-footer :items="personNodes" disable-pagination class="user-table" mobile-breakpoint="1200">

			<template v-slot:item.actions="{ item }">
				<v-btn small :to="{ name: 'contact.details', params: { id: item.id } }" min-width="0" class="ml-auto">
					<v-icon small>fad fa-link</v-icon>
				</v-btn>
				<v-btn small title="Edit Person" @click="editPerson(item.id)" min-width="0" class="ml-2">
					<v-icon small>fad fa-pencil</v-icon>
				</v-btn>
			</template>

			<template v-slot:item.phone="{ item }">
				<template v-for="type in phoneTypes">
					<div :key="type.key" v-if="item[type.key]" class="d-flex align-center mb-2">
						<v-icon small left>{{type.icon}}</v-icon>
						<a :href="item[type.key] | getPhoneLink" class="d-block">
							{{item[type.key]}}
						</a>
					</div>
				</template>
			</template>

			<template v-slot:item.meta_person_hubspot_link="{ item }">
					<v-btn v-if="item.meta_person_hubspot_link" :href="item.meta_person_hubspot_link" target="_blank" small min-width="0" title="View on Hubspot">
						<v-icon small>fab fa-hubspot</v-icon>
					</v-btn>
			</template>

			<template v-slot:item.email="{ item }">
				<template v-for="type in emailTypes">
					<div :key="type.key" v-if="item[type.key]" class="d-flex align-center mb-2">
						<v-icon small left>{{type.icon}}</v-icon>
						<a :href="`mailto:${item[type.key]}`" class="text-truncate">
							{{item[type.key]}}
						</a>
						<v-btn x-small v-clipboard:copy="item[type.key]" v-clipboard:success="copiedToClipboard" min-width="0" class="ml-2">
							<v-icon x-small>fad fa-copy</v-icon>
						</v-btn>
					</div>
				</template>
			</template>

			<template v-slot:item.associations="{ item }">
				<div v-for="client in item.client_links" :key="client.id" class="mb-1 d-flex">
					<v-icon left x-small>fas fa-user</v-icon> Client:
					<router-link :to="{ name: 'client.details', params: { id: client.id } }" class="ml-1 text-truncate">
						{{client.linked_object.name}}
					</router-link>
				</div>
				<div v-for="project in item.project_links" :key="project.id" class="ml-6 mb-1 d-flex">
					Project:
					<router-link :to="{ name: 'project.details', params: { id: project.id } }" class="ml-1 text-truncate">
						{{project.linked_object.name}}
					</router-link>
				</div>
			</template>

		</v-data-table>

		<pager-buttons v-if="persons && persons.nodes.length" class="my-3 mr-3 d-flex align-center justify-end" v-model="page" :numberOfPages="numberOfPages"></pager-buttons>

	</v-container>
</template>

<script>
	import PagerButtons from '../components/pager-buttons.vue';

	export default {
		name: "contactList",

		components: {
			PagerButtons
		},

		apollo: {
			persons: {
				query: gql`
					query GetPersons(
						$filterParam: QueryFilter!
						$limit: Int
						$offset: Int
					) {
						persons
							@sort(param: [{ field: "first_name", order: asc }])
							@filter(param: $filterParam)
							@page(limit: $limit, offset: $offset) {
							total
							nodes {
								node {
									id
									uid
									first_name
									last_name
									full_name
									is_employee
									meta_birthday
									meta_out_today
									meta_person_hubspot_link
									phone
									phone_office
									phone_fax
									phone_primary
									phone_secondary
									email_primary
									email_secondary
									note
									client_links {
										id
										uid
										object_type
										object_id
										note
										linked_object {
											...on NamedObject {
												name
											}
										}
									}
									project_links {
										id
										uid
										object_type
										object_id
										note
										linked_object {
											...on NamedObject {
												name
											}
										}
									}
									inactive
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "is_employee",
							op: "=",
							value: false,
						},
					];

					filters.push({
						field: "inactive",
						op: "=",
						value: false,
					});

					if (this.searchTxt) {
						filters.push({
							joinOp: "or",
							filters: [
								{
									field: "first_name",
									op: "like",
									value: `%${this.searchTxt}%`,
								},
								{
									field: "last_name",
									op: "like",
									value: `%${this.searchTxt}%`,
								},
								{
									field: "client_links.client.name",
									op: "like",
									value: `%${this.searchTxt}%`,
								},
								{
									field: "project_links.project.name",
									op: "like",
									value: `%${this.searchTxt}%`,
								},
							],
						});
					}

					return {
						limit: this.itemsPerPage,
						offset: (this.page - 1) * this.itemsPerPage,
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
		},

		data() {
			return {
				searchTxt: "",
				itemsPerPage: 10,
				page: 1,
				headers: [
					{
						value: "full_name",
						text: "Name",
					},
					{
						value: "note",
						text: "Note",
					},
					{
						value: "phone",
						text: "Phone",
					},
					{
						value: "email",
						text: "Email",
					},
					{
						value: "associations",
						text: "Client / Projects",
					},
					{
						value: "meta_person_hubspot_link",
						text: "Hubspot",
					},
					{
						value: "actions",
						text: "Actions",
						width: "130px",
						height: "auto",
						// cellClass: "d-flex"
					},
				],
				phoneTypes: [
					{ key: "phone_primary", icon: "fad fa-phone-square" },
					{ key: "phone_secondary", icon: "fad fa-phone-square" },
					{ key: "phone_office", icon: "fad fa-building" },
					{ key: "phone_fax", icon: "fad fa-fax" },
				],
				emailTypes: [
					{ key: "email_primary", icon: "fad fa-envelope" },
					{ key: "email_secondary", icon: "fad fa-envelope" },
				],
			};
		},

		computed: {
			numberOfPages() {
				if (!this.persons) {
					return 1;
				}

				return Math.ceil(this.persons.total / this.itemsPerPage);
			},

			personNodes() {
				if (!this.persons) {
					return [];
				}

				return this.persons.nodes
					.map(row => row.node);
			}
		},

		methods: {
			editPerson(personId) {
				this.$modalService
					.create("contactAddEdit", {
						personId: personId,
					})
					.on("save", (e, person) => {
						// this.$db.addModels("person", person, "person-details");
						this.$apollo.queries.persons.refetch();
						this.$snotify.success("Person updated", "Success");
					});
			},
		},

		watch: {
			searchTxt: {
				handler(to) {
					this.page = 1;
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.user-table {
		::v-deep td {
			height: auto !important;
			padding: 7px 16px !important;
		}
		@media (max-width: 1200.9px) {
			::v-deep td {
				border-bottom: 1px solid var(--v-secondary-base);
			}
		}
	}
</style>