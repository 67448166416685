<template>
    <div class="position-relative">
        <div class="legend-extra">
            <v-btn class="btn-reset" dense v-if="selectedSeriesIndex!==null" @click="resetSelection" height="28">Show All</v-btn>
            <div v-else class="text--secondary">Click an item to apply filter</div>
        </div>
        <GChart type="LineChart" :options="options" :data="filteredData" v-if="hasData" :events="events" ref="chart" :resizeDebounce="500"/>
        <slot name="empty" v-if="hasData===false"></slot>
    </div>
</template>

<script>

export default {
    name:  "lineChart",
    props: {
        data: Array
    },
    data() {
        return {
            selectedSeriesIndex: null,
            options:             {
                chartArea: {
                    top:   48,
                    left:  92,
                    width: '70%'
                },
                // title:           'Staff Utilization',
                // titleTextStyle:  {
                //     color: this.$vuetify.theme.currentTheme["text-color"],
                //     bold:  false,
                // },
                backgroundColor: {
                    fill:        this.$vuetify.theme.currentTheme["cards"],
                    fillOpacity: 0.6,
                },
                height:          500,
                // width: 887,
                legend: {
                    position:  "right",
                    textStyle: {
                        color:    this.$vuetify.theme.currentTheme["text-color"],
                        fontSize: 16,
                    },
                },
                // bar: {groupWidth: '90%'},
                hAxis: {

                    textPosition: "out",
                    textStyle:    {
                        color:    this.$vuetify.theme.currentTheme["text-color"],
                        bold:     true,
                        fontSize: 14,
                    },
                },
                vAxis: {
                    format:        'percent',
                    baselineColor: this.$vuetify.theme.currentTheme["dividers"],
                    // textPosition: "none",
                    gridlines:      {
                        minSpacing: 25,
                        color:      this.$vuetify.theme.currentTheme["dividers"],
                    },
                    minorGridlines: {
                        // deprecated but will force gridlines to disappear
                        count: 0,
                        color: this.$vuetify.theme.currentTheme["dividers"],
                    },
                    textStyle:      {
                        color: this.$vuetify.theme.currentTheme["text-color"],
                        // bold: true,
                    },
                },

                series: {
                    // 0: { color: this.$vuetify.theme.currentTheme["today"] },
                },
            },

            events: {
                select: () => {
                    let chart = this.$refs.chart.chartObject
                    let sel = chart.getSelection();
                    console.log('select', sel);
                    if (!sel.length) {
                        // reset selection; show everything
                        this.resetSelection();
                        return;
                    }

                    // ignore clicks on the chart
                    if (sel[0].row !== null) {
                        return;
                    }

                    // toggle:
                    if (sel[0].column - 1 === this.selectedSeriesIndex) {
                        this.resetSelection();
                        return;
                    }

                    // gray out all series except the one the user clicked on
                    this.selectedSeriesIndex = sel[0].column - 1;
                    console.log('setting only this series visible:', this.selectedSeriesIndex);
                    let numberSeries = this.data[0].length - 1;
                    for (let i = 0; i < numberSeries; i++) {
                        let seriesOptions = {
                            color: null,
                            // lineWidth: 1,
                        }
                        if (i !== this.selectedSeriesIndex) {
                            // seriesOptions.lineWidth = 0;
                            seriesOptions.color = this.$vuetify.theme.currentTheme["dividers"];
                        }
                        this.$set(this.options.series, i, seriesOptions);
                    }
                }
            }
        }
    },
    methods:  {
        resetSelection() {
            this.selectedSeriesIndex = null;
            console.log('resetSelection');
            this.options.series = {};
        }
    },
    computed: {
        filteredData() {
            console.log('filtered data selectedSeriesIndex=' + this.selectedSeriesIndex);
            if (this.selectedSeriesIndex === null) {
                return this.data
            }
            return this.data.map(columnData => {
                if (columnData[0] === 'Date') {
                    return columnData;
                }
                return columnData.map((value, index) => {
                    if (index === 0 || index === this.selectedSeriesIndex + 1) {
                        return value;
                    }
                    return 0;
                })
            });
        },
        hasData() {
            if (this.data === null) {
                return null;
            }
            return this.data && this.data.length > 1 && this.data[0].length > 1;
        }
    },
}
</script>

<style scoped lang="scss">
.legend-extra {
    top: 8px;
    // 35px is margin left of the legend
    left: calc(70% + 92px + 38px + 16px);
    position: absolute;
    z-index: 1;
}

::v-deep svg > g > g {
    cursor: pointer;
}
</style>
