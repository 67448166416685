import md from "markdown-it";
import mdTaskListsTipTap from "./markdown-it-task-lists";
import mdTaskLists from './markdown-it-task-lists-font-awesome';

export const tiptapMarkdownRenderer = md({ html: false })
	.use(mdTaskListsTipTap);

export const markdownRenderer = md({ html: false })
	.use(mdTaskLists);

// Make links open in new tab
[tiptapMarkdownRenderer, markdownRenderer].forEach(md => {
	let defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
		return self.renderToken(tokens, idx, options);
	};

	md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
		// If you are sure other plugins can't add `target` - drop check below
		let aIndex = tokens[idx].attrIndex('target');
	
		if (aIndex < 0) {
			tokens[idx].attrPush(['target', '_blank']); // add new attribute
		} else {
			tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
		}
	
		// pass token to default renderer.
		return defaultRender(tokens, idx, options, env, self);
	};
});