// TODO: rename to notes and move up a directory. When we can safely replace the existing notes.vue file
<template>
	<v-card class="py-1" :loading="$apollo.queries.notes.loading">
		<v-card-title>
			Notes <template v-if="notes">({{notes.total}})</template>
			<div class="ml-auto">
				<add-note-button :obj-type="uidDetails.object" :obj-id="uidDetails.objectId" :showType="true" @save="$apollo.queries.notes.refetch()"></add-note-button>
			</div>
		</v-card-title>

		<note-list-filter-card v-if="noteStats" class="py-1 px-4" v-model="noteFilters" :totals="noteStats"></note-list-filter-card>
		<div v-if="notes && notes.nodes.length">
			<template v-for="(item, ndx) in notes.nodes">
				<note-list-item v-if="item" :key="ndx" :note="item.node"></note-list-item>
			</template>
		</div>
		<v-card-text v-else>
			<em>No notes exist here</em>
		</v-card-text>

		<pager-buttons v-if="notes && notes.nodes.length"
			class="my-3 mr-3 d-flex align-center justify-end"
			v-model="page"
			:numberOfPages="numberOfPages"
		></pager-buttons>
	</v-card>
</template>

<script>
	import addNoteButton from "../addNoteButton";
	import noteListItem from "@/components/noteListItem";
	import NoteListFilterCard from "../note-list-filter-card.vue";
	import PagerButtons from '../pager-buttons.vue';
	import { decomposeNodeId } from '../../router/uid';

	export default {
		name: "ClientNotes",
		components: {
			addNoteButton,
			noteListItem,
			NoteListFilterCard,
			PagerButtons
		},
		props: {
			uid: {
				type: [String],
				required: true,
			},
		},
		apollo: {
			notes: {
				query: gql`
					query getNotes($filterParam: QueryFilter! $limit: Int $offset: Int) {
						notes 
							@filter(param: $filterParam)
							@page(limit: $limit offset: $offset)
							@sort(param: [{ field: "date", order: desc }]) 
							{
							total
							nodes {
								node {
									id
									uid
									content
									note_type
									date
									mailto_users {
										id
										full_name
									}
									author {
										id
										department @lowercase
										full_name
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "linked_object.uid",
							op: "=",
							value: this.uid,
						}
					];

					if (this.searchTxt) {
						filters.push({
							field: 'content',
							op: 'like',
							value: `%${this.searchTxt}%`
						});
					}

					if (this.noteTypes.length) {
						filters.push({
							field: 'note_type',
							op: 'in',
							value:this.noteTypes
						});
					}

					return {
						filterParam: {
							joinOp: 'and',
							filters
						},
						limit: this.itemsPerPage,
						offset: (this.page - 1) * this.itemsPerPage
					};
				},
			},
			rawNoteStats: {
				query: gql`
					query getNoteStats($filterParam: QueryFilter!) {
						rawNoteStats: notes 
							@filter(param: $filterParam)
							@sort(param: [{ field: "note_type", order: asc }])
							@groupBy(fields: ["note_type"])
						{
							nodes {
								group_total
								node {
									id
									note_type
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "linked_object.uid",
							op: "=",
							value: this.uid,
						}
					];

					if (this.searchTxt) {
						filters.push({
							field: 'content',
							op: 'like',
							value: `%${this.searchTxt}%`
						});
					}

					return {
						filterParam: {
							joinOp: 'and',
							filters
						}
					};
				},
			}
		},
		data() {
			return {
				searchTxt: "",
				noteTypes: [],
				itemsPerPage: 5,
				page: 1
			};
		},

		destroyed() {
			this.$db.removeHandle(`${this.objectType}-tasks`);
			this.$db.removeHandle(`${this.objectType}-notes`);
		},

		computed: {
			//TODO: Delete this after converting sub-components to uid
			uidDetails() {
				return decomposeNodeId(this.uid);
			},

			noteStats() {
				if (!this.rawNoteStats) {
					return null;
				}

				return this.rawNoteStats.nodes
					.map(row => ({ note_type: row.node.note_type, total: row.group_total }));
			},

			numberOfPages() {
				if (!this.notes) {
					return 1;
				}

				return Math.ceil(this.notes.total / this.itemsPerPage);
			},

			noteFilters: {
				get() {
					return {
						search: this.searchTxt,
						types: this.noteTypes,
					};
				},
				set(v) {
					this.searchTxt = v.search;
					this.noteTypes = v.types;
					this.page = 1;
				},
			},

		},
	};
</script>
