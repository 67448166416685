<template>
	<div class="fill-height d-flex flex-column">
		<v-card class="mb-1" :loading="$apollo.queries.projectTasks.loading">
			<v-card-title>
				Tasks <v-chip v-if="tasks" class="ml-1">{{tasks.length}}</v-chip>
				<div class="ml-auto">
					<v-btn small min-width="0" title="View in Timeline" :to="{name: 'timeline', params: {project_id: project.id}}">
						<v-icon small>fad fa-calendar</v-icon>
					</v-btn>
					<v-btn small min-width="0" @click="addTask" title="Add Task" class="mx-1">
						<span class="fa-stack">
							<v-icon small>fad fa-sticky-note</v-icon>
							<v-icon small class="tiny">fal fa-plus</v-icon>
						</span>
					</v-btn>
					<v-btn small class="mr-2" @click="$apollo.queries.projectTasks.refetch();">
						<v-icon small>fad fa-sync</v-icon>
					</v-btn>
				</div>
			</v-card-title>
		</v-card>

		<v-sheet class="mb-1 py-2 px-4">
			<v-text-field v-model="searchTasks" label="Search Tasks" hide-details clearable class="my-0 mb-3"></v-text-field>
			<v-btn-toggle v-model="viewTasks" dense group class="flex-wrap" color="primary" mandatory>
				<v-btn @click="applyFilter('tasks')" small>
					All
				</v-btn>
				<template v-for="(cnt, status) in taskStats">
					<v-btn :key="status" @click="applyFilter('tasks', status)" small>
						{{status}} ({{cnt}})
					</v-btn>
				</template>
			</v-btn-toggle>
		</v-sheet>

		<v-sheet v-if="!listData" class="mb-1 py-2 px-4">
			<em>No tasks</em>
		</v-sheet>

		<div v-else class="flex-grow-1" style="position:relative;">
			<v-data-iterator :items="listData" :search="searchTasks" dense calculate-widths disable-pagination hide-default-footer class="desktop-fit">
				<template v-slot:default="props">
					<task-list-item v-for="(item, ndx) in props.items" :key="ndx" :task="item" @update="$apollo.queries.projectTasks.refetch()"></task-list-item>
				</template>
			</v-data-iterator>
		</div>

	</div>
</template>

<script>
	import { ProjectTasks } from "@/graphql/queries";
	import taskListItem from "@/components/taskListItem";
	import moment from "moment";

	export default {
		name: "taskListView",

		components: {
			taskListItem,
		},
		inject: ["projectContext"],
		apollo: {
			projectTasks: {
				query() {
					return ProjectTasks(this.project.id);
				},
				skip() {
					return !this.project;
				},
				pollInterval: timeInterval("5 minutes"),
				refetchEvents: ['task/delete', 'task/update']
			},
		},

		data() {
			return {
				filterTaskStatus: "all",
				viewTasks: 0,
				searchTasks: "",
			};
		},

		computed: {
			project() {
				return this.projectContext.project;
			},
			tasks() {
				if (!this.projectTasks) {
					return [];
				}
				return this.projectTasks.nodes.map((row) => row.node);
			},

			taskStats() {
				let statuses = {};

				this.tasks.forEach((task) => {
					if (!statuses[task.status]) {
						statuses[task.status] = 0;
					}

					statuses[task.status]++;
				});

				return statuses;
			},

			listData() {
				let result = this.tasks.filter((task) => {
					return (
						this.filterTaskStatus === "all" ||
						task.status === this.filterTaskStatus
					);
				});

				// console.log(`[taskListView] recomputed task list`);
				// Sort by completion_order then date
				/* result.sort((a, b) => {
							let order = b.completion_order - a.completion_order;

							if (order === 0) {
								order =
									moment(a.timeline_due_date).valueOf() -
									moment(b.timeline_due_date).valueOf();
							}

							return order;
						}); */

				return result;
			},
		},

		methods: {
			applyFilter(types, extraFilter) {
				this.filterTaskStatus = extraFilter || "all";
			},

			addTask() {
				this.$modalService
					.create("taskAddEdit", {
						populate: {
							project_id: this.project.id
						}
					})
					.on("save", (e, task) => {
						// this.$db.addModels("task", task, "project-details");
						this.$snotify.success("Task saved.", "Success!");
						this.$apollo.queries.projectTasks.refetch();
					});
			},

			/**
			 * handler for event 'insert-task' emitted by task-list-item
			 * @param task
			 */
			// insertTask(task) {
			// 	let cancelModalClose = false;
			// 	// JSON.parse(JSON.stringify(task.id))
			// 	this.$modalService
			// 		.create("taskAddEdit", {
			// 			populate: {
			// 				project_id: this.project.id,
			// 				previous_task_ids: [task.id]
			// 			},
			// 			lockedFields: {
			// 				project_id: true,
			// 				previous_task_ids: true
			// 			},
			// 			insertAfter: task.id
			// 		})
			// 		.on("save", (e, newTask) => {
			// 			// this.cancelModalClose = true;
			// 			this.$db.addModels("task", newTask, "project-details");

			// 			this.$apollo.queries.projectTasks.refetch();
			// 			this.$emit("update");
			// 		})
			// 		.on("close", (e) => {
			// 			if (cancelModalClose) {
			// 				e.preventDefault();
			// 			}
			// 		});
			// },

			// TODO: make global. This is recursive
			searchItems(items, search) {
				search = search.toString().toLowerCase();

				return items.filter((i) => this.searchItem(i, search));
			},
			searchItem(item, search) {
				return Object.keys(item).some((k) => {
					if (item[k] === null) {
						return false;
					}

					if (typeof item[k] == "object") {
						return this.searchItem(item[k], search);
					}

					return item[k].toString().toLowerCase().indexOf(search) >= 0;
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	@media (min-width: 960px) {
		.desktop-fit {
			overflow-y: auto;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
</style>