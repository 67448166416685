<script>
	import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
	import VAvatar from 'vuetify/lib/components/VAvatar';
	import UserAvataaar from '../avataaars/user-avataaar.vue';
	import { VListItemAvatar, VListItemContent, VListItemTitle, VListItemSubtitle } from 'vuetify/lib/components/VList';
	import { ActiveEmployees } from "@/graphql/queries";

	function objectWithoutKeys(obj, keys) {
		let result = {};
		for (let i in obj) {
			if (keys.indexOf(i) < 0) {
				result[i] = obj[i];
			}
		}

		return result;
	}
	
	export default {
		model: {
			prop: 'value',
			event: 'input'
		},

		props: {
			...objectWithoutKeys(VAutocomplete.options.props, ['items', 'item-text', 'item-value', 'item-disabled'])
		},

		data() {
			return {
				includeId: this.value
			};
		},

		apollo: {
			getEmployees: {
				query: gql`
					query getEmployees ($filterParam: QueryFilter!) {
						getEmployees: persons
							@filter(param: $filterParam)
							@sort(param: [{ field: "first_name", order: asc }]) 
						{
							nodes {
								node {
									id
									full_name
									email
									inactive
									... on Employee {
										department
										meta_avatar
									}
								}
							}
						}
					}
				`,

				variables() {
					return {
						filterParam: {
							joinOp: 'or',
							filters: [
								{
									field: 'id',
									op: '=',
									value: this.includeId
								},
								{
									joinOp: 'and',
									filters: [
										{
											field: "is_employee",
											op: "=",
											value: true
										},
										{
											field: "inactive",
											op: "=",
											value: false
										}
									]
								}
							]
						}
					};
				}
			}
		},

		computed: {
			items() {
				if (!this.getEmployees) {
					return [];
				}

				return this.getEmployees.nodes
					.map(row => row.node);
			}
		},

		/* methods: {
			getItem(id) {
				return this.items.find(i => i.id == id);
			}
		}, */

		watch: {
			value(to, from) {
				let lastSelected = this.items.find(i => i.id == from) || {},
					selected = this.items.find(i => i.id == to) || {};

				if (selected.inactive != !!lastSelected.inactive) {
					this.includeId = to;
				}
			}
		},

		render(h) {
			return h(
				VAutocomplete,
				{
					props: {
						...this.$props,
						items: this.items,
						itemText: 'full_name',
						itemValue: 'id',
						loading: this.loading || this.$apollo.queries.getEmployees.loading
					},
					scopedSlots: {
						selection: ({item}) => [
							h(
								VAvatar,
								{class: 'mr-2'},
								[
									h(
										UserAvataaar,
										{
											props: {
												user: item
											}
										}
									)
								]
							),
							item.full_name
						],
						item: ({item}) => [
							h(
								VListItemAvatar,
								{},
								[
									h(
										UserAvataaar,
										{
											props: {
												user: item
											}
										}
									)
								]
							),
							h(
								VListItemContent,
								{},
								[
									h(
										VListItemTitle,
										{
											domProps: {
												innerHTML: item.full_name
											}
										}
									),
									h(
										VListItemSubtitle,
										{
											domProps: {
												innerHTML: item.department
											}
										}
									)
								]
							)
						],
						...this.$scopedSlots
					},
					on: this.$listeners
				}
			);
		}
	}
</script>