<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>{{isUpdate ? 'Edit' : 'Add'}} Client</v-card-title>

			<v-card-text v-if="clientData">
				<v-alert v-if="modalErrorMessage" color="error" border="bottom" class="mb-4" v-html="modalErrorMessage"></v-alert>

				<!--Name-->
				<v-text-field label="Name" v-model="clientData.name" :disabled="saving" dense hide-details outlined class="my-3" required></v-text-field>

				<!--Client Description-->
				<v-textarea label="Client Description" v-model="clientData.description" :disabled="saving" outlined hide-details class="my-3" rows="3" auto-grow></v-textarea>

				<v-row>
					<v-col cols="12" sm="6" class="my-3">
						<!--Phone-->
						<v-text-field label="Phone" v-model="clientData.location_phone" :disabled="saving" dense hide-details outlined required></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" class="my-3">
						<!--Fax-->
						<v-text-field label="Fax" v-model="clientData.location_fax" :disabled="saving" dense hide-details outlined required></v-text-field>
					</v-col>
				</v-row>

				<!--Address-->
				<v-text-field label="Address" v-model="clientData.location_address" :disabled="saving" dense hide-details outlined class="my-3" required></v-text-field>

				<v-row>
					<v-col cols="12" sm="6" class="my-3">
						<!--City-->
						<v-text-field label="City" v-model="clientData.location_city" :disabled="saving" dense hide-details outlined required></v-text-field>
					</v-col>
					<v-col cols="6" sm="3" class="my-3">
						<!--State-->
						<v-text-field label="State" v-model="clientData.location_state" :disabled="saving" dense hide-details outlined required></v-text-field>
					</v-col>
					<v-col cols="6" sm="3" class="my-3">
						<!--Zip-->
						<v-text-field label="Zip" v-model="clientData.location_zip" :disabled="saving" dense hide-details outlined required></v-text-field>
					</v-col>
				</v-row>

				<!--Meta-->
				<meta-input-list ref="metaFieldList" :model="clientData" model-type="Client" :disabled="saving" class="input-group input-group-sm"></meta-input-list>
			</v-card-text>
			<v-card-text v-else>
				No client data
			</v-card-text>

			<v-card-actions>
				<v-btn @click="modal.trigger('close')" :disabled="saving" class="ml-auto">Close</v-btn>
				<v-btn v-if="clientData" color="primary" @click="submit" :loading="saving">
					{{isUpdate ? 'Update' : 'Create'}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import MetaInputList from "../metaInputList";
	import { IdType } from "../../utils/IdType";

	export default {
		name: "clientAddEdit",
		components: {
			MetaInputList,
		},
		props: {
			modal: {
				type: Object,
				required: true,
			},
			clientId: {
				type: IdType,
				default: 0,
			},
		},
		apollo: {
			clientDetails: {
				query: gql`
					query GetSingleClient($clientId: ID!) {
						queries
						clientDetails: client(id: $clientId) {
							id
							name
							description
							location_phone
							location_fax
							location_address
							location_city
							location_state
							location_zip
							_meta_values
						}
					}
				`,
				variables() {
					return {
						clientId: this.clientId,
					};
				},
				skip() {
					return !this.realClientId;
				},
			},
		},

		data() {
			return {
				modalErrorMessage: false,
				saving: false,
				realClientId: this.clientId,
				clientData: null,
			};
		},

		computed: {
			isUpdate() {
				return this.realClientId;
			},
		},

		methods: {
			copyData(graphQlData = {}) {
				let copyManyFn = (props, subKey = "") => {
					let source = graphQlData;
					if (subKey) {
						source = source[subKey] || {};
					}

					let result = {};
					for (let i of props) {
						result[i] = source.hasOwnProperty(i) ? source[i] : null;
					}

					return result;
				};

				this.clientData = copyManyFn([
					"id",
					"name",
					"description",
					"location_phone",
					"location_fax",
					"location_address",
					"location_city",
					"location_state",
					"location_zip",
					"_meta_values",
				]);
			},

			formModel() {
				let model = {
					name: this.clientData.name,
					description: this.clientData.description,
					location_phone: this.clientData.location_phone,
					location_fax: this.clientData.location_fax,
					location_address: this.clientData.location_address,
					location_city: this.clientData.location_city,
					location_state: this.clientData.location_state,
					location_zip: this.clientData.location_zip,
				};

				if (this.isUpdate) {
					Object.assign(model, { id: this.realClientId });
				}

				Object.assign(model, this.$refs.metaFieldList.getFields());

				return model;
			},

			setErrors(fieldsAndMessages) {
				$(this.$refs.buttons).children().removeAttr("disabled"); // TODO update
				this.$refs.fieldList.setErrors(fieldsAndMessages);
			},

			submit() {
				this.saving = true;

				if (this.isUpdate) {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: UpdateClientArgs!) {
									updateClient(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: this.formModel(),
							},
							update: (store, { data: { updateClient } }) => {
								this.saving = false;
								this.modal.trigger("save close");
							},
						})
						.catch((error) => {
							console.error("error updating client", error);
							this.modalErrorMessage =
								"There was a problem updating the data.";
						});
				} else {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: CreateClientArgs!) {
									createClient(data: $data) {
										id
										name
									}
								}
							`,
							variables: {
								data: this.formModel(),
							},
							update: (store, { data: { createClient } }) => {
								this.saving = false;
								this.modal.trigger("save close", createClient);
							},
						})
						.catch((error) => {
							console.error("error adding client", error);
							this.modalErrorMessage =
								"There was a problem creating the client.";
						});
				}
			},
		},

		watch: {
			clientDetails(to) {
				this.copyData(to);
			},
			clientId: {
				immediate: true,
				handler(to) {
					if (!to) {
						this.copyData({});
					}
				},
			},
		},
	};
</script>