<template>
    <v-container fluid>
        <v-card>
            <v-card-title>Payroll - Times Transferred to QuickBooks</v-card-title>

            <v-card-text class="">
                <v-row>
                    <v-col cols="5" md="4" class="d-flex align-center">
                        <v-select dense
                                  :menu-props="{maxHeight:600}"
                                  hide-details
                                  class="select-employee"
                                  v-model="reportOptions.employeeId"
                                  :items="$insight.users.activeEmployees()"
                                  placeholder="Pick Employee"></v-select>
                    </v-col>
                    <v-col cols="7" md="5" class="d-flex">
                        <v-menu v-model="showPopupCalendarStart" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="200px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="reportOptions.startDate"
                                              type="date"
                                              label="Start Date"
                                              v-bind="attrs"
                                              v-on="on"
                                              hide-details
                                              outlined
                                              dense
                                              class="my-3"></v-text-field>
                            </template>
                            <v-date-picker v-model="reportOptions.startDate"
                                           @input="showPopupCalendarStart = false"
                                           header-color="primary"
                                           color="primary"
                                           show-adjacent-months
                                           no-title></v-date-picker>
                        </v-menu>
                        <v-menu v-model="showPopupCalendarEnd" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="200px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="reportOptions.endDate"
                                              type="date"
                                              label="End Date"
                                              v-bind="attrs"
                                              v-on="on"
                                              hide-details
                                              outlined
                                              dense
                                              class="my-3"></v-text-field>
                            </template>
                            <v-date-picker v-model="reportOptions.endDate"
                                           @input="showPopupCalendarEnd = false"
                                           header-color="primary"
                                           color="primary"
                                           show-adjacent-months
                                           no-title></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <div class="text-center mb-1">Group by</div>
                        <v-btn-toggle v-model="reportOptions.groupBy" dense mandatory>
                            <v-btn small value="timersGroupedByDate">Date</v-btn>
                            <v-btn small value="timersGroupedByProject">Customer</v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- REPORT -->

        <v-card v-if="timerNodes" class="table-responsive mt-3">
            <v-simple-table v-slot:default class="table-payroll" dense>

                <caption>
                    <v-progress-linear v-if="busy" indeterminate></v-progress-linear>
                </caption>
                <thead>
                <tr>
                    <th class="w-14em">Date</th>
                    <th class="w-20em">Customer</th>
                    <th class="w-20em">Product/Service</th>
                    <th>Description</th>
                    <th class="w-8em">Duration</th>
                </tr>
                </thead>
                <tbody v-for="group in groupedTimers.groups" :data-collapsed="!expandedGroups[group.groupName]">
                <!-- group name -->
                <tr v-if="group.groupName" @click="toggleExpanded(group.groupName)" class="clickable font-weight-bold">
                    <td colspan="4" class="">
                        <v-icon class="collapse-toggle mr-1 fas fa-chevron-right" :class="{'fa-rotate-90': expandedGroups[group.groupName]}"></v-icon>
                        {{ group.groupName }}
                    </td>
                    <td>
                        <div class="visible-collapsed" v-html="$options.filters.hoursMinutes(group.duration)"></div>
                    </td>
                </tr>
                <!-- group items -->
                <tr v-for="timer in group.items" @click="editTimer(timer)" class="clickable grey darken-3">
                    <td>
                        <div class="ml-3">{{ timer.start.substring(0, 10) }}</div>
                    </td>
                    <td>
                        {{ timer.client.name }}: {{ timer.project.name }}
                    </td>
                    <td>{{ timer.job }}</td>
                    <td>{{ timer.description }}</td>
                    <td v-html="$options.filters.hoursMinutes(timer.time_spent)"></td>
                </tr>
                <!-- group summary -->
                <tr class="font-weight-bold grey darken-3 summary-row">
                    <td colspan="4">
                        Total for {{ group.groupName }}
                    </td>
                    <td v-html="$options.filters.hoursMinutes(group.duration)"></td>
                </tr>
                </tbody>

                <!-- total summary of all groups -->
                <tbody>
                <tr class="font-weight-bold grey darken-3 summary-row">
                    <td>
                        Total Time
                    </td>
                    <td colspan="3"></td>
                    <td v-html="$options.filters.hoursMinutes(groupedTimers.totalDuration)"></td>
                </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </v-container>
</template>
<script>
import TimerCard from "@/components/timerCard";
import moment from 'moment'
import {groupBy} from 'lodash';

export default {
    name:       "accountExport",
    components: {
        TimerCard
    },
    data() {
        return {
            expandedGroups:                {},
            showPopupCalendarStart:        false,
            showPopupCalendarEnd:          false,
            reportOptions:                 {
                employeeId: null,
                groupBy:    'timersGroupedByDate',
                startDate:  null,
                endDate:    null,
            },
            editing:                       {},
            busy:                          false,
            timersAwaitingTransferHeaders: [
                {
                    text: ''
                },
                {
                    text:      "Date",
                    value:     "Date",
                    cellClass: "text-no-wrap",
                },
                {
                    text:  "Employee",
                    value: "Employee",
                },
                {
                    text:  "Time",
                    value: "Time",
                },
                {
                    text:  "Service",
                    value: "Service",
                },
                {
                    text:  "Project",
                    value: "Project",
                },
                {
                    text:  "Description",
                    value: "Description",
                },
                {
                    text:  'Status',
                    value: 'qbo_transfer_error'
                },

            ],
        };
    },
    apollo: {
        weeklyTimers: {
            watchLoading(isLoading, countModifier) {
                this.busy = isLoading
            },
            query: gql`
					query getWeeklyTimers($filterParam: QueryFilter!) {
						weeklyTimers: timers
							@filter(param: $filterParam)
							@sort(param: [{ field: "creation_date", order: asc }]) {
							nodes {
								node {
									id
									uid
									description
									job
									time_spent
									task_id
									project_id
									client_id
									creation_date
									submit_date
									stop
									start
									client {
										id
										name
									}
									project {
										id
										name
									}
									task {
										id
										name
										project_id
										assigned_to_user_id
										timeline_due_date
										estimated_completion_date
										description
									}
								}
							}
						}
					}
				`,
            variables() {
                return {
                    // project 359 = Lunch tracking off the clock
                    // we exclude these from the Payroll report
                    filterParam: {
                        joinOp:  "and",
                        filters: [
                            {
                                field: "user_id",
                                op:    "=",
                                value: this.reportOptions.employeeId,
                            },
                            {
                                field: "creation_date",
                                op:    ">=",
                                value: this.reportOptions.startDate
                            },
                            {
                                field: "creation_date",
                                op:    "<=",
                                value: this.reportOptions.endDate + ' 23:59:59'
                            },
                            {
                                field: "export_date",
                                op:    "<>",
                                value: null
                            },
                            {
                                field: "project_id",
                                op:    "<>",
                                value: 359
                            },
                        ],
                    },
                };
            },
            skip() {
                let b = !this.reportOptions.employeeId
                return b
            },
        },
    },
    created() {
        let previousOptionsJson = localStorage.getItem('payroll_report_options');

        if (previousOptionsJson) {
            try {
                let previousOptions = JSON.parse(previousOptionsJson);
                if (previousOptions) {
                    Object.assign(this.reportOptions, previousOptions);
                }
            } catch (parseError) {
                localStorage.removeItem('payroll_report_options');
            }
        }
    },
    computed: {
        timerNodes() {
            if (!this.weeklyTimers) {
                return [];
            }
            return this.weeklyTimers.nodes
                .map(row => row.node)
        },

        groupedTimers() {
            return this[this.reportOptions.groupBy]
        },

        timersGroupedByDate() {
            let result = {
                totalDuration: 0,
                groups:        []
            };
            result.totalDuration = this.timerNodes.reduce((accumulator, timer) => accumulator + parseInt(timer.time_spent, 10), 0)

            let grouped = groupBy(this.timerNodes, (item) => item.start.substring(0, 10))
            for (let startDate in grouped) {
                let groupDuration = grouped[startDate].reduce((accumulator, timer) => accumulator + parseInt(timer.time_spent, 10), 0),
                    groupData     = {
                        groupName: moment(startDate).format('MMMM D, YYYY'),
                        duration:  groupDuration,
                        items:     grouped[startDate],
                    };
                result.groups.push(groupData)
            }
            return result;
        },

        timersGroupedByProject() {
            let result = {
                totalDuration: 0,
                groups:        []
            };

            result.totalDuration = this.timerNodes.reduce((accumulator, timer) => accumulator + parseInt(timer.time_spent, 10), 0)

            let grouped = groupBy(this.timerNodes, (item) => item.project.id)
            for (let projectId in grouped) {
                let groupedTimers = grouped[projectId],
                    firstTimer    = groupedTimers[0],
                    groupDuration = groupedTimers.reduce((accumulator, timer) => accumulator + parseInt(timer.time_spent, 10), 0),

                    groupData     = {
                        groupName: firstTimer.client.name + ': ' + firstTimer.project.name,
                        duration:  groupDuration,
                        items:     groupedTimers,
                    };
                result.groups.push(groupData)
            }
            // results are naturally sorted by date because the results from GraphQL are sorted by date
            result.groups.sort((a, b) => a.groupName.localeCompare(b.groupName, 'en', {sensitivity: 'base'}));
            return result;
        }
    },
    methods:  {
        toggleExpanded(key) {
            this.$set(this.expandedGroups, key, !this.expandedGroups[key]);
        },
        editTimer(timer) {
            this.$modalService
                .create('timerEdit', {
                    timer: timer
                }).on('update', (timer) => {
                this.$apollo.queries.weeklyTimers.refetch();
            })
        },
        toggleEdit(item) {
            this.$set(this.editing, item.ID, !this.editing[item.ID]);
        },
    },
    watch:    {
        reportOptions: {
            handler: function (from, to) {
                localStorage.setItem('payroll_report_options', JSON.stringify(this.reportOptions));
            },
            deep:    true
        }

    }
};
</script>
<style lang="scss" scoped>
.table-payroll ::v-deep {
    .clickable {
        cursor: pointer;
    }

    td {
        vertical-align: top;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    tbody tr + tr {
    }

    tbody[data-collapsed="true"] tr + tr {
        display: none;
    }

    tbody:not([data-collapsed]) .visible-collapsed {
        visibility: hidden;
    }

    .hr-min {
        display: flex;
        justify-content: space-between;
    }

    .summary-row td {
        vertical-align: inherit;
        border-bottom: 4px solid var(--v-dividers-base);
    }
}

//::v-deep .v-data-footer {
//    display:none;
//}
table[aria-busy="true"] {
    .busy {
        text-align: center;
        display: block !important;
    }

    .not-busy {
        display: none;
    }
}

.collapse-toggle {
    font-size: 8px !important;
    top: -1px;
}

.w-7em {
    width: 7rem;
}

.w-8em {
    width: 8rem;
}

.w-10em {
    width: 10rem;
}

.w-12em {
    width: 12rem;
}

.w-13em {
    width: 13rem;
}

.w-14em {
    width: 14rem;
}

.w-16em {
    width: 16rem;
}

.w-18em {
    width: 18rem;
}

.w-20em {
    width: 20rem;
}
</style>
