<template>
	<v-card>
		<v-card-title>Project Information</v-card-title>
		<v-card-text>
			<data-list-item label="Job Number" :value="project.job"></data-list-item>
			<data-list-item label="Type" :value="project.type"></data-list-item>
			<data-list-item label="Category" :value="project.category"></data-list-item>
			<data-list-item label="Status" :value="project.status"></data-list-item>
			<!-- <data-list-item label="Due">{{ project.due_date | shortDate('[No Due Date]') }}
				<span class="number ml-2" v-if="project.due_date">({{ project.due_date | timeFromNow }})</span>
			</data-list-item>
			<data-list-item label="ETC">{{ project.estimated_completion_date | shortDate('[No ETC]') }}</data-list-item> -->
			<data-list-item label="Project Manager" :value="(project.account_executive_user || {}).full_name || '[Not Set]'"></data-list-item>
			<data-list-item label="Department">{{ project.department || "Not Set" }}</data-list-item>
			<data-list-item label="Progress">{{ project.progress || "Department not set" }}</data-list-item>
			<data-list-item v-if="projectExtra" label="Current">
				<div v-if="projectExtra.current_tasks.length">
					<template v-for="(team, ndx) in projectExtra.current_tasks">
						<v-chip v-if="team.assigned_to" :key="ndx" dark x-small label :color="team.assigned_to.department" v-html="(team.assigned_to || {}).full_name || '[Not Set]'" class="ma-1"></v-chip>
					</template>
				</div>
				<v-chip v-else x-small label class="mx-1">Nobody</v-chip>
			</data-list-item>
			<data-list-item v-if="teamMembers" label="Team">
				<v-chip v-for="(team, ndx) in teamMembers.nodes" :key="ndx" dark x-small label :color="team.node.assigned_to.department" v-html="team.node.assigned_to.full_name" class="ma-1"></v-chip>
			</data-list-item>
			<meta-list v-if="projectExtra" :model="projectExtra" layout="projectDetailsDataList"></meta-list>
		</v-card-text>
	</v-card>
</template>

<script>
	import metaList from "@/components/metaList";
	import dataListItem from "@/components/dataListItem";

	export default {
		name: "projectInfoViewSummaryCard",
		components: {
			metaList,
			dataListItem,
		},

		inject: ["projectContext"],

		apollo: {
			projectExtra: {
				query: gql`
					query GetProject_LegacyView($id: ID!) {
						projectExtra: project(id: $id) {
							id
							_meta_types
							_meta_values
							current_tasks {
								assigned_to {
									full_name
									department @lowercase
								}
							}
						}
					}
				`,
				variables() {
					return {
						id: this.project.id,
					};
				},
				skip() {
					return !this.project;
				},
			},
			teamMembers: {
				query: gql`
					query getTeamMembers($filterParam: QueryFilter!) {
						teamMembers: tasks
							@filter(param: $filterParam)
							@sort(param: [{  field: "assigned_to.department" order: asc}, { field: "assigned_to.first_name", order: asc }])
							@groupBy(fields: ["assigned_to.id"]) {
							nodes {
								node {
									assigned_to {
										full_name
										department @lowercase
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "project_id",
									op: "=",
									value: this.project.id,
								},
								{
									field: 'assigned_to.id',
									op: '!=',
									value: null
								}
							],
						},
					};
				},
				skip() {
					return !this.project;
				},
			},
		},
		computed: {
			project() {
				return this.projectContext.project;
			},
		},
	};
</script>