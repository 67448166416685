<!--
Ideas and TODO:
https://vue-chartjs.org/#/home?id=custom-new-charts
https://www.npmjs.com/package/vue-google-charts

1)      TOP: I would like to be able to see how many projects we have and how many are in each department.  It might be nice to see under each of those how those compare to last week (or time period if you add a filter for that).
2)      MID: Show activity of tasks, projects, notes, etc.
3)      LOWER: Tasks chart.  I looked at the project management system for “Asana” and they have an interesting task chart.  Their shows a per project task to completion chart.  I like the idea of this but would rather show an activity by day.  I would like your ideas.
4)      LOWEST: Then show the how busy the staff is.  Who has a ton of tasks vs. pending, etc.  Let me know your thoughts on this as well on what we can show or not.
-->

<template>
	<v-container fluid>
		<project-analytics></project-analytics>
		<tasks-analytics></tasks-analytics>
		<staff-task-analytics></staff-task-analytics>
		<staff-donuts></staff-donuts>
	</v-container>
</template>

<script>
	import ProjectAnalytics from "../../components/analytics/projectAnalytics";
	import TasksAnalytics from "../../components/analytics/tasksAnalytics";
	import StaffTaskAnalytics from "../../components/analytics/staffTaskAnalytics";
	import StaffDonuts from "../../components/analytics/staffDonuts";

	export default {
		name: 'analytics-tasks',
		components: {ProjectAnalytics, StaffTaskAnalytics, TasksAnalytics, StaffDonuts}
	}
</script>
