<template>
	<v-container fluid>
		<v-card :loading="$apollo.queries.users.loading">
			<v-card-title>Changelog</v-card-title>
			<v-card-subtitle>Features &amp; Bug Fixes</v-card-subtitle>
			<v-card-text>

				<p>Not all commits are shown. Trust us. You don't care about everything we do.</p>
				<!-- group-by="type" -->
				<v-data-table :headers="$options.headers" :items="data" disable-pagination hide-default-footer group-by="date" group-desc>
					<template v-slot:item.type="{ item }">
						<v-icon :class="'icon-' + item.type" :title="item.type">{{getIcon(item.type)}}</v-icon>
					</template>
					<template v-slot:item.hash="{ item }">
						<a :href="`https://git.digitalattic.com/phalcon/chuck-it/commit/${item.hash}`" target="_blank">
							<v-icon small>fab fa-gitlab</v-icon>
						</a>
					</template>
					<template v-slot:item.time="{ item }">
						{{item.time}}
					</template>
					<template v-slot:item.user="{ item }">
						<object-link v-if="item.user" :uid="item.user.uid" :title="item.user.full_name">
							<user-avataaar class="avatar mr-2" :uid="item.user.uid" style="max-width:50px;"></user-avataaar>
						</object-link>
						<span v-else>Unknown</span>
					</template>
				</v-data-table>
				<p class="body-2 mt-5">We devs, do solemnly swear, to create better commit messages from here on out. </p>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
	import changelogData from "./changelog.json";
	import UserAvataaar from '../../components/avataaars/user-avataaar'
	import ObjectLink from '../../components/objectLink.vue';

	const usersReferenced = Linq.from(changelogData)
		.select(row => row.author_email)
		.distinct()
		.toArray();
	
	export default {
		name: "changelog",
		components: { UserAvataaar, ObjectLink },

		headers: [
			{ text: "", value: "type", width: 50 },
			{ text: "Date", value: "date" },
			{ text: "Time", value: "time" },
			{ text: "Message", value: "message" },
			{ text: "Body", value: "body" },
			{ text: "User", value: "user", width: 150 },
		],

		apollo: {
			users: {
				query: gql`
					query GetChangelogEmployees($filterParam: QueryFilter!) {
						users: persons
							@filter(param: $filterParam)
						{
							nodes {
								node {
									id
									uid
									email
									full_name
									meta_avatar
								}
							}
						}
					}
				`,
				variables: {
					filterParam: {
						joinOp: 'and',
						filters: [
							{
								field: 'is_employee',
								op: '=',
								value: true
							},
							{
								field: 'email',
								op: 'in',
								value: usersReferenced
							}
						]
					}
				},
				update(serverData) {
					return Linq.from(serverData.users.nodes)
						.select(row => row.node)
						.toObject(u => u.email);
				}
			}
		},

		computed: {
			data() {
				if (!this.users) {
					return [];
				}

				return changelogData.map((log) => {
					let date = log.date
						.replace(/T|\.\d+Z|\-\d\d:\d\d/g, " ")
						.trim();

					let type = "none";
					if (log.message.startsWith("feat") || log.message.startsWith("add")) {
						type = "feature";
					}
					if (log.message.startsWith("refactor")) {
						type = "refactor";
					}
					if (
						log.message.startsWith("bugfix") ||
						log.message.startsWith("fix")
					) {
						type = "bugfix";
					}

					return {
						...log,
						date: this.$options.filters.formatDate(date, 'YYYY/MM/DD'),
						time: this.$options.filters.shortTime(date),
						type: type,
						user: this.users[log.author_email]
					};
				});
			},
		},
		methods: {
			getIcon(type) {
				switch (type) {
					case "bugfix":
						return "fad fa-bug";
					case "feature":
						return "fad fa-sparkles";
					case "refactor":
						return "fas fa-tools";
					default:
						return "fal fa-window-minimize";
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.v-icon {
		&.icon-bugfix {
			color: var(--v-error-base);
		}
		&.icon-feature,
		&.icon-refactor {
			color: var(--v-primary-base);
		}
		&.icon-none {
			color: var(--v-primary-base);
		}
	}
</style>