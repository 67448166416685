<template>
	<v-container v-if="timers" fluid>
		<div class="d-flex flex-wrap px-3 align-center mb-3">
			<who-is-out />
		</div>
		<user-online-status :userData="userTimers" class="mb-4 px-3"></user-online-status>

		<v-row>
			<v-col cols="12" sm="6" md="4" xl="3">
				<active-timers class="fill-height"></active-timers>
			</v-col>
			<v-col cols="12" sm="6" md="4" xl="3" v-for="(item, k) in userTimers" :key="k">
				<v-card class="d-flex flex-column card mb-3" :class="item.info.working ? 'working' : 'not-working elevation-3'" height="100%" elevation="4">
					<v-card-title>
						<user-avataaar class="avatar mr-2" :user="item.user"></user-avataaar>
						{{item.user.full_name}}
						<div class="start-time ml-auto">
							{{item.info.firstTimer | shortTime}}
							<span :class="item.info.isOT ? 'overtime' : ''"></span>
						</div>
					</v-card-title>
					<v-card-subtitle v-if="!item.info.working && item.info.lastStop">Timers stopped at: {{item.info.lastStop | shortTime}}</v-card-subtitle>
					<v-card-text class="flex-grow-1">
						<div v-for="timer in item.timers" class="timer-item d-flex justify-space-between align-center" :key="timer.id" :class="'row-' + timer.status.toLowerCase() || ''">
							<div>
								<v-tooltip top max-width="300">
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on" small class="mr-2">fad fa-info-square</v-icon>
									</template>
									<div class="new-lines" style="word-break:break-word">{{timer.description || "No description yet"}}</div>
								</v-tooltip>
								<template v-if="timer.client">
									<router-link :to="{name: 'client.details', params: {id: timer.client.id}}" target="_blank">{{timer.client.name}}</router-link>:
								</template>
								<span v-else>[NO CLIENT]:</span>
								<router-link v-if="timer.project" :to="{name: 'project.details', params: {id: timer.project.id}}" target="_blank" v-html="timer.project.name"></router-link>
								<span v-else>[NO PROJECT]</span>
							</div>
							<div class="user-timer">
								{{getTimerDuration(timer)}}
								<span :class="timer.status.toLowerCase()" class="mt-n1"></span>
							</div>
						</div>
					</v-card-text>
					<v-card-actions class="justify-space-between">
						<span>Total Timers</span>
						<span>{{$insight.timers.timerDurationsSum(item.timers) | formatDuration}}</span>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
	<v-container v-else class="fill-height flex-column justify-center align-center">
		<p class="text-h6">Loading Timers</p>
		<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
	</v-container>
</template>

<script>
	import moment from "moment";
	import Insight from "../utils/Insight";
	import userOnlineStatus from "@/components/userOnlineStatus";
	import whoIsOut from "@/components/whoIsOut";
	import ActiveTimers from "@/components/activeTimers";
	import UserAvataaar from "../components/avataaars/user-avataaar";
	import { timeInterval } from '../utils/timeInterval';

	export default {
		name: "companyTimers",
		components: { userOnlineStatus, whoIsOut, ActiveTimers, UserAvataaar },
		data() {
			return {
			};
		},

		apollo: {
			timers: {
				query: gql`
					query getCompanyTimers($filterParam: QueryFilter!) {
						timers @filter(param: $filterParam) {
							nodes {
								node {
									id
									uid
									description
									job
									time_spent
									project_id
									client_id
									creation_date
									submit_date
									status
									stop
									start
									client {
										id
										name
									}
									project {
										id
										name
									}
									user {
										id
										first_name
										last_name
										full_name
										department
										meta_avatar
									}
								}
							}
						}
					}
				`,
				pollInterval: timeInterval('2 minutes'),
				variables() {
					return {
						filterParam: {
							joinOp: "or",
							filters: [
								{
									field: "creation_date",
									op: ">=",
									value:  this.$store.getters.currentDay.format("YYYY-MM-DD"),
								},
								{
									field: "submit_date",
									op: "=",
									value: null,
								},
							],
						},
					};
				},
			},
		},

		computed: {
			userTimers() {
				if (!this.timers) {
					return [];
				}

				let today = moment();

				return Linq.from(this.timers.nodes)
					.select(row => row.node)
					.groupBy(
						(i) => i.user.id,
						(uid, data) => {
							let activeTimer = data.first(
								(t) => t.status == "Active"
							);

							return {
								user: data.first().user,
								timers: data.select(({ user, ...o }) => o).toArray(),
								info: {
									working: !!activeTimer && !this.$insight.projects.offTheClockProjects.includes(activeTimer?.project_id),
									firstTimer: data
										.where((t) => today.isSame(t.creation_date, 'day'))
										.min((t) => t.creation_date)?.creation_date,
									lastStop: data.max((t) => t.stop)?.stop,
									isOnBreak: this.$insight.projects.breaktimeProjects.includes(parseInt(activeTimer?.project_id)),
									// todo test
									numSubmitted: data.where((t) => t.status == "Submitted").count(),
									isOT: this.$insight.timers.timerDurationsSum(data,true,true) >= 8 * 3600,
								},
							};
						}
					)
					.orderByDescending((t) => t.info.firstTimer)
					.toArray();
			},
		},

		methods: {
			getTimerDuration(timer) {
				return Insight.timers.formatDuration(
					Insight.timers.getDuration(timer)
				);
			},
		},
	};
</script>


<style lang="scss" scoped>
	.card {
		.avatar {
			max-width: 50px;
		}
		.timer-item {
			padding: 2px 0 3px;
			opacity: 0.7;
			&.row-active {
				opacity: 1;
			}
			&:nth-child(even) {
				background-color: var(--v-odd-base);
				margin: 0 -5px;
				padding: 2px 5px 3px;
			}
		}
		.user-timer,
		.start-time {
			position: relative;
			padding: 0 15px;
			span {
				position: absolute;
				right: 0;
				top: 7px;
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background-color: map-get($grey, "darken-1");
				&.active {
					background-color: var(--v-status-active-base);
					box-shadow: 0 0 5px 1px var(--v-status-active-base);
				}
			}
		}
		&.working {
			.start-time {
				span {
					background-color: var(--v-status-active-base);
					box-shadow: 0 0 5px 1px var(--v-status-active-base);
					&.overtime {
						background-color: var(--v-overtime-base) !important;
						box-shadow: 0 0 5px 1px var(--v-overtime-base);
					}
				}
			}
		}
		&.not-working {
			opacity: 0.4;
			transition: opacity 300ms;
			.start-time {
				span {
					background-color: map-get($grey, "darken-1");
				}
			}
			&:hover,
			&:focus,
			&:focus-within {
				opacity: 1;
			}
		}
	}
</style>