import Vue from 'vue'
import VueRouter from 'vue-router'
import userData from '../utils/user-data';
import { parseParams } from "../utils/advancedRouteParams";

import home from '@/views/home'
import dashboard from '@/views/dashboard'

import tasks from '@/views/tasks'
import taskCards from '@/views/taskCards'
import employeeList from '@/views/employeeList'
import contactDetails from '@/views/contactDetails'
import employeeDetails from '@/views/employeeDetails'
import clientsAndProjectsList from '@/views/clientsAndProjects'
import clients from '@/views/clients'
import clientDetails from '@/views/clientDetails'
import metaList from '@/views/metaList'
import calendar from '@/views/calendar'
import retainers from '@/views/retainers'

import taskAnalytics from '@/views/analytics/taskAnalytics'
import commsAnalytics from '@/views/analytics/commsAnalytics'
import staffAnalytics from '@/views/analytics/staffAnalytics'
import analyticsDashboard from '@/views/analyticsDashboard'

import projectAnalytics from '@/views/analytics/projectAnalytics'
import projectProfitability from '@/views/analytics/projects/projectProfitability'
import openProjects from '@/views/analytics/projects/openProjects'
import webProjects from '@/views/analytics/projects/web'
import contactList from '@/views/contactList'

import timeline from '@/views/timeline'
import companyTimers from '@/views/companyTimers'
import weeklyTimers from '@/views/weeklyTimers'
import projectTimers from '@/views/projectTimers'
import payroll from '@/views/payroll'

import exports from '@/views/exports'
import activity from '@/views/activity'

import ProjectAlerts from '@/views/ProjectAlerts'

import permissions from '@/views/permissions'
import changelog from '@/views/changelog/changelog'
import notFound from '@/components/notFound'

// #region Project Details
import projectDetails from '@/views/projectDetails/projectDetails.vue';
import projectDetails_LegacyView from '@/views/projectDetails/views/legacyView';
import projectDetails_TaskTree from '@/views/projectDetails/views/taskTree';
import projectDetails_Info from '@/views/projectDetails/views/info';
import projectDetails_TasksNotes from '@/views/projectDetails/views/tasksNotes';
import projectDetails_Analytics from '@/views/projectDetails/views/analytics';
import projectDetails_Comms from '@/views/projectDetails/views/comms';
import projectDetails_Calendar from '@/views/projectDetails/views/calendar';
import projectDetails_Timesheet from '@/views/projectDetails/views/timesheet';
import projectDetails_Notepad from '@/views/projectDetails/views/notepad';
import projectDetails_Sitemap from '@/views/projectDetails/views/sitemap';
// #endregion

import store from '@/store'
import { getUidRoute } from './uid';

Vue.use(VueRouter);

const router = new VueRouter({
	routes: [
		{
			path: '/',
			name: 'home',
			component: home,
			meta: {
				title: 'Home'
			}
		},
		{
		    path:      '/dashboard',
		    name:      'dashboard',
		    component: dashboard,
		    meta:      {
		        title: 'Dashboard'
		    }
		},
		{
		    path:      '/clients-and-projects/:options?',
		    name:      'clients-and-projects',
		    component: clientsAndProjectsList,
		    meta:      {
		        title: 'Client/Projects'
			}
		},
		{
		    path:      '/retainers',
		    name:      'retainers',
		    component: retainers,
		    meta:      {
		        title: 'Retainer Hours'
		    }
		},
		{
		    path:      '/clients',
		    name:      'clients',
		    component: clients,
		    meta:      {
		        title: 'Clients'
		    }
		},
		{
		    path:      '/project-timers',
		    name:      'project-timers',
		    component: projectTimers,
		    meta:      {
		        title: 'Project Timers'
		    }
		},
		{
		    path:      '/project-alerts',
		    name:      'project-alerts',
		    component: ProjectAlerts,
		    meta:      {
		        title: 'Project Alerts',
		        isAllowed: () => store.getters.hasPermission('manage_project_alerts'),
		    }
		},
		{
		    path:      '/client/:id',
		    name:      'client.details',
		    component: clientDetails,
		    meta:      {
		        title: 'Client Details'
		    }
		},
		/* {
		    path:      '/project-tree/:id',
		    name:      'project.tree',
		    component: projectTaskTree,
		    meta:      {
		        title: 'Project Task Tree'
		    }
		}, */
		{
		    path:      '/project/:id',
		    component: projectDetails,
		    props:true,
		    meta:      {
		        title: 'Project Details'
			},
			children: [
				{
					name: 'project.details',
					path: '',
					redirect: to => {
						// Preferred subview
						let subView = userData.getSetting('project_detail_view');
						if (subView) {
							let subRoute = { name: subView, params: to.params },
								resolved = router.resolve(subRoute);
							
							if (resolved.route.fullPath != '/') {
								return resolved.route.path;
							}
						}

						return 'legacy';
					}
				},
				{
					name: 'project.legacy',
					path: 'legacy',
					component: projectDetails_LegacyView
				},
				{
					name: 'project.tree',
					path: 'task-tree',
					component: projectDetails_TaskTree
				},
				{
					name: 'project.info',
					path: 'info',
					component: projectDetails_Info
				},
				{
					name: 'project.tasksnotes',
					path: 'tasks-notes',
					component: projectDetails_TasksNotes
				},
				{
					name: 'project.analytics',
					path: 'analytics',
					component: projectDetails_Analytics
				},
				{
					name: 'project.comms',
					path: 'comms',
					component: projectDetails_Comms
				},
				{
					name: 'project.calendar',
					path: 'calendar',
					component: projectDetails_Calendar
				},
				{
					name: 'project.timesheet',
					path: 'timesheet/:tab?/:filter?',
					component: projectDetails_Timesheet
				},
				{
					name: 'project.notepad',
					path: 'notepad',
					component: projectDetails_Notepad
				},
				{
					name: 'project.sitemap',
					path: 'sitemap',
					component: projectDetails_Sitemap
				},
			]
		},
		{
		    path: '/task/:id',
		    name: 'task.details'
		},
		{
		    path:      '/tasks',
		    name:      'tasks',
		    component: tasks,
		    meta:      {
		        title: 'Tasks/Timers'
		    }
		},
		{
		    path:      '/tasks/cards/:department?',
		    name:      'tasks.cards',
		    component: taskCards,
		    meta:      {
		        title: 'Task Cards'
		    }
		},
		{
		    path:      '/user/list',
		    name:      'user.list',
		    component: employeeList,
		    meta:      {
		        title: 'Employees'
		    }
		},
		{
		    path:      '/contacts',
		    name:      'contacts',
		    component: contactList,
		    meta:      {
		        title: 'Contacts'
		    }
		},
		{
		    path:      '/contact/:id',
		    name:      'contact.details',
		    component: contactDetails,
		    meta:      {
		        title: 'Contact Details'
		    }
		},
		{
		    path:      '/employee/:id',
		    name:      'employee.details',
		    component: employeeDetails,
		    meta:      {
		        title: 'Employee Details'
		    }
		},
		{   // filter by project
		    path:      '/timeline/:project_id?/:user_id?',
		    name:      'timeline',
		    component: timeline,
		    meta:      {
		        title: 'Timeline'
		    }
		},
		{
		    path:      '/timers/overview',
		    name:      'timers.overview',
		    component: companyTimers,
		    meta:      {
		        title: 'Company Timers'
		    }
		},
		{
		    path:      '/timers/weekly',
		    name:      'timers.weekly',
		    component: weeklyTimers,
		    meta:      {
		        title: 'Weekly Timers'
		    }
		},
		{
			path:      '/payroll',
			name:      'payroll',
			component: payroll,
			meta:      {
				title: 'Payroll',
				isAllowed: () => store.getters.isAdminUser
			}
		},
		{
		    path:        '/account/export',
		    name:        'exports',
		    component:   exports,
		    meta:        {
				title: 'Exports',
				// TODO: re-think this - the permission should be assigned to object_type=Timer not object_type=Person
		        isAllowed: () => store.getters.hasPermission('export_timers')
		    },
		},
		{
		    path:        '/meta/list',
		    name:        'meta.list',
		    component:   metaList,
		    meta:        {
		        title:  'Show Meta'
		    },
		},
		{
		    path:        '/calendar',
		    name:        'calendar',
		    component:   calendar,
		    meta:        {
		        title: 'Calendar'
		    },
		},
		{
		    path:        '/activity',
		    name:        'activity',
		    component:   activity,
		    meta:        {
		        title: 'Activity',
		    },
		},
		{
			path: '/analytics/tasks',
			name: 'analytics.tasks',
			component: taskAnalytics,
			meta: {
				title: 'Analytics: Tasks',
			}
		},
		{
			path: '/analytics/projects',
			component: projectAnalytics,
			// props: true,
			meta: {
				title: 'Analytics: Projects',
			},
			children: [
				{
					path: '',
					name: 'analytics.projects',
					component: projectAnalytics,
					redirect: to => ({ name: 'project.profitability' })
				},
				{
					path: 'profitability/:options?',
					name: 'project.profitability',
					component: projectProfitability
				},
				{
					path: 'open',
					name: 'open.projects',
					component: openProjects
				},
				{
					path: 'web',
					name: 'web.projects',
					component: webProjects
				}
			]
		},
		{
			path: '/analytics/comms',
			name: 'analytics.comms',
			component: commsAnalytics,
			meta: {
				title: 'Analytics: Communications',
			}
		},
		{
			path: '/analytics/staff',
			name: 'analytics.staff',
			component: staffAnalytics,
			meta: {
				title: 'Analytics: Staff',
			}
		},
		{
			path: '/analytics/dashboard',
			name: 'analytics.dashboard',
			component: analyticsDashboard,
			meta: {
				title: 'Analytics: Dashboard',
			}
		},
		{
			path: '/permissions',
			name: 'permissions',
			component: permissions,
			meta: {
				title: 'User Permissions',
			}
		},
		{
			path: '/changelog',
			name: 'changelog',
			component: changelog,
			meta: {
				title: 'Changes',
			}
		},
		{
			path: '/node/:uid',
			name: 'node.details',
			beforeEnter: (to, from, next) => {
				let routeInfo = getUidRoute(to.params.uid);

				if (typeof routeInfo == 'function') {
					routeInfo();
					next(false);
				}
				else {
					next(routeInfo);
				}
			}
		},
		{
			path: '/:pathMatch(.*)',
			component: notFound,
		}
	],
});

router.beforeEach((to, from, next) => {
	let unwatch = null;

    let proceed = () => {
        console.log('[router] user identified - checking permissions');
        let allowed = to.meta.isAllowed();
        if (unwatch) {
            unwatch();
        }
        if (!allowed) {
            console.error(`[router] not allowed: ${to.path}`);
        }
        next(allowed);
	};
	
	// Send Google Analytics pageview event
	gtag('event', 'page_view', {
		page_title: to.meta.title || 'Unknown',
		page_path: to.fullPath
	});

    // for conditional routes, wait here until we know the user's identity:
    if (to.meta && to.meta.isAllowed) {
        if (!store.getters.isLoggedIn) {
            console.log('[router] waiting for user identity');
            unwatch = store.watch((state, getters) => getters.userData, proceed);
            return;
        }
        proceed();
        return;
    }

    next();
});

export default router
