var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tasks && _vm.deadlinesAndReminders)?_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',{staticClass:"d-flex flex-column flex-sm-row align-center justify-center"},[_c('project-picker',{attrs:{"label":"Project*","placeholder":"Filter by Project","dense":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.name))]),(item.status != 'active' && item.__typename == 'Project')?_c('v-chip',{staticClass:"ml-auto",attrs:{"small":""}},[_vm._v(_vm._s(item.status))]):_vm._e()]}}],null,false,282857217),model:{value:(_vm.filterProjectId),callback:function ($$v) {_vm.filterProjectId=$$v},expression:"filterProjectId"}}),_c('div',{staticClass:"mx-5 mb-3 mb-sm-0 d-flex align-center"},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","clearable":"","label":"Search"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","color":"button","min-width":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-caret-down")])],1)]}}],null,false,3843319741)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":"Show Pending"},model:{value:(_vm.showPending),callback:function ($$v) {_vm.showPending=$$v},expression:"showPending"}})],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":"Show Deadlines/Reminders"},model:{value:(_vm.showDeadlinesAndReminders),callback:function ($$v) {_vm.showDeadlinesAndReminders=$$v},expression:"showDeadlinesAndReminders"}})],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":"Show Weekend Markers"},model:{value:(_vm.showWeekendMarkers),callback:function ($$v) {_vm.showWeekendMarkers=$$v},expression:"showWeekendMarkers"}})],1)],1)],1)],1),(_vm.accountExecutives.length)?_c('v-menu',{attrs:{"offset-y":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":"","color":_vm.ae.length ? 'primary' : 'button'}},'v-btn',attrs,false),on),[_vm._v(" AE "),_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v("fad fa-caret-down")])],1)]}}],null,false,3223958554)},[_c('v-list',_vm._l((_vm.accountExecutives),function(item){return _c('v-list-item',{key:item.id},[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","value":item.id},model:{value:(_vm.ae),callback:function ($$v) {_vm.ae=$$v},expression:"ae"}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(item.fullName)+" "),_c('v-chip',{staticClass:"ml-2",attrs:{"dark":"","x-small":""}},[_vm._v(_vm._s(item.count))])],1)])],1)}),1)],1):_vm._e()],1),_c('debounce-events',{attrs:{"events":{ change: 1000 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var change = ref.change;
return [_c('v-btn-toggle',{attrs:{"value":_vm.taskFilters,"dark":"","dense":"","multiple":""},on:{"change":function($event){change(function () { return _vm.taskFilters = $event; })}}},_vm._l((_vm.$options.taskLegendItems),function(item){return _c('v-btn',{key:item.value,attrs:{"small":"","color":item.value,"value":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]}}],null,false,416673242)})],1)],1),(!_vm.busy)?_c('v-data-table',{attrs:{"loading":_vm.$apollo.queries.tasks.loading || _vm.$apollo.queries.deadlinesAndReminders.loading,"dense":"","headers":_vm.$options.headers,"items":_vm.tableData,"search":_vm.filter,"item-class":function (item) { return item.class; },"mobile-breakpoint":"768","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-pagination":"","hide-default-footer":"","calculated-widths":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.ae",fn:function(ref){
var item = ref.item;
return [(item.ae)?_c('span',[_vm._v(" "+_vm._s(_vm._f("Initials")(item.ae))+" ")]):_vm._e()]}},{key:"item.assigned_to",fn:function(ref){
var item = ref.item;
return [(item.type == 'task')?[(item.assigned_to)?_c('v-chip',{staticClass:"w-100",class:item.assigned_to.department,staticStyle:{"justify-content":"center"},attrs:{"label":"","dark":"","x-small":"","target":"_blank","to":{ name: 'employee.details', params: { id: item.assigned_to.id } }}},[_vm._v(" "+_vm._s(item.assigned_to.full_name)+" ")]):_c('strong',{staticClass:"error--text"},[_vm._v("[UNASSIGNED]")])]:(item.type == 'note')?_c('strong',[_vm._v(_vm._s(_vm._f("capitalize")(item.class)))]):_vm._e()]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [(item.project && item.project.client.id > 0)?_c('router-link',{attrs:{"to":{ name: 'client.details', params: { id: item.project.client.id } },"target":"_blank"}},[_vm._v(_vm._s(item.project.client.name))]):(item.type!=='month-heading'&&item.type!=='weekend-heading')?_c('span',[_vm._v("[NO CLIENT]")]):_vm._e()]}},{key:"item.project",fn:function(ref){
var item = ref.item;
return [(item.project)?_c('router-link',{attrs:{"to":{ name: 'project.details', params: { id: item.project.id } },"target":"_blank"}},[_vm._v(" "+_vm._s(item.project.name)+" ")]):(item.type!=='month-heading'&&item.type!=='weekend-heading')?_c('span',[_vm._v("[NO PROJECT]")]):_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.type==='month-heading')?[_vm._v(_vm._s(item.title))]:(item.type==='weekend-heading')?[_c('div',{staticClass:"weekend-heading",attrs:{"data-start":item.saturday,"data-end":item.sunday}},[_c('span',{staticClass:"weekend-heading-title"},[_vm._v("weekend")])])]:_c('a',{attrs:{"href":"javascript:void(0)","data-uid":item.uid,"title":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(item.title)+" "),_c('v-icon',{attrs:{"x-small":"","right":"","dark":""}},[_vm._v("fad fa-edit")])],1)],2)]}},{key:"item.required_days",fn:function(ref){
var item = ref.item;
return [(item.type==='task')?_c('span',[_vm._v(_vm._s(item.required_days))]):_vm._e()]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",class:{itemoverdue: item.isOverdue, invisible: item.type.indexOf('-heading') >= 0 },attrs:{"title":_vm.pastDue(item)}},[_vm._v(" "+_vm._s(_vm._f("shortDate")(item.date))+" "),(item.isOverdue)?_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"overdue"}},[_vm._v("fas fa-exclamation")]):_vm._e()],1)]}},{key:"item.time_current",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.status == 'current')?[_vm._v(_vm._s(_vm._f("fromNow")(item.time_current)))]:_vm._e()],2)]}},{key:"item.extra",fn:function(ref){
var item = ref.item;
return [(item.type == 'task' || item.type == 'note')?_c('div',{staticClass:"d-flex"},[(item.type == 'task')?_c('add-note-button',{staticClass:"ma-1",attrs:{"objType":"task","objId":item.id,"showType":true}}):_vm._e(),_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","dark":"","color":"primary","min-width":"0","to":{name: 'timeline', params: {project_id: item.project.id}},"title":"View this project's timeline"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-calendar")])],1),(item.type == 'note')?_c('v-icon',{staticClass:"ml-1",attrs:{"small":"","title":"Hide","dark":""},on:{"click":function($event){return _vm.hide(item)}}},[_vm._v("fas fa-eye")]):_vm._e()],1):_vm._e()]}}],null,false,448779821)}):_vm._e()],1):_c('v-container',{staticClass:"fill-height flex-column justify-center align-center white--text"},[_c('p',{staticClass:"text-h6"},[_vm._v("Loading Timeline")]),_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("fad fa-cog fa-spin")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }