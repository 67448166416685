<!--
This template shows on timers\tasks (tasks.vue)
-->
<template>
<v-lazy :options="{threshold: 0.2}" :min-height="100" class="card" style="max-width:100%;">
	<v-card elevation="4" class="task-card-regular" :class="{
	['status-' + this.task.status]: true,
	'subtasks-pending': !subtasksComplete,
	'task-snoozed': $insight.tasks.hideFromCurrentTaskList(this.task),
	'task-pinned-to-current': $insight.tasks.pinToCurrentTaskList(this.task)}" :data-task-id="task.id">

		<!--region Card Header-->
		<div class="d-flex align-center">
			<v-card-subtitle class="text-truncate d-block">
				<i v-if="starred" class="fad fa-star mr-2"></i>
				<router-link v-if="client" :to="{name: 'client.details', params: {id: client.id}}" :title="client.name" class="mr-1">
					{{client.name}}
				</router-link> &nbsp;|&nbsp; 
				<router-link v-if="project" :to="{name: 'project.details', params: {id: project.id}}" :title="project.name">
					{{project.name}}
				</router-link>
			</v-card-subtitle>

			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn small v-bind="attrs" v-on="on" min-width="0" class="ml-auto mr-2">
						<v-icon small>fa fa-ellipsis-v</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item v-if="task.status==='pending' && !$insight.tasks.pinToCurrentTaskList(task)" @click="pinToCurrent">
						<v-list-item-title>Pin to Current</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="task.status==='pending' && $insight.tasks.pinToCurrentTaskList(task)" @click="unPinFromCurrent">
						<v-list-item-title>Un-Pin from Current</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="task.status==='current' && !$insight.tasks.hideFromCurrentTaskList(task)" @click="snoozeTask">
						<v-list-item-title>Snooze Task</v-list-item-title>
					</v-list-item>
					<v-list-item v-if="task.status==='current' && $insight.tasks.hideFromCurrentTaskList(task)" @click="unSnoozeTask">
						<v-list-item-title>Un-Snooze Task</v-list-item-title>
					</v-list-item>
					<v-list-item @click="addNote">
						<v-list-item-title>Add Note</v-list-item-title>
					</v-list-item>
					<v-list-item @click="reAssignTask">
						<v-list-item-title>Assign To</v-list-item-title>
					</v-list-item>
					<v-list-item :disabled="!completeable" @click="completeTask">
						<v-list-item-title>Complete Task</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>
		<!--endregion-->

		<!-- Unused? -->
		<!-- <div v-if="projectStats" class="progress" :title="projectStats.totalTime + ' / ' + projectStats.allocated">
			<div class="progress-bar progress-bar-orange" :style="progressBarStyles"></div>
		</div> -->

		<!--region Card Body-->
		<v-card-text class="card-body py-0">

			<small><strong>TASK</strong></small><br>
			<a href="javascript:void(0)" @click="onTaskClick($event)" :title="task.name" class="task-name text-truncate">{{task.name}}</a>

			<v-expansion-panels v-if="task.description" accordion flat class="mt-2">
				<v-expansion-panel>
					<v-expansion-panel-header color="pa-0">Description</v-expansion-panel-header>
					<v-expansion-panel-content class="new-lines">{{task.description}}</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<template v-if="subtasks.length">
				<a data-toggle="collapse" :href="'#task-subtasks-' + task.id" aria-expanded="false" :aria-controls="`task-subtasks-${task.id}`" class="badge badge-light d-block">
					Subtasks <strong>{{subtasksComplete ? 'Completed' : 'Pending'}}</strong>
					<i class="fa fa-chevron-right"></i>
				</a>
				<div class="collapse" :id="'task-subtasks-' + task.id">
					<div class="collapse-inner">
						<table class="table small table-sm mt-2">
							<thead>
							<tr>
								<th>Subtask</th>
								<th>Assigned</th>
								<th>Status</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="subtask in subtasks" :key="subtask.id">
								<td>{{subtask.name}}</td>
								<td>{{$db.getModel('person', subtask.assigned_to_user_id) | FullName('[NONE]')}}</td>
								<td>{{getSubtaskStatus(subtask)}}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>

		</v-card-text>
		<!--endregion-->

		<!--region Card Footer-->
		<v-card-actions class="card-footer d-flex align-items-baseline justify-content-between">

			<v-tooltip top v-if="task && task.timeline_due_date">
				<template v-slot:activator="{ on, attrs }">
					<v-chip label x-small v-bind="attrs" v-on="on" class="mr-2 task-due white--text" :color="$insight.tasks.taskStatus(task)">
						{{task.estimated_completion_date | shortDate}}
					</v-chip>
				</template>
				<span>Task Due Date</span>
			</v-tooltip>

			<span v-if="pastDue">{{ pastDue }}</span>

			<v-btn small tile outlined min-width="0" class="ml-auto" title="Start Timer" :disabled="timerCreating" @click="createTimer" color="primary">
				<v-icon v-if="!timerCreating" small outlined>fas fa-play</v-icon>
				<v-icon v-else small outlined>fas fa-cog fa-spin</v-icon>
			</v-btn>
		</v-card-actions>
		<!--endregion-->
	</v-card>
</v-lazy>
</template>

<script>
	import ModelCardMixin from '../../mixins/model-card-mixin';
	import TaskCardMixin from '../../mixins/task-card-mixin';

	export default {
        name: "taskCard",

        mixins: [
            ModelCardMixin,
            TaskCardMixin
        ],

        props: {
            task: {
                type:     Object,
                required: true
            }
        },

        data() {
            return {
                timerCreating: false
            };
        },

		computed: {

		},
        methods: {
            createTimer() {
                this.timerCreating = true;

                this.$store
                    .dispatch("createTimer", this.task.id)
                    .catch(() => {
                        this.$snotify.error("Error!", "Start Timer");
                    })
                    .then(() => {
                        this.timerCreating = false;
                    });
            }
        }
    };
</script>

<style lang="scss" scoped>
	.badge {
		text-align: left;
	}

	.task-due {
		line-height: 1;
		padding: 0px 5px;
		font-size: 12px;
	}

	// .status-pending, 
	.task-hidden {
		opacity: 0.4;
		&:hover, &:focus-within {
			opacity: 1;
		}
	}

	.btn-green {
		font-size: 8px;
	}

	.card-body {
		.task-name {
			// font-size: 16px;
			display: block;
		}
	}

	::v-deep .v-card__subtitle {
		padding: 8px 16px;
	}

	.card {
		border: 0;
		margin: 10px 0 0;
		flex-basis: 100%;
		.v-card {
			height: 100%;
		}
		&.layout-grid {
			@media (min-width: 768px) {
				margin: 10px 10px 0 0;
				flex-basis: calc(50% - 10px);
				width: calc(50% - 10px);
			}
		}
	}

</style>
