// AjaxResource by Daniel Flynn
//   A replacement for vue-resource. I wanted more control over how requests are built and processed

class AjaxResource {
	constructor(masterURL, customActions, callbacks) {
		this.masterURL = masterURL;
		this.callbacks = callbacks || {};

		customActions = $.extend(true, {}, AjaxResource.defaultActions, customActions);
		for (let i in customActions) {
			if (customActions[i])
				this._installAction(i, customActions[i]);
		}
	}

	_installAction(name, opts) {
		this[name] = function(params, payload, columns) {
			if (typeof params === 'string') {
				columns = params;
				params = {};
				payload = {};
			}
			else {
				params = params || {};
				if (typeof payload === 'string') {
					columns = payload;
					payload = {};
				}
				else {
					payload = payload || {};
					columns = columns || '';
				}
			}

			let myOpts = $.extend({
				method: 'GET',
				url: this.masterURL,
				xhrFields: AjaxResource.defaultXhrFields,
				beforeSend: false,
				onResponse: false,
				onError: false,
				onComplete: false
			}, opts);

			let that = this;
			let callbacks = {};

			for (let i in AjaxResource.defaultHooks) {
				callbacks[i] = $.Callbacks('stopOnFalse');

				if (myOpts[i])
					callbacks[i].add(myOpts[i]);

				if (this.callbacks[i])
					callbacks[i].add(this.callbacks[i]);

				if (AjaxResource.defaultHooks[i])
					callbacks[i].add(AjaxResource.defaultHooks[i]);
			}

			return new Promise((resolve, reject) => {
				$.ajax({
					url: that._getUrl(myOpts.url, params, columns),
					type: myOpts.method,
					dataType: 'json',
					data: payload,
					xhrFields: myOpts.xhrFields,
					beforeSend: function(request) {
						callbacks.beforeSend.fire(request);
					},
					complete: function(xhr) {
						let payload = {
							status: xhr.status,
							xhr: xhr
						};

						if (typeof xhr.responseJSON !== 'undefined') {
							payload.json = xhr.responseJSON;
							callbacks.onResponse.fire(payload);
							resolve(payload);
						}
						else {
							callbacks.onError.fire(payload);
							reject(payload);
						}

						setTimeout(function() {
							callbacks.onComplete.fire(payload);
						}, 0);
					}
				});
			});
		};
	}

	_getUrl (url, params, columns) {
		params = params || {};

		url = url.replace(/(\{.+?\})/g, function(match) {
			match = match.substr(1, match.length - 2);
			let variable = match.replace(/\W+/g, '');

			if (typeof params[variable] === 'undefined')
				return '';

			return match.replace(/\w+/, params[variable]);
		});

		if (columns) {
			url += '?fields=' + columns;
		}

		return url;
	}
}

AjaxResource.defaultActions = {
	get: {
		method: 'GET'
	},

	new: {
		method: 'POST'
	},

	update: {
		method: 'PUT'
	},

	delete: {
		method: 'DELETE'
	}
};

AjaxResource.defaultHooks = {
	beforeSend: false,
	onResponse: false,
	onError: false,
	onComplete: false
};

AjaxResource.defaultXhrFields = {};

export default AjaxResource;