<template>

	<!--  PROJECT DETAILS PAGE -->
	<div v-if="layout==='projectDetailsDataList'">
		<!-- not ideal  -->
		<data-list-item v-for="fieldDef in metaFields" :key="fieldDef.id" :label="fieldDef.name">
			<div class="d-flex">
				<!--email-->
				<a v-if="fieldDef.type === 'email'" :href="`mailto:${metaValues[fieldDef.machine_name]}`">{{ metaValues[fieldDef.machine_name] }}</a>
				<!--link-->
				<a v-else-if="fieldDef.type === 'link' && hasValue(metaValues[fieldDef.machine_name])" :href="website(metaValues[fieldDef.machine_name])" target="_blank" class="text-truncate">
					{{ website(metaValues[fieldDef.machine_name]) }}
				</a>
				<!--date-->
				<span v-else-if="fieldDef.type === 'date'">{{ metaValues[fieldDef.machine_name] | shortDate }}</span>
				<!--checkbox-->
				<span v-else-if="fieldDef.type === 'boolean'">
					<template v-if="metaValues[fieldDef.machine_name]">
						<v-icon small color="green accent-3" class="ml-2">fal fa-check</v-icon>
					</template>
					<template v-else>
						<v-icon small color="grey" class="ml-2">fal fa-times</v-icon>
					</template>
				</span>
				<!--checkboxes-->
				<span v-else-if="fieldDef.type === 'checkboxes'">{{ metaValues[fieldDef.machine_name] && metaValues[fieldDef.machine_name].join(', ') }}</span>
				<!--person,client,project-->
				<router-link v-else-if="'client project person'.indexOf(fieldDef.type)>=0" :to="referencedObjectRoute(fieldDef)">{{ referencedObjectName(fieldDef) }}</router-link>
				<!--default-->
				<span v-else>{{ metaValues[fieldDef.machine_name] }}</span>
				<!-- copy button -->
				<v-btn v-if="hasValue(metaValues[fieldDef.machine_name]) && fieldDef.type === 'link'"
					   class="ml-auto" small min-width="0"
					   v-clipboard:copy="metaValues[fieldDef.machine_name]"
					   v-clipboard:success="copiedToClipboard"
					   title="Copy text to clipboard">
					<v-icon small>fad fa-clipboard</v-icon>
				</v-btn>
			</div>
		</data-list-item>
	</div>

	<!--  EVERYWHERE ELSE -->
	<div v-else class="meta-list">
		<!-- intentionally classified items by their data type instead of their name -->
		
		<div v-for="fieldDef in metaFields" :key="fieldDef.id" class="d-flex py-2 border-bottom">
			<!--title-->
			<strong class="mr-2 text-no-wrap">{{ fieldDef.name }}:</strong>

			<!--email-->
			<a v-if="fieldDef.type === 'email'" :href="`mailto:${metaValues[fieldDef.machine_name]}`">
				{{ metaValues[fieldDef.machine_name] }}
			</a>

			<!--link-->
			<a v-else-if="fieldDef.type === 'link' && hasValue(metaValues[fieldDef.machine_name])" class="text-truncate" :href="website(metaValues[fieldDef.machine_name])" target="_blank">
				{{ website(metaValues[fieldDef.machine_name]) }}
			</a>

			<!--date-->
			<span v-else-if="fieldDef.type === 'date'" class="item-meta-item item-meta-item-value">
				{{ metaValues[fieldDef.machine_name] | shortDate }}
			</span>

			<!--checkbox-->
			<span v-else-if="fieldDef.type === 'boolean'">
				<template v-if="metaValues[fieldDef.machine_name]"><i class="fas fa-check color-green"></i></template>
				<template v-else><i class="fas fa-times color-gray"></i></template>
			</span>
			<!--checkboxes-->
			<span v-else-if="fieldDef.type === 'checkboxes'">{{ metaValues[fieldDef.machine_name] && metaValues[fieldDef.machine_name].join(', ') }}</span>
			<!--person,client,project-->
			<router-link v-else-if="'client project person'.indexOf(fieldDef.type)>=0" :to="referencedObjectRoute(fieldDef)">{{ referencedObjectName(fieldDef) }}</router-link>
			<!--default-->
			<span v-else class="item-meta-item item-meta-item-value">{{ metaValues[fieldDef.machine_name] }}</span>

			<!-- copy button -->
			<v-btn v-if="hasValue(metaValues[fieldDef.machine_name]) && fieldDef.type === 'link'" class="ml-auto" color="button" x-small min-width="0"
				   v-clipboard:copy="metaValues[fieldDef.machine_name]"
				   v-clipboard:success="copiedToClipboard"
				   title="Copy text to clipboard">
				<v-icon x-small>fad fa-clipboard</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	import dataListItem from "@/components/dataListItem";

	export default {
		name: "metaList",

		props: ["model", "layout"],

		components: {
			dataListItem,
		},
		computed: {
			metaFields() {
				let fields = [];

				if (this.model && this.model._meta_types) {
					for (let field in this.model._meta_types) {
						if (this.model._meta_types[field].protected < 1) {
							fields.push(this.model._meta_types[field]);
						}
					}
				}

				fields.sort((a, b) => {
					let sort = a.order - b.order;
					if (sort === 0) {
						sort =
							moment(a.creation_date).valueOf() -
							moment(b.creation_date).valueOf();
					}

					return sort;
				});

				return fields;
			},
			metaValues() {
				if (!this.model) {
					return {};
				}

				if (this.model._meta_values) {
					return this.model._meta_values;
				}

				return this.model;
			}
		},

		methods: {
			hasValue(text) {
				try {
					let value = text.toString().trim();
					return value.length > 0 && value !== "?";
				} catch (ignore) {
					return false;
				}
			},

			copiedToClipboard(data) {
				this.$snotify.success(`Copied to clipboard: ${data.text}`);
			},
			website(value) {
				if (typeof value == "string" && !value.match(/^https?:|^S:\\/)) {
					return `http://${value}`;
				}
				if (typeof value == "string" && value.match(/^S:\\/)) {
					return `file://${value}";`;
				}
				return value;
			},
			referencedObjectRoute(fieldDef) {
				let type = fieldDef.type.toLowerCase().replace("person", "people");

				if (!this.metaValues[fieldDef.machine_name]) {
					return {};
				}

				return {
					name: `${type}.details`,
					params: {
						id: this.metaValues[fieldDef.machine_name],
					},
				};
			},
			referencedObjectName(fieldDef) {
				let meta = this.metaValues[fieldDef.machine_name],
					type = fieldDef.type.toLowerCase();

				if (!meta) {
					return "";
				}

				meta = this.$db.getModel(type, meta);

				if (!meta) {
					return "";
				}
				if ("name" in meta) {
					return meta.name;
				}
				if ("first_name" in meta) {
					return this.$options.filters.FullName(meta);
				}

				return "";
			},
		},
	};
</script>

<style scoped lang="scss">
	.meta-list {
		> div {
			border-bottom: 1px solid var(--v-dividers-base);
		}
	}
</style>
