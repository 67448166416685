import Vue from 'vue';
import settings from './settings';

export default new Vue({
	created() {
		if (settings.devMode) {
			window.storage = {
				get: this.getSetting,
				set: this.setSetting,
			};
		}

		this._loadData();
		window.addEventListener('storage', this._loadData);
	},

	destroyed() {
		window.removeEventListener('storage', this._loadData);
	},

	data() {
		return {
			localData: {},
			sessionData: {}
		};
	},

	methods: {
		_loadData() {
			['local', 'session'].forEach(type => {
				let keys = Object.keys(this[type + 'Data']);

				Object.entries(window[type + 'Storage'])
					.forEach(([key, val]) => {
						try { val = JSON.parse(val); } catch { }

						Vue.set(this[type + 'Data'], key, val);
						let ndx = keys.indexOf(key);
						if (ndx >= 0) {
							keys.splice(ndx, 1);
						}
					});
				
				keys.forEach(k => Vue.delete(this[type + 'Data'], k));
			}, {})
		},

		getSetting(key, def = null, storage = 'local') {
			const data = this[storage + 'Data'];
			return data.hasOwnProperty(key)
				? (data[key] == null ? def : data[key])
				: def;
		},

		setSetting(key, val, storage = 'local') {
			if (val === null) {
				window[storage + 'Storage'].removeItem(key);
				Vue.delete(this[storage + 'Data'], key);
			}
			else {
				window[storage + 'Storage'].setItem(key, JSON.stringify(val));
				Vue.set(this[storage + 'Data'], key, val);
			}
		}
	}
});