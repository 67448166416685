<!--
This template shows on timers\tasks (tasks.vue)
It is a copy of taskCard-1.vue with a different visual structure.
-->
<template>
<v-lazy :options="{threshold: 0.2}" :min-height="40">
	<!-- d-flex align-items-center -->
	<v-card outlined elevation="1" class="task-card task-card-slim d-flex align-items-baseline mb-1 py-1"
			 :class="{
	['status-' + this.task.status]: true,
	'subtasks-pending': !subtasksComplete,
	'task-snoozed': $insight.tasks.hideFromCurrentTaskList(this.task),
	'task-pinned-to-current': $insight.tasks.pinToCurrentTaskList(this.task)}" :data-task-id="task.id">

		<div class="col-2 py-1 text-truncate">
			<v-icon v-if="starred" small class="mr-2" color="primary">fad fa-star</v-icon>
			<router-link v-if="client" :to="{name: 'client.details', params: {id: client.id}}">
				{{client.name}}
			</router-link>
		</div>

		<div class="col-2 py-1 text-truncate">
			<router-link v-if="project" :to="{name: 'project.details', params: {id: project.id}}">
				{{project.name}}
			</router-link>
		</div>

		<div class="col-4 py-1 col-task">

			<a href="javascript:void(0)" @click="onTaskClick($event)" :title="task.name" class="task-name">{{task.name}}</a>

			<v-expansion-panels v-if="task.description" accordion flat class="mt-2">
				<v-expansion-panel>
					<v-expansion-panel-header color="pa-0" min-height="0">Description</v-expansion-panel-header>
					<v-expansion-panel-content v-html="task.description" class="new-lines"></v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<!-- are we still using subtasks our projects? -->
			<template v-if="subtasks.length">
				<a data-toggle="collapse" :href="'#task-subtasks-' + task.id" aria-expanded="false" :aria-controls="`task-subtasks-${task.id}`" class="badge badge-light d-block">
					Subtasks <strong>{{subtasksComplete ? 'Completed' : 'Pending'}}</strong>
					<i class="fa fa-chevron-right"></i>
				</a>
				<div class="collapse" :id="'task-subtasks-' + task.id">
					<div class="collapse-inner">
						<table class="table small table-sm mt-2">
							<thead>
							<tr>
								<th>Subtask</th>
								<th>Assigned</th>
								<th>Status</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="subtask in subtasks" :key="subtask.id">
								<td>{{subtask.name}}</td>
								<td>{{$db.getModel('person', subtask.assigned_to_user_id) | FullName('[NONE]')}}</td>
								<td>{{getSubtaskStatus(subtask)}}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</div>

		<div class="col-2 py-1 col-due-date">

			<v-tooltip top v-if="task && task.timeline_due_date">
				<template v-slot:activator="{ on, attrs }">
					<v-chip label x-small v-bind="attrs" v-on="on" class="mr-2 task-due white--text" :color="$insight.tasks.taskStatus(task)">
						{{task.estimated_completion_date | shortDate}}
					</v-chip>
				</template>
				<span>Task Due Date</span>
			</v-tooltip>
			<p v-if="pastDue" class="mb-0">{{ pastDue }}</p>
		</div>

		<div class="col-2 py-1 col-options d-flex align-items-baseline">
			<div class="">{{assignedByPerson | Initials}}</div>

			<div class=" ml-auto">
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn small v-bind="attrs" v-on="on" min-width="0" class="mr-2">
							<v-icon small>fa fa-ellipsis-h</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item v-if="task.status==='pending' && !$insight.tasks.pinToCurrentTaskList(task)" @click="pinToCurrent">
							<v-list-item-title>Pin to Current</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="task.status==='pending' && $insight.tasks.pinToCurrentTaskList(task)" @click="unPinFromCurrent">
							<v-list-item-title>Un-Pin from Current</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="task.status==='current' && !$insight.tasks.hideFromCurrentTaskList(task)" @click="snoozeTask">
							<v-list-item-title>Snooze Task</v-list-item-title>
						</v-list-item>
						<v-list-item v-if="task.status==='current' && $insight.tasks.hideFromCurrentTaskList(task)" @click="unSnoozeTask">
							<v-list-item-title>Un-Snooze Task</v-list-item-title>
						</v-list-item>
						<v-list-item @click="addNote">
							<v-list-item-title>Add Note</v-list-item-title>
						</v-list-item>
						<v-list-item @click="reAssignTask">
							<v-list-item-title>Assign To</v-list-item-title>
						</v-list-item>
						<v-list-item :disabled="!completeable" @click="completeTask">
							<v-list-item-title>Complete Task</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>

				<v-btn small outlined min-width="0" title="Start Timer" :disabled="timerCreating" @click="createTimer" color="primary">
					<v-icon v-if="!timerCreating" small outlined>fas fa-play</v-icon>
					<v-icon v-else x-small outlined>fas fa-cog fa-spin</v-icon>
				</v-btn>

			</div>
		</div>

	</v-card>
</v-lazy>
</template>

<script>
	import ModelCardMixin from '../../mixins/model-card-mixin';
	import TaskCardMixin from '../../mixins/task-card-mixin';

	export default {
		name: "taskCard-slim",

		mixins: [
			ModelCardMixin,
			TaskCardMixin
		],

		props: {
			task: {
				type:     Object,
				required: true
			}
		},

		data() {
			return {
				timerCreating: false
			};
		},

		computed: {
			assignedByPerson() {
				let userId = this.task && this.task.assigned_by_user_id;
				return userId ? this.$db.getModel('person', userId) : null;
			}
		},
		methods:  {
			createTimer() {
				this.timerCreating = true;

				this.$store
					.dispatch("createTimer", this.task.id)
					.catch(() => {
						this.$snotify.error("Error!", "Start Timer");
					})
					.then(() => {
						this.timerCreating = false;
					});
			}
		}
	};
</script>

<style lang="scss" scoped>

	// .status-pending, 
	.task-hidden {
		opacity: 0.4;

		&:hover, &:focus-within {
			opacity: 1;
		}
	}

</style>
