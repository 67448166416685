<script>
	export default {
		props: {
			tag: String,

			input: {
				required: true
			},

			transformer: {
				validator(val) {
					if (Array.isArray(val)) {
						return val.every(fn => typeof fn == 'function');
					}
					
					return typeof val == 'function';
				},
				default: a => a
			},

			debug: Boolean
		},

		render(h) {
			let transformers = this.transformer;
			if (!Array.isArray(this.transformer)) {
				transformers = [transformers];
			}

			if (this.debug) {
				performance.mark('before-transform');
			}

			let params = {
				newValue: transformers.reduce((acc, cur) => {
					if (acc instanceof Promise) {
						return acc.then(cur);
					}
					
					return cur(acc);
				}, this.input)
			};

			if (this.debug) {
				performance.mark('after-transform');
				params.duration = performance.measure('transform-exec-time', 'before-transform', 'after-transform').duration;
				performance.clearMarks();
				performance.clearMeasures();
			}

			let result = this.$scopedSlots.default(params);

			if (this.tag) {
				result = h(this.tag, result);
			}

			return result;
		}
	}
</script>