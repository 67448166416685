<template>
	<v-container fluid>
		<v-card class="mb-4" :loading="$apollo.queries.deadlinesAndReminders.loading">
			<v-card-title class="align-start">
				Company Calendar

				<v-menu v-if="accountExecutives && accountExecutives.length" offset-y open-on-hover :close-on-content-click="false">
					<template v-slot:activator="{ on, attrs }">
						<v-btn small v-bind="attrs" v-on="on" class="ml-auto" :color="ae.length ? 'primary' : ''">
							AE <v-icon small class="ml-3">fad fa-caret-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item v-for="item in accountExecutives" :key="item.id">
							<v-checkbox dense hide-details :value="item.ae.id" v-model="ae">
								<div slot="label">
									{{ item.ae.full_name }}
									<v-chip x-small right>{{ item.count}}</v-chip>
								</div>
							</v-checkbox>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-card-title>
			<v-card-subtitle>Deadlines and Reminders for all projects</v-card-subtitle>
		</v-card>

		<v-toolbar flat>
			<v-btn fab text small color="primary" @click="$refs.calendar.prev()">
				<v-icon small>
					fas fa-chevron-left
				</v-icon>
			</v-btn>
			<v-btn fab text small color="primary" @click="$refs.calendar.next()">
				<v-icon small>
					fas fa-chevron-right
				</v-icon>
			</v-btn>
			<v-toolbar-title class="ml-5">
				{{ calendarTitle }}
			</v-toolbar-title>
		</v-toolbar>

		<v-calendar ref="calendar" type="month" color="primary" v-model="value" :events="allNotes" :event-overlap-mode="mode" @click:event="showEvent">
			<template v-slot:event="{ event }">
				<span class="mx-1" style="width:100%;">
					{{event.name}} -
					{{event.details.project.name}}
				</span>
			</template>
		</v-calendar>

		<v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
			<v-card v-if="selectedEvent" color="button" min-width="350px" max-width="500px" flat>
				<v-toolbar :color="selectedEvent.color" class="white--text">
					<v-toolbar-title>{{selectedEvent.details.note_type.charAt(0).toUpperCase() + selectedEvent.details.note_type.slice(1)}}</v-toolbar-title>
				</v-toolbar>
				<v-card-text>
					<h2 class="mb-3">
						{{selectedEvent.details.content}}
					</h2>
					<div class="body-1">
						Project: <router-link :to="{name:'project.details', params:{id: selectedEvent.details.project.id}}" target="_blank" v-html="selectedEvent.details.project.name"></router-link>
						<span v-if="selectedEvent.details.project.client"> <br>
							Client: <router-link :to="{name:'client.details', params:{id: selectedEvent.details.project.client.id}}" target="_blank" v-html="selectedEvent.details.project.client.name"></router-link>
						</span>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn @click="selectedOpen = false" class="ml-auto">
						Close
					</v-btn>
					<v-btn color="primary" @click="editNote(selectedEvent.details.id)">
						Edit {{selectedEvent.details.note_type}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-menu>

	</v-container>
</template>

<script>
	import moment from "moment";

	export default {
		name: "calendar",

		mixins: [
			{
				data() {
					return {
						calendarTitle: "",
					};
				},
			},
		],

		props: {
			project: Object,
		},
		data() {
			return {
				mode: "column", // stack || column
				value: "",
				selectedEvent: null,
				selectedElement: null,
				selectedOpen: false,
				ae: [],
			};
		},

		mounted() {
			this.$refs.calendar.checkChange();

			this.$watch(
				"$refs.calendar.title",
				(title) => {
					this.calendarTitle = title;
				},
				{ immediate: true }
			);
		},

		apollo: {
			deadlinesAndReminders: {
				query: gql`
					query getDeadlinesAndReminders($filterParam: QueryFilter!) {
						deadlinesAndReminders: notes
							@filter(param: $filterParam)
							@sort(param: [{ field: "date", order: desc }]) {
							total
							nodes {
								node {
									id
									content
									modified_date
									date
									note_type
									hidden
									project {
										id
										name
										account_executive_user {
											id
											full_name
										}
										client {
											id
											name
										}
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "date",
							op: ">=",
							value: moment()
								.subtract(6, "months")
								.format("YYYY-MM-DD"),
						},
						{
							field: "note_type",
							op: "in",
							value: ["deadline", "reminder"],
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
		},

		methods: {
			showEvent({ nativeEvent, event }) {
				const open = () => {
					this.selectedEvent = event;
					this.selectedElement = nativeEvent.target;

					setTimeout(() => {
						this.selectedOpen = true;
					}, 10);
				};
				if (this.selectedOpen) {
					this.selectedOpen = false;
					setTimeout(open, 10);
				} else {
					open();
				}
				nativeEvent.stopPropagation();
			},
			editNote(note_id) {
				this.$modalService
					.create("noteAddEdit", {
						title: (note) => `Update ${note.note_type}`,
						noteId: note_id,
					})
					.on("save", (e, task) => {
						// TODO: Update vue-apollo cache
						this.$apollo.queries.deadlinesAndReminders.refetch();
					});
			},
		},

		computed: {
			allNotes() {
				let data = this.calendarEntries;

				if (this.ae.length) {
					return data.filter((item) => {
						if (!item.details.project.account_executive_user) {
							return false;
						}

						return this.ae.includes(
							item.details.project.account_executive_user.id
						);
					});
				}

				return data;
			},

			accountExecutives() {
				if (!this.deadlinesAndReminders) {
					return null;
				}

				return Linq.from(this.deadlinesAndReminders.nodes)
					.select(row => row.node)
					.where(row => row.project.account_executive_user)
					.groupBy(
						(i) => i.project.account_executive_user.id,
						(ae, data) => {
							return {
								ae: data.first().project.account_executive_user,
								count: data.count(),
								// notes: data.select(({ account_executive_user, ...o }) => o).toArray(),
							};
						}
					)
					.orderBy((i) => i.ae.full_name)
					.toArray();
			},

			calendarEntries() {
				if (!this.deadlinesAndReminders) {
					return [];
				}

				return Linq.from(this.deadlinesAndReminders.nodes)
					.select(row => row.node)	
					.select((i) => {
						return {
							name: i.content,
							start: i.date,
							color: i.note_type,
							timed: false,
							details: i,
						};
					})
					.toArray();
			},
		},
	};
</script>

<style scoped lang="scss">
	::v-deep .v-calendar-weekly__day {
		min-height: 100px;
	}
</style>