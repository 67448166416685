<template>
    <div>
        <v-card class="mb-4">
            <v-card-title class="d-flex">
                Utilization Rate
                <date-range-picker v-model="dates" class="ml-auto"></date-range-picker>
                <v-progress-linear indeterminate height="2" v-show="busy"></v-progress-linear>
            </v-card-title>
        </v-card>
        <v-row>
            <v-col>
                <line-chart :data="chart.data">
                    <template #empty>
                        <v-card class="pa-4">There is no data for this time period.</v-card>
                    </template>
                </line-chart>

                <v-data-table :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc" :items="table.data" :headers="table.headers" v-if="table.data.length" dense disable-pagination  hide-default-footer></v-data-table>

            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment'
import dateRangePicker from '@/components/fields/dateRangePicker.vue'
import lineChart from './lineChart.vue';

export default {
    name:       "utilization",
    components: {
        dateRangePicker,
        lineChart
    },
    data() {
        return {
            busy:  false,
            dates: [
                moment().subtract(3, "months").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
            ],
            chart: {
                data: null
            },
            table: {
                headers: [],
                data:    [],
                sortBy: 'name',
                sortDesc: false
            }
        }
    },
    computed: {},
    methods:  {
        fetchData() {
            this.busy = true;
            this.$xhrRequest.send("get", `/api/reports/staff-utilization`, {dates: this.dates}).then((response) => {
                this.chart.data = response.chart.data;
                Object.assign(this.table, response.table);
                this.busy = false;
            });
        }
    },
    watch:    {
        dates: {
            handler: function (to, from) {
                console.log('fetching report for', to.join(' to '));
                this.fetchData();
            }
        }
    }

}
</script>

<style scoped>

</style>
