import { render, staticRenderFns } from "./chartAssignedTasksByDepartment.vue?vue&type=template&id=0129c5cc&scoped=true&"
import script from "./chartAssignedTasksByDepartment.vue?vue&type=script&lang=js&"
export * from "./chartAssignedTasksByDepartment.vue?vue&type=script&lang=js&"
import style0 from "./chartAssignedTasksByDepartment.vue?vue&type=style&index=0&id=0129c5cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0129c5cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.3_0f7bc6efe1949d16b55ed303eb0ec848/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCardText,VCardTitle,VSheet})
