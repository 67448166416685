import Vue from 'vue';

import metaInput from '@/components/metaInput';
import departmentBadge from '@/components/departmentBadge';

import paginator from '@/components/renderless/paginator';
import promiseResolver from '@/components/renderless/promiseResolver';
import dataTransform from '@/components/renderless/data-transform';
import debounceEvents from '@/components/renderless/debounce-events';
import dbAddModels from '@/components/renderless/db-add-models';
import templateData from '@/components/renderless/templateData';
import objectLink from '@/components/objectLink';

Vue.component('metaInput', metaInput);
Vue.component('departmentBadge', departmentBadge);

Vue.component('paginator', paginator);
Vue.component('promise-resolver', promiseResolver);
Vue.component('data-transform', dataTransform);
Vue.component('debounce-events', debounceEvents);
Vue.component('db-add-models', dbAddModels);
Vue.component('template-data', templateData);
Vue.component('object-link', objectLink);