import moment from 'moment';

export class EnumValue {
	constructor(value) {
		this.value = value;
	}

	toString() {
		return this.value;
	}
}

export function GraphqlStringify(data) {
	switch (typeof data) {
		case 'number':
		case 'bigint':
			return data;
		case 'string':
			return `"${data.replace(/(\\|")/g, '\\$1')}"`;
		case 'boolean':
			return data ? 'true' : 'false';
		case 'object':
			if (data === null) {
				return 'null';
			}
			else if (Array.isArray(data)) {
				return '[' + data.map(i => GraphqlStringify(i)).join(',') + ']';
			}
			else if (data instanceof EnumValue) {
				return data;
			}

			return '{' + Object.entries(data).map(([k, v]) => `${k}: ${GraphqlStringify(v)}`).join(' ') + '}';
		
		default:
			return '';
	}
}

let tmp;

/**
 * Get Project Hour stats
 */
export function ProjectHourStats(id) {
	return gql`
		query getProjectHourStats {
			projectStats: project(id: ${id}) {
				name
				time_spent @formatDuration(format: "H.2")
				hours_allocated
				budget_percentage
			}
		}
	`;
}


/**
 * Get Tasks for a Project
 */

export function ProjectTasks(id) {
	let filters = {
		field: 'project.id',
		op: '=',
		value: id
	};

	return gql`
		query getProjectTasks {
			projectTasks: tasks
				@filter(param: ${GraphqlStringify(filters)})
				@sort(param: [{field: "completion_order" order: desc}])
			{
				nodes {
					node {
						id
						name
						description
						status
						project_id
						assigned_to {
							id
							uid
							full_name
							department
						}
						assigned_by {
							id
							uid
							full_name
							department
						}
						assigned_to_user_id
						assigned_by_user_id
						estimated_completion_date
						timeline_due_date @formatDate(format: "Y-m-d")
						complete_date @formatDate(format: "Y-m-d")
						required_days
						_meta_values
						previous_task_ids
						completion_order
					}
				}
			}
		}
	`;
}

/**
 * Get Activate Employees
 * only as fields as needed
 */
tmp = {
	joinOp: new EnumValue("and"),
	filters: [
		{
			field: "is_employee",
			op: "=",
			value: true
		},
		{
			field: "inactive",
			op: "=",
			value: false
		}
	]
};
export const ActiveEmployees = gql`
	query getActiveEmployees {
		getActiveEmployees: persons
			@filter(param: ${GraphqlStringify(tmp)})
			@sort(param: [{ field: "first_name", order: asc }]) 
		{
			nodes {
				node {
					id
					full_name
					email
					... on Employee {
						department
						meta_avatar
					}
				}
			}
		}
	}
`;

/**
 * Get AEs
 */
export function FactoryAEQuery({
	filters = null,
	statuses = [],
	categories = [],
	aes = [],
	showWithTimers = false,
	showWithActiveTimers = false,
	searchTxt = ''
} = {}) {
	if (!filters) {
		filters = [];

		if (statuses.length) {
			filters.push({
				field: "status",
				op: "in",
				value: statuses
			});
		}

		if (showWithTimers || showWithActiveTimers) {
			filters.push({
				field: "timers.id",
				op: "!=",
				value: null,
			});

			if (showWithActiveTimers) {
				filters.push({
					field: "timers.stop",
					op: "=",
					value: null,
				});
			}
			else if (showWithTimers) {
				filters.push({
					joinOp: new EnumValue("or"),
					filters: [
						{
							field: "timers.submit_date",
							op: "=",
							value: null,
						},
						{
							joinOp: new EnumValue("and"),
							filters: [
								{
									field: "timers.submit_date",
									op: ">=",
									value: moment()
										.startOf("day")
										.format("YYYY-MM-DD HH:mm:ss"),
								},
								{
									field: "timers.submit_date",
									op: "<=",
									value: moment()
										.startOf("day")
										.format("YYYY-MM-DD HH:mm:ss"),
								},
							],
						},
					],
				});
			}
		}

		if (aes.length) {
			filters.push({
				field: "account_executive_user.id",
				op: "in",
				value: aes,
			});
		}

		if (categories.length) {
			filters.push({
				field: "category",
				op: "in",
				value: categories
			});
		}

		if (searchTxt) {
			searchTxt = searchTxt.replace(/(%)/g, '\\$1');

			filters.push({
				joinOp: new EnumValue("or"),
				filters: [
					{
						field: "name",
						op: "like",
						value: `%${searchTxt}%`,
					},
					{
						field: "client.name",
						op: "like",
						value: `%${searchTxt}%`,
					},
					{
						field: "type",
						op: "like",
						value: `%${searchTxt}%`
					}
				],
			});
		}

		filters = {
			joinOp: new EnumValue('and'),
			filters
		};
	}

	return gql`
		query GetAccountExecutives {
			aes: projects
				@filter(param: ${GraphqlStringify(filters)})
				@groupBy(fields: ["account_executive_user.id"])
				@sort(
					param: [
						{
							field: "account_executive_user.first_name"
							order: asc
						}
						{
							field: "account_executive_user.last_name"
							order: asc
						}
					]
				)
			{
				# sql
				nodes {
					group_total
					node {
						account_executive_user {
							id
							full_name
						}
					}
				}
			}
		}
	`;
}