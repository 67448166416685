import AjaxResource from './base'
import settings from '../utils/settings'

export default new AjaxResource(
	settings.url + '/api/task{/id}',
	{
		list: {
			method: 'GET',
			url: settings.url + '/api/task/list'
		},
		timeline: {
			method: 'GET',
			url: settings.url + '/api/task/timeline'
		},
		department: {
			method: 'GET',
			url: settings.url + '/api/task/department{/department}'
		}
	}
);