<template>
	<v-container fluid v-if="person.id">
		<v-row>
			<v-col cols="12" md="6">

				<!-- region person Info -->
				<v-card>

					<!--Card Header-->
					<v-card-title>
						{{person | FullName}}
						<span v-if="person.inactive" class="error--text ml-2">Inactive</span>
						<v-btn @click="editPerson" small class="ml-auto">
							<v-icon small>fad fa-pencil</v-icon>
						</v-btn>
						<v-btn v-if="person.inactive" @click="restorePerson" title="Restore Person" small class="ml-2">
							<v-icon small>fad fa-undo</v-icon>
						</v-btn>
						<v-btn v-else @click="removePerson" title="Remove Person" small class="ml-2">
							<v-icon small>fad fa-trash-alt</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-subtitle>
						<span v-if="person.title" class="ml-2 text-muted small font-weight-normal">{{person.title}}</span>
					</v-card-subtitle>

					<!--Card Body-->
					<v-card-text>

						<v-row>

							<v-col cols="3" class="mb-3 position-relative">
								<v-avatar color="primary" :size="avatarSize">
									<span class="white--text headline">{{person | initials}}</span>
								</v-avatar>
							</v-col>
							<v-col>

								<p v-if="person.phone_primary" class="mb-2">
									<v-icon x-small class="mr-2">fad fa-phone</v-icon> Primary/Cell: <a :href="`tel:${person.phone_primary}`">{{person.phone_primary}}</a>
								</p>

								<p v-if="person.phone_secondary" class="mb-2">
									<v-icon x-small class="mr-2">fad fa-phone</v-icon> Secondary: <a :href="`tel:${person.phone_secondary}`">{{person.phone_secondary}}</a>
								</p>

								<p v-if="person.phone_office" class="mb-2">
									<v-icon x-small class="mr-2">fad fa-building</v-icon> Office: <a :href="`tel:${person.phone_office}`">{{person.phone_office}}</a>
								</p>

								<p v-if="person.phone_fax" class="mb-2">
									<v-icon x-small class="mr-2">fad fa-fax</v-icon> Fax: <a :href="`tel:${person.phone_fax}`">{{person.phone_fax}}</a>
								</p>

								<p v-if="person.email_primary" class="mb-2">
									<v-icon small class="mr-2">fad fa-envelope</v-icon> Primary: <a :href="`mailto:${person.email_primary}`">{{person.email_primary || 'No Email'}}</a>
								</p>

								<p v-if="person.email_secondary" class="mb-2">
									<v-icon small class="mr-2">fad fa-envelope</v-icon> Secondary <a :href="`mailto:${person.email_secondary}`">{{person.email_secondary}}</a>
								</p>

							</v-col>

						</v-row>

						<template v-if="person.note">
							<v-divider class="mb-4"></v-divider>
							<div v-html="person.note"></div>
						</template>

						<v-divider class="my-4"></v-divider>
						<meta-list :model="person"></meta-list>

					</v-card-text>

				</v-card>

			</v-col>

			<v-col cols="12" md="6">

				<!-- Clients -->
				<v-card>
					<v-card-title>Clients</v-card-title>
					<v-card-text>
						<v-list v-if="person.client_links.length" dense data-filter="all">
							<v-list-item-group>
								<v-list-item v-for="clientLink in person.client_links" :key="clientLink.id" :to="{name:'client.details', params:{ id: clientLink.client.id }}">
									<v-list-item-title><span v-if="clientLink.note">{{clientLink.note}}: </span>{{clientLink.client.name}}</v-list-item-title>
								</v-list-item>
							</v-list-item-group>
						</v-list>
						<div v-if="!person.client_links">
							<p class="text-center px-4">This person is not assigned to any Clients</p>
						</div>
					</v-card-text>
				</v-card>

				<!-- region Client Projects -->
				<v-card class="mt-4">
					<v-card-title>Projects</v-card-title>
					<v-card-text>
						<v-list v-if="person.project_links.length" dense data-filter="all">
							<v-list-item v-for="projectLink in person.project_links" :key="projectLink.project.id"
										 :to="{name:'project.details', params:{ id: projectLink.project.id}}"
										 :class="projectLink.project.status">
								<v-list-item-title>{{projectLink.project.client.name}}: {{projectLink.project.name}}</v-list-item-title>
								<v-list-item-subtitle>Status: {{projectLink.project.status}}</v-list-item-subtitle>
							</v-list-item>
						</v-list>
						<div v-if="!person.project_links.length">
							<p class="text-center px-4">This person is not assigned to any Projects</p>
						</div>
					</v-card-text>
				</v-card>

			</v-col>

		</v-row>
	</v-container>
	<v-container v-else class="fill-height flex-column justify-center align-center">
		<p class="text-h6">Loading Person</p>
		<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
	</v-container>
</template>

<script>
	import contactAddEdit from "../components/modals/contactAddEdit";
	import settings from "@/utils/settings";
	import metaList from "../components/metaList";


	export default {
		name: "contactDetails",

		components: {
			contactAddEdit,
			metaList
		},

		apollo: {
			simplePerson: {
				query: gql`
					query GetSinglePerson($personId: ID!) {
						simplePerson: person (id: $personId) {
							id
							first_name
							last_name
							inactive
							title
							is_employee
							note
							_meta_values
							_meta_types
							phone
							phone_office
							phone_fax
							phone_primary
							phone_secondary
							email_primary
							email_secondary
							... on Contact {
								client_links {
									client {
										id
										name
									}
									note
								}
								project_links {
									project {
										id
										name
										status
										client {
											id
											name
										}
									}
									note
								}
							}
						}
					}
				`,
				variables() {
					return {
						personId: this.$route.params.id,
					};
				},
			},
		},

		data() {
			return {
			};
		},


		computed: {
			person() {
				return {
					...this.simplePerson
				};
			},
			preferredJobs: {
				get() {
					let myJobs =
						this.getUserSetting("preferred-jobs") ||
						this.person.used_jobs ||
						[];
					myJobs = myJobs.filter((s) => s);

					return myJobs;
				},
				set(val) {
					this.$insight.helpers._timerJobsDep = null;
					if (val === null) {
						this.setUserSetting("preferred-jobs", null);
						// this.$snotify.success(`Preferred jobs reset`);
					} else {
						this.setUserSetting("preferred-jobs", Object.values(val));
						this.$snotify.success(`Preferred jobs updated`);
					}
				}
			},
			avatarSize() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 70;
					case "sm":
						return 120;
					default:
						return 150;
				}
			},
			jobs() {
				return settings.quickbooksJobs;
			},
			isMe() {
				return this.$route.params.id == this.$store.getters.userId;
			},
		},

		destroyed() {
			this.$db.removeHandle("person-details");
		},

		methods: {
			removePerson() {
				this.$swal({
					title: "Are you sure?",
					text: "You cannot undo this!",
					icon: "warning",
					buttons: true,
					dangerMode: true,
				}).then((willDelete) => {
					if (willDelete) {
						this.$apollo
							.mutate({
								mutation: gql`
									mutation ($data: UpdateContactArgs!) {
										updateContact(data: $data) { id inactive }
									}
								`,
								variables: {
									data: { id: this.$route.params.id, inactive: true },
								},
								update: (store, { data: { updateContact } }) => {
									this.$snotify.success(`${this.person.first_name} has been marked as inactive.`);
								},
							})
							.catch((error) => {
								console.error("error removing person", error);
								this.modalErrorMessage = "There was a problem updating the data.";
							});
					}
				});
			},
			restorePerson() {
				this.$apollo
					.mutate({
						mutation: gql`
							mutation ($data: UpdateContactArgs!) {
								updateContact(data: $data) { id inactive }
							}
						`,
						variables: {
							data: { id: this.$route.params.id, inactive: false, },
						},
						update: (store, { data: { updateContact } }) => {
							this.$snotify.success(`${this.person.first_name} has been marked as active.`);
						},
					})
					.catch((error) => {
						console.error("error restoring person", error);
						this.modalErrorMessage = "There was a problem updating the data.";
					});
			},
			editPerson() {
				this.$modalService
					.create("contactAddEdit", {
						personId: this.$route.params.id
					})
					.on("save", (e, person) => {
						this.$snotify.success("Contact updated", "Success");
						this.$apollo.queries.simplePerson.refetch();
					});
			},
		}
	};
</script>

<style lang="scss" scoped>
	.closed {
		text-decoration: line-through;
	}
</style>
