var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',{staticStyle:{"flex-direction":"row-reverse"}},[_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","title":"Add a new meta data field"},on:{"click":function($event){return _vm.edit(null)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-plus")]),_c('span',{staticClass:"sr-only"},[_vm._v("Add a new meta data field")])],1),_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAllowed('chuckit_dev')),expression:"isAllowed('chuckit_dev')"}],staticClass:"mr-3 mt-0",attrs:{"label":"Show Protected","hide-details":""},model:{value:(_vm.showProtected),callback:function ($$v) {_vm.showProtected=$$v},expression:"showProtected"}}),_c('span',{staticClass:"mr-auto"},[_vm._v("Meta Data Fields")])],1)],1),_vm._l((_vm.groupedItems),function(item){return _c('v-card',{key:item.table,staticClass:"mb-4"},[_c('v-card-title',[_vm._v(_vm._s(item.table))]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":item.fields,"headers":_vm.fields,"dense":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0)","title":"Click to edit"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.badgers(item.options))}})]}},{key:"item.protected",fn:function(ref){
var item = ref.item;
return [(item.protected)?_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fad fa-shield-check")])],1):_vm._e()]}},{key:"item.commands",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn btn-link btn-xs float-right",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-trash-alt")])],1)]}}],null,true),model:{value:(_vm.displayedItems),callback:function ($$v) {_vm.displayedItems=$$v},expression:"displayedItems"}})],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }