<template>
	<v-app :data-theme="$store.getters.userData.theme">
		<global-header v-if="appReady"></global-header>
		<v-main>
			<div class="alert-container">
				<v-alert v-for="not in notifications" :key="not.id" @input="hideNotification(not.id)" v-bind="not.props" prominent>
					<div v-if="not.html" class="html-content" v-html="not.html"></div>
					<template v-else>
						<div class="title">{{ not.title }}</div>
						<div class="content">{{ not.msg }}</div>
					</template>
				</v-alert>
			</div>
			<!-- <tip-tap :markdown="true" v-model="tiptapContent"></tip-tap> -->

			<router-view v-if="appReady" ref="view"></router-view>
			<template v-else>
				<v-container class="fill-height flex-column justify-center align-center">
					<p class="text-h5">Loading Application Data</p>
					<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
				</v-container>
			</template>

			<modal-service-component></modal-service-component>
			<vue-snotify></vue-snotify>
		</v-main>
		<global-footer v-if="appReady"></global-footer>
	</v-app>
</template>

<script>
	import "./assets/js/serializeObject";
	import "./assets/js/jquery.helpfulkeypress";
	import "./assets/js/jquery.removeclasspattern";
	import { mapState } from "vuex";

	import globalHeader from "./components/globalHeader";
	import globalFooter from "./components/globalFooter";
	import projectAddEdit from "./components/modals/projectAddEdit";
	import clientAddEdit from "./components/modals/clientAddEdit";

	import Insight from "./utils/Insight";
	import ModalServiceComponent from "./components/ModalServiceComponent";

	import { EventBus } from "./utils/EventBus";
	import { timeInterval } from './utils/timeInterval';

	// import TipTap from './components/TipTap.vue';

	export default {
		name: "app",

		components: {
			globalHeader,
			globalFooter,
			projectAddEdit,
			clientAddEdit,
			ModalServiceComponent,
			// TipTap
		},

		beforeCreate() {
			this.$xhrRequest.on("error", (e, xhr, request) => {
				this.$nextTick(() => {
					if (e.isDefaultPrevented() || request.isSubRequest()) {
						return;
					}

					let error = null;
					switch (xhr.status) {
						case 400:
							// this.$snotify.warning('User supplied garbage on this request and should feel bad.', 'Garbage Input');
							this.$snotify.warning(
								"User supplied bad data to server",
								"Bad Input"
							);
							break;
						case 401:
							this.$snotify.error("You must login", "Authentication");
							// this.$snotify.error('We don\'t know who you are!', 'Authentication');
							this.$store.dispatch("handleLogin");
							break;
						case 403:
							// this.$snotify.error('Stop trying to mess with other people\'s stuff!', 'Denied');
							this.$snotify.error(
								"You do not have permission to access this",
								"Denied"
							);
							break;
						default:
							let error = "Error communicating with the server";
							if (xhr.responseText) {
								error = xhr.responseText;
							} else if (
								xhr.responseJSON &&
								xhr.responseJSON.errors
							) {
								error = xhr.responseJSON.errors;
								if (Array.isArray(error)) {
									error = error.join("\n");
								}
							}
							this.$snotify.error(
								error,
								`Server Error‍: ${xhr.status}`
							);
							break;
					}
				});
			});
		},

		/* this was never being used because there was a 2nd created(). anything important here?
			created() {
				let AjaxResource = require("./models/base").default;
				AjaxResource.defaultXhrFields = {
					withCredentials: true,
				};

				// this.$snotify.setDefaults({
				// 	toast: {
				// 		titleMaxLength: 30,
				// 		showProgressBar: true,
				// 		timeout: 3000,
				// 		position: "rightTop",
				// 	},
				// });
			}, */

		mounted() {
			$("body").addClass(this.$route.name + "-route");

			let theme = this.$store.getters.userData.theme ? this.$store.getters.userData.theme : 'space-dark';

			$("body").attr('data-theme', theme);

			if(this.$store.getters.userData.animations) {
				$("body").addClass("animations");
			} else {
				$("body").removeClass("animations");
			}

			// this.$store.getters.userData.theme
			if (document.cookie.match(/XDEBUG/)) {
				$("body").addClass("dev");
			}
			// Get current logged in user
			this.$store.dispatch("checkAuth");

			if (this.$store.getters.isLoggedIn) {
				this.loadAppData();
			}

			$(document).on("keydown keyup", this.handleKeyPress);
		},

		destroyed() {
			$(document).off("keydown keyup", this.handleKeyPress);
		},

		data() {
			return {
				appDataLoaded: false,
				notifications: [],
	
				// tiptapContent: this.getUserSetting('tiptap', '- [x] do thing 1\n- [ ] do thing 2', 'session')
			}
		},

		created() {
			console.log(this);

			EventBus.$on("new-notification", this.newNotification);
			EventBus.$on("hide-notification", this.hideNotification);

			/* this.$watch('tiptapContent', (to) => {
				this.setUserSetting('tiptap', to, 'session');
			}); */
		},

		computed: {
			appReady() {
				return [this.$store.getters.authReady, this.appDataLoaded].reduce(
					(res, term) => {
						return res && term;
					},
					true
				);
			},

			/* theme(){
				return (this.$vuetify.theme.dark) ? 'dark' : 'light'
			}, */

			// ...mapState({
			// 	currentTheme: (state) => `theme-${state.app.theme || "default"}`,
			// }),
		},

		methods: {
			newNotification(payload) {
				this.notifications.push(payload);

				if (payload.timeout) {
					setTimeout(() => {
						EventBus.$emit("hide-notification", payload.id);
					}, payload.timeout);
				}
			},

			hideNotification(id) {
				let ndx = this.notifications.findIndex((not) => not.id == id);
				if (ndx >= 0) {
					this.notifications.splice(ndx, 1);
				}
			},

			handleKeyPress(e) {
				this.$store.state.app.ctrlKeyHeld = e.ctrlKey;
				this.$store.state.app.altKeyHeld = e.altKey;
				this.$store.state.app.metaKeyHeld = e.metaKey;
			},
			resetPassword() {
				this.$modalService.create("resetPassword");
			},
			loadAppData() {
				$(document).trigger("loadAppData");
				// if you can figure out how to modify the AppController.chain method to cache responses, go for it
				// until then, we must stop using chained requests for anything that can be cached.
				let getProjectsData = this.$xhrRequest
					.send(
						"get",
						`/api/project/list?fields=name,client_id,hours_allocated,status,account_executive_user,type`
					)
					.then((projects) => {
						// this replaces any existing models with new data, but it also erases any additional data that was not part of this request
						this.$db.addModels("project", projects, "projects", true);
					});

				getProjectsData.then((_) => {
					let appDataRequest = new this.$xhrRequest().chainStart();

					appDataRequest.send("get", "/api/meta/list").then((meta) => {
						this.$db.addModels("meta", meta, "meta", true);
					});

					appDataRequest
						.send("get", "/api/timer/list", { fields: "*,task" })
						.then((timers) => {
							this.$db.addModels("timer", timers, "timers", true);
						});

					appDataRequest
						.send("get", "/api/client/list", { fields: "name" })
						.then((clients) => {
							this.$db.addModels("client", clients, "clients", true);
						});

					// appDataRequest.send('get', '/api/project/list', {fields: 'name,client_id,hours_allocated,status,confirmed_time,meta_starred'}).then((projects) => {
					//     this.$db.addModels('project', projects, 'projects', true);
					// });

					appDataRequest
						.send("get", "/api/person/list-user")
						.then((people) => {
							this.$db.addModels("person", people, "users", true);
						});

					appDataRequest
						.send("get", "/api/status")
						.then(this.updateUIStatus);

					appDataRequest.chainEnd().on("chainSuccess", () => {
						this.appDataLoaded = true;
					});
				});
			},

			/**
			 * issue notification when the UI has been updated (when status.lastUpdated changes)
			 * @param status
			 */
			updateUIStatus(status) {
				if (!window.lastUpdated) {
					window.lastUpdated = status.lastUpdated;
					return;
				}
				if (window.lastUpdated !== status.lastUpdated) {
					if (window.refreshRequiredNotification) {
						console.log("already issued a notification");
						window.refreshRequiredNotification = status.lastUpdated;
						return;
					}
					console.log("refresh required as of ", status.lastUpdated);
					window.refreshRequiredNotification = status.lastUpdated;
					window.lastUpdated = status.lastUpdated;
					this.$snotify.warning(
						"Planet got better!",
						"Updated",
						{
							showProgressBar: false,
							timeout: 0,
							closeOnClick: true,
							bodyMaxLength: 300,
							preventDuplicates: true,
							buttons: [
								{
									text: "Refresh",
									action: (toast) => {
										top.location.reload(true);
									},
								},
								{
									text: "See Changes",
									bold: true,
									action: (toast) => {
										let route = this.$router.resolve({
											name: "changelog",
										});

										location.hash = route.href;
										location.reload();
									},
								},
							],
						}
					);
				}
			},

			updatePageTitle() {
				let parts = [];

				if (this.$store.getters.currentTimer) {
					parts.push(
						Insight.timers.formatDuration(
							this.$store.getters.timersCurrentTime
						)
					);

					if (this.$store.getters.onLunch) {
						parts.push("Lunch Break");
					} else {
						let timer = this.$store.getters.currentTimer;
						if (timer.project_id) {
							let client = this.$db.getModel(
									"client",
									timer.client_id
								),
								project = this.$db.getModel(
									"project",
									timer.project_id
								);

							parts.push(client.name + ":" + project.name);
						}
					}
				}

				parts.push("Planet");

				document.title = parts.join(" - ");
			},
		},

		watch: {
			appDataLoaded(to) {
				this.$store.commit("setAppDataLoaded", to);
			},

			"$store.getters.showAuthForm"(to, from) {
				$("html").toggleClass("login-modal-open", to);

				if (to) {
					this.$modalService.create("login");
				}
			},

			"$store.getters.isLoggedIn"(to, from) {
				if (to) {
					this.loadAppData();
					this.dataFetcherTimer = setInterval(() => {
						this.loadAppData();
					}, timeInterval('2 minutes'));
				} else {
					this.appDataLoaded = false;
					["timers", "clients", "projects", "users"].forEach((handle) => {
						this.$db.removeHandle(handle);
					});

					clearInterval(this.dataFetcherTimer);
				}
			},

			$route(to, from) {
				$("body")
					.removeClassPattern(/\w+-route/)
					.addClass(to.name + "-route");

				this.updatePageTitle();
			},

			"$store.getters.timersCurrentTime"() {
				this.updatePageTitle();
			},

			"$store.getters.userData.animations"(to, from) {
				if(to) {
					$("body").addClass("animations");
				} else {
					$("body").removeClass("animations");
				}
			},
		},
	};
</script>

<style lang="scss">
	@import "./assets/css/app.scss";
</style>