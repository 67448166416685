<script lang="js">
	export default {
		props: {
			events: Object,
			tag: String
		},

		data() {
			return {
				eventHandlers: {}
			};
		},

		destroyed() {
			this.hasBeenDestroyed = true;
		},

		render(h) {
			let result = this.$scopedSlots.default(this.eventHandlers);
			if (this.tag) {
				result = h(this.tag, result);
			}

			return result;
		},

		watch: {
			events: {
				deep: true,
				immediate: true,

				handler(to, from) {
					if (JSON.stringify(to) == JSON.stringify(from)) {
						// Same props, don't regenerate
						return;
					}

					let handlers = {};
					for (let i in to) {
						let cooldown;

						handlers[i] = (cb) => {
							if (cooldown) {
								clearTimeout(cooldown);
							}

							cooldown = setTimeout(() => {
								if (!this.hasBeenDestroyed) {
									cb();
								}
							}, to[i]);
						};
					}

					this.eventHandlers = handlers;
				}
			}
		}
	}
</script>