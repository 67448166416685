var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.$apollo.queries.contacts.loading}},[_c('v-card-title',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.caption)}}),_c('v-btn',{staticClass:"ml-auto mr-2",attrs:{"small":"","title":"Add Contact","min-width":"0"},on:{"click":_vm.addContact}},[_c('div',{staticClass:"fa-stack"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-address-card")]),_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("fal fa-plus")])],1)]),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$apollo.queries.contacts.refetch()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-sync")])],1)],1),_c('v-card-text',[(_vm.contacts && _vm.contacts.person_links)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contacts.person_links,"dense":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.person.first_name)+" "+_vm._s(item.person.last_name))])]}},{key:"item.phone_primary",fn:function(ref){
var item = ref.item;
return [(item.person.phone_primary)?_c('div',{staticClass:"text-no-wrap phone-number-wrap"},[_vm._v(" Cell: "),(item.person.phone_primary)?_c('a',{staticClass:"text-no-wrap",attrs:{"href":_vm._f("getPhoneLink")(item.person.phone_primary)}},[_vm._v(_vm._s(item.person.phone_primary))]):_vm._e()]):_vm._e(),(item.person.phone_secondary)?_c('div',{staticClass:"text-no-wrap phone-number-wrap"},[_vm._v(" Phone: "),_c('a',{staticClass:"text-no-wrap",attrs:{"href":_vm._f("getPhoneLink")(item.person.phone_secondary)}},[_vm._v(_vm._s(item.person.phone_secondary))])]):_vm._e(),(item.person.phone_office)?_c('div',{staticClass:"text-no-wrap phone-number-wrap"},[_vm._v(" Office: "),_c('a',{staticClass:"text-no-wrap",attrs:{"href":_vm._f("getPhoneLink")(item.person.phone_office)}},[_vm._v(_vm._s(item.person.phone_office))])]):_vm._e(),(item.person.phone_fax)?_c('div',{staticClass:"text-no-wrap phone-number-wrap"},[_vm._v(" Fax: "),_c('a',{staticClass:"text-no-wrap",attrs:{"href":_vm._f("getPhoneLink")(item.person.phone_fax)}},[_vm._v(_vm._s(item.person.phone_fax))])]):_vm._e()]}},{key:"item.email_primary",fn:function(ref){
var item = ref.item;
return [(item.person.email_primary)?_c('div',{staticClass:"text-no-wrap"},[_c('a',{staticClass:"text-no-wrap",attrs:{"href":("mailto:" + (item.person.email_primary))}},[_vm._v(_vm._s(item.person.email_primary))])]):_vm._e(),(item.person.email_secondary)?_c('div',{staticClass:"text-no-wrap"},[_c('a',{staticClass:"text-no-wrap",attrs:{"href":("mailto:" + (item.person.email_secondary))}},[_vm._v(_vm._s(item.person.email_secondary))])]):_vm._e()]}},{key:"item.view_user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right d-flex",attrs:{"data-id":item.person.id}},[(item.person.meta_person_hubspot_link)?_c('v-btn',{attrs:{"href":item.person.meta_person_hubspot_link,"target":"_blank","small":"","min-width":"0","title":"View on Hubspot"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fab fa-hubspot")])],1):_vm._e(),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","min-width":"0","title":"Edit Contact"},on:{"click":function($event){return _vm.editUser(item.person.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-pencil-alt")])],1)],1)]}}],null,false,3427721110)}):_c('div',[_vm._v("There are no contacts for this client.")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }