import { clothesType as OriginalClothesType } from 'vuejs-avataaars/src/assetsTypes/clothes';
import { standardReplacements } from './utils';

standardReplacements(OriginalClothesType, 'clothetype');

export const clothesType = OriginalClothesType;
export const clothesTypeOptions = {
	_default: {
		Color: 'color'
	},

	BlazerShirt: {},
	BlazerSweater: {},

	GraphicShirt: {
		Color: 'color',
		Graphic: 'graphic'
	}
}