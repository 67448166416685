<template>
	<v-container fluid fill-height class="flex-column justify-center align-center" :style="!$vuetify.theme.dark ? 'background-color: var(--v-primary-base); color: var(--v-background-base)' : ''" >
		<lottie :options="defaultOptions" :height="400" :width="800" style="max-width:100%"/>
		<h1 class="text-center">
			"{{$route.params.pathMatch}}" not found
		</h1>
	</v-container>
</template>
<script>
	import Lottie from "vue-lottie";
	import * as animation from "@/assets/404.json";
	export default {
		components: { Lottie},
		data() {
			return {
				defaultOptions: { animationData: animation.default },
			};
		},
	};
</script>
