<template>
	<v-container fluid>

		<v-card :loading="$apollo.queries.persons.loading">
			<v-card-title>
				Staff
				<v-text-field v-model="filter" label="Search" dense hide-details clearable class="ml-10 mt-0"></v-text-field>
				<v-switch v-model="showPastEmployees" label="Show Past Employees" class="ml-3"></v-switch>
				<v-btn small @click="$apollo.queries.persons.refetch()" class="ml-10">
					<v-icon small>fad fa-sync</v-icon>
				</v-btn>
			</v-card-title>

			<v-data-table v-if="employees" :headers="headers" :items="employees" :search="filter" dense calculate-widths disable-pagination hide-default-footer sort-by="first_name">

				<template v-slot:item.meta_out_today="{ item }">
					<v-switch v-if="!item.inactive" :input-value="item.meta_out_today" @change="updateUser(item, $event)" hide-details class="mb-1 my-0" color="toggle"></v-switch>
				</template>

				<template v-slot:item.meta_avatar="{ item }">
					<user-avataaar :user="item"></user-avataaar>
				</template>

				<template v-slot:item.edit="{ item }">
					<v-btn small title="Edit Person" @click="editPerson(item)" min-width="0" class="mx-1">
						<v-icon small>fad fa-pencil</v-icon>
						<span class="sr-only">Edit</span>
					</v-btn>
					<v-btn v-if="item.id" small :to="{ name: 'employee.details', params: { id: item.id } }" min-width="0" class="mx-1">
						<v-icon small>fad fa-link</v-icon>
					</v-btn>
				</template>

				<template v-slot:item.hired_date="{ item }">
					<div :class="{'primary--text': isThisMonth(item.hired_date)}">{{item.hired_date | shortDate}}
					</div>
				</template>

				<template v-slot:item.meta_birthday="{ item }">
					<div :class="{'primary--text': item.meta_birthday != '' && isToday(item.meta_birthday)}">{{item.meta_birthday | birthDate}}</div>
				</template>

			</v-data-table>
		</v-card>

	</v-container>
</template>

<script>
	import UserAvataaar from "../components/avataaars/user-avataaar";
	import moment from "moment";
	import {EventBus} from '../utils/EventBus';

	const GetPersonsQuery = gql`
		query GetPersons($filterParam: QueryFilter!) {
			persons @filter(param: $filterParam) {
				nodes {
					node {
						id
						first_name
						last_name
						is_employee
						meta_birthday
						meta_out_today
						... on Employee {
							inactive
							department
							hired_date #@formatDate(format: "YY")
						}
						meta_avatar
					}
				}
			}
		}
	`;

	export default {
		name: "employeeList",

		components: {
			UserAvataaar,
		},

		apollo: {
			persons: {
				query: GetPersonsQuery,
				variables() {
					return this.getQueryVariables();
				},
			},
		},

		data() {
			return {
				showPersonEditModal: false,
				filter: null,
				showPastEmployees: false,
				headers: [
					{
						value: "meta_out_today",
						text: "Out Today",
						width: "90px",
					},
					{
						value: "meta_avatar",
						text: "",
						width: "75px",
					},
					{
						value: "first_name",
						text: "First",
						sortable: true,
					},
					{
						value: "last_name",
						text: "Last",
						sortable: true,
					},
					{
						value: "department",
						text: "Department",
						sortable: true,
					},
					{
						value: "hired_date",
						text: "Hired",
						sortable: true,
					},
					{
						value: "meta_birthday",
						text: "Birthday",
						width: "100px",
						sortable: true,
					},
					{
						value: "edit",
						text: "Edit/View",
						width: "130px",
						height: "auto",
						// cellClass: "d-flex"
					},
				],
			};
		},

		computed: {
			employees() {
				if(!this.persons) {
					return [];
				}

				return this.persons.nodes
					.map(row => row.node)
			}
		},

		methods: {
			getQueryVariables() {
				let filters = [
					{
						field: "is_employee",
						op: "=",
						value: true,
					},
				];

				if (!this.showPastEmployees) {
					filters.push({
						field: "inactive",
						op: "=",
						value: false,
					});
				}

				return {
					filterParam: {
						joinOp: "and",
						filters,
					},
				};
			},

			isThisMonth(date) {
				return (
					moment(date).month() == this.$store.getters.currentDay.month()
				);
			},

			isToday(date) {
				let userBirthday = moment(date);
				if (
					userBirthday.isValid() &&
					userBirthday.format("MM/DD") === moment().format("MM/DD")
				) {
					return true;
				}
			},

			editPerson(person) {
				this.$modalService
					.create("employeeAddEdit", {
						personId: person.id,
					})
					.on("save", (e, person) => {
						this.$snotify.success("Person updated", "Success");
						this.$apollo.queries.persons.refetch();
					});
			},

			updateUser(user, value) {
				// graphql mutation
				this.$apollo
					.mutate({
						mutation: gql`
							mutation ($data: UpdateEmployeeArgs!) {
								updateEmployee (data: $data) {
									id
									meta_out_today
								}
							}
						`,
						variables: {
							data: {
								id: user.id,
								meta_out_today: value
							}
						},
						update: (store, { data: { updateEmployee } }) => {
							EventBus.$emit('employee:out_today', updateEmployee);
						},
					})
					.catch((error) => {
						// Error
						console.error(error);

					});
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>