import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

const cachedFonts = [];
function withFonts(fonts, docFn) {
	return new Promise((resolve, reject) => {
		let prom = Promise.resolve();
		for (let name in fonts) {
			if (cachedFonts.includes(name)) {
				continue;
			}

			prom = prom.then(() => {
				if (/^(?:https?:\/\/|\/)/.test(fonts[name])) {
					return new Promise((fResolve, fReject) => {
						$.ajax(fonts[name], {
							dataType: 'binary',
							xhr() {
								let myXhr = jQuery.ajaxSettings.xhr();
								myXhr.responseType = 'blob';
								return myXhr;
							}
						}).then((response) => {
							// response is a Blob
							let reader = new FileReader();
								
							reader.addEventListener('load', () => {
								let base64 = reader.result.substr(reader.result.indexOf(',') + 1);
								jsPDF.API.events.push(['addFonts', function() {
									this.addFileToVFS(`${name}.ttf`, base64);
									this.addFont(`${name}.ttf`, name, 'normal');
								}]);
								cachedFonts.push(name);
								fResolve();
							}, false);

							reader.addEventListener('error', () => {
								fReject(reader.error);
							}, false);

							reader.readAsDataURL(response);
						  });
					});
				}
				else {
					jsPDF.API.events.push(['addFonts', () => {
						this.addFileToVFS(`${name}.ttf`, fonts[name]);
						this.addFont(`${name}.ttf`, name, 'normal');
					}]);
					cachedFonts.push(name);
				}
			});
		}

		prom
			.then(() => {
				let doc = docFn ? docFn() : null;

				if (doc) {
					for (let name in fonts) {
						doc.addFont(`${name}.ttf`, name, 'normal');
					}
				}

				resolve(doc);
			})
			.catch(() => reject());
	});
}

const PDFGenerator = {
	/**
	 *
	 * @param {Object} dataList the data computed by clientList.vue
	 * @param {boolean?} showTimeSpentByProject incorporate budget information
	 *     and order the list by percentage of budget spent on project.
	 */
	projectsReport(dataList, showTimeSpentByProject) {
		let doc = new jsPDF('l', 'pt', 'letter'),
			counter = 0,

			now = moment().format('YYYY MM DD'),
			filtered = ".table-header",
			columns = [
				{
					title: "Client",
					dataKey: "client"
				},
				{
					title: "Project",
					dataKey: "project"
				},
				{
					title: "Status",
					dataKey: "status"
				},
				{
					title: "Due",
					dataKey: "due"
				}
			],
			header = 'Projects - ' + now,
			rows = [],
			lastPos = 0;

		if (showTimeSpentByProject) {
			let colObj = {
				title: "Spent/Budget",
				dataKey: "time_spent"
			};
			columns.push(colObj);
		}

		// todo wut is this
		// filtered = (!$('.check-flag-hidden').is(':visible') ? filtered + ", .hide-project": filtered);
		// filtered = (!$('.check-flag-closed').is(':visible') ? filtered + ", .closed-project": filtered);

		doc.text(header, 40, 40);
		// build a list of table rows
		dataList.forEach((clientProjectData) => {
			clientProjectData.projects.forEach(function (project) {
				let tmpObj = {},
					endPos = 40;

				tmpObj.client = clientProjectData.client.name;
				tmpObj.project = project.name;
				tmpObj.department = project.department + ' / ' + project.status;
				tmpObj.status = project.status;
				tmpObj.due = project.due_date ? moment(project.due_date).format('MM/DD/YYYY') : '';

				let accountStatus = $(`<span>${project.last_status_note || 'None'}</span>`).text();
				tmpObj.account_status = `Account Status - ${accountStatus}`;

				if (showTimeSpentByProject) {
					let hoursAllocated = parseFloat(project.hours_allocated || 0);
					// total_time is set by clientList.vue's projectTotalHours(project) which in turn uses Insight.projects.totalTime(project)
					let hoursSpent = project.time_spent / 3600;
					let budgetSpentPercent = hoursAllocated !== 0 ? 100 * hoursSpent / hoursAllocated : 0;
					let percentOfBudgetSpent = budgetSpentPercent ? ' - ' + budgetSpentPercent.toFixed(0) + '%' : '';

					tmpObj.time_spent = hoursSpent.toFixed(1) + '/' + hoursAllocated.toFixed(1) + percentOfBudgetSpent;
					tmpObj.percentOfBudgetSpent = budgetSpentPercent;
					// tmpObj.time_spent = project.time_spent + '/' + project.hours_allocated + (time_spent_perc ? ' - ' + time_spent_perc + '%' : '');
				}

				rows.push(tmpObj);

			});
		});

		if (showTimeSpentByProject) {
			rows.sort((a, b) => {
				return b.percentOfBudgetSpent - a.percentOfBudgetSpent;
			});
			// rows = _.sortBy(rows, 'percentOfBudgetSpent').reverse();
		}

		doc.autoTable(columns, rows, {
			theme: 'grid',
			startY: 60,
			styles: {
				textColor: 0
			},
			headerStyles: {
				fillColor: 255
			},
			drawRow: function (row, data) {
				let overHeight = 0;
				doc.setFontStyle('normal');
				doc.setFontSize(10);
				doc.setTextColor(0, 0, 0);

				if (row.index === 0 || row.y < 83) {
					overHeight = 10
				}
				else {
					overHeight = 30;
					data.cursor.y += 20;
				}
				let cellPadding = 5;
				// cellPadding = row.cells.client.styles.cellPadding
				doc.autoTableText(data.row.raw.account_status, data.settings.margin.left + cellPadding, row.y + row.height + overHeight, {
					halign: 'left',
					valign: 'middle'
				});
			},
			drawCell: function (cell, data) {
				if (data.column.dataKey === 'client') {
					doc.setFontStyle('bold');
				}
				if (data.column.dataKey === 'time_spent') {
					var cellContent = cell.raw.split(' - ');

					if (cellContent.length === 2) {
						var perc = parseInt(cellContent[1].replace('%', ''));

						if (perc <= 80) {
							doc.setTextColor(46, 204, 113);
						}// cell.styles.textColor = [46,204,113];
						else if (perc > 80 && perc <= 100) {
							doc.setTextColor(243, 156, 18);
						}// cell.styles.textColor = [243,156,18];
						else {
							doc.setTextColor(231, 76, 60);
						}
						// cell.styles.textColor = [231,76,60];
					}
				}
			}
		});

		this.outputAsNewWindow(doc, header);
	},
	
	/**
	 *
	 * @param {Object} data the data computed by projectSitemapView.vue
	 */
	projectSitemapReport(title, columns, data) {
		return import('@fortawesome/fontawesome-pro/webfonts/fa-solid-900.ttf')
			.then(module => {
				return withFonts(
					{ FontAwesome: module.default },
					() => new jsPDF('l', 'pt', 'letter')
				);
			})
			.then((doc) => {
				doc.text(title, 40, 40);

				columns = columns.map(d => ({ title: d.text, dataKey: d.value, type: d.type }));
				if (columns.length > 0 && columns[0].type == 'text') {
					data = data.map(d => {
						return {
							...d,
							'0': '   '.repeat(d._indent) + d[0]
						};
					});
				}

				doc.autoTable(columns, data, {
					theme:        'grid',
					startY:       60,
					styles:       {
						textColor: 0
					},
					headStyles: {
						fillColor: 'c4262e',
						textColor: 255
					},
					didParseCell: function ({ section, column, cell, ...blah }) {
						if (section == 'body') {
							switch (column.raw.type) {
								case 'checkbox':
									cell.text = parseInt(cell.text);
									cell.text = cell.text
										? '\uf00c'
										: '';
									cell.styles.textColor = [47, 158, 19];
									cell.styles.halign = "center";
									break;
							}
						}
					},
					willDrawCell: function ({ section, column, cell }) {
						if (section == 'body') {
							switch (column.raw.type) {
								case 'checkbox':
									doc.setFont('FontAwesome');
									break;
							}
						}
					}
				});

				this.outputAsNewWindow(doc, title);
			});
    },

    outputAsNewWindow(doc, title) {
        let data = doc.output('datauristring');
        let el = `<embed type="application/pdf" src="${data}" width="100%" height="100%" style="border:none" />`;
        let newWin = window.open();
        newWin.document.open();
        newWin.document.write(el);
        newWin.document.title = title;
        newWin.document.close();
    }
};

export default PDFGenerator;
