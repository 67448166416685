<template>
	<div class="type-picker">
		<v-select :label="label" :items="fieldChoices" :value="curValue[valueKey]" @input="setValue(valueKey, $event, true)" dense hide-details outlined menu-props="auto" class="mt-3"></v-select>
		<template v-for="(type, name) in fieldOptions">
			<div v-if="type == 'color'" :key="name" class="mt-3">
				<label class="mb-1">{{name}}</label>
				<v-color-picker hide-canvas hide-inputs hide-sliders show-swatches swatches-max-height="150" mode="hexa" width="100%" :value="curValue[name]" @input="setColor(name, $event)"></v-color-picker>
			</div>
			<type-picker v-else-if="type == 'graphic'" :key="name" label="Graphic" field="GraphicShirtTypes" :value-key="name" v-model="curValue" @input="sendValue"></type-picker>
			<div v-else :key="name">
				Unknown option type: {{type}}
			</div>
		</template>
	</div>
</template>

<script>
	import * as dataTypes from './index';

	export default {
		name: 'type-picker',

		props: {
			label: {
				type: String,
				required: true
			},
			field: {
				type: String,
				required: true
			},

			value: {
				required: true
			},

			valueKey: {
				type: String,
				default: 'value'
			}
		},

		data() {
			return {
				curValue: {}
			};
		},

		computed: {
			fieldChoices() {
				return Object.keys(dataTypes[this.field]);
			},
			fieldOptions() {
				if (!dataTypes[`${this.field}Options`]) {
					return [];
				}

				return dataTypes[`${this.field}Options`][this.curValue[this.valueKey]] || dataTypes[`${this.field}Options`]._default;
			}
		},

		methods: {
			sendValue() {
				this.$emit('input', this.curValue);
			},

			setColor(name, e) {
				if (typeof e == 'object') {
					this.setValue(name, e.hex);
				}
				else {
					this.setValue(name, e);
				}

				this.sendValue();
			},

			setValue(name, val, send = false) {
				this.$set(this.curValue, name, val);

				if (send) {
					this.sendValue();
				}
			}
		},

		watch: {
			value: {
				immediate: true,
				handler(to) {
					this.curValue = Object.assign({
						value: ''
					}, to || {});
				}
			}
		}
	}
</script>