<template>

	<v-card tile :data-task-id="task.id" class="mb-2">

		<v-card-title class="body-2 mb-4 d-flex flex-nowrap justify-space-between" :class="$insight.tasks.taskStatus(task)">
			<a href="javascript:void(0)" @click="onTaskClick($event)" class="d-inline-block text-truncate white--text">{{task.name}}</a>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn x-small v-bind="attrs" v-on="on" min-width="0">
						<v-icon x-small>fa fa-ellipsis-v</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="addNote">
						<v-list-item-title>Add Note</v-list-item-title>
					</v-list-item>
					<v-list-item @click="reAssignTask">
						<v-list-item-title>Assign To</v-list-item-title>
					</v-list-item>
					<v-list-item :disabled="!completeable" @click="completeTask">
						<v-list-item-title>Complete Task</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>

		<v-card-subtitle class="d-flex justify-space-between py-2">
			<router-link v-if="task.project.client" :to="{name: 'client.details', params: {id: task.project.client.id}}" class="caption text-truncate">
				{{task.project.client.name}}
			</router-link>
			<router-link v-if="task.project" :to="{name: 'project.details', params: {id: task.project.id}}" class="caption text-truncate">
				{{task.project.name}}
			</router-link>
		</v-card-subtitle>

		<v-card-text class="pb-0">
			<v-expansion-panels v-if="task.description" accordion flat background="transparent">
				<v-expansion-panel>
					<v-expansion-panel-header class="pa-0" color="transparent">Description</v-expansion-panel-header>
					<v-expansion-panel-content>{{task.description}}</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card-text>

		<v-card-actions v-if="task.timeline_due_date" class="justify-space-between caption">
			<span :class="$insight.helpers.getTaskStatusClass(task)" :title=" 'Due: ' + $options.filters.shortDate(task.timeline_due_date) ">{{task.estimated_completion_date | shortDate}}</span>
			<span v-if="pastDue">{{ pastDue }}</span>
		</v-card-actions>

	</v-card>

</template>

<script>
	import ModelCardMixin from "../../mixins/model-card-mixin";
	import TaskCardMixin from "../../mixins/task-card-mixin";

	export default {
		name: "taskCard",

		mixins: [ModelCardMixin, TaskCardMixin],

		props: {
			task: {
				type: Object,
				required: true,
			},
		},
		computed: {},
	};
</script>

<style scoped lang="scss">
	::v-deep .v-card__title {
		padding: 8px 15px;
	}
	.v-expansion-panels .v-expansion-panel {
		background: transparent;
	}
	::v-deep .v-expansion-panel-content__wrap {
		padding-left: 0;
		padding-right: 0;
	}
	::v-deep .v-expansion-panel-header {
		min-height: unset;
	}
</style>
