import moment from 'moment';
import Insight from '../../utils/Insight';

import {dbInst} from "../../db/simple-db";
import {XHRRequest} from '../../utils/xhr-request';

export default {
    actions: {
        createTimer({getters}, newTimerOpts={}) {
            // fixme: there might be somewhere in the code that relies on the action's argument being a task_id
            if (typeof newTimerOpts !== 'object'){
                newTimerOpts= {task_id: newTimerOpts};
			}
			
			for (let i in newTimerOpts) {
				newTimerOpts[i] = '' + newTimerOpts[i];
			}

            let apiRequest = XHRRequest.chainStart();

            apiRequest.send('post', `/api/user/${getters.userId}/timer`, newTimerOpts).then((newTimer) => {
                dbInst.addModels('timer', newTimer, 'timers');
            });

            if (getters.currentTimer) {
                apiRequest.send('get', `/api/timer/${getters.currentTimer.id}`).then((lastTimer) => {
                    dbInst.addModels('timer', lastTimer);
                });
            }

            return apiRequest.chainEnd().toPromise();
        },
        startTimer({dispatch, getters}, timerId) {
            let apiRequest = XHRRequest.chainStart();

            apiRequest.send('put', `/api/timer/${timerId}`, {active: 1}).then((startedTimer) => {
                dbInst.addModels('timer', startedTimer);
            });

            if (getters.currentTimer) {
                apiRequest.send('get', `/api/timer/${getters.currentTimer.id}`).then((lastTimer) => {
                    dbInst.addModels('timer', lastTimer);
                });
            }

            return apiRequest.chainEnd().toPromise();
        },
        stopTimer({dispatch}, timerId) {
            return XHRRequest.send('put', `/api/timer/${timerId}`, {active: 0}).then((stoppedTimer) => {
                dbInst.addModels('timer', stoppedTimer);
            });
        },
        stopListening({state}) {
            if (state.interval) {
                clearInterval(state.interval);
            }
        }
    },
	getters: {
		
        myTimers(state, getters) {
            if (!(getters.userId > 0)) {
                return [];
            }

            return dbInst.findModels('timer', (timer) => {
                return timer.user_id == getters.userId;
            });
        },

        myTimersToday(state, getters) {
            return getters.myTimers.filter((timer) => {
                return getters.currentDay.isSame(timer.creation_date, 'day')
            });
        },

        myLunchTimers(state, getters) {
            if (!(getters.userId > 0)) {
                return [];
            }

            return dbInst.findModels('timer', (timer) => {
                return timer.user_id == getters.userId && Insight.projects.offTheClockProjects.includes(timer.project_id);
            });
        },


        hasTimers(state, getters) {
            return getters.myTimers.length > 0;
        },

        currentTimer(state, getters) {
            return getters.myTimers.find((item) => {
                return item.status === 'Active';
            });
        },

        onLunch(state, getters) {
			let timer = getters.currentTimer;
            if (timer) {
                return Insight.projects.offTheClockProjects.includes(timer.project_id);
            }

            return false;
        },

        timersCurrentTime(state, getters) {
			let timer = getters.currentTimer;
            return Insight.timers.getDuration(timer);
        },

        timersTotalLunchTime(state, getters) {
            return Insight.timers.timerDurationsSum(getters.myLunchTimers, false);
        },

        /**
         * sum of all timers for current user, excluding lunch time.
         * @param state
         * @param getters
         * @returns {*}
         */
        timersTotalTime(state, getters) {
            return Insight.timers.timerDurationsSum(getters.myTimersToday);
        },

        /**
         * The clock time at which you will have accumulated 8 hours of work.
         * It is displayed in the global header as "Out: "
         *
         * It is = current_time + (8 hours - time_worked)
         * @param state
         * @param getters
         */
        timersOutTime(state, getters) {

            let currentTime = getters.currentTime.clone().local();
            let timeWorked = getters.timersTotalTime;
            let timeLeft = moment.duration({hours: 8});

            // Remove time worked
            timeLeft = timeLeft.subtract(timeWorked, 'seconds');

            // let timeLeftString=`${timeLeft._data.hours}:${timeLeft._data.minutes}:${timeLeft._data.seconds}`;
            // console.log(`timeLeft=${timeLeftString}`);

            let numTimers = getters.myTimers.reduce((result, timer) => {
                if (typeof result[timer.status] === 'undefined') {
                    result[timer.status] = 0;
                }

                let stopDate = moment(timer.stop);
                if (!result.lastStop || stopDate.isAfter(result.lastStop)) {
                    result.lastStop = stopDate;
                }

                result[timer.status]++;
                return result;
            }, {});

            if (!numTimers.Active && !numTimers.Inactive && numTimers.Submitted) {
                // done for the day because at least one timer has been submitted and no other timers are present
                return {
                    stopped: true,
                    time:    numTimers.lastStop
                };
            }

            // compute out time
            return {time: currentTime.add(timeLeft)};
        }
    }
}
