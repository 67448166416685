<template>
	<div class="fill-height d-flex flex-column">

		<!-- task groups -->
		<v-toolbar class="mb-5" tag="div" dense>
			<template>
				<v-toolbar-title>Task Group: 
					<template v-if="selectedTaskGroup">{{selectedTaskGroup.name}}</template>
					<template v-else>None</template>
				</v-toolbar-title>
				<!-- <div v-if="selectedTaskGroup.start_date" class="ml-4">Started: {{selectedTaskGroup.start_date | shortDate}}</div> -->
				<v-btn v-if="selectedTaskGroup" @click="editGroup" small class="ml-4" min-width="0" text><v-icon small>fad fa-pencil</v-icon></v-btn>
				<v-btn v-if="selectedTaskGroup" @click="duplicateGroup" small min-width="0" title="Duplicate Task Group to another project"><v-icon small>fad fa-copy</v-icon></v-btn>
				<v-spacer></v-spacer>
				<div v-if="taskGroups && taskGroups.length > 1">
					<v-autocomplete v-model="selectedTaskGroupId" :items="taskGroups" outlined dense item-text="name" item-value="id" label="Selected Task Group" :data-id="selectedTaskGroup" hide-details>
						<template v-slot:selection="data">
							<v-icon left small>{{$insight.helpers.getTaskGroupIcon(data.item.type)}}</v-icon>
							{{data.item.name}} <em v-if="data.item.is_default"> - default</em>
							<em v-if="data.item.is_archived" class="ml-2 text--disabled">(archived)</em>
						</template>
						<template v-slot:item="data">
							<v-list-item-avatar>
								<v-icon>{{$insight.helpers.getTaskGroupIcon(data.item.type)}}</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{data.item.name}}<em v-if="data.item.is_default"> - default</em> <em v-if="data.item.is_archived" class="ml-1 text--disabled">(archived)</em></v-list-item-title>
								<v-list-item-subtitle>{{data.item.start_date | shortDate}}</v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</v-autocomplete>
				</div>
				<v-btn @click="addGroup" min-width="0" class="ml-1" text><v-icon small>fas fa-plus</v-icon></v-btn>
			</template>
		</v-toolbar>
		
		<div fluid class="graph-container align-stretch fill-height">
			<v-row v-if="taskGroupTasks" class="fill-height flex-grow-1 align-stretch">
				<v-col cols="12" md="8" @click="selectedTask = null" class="d-flex align-stretch" style="position:relative;min-height:300px;">
					<div class="scroll">
						<task-tree-view :tasks="tasks" @insertTask="insertTask">
							<template v-slot:task="{task, mouseenter, mouseleave}">
								<v-tooltip top :key="task.id">
									<template v-slot:activator="{ on, attrs }">
										<v-sheet dark
												 v-bind="attrs"
												 v-on="on"
												 @mouseenter="mouseenter"
												 @mouseleave="mouseleave"
												 @contextmenu="handleContextMenu($event, task)"
												 class="calendar-task text-center px-1"
												 :color="`task-${task.status}`"
												 :class="{
												[(task.assigned_to_user_id ? 'task-' : 'task-un') + 'assigned']: true,
												selected: task.id == selectedTask
											}"
												 :data-task-id="task.id"
												 @click="selectTask($event, task.id)"
												 rounded>
											<!-- task title -->
											<div class="text-truncate">{{task.name}}</div>
											<!-- assigned -->
											<department-badge v-if="task.assigned_to_user_id" class="assigned-to" :user="task.assigned_to_user_id" />
											<span v-else class="assigned-to badge bg-red">Unassigned</span>
										</v-sheet>
									</template>
									<span>{{task.name}}</span>
								</v-tooltip>
							</template>
						</task-tree-view>
					</div>
				</v-col>
				<v-col cols="12" md="4" class="floating-panel d-flex flex-column align-stretch">

					<v-alert v-if="selectedTaskGroup && selectedTaskGroup.is_archived" color="error" border="top" dark icon="fad fa-siren-on" class="text-left">
						This is an archived task group
					</v-alert>

					<v-card v-if="selectedTask">
						<form>
							<v-card-title>
								Editing Task
								<v-btn text x-small color="primary" class="ml-auto" @click="deleteTask">Delete</v-btn>
							</v-card-title>
							<v-card-text>
								<v-alert v-if="errorMessages" dark color="error" border="bottom" class="my-3" v-html="errorMessages"></v-alert>

								<v-text-field label="Name *" v-model="selectedTask.name" :disabled="saving" dense hide-details outlined class="my-3"></v-text-field>

								<previous-tasks :taskId="selectedTask.id" :all-tasks="tasks" v-model="selectedTask.previous_task_ids" :disabled="saving" />

								<div class="my-3">
									<div class="d-flex input-group">
										<v-text-field v-model="selectedTask.required_days" type="number" min="0" dense label="Required Days" outlined hide-details></v-text-field>
										<required-days-picker
											v-model="selectedTask.required_days"
											:timeline-due-date="selectedTask.timeline_due_date"
											:previous-task-ids="selectedTask.previous_task_ids"
											:project-id="projectDetails.id"
											v-slot="{ trigger }"
										>
											<v-btn color="primary" min-width="0" @click="trigger" :disabled="saving"><v-icon small>fad fa-calendar-alt</v-icon></v-btn>
										</required-days-picker>
									</div>
								</div>

								<employee-picker v-model="selectedTask.assigned_by_user_id" :disabled="!$store.getters.isAdminUser || saving"
												 label="Assigned By User"
												 class="my-3"
												 dense outlined hide-details />

								<employee-picker v-model="selectedTask.assigned_to_user_id" :disabled="saving"
												 label="Assigned To User" placeholder="Who receives this task"
												 class="my-3"
												 dense outlined hide-details />


								<v-textarea label="Description" v-model="selectedTask.description" :disabled="saving" outlined hide-details class="my-3" rows="3" auto-grow></v-textarea>

								<!-- todo: decide whether or not this has ever been used by anyone other than user 7 -->
								<!-- <v-autocomplete v-model="selectedTask.parent_id" :items="otherTasks" label="Subtask of" :disabled="saving" placeholder="Not a subtask" dense hide-details outlined class="my-3"></v-autocomplete> -->

								<v-divider></v-divider>

								<meta-input-list ref="metaFieldList" :model="selectedTask" model-type="Task" :disabled="saving"></meta-input-list>

								<v-menu  v-if="$store.getters.hasPermission('chuckit_dev') || $store.getters.isAdminUser" ref="completeDateMenu" v-model="completeDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="selectedTask.complete_date" type="date" label="Complete Date" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" clearable dense class="mt-5" outlined hide-details></v-text-field>
									</template>
									<v-date-picker v-model="selectedTask.complete_date" no-title scrollable color="primary" @input="completeDateMenu = false"></v-date-picker>
								</v-menu>


							</v-card-text>
							<v-card-actions>
								<v-btn small @click="selectTask($event, selectedTask.id)" class="ml-auto">Reset</v-btn>
								<v-btn small color="primary" @click="saveTask">Save</v-btn>
							</v-card-actions>
						</form>
					</v-card>

					<div v-else-if="selectedTaskGroupId" class="py-5 fill-height d-flex flex-column">

						<v-alert color="primary" border="top" dark icon="fad fa-info-circle" class="text-left">Select a task to edit</v-alert>
						<template v-if="selectedTaskGroup && !selectedTaskGroup.is_archived">
							<div class="mb-2 text-over-bg--text">OR</div>
							<v-btn small color="primary" @click="addTask">
								<v-icon small class="mr-2">fad fa-sticky-note</v-icon>
								Create Task
							</v-btn>
						</template>

						<div v-if="orphanedTasks.length" class="mt-5 mb-2 border-bottom text-over-bg--text">
							Select/Edit an Orphaned Task
						</div>
						<v-sheet v-if="orphanedTasks.length" :min-height="$vuetify.breakpoint.md ? '' : 300" class="orphan-task-container flex-grow-1 overflow-none" style="position:relative;" color="transparent">
							<div class="overflow-y-auto" style="position:absolute;top:0;bottom:0;left:0;right:0;">
								<div v-for="task in orphanedTasks" :key="task.id">
									<v-sheet dark :color="`task-${task.status}`" class="mb-3 text-center pa-2 rounded" @click="selectTask($event, task.id)" @contextmenu="handleContextMenu($event, task)">
										<!-- task title -->
										<div class="text-truncate">{{task.name}}</div>
										<!-- assigned -->
										<department-badge v-if="task.assigned_to_user_id" class="assigned-to" :user="task.assigned_to_user_id" />
										<span v-else class="assigned-to badge bg-red">Unassigned</span>
									</v-sheet>
								</div>
							</div>
						</v-sheet>
					</div>

				</v-col>
			</v-row>
			<!-- todo update this -->
			<div v-if="$apollo.queries.taskGroupTasks.loading" class="text-center mt-5 mx-auto">
				<p class="text-h6">Loading Project Tree</p>
				<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
			</div>
		</div>

		<!-- context menu -->
		<v-menu v-model="contextMenu.show" :position-x="contextMenu.x" :position-y="contextMenu.y" absolute offset-y max-width="350">
			<v-list>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h6 text-wrap">
						Move/Copy a task to another group. 
						</v-list-item-title>
						<v-list-item-subtitle class="text-wrap">If the task has chilren, all children will be moved/copied as well.</v-list-item-subtitle>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="moveTaskToTaskGroup(false)">
					<v-list-item-title><v-icon left small>fad fa-person-to-portal</v-icon> Move task</v-list-item-title>
				</v-list-item>
				<v-list-item @click="moveTaskToTaskGroup(true)">
					<v-list-item-title><v-icon left small>fad fa-copy</v-icon> Copy task</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<v-dialog v-model="moveTaskGroupData.show" width="500">
			<v-card>
				<v-card-title>Pick Task Group</v-card-title>
				<v-card-text>
					<task-group-picker
						class="my-3"
						v-model="moveTaskGroupData.id"
						:project-id="projectDetails.id"
						:show-archived="true"
					></task-group-picker>
				</v-card-text>

				<v-card-actions>
					<div class="ml-auto">
						<v-btn :disabled="saving" class="mr-3" @click="moveTaskGroupData.show = false">Cancel</v-btn>
						<v-btn
							color="primary"
							:disabled="contextMenu.task.task_group_id == moveTaskGroupData.id"
							:loading="saving"
							@click="doMoveTaskToTaskGroup"
						>Pick</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	// import { ProjectTasks } from "@/graphql/queries";
	import taskTreeView from "../components/taskTreeView";
	import MetaInputList from "@/components/metaInputList";
	import UserAvataaar from "../../../components/avataaars/user-avataaar.vue";
	import EmployeePicker from "@/components/fields/employeePicker.vue";
	import PreviousTasks from "@/components/fields/previousTasks.vue";
	import requiredDaysPicker from "@/components/fields/requiredDaysPicker.vue";
	import { timeInterval } from '../../../utils/timeInterval';
	import TaskGroupPicker from '../../../components/fields/taskGroupPicker.vue';

	const TASK_FRAGMENT = `
		id
		name
		project {
			id
			status
		}
		assigned_to {
			id
			uid
			full_name
			department
		}
		assigned_by {
			id
			uid
			full_name
			department
		}
		project_id
		parent_id
		previous_task_ids
		completion_order
		status
		description
		task_group_id
		due_date @formatDate(format: "Y-m-d")
		required_days
		assigned_by_user_id
		assigned_to_user_id
		start_date
		complete_date @formatDate(format: "Y-m-d")
		current_status_date
		pending_status_date
		estimated_completion_date
		timeline_due_date @formatDate(format: "Y-m-d")
		creation_date
		_meta_values
	`;

	export default {
		name: "projectDetails-TaskTree",

		components: {
			taskTreeView,
			MetaInputList,
			UserAvataaar,
			EmployeePicker,
			PreviousTasks,
			TaskGroupPicker,
			requiredDaysPicker
		},

		data() {
			return {
				selectedTask: null,
				saving: false,
				showAllFields: false,
				errorMessages: false,
				menu: false,
				completeDateMenu: false,
				selectedTaskGroupId: null,

				contextMenu: {
					show: false,
					task: {},
					x: 0,
					y: 0
				},

				moveTaskGroupData: {
					show: false,
					id: 0,
					copy: false
				}
			};
		},

		inject: ['projectContext'],

		apollo: {
			taskGroups: {
				query: gql`
					query taskTreeTaskGroups($projectId: ID! $showArchived: Boolean) {
						taskGroups: project (id: $projectId) {
							id
							task_groups(show_archived: $showArchived) {
								id
								name
								type
								start_date
								is_default
								is_archived
							}
						}
					}
				`,
				variables() {
					return {
						projectId: this.projectDetails.id,
						showArchived: true
					};
				},
				update(serverData) {
					return serverData.taskGroups.task_groups;
				}
			},

			// projectTasks: {
			// 	query() {
			// 		return ProjectTasks(this.projectDetails.id);
			// 	},
			// 	skip() {
			// 		return !this.projectDetails;
			// 	},
			// 	pollInterval: timeInterval('5 minutes'),
			// }

			taskGroupTasks: {
				query: gql`
					query taskTreeTaskGroup($id: ID!) {
						taskGroupTasks: taskGroup(id: $id) {
							id
							tasks {
								${TASK_FRAGMENT}
							}
						}
					}
				`,
				skip() {
					return !this.selectedTaskGroupId;
				},
				variables() {
					return {
						id: this.selectedTaskGroupId
					};
				},
				pollInterval: timeInterval('5 minutes'),
				update(serverData) {
					if (serverData.taskGroupTasks) {
						return serverData.taskGroupTasks.tasks;
					}

					return [];
				}
			}
			
		},

		mounted() {
			this.$modalService.on("save", this.checkModalSaveEvent);
		},

		computed: {
			projectDetails() {
				return this.projectContext.project;
			},
			selectedTaskGroup() {
				if (!this.taskGroups) {
					return null;
				}

				return this.taskGroups.find(i => i.id == this.selectedTaskGroupId);
			},
			tasks() {
				if (!this.taskGroupTasks) {
					return [];
				}

				return this.taskGroupTasks;
			},
			taskMap() {
				let result = {};
				for (let i = 0; i < this.tasks.length; i++) {
					result[this.tasks[i].id] = this.tasks[i];
				}

				return result;
			},

			orphanedTasks() {
				return this.tasks.filter((t) => {
					if (t.previous_task_ids.length) {
						return false;
					}

					return !this.tasks.some((ot) =>
						ot.previous_task_ids.includes(t.id)
					);
				});
			},
		},

		destroyed() {
			this.$db.removeModels("project-task-tree");
			this.$modalService.off("save", this.checkModalSaveEvent);
		},

		methods: {
			handleContextMenu(e, task) {
				if (!task.previous_task_ids.length) {
					e.preventDefault();

					this.contextMenu.show = false;
					this.contextMenu.x = e.clientX;
					this.contextMenu.y = e.clientY;
					this.contextMenu.task = task;
					this.$nextTick(() => {
						this.contextMenu.show = true;
					});
				}
			},

			moveTaskToTaskGroup(copy) {
				this.moveTaskGroupData.id = this.contextMenu.task.task_group_id,
				this.moveTaskGroupData.copy = copy;
				this.moveTaskGroupData.show = true;
			},

			doMoveTaskToTaskGroup() {
				this.saving = true;

				let copy = this.moveTaskGroupData.copy;

				this.$apollo
					.mutate({
						mutation: gql`
							mutation MoveTaskTree($taskId: ID! $taskGroupId: ID! $copy: Boolean) {
								moveTaskTree(taskId: $taskId taskGroupId: $taskGroupId copy: $copy) {
									id
									task_group_id
								}
							}
						`,
						variables: {
							taskId: this.contextMenu.task.id,
							taskGroupId: this.moveTaskGroupData.id,
							copy
						},
						update: (store, { data: { moveTaskTree } }) => {
							this.$snotify.success(
								`Task Tree ${copy ? 'copied' : 'moved'}`,
								"Success"
							);

							this.$apollo.queries.taskGroupTasks.refetch();
							this.moveTaskGroupData.show = false;
						},
					})
					.catch((error) => {
						this.$snotify.error("Error moving task tree", "Error");
					})
					.then(() => this.saving = false);
			},

			editGroup() {
				this.$modalService
					.create("taskGroupAddEdit", {
						taskGroupId: this.selectedTaskGroup.id
					})
					.on("save", (e, taskGroup) => {
						this.$apollo.queries.taskGroups.refetch();
						this.$snotify.success("Task Group Updated", "Success");
						// this.$apollo.queries.taskGroupTasks.refetch();
					});
			},

			addGroup() {
				this.$modalService
					.create("taskGroupAddEdit", {
						taskGroupId: 0,
						projectId: this.projectDetails.id
					})
					.on("save", (e, taskGroup) => {
						this.$apollo.queries.taskGroups.refetch();
						this.$snotify.success("Task Group Updated", "Success");
						this.selectedTaskGroupId = taskGroup.id;
					});
			},


			duplicateGroup() {
				this.$modalService
					.create("taskGroupDuplicate", {
						taskGroupId: this.selectedTaskGroup.id
						// projectId: this.projectDetails.id
					})
					.on("save", (e, taskGroup) => {
						this.$apollo.queries.taskGroups.refetch();
						// todo ask in alert if they want to go to that project. taskGroup.project_id
						console.log('task group duplicated',taskGroup)
						this.$snotify.success(
							"Task Group Duplicated", 
							"Success",
							{
								showProgressBar: false,
								timeout: 0,
								closeOnClick: true,
								buttons: [
								{
									text: "Close",
									action: null,
								},
								{
									text: "Go to Project",
									bold: true,
									action: (toast) => {
										this.$router.push({
											name: "project.tree",
											params: {
												id: taskGroup.project_id
											}
										});

										this.$snotify.remove(toast.id);
									},
								},
							],
							}
						);
					});
			},

			checkModalSaveEvent(e, task) {
				if (e.modal.modalType == 'taskAddEdit' && task.project_id == this.projectDetails.id) {
					this.$apollo.queries.taskGroupTasks.refetch();
				}
			},

			selectTask(e, id) {
				e.stopPropagation();
				// TODO: SelectedTask should not be a direct reference
				this.selectedTask = JSON.parse(JSON.stringify(this.taskMap[id]));
			},

			saveTask(e) {
				e.preventDefault();
				this.saving = true;

				let payload = {
					variables: {
						data: this.formModel()
					},
					update: (store, { data: { result } }) => {
						this.saving = false;
						this.$db.addModels("task", result);
						this.$snotify.success("Updated Task", "Success!");
						this.$apollo.queries.taskGroupTasks.refetch();
					}
				}

				// if editing
				if(this.selectedTask && this.selectedTask.id) {
					payload.mutation = gql`
						mutation ($data: UpdateTaskArgs!) {
							result: updateTask(data: $data) {
								${TASK_FRAGMENT}
							}
						}
					`;
				}
				// if new. Will there ever be a new task here?
				else {
					payload.mutation = gql`
						mutation ($data: CreateTaskArgs!) {
							result: createTask(data: $data) {
								${TASK_FRAGMENT}
							}
						}
					`;
				}

				this.$apollo
					.mutate(payload)
					.catch((error)  => {
						console.error("error saving task", error);
						this.modalErrorMessage = "There was a problem saving the data.";
						this.saving = false;
					})
			},

			// todo this used?
			uncomplete() {
				this.selectedTask.complete_date = "";
			},

			formModel() {
				let model = {
					name: this.selectedTask.name,
					parent_id: this.selectedTask.parent_id,
					previous_task_ids: this.selectedTask.previous_task_ids.join(","),
					required_days: parseInt(this.selectedTask.required_days),
					due_date: this.selectedTask.due_date,
					assigned_by_user_id: this.selectedTask.assigned_by_user_id,
					assigned_to_user_id: this.selectedTask.assigned_to_user_id,
					description: this.selectedTask.description,
					complete_date: this.selectedTask.complete_date,
					insertAfter: this.selectedTask.insertAfter,
					// timeline_due_date: this.selectedTask.timeline_due_date
				};

				if(this.selectedTask && this.selectedTask.id) {
					Object.assign(model, { id: this.selectedTask.id });
				} else {
					Object.assign(model, { project_id: this.selectedTask.project_id });
				}

				for (let field in model) {
					if (!model[field]) {
						// prevent editing of complete_date
						if ("complete_date due_date".indexOf(field) >= 0) {
							// allow to un-complete a task, or remove a task's due date, by setting the value as null:
							model[field] = null;
						}
					}
				}

				Object.assign(model, this.$refs.metaFieldList.getFields());

				return model;
			},

			addTask() {
				this.$modalService
					.create("taskAddEdit", {
						populate: {
							project_id: this.projectDetails.id,
							task_group_id: this.selectedTaskGroupId
						},
						lockedFields: {
							project_id: true,
							task_group_id: true
						}
					})
					.on("save", (e, task) => {
						this.$db.addModels("task", task, "project-task-tree");
						this.$snotify.success("Task saved.", "Success!");
					});
			},

			deleteTask() {
				this.$apollo.query({
					query: gql`
						query CheckTaskForTimers ($filterParam: QueryFilter!) {
							timers
								@filter(param: $filterParam)
							{
								total
							}
						}
					`,
					variables: {
						filterParam: {
							field: 'task.id',
							op: '=',
							value: this.selectedTask.id
						}
					}
				})
				.then((result) => {
					if (result.data.timers.total > 0) {
						this.$swal(
							"Cannot delete this task",
							`This task has one or more timers associated with it.`
						);
						return;
					}

					this.$swal({
						title: "Delete this task?",
						text: "You cannot undo this!",
						icon: "warning",
						buttons: true,
						dangerMode: true,
					}).then((willDelete) => {
						if (willDelete) {
							this.$apollo
							.mutate({
								mutation: gql`
									mutation ($id: ID!) {
										deleteTask(id: $id)
									}
								`,
								variables: {
									id: this.selectedTask.id
								},
								update: (store, { data: { deleteTask } }) => {
									this.selectedTask = null;
									this.$apollo.queries.taskGroupTasks.refetch();
								}
							})
							.catch((error) => {
								console.error("error deleting task", error);
								this.$snotify.error("Error deleting task", "Error");
							})
						}
					});
				})
			},

			insertTask({ from, to }) {
				this.$modalService
					.create("taskAddEdit", {
						populate: {
							project_id: this.projectDetails.id,
							task_group_id: this.selectedTaskGroupId,
							previous_task_ids: [from]
						},
						lockedFields: {
							project_id: true,
							task_group_id: true
						}
					})
					.on("save", (e, task) => {
						this.saving = true;

						if (task.previous_task_ids.includes(from)) {
							// still an insert operation
							let previous_task_ids = this.taskMap[to].previous_task_ids.slice(0),
								ndx = previous_task_ids.indexOf(from);
							previous_task_ids.splice(ndx, 1);
							previous_task_ids.push(task.id);

							this.$apollo
								.mutate({
									mutation: gql`
										mutation ($data: UpdateTaskArgs!) {
											result: updateTask(data: $data) {
												${TASK_FRAGMENT}
											}
										}
									`,
									variables: {
										data: {
											id: to,
											previous_task_ids
										}
									},
									update: (store, { data: { result } }) => {
										this.saving = false;
										this.$db.addModels("task", task, "project-task-tree");
										this.$db.addModels("task", result);
										this.$snotify.success("Task Inserted!","Success!");
									}
								})
								.catch((error) => {
									this.saving = false;
									console.error("error saving task", error);
									this.modalErrorMessage = "There was a problem saving the data.";
								})
						}
					});
			},
		},

		watch: {
			taskGroups(to) {
				let currentTaskGroup = to.find(tg => tg.id == this.selectedTaskGroupId);
				if (!currentTaskGroup) {
					let defaultGroup = to.find(group => group.is_default);
					if (defaultGroup) {
						this.selectedTaskGroupId = defaultGroup.id;
					}
				}
			}
		},
	};
</script>

<style lang="scss" scoped>
	// .sticky-header {
	// 	position: fixed;
	// 	right: 0;
	// 	max-width: 100%;
	// 	left: 60px;
	// }

	hr {
		border: 1px solid rgba(161, 161, 161, 0.3);
		width: 100%;
	}

	.scroll {
		max-height: 100%;
		overflow: auto;
		position: absolute;
		top: 0;
		right: 5px;
		bottom: 0;
		left: 5px;
	}

	.calendar-task.task-unassigned {
		background-color: var(--v-error-base) !important;
	}

	/* .task-tree-view ::v-deep .node {
				overflow: visible;
			} */
</style>
