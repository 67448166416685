import AjaxResource from './base'
import settings from '../utils/settings'

export default new AjaxResource(
	settings.url + '/api/person{/id}',
	{
		current: {
			method: 'GET',
			url: settings.url + '/api/person/current'
		},

		login: {
			method: 'POST',
			url: settings.url + '/api/person/login'
		},

		logout: {
			method: 'GET',
			url: settings.url + '/api/person/logout'
		},

		getTimers: {
			method: 'GET',
			url: settings.url + '/api/person/{userId}/timer'
		},

		getTasks: {
			method: 'GET',
			url: settings.url + '/api/person/{userId}/task'
		},

		list: {
			method: 'GET',
			url: settings.url + '/api/user/list'
		}
	}
);