<template>
	<v-container fluid>
		<v-card class="my-3" :loading="computedIsLoading('items')">
			<v-card-title>
				Project Alerts
				<v-btn small color="button" @click="editAlert()" class="ml-auto">Create Alert</v-btn>
			</v-card-title>

			<v-data-table v-if="computedIsResolved('items')" :headers="headers" :items="itemsForDataTable" class="my-4" ref="table" dense disable-pagination hide-default-footer>

				<template v-slot:item.id="{ item }">
					<div class="d-flex">
						<v-btn small color="primary" @click="editAlert(item.id)" class="ma-1">
							<v-icon small>fad fa-edit</v-icon>
						</v-btn>
						<v-btn small color="error" @click="removeAlert(item.id)" class="ma-1">
							<v-icon small>fad fa-trash-alt</v-icon>
						</v-btn>
					</div>
				</template>

			</v-data-table>
		</v-card>
	</v-container>
</template>


<script>
	import promiseResolver from "../components/renderless/promiseResolver.vue";

	export default {
		name: "ProjectAlerts",
		components: { promiseResolver },

		data() {
			return {
				itemData: [],
				headers: [
					{
						text: "Client",
						value: "client",
					},
					{
						text: "Project",
						value: "project",
					},
					{
						text: "Hours",
						value: "hours",
					},
					{
						text: "Email Recipients",
						value: "email_recipients",
					},
					{
						text: "",
						value: "id",
						width: "150px",
						sortable: false,
					},
				],
			};
		},

		asyncComputed: {
			items() {
				return new Promise((resolve, reject) => {
					this.$xhrRequest
						.send("get", `/api/alert/list`)
						.then((items) => {
							resolve(items);
						});
				});
			},
		},

		computed: {
			itemsForDataTable() {
				if (!this.items) {
					return [];
				}

				return this.items.map((item) => {
					let recipientNames =
						item.email_recipients &&
						item.email_recipients.map((email_recipient) => {
							let personId = parseInt(email_recipient, 10);
							if (personId) {
								let person = this.$db.getModel("person", personId);
								return this.$options.filters.FullName(person);
							}
							return email_recipient;
						});
					let project = this.$db.getModel("project", item.project_id);
					let client_id =
						(project && project.client_id) || item.client_id || null;
					let client = this.$db.getModel("client", client_id);
					let tableData = {
						id: item.id,
						client: client && client.name,
						project: project ? project.name : "All projects",
						hours: `${item.limit} / ` + item.time_period.slice(0, -2),
						email_recipients:
							recipientNames && recipientNames.join(", "),
					};
					return tableData;
				});
			},
		},

		methods: {
			removeAlert(id) {
				this.$xhrRequest.send("delete", `/api/alert/${id}`).then((_) => {
					this.computedRefresh("items");
				});
			},
			editAlert(id = null) {
				let modalData = null;
				if (id) {
					modalData = {
						sourceData: this.items.find((i) => i.id === id),
					};
				}
				this.$modalService
					.create("projectAlertAddEdit", modalData)
					.on("save", (_) => {
						this.computedRefresh("items");
					});
			},
		},
	};
</script>