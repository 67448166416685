
function isDevMode() {
	return window.webpackHotUpdate || (
		process.env.NODE_ENV !== "production" &&
		process.env.NODE_ENV !== "test" &&
		typeof console !== "undefined"
	);
}

export default {
	devMode: isDevMode(),
	
    url: isDevMode()?'http://localhost/chuck-it':'https://planet.digitalattic.com',

    userDepartments: [
        'Admin',
        'Designer',
        'Developer',
        'IT',
        'Video'
    ],

	quickbooksJobs: [
		// TODO: get descriptions into services, but not submitted with timers. Probably not until timers are converted to GQL

		// DA
		"Digital Attic:Accounting",
		"Digital Attic:Administration",
		"Digital Attic:Billing",
		"Digital Attic:Break",
		"Digital Attic:Meetings",
		"Digital Attic:Bid Work",
		"Digital Attic:Timer - No Project",
		"Digital Attic:IT Services",

		// Marketing, Online Advertising
		"Agency - Admin",
		"Agency - Advertising",
		"Agency - Copywriting",
		"Agency - Design",
		"Agency - Video & Photo",
		"Agency - Web Development",
		"Agency - Web Support",

		// cps
		"CPS - Admin",
		"CPS - Customer Support",
		"CPS - Design",
		"CPS - Programming",
		"CPS - Server",

		// Design
		"Design - Admin",
		"Design - Copywriting",
		"Design - Graphic Design",
		"Design - Photography",
		"Design - Printing",
		"Design - Stock",

		// Gallo
		"Gallo - Admin",
		"Gallo - Web Dev",
		// Gallo mainentance only
		"Gallo - Web Maintenance",

		// Grocery Outlet
		"Grocery Outlet:GO - Admin",
		"Grocery Outlet:GO - Advanced Development",
		"Grocery Outlet:GO - Design",
		"Grocery Outlet:GO - Server",
		"Grocery Outlet:GO - Web Maintenance",

		// Save Mart
		"Save Mart:SM - Admin",
		"Save Mart:SM - Web Dev",
		"Save Mart:SM - Web Maintenace",
		"Save Mart:SM - Design",
		"Save Mart:SM - Video",
		"Save Mart:SM - Email Marketing",
		
		// Software
		"Software - Admin",
		"Software - Customer Support",
		"Software - Design",
		"Software - Meetings",
		"Software - Programming",
		"Software - Server",
		"Software - Web Dev",
		"Software - Web Support",

		// Hosting
		"Hosting - Migrate Hosting",
		"Hosting - Server Maintenance",
		"Hosting - Security",
		"Hosting - Admin",

		// Studio
		"Studio - Rental",
		"Studio - Admin",
		"Studio - Labor",
		"Studio - Equipment",

		// Video and photo
		"Video & Photo - Admin",
		"Video & Photo - Editing",
		"Video & Photo - Filming",
		"Video & Photo - Graphics",
		"Video & Photo - Motion Graphics",
		"Video & Photo - Photography",
		"Video & Photo - Scriptwriting",
		"Video & Photo - Storyboarding",
		"Video & Photo - Studio",
		"Video & Photo - Transportation",

		// web support
		"Web Support - Admin",
		"Web Support - Web Maintenance",
		"Web Support - Advanced Development",
		"Web Support - IT Services",

		// Web Development
		"Web Dev - Admin",
		"Web Dev - Design",
		"Web Dev - Programming",
		"Web Dev - Copywriting",
		"Web Dev - IT Services",
		"Web Dev - Video & Photo",
		"Web Dev - SEO & Strategy",
		"Web Dev - Server",
		"Web Dev - Training",
		"Web Dev - Stock",

		// deprecated
        // "Website:Development",
        // "_DA-In House",
        // "_DA-In House:Accounting",
        // "_DA-In House:Administration",
        // "_DA-In House:Billing",
        // "_DA-In House:Break",
        // "_DA-In House:Email / Paper Work",
        // "_DA-In House:Marketing",
        // "_DA-In House:Meetings & Consultations",
        // "_DA-In House:Payroll Issues",
        // "_DA-In House:Phone Call",
        // "_DA-In House:Proposal / Contract",
        // "Domain Name Registration",
        // "Donation",
        // "Graphic Design",
        // "Graphic Design:Meetings/Consultation",
        // "ID Protect",
        // "Interest",
        // "Internet Marketing Strategy",
        // "IT Support",
        // "Labor",
        // "MPN Conference DVD",
        // "Photo Shoot",
        // "Printing/Production",
        // "Rentals",
        // "Shipping",
        // "Software Development",
        // "Software Development:iPhone Application",
        // "Software Program",
        // "Still Photo Shoot",
        // "Stock photo",
        // "Talent Fees",
        // "Video",
        // "Video:3D Animation",
        // "Video:Additional Pictures",
        // "Video:Closed Captioning",
        // "Video:Conversion",
        // "Video:Digitizing",
        // "Video:Editing",
        // "Video:Encoding",
        // "Video:Field Shooting",
        // "Video:Graphics",
        // "Video:Montage",
        // "Video:Production",
        // "Video:Script Writing",
        // "Video:Still",
        // "Video:Streaming",
        // "WebCam",
        // "Website",
        // "Website:CMS-Content Management System",
        // "Website:CMS Training",
        // "Website:Construction",
        // "Website:Consulting",
        // "Website:Domain Name Transfer",
        // "Website:Ebay Template",
        // "Website:Eblast Emailer",
        // "Website:Enterprise Zone Vouchers",
        // "Website:Flash Programming",
        // "Website:Hosting",
        // "Website:Hosting & E-mail",
        // "Website:Images",
        // "Website:Internet Marketing",
        // "Website:Internet Renewal",
        // "Website:Meeting",
        // "Website:Programming",
        // "Website:Prototyping",
        // "Website:Re-Design",
		// "Website:Search Engine Optimization (SEO)",
        // "Website:Search Engine Submission",
        // "Website:SSL Certificate",
        // "Window Dub",
        // "Website: Gallo - Meetings",
        // "Website:Gallo Maintenance 1",
		// "Website: Save Mart Maintenance"
	],

    noteRecipients: [
       /*  {
            value: "programming@digitalattic.com",
            text:  "Programming Dept.",
            initials: "Programming"
        }, */
        {
            value: "it@digitalattic.com",
            text:  "IT Dept.",
            initials: 'IT'
        },
        {
            value: "design@digitalattic.com",
            text:  "Design Dept.",
            initials: 'Design'
        }
	],
	
	sessionIdCacheKey: '_store_auth_session_id'
}
