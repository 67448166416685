import { ModalService } from '../utils/ModalService';

export function decomposeNodeId(uid) {
	try {
		let parts = atob(uid).split(':');
		return {
			object: parts[0].toLowerCase(),
			objectId: parseInt(parts[1]),
			extra: parts[2]
		};
	}
	catch {
		return {};
	}
};

export function getUidRoute(uid) {
	let parts = decomposeNodeId(uid);
	if (!parts.object) {
		throw 'Invalid UID';
	}

	switch (parts.object) {
		case 'client':
			return { name: 'client.details', params: { id: parts.objectId } };
		case 'project':
		case 'projecttaskcounts':
			return { name: 'project.details', params: { id: parts.objectId } };
		case 'task':
			return () => ModalService.create('taskAddEdit', { taskId: parts.objectId });
		case 'person':
		case 'personlink':
			if (!parts.extra) {
				return () => ModalService.create('personAddEdit', { personId: parts.objectId });
			}

			return { name: `${parts.extra.toLowerCase()}.details`, params: { id: parts.objectId } };
		case 'note':
			return () => ModalService.create('noteAddEdit', { noteId: parts.objectId });
		
		default:
			throw 'No path for this UID';
	}
}

export function triggerUidEditAction(uid) {
	let parts = decomposeNodeId(uid);
	if (!parts.object) {
		throw 'Invalid UID';
	}

	switch (parts.object) {
		case 'client':
			return ModalService.create('clientAddEdit', { clientId: parts.objectId });
		case 'project':
		case 'projecttaskcounts':
			return ModalService.create('projectAddEdit', { projectId: parts.objectId });
		case 'task':
			return ModalService.create('taskAddEdit', { taskId: parts.objectId });
		case 'person':
		case 'personlink':
			return ModalService.create(`${parts.extra.toLowerCase()}AddEdit`, { personId: parts.objectId });
		case 'note':
			return ModalService.create('noteAddEdit', { noteId: parts.objectId });
		
		default:
			throw 'No path for this UID';
	}
}