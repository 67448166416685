<template>

<v-container v-if="tasks && deadlinesAndReminders" fluid>
	<v-card class="mb-4">
		<v-card-title class="d-flex flex-column flex-sm-row align-center justify-center">

			<project-picker v-model="filterProjectId" label="Project*" placeholder="Filter by Project" dense hide-details clearable>
				<template v-slot:item="{item}">
					<span class="mr-1">{{item.name}}</span>
					<v-chip small v-if="item.status != 'active' && item.__typename == 'Project'" class="ml-auto">{{item.status}}</v-chip>
				</template>
			</project-picker>

			<div class="mx-5 mb-3 mb-sm-0 d-flex align-center">
				<v-text-field dense hide-details v-model="filter" clearable label="Search"></v-text-field>
				<v-menu offset-y open-on-hover>
					<template v-slot:activator="{ on, attrs }">
						<v-btn small class="ml-3" color="button" v-bind="attrs" v-on="on" min-width="0">
							<v-icon small>fad fa-caret-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item>
							<v-list-item-title>
								<v-checkbox dense hide-details v-model="showPending" label="Show Pending"></v-checkbox>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<v-checkbox dense hide-details v-model="showDeadlinesAndReminders" label="Show Deadlines/Reminders"></v-checkbox>
							</v-list-item-title>
						</v-list-item>
						<v-list-item>
							<v-list-item-title>
								<v-checkbox dense hide-details v-model="showWeekendMarkers" label="Show Weekend Markers"></v-checkbox>
							</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>

				<v-menu v-if="accountExecutives.length" offset-y open-on-hover :close-on-content-click="false">
					<template v-slot:activator="{ on, attrs }">
						<v-btn small v-bind="attrs" v-on="on" class="ml-3" :color="ae.length ? 'primary' : 'button'">
							AE <v-icon small class="ml-3">fad fa-caret-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item v-for="item in accountExecutives" :key="item.id">
							<v-checkbox dense hide-details :value="item.id" v-model="ae">
								<div slot="label">
									{{ item.fullName }} <v-chip dark x-small class="ml-2">{{ item.count }}</v-chip>
								</div>
							</v-checkbox>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>

			<debounce-events :events="{ change: 1000 }" v-slot="{ change }">
				<v-btn-toggle :value="taskFilters" @change="change(() => taskFilters = $event)" dark dense multiple>
					<v-btn small v-for="item in $options.taskLegendItems" :key="item.value" :color="item.value" :value="item.value">
						{{item.name}}
					</v-btn>
				</v-btn-toggle>
			</debounce-events>
		</v-card-title>
	</v-card>

	<v-data-table v-if="!busy" :loading="$apollo.queries.tasks.loading || $apollo.queries.deadlinesAndReminders.loading" dense :headers="$options.headers" :items="tableData" :search="filter" :item-class="(item) => item.class" mobile-breakpoint="768" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" disable-pagination hide-default-footer calculated-widths>

		<!-- AE -->
		<template v-slot:item.ae="{ item }">
			<span v-if="item.ae">
				{{item.ae | Initials}}
			</span>
		</template>

		<!-- Assigned To -->
		<template v-slot:item.assigned_to="{ item }">
			<template v-if="item.type == 'task'">
				<v-chip label dark x-small v-if="item.assigned_to" target="_blank"
					:to="{ name: 'employee.details', params: { id: item.assigned_to.id } }"
					class="w-100"
					:class="item.assigned_to.department"
					style="justify-content: center"
				>
					{{item.assigned_to.full_name}}
				</v-chip>
				<strong v-else class="error--text">[UNASSIGNED]</strong>
			</template>
			<strong v-else-if="item.type == 'note'">{{item.class | capitalize}}</strong>
		</template>

		<!-- Client -->
		<template v-slot:item.client="{ item }">
			<router-link v-if="item.project && item.project.client.id > 0" :to="{ name: 'client.details', params: { id: item.project.client.id } }" target="_blank">{{item.project.client.name}}</router-link>
			<span v-else-if="item.type!=='month-heading'&&item.type!=='weekend-heading'">[NO CLIENT]</span>
		</template>

		<!-- Project -->
		<template v-slot:item.project="{ item }">
			<router-link v-if="item.project" :to="{ name: 'project.details', params: { id: item.project.id } }" target="_blank">
				{{item.project.name}}
			</router-link>
			<span v-else-if="item.type!=='month-heading'&&item.type!=='weekend-heading'">[NO PROJECT]</span>
		</template>

		<!-- Task -->
		<template v-slot:item.title="{ item }">
			<div>
				<template v-if="item.type==='month-heading'">{{ item.title }}</template>
				<template v-else-if="item.type==='weekend-heading'">
					<div class="weekend-heading" :data-start="item.saturday" :data-end="item.sunday">
						<span class="weekend-heading-title">weekend</span>
					</div>
				</template>

				<a v-else href="javascript:void(0)" @click.prevent="editItem(item)" :data-uid="item.uid" title="Edit">
					{{item.title}}
					<v-icon x-small right dark>fad fa-edit</v-icon>
				</a>
			</div>
		</template>

		<!-- Days Required -->
		<template v-slot:item.required_days="{ item }">
			<span v-if="item.type==='task'">{{item.required_days}}</span>
		</template>

		<!-- Estimated Completion Date -->
		<template v-slot:item.date="{ item }">
			<div class="d-flex align-center" :class="{itemoverdue: item.isOverdue, invisible: item.type.indexOf('-heading') >= 0 }" :title="pastDue(item)">
				{{item.date | shortDate}}
				<v-icon small v-if="item.isOverdue" color="overdue" class="ml-2">fas fa-exclamation</v-icon>
			</div>
		</template>

		<!-- Time Since Current -->
		<template v-slot:item.time_current="{ item }">
			<span>
				<template v-if="item.status == 'current'">{{ item.time_current | fromNow }}</template>
			</span>
		</template>

		<!-- hide/show, overdue alert, view project timeline button -->
		<template v-slot:item.extra="{ item }">
			<div class="d-flex" v-if="item.type == 'task' || item.type == 'note'">
				<add-note-button v-if="item.type == 'task'" objType="task" :objId="item.id" :showType="true" class="ma-1"></add-note-button>
				<v-btn small dark color="primary" min-width="0" :to="{name: 'timeline', params: {project_id: item.project.id}}" class="ma-1" title="View this project's timeline">
					<v-icon small>fad fa-calendar</v-icon>
				</v-btn>
				<v-icon v-if="item.type == 'note'" @click="hide(item)" small class="ml-1" title="Hide" dark>fas fa-eye</v-icon>
			</div>
		</template>

	</v-data-table>

</v-container>
<v-container v-else class="fill-height flex-column justify-center align-center white--text">
	<p class="text-h6">Loading Timeline</p>
	<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
</v-container>

</template>

<script>
	// TODO: Need to accept parameters that auto filter by project or client.
	import moment from "moment";
	import addNoteButton from "../components/addNoteButton";
	import ProjectPicker from "@/components/fields/projectPicker.vue"

	export default {
		name: "timeline",
		components: { addNoteButton, ProjectPicker },
		// if a project_id filter is specified, show everything
		// otherwise: the default view will only show current tasks that are overdue

		taskLegendItems: [
			{
				name: "Overdue",
				value: "overdue",
			},
			{
				name: "Today",
				value: "today",
			},
			{
				name: "Soon",
				value: "soon",
			},
			{
				name: "Later",
				value: "later",
			},
		],

		headers: [
			{
				value: "ae",
				text: "AE",
			},
			{
				value: "assigned_to",
				text: "Assigned To",
			},
			{
				value: "client",
				text: "Client",
			},
			{
				value: "project",
				text: "Project",
			},
			{
				value: "title",
				text: "Task",
			},
			{
				value: "required_days",
				text: "Days",
			},
			// due_date:              {
			//     text:    'Due Date',
			//     sortable: true
			// },
			{
				value: "date",
				text: "Due Date",
				sortable: true,
			},
			{
				value: "time_current",
				text: "Time Since Current",
			},
			// extra exists as a container for the visibility/timeline buttons
			{
				value: "extra",
				text: "",
				sortable: false,
			},
		],

		data() {
			return {
				showHiddenDeadlines: false,
				sortBy: "date",
				sortDesc: false,
				filter: null,
				_filterProjectId: this.$route.params.project_id,
				filterProjectId: this.$route.params.project_id,
				filterAssignedToUserId: this.$route.params.user_id,

				ae: [],

				showPending: this.showEverything(),
				showDeadlinesAndReminders: true,
				showWeekendMarkers: true,
				/* headers: [
					{
						value: "ae",
						text: "AE",
					},
					{
						value: "assigned_to_user_name",
						text: "Assigned To",
					},
					{
						value: "client_name",
						text: "Client",
					},
					{
						value: "project_name",
						text: "Project",
					},
					{
						value: "task_name",
						text: "Task",
					},
					{
						value: "required_days",
						text: "Days",
					},
					// due_date:              {
					//     text:    'Due Date',
					//     sortable: true
					// },
					{
						value: "etc",
						text: "Due Date",
						sortable: true,
					},
					{
						value: "time_current",
						text: "Time Since Current",
					},
					// extra exists as a container for the visibility/timeline buttons
					{
						value: "extra",
						text: "",
						sortable: false,
					},
				], */

				limit: 300,
				busy: false,
			};
		},

		apollo: {
			tasks: {
				query: gql`
					query getTimelineTasks($filterParam: QueryFilter!) {
						tasks
							@filter(param: $filterParam)
							@sort(param: [{ field: "estimated_completion_date", order: asc }])
						{
							# sql
							total
							nodes {
								node {
									id
									uid
									name
									description
									timeline_due_date
									estimated_completion_date
									timeline_due_date
									current_status_date
									status
									due_status
									required_days
									assigned_by {
										id
										full_name
									}
									assigned_to {
										id
										full_name
										department @lowercase
									}
									project {
										id
										uid
										name
										client {
											id
											uid
											name
										}
										account_executive_user {
											id
											first_name
											last_name
										}
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "assigned_to.is_employee",
							op: "=",
							value: true
						},
						{
							field: "assigned_to.inactive",
							op: "=",
							value: false
						},
						{
							field: "project.status",
							op: "!=",
							value: "closed",
						},
						{
							field: "complete_date",
							op: "=",
							value: null,
						}
					];

					if(this.$options.taskLegendItems.filter(i => this.taskFilters.indexOf(i.value) < 0).length) {
						filters.push({
							field: "due_status",
							op: "in",
							value: this.taskFilters,
						})
					}

					if(this.showPending) {
						filters.push({
							field: "status",
							op: "in",
							value: ['current', 'pending'],
						})
					}
					else {
						filters.push({
							field: "status",
							op: "in",
							value: ['current'],
						})
					}


					if (this.$route.params.project_id > 0) {
						filters.push({
							field: 'project.id',
							op: '=',
							value: this.$route.params.project_id
						});
					}

					return {
						filterParam: {
							joinOp: "and",
							filters,
						}
					};
				},
			},
			deadlinesAndReminders: {
				query: gql`
					query getTimelineDeadlinesAndReminders($filterParam: QueryFilter!) {
						deadlinesAndReminders: notes
							@filter(param: $filterParam)
							@sort(param: [{ field: "date", order: desc }]) {
							total
							nodes {
								node {
									id
									content
									modified_date
									date
									note_type
									hidden
									project {
										id
										name
										account_executive_user {
											id
											first_name
											last_name
										}
										client {
											id
											name
										}
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "note_type",
							op: "in",
							value: ["deadline", "reminder"],
						},
						{
							field: "hidden",
							op: "=",
							value: false,
						},
						{
							field: 'project.status',
							op: '!=',
							value: 'closed'
						}
					];

					if (this.$route.params.project_id > 0) {
						filters.push({
							field: 'project.id',
							op: '=',
							value: this.$route.params.project_id
						});
					}

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				skip() {
					return !this.showDeadlinesAndReminders;
				}
			},
		},


		/* asyncComputed: {
			tasksLoaded() {
				// console.log("async tasksLoaded");
				return this.loadData();
			},
		}, */

		computed: {
			accountExecutives() {
				if (!this.tableDataRaw) {
					return [];
				}

				return Linq.from(this.tableDataRaw)
					.groupBy(
						(p) => p.ae ? p.ae.id : 0,
						(userId, projects) => {
							return {
								id: userId,
								fullName: userId > 0
									? this.$options.filters.FullName(projects.first().ae)
									: 'NOT SET',
								count: projects.count(),
							};
						}
					)
					.orderBy((p) => p.fullName)
					.toArray();
			},

			taskFilters: {
				get() {
					return this.showEverything()
						? this.getUserSetting("timeline-project-taskFilters", [
								"overdue",
								"today",
								"soon",
								"later",
						  ])
						: this.getUserSetting("timeline-all-taskFilters", [
								"overdue",
						  ]);
				},

				set(v) {
					if (this.showEverything()) {
						this.setUserSetting("timeline-project-taskFilters", v);
					} else {
						this.setUserSetting("timeline-all-taskFilters", v);
					}
				},
			},
			/* sortOptions() {
				// Create an options list from our fields
				return Object.entries(this.fields)
					.map(([k]) => {
						return Object.assign({ key: k }, this.fields[k]);
					})
					.filter((f) => f.sortable)
					.map((f) => {
						return {
							text: f.label,
							value: f.key,
						};
					});
			}, */
			/* taskLegendItems() {
				return [
					{
						name: "Overdue",
						value: "overdue",
					},
					{
						name: "Today",
						value: "today",
					},
					{
						name: "Soon",
						value: "soon",
					},
					{
						name: "Later",
						value: "later",
					},
				];
			}, */
			tableDataRaw() {
				if (this.$apollo.queries.tasks.loading) {
					return null;
				}

				if (this.showDeadlinesAndReminders && !this.deadlinesAndReminders) {
					return null;
				}

				let deadlinesAndReminders = [];
				if (this.showDeadlinesAndReminders) {
					deadlinesAndReminders = Linq.from(this.deadlinesAndReminders.nodes)
						.select(r => r.node)
						.select(n => ({
							type: 'note',
							class: n.note_type,
	
							uid: n.uid,
							id: n.id,
							ae: n.project.account_executive_user,
							project: n.project,
							client: n.project.client,
							title: n.content,
							date: n.date
						}));
				}

				let tasks = Linq.from(this.tasks.nodes)
					.select(r => r.node)
					.select(t => ({
						type: 'task',
						class: 'task',

						uid: t.uid,
						id: t.id,
						ae: t.project.account_executive_user,
						assigned_to: t.assigned_to,
						project: t.project,
						client: t.project.client,
						title: t.name,
						days: t.required_days,
						status: t.status,
						date: t.estimated_completion_date,
						timeline_due_date: t.timeline_due_date,
						time_current: t.current_status_date,
						required_days: t.required_days,
						isOverdue: t.estimated_completion_date > t.timeline_due_date
					}));

				let data = Linq.from(deadlinesAndReminders)
					.concat(tasks)
					.toArray();

				return data;
			},

			tableData() {
				if(!this.tableDataRaw) {
					return [];
				}
				let data = this.tableDataRaw.slice(0);

				if (this.ae.length) {
					data = data.filter((item) => this.ae.includes(item.ae.id));
				}

				if (!data.length) {
					return data;
				}

				// Add month/weekend separators
				let earliest = Linq.from(data)
					.min(r => r.date).date;

				let latest = Linq.from(data)
					.max(r => r.date).date;

				let day = moment(earliest);
				latest = moment(latest);
				while (day < latest) {
					data.push({
						type: "month-heading",
						class: 'month-heading',
						title: day.format("MMMM"),
						date: day.format("YYYY-MM-01"),
					});

					day.add(1, "months");
				}

				if (this.showWeekendMarkers) {
					day = moment(earliest).isoWeekday(6);
					let sunday = null;
					while (day < latest) {
						sunday = moment(day).isoWeekday(7);
						data.push({
							type: "weekend-heading",
							class: 'weekend-heading',
							title: "weekend",
							saturday: day.format("MM/DD/YYYY"),
							sunday: sunday.format("MM/DD/YYYY"),
							date: sunday.format("YYYY-MM-DD")
						});

						day.add(7, "day");
					}
				}

				return data;
			},
		},

		/* destroyed() {
			this.$db.removeHandle("timeline");
		}, */

		methods: {

			itemRowBackground(item) {
				return item.type;
			},

			pastDue(task) {
				let days = this.$insight.tasks.taskOverdueDays(task);
				return days > 0
					? `Past due ${days + (days === 1 ? " day" : " days")}`
					: null;
			},

			showEverything() {
				return this.$route.params.project_id > 0;
			},

			shouldShowTask(task) {
				let isTemplateProject =
					task.project && /Template Project/.test(task.project.name);
				let alwaysShowProject =
					this.filterProjectId && task.project_id == this.filterProjectId;
				return (
					this.taskFilters.includes(
						this.$insight.tasks.taskStatus(task)
					) &&
					(alwaysShowProject || !isTemplateProject)
				);
			},

			shouldShowDeadline(note) {
				return (
					note.hidden < 1 &&
					this.taskFilters.includes(
						this.$insight.tasks.taskStatus({
							timeline_due_date: note.date,
						})
					)
				);
			},

			hide(item) {
				this.$xhrRequest
					.send("put", `/api/note/${item.id}`, { hidden: 1 })
					.then((updated) => {
						this.$apollo.queries.deadlinesAndReminders.refetch();
						// this.$db.addModels("note", updated, "timeline");
						// this.$snotify.success(
						// 	this.$options.filters.capitalize(
						// 		updated.note_type + (hidden ? " hidden" : " shown"),
						// 		updated.content
						// 	)
						// );
					});
			},

			/* loadData(requester = this.$xhrRequest) {
				let options = {
					fields: "name,previous_task_ids,project_id,assigned_to_user_id,estimated_completion_date,required_days,parent_id,status,current_status_date,Note.*",
					limit: this.limit,
					project: this.filterProjectId,
					person: this.filterAssignedToUserId,
				};
				options.taskStatus = this.showPending
					? "current,pending"
					: "current";
				this.busy = true;
				return requester
					.send("get", "/api/task/timeline", options)
					.then((tasks) => {
						// this.$db.removeHandle('timeline');
						this.$db.removeHandle("timeline");
						if (typeof tasks.tasks !== "undefined") {
							this.$db.addModels("task", tasks.tasks, "timeline");
							this.$db.addModels("note", tasks.deadlines, "timeline");
						} else {
							// fixme: bug?
							this.$db.addModels("task", tasks[0], "timeline");
							this.$db.addModels("note", tasks[1], "timeline");
							return true;
						}
						return true;
					})
					.finally(() => {
						this.busy = false;
					});
			}, */

			/* updateRequiredDays(item) {
				let task = this.$db.getModel("task", item.task_id);
				if (item.required_days < 1) {
					item.required_days = 1;
					// return false;
				}
				task.required_days = item.required_days;
				let request = this.$xhrRequest.chainStart();

				let api = `/api/task/${task.id}?fields=name,previous_task_ids,project_id,assigned_to_user_id,estimated_completion_date,required_days,parent_id`;
				request
					.send("put", api, { required_days: task.required_days })
					.then((task) => {
						// this.$db.addModels('task', task, 'timeline');
					});

				this.loadData(request);

				request
					.chainEnd()
					.then(() => {
						this.$snotify.success("Updated task required days");
					})
					.catch(() => {
						this.$snotify.error("Error!", "Update required days");
					});
			}, */

			editItem(item) {
				if (item.type == 'task') {
					this.editTask(item.id);
				}
				else {
					this.editNote(item.id);
				}
			},

			editTask(task_id) {
				this.$xhrRequest
					.send("get", `/api/task/${task_id}`)
					.then((task) => {
						this.$modalService
							.create("taskAddEdit", {
								taskId: task.id,
							})
							.on("save", (e, task) => {
								this.$db.addModels("task", task, "timeline");
							});
					});
			},

			editNote(note_id) {
				this.$modalService
					.create("noteAddEdit", {
						title: (note) => `Update ${note.note_type}`,
						showType: false,
						noteId: note_id,
					})
					.on("save", (e, task) => {
						this.$db.addModels("note", task, "timeline");
					});
			},

			updateTask() {
				console.log("updating");
			},
		},

		watch: {
			$route(to) {
				this.filterProjectId = to.params.project_id;
				this.showPending = to.params.project_id > 0;
			},

			filterProjectId(to) {
				let params = {};
				if (to) {
					params.project_id = to;
				}
				this.$router.push({
					name: "timeline",
					params,
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep tr {
		&.deadline,
		&.reminder {
			color: white;
			a {
				color: white;
			}
		}
	}

	.invisible {
		font-size: 0;
	}

	/* ::v-deep .theme--dark.v-data-table .v-data-table-header th.sortable.active {
			border-color: rgba(255, 255, 255, 0.12) !important;
			background-color: #2c2c2c !important;
		} */

	::v-deep table {
		td {
			font-size: 13px;
		}

		thead {
			th {
				white-space: nowrap;
			}
		}

		@media (min-width: 768px) {
			tbody tr.month-heading,
			tbody tr.weekend-heading {
				// background-color: map-get($grey, "darken-3");
				position: relative;
			}

			tbody tr.weekend-heading {
				td {
					border-bottom: 0 !important;
				}
				td:nth-child(5) div {
					height: 1px;
					background-color: var(--v-dividers-base);
					overflow: visible;
					color: var(--v-text-color-base);
					left: 15px;
					right: 15px;
					position: absolute;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: space-between;
					line-height: 1;

					> div:before {
						content: attr(data-start);
					}

					> div:after {
						content: attr(data-end);
					}

					> div:after,
					> div:before {
						display: inline-block;
						padding: 0 5px;
						font-family: "Roboto Mono", sans-serif;
						background-color: var(--v-background-base);
					}

					.weekend-heading-title {
						background-color: var(--v-background-base);
						padding: 0 5px;
					}
				}
				&:hover {
					background-color: transparent !important;
				}
			}

			tr.month-heading {
				td {
					border-bottom: 0 !important;
					vertical-align: middle;
				}
				td:nth-child(5) {
					> div {
						color: var(--v-text-color-base);
						left: 0;
						right: 0;
						position: absolute;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						line-height: 1;
						font-size: 14px;
						margin-top: -7px;
					}
				}
			}
		}
	}
</style>
