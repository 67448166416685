<template>
    <v-container fluid>
        <!-- vuetify? -->
        <v-card>
            <v-card-title>Quickbooks Online Timers Awaiting Transfer</v-card-title>

            <v-card-text>

                <promise-resolver :input="timersAwaitingTransfer()" v-slot:default="{ isResolved, value, isLoading }">
                    <div v-if="isResolved">
                        <v-data-table v-if="value.length"
                                      dense
                                      :headers="timersAwaitingTransferHeaders"
                                      :items="value"
                                      :loading="isLoading"
                                      class="mt-0">
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td width="3rem">
                                        <v-btn x-small color="primary" class="mx-0 py-1 btn-edit-timer" @click="toggleEdit(item)">
                                            <v-icon x-small v-if="editing[item.ID]">fad fa-times</v-icon>
                                            <v-icon x-small v-else>fad fa-pencil</v-icon>
                                        </v-btn>
                                    </td>
                                    <td>{{ item.Date }}</td>
                                    <td>{{ item.Employee }}</td>
                                    <td>{{ item.Time }}</td>
                                    <td>{{ item.Service }}</td>
                                    <td>{{ item.Project }}</td>
                                    <td>{{ item.Description }}</td>
                                    <td>{{ item.qbo_transfer_error }}</td>
                                </tr>
                                <tr v-if="editing[item.ID]">
                                    <td colspan="8">
                                        <timer-card :timer="item.timer" @timerUpdated="onUpdateTimer" hideStartSubmitButtons></timer-card>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>

                        <div v-else>There are no new timers ready to be transferred.</div>
                    </div>
                </promise-resolver>


            </v-card-text>

        </v-card>
    </v-container>
</template>
<script>
import settings from "@/utils/settings";
import promiseResolver from "../components/renderless/promiseResolver";
import TimerCard from "@/components/timerCard";

export default {
    name:       "accountExport",
    components: {
        promiseResolver,
        TimerCard
    },
    data() {
        return {
            editing:{},
            numReadyForTransfer:           null,
            lastResults:                   null,
            recentErrors:                  null,
            transferredTimersRow0:         null,
            busy:                          false,
            searchTransferredItems:        null,
            timersAwaitingTransferHeaders: [
                {
                    text:''
                },
                {
                    text:      "Date",
                    value:     "Date",
                    cellClass: "text-no-wrap",
                },
                {
                    text:  "Employee",
                    value: "Employee",
                },
                {
                    text:  "Time",
                    value: "Time",
                },
                {
                    text:  "Service",
                    value: "Service",
                },
                {
                    text:  "Project",
                    value: "Project",
                },
                {
                    text:  "Description",
                    value: "Description",
                },
                {
                    text:  'Status',
                    value: 'qbo_transfer_error'
                },

            ],
        };
    },

    computed: {},
    methods:  {
        toggleEdit(item) {
            this.$set(this.editing, item.ID, !this.editing[item.ID]);
        },
        onUpdateTimer(e) {
            console.log('timer saved', e);
            this.$snotify.success("Success!", `Timer updated.`);
        },
        doExport() {
            this.busy = true;
            let request = this.$xhrRequest.send("get", "/api/export/timers");
            return request.then(
                (response) => {
                    this.lastResults = response;
                    this.busy = false;
                },
                (error) => {
                    this.busy = false;
                }
            );
        },
        refresh() {
            setTimeout(() => {
                this.$refs.table.refresh();
            }, 3000);
        },
        itemsProvider(ctx) {
            let request = this.$xhrRequest.send("get", `/api/export/files`);
            return request.then(
                (files) => {
                    return files.map((file) => {
                        // let parts = file.match(/(\d{4}-\d\d-\d\d)_(\d\d-\d\d-\d\d)-timesheets.iif/);
                        return {
                            filename: file,
                            // date:     `${parts[1]} ${parts[2]}`
                        };
                    });
                },
                (xhr) => {
                    return [];
                }
            );
        },

        transferredTimers(ctx) {
            let request = this.$xhrRequest.send(
                "get",
                `/api/export/get-transferred-timers`
            );
            return request.then(
                (response) => {
                    return response;
                },
                (xhr) => {
                    return [];
                }
            );
        },


        /**
         * Call \ExportController::getTimersAwaitingTransfer() to return list of timers that have not been exported yet
         */
        timersAwaitingTransfer(ctx) {
            let request = this.$xhrRequest.send(
                "get",
                `/api/export/timers-awaiting-transfer`
            );
            return request.then(
                (response) => {
                    return response;
                },
                (xhr) => {
                    return [];
                }
            );
        },

        downloadLink(file) {
            return `${settings.url}/api/export/download/${file}`;
        },

        async retransfer(e, id) {
            if (!confirm('Are you sure you want to transfer this timer again to Quickbooks Online?')) {
                return;
            }
            let response = await this.$xhrRequest.send('post', `/api/export/retransfer-timer`, {id: id});
            if (response && !response.error) {
                this.$snotify.success(`Timer Transferred`);
            } else {
                await this.$swal({
                    title: 'The timer could not be transferred',
                    text:  response.error,
                    icon:  'error',
                });
            }
            this.$refs.transferredTimersTable.refresh();
        }
    },
};
</script>
<style lang="scss" scoped>
::v-deep .card-quick-timer {
    max-width: 400px;
}
::v-deep .v-data-footer {
    display:none;
}
table[aria-busy="true"] {
    .busy {
        text-align: center;
        display: block !important;
    }

    .not-busy {
        display: none;
    }
}
</style>
