export function addEventListener(target, event, fn, { useCapture = false } = {}) {
	target.addEventListener(event, fn, { useCapture });
	
	let removed = false;
	return function () {
		if (!removed) {
			target.removeEventListener(event, fn, { useCapture });
			removed = true;
		}
    };
}

export const addEventListenerMixin = {
    beforeCreate() {
        this._eventListeners = [];
    },

    beforeDestroy() {
        let unsubs = this._eventListeners;
        this._eventListeners = null;

        unsubs.forEach(unsub => unsub());
    },

    methods: {
        addEventListener(target, event, fn, { useCapture = false } = {}) {
            target.addEventListener(event, fn, { useCapture });

			let removed = false;
			let unsub = () => {
				if (!removed) {
					target.removeEventListener(event, fn, { useCapture });
	
					if (this._eventListeners) {
						let ndx = this._eventListeners.indexOf(unsub);
						this._eventListeners.splice(ndx, 1);
					}

					removed = true;
				}
			};

            this._eventListeners.push(unsub);
            return unsub;
        }
    }
};