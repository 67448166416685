import colors from 'vuetify/lib/util/colors';

const themeColors = {
	light: "#fdf9ff",
	dark: "#1e0146",
	purple: "#4b1590",
	lightPurple: colors.deepPurple.lighten4,
	darkestPurple: "#1e0146",
	teal: "#33BBCC",
	magenta: "#E524C5",
	green: "#28df87"
};

export default {
	options: {
		customProperties: true,
	},

	primary: themeColors.teal,
	secondary: themeColors.purple,
	tertiary: '#c643c8',
	accent: colors.lightPurple,

	magenta: themeColors.magenta,
	error: themeColors.magenta,

	anchor: themeColors.darkestPurple,

	odd: "#eeeeee",
	background: "#eeeeee",

	// departments
	admin: "#4C1396",
	it: "#0097a7",
	video: "#3338cc",
	designer: "#6e48d0",
	developer: "#3673ce",

	// status
	pending: "#1e0146",
	overdue: themeColors.magenta,
	today: "#B7159C",
	soon: "#800F6D",
	later: "#49093E",

	current: colors.lightBlue.darken1,

	//timer
	"status-active": themeColors.green,
	disabled: colors.grey.darken4,


	"task-complete": colors.teal.base,
	"task-current": "#c643c8", // lets not use a danger color. think of something else.
	"task-pending": colors.deepPurple.base,

	// task status:
	unassigned: themeColors.magenta,
	assigned: themeColors.purple,
	completed: colors.teal.base,

	overtime: themeColors.magenta,

	deadline: themeColors.magenta,
	reminder: colors.blue.darken2,

	"text-color": themeColors.dark,
	"cards": themeColors.light,
	"app-bar": themeColors.light,
	"toolbar": "#e0dce2",
	"chips": "#888888",
	"dividers": "#dcdcdc",
	"navigation-drawer": themeColors.light,
	"system-bar": "#4a1192",

	// calendar
	"calendar-background": "#f8f0fc",
	"outside-background-color": themeColors.light,
	"weeknumber-background-color": colors.lightPurple,
	"line-color": "#e0dce2",
	"interval-color": "#e0dce2",
	"interval-line-color": "#e0dce2",
	"past-color": themeColors.dark,
	
	// table
	"table-active": themeColors.purple,
	"table-hover": "#dddddd",
	"table-group": "#f8f0fc",
	
	// picker
	"picker-body": themeColors.light,
	"picker-clock": themeColors.light,
	"picker-indeterminateTime": themeColors.light,
	"picker-title": themeColors.light,
	
	// kbd
	"kbd-bg": themeColors.magenta,
	"kbd-color": themeColors.light,
	
	"toggle": themeColors.magenta,

	// text over background
	"text-over-bg": colors.grey.darken4,
	"ot-text-over-bg": "#ffa9ff",
	"working-text-over-bg": themeColors.green,
}