<template>
	<v-container v-if="projects" fluid>

		<v-toolbar flat dense class="mb-4">
			<v-toolbar-title>Projects</v-toolbar-title>
			<v-spacer></v-spacer>
			<project-counts class="ml-auto body-2"></project-counts>
			<v-btn small @click="$apollo.queries.projects.refetch()" class="ml-3">
				<v-icon small>fad fa-sync</v-icon>
			</v-btn>
			<template v-slot:extension>
				<v-tabs v-if="projectCats.length" v-model="selectedTab" show-arrows grow icons-and-text ref="tabs">
					<v-tab v-for="i in projectCats" :key="i.value">
						<span>
							{{i.text}}
							<template v-if="categoryCounts">
								({{categoryCounts[i.value] || 0}})
							</template>
						</span>
						<v-icon small class="mb-1">{{$insight.helpers.getProjectIcon(i.value)}}</v-icon>
					</v-tab>
				</v-tabs>
			</template>
		</v-toolbar>

		<v-card class="mb-4" :loading="$apollo.queries.projects.loading">
			<v-card-text>
				<div class="d-flex flex-wrap align-end">
					<debounce-events :events="{ change: 750 }" v-slot="{ change }">
						<v-text-field :value="searchTxt" hide-details clearable placeholder="Search in selected category" @input="change(() => searchTxt = $event || '')" class="mb-4 mb-sm-0"></v-text-field>
					</debounce-events>

					<v-menu offset-y open-on-hover :close-on-content-click="false">
						<template v-slot:activator="{ on, attrs }">
							<v-btn small class="mx-3 mb-4 mb-sm-0" color="button" v-bind="attrs" v-on="on" min-width="0">
								<v-icon small>fad fa-caret-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item>
								<v-list-item-title>
									<v-checkbox dense hide-details v-model="showWithTimers" label="Show With Timers Only"></v-checkbox>
								</v-list-item-title>
							</v-list-item>
							<v-list-item>
								<v-list-item-title>
									<v-checkbox dense hide-details v-model="showWithActiveTimers" label="Show Active Timers Only"></v-checkbox>
								</v-list-item-title>
							</v-list-item>
							<v-list-item>
								<v-list-item-title>
									<v-checkbox dense hide-details v-model="showOnFire" label="Show On Fire Only"></v-checkbox>
								</v-list-item-title>
							</v-list-item>
							<v-divider class="mt-2"></v-divider>
							<v-list-item>
								<v-list-item-title>
									<v-icon small class="mr-2">fad fa-print</v-icon>
									<a class="dropdown-item" href="" @click.prevent="printProjects(false)">
										Print Projects
									</a>
								</v-list-item-title>
							</v-list-item>
							<v-list-item>
								<v-list-item-title>
									<v-icon small class="mr-2">fad fa-print</v-icon>
									<a class="dropdown-item" href="" @click.prevent="printProjects(true)">
										Print by Time Spent
									</a>
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>

					<v-btn-toggle v-model="projectStatusSelected" dense multiple mandatory class="mr-3">
						<v-btn small v-for="item in projectStatuses" :key="item" :value="item">
							{{item}}
						</v-btn>
					</v-btn-toggle>

					<v-menu offset-y open-on-hover :close-on-content-click="false">
						<template v-slot:activator="{ on, attrs }">
							<v-btn small v-bind="attrs" v-on="on" color="button" class="mr-3">
								Display <v-icon small class="ml-3">fad fa-caret-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-for="item in displayOptions" :key="item.id">
								<v-checkbox dense hide-details :value="item" v-model="projectDisplay">
									<div slot="label">
										{{ item }}
									</div>
								</v-checkbox>
							</v-list-item>
						</v-list>
					</v-menu>

					<v-menu offset-y open-on-hover :close-on-content-click="false" bottom left>
						<template v-slot:activator="{ on, attrs }">
							<v-btn small v-bind="attrs" v-on="on" class="mt-3 mr-3" :color="ae.length ? 'primary' : 'button'">
								AE <v-icon small class="ml-3">fad fa-caret-down</v-icon>
							</v-btn>
						</template>
						<v-list v-if="aes">
							<v-list-item v-for="item in aes.nodes" :key="(item.node.account_executive_user || {}).id">
								<v-checkbox dense hide-details :value="item.node.account_executive_user ? item.node.account_executive_user.id : null" v-model="ae">
									<div slot="label">
										<template v-if="item.node.account_executive_user">
											{{ item.node.account_executive_user.full_name || 'Not Set' }} <v-chip dark x-small class="ml-2">{{ item.group_total }}</v-chip>
										</template>
										<template v-else>
											NOT SET <v-chip dark x-small class="ml-2">{{ item.group_total }}</v-chip>
										</template>
									</div>
								</v-checkbox>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</v-card-text>
		</v-card>


		<div v-for="row in groupedProjects" :key="row.client.id">
			<v-card class="mb-4">
				<v-card-title class="justify-space-between">
					<router-link :to="{ name: 'client.details', params: { id: row.client.id } }">
						{{ row.client.name }}
					</router-link>
					<add-note-button objType="client" :objId="row.client.id" :showType="true"></add-note-button>
				</v-card-title>

				<v-card-text>
					<ul class="project-list" style="list-type:unstyled:padding:0;">
						<li v-for="(project, projectNdx) in row.projects" :key="project.id"
							:class="['project-' + project.status, projectNdx % 2 ? 'odd' : 'even']"
							class="d-flex flex-wrap flex-md-nowrap align-md-center justify-space-between py-1">

							<div class="project-status ml-2 mr-1">
								<i v-if="project.status === 'hidden'" class="fal fa-eye-slash" title="Hidden project"></i>
								<i v-else-if="project.status === 'closed'" class="fal fa-ban" title="Closed project"></i>
							</div>

							<div class="project-info mr-1">
								<v-tooltip v-if="project.last_status" right>
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											<v-icon small>fad fa-info-square</v-icon>
										</span>
									</template>
									<div v-html="project.last_status.content"></div>
								</v-tooltip>
							</div>

							<div class="project-title">
								<router-link :to="{name: 'project.details', params: {id: project.id}}">{{ project.name }}</router-link>
								<v-btn x-small color="button" v-clipboard:copy="project.name" min-width="0" v-clipboard:success="copiedToClipboard" class="ml-1" title="Copy project name to clipboard">
									<v-icon x-small>fas fa-copy</v-icon>
								</v-btn>
							</div>

							<!-- QB type -->
							<div style="max-width:230px;" title="Quickbooks Type">
								<div v-if="project.type">{{project.type}}</div>
								<div v-else class="error--text">NO TYPE SET</div>
							</div>

							<div v-if="projectDisplay.includes('category')" class="project-cat text-overline" title="category">
								{{project.category}}
							</div>

							<div v-if="projectDisplay.includes('ae') && project.account_executive_user" class="project-ae">
								<router-link :to="{name: 'employee.details', params: {id: project.account_executive_user.id}}">
									{{project.account_executive_user.full_name}}
								</router-link>
							</div>

							<div v-if="projectDisplay.includes('unassigned tasks')" class="project-unassigned-tasks mr-sm-3" title="Open Tasks: unassigned / total tasks">
								<v-icon x-small class="error--text mr-1" :style="`opacity: ${projectStalled(project) ? '1' : '0'}`">fas fa-fire</v-icon>
								<template v-if="project.task_counts">
									{{ project.task_counts.unassigned || 0 }}/{{ project.task_counts.total || 0 }}
								</template>
							</div>

							<div v-if="projectDisplay.includes('current tasks')" class="project-current-tasks pr-2">
								<div v-if="project.current_tasks && project.current_tasks.length">
									<div v-for="task in project.current_tasks" :key="task.id"
										 v-html="currentTaskInformation(task)" class="mb-1 pr-1 ellipsis d-flex"
										 @click.prevent="currentTaskClick">
									</div>
								</div>
								<div v-else>[ none ]</div>
							</div>

							<div v-if="projectDisplay.includes('hours')" class="project-hours" title="Hours used / Hours allocated">
								{{ projectTotalHours(project.time_spent) }} / {{project.hours_allocated}}
							</div>

							<div v-if="projectDisplay.includes('communication') " class="project-last-comm" title="Days since last communication to client"
								 :class="{'error--text font-weight-bold': project.last_communication>7 || project.last_communication == null }">
								<template v-if="row.client.id != 595">
									<div v-if="project.last_communication >= 0">
										{{ project.last_communication }}
									</div>
									<div v-else>
										Never
									</div>
								</template>
							</div>

							<div v-if="projectDisplay.includes('due')" class="project-due" title="Due">{{ project.due_date | shortDate('[NO DUE DATE]') }}</div>

							<div class="project-actions d-flex justify-end">
								<v-btn v-if="projectDisplay.includes('timeline')" dark small color="primary darken-1" :to="{name: 'timeline', params: {project_id: project.id}}" title="View Timeline" min-width="0" class="mr-1">
									<v-icon small>fad fa-calendar</v-icon>
								</v-btn>
								<add-note-button v-if="projectDisplay.includes('note')" objType="project" :objId="project.id" :showType="true"></add-note-button>
								
								<!-- <object-link :uid="project.uid" :edit="true" :vbtn="true">
									<template v-slot:content>
										<v-icon small>fad fa-edit</v-icon>
									</template>
								</object-link> -->

								<object-link :uid="project.uid" :edit="true" v-slot="{handler}" @save="refetchAll">
									<v-btn @click="handler" small class="ml-1" min-width="0"><v-icon small>fad fa-edit</v-icon></v-btn>
								</object-link>
							</div>
						</li>
					</ul>
				</v-card-text>
			</v-card>
		</div>


		<div v-if="!groupedProjects.length" class="container">
			There are no projects or your filters are too strict.
		</div>
	</v-container>
	<v-container v-else class="fill-height flex-column justify-center align-center">
		<p class="text-h6">Loading Projects</p>
		<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
	</v-container>

</template>

<script>
	import addNoteButton from "../components/addNoteButton";
	import projectCounts from "../components/projectCounts";
	import PDFGenerator from "../utils/PDFGenerator";
	import moment from "moment";
	import { FactoryAEQuery } from "../graphql/queries";
	import { parseParams, stringifyParams } from "../utils/advancedRouteParams";
	import userData from '../utils/user-data';

	export default {
		name: "clientsAndProjects",
		components: { addNoteButton, projectCounts },
		apollo: {
			projects: {
				query: gql`
					query GetClientAndProjects(
						$filterParam: QueryFilter!
						$skipAE: Boolean!
						$skipCommunication: Boolean!
						$skipTaskCounts: Boolean!
						#$skipCurrentTasks: Boolean!
						$skipHours: Boolean! # $limit: Int
					) # $offset: Int
					{
						queries
						projects
							@sort(
								param: [
									{ field: "client.name", order: asc }
									{ field: "name", order: asc }
								]
							)
							@filter(param: $filterParam) { # @page(limit: $limit, offset: $offset)
							# sql
							total
							nodes {
								node {
									uid
									id
									name
									status
									category #@skip(if: $skipCategory)
									type
									# TODO: fix
									time_spent @skip(if: $skipHours)
									# @formatDuration(format: "%H")
									hours_allocated @skip(if: $skipHours)
									# due_date is required for current_tasks
									due_date
									last_communication @skip(if: $skipCommunication)
									account_executive_user @skip(if: $skipAE) {
										id
										full_name
									}
									task_counts @skip(if: $skipTaskCounts) {
										unassigned
										total
									}
									last_status {
										content
									}
									current_tasks {
										#@skip(if: $skipCurrentTasks)
										id
										name
										required_days
										timeline_due_date
										assigned_to {
											id
											initials
											department
											inactive
											full_name
										}
									}
									client {
										id
										name
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						// limit: this.itemsPerPage,
						// offset: (this.page - 1) * this.itemsPerPage,
						filterParam: this.getGraphqlFilterParams({
							includeCategory: true,
							includeAE: true,
						}),
						//skipCategory: !this.projectDisplay.includes("category"),
						skipAE: !this.projectDisplay.includes("ae"),
						skipCommunication:
							!this.projectDisplay.includes("communication"),
						skipTaskCounts:
							!this.projectDisplay.includes("unassigned tasks"),
						// skipCurrentTasks: !this.projectDisplay.includes("current tasks"),
						skipHours: !this.projectDisplay.includes("hours"),
					};
				},
				skip() {
					if (!this.aes || this.$apollo.queries.aes.loading || !this.projectCats.length) {
						return true;
					}

					if (
						!this.categoryCounts ||
						this.$apollo.queries.categoryCounts.loading
					) {
						return true;
					}

					return false;
				},
			},

			aes: {
				query() {
					return FactoryAEQuery({
						statuses: this.projectStatusSelected,
						categories: this.selectedCategory == 'All'
							? []
							: [this.selectedCategory],
						showWithTimers: this.showWithTimers,
						showWithActiveTimers: this.showWithActiveTimers,
						searchTxt: this.searchTxt,
					});
				},
			},

			categoryCounts: {
				query: gql`
					query GetCategoryCounts($filterParam: QueryFilter!) {
						categoryCounts: projects
							@filter(param: $filterParam)
							@groupBy(fields: ["category"])
							@sort(param: [{ field: "category", order: asc }]) {
							nodes {
								group_total
								node {
									category
								}
							}
						}
					}
				`,

				variables() {
					return {
						filterParam: this.getGraphqlFilterParams({
							includeAE: true,
						}),
					};
				},

				update(serverData) {
					let map = {}, total = 0;
					serverData.categoryCounts.nodes.forEach(row => {
						map[row.node.category || ''] = row.group_total;
						total += row.group_total;
					});

					map.All = total;

					return map;
				}
			},
		},

		data() {
			let displayOptions = [
				"category",
				"ae",
				"communication",
				"unassigned tasks",
				"current tasks",
				"hours",
				"due",
				"timeline",
				"note",
			];

			return {
				// searchTxt: "",
				showWithTimers: false,
				showWithActiveTimers: false,
				showOnFire: false,

				displayOptions,
				projectDisplay: this.getUserSetting(
					"projectDisplay",
					displayOptions
				),

				ae: [],

				projectStatusSelected: ["active"],
				projectStatuses: ["active", "hidden", "closed"],
				// itemsPerPage: 50,
				// page: 1,

				// tabs: null,
			};
		},

		mounted() {
			this.$watch(
				() => {
					return [
						this.showWithTimers,
						this.showWithActiveTimers,
						this.showOnFire,
						this.searchTxt,
						this.projectStatusSelected,
						this.selectedCategory,
						this.ae,
					];
				},
				() => {
					this.page = 1;
				}
			);
		},

		computed: {
			projectCats() {
				let values = this.getEnumValues('ProjectCategory');
				if (values.length) {
					values = [
						...values,
						{
							text: 'Not Set',
							value: ''
						},
						{
							text: 'All',
							value: 'All'
						}
					];
				}

				return values;
			},

			routeParams: {
				get() {
					return parseParams(this.$route.params);
				},
				set(v) {
					this.$router.replace({ params: stringifyParams(v, true) });
				},
			},

			selectedTab: {
				get() {
					if (this.projectCats.length && this.routeParams.options.hasOwnProperty('category')) {
						let ndx = this.projectCats.findIndex(i => {
							return i.value == this.routeParams.options.category;
						});

						return ndx >= 0
							? ndx
							: null;
					}
					
					return null;
				},
				set(v) {
					if (v == null) {
						return;
					}

					let newParams = JSON.parse(JSON.stringify(this.routeParams));
					newParams.options.category = this.projectCats[v].value;

					this.setUserSetting('clientsAndProjects.lastCat', this.projectCats[v].value);
					this.routeParams = newParams;
				},
			},

			searchTxt: {
				get() {
					if (this.routeParams.options && this.routeParams.options.hasOwnProperty('search')) {
						return this.routeParams.options.search;
					}

					return '';
				},
				set(v) {
					let newParams = JSON.parse(JSON.stringify(this.routeParams));

					if (v == '') {
						delete newParams.options.search;
					}
					else {
						newParams.options.search = v;
					}

					this.routeParams = newParams;
				}
			},

			selectedCategory() {
				if (!this.projectCats.length || this.selectedTab == null || this.selectedTab == -1) {
					return undefined;
				}

				return this.projectCats[this.selectedTab].value || null;
			},

			// rewrite graphql data.
			groupedProjects() {
				if (!this.projects || !this.projects.nodes) {
					return [];
				}

				let projects = Linq.from(this.projects.nodes).select(
						(row) => row.node
					),
					clients = {};

				clients = projects
					.groupBy(
						(n) => n.client.id,
						(_, projects) => ({
							client: projects.first().client,
							projects: projects.toArray(),
						})
					)
					.orderByDescending((c) => c.client.name.startsWith("_"))
					.thenBy((c) => c.client.name)
					.toArray();

				return clients;
			},

			numberOfPages() {
				if (!this.projects) {
					return 0;
				}

				return Math.ceil(this.projects.total / this.itemsPerPage);
			},
		},

		methods: {
			refetchAll() {
				this.$apollo.queries.projects.refetch();
				this.$apollo.queries.aes.refetch();
				this.$apollo.queries.categoryCounts.refetch();
			},

			getGraphqlFilterParams({
				includeCategory = false,
				includeAE = false,
			} = {}) {
				let filters = [
					{
						field: "status",
						op: "in",
						value: this.projectStatusSelected,
					},
				];

				if (this.showWithTimers || this.showWithActiveTimers) {
					filters.push({
						field: "timers.id",
						op: "!=",
						value: null,
					});

					if (this.showWithActiveTimers) {
						filters.push({
							field: "timers.stop",
							op: "=",
							value: null,
						});
					} else if (this.showWithTimers) {
						filters.push({
							joinOp: "or",
							filters: [
								{
									field: "timers.submit_date",
									op: "=",
									value: null,
								},
								{
									joinOp: "and",
									filters: [
										{
											field: "timers.submit_date",
											op: ">=",
											value: moment()
												.startOf("day")
												.format("YYYY-MM-DD HH:mm:ss"),
										},
										{
											field: "timers.submit_date",
											op: "<=",
											value: moment()
												.endOf("day")
												.format("YYYY-MM-DD HH:mm:ss"),
										},
									],
								},
							],
						});
					}
				}

				if (includeAE && this.ae.length) {
					filters.push({
						field: "account_executive_user.id",
						op: "in",
						value: this.ae,
					});
				}

				if (includeCategory && this.selectedCategory != 'All') {
					filters.push({
						field: "category",
						op: "=",
						value: this.selectedCategory || null
					});
				}

				if (this.searchTxt) {
					let searchTxt = this.searchTxt.replace(/(%)/g, '\\$1');

					filters.push({
						joinOp: "or",
						filters: [
							{
								field: "name",
								op: "like",
								value: `%${searchTxt}%`,
							},
							{
								field: "client.name",
								op: "like",
								value: `%${searchTxt}%`,
							},
							{
								field: "type",
								op: "like",
								value: `%${searchTxt}%`
							}
						],
					});
				}

				if (this.showOnFire) {
					filters.shift();
					filters.push(
						{
							field: "status",
							op: "=",
							value: "active",
						},
						// {
						// 	field: 'current_tasks.id',
						// 	op: '!=',
						// 	value: null
						// },
						{
							joinOp: "or",
							filters: [
								{
									field: "current_tasks.assigned_to.id",
									op: "=",
									value: null,
								},
								{
									field: "current_tasks.assigned_to.inactive",
									op: "=",
									value: true,
								},
							],
						}
					);
				}

				return {
					joinOp: "and",
					filters,
				};
			},

			nextPage() {
				if (this.page + 1 <= this.numberOfPages) this.page += 1;
			},

			previousPage() {
				if (this.page - 1 >= 1) this.page -= 1;
			},

			printProjects(showBudget) {
				PDFGenerator.projectsReport(this.groupedProjects, showBudget);
			},

			projectTotalHours(time_spent) {
				return Math.floor(time_spent / 3600);
			},

			currentTaskInformation(task) {
				let assignedToUser = task.assigned_to;
				let initials = "N/A",
					userName = "N/A",
					department = "username",
					timelineDueDate = "";

				if (assignedToUser) {
					initials = assignedToUser.initials;
					userName = assignedToUser.full_name;
					department = assignedToUser.department;
				}

				if (task.timeline_due_date) {
					timelineDueDate = this.$options.filters.formatDate(
						task.timeline_due_date,
						"MM/DD",
						false
					);
				}

				return `<div style="background-color:var(--v-${department.toLowerCase()}-base)" class="badge mr-2 white--text" title="${userName} (${department})">${initials}</div> <span title="Current Task Due">${timelineDueDate}</span> <div class="badge mx-2" style="background:var(--v-chips-base)" title="Required Days">${
					task.required_days
				}</div> <a href class="current-task" data-id="${
					task.id
				}" title="Edit task">${task.name}</a>`;
			},

			projectStalled(project) {
				if (project.status == "active") {
					if (!project.current_tasks.length) {
						return true;
					}

					return (
						project.current_tasks.filter(
							(t) => !t.assigned_to || t.assigned_to.inactive
						).length > 0
					);
				}

				return false;
			},

			currentTaskClick(e) {
				let taskLink = e.target.matches(".current-task");

				if (taskLink) {
					let taskId = e.target.getAttribute("data-id");
					this.editTask(taskId);
				}
			},

			// TODO
			editTask(task_id) {
				this.$xhrRequest
					.send("get", `/api/task/${task_id}`)
					.then((task) => {
						this.$modalService
							.create("taskAddEdit", {
								taskId: task.id,
							})
							.on("save", (e, task) => {
								this.$db.addModels("task", task);
							});
					});
			},
		},
		watch: {

			categoryCounts() {
				this.$refs.tabs && this.$refs.tabs.onResize();
			},

			projectDisplay(to) {
				this.setUserSetting("projectDisplay", to);
			},

			aes(to) {
				let map = {};
				for (let i = 0; i < to.nodes.length; i++) {
					let k = to.nodes[i].node.account_executive_user
						? to.nodes[i].node.account_executive_user.id
						: 0;

					map[k] = true;
				}

				let toRemove = [];
				for (let i = 0; i < this.ae.length; i++) {
					if (!map[this.ae[i] || 0]) {
						toRemove.push(this.ae[i]);
					}
				}

				for (let i = 0; i < toRemove.length; i++) {
					let ndx = this.ae.indexOf(toRemove[i]);
					this.ae.splice(ndx, 1);
				}
			}
		},

		beforeRouteEnter(to, from, next) {
			let params = parseParams(to.params);

			if (!params.options || !params.options.category) {
				let lastCat = userData.getSetting('clientsAndProjects.lastCat');
				if (typeof lastCat == 'string' && lastCat) {
					return next({
						name: 'clients-and-projects',
						params: {
							options: 'category=' + lastCat
						}
					});
				}
			}
			
			return next();
		}
	};
</script>

<style lang="scss" scoped>
	.v-tabs-items {
		background-color: transparent !important;
	}

	.project-closed {
		opacity: 0.5;
	}

	.project-hidden {
		opacity: 0.75;
	}

	.project-list {
		padding: 0;
		font-size: 14px;
		.odd {
			background-color: var(--v-odd-base);
		}
		@media (min-width: 768px) {
			font-size: 12px;
		}
		@media (min-width: 1200px) {
			font-size: 13px;
		}

		li {
			> div {
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-bottom: 8px;
				padding: 0 8px;
				@media (min-width: 768px) {
					padding: 0;
				}
			}
		}

		.project-meta {
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.project-title {
			font-weight: 700;
			width: 100%;
			// max-width: calc(100% - 15px);
			@media (max-width: 768px - 1) {
				max-width: 100%;
				padding-right: 45px;
			}
		}

		.project-info {
			max-width: 15px;
			@media (max-width: 768px - 1) {
				position: absolute;
				right: 15px;
				top: 5px;
			}
		}

		.project-status {
			max-width: 20px;
			@media (max-width: 768px - 1) {
				position: absolute;
				right: 30px;
				top: 5px;
			}
		}

		.project-cat {
			max-width: 33.333%;
			@media (min-width: 768px) {
				max-width: 80px;
			}
		}

		.project-ae {
			max-width: 33.333%;
			@media (min-width: 768px) {
				max-width: 150px;
			}
		}

		.project-unassigned-tasks {
			max-width: 33.3333%;
			text-align: right;
			@media (min-width: 768px) {
				max-width: 75px;
			}
		}

		.project-due {
			max-width: 25%;
			text-align: right;
			@media (min-width: 768px) {
				max-width: 100px;
			}
		}

		.project-hours {
			max-width: 25%;
			@media (min-width: 768px) {
				max-width: 110px;
			}
		}

		.project-last-comm {
			width: 14em;
			max-width: 20%;
			text-align: center;
			margin-right: 1em;
		}

		.project-actions {
			// @media (max-width: 768px - 1) {
			max-width: 150px;
			// }
			// @media (min-width: 768px) {
			// flex: 0 0 66px;
			// }
		}
	}

	// .project-current-tasks >>> .badge-username {
	// 	width: 30px;
	// }

	// .project-current-tasks >>> .badge-days {
	// 	background-color: darken(gray, 30);
	// 	color: lightgray;
	// 	min-width: 30px;
	// }
</style>
