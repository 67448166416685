import { GraphicShirtTypes as OriginalGraphicShirtTypes } from 'vuejs-avataaars/src/assetsTypes/graphic-shirt'
import { standardReplacements, customReplacements } from './utils'

delete OriginalGraphicShirtTypes['Cumbia'];

customReplacements(OriginalGraphicShirtTypes, /fill=(['"])#ffffff\1/gi, 'fill=$1var(--avataaar-graphic-color)$1');

OriginalGraphicShirtTypes['Digital Attic'] = `
<g id="Clothing/Graphic-Shirt" transform="translate(0.000000, 170.000000)">
	<defs>
		<path d="M165.624032,29.2681342 C202.760022,32.1373245 232,63.1798426 232,101.051724 L232,110 L32,110 L32,101.051724 C32,62.8348009 61.7752018,31.5722494 99.3929298,29.1967444 C99.1342224,30.2735458 99,31.3767131 99,32.5 C99,44.3741221 113.998461,54 132.5,54 C151.001539,54 166,44.3741221 166,32.5 C166,31.4015235 165.871641,30.3222877 165.624025,29.2681336 Z" id="react-path-1153">
		</path>
	</defs>
	<mask id="react-mask-1154" fill="white">
		<use xlink:href="#react-path-1153"></use>
	</mask>
	<use id="Clothes" fill="#E6E6E6" fill-rule="evenodd" xlink:href="#react-path-1153"></use>
	<g id="Color/Palette/" mask="url(#react-mask-1154)" fill-rule="evenodd" fill='var(--avataaar-shirt-color)'>
		<rect id="🖍Color" x="0" y="0" width="264" height="110"></rect>
	</g>
	<g id="Clothing/Graphic/DigitalAttic" mask="url(#react-mask-1154)" fill-rule="evenodd" fill="#fff">
		<g transform="translate(77.000000, 58.000000)" id="Fill-49">
			<path class="st1" d="M94.8,15c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5
			C94.1,13.5,94.8,14.2,94.8,15z" fill="#c4262e"/>
			<path class="st2" d="M17.6,17.4v11.9H15V17.4H17.6z" />
			<path class="st2" d="M36,17.4v11.9h-2.6V17.4H36z" />
			<path class="st2" d="M38.9,17.4v-4.6h2.5v4.5h3.3v2.1h-3.2v6c0,0.5,0.1,0.9,0.4,1.1c0.3,0.2,0.6,0.4,1,0.4h1.6v2.4h-1.8
			c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.2-0.8c-0.3-0.4-0.6-0.8-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.6v-5.9h-1.3l0.4-2.1
			L38.9,17.4L38.9,17.4z"/>
			<path class="st2" d="M61.6,11.6v17.7H59V11.6H61.6z" />
			<path class="st2" d="M9.8,24.9c-0.3,0.9-0.8,1.6-1.4,1.9c-0.6,0.4-1.4,0.5-2.2,0.5c-0.5,0-1-0.1-1.4-0.3
			c-0.4-0.2-0.8-0.5-1.1-0.8S3.2,25.5,3,25c-0.2-0.5-0.3-1-0.3-1.5c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.3
			c0.3-0.4,0.7-0.7,1.1-0.8c0.4-0.2,0.9-0.3,1.5-0.3c0.1,0,0.2,0,0.4,0v-2.3c-0.3,0-0.6-0.1-0.9-0.1c-0.8,0-1.6,0.2-2.3,0.5
			s-1.3,0.7-1.8,1.3c-0.5,0.5-0.9,1.2-1.2,1.9C0.1,21.6,0,22.3,0,23.2c0,0.8,0.1,1.6,0.3,2.4s0.6,1.5,1,2
			c0.5,0.6,1.1,1.1,1.8,1.4s1.5,0.5,2.5,0.5c1.1,0,2-0.2,2.6-0.5c0.6-0.3,1.1-0.7,1.4-1.1l0.9,1.3h1.9V11.6H9.9L9.8,24.9z"/>
			<path class="st2" d="M28.5,27.3c0,3.3-1.1,4.9-3.7,4.9c-0.4,0-0.8,0-1.2-0.1c-0.4-0.1-0.8-0.2-1.1-0.3
			c-0.3-0.1-0.7-0.2-0.9-0.4c-0.3-0.1-0.5-0.3-0.6-0.4l-1.2,2c0.4,0.4,1,0.8,1.8,1.1s2,0.5,3.4,0.5c0.9,0,1.6-0.1,2.4-0.4
			c0.7-0.3,1.4-0.7,1.9-1.3c0.6-0.6,1-1.3,1.3-2.2c0.3-0.9,0.5-1.9,0.5-3V17.4h-1.8l-0.7,1.1c-0.2-0.3-0.7-0.6-1.3-0.9
			c-0.6-0.3-1.4-0.4-2.4-0.4c-0.9,0-1.7,0.2-2.4,0.5s-1.3,0.8-1.7,1.3c-0.5,0.6-0.8,1.2-1,1.9c-0.2,0.7-0.4,1.5-0.4,2.2
			c0,0.8,0.1,1.5,0.3,2.3c0.2,0.7,0.6,1.3,1,1.9c0.4,0.5,1,1,1.7,1.3s1.4,0.5,2.3,0.5c0.3,0,0.6,0,1-0.1v-2.2
			c-0.1,0-0.3,0-0.4,0c-1.2,0-2-0.3-2.6-1c-0.6-0.7-0.8-1.6-0.8-2.7c0-1.1,0.3-1.9,0.8-2.6c0.6-0.7,1.4-1,2.5-1
			c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1,0.8,1.3,1.4c0,0,0.1,2.4,0.1,3.2C28.5,25.3,28.5,27.3,28.5,27.3z"/>
			<path class="st2" d="M53.9,24.7l0,1.9c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.8,0.2c-0.3,0.1-0.6,0.1-0.9,0.2
			c-0.3,0-0.6,0.1-0.9,0.1c-0.8,0-1.4-0.1-1.8-0.4c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.6,0.2-1.1,0.6-1.4c0.4-0.3,1-0.5,1.8-0.5V22
			c-0.1,0-0.3,0-0.4,0c-1.3,0-2.4,0.3-3.2,1c-0.8,0.7-1.2,1.7-1.2,3c0,0.6,0.1,1.2,0.3,1.6c0.2,0.5,0.5,0.8,0.9,1.1
			c0.4,0.3,0.8,0.5,1.2,0.7c0.5,0.1,0.9,0.2,1.4,0.2c1,0,1.9-0.1,2.5-0.4c0.7-0.3,1.1-0.6,1.4-0.9l0.8,1.1h1.9v-7.3
			c0-0.9-0.2-1.7-0.6-2.3c-0.4-0.6-0.8-1.1-1.4-1.5c-0.5-0.4-1.1-0.7-1.7-0.8c-0.6-0.1-1.2-0.2-1.7-0.2c-0.3,0-0.6,0-0.9,0
			c-0.3,0-0.7,0.1-1.1,0.1c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.8,0.3-1.2,0.5l1,2.1c0.1-0.1,0.3-0.2,0.5-0.3
			c0.2-0.1,0.5-0.1,0.7-0.2c0.3-0.1,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.6,0c0.7,0,1.3,0.1,1.8,0.2c0.4,0.1,0.8,0.3,1,0.5
			c0.4,0.3,0.6,0.6,0.7,1c0.1,0.4,0.1,0.9,0.1,1.5L53.9,24.7z"/>
			<path fill="#AEA299" class="st1" d="M71.5,24.7l0,1.9c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.8,0.2s-0.6,0.1-0.9,0.2
			c-0.3,0-0.6,0.1-0.9,0.1c-0.8,0-1.4-0.1-1.8-0.4c-0.4-0.3-0.6-0.7-0.6-1.2c0-0.6,0.2-1.1,0.6-1.4c0.4-0.3,1-0.5,1.8-0.5V22
			c-0.1,0-0.3,0-0.4,0c-1.4,0-2.4,0.3-3.2,1c-0.8,0.7-1.2,1.7-1.2,3c0,0.6,0.1,1.2,0.3,1.6c0.2,0.5,0.5,0.8,0.9,1.1
			c0.4,0.3,0.8,0.5,1.2,0.7c0.5,0.1,0.9,0.2,1.4,0.2c1,0,1.9-0.1,2.5-0.4c0.7-0.3,1.1-0.6,1.4-0.9l0.8,1.1H74v-7.3
			c0-0.9-0.2-1.7-0.6-2.3c-0.4-0.6-0.8-1.1-1.4-1.5c-0.5-0.4-1.1-0.7-1.7-0.8c-0.6-0.1-1.2-0.2-1.7-0.2c-0.3,0-0.6,0-0.9,0
			c-0.3,0-0.7,0.1-1.1,0.1s-0.8,0.2-1.2,0.3c-0.4,0.1-0.8,0.3-1.2,0.5l1,2.1c0.1-0.1,0.3-0.2,0.5-0.3s0.5-0.1,0.7-0.2
			c0.3-0.1,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.6,0c0.7,0,1.3,0.1,1.8,0.2c0.4,0.1,0.8,0.3,1,0.5c0.4,0.3,0.6,0.6,0.7,1
			c0.1,0.4,0.1,0.9,0.1,1.5L71.5,24.7z"/>
			<g fill="#AEA299">
				<g>
					<path class="st1" d="M76.4,17.4v-4.6h2.5v4.5h3.3v2.1H79v6c0,0.5,0.1,0.9,0.4,1.1c0.3,0.2,0.6,0.4,1,0.4H82v2.4h-1.8
					c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.2-0.8c-0.3-0.4-0.6-0.8-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.6v-5.9h-1.3l0.4-2.1
					L76.4,17.4L76.4,17.4z"/>
					<path class="st1" d="M84.6,17.4v-4.6h2.5v4.5h3.3v2.1h-3.2v6c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1,0.4h1.6v2.4h-1.8
					c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.2-0.8c-0.3-0.4-0.6-0.8-0.8-1.2c-0.2-0.5-0.3-1-0.3-1.6v-5.9h-1.3l0.4-2.1
					L84.6,17.4L84.6,17.4z"/>
					<path class="st1" d="M94.6,17.4v11.9H92V17.4H94.6z" />
					<path class="st1" d="M102.5,17.2c0.6,0,1.2,0.1,1.6,0.2c0.5,0.1,0.9,0.2,1.3,0.4c0.4,0.2,0.7,0.3,0.9,0.5
					c0.2,0.2,0.4,0.3,0.5,0.4l-1.2,2.2c-0.3-0.4-0.7-0.7-1.1-1c-0.5-0.3-1.1-0.4-1.9-0.4c-0.6,0-1.1,0.1-1.6,0.3
					c-0.5,0.2-0.9,0.5-1.2,0.8c-0.3,0.4-0.6,0.8-0.8,1.2s-0.3,1-0.3,1.6c0,0.6,0.1,1.1,0.3,1.6c0.2,0.5,0.5,0.9,0.8,1.2
					c0.3,0.4,0.7,0.6,1.2,0.8c0.4,0.2,0.9,0.3,1.4,0.3c0.8,0,1.5-0.1,2-0.4c0.6-0.3,1-0.6,1.2-0.9l1.5,1.8
					c-0.5,0.6-1.1,1-1.9,1.3c-0.8,0.3-1.8,0.5-2.9,0.5c-0.9,0-1.7-0.2-2.5-0.5c-0.8-0.3-1.4-0.8-2-1.3c-0.5-0.6-1-1.2-1.3-2
					c-0.3-0.8-0.5-1.6-0.5-2.4c0-0.8,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.4,1.3-2c0.5-0.6,1.2-1,2-1.4
					C100.6,17.3,101.5,17.2,102.5,17.2z"/>
				</g>
			</g>
			<path class="st3" d="M36.1,15c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5c0-0.8,0.6-1.5,1.5-1.5C35.5,13.5,36.1,14.2,36.1,15z"
			/>
			<path class="st2" d="M17.7,15c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.4-0.7-1.4-1.5c0-0.8,0.6-1.4,1.5-1.4
			C17.1,13.5,17.7,14.2,17.7,15z"/>
		</g>
		<g>
			<g>
				<path class="st1" d="M108.1,14.6c0.1,0,0.3,0,0.4,0c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.4v0
				c0.1,0,0.1,0.1,0.2,0.3c0,0.2,0.1,0.4,0.1,0.4h-0.3c0,0-0.1-0.2-0.1-0.3c0-0.2-0.1-0.2-0.2-0.2h-0.1V16h-0.3L108.1,14.6
				L108.1,14.6z M108.4,15.2h0.1c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0-0.1,0V15.2z"/>
			</g>
			<g>
				<path class="st1" d="M108.5,16.7c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5
				C110,16.1,109.3,16.7,108.5,16.7z M108.5,14.2c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1
				C109.6,14.7,109.1,14.2,108.5,14.2z"/>
			</g>
		</g>
	</g>
</g>
`;

OriginalGraphicShirtTypes['Jollibee'] = `
<g id="Clothing/Graphic-Shirt" transform="translate(0.000000, 170.000000)">
	<defs>
		<path d="M165.624032,29.2681342 C202.760022,32.1373245 232,63.1798426 232,101.051724 L232,110 L32,110 L32,101.051724 C32,62.8348009 61.7752018,31.5722494 99.3929298,29.1967444 C99.1342224,30.2735458 99,31.3767131 99,32.5 C99,44.3741221 113.998461,54 132.5,54 C151.001539,54 166,44.3741221 166,32.5 C166,31.4015235 165.871641,30.3222877 165.624025,29.2681336 Z" id="react-path-1153">
		</path>
	</defs>
	<mask id="react-mask-1154" fill="white">
		<use xlink:href="#react-path-1153"></use>
	</mask>
	<use id="Clothes" fill="#E6E6E6" fill-rule="evenodd" xlink:href="#react-path-1153"></use>
	<g id="Color/Palette/" mask="url(#react-mask-1154)" fill-rule="evenodd" fill='var(--avataaar-shirt-color)'>
		<rect id="🖍Color" x="0" y="0" width="264" height="110"></rect>
	</g>
	<g id="Clothing/Graphic/Jollibee" mask="url(#react-mask-1154)" fill-rule="evenodd">
		<g transform="translate(77.000000, 65.000000)" id="Fill-49">
			<g>
			<path fill="#231F20;" d="M47.9,2.2c1.4-0.8,3-1.4,4.7-1.3C53.8,1,55,1.6,55.8,2.6c0.9,1.1,1.3,2.6,1,4c-0.4,1.8-1.7,3.1-3.2,4
				c0.2,0.8,0.5,1.7,0.7,2.5c0.3,0,0.7,0,1,0c0-1.6,0.5-3.1,1.6-4.3C57.2,8.5,57.6,8.2,58,8c0.4-0.2,1,0,1.2,0.5
				c0.2,0.7-0.2,1.3-0.5,1.8C58,11.2,57.1,12,57,13.2c2.8,0.3,5.5,1.6,7.4,3.6c2,2,3.3,4.8,3.4,7.7c1,0.1,2.1,0.5,2.6,1.5
				c0.7,1.2,0.9,2.6,0.7,3.9c-0.2,1.7-1,3.3-2,4.7c-1.2,1.7-2.7,3.1-4.2,4.4c-0.3,0.2-0.6,0.4-0.8,0.8c-0.5,0.9-1.2,1.6-2.1,2.1
				c-1.5,0.8-3.2,1.1-4.8,0.8c-0.4-0.1-0.7-0.2-1.1-0.3c-2.6-0.2-5.2-0.4-7.8-0.9c-1.9-0.3-3.8-0.9-5.5-1.9c-1.4-0.8-2.6-2-3.2-3.6
				c-0.4-1.1-0.5-2.3-0.1-3.3c0.3-0.9,0.9-1.5,1.6-2.1c-0.6-1.5-0.9-3.1-0.9-4.7c0.1-2.5,0.9-5,2.3-7.1c-0.3-0.3-0.5-0.5-0.7-0.8
				c-1.1,0.6-2.3,0.8-3.5,0.5c-1.3-0.2-2.6-1-3.2-2.1c-0.6-1.2-0.7-2.7-0.1-3.9c0.5-1.1,1.5-1.9,2.5-2.5c1.7-1,3.5-1.5,5.4-1.8
				C43.8,5.7,45.6,3.6,47.9,2.2 M48.1,2.8c-2.6,1.6-4.3,4.2-5.2,7c-0.1,0.3-0.2,0.7-0.5,0.7c0-0.5,0.2-1.1,0.3-1.6
				c-1.7,0.4-3.4,0.8-4.9,1.7c-0.9,0.5-1.8,1.2-2.2,2.2c-0.6,1.3-0.4,2.9,0.5,3.9c0.6,0.7,1.6,1.1,2.5,1.3c0.9,0.1,2,0.1,2.8-0.4
				c-0.8-0.7-1.6-1.4-2.4-2c-0.1-0.1-0.2-0.2-0.2-0.3c1.6,0.5,2.8,1.7,4,2.9c0.1,0.1,0.2,0.3,0.3,0.1c1.3-1.6,3-2.8,4.8-3.6
				c-0.5-1-0.9-2.1-0.9-3.3c0-0.7,0.1-1.5,0.5-2.1c0.3-0.4,0.8-0.7,1.4-0.5c0.3,0.1,0.5,0.4,0.6,0.7c0.4,1.5-0.3,3,0.2,4.4
				c1.3-0.4,2.6-0.8,4-0.8c-0.4-1.5-0.8-3-1-4.5c0-0.5-0.1-0.9,0-1.4c0,0,0.1-0.1,0.2-0.1C53,8.1,53.2,9,53.4,10
				c1.4-0.8,2.6-2.1,2.9-3.7c0.4-2-1.1-4.3-3.2-4.6C51.3,1.3,49.6,1.9,48.1,2.8 M58,8.5c-0.5,0.2-0.8,0.5-1.1,0.9
				c-0.5,0.8-1,1.7-1.1,2.6c-0.2,0.9-0.1,1.9-0.1,2.8c-0.4-0.3-0.2-0.8-0.3-1.2c-1.9-0.1-3.7,0.3-5.5,0.9c0.1,0.5,0.6,0.9,0.6,1.4
				c-0.1-0.1-0.3-0.1-0.3-0.3c-0.6-0.6-1-1.4-1.1-2.2c-0.1-0.8,0-1.6,0-2.4c0-0.5,0.1-1.1-0.3-1.6c-0.3-0.2-0.7-0.1-0.8,0.2
				c-0.3,0.5-0.4,1.1-0.4,1.7c0,1.5,0.5,2.9,1.3,4.1c0.2,0.3,0.5,0.6,0.6,1c-0.6-0.2-0.8-0.9-1.3-1.3c-0.7,0.3-1.4,0.8-2.1,1.2
				c-2.2,1.6-3.9,3.9-4.7,6.5c-0.8,2.4-0.6,5.1,0.3,7.5c1.4-0.7,3.2-0.7,4.7,0c0,0.1,0.1,0.1,0.1,0.2c-0.8-0.1-1.6-0.3-2.4-0.2
				c-1.1,0-2.1,0.3-2.9,1c-0.6,0.6-1.1,1.4-1.2,2.2c-0.1,1.6,0.5,3.2,1.5,4.3c1.4,1.4,3.2,2.2,5.1,2.7c2.7,0.7,5.4,0.9,8.1,1.2
				c-0.3-0.2-0.5-0.4-0.7-0.7l0-0.1c0.5,0.1,1,0.4,1.5,0.6c1.6,0.7,3.6,0.9,5.3,0.3c1.5-0.5,2.7-1.8,3.3-3.3c0.1-0.2,0.1-0.5,0.4-0.6
				c0,0.2,0,0.5,0,0.7c1.4-1.1,2.8-2.4,3.9-3.8c1-1.3,1.8-2.8,2.1-4.4c0.3-1.2,0.2-2.5-0.3-3.7c-0.3-0.7-0.9-1.3-1.6-1.6
				c-0.9-0.3-1.9-0.2-2.8,0.2v-0.2c0.4-0.3,0.9-0.5,1.4-0.6c-0.1-3.3-1.8-6.6-4.5-8.5c-1.7-1.3-3.8-2.1-5.9-2.3c0,0.4,0,0.8-0.1,1.2
				c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.7,0-1.4,0.3-2.1c0.4-1,1.2-1.8,1.7-2.8c0.1-0.3,0.3-0.6,0.1-0.9C58.5,8.4,58.2,8.5,58,8.5L58,8.5z"
				/>
			<path class="st0" fill="#231F20;" d="M59.7,19.4c0.9-0.3,1.8,0,2.5,0.5c0.8,0.6,1.4,1.4,1.8,2.3c0.5,1,0.7,2.1,0.7,3.3c0.2,0,0.4,0,0.3,0.3
				c-1.3,0.7-2.6,1.3-3.9,2c-0.5,0.2-0.9,0.5-1.3,0.7c-0.8-1-1.3-2.2-1.7-3.4c-0.3-1.1-0.5-2.4-0.2-3.5C58.1,20.5,58.7,19.7,59.7,19.4
				M58.5,21c-0.4,1-0.4,2.2-0.1,3.2c0.1-0.6,0.4-1.3,1-1.7c0.8-0.5,1.8-0.4,2.5,0.2c-0.2,0.6-0.5,1.1-0.7,1.7
				c0.5-0.3,0.9-0.6,1.4-0.9c0.5,0.8,0.8,1.7,0.8,2.7c0.3-0.1,0.7-0.3,1-0.5c-0.1-1.7-0.6-3.5-1.8-4.8c-0.6-0.6-1.4-1.1-2.3-1.1
				C59.5,19.8,58.8,20.3,58.5,21L58.5,21z M50.2,21.4c1.3-0.1,2.4,0.7,3.2,1.7c1,1.3,1.3,2.9,1.4,4.5c0,0.7,0,1.4-0.1,2.2
				c-0.8,0-1.6,0-2.4,0c-1.5,0.1-3,0.3-4.5,0.5c0-0.1-0.1-0.2-0.1-0.3c0.1-0.1,0.3-0.1,0.4-0.2c-0.7-1.3-1.2-2.8-1.2-4.3
				c0-1.1,0.4-2.3,1.2-3.1C48.6,21.9,49.4,21.4,50.2,21.4 M49.6,22.1c-0.8,0.2-1.5,0.9-1.8,1.6c-0.5,1-0.6,2.1-0.3,3.2
				c0.1-1,0.8-2,1.8-2.3c0.7-0.2,1.6,0.1,2.2,0.5c-0.3,0.6-0.6,1.3-0.8,2c0.5-0.3,1-0.7,1.6-1c0.6,1,0.7,2.2,0.5,3.3c0.5,0,1,0,1.6,0
				c0.1-1.4,0-2.9-0.5-4.3c-0.4-1.2-1.1-2.2-2.2-2.8C51,21.9,50.3,21.9,49.6,22.1L49.6,22.1z M65.2,29.5c0.3,0,0.5-0.3,0.9-0.2
				c-0.4,0.5-1.1,0.6-1.4,1.1c-1,1.3-1.8,2.8-2.4,4.3c-0.4,0.9-1,1.9-1.9,2.4c-1,0.4-2.2,0.4-3.1-0.1c-0.9-0.4-1.7-1-2.6-1.4
				c-1.2-0.5-2.3-1-3.6-1.4c-0.3-0.1-0.6-0.2-0.9-0.1c-0.5,0.2-1.1,0.2-1.7,0.1c0,0,0.1-0.1,0.1-0.1c0.7-0.1,1.4-0.3,1.9-0.9
				c0.2-0.1,0.3-0.4,0.5-0.4c0,0.3-0.1,0.5-0.2,0.8c0.7,0.3,1.5,0.4,2.3,0.5c2.2,0.3,4.4,0.3,6.5-0.4c1.6-0.5,3.2-1.4,4.2-2.7
				c0.2-0.3,0.5-0.6,0.6-1c-0.3-0.1-0.7-0.2-0.8-0.5C64.1,29.6,64.7,29.7,65.2,29.5 M59.3,35.2c0.1,0.2,0.3,0.4,0.4,0.6
				c-1.1-0.8-2.6-0.6-3.7,0.1c0.9,0.7,2,1.1,3.1,1.1c1.1-0.1,2-0.9,2.4-1.9c-0.4-0.1-0.7-0.3-1.1-0.3C60,34.8,59.6,34.9,59.3,35.2
				L59.3,35.2z"/>
			<path class="st0" fill="#231F20;" d="M59.5,30c0.3,0.2,0.5,0.5,0.5,0.8c0,0.4-0.3,0.8-0.7,1c-0.6,0.3-1.2,0.3-1.8,0.1c-0.4-0.1-1-0.4-1.1-0.9
				c0.6,0.4,1.4,0.6,2.2,0.4c0.4-0.1,0.8-0.3,1-0.7C59.7,30.5,59.6,30.2,59.5,30L59.5,30z"/>
		</g>
		<g>
			<path class="st1" fill="#fff" d="M48.1,2.8c1.5-0.9,3.2-1.5,5-1.2c2.1,0.4,3.5,2.6,3.2,4.6c-0.3,1.6-1.5,3-2.9,3.7C53.2,9,53,8,52.8,7.1
				c0,0-0.1,0.1-0.2,0.1c-0.1,0.5-0.1,0.9,0,1.4c0.2,1.6,0.6,3,1,4.5c-1.4,0.1-2.7,0.4-4,0.8c-0.5-1.4,0.2-3-0.2-4.4
				c-0.1-0.3-0.3-0.6-0.6-0.7c-0.5-0.2-1.1,0.1-1.4,0.5c-0.4,0.6-0.5,1.4-0.5,2.1c0,1.1,0.3,2.3,0.9,3.3c-1.8,0.9-3.5,2.1-4.8,3.6
				c-0.1,0.1-0.2-0.1-0.3-0.1c-1.1-1.2-2.4-2.4-4-2.9c0,0.1,0.1,0.2,0.2,0.3c0.8,0.6,1.6,1.3,2.4,2c-0.8,0.5-1.9,0.5-2.8,0.4
				c-0.9-0.2-1.8-0.6-2.5-1.3c-0.9-1.1-1.1-2.7-0.5-3.9c0.5-1,1.3-1.7,2.2-2.2c1.5-0.9,3.2-1.3,4.9-1.7c-0.1,0.5-0.4,1-0.3,1.6
				c0.4,0,0.4-0.4,0.5-0.7C43.8,7,45.5,4.4,48.1,2.8L48.1,2.8z M58.1,17.8c0.9-0.4,1.9-0.5,2.9-0.2c1.1,0.3,2,1.1,2.7,1.9
				c1.4,1.6,2.1,3.7,2.1,5.8v0.2c0.8-0.4,1.9-0.6,2.8-0.2c0.7,0.3,1.3,0.8,1.6,1.6c0.5,1.2,0.6,2.5,0.3,3.7c-0.3,1.6-1.1,3.1-2.1,4.4
				c-1.1,1.5-2.4,2.7-3.9,3.8c0-0.2,0-0.5,0-0.7c-0.3,0.1-0.2,0.4-0.4,0.6c-0.5,1.5-1.8,2.7-3.3,3.3c-1.7,0.7-3.6,0.4-5.3-0.3
				c-0.5-0.2-1-0.5-1.5-0.6l0,0.1c0.2,0.3,0.5,0.5,0.7,0.7c-2.7-0.2-5.5-0.5-8.1-1.2c-1.9-0.5-3.8-1.3-5.1-2.7
				c-1.1-1.1-1.7-2.7-1.5-4.3c0.1-0.8,0.5-1.6,1.2-2.2c0.8-0.7,1.9-1.1,2.9-1c0.8,0,1.6,0.1,2.4,0.2c0-0.1-0.1-0.1-0.1-0.2
				c-1.1-2.1-1.7-4.6-1.1-6.9c0.3-1.4,1.2-2.7,2.4-3.4c1.3-0.7,2.9-0.9,4.3-0.4c1.6,0.6,3,1.9,3.8,3.5c0-0.8-0.1-1.7,0.2-2.5
				C56.3,19.5,57,18.3,58.1,17.8 M59.7,19.4c-0.9,0.3-1.6,1.1-1.8,2c-0.3,1.2-0.1,2.4,0.2,3.5c0.3,1.2,0.9,2.4,1.7,3.4
				c0.5-0.2,0.9-0.5,1.3-0.7c1.3-0.7,2.6-1.3,3.9-2c0.1-0.2-0.1-0.3-0.3-0.3c0-1.1-0.3-2.2-0.7-3.3c-0.4-0.9-1-1.8-1.8-2.3
				C61.4,19.4,60.5,19.1,59.7,19.4 M50.2,21.4c-0.8,0-1.5,0.4-2.1,0.9c-0.8,0.8-1.2,2-1.2,3.1c0,1.5,0.5,3,1.2,4.3
				c-0.1,0.1-0.3,0.1-0.4,0.2c0,0.1,0.1,0.2,0.1,0.3c1.5-0.2,3-0.4,4.5-0.5c0.8,0,1.6,0,2.4,0c0.1-0.7,0.1-1.4,0.1-2.2
				c-0.1-1.6-0.4-3.2-1.4-4.5C52.6,22.2,51.4,21.4,50.2,21.4 M65.2,29.5c-0.5,0.2-1.1,0.1-1.7,0.1c0.1,0.3,0.5,0.5,0.8,0.5
				c-0.1,0.4-0.4,0.7-0.6,1c-1.1,1.3-2.6,2.2-4.2,2.7c-2.1,0.7-4.4,0.7-6.5,0.4c-0.8-0.1-1.6-0.2-2.3-0.5c0.1-0.2,0.3-0.5,0.2-0.8
				c-0.3,0-0.3,0.3-0.5,0.4c-0.5,0.5-1.2,0.8-1.9,0.9c0,0-0.1,0.1-0.1,0.1c0.6,0.1,1.1,0.1,1.7-0.1c0.3-0.1,0.6,0,0.9,0.1
				c1.2,0.4,2.4,0.8,3.6,1.4c0.9,0.4,1.7,1,2.6,1.4c1,0.4,2.1,0.5,3.1,0.1c1-0.5,1.5-1.5,1.9-2.4c0.6-1.5,1.4-3,2.4-4.3
				c0.3-0.5,1.1-0.6,1.4-1.1C65.8,29.3,65.5,29.5,65.2,29.5 M59.5,30c0.1,0.2,0.2,0.5,0.1,0.7c-0.2,0.4-0.6,0.6-1,0.7
				c-0.7,0.2-1.6,0-2.2-0.4c0.1,0.5,0.6,0.8,1.1,0.9c0.6,0.2,1.3,0.1,1.8-0.1c0.4-0.2,0.7-0.6,0.7-1C60,30.5,59.8,30.2,59.5,30
				M59.5,38.5c-1,0.2-2.1-0.1-3-0.5c0.7,0.9,2.1,1.2,3.2,1c1-0.2,1.9-0.9,2.2-1.9C61.3,37.7,60.5,38.3,59.5,38.5L59.5,38.5z"/>
			<path class="st1" fill="#fff" d="M58.5,21c0.3-0.7,1-1.2,1.8-1.2c0.9-0.1,1.7,0.4,2.3,1.1c1.2,1.3,1.7,3.1,1.8,4.8c-0.3,0.2-0.7,0.3-1,0.5
				c0-0.9-0.3-1.9-0.8-2.7c-0.5,0.3-0.9,0.6-1.4,0.9c0.2-0.6,0.5-1.1,0.7-1.7c-0.7-0.6-1.7-0.7-2.5-0.2c-0.6,0.3-0.9,1-1,1.7
				C58.1,23.2,58.1,22,58.5,21L58.5,21z M49.6,22.1c0.7-0.2,1.4-0.1,2,0.2c1.1,0.6,1.8,1.7,2.2,2.8c0.4,1.4,0.6,2.8,0.5,4.3
				c-0.5,0-1,0-1.6,0c0.2-1.1,0.1-2.3-0.5-3.3c-0.5,0.3-1.1,0.6-1.6,1c0.2-0.7,0.5-1.3,0.8-2c-0.6-0.5-1.4-0.7-2.2-0.5
				c-1,0.3-1.7,1.3-1.8,2.3c-0.3-1-0.2-2.2,0.3-3.2C48.1,22.9,48.8,22.3,49.6,22.1L49.6,22.1z"/>
		</g>
		<g>
			<path class="st2" fill="#E5173F" d="M58,8.5c0.2,0,0.5-0.1,0.6,0.1c0.2,0.3,0.1,0.6-0.1,0.9c-0.5,1-1.3,1.8-1.7,2.8c-0.3,0.7-0.3,1.4-0.3,2.1
				c0,0.2,0,0.5,0.2,0.6c0-0.4,0-0.8,0.1-1.2c2.1,0.2,4.2,1.1,5.9,2.3c2.7,2,4.4,5.2,4.5,8.5c-0.5,0.1-1,0.3-1.4,0.6
				c0-2.1-0.7-4.2-2.1-5.8c-0.7-0.8-1.6-1.6-2.7-1.9c-0.9-0.3-2-0.2-2.9,0.2c-1.1,0.5-1.8,1.7-2.1,2.9c-0.2,0.8-0.2,1.7-0.2,2.5
				c-0.8-1.5-2.2-2.8-3.8-3.5c-1.4-0.6-3-0.4-4.3,0.4c-1.3,0.7-2.1,2-2.4,3.4c-0.6,2.3,0,4.8,1.1,6.9c-1.5-0.7-3.2-0.7-4.7,0
				c-0.9-2.4-1.1-5.1-0.3-7.5c0.8-2.6,2.4-5,4.7-6.5c0.7-0.5,1.4-0.9,2.1-1.2c0.4,0.4,0.7,1,1.3,1.3c-0.1-0.4-0.4-0.7-0.6-1
				c-0.8-1.2-1.4-2.6-1.3-4.1c0-0.6,0.1-1.2,0.4-1.7c0.2-0.3,0.6-0.4,0.8-0.2c0.3,0.5,0.3,1.1,0.3,1.6c0,0.8-0.2,1.6,0,2.4
				c0.1,0.8,0.5,1.6,1.1,2.2c0.1,0.1,0.2,0.2,0.3,0.3c-0.1-0.5-0.5-0.9-0.6-1.4c1.8-0.6,3.6-0.9,5.5-0.9c0.1,0.4-0.1,1,0.3,1.2
				c0-0.9-0.1-1.9,0.1-2.8c0.1-1,0.6-1.8,1.1-2.6C57.2,9,57.5,8.7,58,8.5L58,8.5z M59.3,35.2c0.3-0.2,0.7-0.4,1.1-0.4
				c0.4,0,0.8,0.1,1.1,0.3c-0.4,1-1.3,1.8-2.4,1.9C58,37,56.9,36.6,56,35.9c1.1-0.7,2.6-0.9,3.7-0.1C59.6,35.6,59.4,35.4,59.3,35.2
				L59.3,35.2z"/>
		</g>
		<path id="_x23_221e1fff" fill="#221E1F" class="st3" d="M59.5,38.5c1-0.2,1.8-0.8,2.4-1.5c-0.3,1-1.2,1.7-2.2,1.9c-1.1,0.2-2.5,0-3.2-1
			C57.5,38.3,58.5,38.7,59.5,38.5L59.5,38.5z"/>
		</g>
	</g>
</g>
`;

OriginalGraphicShirtTypes['Giants'] = `
<g id="Clothing/Graphic-Shirt" transform="translate(0.000000, 170.000000)">
	<defs>
		<path d="M165.624032,29.2681342 C202.760022,32.1373245 232,63.1798426 232,101.051724 L232,110 L32,110 L32,101.051724 C32,62.8348009 61.7752018,31.5722494 99.3929298,29.1967444 C99.1342224,30.2735458 99,31.3767131 99,32.5 C99,44.3741221 113.998461,54 132.5,54 C151.001539,54 166,44.3741221 166,32.5 C166,31.4015235 165.871641,30.3222877 165.624025,29.2681336 Z" id="react-path-1153">
		</path>
	</defs>
	<mask id="react-mask-1154" fill="white">
		<use xlink:href="#react-path-1153"></use>
	</mask>
	<use id="Clothes" fill="#E6E6E6" fill-rule="evenodd" xlink:href="#react-path-1153"></use>
	<g id="Color/Palette/" mask="url(#react-mask-1154)" fill-rule="evenodd" fill='var(--avataaar-shirt-color)'>
		<rect id="🖍Color" x="0" y="0" width="264" height="110"></rect>
	</g>
	<g id="Clothing/Graphic/Giants" mask="url(#react-mask-1154)" fill-rule="evenodd">
		<g transform="translate(93.000000, 60.000000)" id="Fill-49">
		<path fill="#BC9B6A" d="M17.8,20.7c0,11.4,9.3,20.7,20.7,20.7c11.4,0,20.7-9.3,20.7-20.7C59.1,9.3,49.8,0,38.4,0
		C27,0,17.8,9.3,17.8,20.7 M19.1,20.7c0-10.7,8.7-19.4,19.4-19.4c10.7,0,19.4,8.7,19.4,19.4c0,10.7-8.7,19.4-19.4,19.4
		C27.8,40,19.1,31.3,19.1,20.7z"/>
	<path id="XMLID_444_" fill="#FFFFFF" d="M38.4,40.7c11.1,0,20-9,20-20c0-11.1-9-20-20-20c-11.1,0-20,9-20,20
		C18.4,31.7,27.4,40.7,38.4,40.7"/>
	<path id="XMLID_443_" fill="#FFE1BB" d="M18.7,23.4c-0.1,0-0.1,0-0.2,0c0-0.2,0-0.4-0.1-0.6c0,0,0.1,0,0.2,0c0.2,0,0.3,0.2,0.3,0.3
		C19,23.2,19,23.4,18.7,23.4"/>
	<path id="XMLID_442_" fill="#FFE1BB" d="M58.4,20.7c0,11.1-9,20-20,20c-3.4,0-6.5-0.8-9.3-2.3c0,0,0,0,0-0.1c0-0.1,0-0.1-0.1-0.1
		c0,0-0.1,0-0.1,0.1c-0.9-0.5-1.7-1-2.5-1.6c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1-0.1-0.3-0.2-0.4-0.3
		c0,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0.1,0,0.1-0.1,0.1-0.1
		c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1-0.1-0.3-0.3-0.4-0.4c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0c-0.1-0.1-0.3-0.3-0.4-0.4c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0c-0.1-0.1-0.2-0.2-0.3-0.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		c-0.1,0-0.2,0.1-0.2,0.1c-0.1-0.1-0.2-0.2-0.3-0.3c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.1,0-0.2,0.1
		c-0.1-0.1-0.2-0.2-0.2-0.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0c-0.6-0.7-1.1-1.5-1.5-2.3c0.1,0,0.2-0.1,0.2-0.2
		c0-0.2,0-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3
		c0.1,0,0.3-0.1,0.3-0.3c0-0.2,0.1-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.3-0.2,0.3c-0.2,0-0.3,0.1-0.3,0.3
		c0,0.2,0.1,0.3,0.3,0.3c0.3,0,0.3,0,0.3-0.3c0-0.2,0.1-0.2,0.3-0.2c0,0,0.2,0.1,0.2,0.2c0,0.1,0,0.2-0.2,0.2c-0.3,0-0.3,0-0.3,0.3
		c0,0.1,0.1,0.3,0.3,0.3c0.3,0,0.3,0,0.3,0.3c0,0.1,0,0.3,0.3,0.3c0.2,0,0.3,0,0.3,0.3c0,0.1,0,0.3,0.3,0.3c0.3,0,0.3,0,0.3,0.3
		c0,0.1,0.1,0.3,0.3,0.3c0.3,0,0.3,0,0.3-0.3c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.2-0.1,0.3-0.2,0.3
		c-0.3,0-0.3,0-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.3-0.1,0.3-0.3c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2
		c0,0.1,0,0.3-0.2,0.3c-0.2,0-0.3,0-0.3,0.3c0,0.2,0.2,0.3,0.3,0.3c0.1,0,0.3-0.1,0.3-0.3c0-0.2,0.1-0.2,0.3-0.2
		c0.1,0,0.3,0.1,0.3,0.2c0,0.3-0.1,0.3-0.3,0.3c-0.2,0-0.3,0-0.3,0.3c0,0.2,0.2,0.3,0.3,0.3c0.1,0,0.3,0,0.3-0.3
		c0-0.2,0-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.3,0,0.3,0.3,0.3c0.2,0,0.3,0,0.3,0.3c0,0.2,0,0.3,0.3,0.3s0.3,0,0.3,0.3
		c0,0.1,0,0.3,0.3,0.3c0.3,0,0.3-0.2,0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3c0,0.3,0,0.3-0.3,0.3
		c-0.2,0-0.2,0.2-0.2,0.3s0.1,0.2,0.2,0.2c0.2,0,0.3-0.1,0.3-0.2c0-0.3,0-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.2,0.1,0.3,0.3,0.3
		c0.3,0,0.3,0,0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3c0,0.1,0,0.3-0.3,0.3c-0.2,0-0.3,0-0.3,0.3
		c0,0.2,0.1,0.2,0.3,0.2c0.2,0,0.3,0,0.3-0.2c0-0.2,0.2-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.2,0,0.3,0.3,0.3c0.3,0,0.3,0,0.3,0.3
		c0,0.2,0.1,0.2,0.3,0.2C32,38,32,38,32,37.8c0-0.2,0.2-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.2,0,0.3,0.3,0.3c0.3,0,0.3,0,0.3-0.3
		c0-0.1,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.3,0,0.3,0.3,0.3c0.3,0,0.3,0,0.3,0.3c0,0.2,0.1,0.2,0.3,0.2c0.1,0,0.3,0,0.3-0.2
		c0-0.2,0-0.3,0.3-0.3c0.3,0,0.3,0.1,0.3,0.3c0,0.2,0.1,0.2,0.3,0.2c0.2,0,0.3,0,0.3-0.2c0-0.2,0-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
		c0,0.2,0.1,0.2,0.3,0.2c0.2,0,0.3,0,0.3-0.2c0-0.2,0-0.3,0.3-0.3c0.3,0,0.3,0,0.3,0.3s0.1,0.2,0.3,0.2c0.2,0,0.3,0,0.3-0.2
		c0-0.2,0-0.3,0.3-0.3c0.3,0,0.3,0,0.3,0.3c0,0.2,0.2,0.2,0.3,0.2c0.1,0,0.3-0.1,0.3-0.2c0-0.3,0-0.3-0.3-0.3c-0.3,0-0.3,0-0.3-0.3
		c0-0.1,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.3,0,0.3,0.3,0.3c0.3,0,0.3,0,0.3-0.3c0-0.1,0.1-0.3,0.3-0.3
		c0.2,0,0.3,0.1,0.3,0.3c0,0.3,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0.2-0.3,0.3-0.3c0.3,0,0.3,0,0.3,0.3
		c0,0.2,0.2,0.3,0.3,0.3c0.1,0,0.3-0.1,0.3-0.3c0-0.3,0-0.3-0.3-0.3c-0.2,0-0.3,0-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3
		c0,0.3,0,0.3,0.3,0.3c0.3,0,0.3,0,0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.2,0.2,0.3,0.3,0.3
		c0.1,0,0.3-0.1,0.3-0.3c0-0.3,0-0.3-0.3-0.3c-0.1,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3
		c0,0.3,0,0.3,0.3,0.3c0.3,0,0.3,0,0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.3,0,0.3,0,0.3,0.3c0,0.1,0.1,0.2,0.3,0.2c0.2,0,0.3-0.2,0.3-0.2
		c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0,0.3,0.3c0,0.2,0.2,0.3,0.3,0.3
		c0.1,0,0.3,0,0.3-0.3c0-0.2,0-0.3,0.3-0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.3,0.3-0.3c0.3,0,0.3,0,0.3-0.3c0-0.1,0.1-0.3,0.3-0.3
		c0.3,0,0.3,0,0.3,0.3c0,0.1,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.3-0.3
		c0-0.1,0.2-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.1,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3
		c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.3,0.1,0.3,0.2c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3
		c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.3,0,0.3,0.2s0.1,0.3,0.3,0.3c0.1,0,0.3-0.1,0.3-0.3
		c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.3-0.2c0.2,0,0.3,0,0.3,0.2c0,0.2,0.1,0.3,0.3,0.3
		c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.3,0.1,0.3,0.2
		c0,0.1,0.1,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.2-0.2-0.2-0.3c0-0.1,0-0.2,0.2-0.2
		c0.2,0,0.3,0.1,0.3,0.2c0,0.2,0,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2,0-0.3-0.3-0.3c-0.2,0-0.2-0.2-0.2-0.3
		c0-0.1,0.1-0.2,0.2-0.2c0.3,0,0.3,0.1,0.3,0.2c0,0.2,0,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3c0-0.1,0-0.3-0.3-0.3
		c-0.2,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.3,0.2-0.3c0.3,0,0.3,0.2,0.3,0.3c0,0.1,0,0.3,0.3,0.3c0.2,0,0.3-0.2,0.3-0.3
		c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.2-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.3,0,0.3,0,0.3-0.3c0-0.2,0.1-0.3,0.3-0.3
		c0.3,0,0.3-0.1,0.3-0.3c0-0.2,0-0.3,0.2-0.3c0.1,0,0.3-0.1,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.1,0-0.3-0.1-0.3-0.3
		c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0,0.3,0.3c0,0.2,0.1,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.2c0-0.1-0.1-0.3-0.3-0.3
		c-0.2,0-0.3,0-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.3,0,0.3,0,0.3-0.3c0-0.2,0-0.3,0.3-0.3c0.2,0,0.3-0.1,0.3-0.3
		c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.2-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.3,0,0.3,0,0.3-0.3c0-0.2,0-0.3,0.3-0.3
		c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.2-0.3,0.3-0.3c0.1,0,0.3,0,0.3-0.3
		c0-0.3-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.3,0,0.3,0,0.3,0.3c0,0.1,0.1,0.3,0.3,0.3
		c0.2,0,0.3-0.2,0.3-0.3c0-0.1,0-0.3-0.3-0.3c-0.3,0-0.2-0.1-0.2-0.3c0-0.2,0-0.3,0.2-0.3c0.2,0,0.3-0.1,0.3-0.3
		c0-0.2,0-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.2-0.3-0.3
		c0-0.1,0.1-0.3,0.3-0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.2-0.3-0.3c0-0.1,0-0.3,0.3-0.3
		c0.2,0,0.3-0.1,0.3-0.3s-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3-0.1,0.3-0.3
		c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.3-0.3c0-0.3,0-0.3,0.3-0.3c0.1,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3
		c-0.1,0-0.3,0.1-0.3,0.3c0,0.2,0,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.3,0,0.3,0,0.3-0.3
		c0-0.2,0-0.3-0.3-0.3c-0.2,0-0.3-0.2-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.2,0,0.3-0.2,0.3-0.3c0-0.1,0-0.3-0.3-0.3
		c-0.2,0-0.2,0-0.2-0.3c0-0.3,0-0.3-0.3-0.3c-0.1,0-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.2-0.3c0.2,0,0.3-0.1,0.3-0.3
		c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.2-0.1-0.2-0.2c0-0.3,0-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3
		c0.2,0,0.3-0.1,0.3-0.3c0-0.1,0-0.3-0.3-0.3c-0.3,0-0.3,0.2-0.3,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.2-0.1-0.2-0.2
		c0-0.2,0.1-0.2,0.2-0.2c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.1,0-0.3,0-0.3-0.3c0-0.3,0-0.3-0.3-0.3
		c-0.1,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3
		c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3-0.1,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3
		c-0.1,0-0.3,0.1-0.3,0.3c0,0.2-0.1,0.2-0.3,0.2C51.1,9.3,51,9.1,51,9c0-0.2,0.1-0.3,0.2-0.3c0.3,0,0.3-0.2,0.3-0.3
		c0-0.1,0-0.3-0.3-0.3C51,8.2,51,8.2,51,8c0-0.1,0-0.3-0.3-0.3c-0.3,0-0.3,0-0.3-0.3c0-0.1,0-0.3-0.3-0.3c-0.2,0-0.3,0-0.3-0.3
		c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3,0-0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.1-0.1-0.3-0.3-0.3
		c-0.3,0-0.3-0.2-0.3-0.3c0-0.1,0-0.3-0.3-0.3c-0.3,0-0.3,0-0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.1,0-0.3,0-0.3,0.3
		C47.2,4.9,47,5,46.9,5c-0.2,0-0.3-0.1-0.3-0.2c0-0.2,0.2-0.3,0.3-0.3c0.1,0,0.3,0,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3
		c-0.1,0-0.3,0-0.3,0.3c0,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.3,0-0.3-0.3c0-0.3,0-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3
		c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3-0.1-0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.2-0.1,0.2-0.3,0.2
		c-0.2,0-0.3-0.1-0.3-0.2c0-0.3,0-0.3,0.3-0.3c0.1,0,0.2-0.1,0.2-0.3c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.3,0.1-0.3,0.3
		c0,0.2,0,0.3-0.3,0.3c-0.2,0-0.3-0.2-0.3-0.3c0-0.1,0-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3c0,0.2-0.1,0.2-0.2,0.2
		c-0.1,0-0.3-0.1-0.3-0.2c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.3-0.1,0.3-0.3c0-0.2-0.2-0.3-0.3-0.3c-0.1,0-0.3,0-0.3,0.3
		c0,0.2-0.2,0.3-0.3,0.3c-0.1,0-0.3-0.1-0.3-0.3c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.2-0.2,0.3-0.3,0.3
		c0,0-0.3,0-0.3-0.3c0-0.2,0.1-0.3,0.3-0.3c0.1,0,0.2-0.1,0.2-0.2c0-0.2-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		c0,0.3-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3S40,1.2,39.9,1.2c-0.1,0-0.3,0.1-0.3,0.3c0,0.2-0.1,0.2-0.3,0.2
		c-0.2,0-0.3-0.1-0.3-0.2c0-0.2-0.2-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3c0,0.2-0.1,0.2-0.3,0.2c-0.2,0-0.2-0.1-0.2-0.2
		c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.2-0.3,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.2,0-0.2-0.1-0.2-0.2c0-0.1-0.1-0.3-0.3-0.3
		c-0.2,0-0.3,0.1-0.3,0.3c0,0.2-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.2-0.2c0-0.2-0.1-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3
		S35.1,1.7,35,1.7c-0.1,0-0.3,0-0.3-0.2c0-0.2-0.2-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3c0,0.2-0.1,0.2-0.2,0.2c-0.1,0-0.3,0-0.3-0.2
		c0-0.1,0-0.2-0.1-0.2C34,1.2,34.4,1.1,34.8,1c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0.2,0,0.4-0.1,0.6-0.1c0,0,0,0.1,0,0.1
		c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.1,0-0.1c0.3,0,0.5-0.1,0.8-0.1c-0.1,0-0.1,0.1-0.1,0.2
		c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.1-0.2c0.4,0,0.8,0,1.1,0c0.6,0,1.3,0,1.9,0.1
		c-0.1,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.1-0.1-0.1c0.3,0,0.5,0.1,0.8,0.1c0,0,0,0,0,0
		c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.1C51.2,2.5,58.4,10.7,58.4,20.7"/>
	<path id="XMLID_441_" fill="#FFE1BB" d="M21.2,29.6c0,0.3,0,0.3-0.3,0.3c-0.1,0-0.2,0-0.2,0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0-0.1
		c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.4-0.4-0.8-0.5-1.2c0.2,0,0.2,0,0.2-0.3c0-0.1,0-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3
		c0,0.1,0,0.3-0.3,0.3c-0.3,0-0.3,0-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3,0,0.3,0.3c0,0.2,0,0.3,0.3,0.3
		C21.1,29.4,21.2,29.5,21.2,29.6"/>
	<path id="XMLID_440_" fill="#FFE1BB" d="M18.8,24.5c0-0.2-0.1-0.4-0.1-0.6c0,0,0,0,0.1,0c0.2,0,0.3,0.1,0.3,0.3
		C19,24.4,18.9,24.5,18.8,24.5"/>
	<path id="XMLID_439_" fill="#FFE1BB" d="M19.3,26.1c0,0-0.1,0-0.1,0c-0.1-0.4-0.2-0.7-0.3-1.1c0.1,0,0.1,0.1,0.1,0.3
		c0,0.3,0,0.3,0.3,0.3c0.2,0,0.3,0.1,0.3,0.3C19.6,26,19.5,26.1,19.3,26.1"/>
	<path id="XMLID_438_" fill="#FFE1BB" d="M19.8,27.8c0,0-0.1,0-0.1,0c-0.1-0.4-0.3-0.8-0.4-1.2c0.2,0,0.2,0.1,0.2,0.3
		c0,0.3,0,0.3,0.3,0.3c0.2,0,0.3,0.1,0.3,0.3C20.1,27.6,20,27.8,19.8,27.8"/>
	<path id="XMLID_437_" fill="#FFE1BB" d="M18.5,20.4c0-0.1,0-0.1-0.1-0.2c0,0.1,0,0.2,0,0.3C18.5,20.6,18.5,20.5,18.5,20.4"/>
	<path id="XMLID_436_" fill="#FFE1BB" d="M19.3,15.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0.1-0.1,0.3-0.1,0.4
		C19.2,15.2,19.2,15.2,19.3,15.2"/>
	<path id="XMLID_435_" fill="#FFE1BB" d="M19,16.6c0-0.1-0.1-0.2-0.1-0.2c0,0.2-0.1,0.3-0.1,0.5C18.9,16.9,19,16.8,19,16.6"/>
	<path id="XMLID_434_" fill="#FFE1BB" d="M19.8,13.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.1,0.3-0.2,0.4
		C19.7,13.6,19.8,13.6,19.8,13.6"/>
	<path id="XMLID_433_" fill="#FFE1BB" d="M20.6,11.8c0,0,0-0.1,0-0.1c-0.1,0.1-0.1,0.2-0.2,0.3C20.5,12,20.6,11.9,20.6,11.8"/>
	<path id="XMLID_432_" fill="#FFE1BB" d="M20.9,11.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0
		c-0.1,0.1-0.1,0.2-0.2,0.3C20.8,11.4,20.8,11.4,20.9,11.4"/>
	<path id="XMLID_431_" fill="#FFE1BB" d="M18.7,18c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0c0,0.1,0,0.3-0.1,0.4
		C18.6,17.9,18.7,18,18.7,18"/>
	<path id="XMLID_430_" fill="#FFE1BB" d="M25.3,6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0.1-0.2,0.1-0.2,0.2
		C25,5.9,25.1,6,25.3,6"/>
	<path id="XMLID_429_" fill="#FFE1BB" d="M25.8,5.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.3
		C25.6,5.4,25.7,5.5,25.8,5.5"/>
	<path id="XMLID_428_" fill="#FFE1BB" d="M26.3,4.9c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1c-0.1,0.1-0.2,0.2-0.4,0.3
		C26.2,4.9,26.3,4.9,26.3,4.9"/>
	<path id="XMLID_427_" fill="#FFE1BB" d="M26.9,4.4c0.1,0,0.2-0.1,0.2-0.2c0,0,0,0,0,0C27,4.2,26.9,4.3,26.9,4.4
		C26.8,4.4,26.9,4.4,26.9,4.4"/>
	<path id="XMLID_426_" fill="#FFE1BB" d="M24.7,6.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2
		c0,0,0,0,0,0C24.5,6.5,24.6,6.6,24.7,6.6"/>
	<path id="XMLID_425_" fill="#FFE1BB" d="M24.2,7.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0C24.1,6.7,24,6.8,24,6.8
		c0,0,0,0,0,0C24,7,24.1,7.1,24.2,7.1"/>
	<path id="XMLID_424_" fill="#FFE1BB" d="M23.6,7.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2
		C23.4,7.5,23.5,7.6,23.6,7.6"/>
	<path id="XMLID_423_" fill="#FFE1BB" d="M23.1,8.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2C23.1,7.9,23,8,22.9,8
		C22.9,8.1,23,8.2,23.1,8.2"/>
	<path id="XMLID_422_" fill="#FFE1BB" d="M22.5,8.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.1-0.1-0.2c-0.1,0.1-0.2,0.2-0.2,0.3
		C22.5,8.7,22.5,8.7,22.5,8.7"/>
	<path id="XMLID_421_" fill="#FFE1BB" d="M22,9.2c0.1,0,0.2-0.1,0.2-0.2c0,0,0-0.1,0-0.1C22.1,9.1,22.1,9.2,22,9.2
		C22,9.2,22,9.2,22,9.2"/>
	<path id="XMLID_420_" fill="#FFE1BB" d="M29,3.3c0.1,0,0.3-0.1,0.3-0.3c0-0.1,0-0.1-0.1-0.2C29.1,3,28.9,3,28.8,3.1
		C28.8,3.2,28.9,3.3,29,3.3"/>
	<path id="XMLID_419_" fill="#FFE1BB" d="M54.5,20.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C54.3,20,54.4,20.1,54.5,20.1"/>
	<path id="XMLID_418_" fill="#FFE1BB" d="M53.4,20C53.5,20,53.5,19.9,53.4,20c0.1-0.2,0.1-0.2,0-0.2C53.4,19.8,53.3,19.8,53.4,20
		C53.3,19.9,53.4,20,53.4,20"/>
	<path id="XMLID_417_" fill="#FFE1BB" d="M52.9,20.5C52.9,20.5,52.9,20.5,52.9,20.5C52.9,20.4,52.9,20.4,52.9,20.5
		C52.9,20.4,52.9,20.4,52.9,20.5C52.9,20.5,52.9,20.5,52.9,20.5"/>
	<path id="XMLID_416_" fill="#FFE1BB" d="M54.7,19.9c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C54.6,20.1,54.7,20,54.7,19.9"/>
	<path id="XMLID_415_" fill="#FFE1BB" d="M54.1,20.4c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C54.1,20.6,54.1,20.5,54.1,20.4"/>
	<path id="XMLID_414_" fill="#FFE1BB" d="M53.5,21c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0.1,0.1
		C53.5,21.1,53.5,21,53.5,21"/>
	<path id="XMLID_413_" fill="#FFE1BB" d="M53,21.5C53,21.5,52.9,21.5,53,21.5C52.9,21.5,52.9,21.5,53,21.5C52.9,21.5,52.9,21.6,53,21.5
		C52.9,21.6,53,21.5,53,21.5"/>
	<path id="XMLID_412_" fill="#FFE1BB" d="M54.7,21c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C54.6,21.2,54.7,21.1,54.7,21"/>
	<path id="XMLID_411_" fill="#FFE1BB" d="M54.1,21.5c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C54.1,21.7,54.1,21.6,54.1,21.5"/>
	<path id="XMLID_410_" fill="#FFE1BB" d="M53.5,22.1c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0.1,0.1
		C53.5,22.2,53.5,22.1,53.5,22.1"/>
	<path id="XMLID_409_" fill="#FFE1BB" d="M53,22.6C53,22.6,52.9,22.5,53,22.6c-0.1-0.1-0.2,0-0.2,0C52.8,22.6,52.9,22.7,53,22.6
		C52.9,22.7,53,22.6,53,22.6"/>
	<path id="XMLID_408_" fill="#FFE1BB" d="M52.4,24.2C52.4,24.2,52.4,24.2,52.4,24.2C52.3,24.2,52.3,24.2,52.4,24.2
		C52.3,24.3,52.3,24.3,52.4,24.2C52.4,24.3,52.4,24.3,52.4,24.2"/>
	<path id="XMLID_407_" fill="#FFE1BB" d="M49.7,29.1C49.7,29.1,49.7,29.1,49.7,29.1C49.6,29.1,49.6,29.1,49.7,29.1
		C49.6,29.1,49.6,29.1,49.7,29.1C49.7,29.1,49.7,29.1,49.7,29.1"/>
	<path id="XMLID_406_" fill="#FFE1BB" d="M54.5,19.7c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C54.3,19.8,54.4,19.7,54.5,19.7"/>
	<path id="XMLID_405_" fill="#FFE1BB" d="M53.4,19.8C53.5,19.8,53.5,19.8,53.4,19.8c0.1,0.2,0.1,0.2,0,0.2C53.4,20,53.3,19.9,53.4,19.8
		C53.3,19.8,53.4,19.8,53.4,19.8"/>
	<path id="XMLID_404_" fill="#FFE1BB" d="M52.9,19.3C52.9,19.3,52.9,19.3,52.9,19.3C52.9,19.4,52.9,19.4,52.9,19.3
		C52.9,19.4,52.9,19.4,52.9,19.3C52.9,19.3,52.9,19.3,52.9,19.3"/>
	<path id="XMLID_403_" fill="#FFE1BB" d="M54.7,19.9c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C54.6,19.7,54.7,19.8,54.7,19.9"/>
	<path id="XMLID_402_" fill="#FFE1BB" d="M54.1,19.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C54.1,19.2,54.1,19.3,54.1,19.3"/>
	<path id="XMLID_401_" fill="#FFE1BB" d="M53.5,18.8c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1
		C53.5,18.7,53.5,18.7,53.5,18.8"/>
	<path id="XMLID_400_" fill="#FFE1BB" d="M53,18.3C53,18.3,52.9,18.3,53,18.3C52.9,18.3,52.9,18.3,53,18.3C52.9,18.2,52.9,18.2,53,18.3
		C52.9,18.2,53,18.2,53,18.3"/>
	<path id="XMLID_399_" fill="#FFE1BB" d="M54.7,18.8c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C54.6,18.6,54.7,18.7,54.7,18.8"/>
	<path id="XMLID_398_" fill="#FFE1BB" d="M54.1,18.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C54.1,18.1,54.1,18.2,54.1,18.3"/>
	<path id="XMLID_397_" fill="#FFE1BB" d="M53.5,17.7c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1
		C53.5,17.6,53.5,17.7,53.5,17.7"/>
	<path id="XMLID_396_" fill="#FFE1BB" d="M54,14.8c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C53.7,14.9,53.9,14.8,54,14.8"/>
	<path id="XMLID_395_" fill="#FFE1BB" d="M50.2,10.1C50.2,10.2,50.2,10.2,50.2,10.1c-0.1,0.1-0.1,0-0.1,0
		C50.1,10.1,50.2,10.1,50.2,10.1C50.2,10.1,50.2,10.1,50.2,10.1"/>
	<path id="XMLID_394_" fill="#FFE1BB" d="M47.5,5.5c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.2,0,0.2,0.2
		C47.7,5.4,47.6,5.5,47.5,5.5"/>
	<path id="XMLID_393_" fill="#FFE1BB" d="M37.8,3.1C37.8,3,37.8,3,37.8,3.1c-0.1-0.1-0.2-0.1-0.2,0C37.6,3.1,37.7,3.2,37.8,3.1
		C37.8,3.2,37.8,3.1,37.8,3.1"/>
	<path id="XMLID_392_" fill="#FFE1BB" d="M38.9,3.1C38.9,3,38.9,3,38.9,3.1c-0.1-0.1-0.2-0.1-0.2,0C38.7,3.1,38.8,3.2,38.9,3.1
		C38.9,3.2,38.9,3.1,38.9,3.1"/>
	<path id="XMLID_391_" fill="#FFE1BB" d="M38.3,3.6C38.3,3.6,38.3,3.6,38.3,3.6C38.2,3.6,38.2,3.6,38.3,3.6C38.2,3.6,38.2,3.7,38.3,3.6
		C38.3,3.7,38.3,3.6,38.3,3.6"/>
	<path id="XMLID_390_" fill="#FFE1BB" d="M36.7,3.1C36.7,3,36.7,3,36.7,3.1c-0.1-0.1-0.2-0.1-0.2,0C36.5,3.1,36.6,3.2,36.7,3.1
		C36.7,3.2,36.7,3.1,36.7,3.1"/>
	<path id="XMLID_389_" fill="#FFE1BB" d="M37.2,3.6C37.2,3.6,37.2,3.6,37.2,3.6C37.2,3.6,37.1,3.6,37.2,3.6C37.1,3.6,37.2,3.7,37.2,3.6
		C37.2,3.7,37.2,3.6,37.2,3.6"/>
	<path id="XMLID_388_" fill="#FFE1BB" d="M39.8,3.1C39.8,3,39.8,3,39.9,3C40,3,40,3,40,3.1c0,0.1-0.1,0.1-0.1,0.1
		C39.8,3.2,39.8,3.2,39.8,3.1"/>
	<path id="XMLID_387_" fill="#FFE1BB" d="M40.8,3.1c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C40.9,3.2,40.8,3.2,40.8,3.1"/>
	<path id="XMLID_386_" fill="#FFE1BB" d="M41.9,3.1c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C42,3.3,41.9,3.2,41.9,3.1"/>
	<path id="XMLID_385_" fill="#FFE1BB" d="M42.9,3.1c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C43,3.3,42.9,3.2,42.9,3.1"/>
	<path id="XMLID_384_" fill="#FFE1BB" d="M47,6.9C47,6.9,47,7,47,6.9c-0.1,0.1-0.1,0-0.1,0C46.9,6.8,46.9,6.8,47,6.9
		C47,6.8,47,6.8,47,6.9"/>
	<path id="XMLID_383_" fill="#FFE1BB" d="M46.5,6.3C46.5,6.4,46.4,6.4,46.5,6.3c-0.1,0.1-0.2,0-0.2,0C46.3,6.3,46.4,6.3,46.5,6.3
		C46.4,6.3,46.5,6.3,46.5,6.3"/>
	<path id="XMLID_382_" fill="#FFE1BB" d="M46,5.8C46,5.9,45.9,5.9,46,5.8c-0.2,0.1-0.2,0.1-0.2,0C45.8,5.7,45.8,5.7,46,5.8
		C45.9,5.7,46,5.7,46,5.8"/>
	<path id="XMLID_381_" fill="#FFE1BB" d="M45.4,5.3c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1
		C45.4,5.1,45.4,5.2,45.4,5.3"/>
	<path id="XMLID_380_" fill="#FFE1BB" d="M44.9,4.7c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1
		C44.8,4.6,44.9,4.6,44.9,4.7"/>
	<path id="XMLID_379_" fill="#FFE1BB" d="M39.4,3.7C39.4,3.7,39.4,3.7,39.4,3.7c0.1-0.1,0-0.1,0-0.1C39.3,3.6,39.3,3.6,39.4,3.7
		C39.3,3.7,39.3,3.7,39.4,3.7"/>
	<path id="XMLID_378_" fill="#FFE1BB" d="M40.4,3.7c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1
		C40.3,3.7,40.4,3.7,40.4,3.7"/>
	<path id="XMLID_377_" fill="#FFE1BB" d="M41.5,3.8c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1
		C41.4,3.7,41.4,3.8,41.5,3.8"/>
	<path id="XMLID_376_" fill="#FFE1BB" d="M42.6,3.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C42.4,3.7,42.5,3.8,42.6,3.8"/>
	<path id="XMLID_375_" fill="#FFE1BB" d="M42.1,4.2C42.1,4.2,42.1,4.2,42.1,4.2c0.1-0.1,0-0.1,0-0.1C42,4.1,42,4.1,42.1,4.2
		C42,4.2,42,4.2,42.1,4.2"/>
	<path id="XMLID_374_" fill="#FFE1BB" d="M43.1,4.3c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1
		C43,4.2,43.1,4.3,43.1,4.3"/>
	<path id="XMLID_373_" fill="#FFE1BB" d="M44.2,4.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1
		C44.1,4.2,44.2,4.3,44.2,4.3"/>
	<path id="XMLID_372_" fill="#FFE1BB" d="M43.7,4.8C43.7,4.8,43.8,4.7,43.7,4.8c0.1-0.1,0-0.1,0-0.1C43.7,4.6,43.6,4.7,43.7,4.8
		C43.6,4.7,43.7,4.8,43.7,4.8"/>
	<path id="XMLID_371_" fill="#FFE1BB" d="M43.7,3.8c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C43.5,3.7,43.6,3.8,43.7,3.8"/>
	<path id="XMLID_370_" fill="#FFE1BB" d="M44.8,3.9c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C44.5,3.8,44.6,3.9,44.8,3.9"/>
	<path id="XMLID_369_" fill="#FFE1BB" d="M45.3,4.4c0.1,0,0.2-0.1,0.2-0.2c0-0.2,0-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C45.1,4.3,45.2,4.4,45.3,4.4"/>
	<path id="XMLID_368_" fill="#FFE1BB" d="M45.9,4.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C45.6,4.8,45.7,4.9,45.9,4.9"/>
	<path id="XMLID_367_" fill="#FFE1BB" d="M46.4,5.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C46.2,5.4,46.3,5.5,46.4,5.5"/>
	<path id="XMLID_366_" fill="#FFE1BB" d="M46.9,6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C46.8,5.9,46.8,6,46.9,6"/>
	<path id="XMLID_365_" fill="#FFE1BB" d="M47.5,6.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C47.3,6.4,47.4,6.5,47.5,6.5"/>
	<path id="XMLID_364_" fill="#FFE1BB" d="M48,7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C47.9,7,47.9,7,48,7"/>
	<path id="XMLID_363_" fill="#FFE1BB" d="M48.6,7.6c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1
		C48.4,7.5,48.5,7.6,48.6,7.6"/>
	<path id="XMLID_362_" fill="#FFE1BB" d="M49.1,8.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1C49,7.8,49,7.9,49,8
		C49,8,49,8.1,49.1,8.1"/>
	<path id="XMLID_361_" fill="#FFE1BB" d="M50.8,10.7C50.8,10.7,50.8,10.8,50.8,10.7c-0.1,0.1-0.2,0-0.2,0
		C50.6,10.6,50.7,10.6,50.8,10.7C50.8,10.6,50.8,10.6,50.8,10.7"/>
	<path id="XMLID_360_" fill="#FFE1BB" d="M51.4,11.2c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1
		C51.3,11.1,51.4,11.1,51.4,11.2"/>
	<path id="XMLID_359_" fill="#FFE1BB" d="M52,11.8c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C51.9,11.6,52,11.7,52,11.8"/>
	<path id="XMLID_358_" fill="#FFE1BB" d="M52.5,12.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C52.5,12.1,52.5,12.2,52.5,12.3"/>
	<path id="XMLID_357_" fill="#FFE1BB" d="M53.1,12.8c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C53,12.6,53.1,12.7,53.1,12.8"/>
	<path id="XMLID_356_" fill="#FFE1BB" d="M53.4,13.1c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C53.7,13.2,53.6,13.1,53.4,13.1"/>
	<path id="XMLID_355_" fill="#FFE1BB" d="M52.4,11c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C52.6,11.1,52.5,11,52.4,11"/>
	<path id="XMLID_354_" fill="#FFE1BB" d="M51.8,10.5c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C52,10.6,51.9,10.5,51.8,10.5"/>
	<path id="XMLID_353_" fill="#FFE1BB" d="M51.3,10c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C51.4,10,51.4,10,51.3,10"/>
	<path id="XMLID_352_" fill="#FFE1BB" d="M50.7,9.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C50.9,9.5,50.8,9.4,50.7,9.4"/>
	<path id="XMLID_351_" fill="#FFE1BB" d="M50.2,8.9c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1
		C50.3,9,50.3,8.9,50.2,8.9"/>
	<path id="XMLID_350_" fill="#FFE1BB" d="M49.6,8.4c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1
		C49.8,8.4,49.7,8.4,49.6,8.4"/>
	<path id="XMLID_349_" fill="#FFE1BB" d="M48.6,8.5C48.6,8.5,48.6,8.5,48.6,8.5C48.6,8.5,48.6,8.5,48.6,8.5C48.5,8.5,48.5,8.5,48.6,8.5
		C48.5,8.5,48.5,8.5,48.6,8.5"/>
	<path id="XMLID_348_" fill="#FFE1BB" d="M49.1,9.1C49.1,9.1,49.1,9.1,49.1,9.1C49.1,9,49.1,9,49.1,9.1C49.1,9,49.1,9,49.1,9.1
		C49.1,9.1,49.1,9.1,49.1,9.1"/>
	<path id="XMLID_347_" fill="#FFE1BB" d="M52.4,15.6C52.4,15.6,52.4,15.6,52.4,15.6C52.4,15.5,52.4,15.5,52.4,15.6
		C52.3,15.5,52.3,15.5,52.4,15.6C52.3,15.6,52.3,15.6,52.4,15.6"/>
	<path id="XMLID_346_" fill="#FFE1BB" d="M51.9,12.8C51.9,12.9,51.9,12.9,51.9,12.8c-0.1,0.1-0.2,0-0.2,0
		C51.7,12.8,51.8,12.8,51.9,12.8C51.9,12.8,51.9,12.8,51.9,12.8"/>
	<path id="XMLID_345_" fill="#FFE1BB" d="M51.3,13.4C51.3,13.4,51.3,13.4,51.3,13.4C51.3,13.4,51.3,13.4,51.3,13.4
		C51.3,13.4,51.2,13.4,51.3,13.4C51.2,13.4,51.3,13.4,51.3,13.4"/>
	<path id="XMLID_344_" fill="#FFE1BB" d="M50.2,11.3C50.2,11.3,50.2,11.2,50.2,11.3C50.2,11.2,50.2,11.2,50.2,11.3
		C50.2,11.2,50.2,11.2,50.2,11.3C50.2,11.2,50.2,11.3,50.2,11.3"/>
	<path id="XMLID_343_" fill="#FFE1BB" d="M47.5,7.5C47.5,7.5,47.5,7.5,47.5,7.5c0.1-0.1,0-0.1,0-0.1C47.5,7.4,47.4,7.4,47.5,7.5
		C47.4,7.5,47.5,7.5,47.5,7.5"/>
	<path id="XMLID_342_" fill="#FFE1BB" d="M48,8C48,8,48.1,8,48,8C48.1,7.9,48,7.9,48,8C48,7.9,48,7.9,48,8C48,8,48,8,48,8"/>
	<path id="XMLID_341_" fill="#FFE1BB" d="M49.6,9.6C49.7,9.6,49.7,9.6,49.6,9.6c0.1-0.1,0-0.1,0-0.1C49.6,9.5,49.6,9.6,49.6,9.6
		C49.6,9.6,49.6,9.6,49.6,9.6"/>
	<path id="XMLID_340_" fill="#FFE1BB" d="M54,16.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C53.8,16.2,53.9,16.3,54,16.3"/>
	<path id="XMLID_339_" fill="#FFE1BB" d="M53.4,15.7c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.1,0.2
		C53.3,15.6,53.4,15.7,53.4,15.7"/>
	<path id="XMLID_338_" fill="#FFE1BB" d="M53.4,16.8c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1
		C53.3,16.7,53.4,16.8,53.4,16.8"/>
	<path id="XMLID_337_" fill="#FFE1BB" d="M54,17.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C53.8,17.3,53.9,17.4,54,17.4"/>
	<path id="XMLID_336_" fill="#FFE1BB" d="M54.5,18c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C54.3,17.9,54.4,18,54.5,18"/>
	<path id="XMLID_335_" fill="#FFE1BB" d="M52.9,16.2C52.9,16.2,53,16.1,52.9,16.2c0.1-0.1,0-0.1,0-0.1C52.9,16,52.8,16.1,52.9,16.2
		C52.8,16.1,52.9,16.2,52.9,16.2"/>
	<path id="XMLID_334_" fill="#FFE1BB" d="M52.9,15.1C53,15.1,53,15.1,52.9,15.1c0.1-0.2,0.1-0.2,0-0.2C52.8,14.9,52.8,15,52.9,15.1
		C52.8,15.1,52.8,15.1,52.9,15.1"/>
	<path id="XMLID_333_" fill="#FFE1BB" d="M53.4,14.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C53.3,14.6,53.3,14.7,53.4,14.7"/>
	<path id="XMLID_332_" fill="#FFE1BB" d="M52.9,14.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1
		C52.8,14,52.8,14.1,52.9,14.1"/>
	<path id="XMLID_331_" fill="#FFE1BB" d="M52.4,14.5C52.4,14.5,52.4,14.5,52.4,14.5c0.1-0.1,0-0.1,0-0.1C52.3,14.4,52.3,14.4,52.4,14.5
		C52.3,14.5,52.3,14.5,52.4,14.5"/>
	<path id="XMLID_330_" fill="#FFE1BB" d="M52.4,13.5c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1
		C52.3,13.4,52.3,13.5,52.4,13.5"/>
	<path id="XMLID_329_" fill="#FFE1BB" d="M54.5,22.3c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.2,0.1-0.2,0.3
		C54.3,22.2,54.4,22.3,54.5,22.3"/>
	<path id="XMLID_328_" fill="#FFE1BB" d="M53,23.7C53,23.6,53,23.6,53,23.7c-0.1-0.1-0.2-0.1-0.2,0C52.8,23.7,52.8,23.8,53,23.7
		C53,23.8,53,23.7,53,23.7"/>
	<path id="XMLID_327_" fill="#FFE1BB" d="M53.6,23.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1
		S53.6,23.2,53.6,23.1"/>
	<path id="XMLID_326_" fill="#FFE1BB" d="M54.2,22.6c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C54.1,22.8,54.2,22.7,54.2,22.6"/>
	<path id="XMLID_325_" fill="#FFE1BB" d="M54.2,23.7c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C54.1,23.9,54.2,23.8,54.2,23.7"/>
	<path id="XMLID_324_" fill="#FFE1BB" d="M54,25c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3
		C53.7,24.9,53.8,25,54,25"/>
	<path id="XMLID_323_" fill="#FFE1BB" d="M51.3,27.5C51.3,27.4,51.3,27.4,51.3,27.5c-0.1-0.1-0.1,0-0.1,0
		C51.2,27.5,51.2,27.6,51.3,27.5C51.3,27.6,51.3,27.5,51.3,27.5"/>
	<path id="XMLID_322_" fill="#FFE1BB" d="M51.9,26.9c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1s0,0.1,0.1,0.1
		C51.9,27.1,51.9,27,51.9,26.9"/>
	<path id="XMLID_321_" fill="#FFE1BB" d="M52.5,25.3c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0.1,0.1
		C52.4,25.4,52.5,25.4,52.5,25.3"/>
	<path id="XMLID_320_" fill="#FFE1BB" d="M52.5,26.4c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C52.4,26.5,52.5,26.5,52.5,26.4"/>
	<path id="XMLID_319_" fill="#FFE1BB" d="M52.4,28.8c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3
		C52.1,28.7,52.2,28.8,52.4,28.8"/>
	<path id="XMLID_318_" fill="#FFE1BB" d="M53.6,24.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C53.5,24.4,53.6,24.3,53.6,24.2"/>
	<path id="XMLID_317_" fill="#FFE1BB" d="M53.7,25.3c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C53.6,25.5,53.7,25.4,53.7,25.3"/>
	<path id="XMLID_316_" fill="#FFE1BB" d="M53,24.8c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1
		C53,24.9,53,24.8,53,24.8"/>
	<path id="XMLID_315_" fill="#FFE1BB" d="M53.1,25.9c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C53,26,53.1,26,53.1,25.9"/>
	<path id="XMLID_314_" fill="#FFE1BB" d="M44.7,34C44.7,34,44.8,34,44.7,34C44.8,34,44.8,34,44.7,34C44.8,34,44.8,34,44.7,34
		C44.8,34,44.7,34,44.7,34"/>
	<path id="XMLID_313_" fill="#FFE1BB" d="M39.9,35.6C39.9,35.6,39.9,35.6,39.9,35.6C39.9,35.6,39.9,35.6,39.9,35.6
		C39.9,35.6,39.9,35.6,39.9,35.6C39.9,35.6,39.9,35.6,39.9,35.6"/>
	<path id="XMLID_312_" fill="#FFE1BB" d="M44.2,34.5C44.2,34.6,44.2,34.6,44.2,34.5c0.1,0.1,0.1,0,0.1,0C44.3,34.5,44.3,34.5,44.2,34.5
		C44.2,34.5,44.2,34.5,44.2,34.5"/>
	<path id="XMLID_311_" fill="#FFE1BB" d="M43.6,35.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
		C43.6,35,43.6,35,43.6,35.1"/>
	<path id="XMLID_310_" fill="#FFE1BB" d="M42.5,35.1C42.5,35.1,42.6,35.1,42.5,35.1c0.1,0.1,0.1,0,0.1,0C42.7,35,42.6,35,42.5,35.1
		C42.6,35,42.5,35,42.5,35.1"/>
	<path id="XMLID_309_" fill="#FFE1BB" d="M40.9,35.6C40.9,35.7,40.9,35.7,40.9,35.6c0.1,0.1,0.1,0,0.1,0C41,35.6,41,35.5,40.9,35.6
		C40.9,35.5,40.9,35.6,40.9,35.6"/>
	<path id="XMLID_308_" fill="#FFE1BB" d="M41.9,35.6c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
		C42,35.5,41.9,35.5,41.9,35.6"/>
	<path id="XMLID_307_" fill="#FFE1BB" d="M43,35.6c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C43.1,35.5,43,35.5,43,35.6"/>
	<path id="XMLID_306_" fill="#FFE1BB" d="M41.8,36.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C41.9,36.5,41.8,36.6,41.8,36.7"/>
	<path id="XMLID_305_" fill="#FFE1BB" d="M42.4,36.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C42.5,36,42.4,36.1,42.4,36.2"/>
	<path id="XMLID_304_" fill="#FFE1BB" d="M53.7,26.4c0-0.1-0.1-0.3-0.3-0.3c-0.1,0-0.3,0.1-0.3,0.3c0,0.1,0.1,0.3,0.3,0.3
		C53.7,26.6,53.7,26.6,53.7,26.4"/>
	<path id="XMLID_303_" fill="#FFE1BB" d="M48.6,32.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C48.4,32.4,48.5,32.5,48.6,32.5"/>
	<path id="XMLID_302_" fill="#FFE1BB" d="M51.8,29.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C51.6,29.2,51.7,29.3,51.8,29.3"/>
	<path id="XMLID_301_" fill="#FFE1BB" d="M51.3,29.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C51.1,29.8,51.2,29.9,51.3,29.9"/>
	<path id="XMLID_300_" fill="#FFE1BB" d="M50.7,30.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C50.5,30.3,50.6,30.4,50.7,30.4"/>
	<path id="XMLID_299_" fill="#FFE1BB" d="M50.2,30.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C50,30.8,50.1,30.9,50.2,30.9"/>
	<path id="XMLID_298_" fill="#FFE1BB" d="M49.6,31.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C49.5,31.4,49.6,31.4,49.6,31.4"/>
	<path id="XMLID_297_" fill="#FFE1BB" d="M49.1,32c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C49,31.9,49,32,49.1,32"/>
	<path id="XMLID_296_" fill="#FFE1BB" d="M45.3,35.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.3,0,0.2,0,0.2-0.2
		C45.6,35.5,45.4,35.4,45.3,35.4"/>
	<path id="XMLID_295_" fill="#FFE1BB" d="M45.9,34.9c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		S46,34.9,45.9,34.9"/>
	<path id="XMLID_294_" fill="#FFE1BB" d="M46.4,34.3c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C46.6,34.4,46.5,34.3,46.4,34.3"/>
	<path id="XMLID_293_" fill="#FFE1BB" d="M46.9,33.8c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C47.1,33.9,47,33.8,46.9,33.8"/>
	<path id="XMLID_292_" fill="#FFE1BB" d="M47.5,33.3c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C47.6,33.4,47.6,33.3,47.5,33.3"/>
	<path id="XMLID_291_" fill="#FFE1BB" d="M48,32.7c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C48.2,32.8,48.1,32.7,48,32.7"/>
	<path id="XMLID_290_" fill="#FFE1BB" d="M48,31.9C48.1,31.9,48.1,31.8,48,31.9c0.1-0.1,0-0.1,0-0.1C48,31.8,48,31.8,48,31.9
		C48,31.8,48,31.9,48,31.9"/>
	<path id="XMLID_289_" fill="#FFE1BB" d="M48.6,31.3C48.6,31.3,48.6,31.3,48.6,31.3c0.1-0.1,0-0.1,0-0.1C48.5,31.2,48.5,31.2,48.6,31.3
		C48.5,31.3,48.5,31.3,48.6,31.3"/>
	<path id="XMLID_288_" fill="#FFE1BB" d="M49.1,30.8C49.1,30.8,49.2,30.8,49.1,30.8c0.1-0.1,0-0.1,0-0.1C49.1,30.7,49.1,30.7,49.1,30.8
		C49.1,30.8,49.1,30.8,49.1,30.8"/>
	<path id="XMLID_287_" fill="#FFE1BB" d="M49.6,30.3C49.7,30.3,49.7,30.2,49.6,30.3c0.1-0.1,0-0.2,0-0.2C49.6,30.1,49.6,30.1,49.6,30.3
		C49.6,30.2,49.6,30.3,49.6,30.3"/>
	<path id="XMLID_286_" fill="#FFE1BB" d="M50.2,29.7C50.2,29.7,50.3,29.7,50.2,29.7c0.1-0.2,0.1-0.2,0-0.2
		C50.1,29.5,50.1,29.6,50.2,29.7C50.1,29.7,50.1,29.7,50.2,29.7"/>
	<path id="XMLID_285_" fill="#FFE1BB" d="M50.7,29.2c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1
		C50.6,29.2,50.7,29.2,50.7,29.2"/>
	<path id="XMLID_284_" fill="#FFE1BB" d="M51.3,28.7c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1s-0.1,0.1-0.1,0.1
		C51.1,28.6,51.2,28.7,51.3,28.7"/>
	<path id="XMLID_283_" fill="#FFE1BB" d="M51.8,28.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C51.7,28.1,51.7,28.2,51.8,28.2"/>
	<path id="XMLID_282_" fill="#FFE1BB" d="M52.4,27.7c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C52.2,27.6,52.3,27.7,52.4,27.7"/>
	<path id="XMLID_281_" fill="#FFE1BB" d="M52.9,27.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C52.7,27.1,52.8,27.1,52.9,27.1"/>
	<path id="XMLID_280_" fill="#FFE1BB" d="M47.5,32.3C47.5,32.3,47.4,32.3,47.5,32.3c-0.1,0.1,0,0.1,0,0.1
		C47.5,32.4,47.5,32.4,47.5,32.3C47.5,32.3,47.5,32.3,47.5,32.3"/>
	<path id="XMLID_279_" fill="#FFE1BB" d="M46.9,32.8C46.9,32.8,46.9,32.9,46.9,32.8c-0.1,0.1,0,0.2,0,0.2C47,33,47,32.9,46.9,32.8
		C47,32.9,47,32.8,46.9,32.8"/>
	<path id="XMLID_278_" fill="#FFE1BB" d="M46.4,33.3c-0.1,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1
		C46.5,33.4,46.5,33.3,46.4,33.3"/>
	<path id="XMLID_277_" fill="#FFE1BB" d="M45.9,33.9c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1
		C46,33.9,45.9,33.9,45.9,33.9"/>
	<path id="XMLID_276_" fill="#FFE1BB" d="M45.3,34.4c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1
		C45.5,34.4,45.4,34.4,45.3,34.4"/>
	<path id="XMLID_275_" fill="#FFE1BB" d="M44.8,34.9c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C44.9,35,44.9,34.9,44.8,34.9"/>
	<path id="XMLID_274_" fill="#FFE1BB" d="M44.2,35.4c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C44.4,35.5,44.3,35.4,44.2,35.4"/>
	<path id="XMLID_273_" fill="#FFE1BB" d="M43.7,35.9c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C43.9,36,43.8,35.9,43.7,35.9"/>
	<path id="XMLID_272_" fill="#FFE1BB" d="M36.6,38c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C36.4,37.9,36.5,38,36.6,38"/>
	<path id="XMLID_271_" fill="#FFE1BB" d="M37.7,38c0.1,0,0.3-0.1,0.3-0.3c0-0.1-0.1-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C37.5,37.9,37.6,38,37.7,38"/>
	<path id="XMLID_270_" fill="#FFE1BB" d="M40.4,37.5c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C40.2,37.4,40.3,37.5,40.4,37.5"/>
	<path id="XMLID_269_" fill="#FFE1BB" d="M39.4,37.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C39.1,37.4,39.2,37.4,39.4,37.4"/>
	<path id="XMLID_268_" fill="#FFE1BB" d="M38.3,37.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C38.1,37.3,38.2,37.4,38.3,37.4"/>
	<path id="XMLID_267_" fill="#FFE1BB" d="M37.2,37.4c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C37,37.3,37.1,37.4,37.2,37.4"/>
	<path id="XMLID_266_" fill="#FFE1BB" d="M36.6,36.8c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1
		C36.5,36.8,36.6,36.8,36.6,36.8"/>
	<path id="XMLID_265_" fill="#FFE1BB" d="M41,36.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C40.8,36.8,40.9,36.9,41,36.9"/>
	<path id="XMLID_264_" fill="#FFE1BB" d="M39.9,36.9c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C39.7,36.8,39.8,36.9,39.9,36.9"/>
	<path id="XMLID_263_" fill="#FFE1BB" d="M38.8,36.8c0.1,0,0.1-0.1,0.1-0.1s-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1
		S38.7,36.8,38.8,36.8"/>
	<path id="XMLID_262_" fill="#FFE1BB" d="M37.7,36.8c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1
		C37.6,36.8,37.7,36.8,37.7,36.8"/>
	<path id="XMLID_261_" fill="#FFE1BB" d="M37.2,36.2C37.2,36.2,37.2,36.2,37.2,36.2c0.1-0.1,0-0.1,0-0.1C37.2,36.1,37.1,36.1,37.2,36.2
		C37.1,36.2,37.2,36.2,37.2,36.2"/>
	<path id="XMLID_260_" fill="#FFE1BB" d="M41.5,36.3c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2
		C41.4,36.2,41.4,36.3,41.5,36.3"/>
	<path id="XMLID_259_" fill="#FFE1BB" d="M40.4,36.3c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1s-0.1,0.1-0.1,0.1
		C40.3,36.2,40.4,36.3,40.4,36.3"/>
	<path id="XMLID_258_" fill="#FFE1BB" d="M39.4,36.2C39.4,36.2,39.4,36.2,39.4,36.2c0.1-0.1,0-0.2,0-0.2C39.3,36.1,39.3,36.1,39.4,36.2
		C39.3,36.2,39.3,36.2,39.4,36.2"/>
	<path id="XMLID_257_" fill="#FFE1BB" d="M38.3,36.2C38.3,36.2,38.3,36.2,38.3,36.2c0.1-0.1,0-0.1,0-0.1C38.2,36.1,38.2,36.1,38.3,36.2
		C38.2,36.2,38.2,36.2,38.3,36.2"/>
	<path id="XMLID_256_" fill="#FFE1BB" d="M18.7,20.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C19,20,18.9,20.1,18.7,20.1"/>
	<path id="XMLID_255_" fill="#FFE1BB" d="M19.8,20C19.8,20,19.7,19.9,19.8,20c-0.1-0.2-0.1-0.2,0-0.2C19.9,19.8,19.9,19.8,19.8,20
		C19.9,19.9,19.9,20,19.8,20"/>
	<path id="XMLID_254_" fill="#FFE1BB" d="M20.4,20.5C20.4,20.5,20.3,20.5,20.4,20.5C20.3,20.4,20.4,20.4,20.4,20.5
		C20.4,20.4,20.4,20.4,20.4,20.5C20.4,20.5,20.4,20.5,20.4,20.5"/>
	<path id="XMLID_253_" fill="#FFE1BB" d="M18.5,19.9c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C18.6,20.1,18.5,20,18.5,19.9"/>
	<path id="XMLID_252_" fill="#FFE1BB" d="M19.1,20.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C19.2,20.6,19.1,20.5,19.1,20.4"/>
	<path id="XMLID_251_" fill="#FFE1BB" d="M19.7,21c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1
		C19.8,21.1,19.7,21,19.7,21"/>
	<path id="XMLID_250_" fill="#FFE1BB" d="M20.3,21.5C20.3,21.5,20.3,21.5,20.3,21.5C20.4,21.5,20.4,21.5,20.3,21.5
		C20.4,21.5,20.4,21.6,20.3,21.5C20.3,21.6,20.3,21.5,20.3,21.5"/>
	<path id="XMLID_249_" fill="#FFE1BB" d="M18.5,21c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C18.6,21.2,18.5,21.1,18.5,21"/>
	<path id="XMLID_248_" fill="#FFE1BB" d="M19.1,21.5c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C19.2,21.7,19.1,21.6,19.1,21.5"/>
	<path id="XMLID_247_" fill="#FFE1BB" d="M19.7,22.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1
		C19.8,22.2,19.7,22.1,19.7,22.1"/>
	<path id="XMLID_246_" fill="#FFE1BB" d="M20.3,22.6C20.3,22.6,20.3,22.5,20.3,22.6c0.1-0.1,0.2,0,0.2,0C20.5,22.6,20.4,22.7,20.3,22.6
		C20.3,22.7,20.3,22.6,20.3,22.6"/>
	<path id="XMLID_245_" fill="#FFE1BB" d="M20.9,23.1C20.9,23.1,20.9,23.1,20.9,23.1C20.9,23.1,21,23.1,20.9,23.1
		C21,23.2,20.9,23.2,20.9,23.1C20.9,23.2,20.9,23.2,20.9,23.1"/>
	<path id="XMLID_244_" fill="#FFE1BB" d="M20.9,24.2C20.9,24.2,20.9,24.2,20.9,24.2C20.9,24.2,21,24.2,20.9,24.2
		C21,24.3,20.9,24.3,20.9,24.2C20.9,24.3,20.9,24.3,20.9,24.2"/>
	<path id="XMLID_243_" fill="#FFE1BB" d="M21.4,24.8C21.4,24.8,21.4,24.7,21.4,24.8C21.5,24.7,21.5,24.8,21.4,24.8
		C21.5,24.8,21.5,24.8,21.4,24.8C21.4,24.8,21.4,24.8,21.4,24.8"/>
	<path id="XMLID_242_" fill="#FFE1BB" d="M21.4,25.9C21.4,25.8,21.4,25.8,21.4,25.9C21.5,25.8,21.5,25.8,21.4,25.9
		C21.5,25.9,21.5,25.9,21.4,25.9C21.4,25.9,21.4,25.9,21.4,25.9"/>
	<path id="XMLID_241_" fill="#FFE1BB" d="M22,26.4C22,26.4,22,26.4,22,26.4C22,26.4,22,26.4,22,26.4C22,26.4,22,26.4,22,26.4
		C22,26.4,22,26.4,22,26.4"/>
	<path id="XMLID_240_" fill="#FFE1BB" d="M22.5,28C22.5,28,22.5,28,22.5,28C22.6,28,22.6,28,22.5,28C22.6,28,22.6,28.1,22.5,28
		C22.5,28.1,22.5,28,22.5,28"/>
	<path id="XMLID_239_" fill="#FFE1BB" d="M23.1,28.6C23.1,28.5,23.1,28.5,23.1,28.6C23.1,28.5,23.1,28.5,23.1,28.6
		C23.1,28.6,23.1,28.6,23.1,28.6C23.1,28.6,23.1,28.6,23.1,28.6"/>
	<path id="XMLID_238_" fill="#FFE1BB" d="M23.1,32.1c-0.2,0-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C23.3,32,23.2,32.1,23.1,32.1"/>
	<path id="XMLID_237_" fill="#FFE1BB" d="M24.2,33.1c-0.2,0-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C24.4,33,24.3,33.1,24.2,33.1"/>
	<path id="XMLID_236_" fill="#FFE1BB" d="M23.6,32.6c-0.2,0-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C23.9,32.5,23.8,32.6,23.6,32.6"/>
	<path id="XMLID_235_" fill="#FFE1BB" d="M18.7,19.7c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2
		C19,19.8,18.9,19.7,18.7,19.7"/>
	<path id="XMLID_234_" fill="#FFE1BB" d="M19.8,19.8C19.8,19.8,19.7,19.8,19.8,19.8c-0.1,0.2-0.1,0.2,0,0.2
		C19.9,20,19.9,19.9,19.8,19.8C19.9,19.8,19.9,19.8,19.8,19.8"/>
	<path id="XMLID_233_" fill="#FFE1BB" d="M20.4,19.3C20.4,19.3,20.3,19.3,20.4,19.3C20.3,19.4,20.4,19.4,20.4,19.3
		C20.4,19.4,20.4,19.4,20.4,19.3C20.4,19.3,20.4,19.3,20.4,19.3"/>
	<path id="XMLID_232_" fill="#FFE1BB" d="M18.5,19.9c0,0.1,0,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C18.6,19.7,18.5,19.8,18.5,19.9"/>
	<path id="XMLID_231_" fill="#FFE1BB" d="M19.1,19.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C19.2,19.2,19.1,19.3,19.1,19.3"/>
	<path id="XMLID_230_" fill="#FFE1BB" d="M19.7,18.8c0,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1
		C19.8,18.7,19.7,18.7,19.7,18.8"/>
	<path id="XMLID_229_" fill="#FFE1BB" d="M20.3,18.3C20.3,18.3,20.3,18.3,20.3,18.3C20.4,18.3,20.4,18.3,20.3,18.3
		C20.4,18.2,20.4,18.2,20.3,18.3C20.3,18.2,20.3,18.2,20.3,18.3"/>
	<path id="XMLID_228_" fill="#FFE1BB" d="M18.5,18.8c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C18.6,18.6,18.5,18.7,18.5,18.8"/>
	<path id="XMLID_227_" fill="#FFE1BB" d="M19.1,18.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C19.2,18.1,19.1,18.2,19.1,18.3"/>
	<path id="XMLID_226_" fill="#FFE1BB" d="M19.7,17.7c0,0.1,0,0.1,0.1,0.1c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1
		C19.8,17.6,19.7,17.7,19.7,17.7"/>
	<path id="XMLID_225_" fill="#FFE1BB" d="M20.3,17.2C20.3,17.2,20.3,17.2,20.3,17.2C20.4,17.2,20.4,17.2,20.3,17.2
		C20.4,17.1,20.4,17.1,20.3,17.2C20.3,17.1,20.3,17.1,20.3,17.2"/>
	<path id="XMLID_224_" fill="#FFE1BB" d="M23,10.1C23,10.2,23.1,10.2,23,10.1c0.1,0.1,0.1,0,0.1,0C23.1,10.1,23.1,10.1,23,10.1
		C23.1,10.1,23,10.1,23,10.1"/>
	<path id="XMLID_223_" fill="#FFE1BB" d="M35.5,3.1C35.5,3,35.5,3,35.5,3.1c0.1-0.1,0.2-0.1,0.2,0C35.6,3.1,35.6,3.2,35.5,3.1
		C35.5,3.2,35.5,3.1,35.5,3.1"/>
	<path id="XMLID_222_" fill="#FFE1BB" d="M34.4,3.1C34.4,3,34.4,3,34.4,3.1c0.1-0.1,0.2-0.1,0.2,0C34.6,3.1,34.5,3.2,34.4,3.1
		C34.4,3.2,34.4,3.1,34.4,3.1"/>
	<path id="XMLID_221_" fill="#FFE1BB" d="M35,3.6C35,3.6,35,3.6,35,3.6C35,3.6,35,3.6,35,3.6C35,3.6,35,3.7,35,3.6
		C35,3.7,35,3.6,35,3.6"/>
	<path id="XMLID_220_" fill="#FFE1BB" d="M36.1,3.6C36.1,3.6,36.1,3.6,36.1,3.6C36.1,3.6,36.1,3.6,36.1,3.6C36.1,3.6,36.1,3.7,36.1,3.6
		C36.1,3.7,36.1,3.6,36.1,3.6"/>
	<path id="XMLID_219_" fill="#FFE1BB" d="M33.5,3.1C33.5,3,33.5,3,33.4,3c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.1,0.1
		C33.5,3.2,33.5,3.2,33.5,3.1"/>
	<path id="XMLID_218_" fill="#FFE1BB" d="M32.5,3.1c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C32.4,3.2,32.5,3.2,32.5,3.1"/>
	<path id="XMLID_217_" fill="#FFE1BB" d="M31.4,3.1c0-0.1-0.1-0.2-0.2-0.2C31.1,2.9,31,3,31,3.1c0,0.1,0.1,0.2,0.2,0.2
		C31.3,3.3,31.4,3.2,31.4,3.1"/>
	<path id="XMLID_216_" fill="#FFE1BB" d="M30.4,3.1c0-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2
		C30.3,3.3,30.4,3.2,30.4,3.1"/>
	<path id="XMLID_215_" fill="#FFE1BB" d="M26.3,6.9C26.3,6.9,26.3,7,26.3,6.9c0.1,0.1,0.1,0,0.1,0C26.4,6.8,26.4,6.8,26.3,6.9
		C26.3,6.8,26.3,6.8,26.3,6.9"/>
	<path id="XMLID_214_" fill="#FFE1BB" d="M26.8,6.3C26.8,6.4,26.8,6.4,26.8,6.3c0.1,0.1,0.2,0,0.2,0C27,6.3,26.9,6.3,26.8,6.3
		C26.8,6.3,26.8,6.3,26.8,6.3"/>
	<path id="XMLID_213_" fill="#FFE1BB" d="M27.3,5.8C27.3,5.9,27.4,5.9,27.3,5.8c0.2,0.1,0.2,0.1,0.2,0C27.5,5.7,27.5,5.7,27.3,5.8
		C27.4,5.7,27.3,5.7,27.3,5.8"/>
	<path id="XMLID_212_" fill="#FFE1BB" d="M27.8,5.3c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1-0.1-0.1
		C27.9,5.1,27.8,5.2,27.8,5.3"/>
	<path id="XMLID_211_" fill="#FFE1BB" d="M28.4,4.7c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
		C28.4,4.6,28.4,4.6,28.4,4.7"/>
	<path id="XMLID_210_" fill="#FFE1BB" d="M33.9,3.7C33.9,3.7,33.9,3.7,33.9,3.7c-0.1-0.1,0-0.1,0-0.1C34,3.6,34,3.6,33.9,3.7
		C34,3.7,34,3.7,33.9,3.7"/>
	<path id="XMLID_209_" fill="#FFE1BB" d="M32.8,3.7c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1
		C32.9,3.7,32.9,3.7,32.8,3.7"/>
	<path id="XMLID_208_" fill="#FFE1BB" d="M31.8,3.8c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
		C31.9,3.7,31.8,3.8,31.8,3.8"/>
	<path id="XMLID_207_" fill="#FFE1BB" d="M30.7,3.8c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C30.8,3.7,30.8,3.8,30.7,3.8"/>
	<path id="XMLID_206_" fill="#FFE1BB" d="M32.3,4.2C32.3,4.2,32.3,4.1,32.3,4.2C32.3,4.1,32.3,4.2,32.3,4.2C32.3,4.2,32.3,4.2,32.3,4.2
		C32.3,4.2,32.3,4.2,32.3,4.2"/>
	<path id="XMLID_205_" fill="#FFE1BB" d="M31.2,4.2C31.2,4.2,31.1,4.2,31.2,4.2c-0.1-0.1,0-0.1,0-0.1C31.3,4.1,31.3,4.1,31.2,4.2
		C31.3,4.2,31.3,4.2,31.2,4.2"/>
	<path id="XMLID_204_" fill="#FFE1BB" d="M30.1,4.3c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1
		C30.2,4.2,30.2,4.3,30.1,4.3"/>
	<path id="XMLID_203_" fill="#FFE1BB" d="M29,4.3c-0.1,0-0.1-0.1-0.1-0.1C28.9,4.1,29,4,29,4c0.1,0,0.1,0.1,0.1,0.1
		C29.2,4.2,29.1,4.3,29,4.3"/>
	<path id="XMLID_202_" fill="#FFE1BB" d="M30.6,4.7C30.6,4.7,30.7,4.7,30.6,4.7C30.7,4.7,30.7,4.7,30.6,4.7C30.7,4.7,30.7,4.7,30.6,4.7
		C30.7,4.7,30.6,4.7,30.6,4.7"/>
	<path id="XMLID_201_" fill="#FFE1BB" d="M27.9,6.3C27.9,6.3,27.9,6.3,27.9,6.3C28,6.3,28,6.3,27.9,6.3C28,6.4,28,6.4,27.9,6.3
		C27.9,6.4,27.9,6.4,27.9,6.3"/>
	<path id="XMLID_200_" fill="#FFE1BB" d="M28.5,5.8C28.5,5.8,28.5,5.8,28.5,5.8C28.5,5.8,28.5,5.8,28.5,5.8C28.5,5.8,28.5,5.8,28.5,5.8
		C28.5,5.8,28.5,5.8,28.5,5.8"/>
	<path id="XMLID_199_" fill="#FFE1BB" d="M29,5.3C29,5.2,29,5.2,29,5.3c0.1-0.1,0.1,0,0.1,0C29.1,5.3,29.1,5.3,29,5.3
		C29,5.3,29,5.3,29,5.3"/>
	<path id="XMLID_198_" fill="#FFE1BB" d="M29.6,4.8C29.6,4.8,29.5,4.7,29.6,4.8c-0.1-0.1,0-0.1,0-0.1C29.6,4.6,29.7,4.7,29.6,4.8
		C29.7,4.7,29.6,4.8,29.6,4.8"/>
	<path id="XMLID_197_" fill="#FFE1BB" d="M29.6,3.8c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C29.8,3.7,29.7,3.8,29.6,3.8"/>
	<path id="XMLID_196_" fill="#FFE1BB" d="M28.5,3.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C28.8,3.8,28.6,3.9,28.5,3.9"/>
	<path id="XMLID_195_" fill="#FFE1BB" d="M28,4.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C28.2,4.3,28.1,4.4,28,4.4"/>
	<path id="XMLID_194_" fill="#FFE1BB" d="M27.4,4.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C27.6,4.8,27.5,4.9,27.4,4.9"/>
	<path id="XMLID_193_" fill="#FFE1BB" d="M26.9,5.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C27.1,5.4,27,5.5,26.9,5.5"/>
	<path id="XMLID_192_" fill="#FFE1BB" d="M26.3,6c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C26.5,5.9,26.4,6,26.3,6"/>
	<path id="XMLID_191_" fill="#FFE1BB" d="M25.8,6.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2S26,6.2,26,6.3
		C26,6.4,25.9,6.5,25.8,6.5"/>
	<path id="XMLID_190_" fill="#FFE1BB" d="M25.3,7c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C25.4,7,25.3,7,25.3,7"/>
	<path id="XMLID_189_" fill="#FFE1BB" d="M24.7,7.6c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
		C24.9,7.5,24.8,7.6,24.7,7.6"/>
	<path id="XMLID_188_" fill="#FFE1BB" d="M24.2,8.1C24.1,8.1,24,8,24,8c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
		C24.3,8,24.2,8.1,24.2,8.1"/>
	<path id="XMLID_187_" fill="#FFE1BB" d="M22.5,10.7C22.5,10.7,22.5,10.8,22.5,10.7c0.1,0.1,0.2,0,0.2,0C22.6,10.6,22.6,10.6,22.5,10.7
		C22.5,10.6,22.5,10.6,22.5,10.7"/>
	<path id="XMLID_186_" fill="#FFE1BB" d="M21.9,11.2c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.1
		C21.9,11.1,21.9,11.1,21.9,11.2"/>
	<path id="XMLID_185_" fill="#FFE1BB" d="M21.3,11.8c0,0.1,0.1,0.2,0.2,0.2s0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2S21.3,11.7,21.3,11.8
		"/>
	<path id="XMLID_184_" fill="#FFE1BB" d="M20.7,12.3c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C20.8,12.1,20.7,12.2,20.7,12.3"/>
	<path id="XMLID_183_" fill="#FFE1BB" d="M20.2,12.8c0,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2
		C20.3,12.6,20.2,12.7,20.2,12.8"/>
	<path id="XMLID_182_" fill="#FFE1BB" d="M21.5,10.5c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C21.3,10.6,21.4,10.5,21.5,10.5"/>
	<path id="XMLID_181_" fill="#FFE1BB" d="M22,10c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C21.8,10,21.9,10,22,10"/>
	<path id="XMLID_180_" fill="#FFE1BB" d="M22.5,9.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C22.4,9.5,22.5,9.4,22.5,9.4"/>
	<path id="XMLID_179_" fill="#FFE1BB" d="M23.1,8.9c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1
		C22.9,9,23,8.9,23.1,8.9"/>
	<path id="XMLID_178_" fill="#FFE1BB" d="M23.6,8.4c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1
		C23.5,8.4,23.6,8.4,23.6,8.4"/>
	<path id="XMLID_177_" fill="#FFE1BB" d="M24.7,8.5C24.7,8.5,24.7,8.5,24.7,8.5C24.7,8.5,24.7,8.5,24.7,8.5C24.7,8.5,24.7,8.5,24.7,8.5
		C24.7,8.5,24.7,8.5,24.7,8.5"/>
	<path id="XMLID_176_" fill="#FFE1BB" d="M24.2,9.1C24.1,9.1,24.1,9.1,24.2,9.1C24.1,9,24.1,9,24.2,9.1C24.2,9,24.2,9,24.2,9.1
		C24.2,9.1,24.2,9.1,24.2,9.1"/>
	<path id="XMLID_175_" fill="#FFE1BB" d="M21.4,12.8C21.4,12.9,21.4,12.9,21.4,12.8c0.1,0.1,0.2,0,0.2,0C21.5,12.8,21.5,12.8,21.4,12.8
		C21.4,12.8,21.4,12.8,21.4,12.8"/>
	<path id="XMLID_174_" fill="#FFE1BB" d="M23.1,11.3C23.1,11.3,23.1,11.2,23.1,11.3C23.1,11.2,23.1,11.2,23.1,11.3
		C23.1,11.2,23.1,11.2,23.1,11.3C23.1,11.2,23.1,11.3,23.1,11.3"/>
	<path id="XMLID_173_" fill="#FFE1BB" d="M22.5,11.8C22.5,11.8,22.5,11.8,22.5,11.8C22.5,11.7,22.5,11.7,22.5,11.8
		C22.6,11.7,22.6,11.7,22.5,11.8C22.6,11.8,22.6,11.8,22.5,11.8"/>
	<path id="XMLID_172_" fill="#FFE1BB" d="M22,12.4C22,12.4,21.9,12.3,22,12.4c-0.1-0.1,0-0.1,0-0.1C22,12.2,22.1,12.3,22,12.4
		C22.1,12.3,22,12.4,22,12.4"/>
	<path id="XMLID_171_" fill="#FFE1BB" d="M21.5,14C21.4,14,21.4,13.9,21.5,14C21.4,13.9,21.4,13.9,21.5,14C21.5,13.9,21.5,13.9,21.5,14
		C21.5,13.9,21.5,14,21.5,14"/>
	<path id="XMLID_170_" fill="#FFE1BB" d="M25.8,7.5C25.8,7.5,25.7,7.5,25.8,7.5c-0.1-0.1,0-0.1,0-0.1C25.8,7.4,25.9,7.4,25.8,7.5
		C25.9,7.5,25.8,7.5,25.8,7.5"/>
	<path id="XMLID_169_" fill="#FFE1BB" d="M25.3,8C25.2,8,25.2,8,25.3,8C25.2,7.9,25.2,7.9,25.3,8C25.3,7.9,25.3,7.9,25.3,8
		C25.3,8,25.3,8,25.3,8"/>
	<path id="XMLID_168_" fill="#FFE1BB" d="M23.6,9.6C23.6,9.6,23.6,9.6,23.6,9.6c-0.1-0.1,0-0.1,0-0.1C23.7,9.5,23.7,9.6,23.6,9.6
		C23.7,9.6,23.7,9.6,23.6,9.6"/>
	<path id="XMLID_167_" fill="#FFE1BB" d="M19.3,16.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C19.5,16.2,19.4,16.3,19.3,16.3"/>
	<path id="XMLID_166_" fill="#FFE1BB" d="M19.8,15.7c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1,0,0.1,0.1,0.1,0.2
		C20,15.6,19.9,15.7,19.8,15.7"/>
	<path id="XMLID_165_" fill="#FFE1BB" d="M19.8,16.8c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
		C19.9,16.7,19.9,16.8,19.8,16.8"/>
	<path id="XMLID_164_" fill="#FFE1BB" d="M19.3,17.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C19.5,17.3,19.4,17.4,19.3,17.4"/>
	<path id="XMLID_163_" fill="#FFE1BB" d="M20.4,16.2C20.3,16.2,20.3,16.1,20.4,16.2c-0.1-0.1,0-0.1,0-0.1C20.4,16,20.4,16.1,20.4,16.2
		C20.4,16.1,20.4,16.2,20.4,16.2"/>
	<path id="XMLID_162_" fill="#FFE1BB" d="M20.4,14.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
		C20.5,14,20.5,14.1,20.4,14.1"/>
	<path id="XMLID_161_" fill="#FFE1BB" d="M20.9,14.5C20.9,14.5,20.9,14.5,20.9,14.5c-0.1-0.1,0-0.1,0-0.1C21,14.4,21,14.4,20.9,14.5
		C21,14.5,21,14.5,20.9,14.5"/>
	<path id="XMLID_160_" fill="#FFE1BB" d="M20.9,13.5c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1
		C21,13.4,21,13.5,20.9,13.5"/>
	<path id="XMLID_159_" fill="#FFE1BB" d="M18.7,22.3c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.2,0.1,0.2,0.3
		C19,22.2,18.9,22.3,18.7,22.3"/>
	<path id="XMLID_158_" fill="#FFE1BB" d="M20.3,23.7C20.3,23.6,20.3,23.6,20.3,23.7c0.1-0.1,0.2-0.1,0.2,0
		C20.5,23.7,20.4,23.8,20.3,23.7C20.3,23.8,20.3,23.7,20.3,23.7"/>
	<path id="XMLID_157_" fill="#FFE1BB" d="M19.7,23.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1
		C19.8,23.3,19.7,23.2,19.7,23.1"/>
	<path id="XMLID_156_" fill="#FFE1BB" d="M19.1,22.6c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C19.2,22.8,19.1,22.7,19.1,22.6"/>
	<path id="XMLID_155_" fill="#FFE1BB" d="M19.1,23.7c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C19.2,23.9,19.1,23.8,19.1,23.7"/>
	<path id="XMLID_154_" fill="#FFE1BB" d="M19.3,25c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3
		C19.5,24.9,19.4,25,19.3,25"/>
	<path id="XMLID_153_" fill="#FFE1BB" d="M21.9,27.5C21.9,27.4,22,27.4,21.9,27.5c0.1-0.1,0.1,0,0.1,0C22.1,27.5,22,27.6,21.9,27.5
		C22,27.6,21.9,27.5,21.9,27.5"/>
	<path id="XMLID_152_" fill="#FFE1BB" d="M21.3,26.9c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1s-0.1,0.1-0.1,0.1
		C21.4,27.1,21.3,27,21.3,26.9"/>
	<path id="XMLID_151_" fill="#FFE1BB" d="M20.8,25.3c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1
		C20.9,25.4,20.8,25.4,20.8,25.3"/>
	<path id="XMLID_150_" fill="#FFE1BB" d="M20.8,26.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C20.8,26.5,20.8,26.5,20.8,26.4"/>
	<path id="XMLID_149_" fill="#FFE1BB" d="M20.9,28.8c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3
		C21.2,28.7,21.1,28.8,20.9,28.8"/>
	<path id="XMLID_148_" fill="#FFE1BB" d="M19.7,24.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C19.7,24.4,19.7,24.3,19.7,24.2"/>
	<path id="XMLID_147_" fill="#FFE1BB" d="M19.6,25.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C19.7,25.5,19.6,25.4,19.6,25.3"/>
	<path id="XMLID_146_" fill="#FFE1BB" d="M20.2,24.8c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1
		C20.3,24.9,20.2,24.8,20.2,24.8"/>
	<path id="XMLID_145_" fill="#FFE1BB" d="M20.2,25.9c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2
		C20.3,26,20.2,26,20.2,25.9"/>
	<path id="XMLID_144_" fill="#FFE1BB" d="M28,33.4C28,33.5,28,33.5,28,33.4C27.9,33.5,27.9,33.5,28,33.4C27.9,33.4,27.9,33.4,28,33.4
		C28,33.4,28,33.4,28,33.4"/>
	<path id="XMLID_143_" fill="#FFE1BB" d="M27.5,32.9C27.5,32.9,27.4,32.9,27.5,32.9C27.4,32.9,27.4,32.9,27.5,32.9
		C27.4,32.9,27.4,32.9,27.5,32.9C27.4,32.9,27.5,32.9,27.5,32.9"/>
	<path id="XMLID_142_" fill="#FFE1BB" d="M30.2,34.5C30.2,34.5,30.1,34.6,30.2,34.5C30.1,34.6,30.1,34.5,30.2,34.5
		C30.1,34.5,30.1,34.5,30.2,34.5C30.1,34.5,30.2,34.5,30.2,34.5"/>
	<path id="XMLID_141_" fill="#FFE1BB" d="M31.8,35.1C31.8,35.1,31.8,35.1,31.8,35.1C31.7,35.1,31.7,35.1,31.8,35.1
		C31.7,35.1,31.7,35,31.8,35.1C31.8,35,31.8,35.1,31.8,35.1"/>
	<path id="XMLID_140_" fill="#FFE1BB" d="M33.4,35.6C33.4,35.6,33.4,35.6,33.4,35.6C33.4,35.6,33.4,35.6,33.4,35.6
		C33.4,35.6,33.4,35.6,33.4,35.6C33.4,35.6,33.4,35.6,33.4,35.6"/>
	<path id="XMLID_139_" fill="#FFE1BB" d="M31.8,37c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3c-0.2,0-0.3,0-0.3-0.3
		C31.5,37.1,31.6,37,31.8,37"/>
	<path id="XMLID_138_" fill="#FFE1BB" d="M29.1,34.5C29.1,34.6,29.1,34.6,29.1,34.5c-0.1,0.1-0.1,0-0.1,0C29,34.5,29,34.5,29.1,34.5
		C29.1,34.5,29.1,34.5,29.1,34.5"/>
	<path id="XMLID_137_" fill="#FFE1BB" d="M29.7,35.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1
		C29.7,35,29.7,35,29.7,35.1"/>
	<path id="XMLID_136_" fill="#FFE1BB" d="M30.7,35.1C30.7,35.1,30.7,35.1,30.7,35.1c-0.1,0.1-0.1,0-0.1,0C30.6,35,30.6,35,30.7,35.1
		C30.7,35,30.7,35,30.7,35.1"/>
	<path id="XMLID_135_" fill="#FFE1BB" d="M32.4,35.6C32.4,35.7,32.3,35.7,32.4,35.6c-0.1,0.1-0.1,0-0.1,0
		C32.2,35.6,32.3,35.5,32.4,35.6C32.3,35.5,32.4,35.6,32.4,35.6"/>
	<path id="XMLID_134_" fill="#FFE1BB" d="M31.3,35.6c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0.1-0.1
		C31.3,35.5,31.3,35.5,31.3,35.6"/>
	<path id="XMLID_133_" fill="#FFE1BB" d="M30.3,35.6c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C30.2,35.5,30.3,35.5,30.3,35.6"/>
	<path id="XMLID_132_" fill="#FFE1BB" d="M31.4,36.7c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C31.3,36.5,31.4,36.6,31.4,36.7"/>
	<path id="XMLID_131_" fill="#FFE1BB" d="M30.9,36.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2
		C30.8,36,30.9,36.1,30.9,36.2"/>
	<path id="XMLID_130_" fill="#FFE1BB" d="M19.6,26.4c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3
		C19.7,26.6,19.6,26.5,19.6,26.4"/>
	<path id="XMLID_129_" fill="#FFE1BB" d="M24.7,32.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C24.9,32.4,24.8,32.5,24.7,32.5"/>
	<path id="XMLID_128_" fill="#FFE1BB" d="M21.5,29.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C21.7,29.2,21.6,29.3,21.5,29.3"/>
	<path id="XMLID_127_" fill="#FFE1BB" d="M22,29.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C22.2,29.8,22.1,29.9,22,29.9"/>
	<path id="XMLID_126_" fill="#FFE1BB" d="M22.5,30.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C22.7,30.3,22.7,30.4,22.5,30.4"/>
	<path id="XMLID_125_" fill="#FFE1BB" d="M23.6,31.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C23.8,31.4,23.7,31.4,23.6,31.4"/>
	<path id="XMLID_124_" fill="#FFE1BB" d="M24.2,32c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C24.3,31.9,24.3,32,24.2,32"/>
	<path id="XMLID_123_" fill="#FFE1BB" d="M28,35.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C27.7,35.5,27.8,35.4,28,35.4"/>
	<path id="XMLID_122_" fill="#FFE1BB" d="M27.4,34.9c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		S27.3,34.9,27.4,34.9"/>
	<path id="XMLID_121_" fill="#FFE1BB" d="M26.9,34.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C26.7,34.4,26.8,34.3,26.9,34.3"/>
	<path id="XMLID_120_" fill="#FFE1BB" d="M26.3,33.8c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C26.2,33.9,26.2,33.8,26.3,33.8"/>
	<path id="XMLID_119_" fill="#FFE1BB" d="M25.8,33.3c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C25.6,33.4,25.7,33.3,25.8,33.3"/>
	<path id="XMLID_118_" fill="#FFE1BB" d="M25.3,32.7c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C25.1,32.8,25.2,32.7,25.3,32.7"/>
	<path id="XMLID_117_" fill="#FFE1BB" d="M25.3,31.9C25.2,31.9,25.2,31.8,25.3,31.9c-0.1-0.1,0-0.1,0-0.1
		C25.3,31.8,25.3,31.8,25.3,31.9C25.3,31.8,25.3,31.9,25.3,31.9"/>
	<path id="XMLID_116_" fill="#FFE1BB" d="M24.7,31.3C24.7,31.3,24.7,31.3,24.7,31.3c-0.1-0.1,0-0.1,0-0.1
		C24.7,31.2,24.8,31.2,24.7,31.3C24.8,31.3,24.7,31.3,24.7,31.3"/>
	<path id="XMLID_115_" fill="#FFE1BB" d="M24.2,30.8C24.1,30.8,24.1,30.8,24.2,30.8c-0.1-0.1,0-0.1,0-0.1
		C24.2,30.7,24.2,30.7,24.2,30.8C24.2,30.8,24.2,30.8,24.2,30.8"/>
	<path id="XMLID_114_" fill="#FFE1BB" d="M23.6,30.3C23.6,30.3,23.5,30.2,23.6,30.3c-0.1-0.1,0-0.2,0-0.2
		C23.7,30.1,23.7,30.1,23.6,30.3C23.7,30.2,23.7,30.3,23.6,30.3"/>
	<path id="XMLID_113_" fill="#FFE1BB" d="M22.5,29.2c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
		C22.7,29.2,22.6,29.2,22.5,29.2"/>
	<path id="XMLID_112_" fill="#FFE1BB" d="M22,28.7c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
		C22.1,28.6,22.1,28.7,22,28.7"/>
	<path id="XMLID_111_" fill="#FFE1BB" d="M21.5,28.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C21.6,28.1,21.5,28.2,21.5,28.2"/>
	<path id="XMLID_110_" fill="#FFE1BB" d="M20.9,27.7c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C21.1,27.6,21,27.7,20.9,27.7"/>
	<path id="XMLID_109_" fill="#FFE1BB" d="M20.4,27.1c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C20.6,27.1,20.5,27.1,20.4,27.1"/>
	<path id="XMLID_108_" fill="#FFE1BB" d="M25.8,32.3C25.8,32.3,25.9,32.3,25.8,32.3c0.1,0.1,0,0.1,0,0.1C25.8,32.4,25.7,32.4,25.8,32.3
		C25.7,32.3,25.8,32.3,25.8,32.3"/>
	<path id="XMLID_107_" fill="#FFE1BB" d="M26.3,32.8C26.4,32.8,26.4,32.9,26.3,32.8c0.1,0.1,0,0.2,0,0.2C26.3,33,26.3,32.9,26.3,32.8
		C26.3,32.9,26.3,32.8,26.3,32.8"/>
	<path id="XMLID_106_" fill="#FFE1BB" d="M26.9,33.3c0.1,0,0.1,0,0.1,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1-0.1
		C26.8,33.4,26.8,33.3,26.9,33.3"/>
	<path id="XMLID_105_" fill="#FFE1BB" d="M27.4,33.9c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1
		C27.3,33.9,27.4,33.9,27.4,33.9"/>
	<path id="XMLID_104_" fill="#FFE1BB" d="M28,34.4c0.1,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.1-0.1
		C27.8,34.4,27.9,34.4,28,34.4"/>
	<path id="XMLID_103_" fill="#FFE1BB" d="M28.5,34.9c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C28.3,35,28.4,34.9,28.5,34.9"/>
	<path id="XMLID_102_" fill="#FFE1BB" d="M29,35.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C28.9,35.5,28.9,35.4,29,35.4"/>
	<path id="XMLID_101_" fill="#FFE1BB" d="M29.6,35.9c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2
		C29.4,36,29.5,35.9,29.6,35.9"/>
	<path id="XMLID_100_" fill="#FFE1BB" d="M35.6,38c-0.1,0-0.3-0.1-0.3-0.3c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2
		C35.8,37.9,35.7,38,35.6,38"/>
	<path id="XMLID_99_" fill="#FFE1BB" d="M34.5,38c-0.2,0-0.3,0-0.3-0.3c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3
		C34.7,38,34.7,38,34.5,38"/>
	<path id="XMLID_98_" fill="#FFE1BB" d="M32.8,37.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C33.1,37.4,33,37.5,32.8,37.5"/>
	<path id="XMLID_97_" fill="#FFE1BB" d="M33.9,37.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C34.1,37.4,34,37.4,33.9,37.4"/>
	<path id="XMLID_96_" fill="#FFE1BB" d="M35,37.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C35.2,37.3,35.1,37.4,35,37.4"/>
	<path id="XMLID_95_" fill="#FFE1BB" d="M36.1,37.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C36.3,37.3,36.2,37.4,36.1,37.4"/>
	<path id="XMLID_94_" fill="#FFE1BB" d="M32.3,36.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C32.5,36.8,32.4,36.9,32.3,36.9"/>
	<path id="XMLID_93_" fill="#FFE1BB" d="M33.4,36.9c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C33.5,36.8,33.5,36.9,33.4,36.9"/>
	<path id="XMLID_92_" fill="#FFE1BB" d="M34.5,36.8c-0.1,0-0.1-0.1-0.1-0.1s0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1S34.5,36.8,34.5,36.8
		"/>
	<path id="XMLID_91_" fill="#FFE1BB" d="M35.6,36.8c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1
		C35.7,36.8,35.6,36.8,35.6,36.8"/>
	<path id="XMLID_90_" fill="#FFE1BB" d="M36.1,36.2C36.1,36.2,36,36.2,36.1,36.2c-0.1-0.1,0-0.1,0-0.1C36.1,36.1,36.2,36.1,36.1,36.2
		C36.2,36.2,36.1,36.2,36.1,36.2"/>
	<path id="XMLID_89_" fill="#FFE1BB" d="M31.8,36.3c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2
		C31.9,36.2,31.8,36.3,31.8,36.3"/>
	<path id="XMLID_88_" fill="#FFE1BB" d="M32.8,36.3c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1
		C33,36.2,32.9,36.3,32.8,36.3"/>
	<path id="XMLID_87_" fill="#FFE1BB" d="M33.9,36.2C33.9,36.2,33.8,36.2,33.9,36.2c-0.1-0.1,0-0.2,0-0.2C34,36.1,34,36.1,33.9,36.2
		C34,36.2,34,36.2,33.9,36.2"/>
	<path id="XMLID_86_" fill="#FFE1BB" d="M35,36.2C35,36.2,35,36.2,35,36.2c-0.1-0.1,0-0.1,0-0.1C35,36.1,35.1,36.1,35,36.2
		C35.1,36.2,35,36.2,35,36.2"/>
	<path id="XMLID_85_" fill="#FFE1BB" d="M47.8,5.8c0-0.1,0.1-0.3,0.2-0.3c0.2,0,0.2,0,0.2,0.3C48.3,5.9,48.2,6,48,6
		C47.9,6,47.8,5.9,47.8,5.8"/>
	<path id="XMLID_84_" fill="#FFE1BB" d="M48.3,6.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.3-0.3,0.3
		C48.4,6.6,48.3,6.5,48.3,6.3"/>
	<path id="XMLID_83_" fill="#FFE1BB" d="M48.9,6.9c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.3,0.1,0.3,0.2c0,0.1-0.1,0.2-0.3,0.2
		C49,7.1,48.9,7,48.9,6.9"/>
	<path id="XMLID_82_" fill="#FFE1BB" d="M49.4,7.4c0-0.1,0.1-0.3,0.3-0.3c0.1,0,0.3,0.1,0.3,0.3c0,0.1-0.1,0.3-0.3,0.3
		C49.5,7.7,49.4,7.6,49.4,7.4"/>
	<path id="XMLID_81_" fill="#FFE1BB" d="M49.9,8c0-0.1,0.1-0.3,0.3-0.3c0.2,0,0.2,0,0.2,0.3c0,0.1-0.1,0.3-0.2,0.3
		C50.1,8.2,49.9,8.1,49.9,8"/>
	<path id="XMLID_80_" fill="#FFE1BB" d="M50.5,8.5c0-0.1,0.1-0.3,0.3-0.3c0.3,0,0.3,0,0.3,0.3c0,0.1-0.1,0.2-0.3,0.2
		C50.6,8.8,50.5,8.6,50.5,8.5"/>
	<path id="XMLID_79_" fill="#FFE1BB" d="M51.8,25.9C51.8,25.9,51.9,25.9,51.8,25.9C51.9,25.8,51.8,25.8,51.8,25.9
		C51.8,25.8,51.8,25.8,51.8,25.9C51.8,25.9,51.8,25.9,51.8,25.9"/>
	<path id="XMLID_78_" fill="#BC9B6A" d="M24.6,31.5L24.2,33l-0.3,0.2c0,0,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.2,0.3,0.2l0.9,1.1
		c0,0-0.1,0.4,0,0.4c0.1,0.1,0.6,0,0.6,0l-0.2-0.3l0.3-0.3l-0.1,0L25.2,33c-0.2-0.1-0.4,0-0.4,0l0.4-1.4l0.2-0.2l-0.4-0.3
		c0,0-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2-0.1s-0.4-0.1-0.5,0.2C24.5,31.1,24.6,31.5,24.6,31.5"/>
	<path id="XMLID_77_" fill="#BC9B6A" d="M28.3,33.8c0,0-0.4,0-0.6-0.1c-0.1-0.1,0-0.4,0-0.4l-0.9-1.1c0,0-0.1-0.1-0.2-0.2
		c-0.2-0.1-0.6,0.1-0.6,0.1l0.3-0.2l0.5-1.5c0,0-0.2-0.4,0-0.6c0.1-0.2,0.5-0.1,0.5-0.1s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2
		l0.4,0.3l-0.2,0.1l-0.4,1.4c0,0,0.3-0.1,0.4,0l1.1,1.3l0.1,0l-0.3,0.3L28.3,33.8"/>
	<path id="XMLID_76_" fill="#BC9B6A" d="M30.3,33c0,0-0.4,0-0.5-0.1c-0.1-0.1,0-0.4,0-0.4L29,31.4c0,0-0.1-0.1-0.2-0.2
		c-0.2-0.1-0.6,0-0.6,0l0.3-0.2l0.5-1.5c0,0-0.1-0.4,0-0.6c0.1-0.2,0.5-0.1,0.5-0.1S29.4,29,29.3,29c-0.1,0,0,0.2,0,0.2l0.4,0.4
		l-0.2,0.1L29,31c0,0,0.3,0,0.4,0.1l1,1.3l0.1,0l-0.3,0.3L30.3,33"/>
	<path id="XMLID_75_" fill="#BC9B6A" d="M32.3,32.4c0,0-0.4,0-0.5-0.1c-0.1-0.1,0-0.4,0-0.4L31,30.7c0,0-0.1-0.1-0.2-0.2
		c-0.2-0.1-0.6,0-0.6,0l0.3-0.2l0.6-1.4c0,0-0.1-0.4,0-0.6c0.1-0.2,0.5-0.1,0.5-0.1s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2l0.4,0.4
		l-0.2,0.1l-0.6,1.3c0,0,0.3,0,0.4,0.1l0.9,1.4l0.1,0l-0.3,0.2L32.3,32.4"/>
	<path id="XMLID_74_" fill="#BC9B6A" d="M34.3,32c0,0-0.4,0-0.5-0.2c-0.1-0.1,0.1-0.4,0.1-0.4l-0.7-1.2c0,0,0-0.1-0.2-0.2
		c-0.2-0.1-0.6,0-0.6,0l0.3-0.2l0.7-1.4c0,0-0.1-0.4,0-0.6c0.2-0.2,0.5-0.1,0.5-0.1s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2l0.3,0.4
		l-0.2,0.1l-0.6,1.2c0,0,0.3,0,0.4,0.1l0.8,1.4l0.1,0l-0.4,0.2L34.3,32"/>
	<path id="XMLID_73_" fill="#BC9B6A" d="M36.2,31.7c0,0-0.4-0.1-0.5-0.2c-0.1-0.1,0.1-0.4,0.1-0.4l-0.6-1.2c0,0,0-0.1-0.2-0.2
		c-0.2-0.1-0.6-0.1-0.6-0.1l0.3-0.1l0.8-1.3c0,0-0.1-0.4,0.1-0.6c0.2-0.2,0.5,0,0.5,0s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2l0.3,0.4
		L36,28.3l-0.7,1.2c0,0,0.3,0,0.4,0.1l0.7,1.5l0.1,0l-0.4,0.2L36.2,31.7"/>
	<path id="XMLID_72_" fill="#BC9B6A" d="M38.2,31.6c0,0-0.4-0.1-0.5-0.2c-0.1-0.1,0.1-0.4,0.1-0.4l-0.5-1.2c0,0,0-0.1-0.2-0.2
		c-0.1-0.1-0.6-0.1-0.6-0.1l0.3-0.1l0.8-1.3c0,0,0-0.4,0.1-0.5c0.2-0.2,0.5,0,0.5,0s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2l0.3,0.4
		l-0.2,0.1l-0.8,1.2c0,0,0.3,0,0.4,0.2l0.6,1.5l0.1,0l-0.4,0.2L38.2,31.6"/>
	<path id="XMLID_71_" fill="#BC9B6A" d="M40.2,31.6c0,0-0.4-0.1-0.5-0.2c-0.1-0.1,0.1-0.4,0.1-0.4l-0.5-1.3c0,0,0-0.1-0.2-0.3
		c-0.1-0.1-0.6-0.1-0.6-0.1l0.4-0.1l0.9-1.2c0,0,0-0.4,0.1-0.5c0.2-0.2,0.5,0,0.5,0s-0.1,0-0.2,0.1c-0.1,0-0.1,0.2-0.1,0.2l0.3,0.4
		l-0.3,0.1l-0.8,1.1c0,0,0.3,0,0.4,0.2l0.6,1.5l0.1,0.1l-0.4,0.1L40.2,31.6"/>
	<path id="XMLID_70_" fill="#BC9B6A" d="M42.2,31.8c0,0-0.4-0.1-0.5-0.3c-0.1-0.1,0.1-0.4,0.1-0.4l-0.4-1.3c0,0,0-0.1-0.1-0.3
		c-0.1-0.1-0.6-0.2-0.6-0.2l0.4-0.1l1-1.2c0,0,0-0.4,0.2-0.5c0.2-0.2,0.5,0.1,0.5,0.1s-0.1,0-0.2,0.1c-0.1,0-0.1,0.2-0.1,0.2
		l0.2,0.5l-0.3,0l-0.9,1.1c0,0,0.3,0,0.4,0.2l0.5,1.6l0.1,0.1l-0.4,0.1L42.2,31.8"/>
	<path id="XMLID_69_" fill="#BC9B6A" d="M44.2,32.2c0,0-0.4-0.2-0.5-0.3c-0.1-0.1,0.2-0.4,0.2-0.4l-0.3-1.3c0,0,0-0.1-0.1-0.3
		c-0.1-0.2-0.6-0.2-0.6-0.2l0.4-0.1l1.1-1.1c0,0,0-0.4,0.2-0.5c0.2-0.2,0.5,0.1,0.5,0.1s-0.1,0-0.2,0c-0.1,0-0.1,0.2-0.1,0.2
		l0.2,0.5l-0.3,0l-0.9,1c0,0,0.3,0.1,0.4,0.2l0.4,1.6l0.1,0.1l-0.4,0.1L44.2,32.2"/>
	<path id="XMLID_68_" fill="#BC9B6A" d="M46.2,32.7c0,0-0.4-0.2-0.4-0.3c-0.1-0.1,0.2-0.4,0.2-0.4l-0.3-1.3c0,0,0-0.1-0.1-0.3
		c-0.1-0.2-0.6-0.2-0.6-0.2l0.4,0l1.1-1.1c0,0,0-0.4,0.2-0.5c0.2-0.1,0.5,0.1,0.5,0.1s-0.1,0-0.2,0c-0.1,0-0.1,0.2-0.1,0.2l0.2,0.5
		l-0.3,0l-1,1c0,0,0.3,0.1,0.4,0.2l0.3,1.6l0.1,0.1l-0.4,0.1L46.2,32.7"/>
	<path id="XMLID_67_" fill="#BC9B6A" d="M48.2,33.4c0,0-0.4-0.2-0.4-0.4c-0.1-0.1,0.2-0.4,0.2-0.4l-0.2-1.4c0,0,0-0.1-0.1-0.3
		c-0.1-0.2-0.6-0.3-0.6-0.3l0.4,0l1.2-1c0,0,0.1-0.4,0.3-0.5c0.2-0.1,0.5,0.1,0.5,0.1s-0.1,0-0.2,0c-0.1,0-0.1,0.2-0.1,0.2l0.2,0.5
		L49,30L48,31c0,0,0.3,0.1,0.3,0.3l0.2,1.7l0.1,0.1l-0.4,0.1L48.2,33.4"/>
	<path id="XMLID_66_" fill="#BC9B6A" d="M50.9,30.6l-1.3,1l-0.4,0c0,0,0.5,0.1,0.6,0.3c0.1,0.2,0.1,0.3,0.1,0.3l0.1,1.4
		c0,0-0.3,0.2-0.2,0.4c0.1,0.2,0.4,0.4,0.4,0.4l0-0.4l0.4,0l-0.1-0.1l-0.1-1.7c-0.1-0.2-0.3-0.3-0.3-0.3l1.1-0.9l0.3,0l-0.1-0.5
		c0,0,0-0.2,0.1-0.2c0.1,0,0.2,0,0.2,0s-0.3-0.3-0.5-0.2C51,30.1,50.9,30.6,50.9,30.6"/>
	<path id="XMLID_65_" fill="#BC9B6A" d="M52.7,31.4l-1.3,1l-0.4,0c0,0,0.5,0.1,0.6,0.3c0.1,0.2,0.1,0.3,0.1,0.3l0.1,1.4
		c0,0-0.3,0.2-0.2,0.4c0.1,0.2,0.4,0.4,0.4,0.4l0-0.4l0.4,0l-0.1-0.1L52.2,33c-0.1-0.2-0.3-0.3-0.3-0.3l1.1-0.9l0.3,0l-0.2-0.5
		c0,0,0-0.2,0.1-0.2c0.1,0,0.2,0,0.2,0s-0.3-0.3-0.5-0.2C52.8,31,52.7,31.4,52.7,31.4"/>
	<path id="XMLID_64_" fill="#BC9B6A" d="M52.6,9.5L53,7.9l0.3-0.2c0,0-0.4,0.2-0.6,0.1c-0.2-0.1-0.3-0.2-0.3-0.2l-0.9-1.1
		c0,0,0.1-0.4,0-0.4c-0.1-0.1-0.6,0-0.6,0l0.2,0.3l-0.3,0.3l0.1,0L52,8c0.2,0.1,0.4,0,0.4,0l-0.4,1.4l-0.2,0.2l0.4,0.3
		c0,0,0.1,0.2,0,0.2c-0.1,0.1-0.1,0.1-0.1,0.1s0.4,0.1,0.5-0.2C52.8,9.9,52.6,9.5,52.6,9.5"/>
	<path id="XMLID_63_" fill="#BC9B6A" d="M48.9,7.1c0,0,0.4,0,0.6,0.1c0.1,0.1,0,0.4,0,0.4l0.9,1.1c0,0,0.1,0.1,0.2,0.2
		c0.2,0.1,0.6-0.1,0.6-0.1l-0.3,0.2l-0.5,1.5c0,0,0.2,0.4,0,0.6c-0.1,0.2-0.5,0.1-0.5,0.1s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2
		l-0.4-0.3l0.2-0.1l0.4-1.4c0,0-0.3,0.1-0.4,0l-1.1-1.3l-0.1,0l0.3-0.3L48.9,7.1"/>
	<path id="XMLID_62_" fill="#BC9B6A" d="M46.9,7.9c0,0,0.4,0,0.5,0.1c0.1,0.1,0,0.4,0,0.4l0.8,1.1c0,0,0.1,0.1,0.2,0.2
		c0.2,0.1,0.6,0,0.6,0l-0.3,0.2l-0.5,1.5c0,0,0.1,0.4,0,0.6c-0.1,0.2-0.5,0.1-0.5,0.1s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2l-0.4-0.4
		l0.2-0.1l0.5-1.3c0,0-0.3,0-0.4-0.1l-1-1.3l-0.1,0l0.3-0.3L46.9,7.9"/>
	<path id="XMLID_61_" fill="#BC9B6A" d="M44.9,8.5c0,0,0.4,0,0.5,0.1c0.1,0.1,0,0.4,0,0.4l0.7,1.1c0,0,0.1,0.1,0.2,0.2
		c0.2,0.1,0.6,0,0.6,0l-0.3,0.2L46.1,12c0,0,0.1,0.4,0,0.6c-0.1,0.2-0.5,0.1-0.5,0.1s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2L45.4,12
		l0.2-0.1l0.6-1.3c0,0-0.3,0-0.4-0.1l-0.9-1.4l-0.1,0l0.3-0.2L44.9,8.5"/>
	<path id="XMLID_60_" fill="#BC9B6A" d="M43,9c0,0,0.4,0,0.5,0.2c0.1,0.1-0.1,0.4-0.1,0.4l0.7,1.2c0,0,0,0.1,0.2,0.2
		c0.2,0.1,0.6,0,0.6,0l-0.3,0.2l-0.7,1.4c0,0,0.1,0.4,0,0.6c-0.2,0.2-0.5,0.1-0.5,0.1s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2l-0.3-0.4
		l0.2-0.1l0.6-1.2c0,0-0.3,0-0.4-0.1l-0.8-1.4l-0.1,0l0.4-0.2L43,9"/>
	<path id="XMLID_59_" fill="#BC9B6A" d="M41,9.3c0,0,0.4,0.1,0.5,0.2c0.1,0.1-0.1,0.4-0.1,0.4l0.6,1.2c0,0,0,0.1,0.2,0.2
		c0.2,0.1,0.6,0.1,0.6,0.1l-0.3,0.1l-0.8,1.3c0,0,0.1,0.4-0.1,0.6c-0.2,0.2-0.5,0-0.5,0s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2L41,12.7
		l0.2-0.1l0.7-1.2c0,0-0.3,0-0.4-0.1l-0.7-1.5l-0.1,0l0.4-0.2L41,9.3"/>
	<path id="XMLID_58_" fill="#BC9B6A" d="M39,9.4c0,0,0.4,0.1,0.5,0.2c0.1,0.1-0.1,0.4-0.1,0.4l0.5,1.2c0,0,0,0.1,0.2,0.2
		c0.1,0.1,0.6,0.1,0.6,0.1l-0.3,0.1L39.5,13c0,0,0,0.4-0.1,0.5c-0.2,0.2-0.5,0-0.5,0s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2l-0.3-0.4
		l0.2-0.1l0.7-1.2c0,0-0.3,0-0.4-0.2l-0.6-1.5l-0.1-0.1l0.4-0.2L39,9.4"/>
	<path id="XMLID_57_" fill="#BC9B6A" d="M37,9.3c0,0,0.4,0.1,0.5,0.2c0.1,0.1-0.1,0.4-0.1,0.4l0.5,1.3c0,0,0,0.1,0.2,0.3
		c0.1,0.1,0.6,0.1,0.6,0.1l-0.4,0.1L37.3,13c0,0,0,0.4-0.1,0.5c-0.2,0.2-0.5,0-0.5,0s0.1,0,0.2-0.1c0.1,0,0.1-0.2,0.1-0.2l-0.3-0.4
		l0.2-0.1l0.8-1.1c0,0-0.3,0-0.4-0.2l-0.6-1.5l-0.1-0.1l0.4-0.1L37,9.3"/>
	<path id="XMLID_56_" fill="#BC9B6A" d="M35,9.1c0,0,0.4,0.1,0.5,0.3c0.1,0.1-0.1,0.4-0.1,0.4l0.4,1.3c0,0,0,0.1,0.2,0.3
		c0.1,0.1,0.6,0.2,0.6,0.2l-0.4,0.1l-1,1.2c0,0,0,0.4-0.2,0.5c-0.2,0.2-0.5-0.1-0.5-0.1s0.1,0,0.2-0.1c0.1,0,0.1-0.2,0.1-0.2
		l-0.2-0.5l0.3,0l0.9-1.1c0,0-0.3,0-0.4-0.2l-0.5-1.6l-0.1-0.1l0.4-0.1L35,9.1"/>
	<path id="XMLID_55_" fill="#BC9B6A" d="M33,8.8c0,0,0.4,0.2,0.5,0.3c0.1,0.1-0.2,0.4-0.2,0.4l0.3,1.3c0,0,0,0.1,0.1,0.3
		c0.1,0.2,0.6,0.2,0.6,0.2L34,11.3l-1.1,1.1c0,0,0,0.4-0.2,0.5c-0.2,0.2-0.5-0.1-0.5-0.1s0.1,0,0.2-0.1c0.1,0,0.1-0.2,0.1-0.2
		l-0.2-0.5l0.3,0l0.9-1c0,0-0.3-0.1-0.4-0.2l-0.4-1.6l-0.1-0.1l0.4-0.1L33,8.8"/>
	<path id="XMLID_54_" fill="#BC9B6A" d="M31,8.3c0,0,0.4,0.2,0.5,0.3C31.6,8.7,31.3,9,31.3,9l0.2,1.3c0,0,0,0.1,0.1,0.3
		c0.1,0.2,0.6,0.2,0.6,0.2l-0.4,0L30.7,12c0,0,0,0.4-0.2,0.5c-0.2,0.1-0.5-0.1-0.5-0.1s0.1,0,0.2,0c0.1,0,0.1-0.2,0.1-0.2l-0.2-0.4
		l0.2-0.1l1.1-1c0,0-0.3-0.1-0.4-0.2l-0.3-1.6l-0.1-0.1l0.4-0.1L31,8.3"/>
	<path id="XMLID_53_" fill="#BC9B6A" d="M29,7.5c0,0,0.4,0.2,0.4,0.4c0.1,0.1-0.2,0.4-0.2,0.4l0.2,1.4c0,0,0,0.1,0.1,0.3
		c0.1,0.2,0.6,0.3,0.6,0.3l-0.4,0l-1.2,1c0,0-0.1,0.4-0.3,0.5c-0.2,0.1-0.5-0.1-0.5-0.1s0.1,0,0.2,0c0.1,0,0.1-0.2,0.1-0.2l-0.2-0.5
		l0.3,0l1.1-1c0,0-0.3-0.1-0.3-0.3L28.7,8L28.6,8l0.4-0.1L29,7.5"/>
	<path id="XMLID_52_" fill="#BC9B6A" d="M26.3,10.4l1.3-1l0.4,0c0,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.1-0.3-0.1-0.3l-0.1-1.4
		c0,0,0.3-0.2,0.2-0.4C27.4,6.9,27,6.6,27,6.6L27,7l-0.4,0l0.1,0.1l0.1,1.7c0.1,0.2,0.3,0.3,0.3,0.3L26,10l-0.3,0l0.2,0.5
		c0,0,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.2,0s0.3,0.3,0.5,0.2C26.2,10.8,26.3,10.4,26.3,10.4"/>
	<path id="XMLID_51_" fill="#BC9B6A" d="M24.5,9.5l1.3-1l0.4,0c0,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.1-0.3-0.1-0.3l-0.1-1.4
		c0,0,0.3-0.2,0.2-0.4c-0.1-0.2-0.4-0.4-0.4-0.4l0,0.4l-0.4,0l0.1,0.1L25,8c0.1,0.2,0.3,0.3,0.3,0.3l-1.1,0.9l-0.3,0L24,9.7
		c0,0,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.2,0s0.3,0.3,0.5,0.2C24.4,9.9,24.5,9.5,24.5,9.5"/>
	<path id="XMLID_50_" fill="#F47836" d="M24.5,31.5L24.1,33l-0.3,0.2c0,0,0.4-0.2,0.6-0.1c0.2,0.1,0.3,0.2,0.3,0.2l0.9,1.1
		c0,0-0.1,0.4,0,0.4c0.1,0.1,0.6,0,0.6,0L26,34.6l0.3-0.3l-0.1,0L25,33c-0.2-0.1-0.4,0-0.4,0l0.4-1.4l0.2-0.2l-0.4-0.3
		c0,0-0.1-0.2,0-0.2c0.1-0.1,0.2-0.1,0.2-0.1s-0.4-0.1-0.5,0.2C24.3,31.1,24.5,31.5,24.5,31.5"/>
	<path id="XMLID_49_" fill="#F47836" d="M28.2,33.8c0,0-0.4,0-0.6-0.1c-0.1-0.1,0-0.4,0-0.4l-0.9-1.1c0,0-0.1-0.1-0.2-0.2
		c-0.2-0.1-0.6,0.1-0.6,0.1l0.3-0.2l0.5-1.5c0,0-0.2-0.4,0-0.6c0.1-0.2,0.5-0.1,0.5-0.1s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2
		l0.4,0.3l-0.2,0.1l-0.4,1.4c0,0,0.3-0.1,0.4,0l1.1,1.3l0.1,0l-0.3,0.3L28.2,33.8"/>
	<path id="XMLID_48_" fill="#F47836" d="M30.1,33c0,0-0.4,0-0.5-0.1c-0.1-0.1,0-0.4,0-0.4l-0.8-1.1c0,0-0.1-0.1-0.2-0.2
		c-0.2-0.1-0.6,0-0.6,0l0.3-0.2l0.5-1.5c0,0-0.1-0.4,0-0.6c0.1-0.2,0.5-0.1,0.5-0.1S29.2,29,29.1,29c-0.1,0,0,0.2,0,0.2l0.4,0.4
		l-0.2,0.1L28.8,31c0,0,0.3,0,0.4,0.1l1,1.3l0.1,0l-0.3,0.3L30.1,33"/>
	<path id="XMLID_47_" fill="#F47836" d="M32.1,32.4c0,0-0.4,0-0.5-0.1c-0.1-0.1,0-0.4,0-0.4l-0.7-1.1c0,0-0.1-0.1-0.2-0.2
		c-0.2-0.1-0.6,0-0.6,0l0.3-0.2l0.6-1.4c0,0-0.1-0.4,0-0.6c0.1-0.2,0.5-0.1,0.5-0.1s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2l0.4,0.4
		l-0.2,0.1l-0.5,1.3c0,0,0.3,0,0.4,0.1l0.9,1.4l0.1,0l-0.3,0.2L32.1,32.4"/>
	<path id="XMLID_46_" fill="#F47836" d="M34.1,32c0,0-0.4,0-0.5-0.2c-0.1-0.1,0.1-0.4,0.1-0.4l-0.7-1.2c0,0,0-0.1-0.2-0.2
		c-0.2-0.1-0.6,0-0.6,0l0.3-0.2l0.7-1.4c0,0-0.1-0.4,0-0.6c0.2-0.2,0.5-0.1,0.5-0.1s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2l0.3,0.4
		l-0.2,0.1L33,29.9c0,0,0.3,0,0.4,0.1l0.8,1.4l0.1,0l-0.4,0.2L34.1,32"/>
	<path id="XMLID_45_" fill="#F47836" d="M36.1,31.7c0,0-0.4-0.1-0.5-0.2c-0.1-0.1,0.1-0.4,0.1-0.4L35,29.9c0,0,0-0.1-0.2-0.2
		c-0.2-0.1-0.6-0.1-0.6-0.1l0.3-0.1l0.8-1.3c0,0-0.1-0.4,0.1-0.6c0.2-0.2,0.5,0,0.5,0s-0.1,0.1-0.2,0.1c-0.1,0,0,0.2,0,0.2l0.3,0.4
		l-0.2,0.1l-0.7,1.2c0,0,0.3,0,0.4,0.1l0.7,1.5l0.1,0l-0.4,0.2L36.1,31.7"/>
	<path id="XMLID_44_" fill="#F47836" d="M38,31.6c0,0-0.4-0.1-0.5-0.2c-0.1-0.1,0.1-0.4,0.1-0.4l-0.5-1.2c0,0,0-0.1-0.2-0.2
		c-0.1-0.1-0.6-0.1-0.6-0.1l0.3-0.1l0.8-1.3c0,0,0-0.4,0.1-0.5c0.2-0.2,0.5,0,0.5,0S38,27.5,38,27.5c-0.1,0-0.1,0.2-0.1,0.2l0.3,0.4
		L38,28.2l-0.7,1.2c0,0,0.3,0,0.4,0.2l0.6,1.5l0.1,0l-0.4,0.2L38,31.6"/>
	<path id="XMLID_43_" fill="#F47836" d="M40,31.6c0,0-0.4-0.1-0.5-0.2c-0.1-0.1,0.1-0.4,0.1-0.4l-0.5-1.3c0,0,0-0.1-0.2-0.3
		c-0.1-0.1-0.6-0.1-0.6-0.1l0.4-0.1l0.9-1.2c0,0,0-0.4,0.1-0.5c0.2-0.2,0.5,0,0.5,0s-0.1,0-0.2,0.1c-0.1,0-0.1,0.2-0.1,0.2l0.3,0.4
		l-0.3,0.1l-0.8,1.1c0,0,0.3,0,0.4,0.2l0.5,1.5l0.1,0.1l-0.4,0.1L40,31.6"/>
	<path id="XMLID_42_" fill="#F47836" d="M42,31.8c0,0-0.4-0.1-0.5-0.3c-0.1-0.1,0.1-0.4,0.1-0.4l-0.4-1.3c0,0,0-0.1-0.1-0.3
		c-0.1-0.1-0.6-0.2-0.6-0.2l0.4-0.1l1-1.2c0,0,0-0.4,0.2-0.5c0.2-0.2,0.5,0.1,0.5,0.1s-0.1,0-0.2,0.1c-0.1,0-0.1,0.2-0.1,0.2
		l0.2,0.5l-0.3,0l-0.9,1.1c0,0,0.3,0,0.4,0.2l0.5,1.6l0.1,0.1l-0.4,0.1L42,31.8"/>
	<path id="XMLID_41_" fill="#F47836" d="M44,32.2c0,0-0.4-0.2-0.5-0.3c-0.1-0.1,0.2-0.4,0.2-0.4l-0.3-1.3c0,0,0-0.1-0.1-0.3
		c-0.1-0.2-0.6-0.2-0.6-0.2l0.4-0.1l1.1-1.1c0,0,0-0.4,0.2-0.5c0.2-0.2,0.5,0.1,0.5,0.1s-0.1,0-0.2,0c-0.1,0-0.1,0.2-0.1,0.2
		l0.2,0.5l-0.3,0l-0.9,1c0,0,0.3,0.1,0.4,0.2l0.4,1.6l0.1,0.1l-0.4,0.1L44,32.2"/>
	<path id="XMLID_40_" fill="#F47836" d="M46,32.7c0,0-0.4-0.2-0.4-0.3c-0.1-0.1,0.2-0.4,0.2-0.4l-0.3-1.3c0,0,0-0.1-0.1-0.3
		c-0.1-0.2-0.6-0.2-0.6-0.2l0.4,0l1.1-1.1c0,0,0-0.4,0.2-0.5c0.2-0.1,0.5,0.1,0.5,0.1s-0.1,0-0.2,0c-0.1,0-0.1,0.2-0.1,0.2l0.2,0.5
		l-0.3,0l-1,1c0,0,0.3,0.1,0.4,0.2l0.3,1.6l0.1,0.1L46,32.4L46,32.7"/>
	<path id="XMLID_39_" fill="#F47836" d="M48,33.4c0,0-0.4-0.2-0.4-0.4c-0.1-0.1,0.2-0.4,0.2-0.4l-0.2-1.4c0,0,0-0.1-0.1-0.3
		c-0.1-0.2-0.6-0.3-0.6-0.3l0.4,0l1.2-1c0,0,0.1-0.4,0.3-0.5c0.2-0.1,0.5,0.1,0.5,0.1s-0.1,0-0.2,0c-0.1,0-0.1,0.2-0.1,0.2l0.2,0.5
		l-0.3,0L47.8,31c0,0,0.3,0.1,0.3,0.3l0.2,1.7l0.1,0.1L48,33.1L48,33.4"/>
	<path id="XMLID_38_" fill="#F47836" d="M50.7,30.6l-1.3,1l-0.4,0c0,0,0.5,0.1,0.6,0.3c0.1,0.2,0.1,0.3,0.1,0.3l0.1,1.4
		c0,0-0.3,0.2-0.2,0.4c0.1,0.2,0.4,0.4,0.4,0.4l0-0.4l0.4,0l-0.1-0.1l-0.1-1.7c-0.1-0.2-0.3-0.3-0.3-0.3l1.1-0.9l0.3,0l-0.1-0.5
		c0,0,0-0.2,0.1-0.2c0.1,0,0.2,0,0.2,0S51.2,29.9,51,30C50.8,30.1,50.7,30.6,50.7,30.6"/>
	<path id="XMLID_37_" fill="#F47836" d="M52.5,31.4l-1.3,1l-0.4,0c0,0,0.5,0.1,0.6,0.3c0.1,0.2,0.1,0.3,0.1,0.3l0.1,1.4
		c0,0-0.3,0.2-0.2,0.4c0.1,0.2,0.4,0.4,0.4,0.4l0-0.4l0.4,0l-0.1-0.1L52.1,33c-0.1-0.2-0.3-0.3-0.3-0.3l1.1-0.9l0.3,0L53,31.3
		c0,0,0-0.2,0.1-0.2c0.1,0,0.2,0,0.2,0s-0.3-0.3-0.5-0.2C52.6,31,52.5,31.4,52.5,31.4"/>
	<path id="XMLID_36_" fill="#F47836" d="M52.4,9.5l0.4-1.6l0.3-0.2c0,0-0.4,0.2-0.6,0.1c-0.2-0.1-0.3-0.2-0.3-0.2l-0.9-1.1
		c0,0,0.1-0.4,0-0.4c-0.1-0.1-0.6,0-0.6,0l0.2,0.3l-0.3,0.3l0.1,0L51.8,8C52,8,52.3,8,52.3,8l-0.4,1.4l-0.2,0.2l0.4,0.3
		c0,0,0.1,0.2,0,0.2c-0.1,0.1-0.2,0.1-0.2,0.1s0.4,0.1,0.5-0.2C52.6,9.9,52.4,9.5,52.4,9.5"/>
	<path id="XMLID_35_" fill="#F47836" d="M48.7,7.1c0,0,0.4,0,0.6,0.1c0.1,0.1,0,0.4,0,0.4l0.9,1.1c0,0,0.1,0.1,0.2,0.2
		C50.6,9,51,8.8,51,8.8l-0.3,0.2l-0.5,1.5c0,0,0.2,0.4,0,0.6c-0.1,0.2-0.5,0.1-0.5,0.1s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2l-0.4-0.3
		l0.2-0.1l0.4-1.4c0,0-0.3,0.1-0.4,0l-1.1-1.3l-0.1,0l0.3-0.3L48.7,7.1"/>
	<path id="XMLID_34_" fill="#F47836" d="M46.7,7.9c0,0,0.4,0,0.5,0.1c0.1,0.1,0,0.4,0,0.4l0.8,1.1c0,0,0.1,0.1,0.2,0.2
		c0.2,0.1,0.6,0,0.6,0l-0.3,0.2l-0.5,1.5c0,0,0.1,0.4,0,0.6c-0.1,0.2-0.5,0.1-0.5,0.1s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2l-0.4-0.4
		l0.2-0.1l0.5-1.3c0,0-0.3,0-0.4-0.1l-1-1.3l-0.1,0l0.3-0.3L46.7,7.9"/>
	<path id="XMLID_33_" fill="#F47836" d="M44.8,8.5c0,0,0.4,0,0.5,0.1c0.1,0.1,0,0.4,0,0.4l0.7,1.1c0,0,0.1,0.1,0.2,0.2
		c0.2,0.1,0.6,0,0.6,0l-0.3,0.2L45.9,12c0,0,0.1,0.4,0,0.6c-0.1,0.2-0.5,0.1-0.5,0.1s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2L45.2,12
		l0.2-0.1l0.6-1.3c0,0-0.3,0-0.4-0.1l-0.9-1.4l-0.1,0l0.4-0.2L44.8,8.5"/>
	<path id="XMLID_32_" fill="#F47836" d="M42.8,9c0,0,0.4,0,0.5,0.2c0.1,0.1-0.1,0.4-0.1,0.4l0.7,1.2c0,0,0,0.1,0.2,0.2
		c0.2,0.1,0.6,0,0.6,0l-0.3,0.2l-0.7,1.4c0,0,0.1,0.4,0,0.6c-0.2,0.2-0.5,0.1-0.5,0.1s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2L43,12.4
		l0.2-0.1l0.6-1.2c0,0-0.3,0-0.4-0.1l-0.8-1.4l-0.1,0l0.4-0.2L42.8,9"/>
	<path id="XMLID_31_" fill="#F47836" d="M40.8,9.3c0,0,0.4,0.1,0.5,0.2c0.1,0.1-0.1,0.4-0.1,0.4l0.6,1.2c0,0,0,0.1,0.2,0.2
		c0.2,0.1,0.6,0.1,0.6,0.1l-0.3,0.1l-0.8,1.3c0,0,0.1,0.4-0.1,0.6c-0.2,0.2-0.5,0-0.5,0s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2l-0.3-0.4
		l0.2-0.1l0.7-1.2c0,0-0.3,0-0.4-0.1l-0.7-1.5l-0.1,0l0.4-0.2L40.8,9.3"/>
	<path id="XMLID_30_" fill="#F47836" d="M38.8,9.4c0,0,0.4,0.1,0.5,0.2c0.1,0.1-0.1,0.4-0.1,0.4l0.5,1.2c0,0,0,0.1,0.2,0.2
		c0.1,0.1,0.6,0.1,0.6,0.1l-0.3,0.1L39.3,13c0,0,0,0.4-0.1,0.5c-0.2,0.2-0.5,0-0.5,0s0.1-0.1,0.2-0.1c0.1,0,0-0.2,0-0.2l-0.3-0.4
		l0.2-0.1l0.7-1.2c0,0-0.3,0-0.4-0.2l-0.6-1.5l-0.1-0.1l0.4-0.2L38.8,9.4"/>
	<path id="XMLID_29_" fill="#F47836" d="M36.8,9.3c0,0,0.4,0.1,0.5,0.2c0.1,0.1-0.1,0.4-0.1,0.4l0.5,1.3c0,0,0,0.1,0.2,0.3
		c0.1,0.1,0.6,0.1,0.6,0.1L38,11.7L37.1,13c0,0,0,0.4-0.1,0.5c-0.2,0.2-0.5,0-0.5,0s0.1,0,0.2-0.1c0.1,0,0.1-0.2,0.1-0.2l-0.3-0.4
		l0.3-0.1l0.8-1.1c0,0-0.3,0-0.4-0.2l-0.5-1.5l-0.1-0.1l0.4-0.1L36.8,9.3"/>
	<path id="XMLID_28_" fill="#F47836" d="M34.8,9.1c0,0,0.4,0.1,0.5,0.3c0.1,0.1-0.1,0.4-0.1,0.4l0.4,1.3c0,0,0,0.1,0.1,0.3
		c0.1,0.1,0.6,0.2,0.6,0.2l-0.4,0.1l-1,1.2c0,0,0,0.4-0.2,0.5c-0.2,0.2-0.5-0.1-0.5-0.1s0.1,0,0.2-0.1c0.1,0,0.1-0.2,0.1-0.2
		l-0.2-0.5l0.3,0l0.9-1.1c0,0-0.3,0-0.4-0.2l-0.5-1.6l-0.1-0.1l0.4-0.1L34.8,9.1"/>
	<path id="XMLID_27_" fill="#F47836" d="M32.8,8.8c0,0,0.4,0.2,0.5,0.3c0.1,0.1-0.2,0.4-0.2,0.4l0.3,1.3c0,0,0,0.1,0.1,0.3
		c0.1,0.2,0.6,0.2,0.6,0.2l-0.4,0.1l-1.1,1.1c0,0,0,0.4-0.2,0.5c-0.2,0.2-0.5-0.1-0.5-0.1s0.1,0,0.2-0.1c0.1,0,0.1-0.2,0.1-0.2
		l-0.2-0.5l0.3,0l0.9-1c0,0-0.3-0.1-0.4-0.2l-0.4-1.6l-0.1-0.1l0.4-0.1L32.8,8.8"/>
	<path id="XMLID_26_" fill="#F47836" d="M30.9,8.3c0,0,0.4,0.2,0.4,0.3C31.4,8.7,31.1,9,31.1,9l0.2,1.3c0,0,0,0.1,0.1,0.3
		c0.1,0.2,0.6,0.2,0.6,0.2l-0.4,0L30.6,12c0,0,0,0.4-0.2,0.5c-0.2,0.1-0.5-0.1-0.5-0.1s0.1,0,0.2,0c0.1,0,0.1-0.2,0.1-0.2l-0.2-0.4
		l0.2-0.1l1.1-1c0,0-0.3-0.1-0.4-0.2l-0.3-1.6l-0.1-0.1l0.4-0.1L30.9,8.3"/>
	<path id="XMLID_25_" fill="#F47836" d="M28.9,7.5c0,0,0.4,0.2,0.4,0.4c0.1,0.1-0.2,0.4-0.2,0.4l0.2,1.4c0,0,0,0.1,0.1,0.3
		c0.1,0.2,0.6,0.3,0.6,0.3l-0.4,0l-1.2,1c0,0-0.1,0.4-0.3,0.5c-0.2,0.1-0.5-0.1-0.5-0.1s0.1,0,0.2,0c0.1,0,0.1-0.2,0.1-0.2l-0.2-0.5
		l0.3,0l1.1-1c0,0-0.3-0.1-0.3-0.3L28.5,8L28.5,8l0.4-0.1L28.9,7.5"/>
	<path id="XMLID_24_" fill="#F47836" d="M26.1,10.4l1.3-1l0.4,0c0,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.1-0.3-0.1-0.3L27,7.4
		c0,0,0.3-0.2,0.2-0.4c-0.1-0.2-0.4-0.4-0.4-0.4l0,0.4l-0.4,0l0.1,0.1l0.1,1.7C26.7,9,27,9.1,27,9.1L25.8,10l-0.3,0l0.2,0.5
		c0,0,0,0.2-0.1,0.2c-0.1,0-0.2,0-0.2,0s0.3,0.3,0.5,0.2C26.1,10.8,26.1,10.4,26.1,10.4"/>
	<path id="XMLID_23_" fill="#F47836" d="M24.3,9.5l1.3-1l0.4,0c0,0-0.5-0.1-0.6-0.3c-0.1-0.2-0.1-0.3-0.1-0.3l-0.1-1.4
		c0,0,0.3-0.2,0.2-0.4C25.4,6,25,5.8,25,5.8l0,0.4l-0.4,0l0.1,0.1L24.8,8c0.1,0.2,0.3,0.3,0.3,0.3L24,9.2l-0.3,0l0.1,0.5
		c0,0,0,0.2-0.1,0.2s-0.2,0-0.2,0s0.3,0.3,0.5,0.2C24.2,9.9,24.3,9.5,24.3,9.5"/>
	<path id="XMLID_22_" fill="#BC9B6A" d="M2,18.6l5.8-2.1c0.8,0.6,2.2,1,3.3,1.2c-0.1,0.6,0,1.2,0.1,1.5c0.1,0.4,1.3,3.1,1.3,3.1
		l-3.8,1.4l-1.4-3.4l-1.3-0.3l-0.3,1.6l3.9,9.8l1.2,0.3l0.7-1.2l-0.9-2.3l-1,0.4l-1.3-3.3l4.8-1.8c0,0,1.8,4.5,2,5
		c0.2,0.5,0.8,1.3,1.1,1.5c-0.5,0.5-1.6,1.8-1.4,3.1l-5.9,2.3c-0.6-0.6-1-1.9-2.8-1.7c0-0.5-0.4-0.9-0.7-1.7c-0.3-0.8-3.3-8-3.5-8.5
		c-0.2-0.5-0.5-1.3-1.2-2C1.5,21,2.2,19.8,2,18.6"/>
	<path fill="#BC9B6A" d="M26.7,12.5L32,12c0.3,1.2,1.2,2,1.6,2.4c-0.5,0.6-0.4,1.3-0.2,1.7c0.5,1.4,3,8.4,3.2,8.8
		c0.3,0.7,1,1.3,1.6,1.8c-0.1,0.2-0.9,1.3-0.9,2.7c0,0-4.1,0.3-4.1,0.3c-0.1-0.5-0.8-1.8-1.5-2.6c0.7-0.5,0.9-0.9,0.7-1.6
		c-0.1-0.1-0.2-0.6-0.3-0.9L29,24.9c0,0.4,0,0.8,0,0.9c-0.1,0.8,0.5,1.1,1,1.4c-0.3,0.7-0.7,1.7-0.7,2.8l-4.4,0.6
		c-0.1-0.8-0.7-1.9-1.2-2.5c0.7-0.6,0.9-1.3,1.1-2.4c0.1-0.7,0.5-3.5,0.8-5.8c0.2-1.8,0.2-2.9,0.3-3.2c0.1-0.7,0-1.1-0.4-1.6
		C26,14.6,26.6,13.6,26.7,12.5 M30.2,19c-0.1-0.5-0.4-1.6-0.4-1.6s-0.1,1.3-0.2,1.7c-0.1,0.4-0.3,2.3-0.3,2.3l1.7-0.2
		C31,21.2,30.4,19.5,30.2,19z"/>
	<path id="XMLID_19_" fill="#BC9B6A" d="M47.4,12.2l4.2,0.4c0.1,1,0.5,2.2,0.8,2.8c-0.8,0.5-1.2,1-1.3,2l-0.5,8.4
		c0,0.6,0.1,1.1,0.6,1.6c-0.3,0.3-1.2,1.8-1.5,2.8l-3.3-0.4l-2.8-7.8l-0.4-1.6c0,0.3,0.1,1.4,0,1.7c-0.1,1.5-0.2,3-0.2,3
		c0,0.5,0.3,1.6,1,2c-0.4,0.5-1.2,1.5-1.4,2.6L39,29.4c-0.2-1.3-0.4-1.6-1-2.7c0.3-0.2,1.2-1.1,1.2-1.9l0.5-8.5c0-0.6,0.2-1.3-0.7-2
		c0.5-0.7,0.9-1.9,1-2.5l4.2,0.1l2.6,7.3l0.3,1.7l0-1.8l0.2-2.5c0-0.7,0-1.3-0.8-2C46.8,14.2,47.2,12.8,47.4,12.2"/>
	<path id="XMLID_18_" fill="#BC9B6A" d="M20.1,18.2c0.2,1,2.1,7.8,2.1,8c0.2,0.9,0.8,1.3,1.6,1.7c-0.5,1-0.8,1.6-0.4,2.9
		c0,0-4.1,1-4.1,1c-0.2-0.9-0.4-1.5-1.8-2.3c0.7-0.9,0.8-1.7,0.6-2.4c-0.2-0.7-1.9-7.3-2-7.8c-0.1-0.5-0.4-1.3-1.7-2
		c0.4-0.6,0.7-1.7,0.5-2.7c0,0,4.2-1,4.2-1c0.3,1.1,1.1,2,1.7,2.2C20.3,16.6,19.8,17.3,20.1,18.2"/>
	<path id="XMLID_17_" fill="#BC9B6A" d="M55.6,26.3l2.1-9c-0.8,0-2.2,0.5-3.2,1.4l-2.3-2.2c1.2-0.8,2.9-2.1,3.4-3.4
		c0,0,9.8,2.1,9.8,2.1c-0.2,1.3,0.5,3.2,1.2,4.4c0,0-2.8,0.9-2.8,0.9c-0.4-1-1.2-2.3-2-2.7l-2.2,9.4c-0.2,0.8,0.1,1.7,0.6,2.3
		c-0.9,0.6-1.5,1.2-1.7,2.4c0,0-3.9-0.9-3.9-0.9c0.4-1.1,0-1.9-0.5-2.9C55,27.7,55.4,27,55.6,26.3"/>
	<path id="XMLID_16_" fill="#BC9B6A" d="M63.9,28.8l0.8-1.9l3.9,1.5l-1,2.7l0.6,1.1l1.2-0.5l1.1-2.9l-5.2-4.1c0.4-0.5,0.6-0.9,0.8-1.3
		c0.2-0.4,1.2-2.6,1.4-3.2c0.2-0.6,0.3-0.8,0.2-2.1c1,0,2.5-0.4,3-1.4l5.4,2c0.1,0.9,1.5,2.7,2,3.2c-0.4,0.4-0.8,1-1,1.5
		c-0.2,0.5-1,2.6-1,2.6l-3.8-1.5l1.1-3.1l0-0.4l-0.5-0.8l-1.3,0.5l-1.3,3.1l5.1,4.1c-0.3,0.4-0.5,0.7-0.7,1.2
		c-0.4,1.1-1.4,3.6-1.4,3.6c-0.2,0.7-0.2,1.3-0.2,1.8c-1.3,0.1-2.7,0.5-3.2,1.3l-5.7-2.2c0.1-1.3-0.1-2.5-1.1-3.3
		C63.7,29.9,63.7,29.4,63.9,28.8"/>
	<path id="XMLID_15_" fill="#F47836" d="M1.4,18.3l6-2.1c0.8,0.6,2,1,3.1,1.2c-0.1,0.6,0,1.2,0.1,1.5c0.1,0.4,1.3,3.2,1.3,3.2l-3.8,1.4
		l-1.4-3.4l-1.3-0.3l-0.4,1.5L9,31.1l1.3,0.4l0.7-1.4l-0.8-2.2l-1.1,0.4L7.8,25l4.8-1.8c0,0,1.8,4.5,2,5c0.2,0.5,0.8,1.3,1.1,1.5
		c-0.5,0.5-1.6,1.8-1.4,3.1l-5.9,2.3c-0.6-0.6-1.4-1.3-3.2-1.1c0-0.5,0-1.4-0.3-2.2c-0.3-0.8-3.5-8.5-3.5-8.5C1,22.6,0.7,22,0,21.3
		C0.9,20.7,1.6,19.5,1.4,18.3"/>
	<path fill="#F47836" d="M26.1,12.1l5.3-0.5c0.3,1.2,1.1,2,1.6,2.4c-0.5,0.6-0.4,1.3-0.2,1.7c0.5,1.4,3,8.4,3.2,8.8
		c0.4,0.8,0.9,1.4,1.5,1.8c-0.1,0.2-0.8,1.3-0.8,2.7c0,0-4.1,0.3-4.1,0.2c-0.1-0.5-0.8-1.8-1.5-2.6c0.7-0.5,0.9-0.9,0.7-1.6
		c0-0.1-0.2-0.6-0.3-0.9l-3.2,0.4c0,0.4,0,0.8,0,0.9c0,0.8,0.5,1.1,1,1.4c-0.3,0.7-0.7,1.7-0.6,2.8l-4.4,0.6
		c-0.1-0.8-0.6-1.9-1.1-2.5c0.7-0.6,0.9-1.3,1.1-2.4c0.1-0.7,0.5-3.5,0.8-5.8c0.2-1.8,0.3-2.9,0.3-3.2c0.1-0.7,0-1.1-0.4-1.6
		C25.5,14.2,26.1,13.1,26.1,12.1 M29.7,18.6c-0.1-0.5-0.4-1.7-0.4-1.7s0,1.3-0.1,1.7c-0.1,0.4-0.3,2.3-0.3,2.3l1.6-0.2
		C30.5,20.8,29.8,19.1,29.7,18.6z"/>
	<path id="XMLID_12_" fill="#F47836" d="M47,11.6l4.2,0.4c0.1,1,0.5,2.2,0.8,2.8c-0.8,0.5-1.2,1-1.3,2l-0.6,8.4c0,0.6,0.1,1.1,0.6,1.6
		c-0.3,0.3-1.2,1.8-1.5,2.8L46,29.2l-2.8-7.8l-0.4-2.2c0,0.3,0.1,1.9,0.1,2.2c-0.1,1.5-0.2,3-0.2,3c0,0.5,0.3,1.6,1,2
		c-0.4,0.5-1.2,1.5-1.4,2.6l-3.7-0.1c-0.2-1.3-0.3-1.6-1-2.7c0.5-0.4,1.1-1.1,1.2-1.9l0.5-8.5c0-0.6,0-1.2-0.8-1.9
		c0.5-0.7,1.1-1.9,1.1-2.6l4,0.1l2.6,7.3l0.5,2.1l-0.1-2.3l0.2-2.5c0-0.7-0.2-1.3-1-1.9C46.2,13.8,46.7,12.3,47,11.6"/>
	<path id="XMLID_11_" fill="#F47836" d="M19.5,17.9c0.2,1,2.1,7.8,2.1,8.1c0.2,0.9,0.7,1.3,1.5,1.8c-0.5,1-0.7,1.6-0.3,2.9
		c0,0-4.1,1-4.1,1c-0.2-0.9-0.4-1.5-1.8-2.3c0.7-0.9,0.8-1.7,0.6-2.4c-0.2-0.7-2-7.5-2.1-8c-0.1-0.5-0.4-1.1-1.6-1.8
		c0.4-0.6,0.8-1.8,0.5-2.7c0,0,4.2-1,4.2-1c0.3,1.1,1.1,2,1.7,2.2C19.7,16.2,19.3,16.9,19.5,17.9"/>
	<path id="XMLID_10_" fill="#F47836" d="M55.3,25.7l2.1-9c-0.8,0-2.1,0.5-3.1,1.4l-2.3-2.2c1.2-0.8,2.8-2,3.3-3.3c0,0,9.6,2,9.6,2
		c-0.2,1.3,0.8,3.1,1.5,4.4l-3,1c-0.4-1-1.2-2.1-2-2.5l-2.1,9.1c-0.2,0.8,0.1,1.7,0.6,2.3c-0.9,0.6-1.5,1.2-1.7,2.4
		c0,0-3.9-0.9-3.9-0.9c0.4-1.1,0-1.9-0.5-2.9C54.6,27.1,55.1,26.4,55.3,25.7"/>
	<path id="XMLID_9_" fill="#F47836" d="M63.6,28.2l0.8-1.9l3.9,1.5l-1,2.7l0.6,1.1l1.2-0.5l1.1-2.9L64.9,24c0.4-0.5,0.8-1.2,1-1.7
		c0.2-0.4,1-2.2,1.2-2.8c0.2-0.6,0.3-0.8,0.2-2.1c1,0,2.5-0.4,3-1.4l5.7,2.1c0.1,0.9,1.3,2.6,1.7,3.1c-0.4,0.4-0.8,1-1,1.5
		c-0.2,0.5-1,2.6-1,2.6L72,23.8l1.1-3.1l-0.6-1l-1.1,0.4l-1.3,3.1l5.1,4.1c-0.3,0.4-0.5,0.7-0.7,1.2C74,29.5,73,32.1,73,32.1
		c-0.2,0.7-0.2,1.3-0.2,1.8c-1.3,0.1-2.7,0.5-3.2,1.3L64,33c0.1-1.3-0.6-2.4-1.5-3.2C63,29.4,63.4,28.8,63.6,28.2"/>
	<path id="XMLID_8_" d="M8.4,34.5c-0.7-0.7-1.8-1.1-2.8-1.1c0-0.4,0.1-0.8-0.2-1.6c-0.3-0.7-3.3-7.9-3.6-8.8c-0.3-0.8-0.7-1.5-1-1.8
		c0.8-0.6,1.2-2,1.2-2.7l5.3-1.9c0.5,0.4,1.8,0.8,2.7,1c-0.1,0.4-0.1,0.9,0.1,1.3c0.2,0.4,1,2.6,1,2.6l-2.7,1l-1.3-3.1l-1.9-0.6
		l-0.8,2.1l4.1,10.3l2,0.7l1-1.9l-1.2-3l-1,0.4l-0.9-2.3l3.8-1.4c0,0,1.7,4.1,1.8,4.5c0.2,0.4,0.4,0.8,0.9,1.3
		c-0.6,0.5-1.3,1.8-1.3,2.8L8.4,34.5"/>
	<path d="M32.4,14c-0.4,0.5-0.3,1.2-0.1,1.8c0.4,1.2,3,8.4,3.2,8.8c0.3,0.6,0.6,1.1,1.3,1.6c-0.2,0.3-0.6,1.3-0.7,2.1l-3.2,0.2
		c-0.1-0.4-0.9-1.6-1.2-1.9c0.6-0.4,0.8-1,0.5-1.8c-0.1-0.2-0.4-1.3-0.4-1.3L27.9,24c0,0,0,1.1,0,1.4c-0.1,0.7,0.2,1.3,0.9,1.6
		c-0.4,0.8-0.5,1.4-0.5,2.1l-3.4,0.5c-0.1-0.7-0.6-1.4-1-2c0.5-0.5,0.8-1.1,1-2.2c0.2-1.1,1-8.4,1.1-9.1c0.1-0.6,0.1-1.2-0.3-1.7
		c0.5-0.6,0.9-1.4,1-2.1l4.5-0.4C31.5,13,31.9,13.6,32.4,14 M29.7,17c-0.1-0.3-0.6-1.6-0.6-1.6s-0.2,1.4-0.2,1.7
		c0,0.2-0.6,4.5-0.6,4.5l3-0.3C31.2,21.2,29.8,17.4,29.7,17z"/>
	<path id="XMLID_5_" d="M48.9,29l-2.5-0.3c0,0-2.7-7.9-3-8.6c-0.3-0.8-0.8-2.5-0.8-2.5c0,0.7-0.1,1.7-0.2,2.5
		c-0.1,1.9-0.3,4-0.3,4.2c0,0.7,0.3,1.7,0.8,2.1c-0.6,0.8-0.9,1.2-1.1,2l-2.8,0c-0.1-0.9-0.4-1.4-0.9-2.1c0.4-0.3,1.1-1,1.1-1.9
		c0-0.6,0.5-7.9,0.6-8.5c0-0.6,0.1-1.4-0.7-2.1c0.4-0.7,0.8-1.4,0.9-1.9l3.2,0.1c0,0,2.7,7.8,3,8.4c0.2,0.6,0.7,2.1,0.7,2.1
		c0-0.6,0.1-1.3,0.1-1.9c0.1-1.9,0.3-4,0.3-4.3c0.1-0.8-0.2-1.4-0.9-2c0.4-0.5,0.7-1.3,0.9-2l3.4,0.3c0.1,0.7,0.3,1.5,0.7,2.2
		c-0.5,0.3-1.1,1-1.2,2.1c-0.1,1.3-0.5,7.9-0.5,8.4c0,0.7,0.1,1.3,0.5,1.7C49.8,27.2,49.2,28.3,48.9,29"/>
	<path id="XMLID_4_" d="M22.1,30.2c0,0-0.1-0.6,0-1.1c0.1-0.6,0.4-1.1,0.4-1.1s-0.3-0.1-0.8-0.6c-0.5-0.5-0.7-1.2-0.7-1.2L18.9,18
		c0,0-0.1-0.7,0-1.2c0.1-0.4,0.5-1,0.5-1s-0.2,0-0.7-0.7c-0.5-0.7-0.6-1.1-0.6-1.1l-3.3,0.7c0,0,0.1,0.4-0.1,1.2
		c-0.2,0.8-0.3,0.9-0.3,0.9s0.6,0.3,0.9,0.7c0.4,0.4,0.6,1.1,0.6,1.1l2.2,8.2c0,0,0.2,0.7,0,1.4c-0.2,0.7-0.4,0.9-0.4,0.9
		s0.5,0.3,0.9,0.8c0.4,0.5,0.5,1,0.5,1L22.1,30.2"/>
	<path id="XMLID_3_" d="M64.7,16.7C64.4,16,64.3,15,64.3,15l-8.8-1.8c0,0-0.6,0.9-1.2,1.4c-0.9,0.9-1.6,1.3-1.6,1.3l1.5,1.4
		c1.3-1.2,2.7-1.3,3.8-1.2l-2.2,9.7c0,0-0.2,0.7-0.6,1.2c-0.5,0.5-0.8,0.6-0.8,0.6s0.3,0.5,0.4,1.1c0.1,0.6,0,1.1,0,1.1l2.9,0.7
		c0,0,0.1-0.6,0.5-1c0.4-0.5,0.9-0.8,0.9-0.8s-0.2-0.2-0.4-0.9c-0.2-0.6,0-1.4,0-1.4l2.3-9.8c1.1,0.4,2.3,1.3,2.7,2.6l1.9-0.7
		C65.6,18.7,65.1,17.9,64.7,16.7"/>
	<path id="XMLID_2_" d="M66,23.3c-0.3,0.6-0.4,0.7-0.4,0.7l5.2,4.1l-1.3,3.5l-1.9,0.8l-0.9-1.8l0.9-2.4L64.7,27
		c-0.3,0.8-0.6,1.5-0.6,1.6c-0.1,0.3-0.6,1-0.9,1.3c0.8,0.7,1.3,1.8,1.4,2.8l5,1.9c0.6-0.8,2-1.1,2.8-1.2c0-0.6,0.1-1.3,0.2-1.5
		l1.2-3.1c0,0,0.3-0.8,0.5-1.2c0.2-0.3,0.2-0.3,0.2-0.3l-5-4l1.5-3.7l1.8-0.6l1,1.6l-1,2.8l2.8,1.1l0.8-2.3c0.2-0.5,0.5-0.7,0.8-1.1
		c-0.6-0.7-1.3-2-1.5-2.6l-5-1.8c-0.3,0.5-1.7,1.2-2.7,1.2c0.1,0.4-0.1,1.3-0.3,2c0,0.1-0.3,0.5-0.7,1.6C66.8,21.5,66.2,23,66,23.3"
		/>
		</g>
	</g>
</g>
`;

customReplacements(OriginalGraphicShirtTypes, /--avataaar-shirt-color/g, '--avataaar-clothetype-color');
customReplacements(OriginalGraphicShirtTypes, /--avataaar-graphic-color/g, '--avataaar-clothetype-graphic-color');

export const GraphicShirtTypes = OriginalGraphicShirtTypes;
export const GraphicShirtTypesOptions = {
	'_default': {
		'Graphic Color': 'color'
	},

	'Digital Attic': {},
	'Jollibee': {},
	'Giants': {},

	'yin-yang': {
		Primary: 'color',
		Secondary: 'color'
	}
};