const standardRegex = /--avataaar-(?:shirt|graphic|hat|(?:facial-)?hair)-color/g

export function standardReplacements(types, typeName) {
	for (let i in types) {
		types[i] = types[i].replace(standardRegex, `--avataaar-${typeName}-color`);
	}
}

export function customReplacements(types, sourceRegex, target) {
	for (let i in types) {
		types[i] = types[i].replace(sourceRegex, target);
	}
}