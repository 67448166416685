<script>
	import { IdType } from '../../utils/IdType'
	import { ModalService } from '../../utils/ModalService';
	import moment from 'moment';

	export default {
		model: {
			prop: 'value',
			event: 'input'
		},

		props: {
			tag: String,

			value: [Number, String],

			timelineDueDate: {
				type: String,
				default() {
					return moment().format('YYYY-MM-DD');
				}
			},
			previousTaskIds: {
				type: Array,
				required: true
			},
			projectId: {
				type: IdType,
				required: true
			}
		},

		methods: {
			triggerHandler() {
				ModalService.create('getDate', {
					value: this.timelineDueDate
				})
				.on('save', (modal, day) => {
					let payload = {
						project_id: this.projectId,
						previous_task_ids: (this.previousTaskIds || []).join(","),
						endDate: day,
					};

					this.$xhrRequest
						.send(
							"post",
							"/api/task/compute-business-days",
							payload
						)
						.on("success", (e, resp) => {
							let requiredDays = Math.max(0, resp.days);

							this.$emit('input', requiredDays);
						});
				});
			}
		},

		render() {
			let result = this.$scopedSlots.default({
				trigger: this.triggerHandler.bind(this)
			});

			if (this.tag) {
				result = h(this.tag, result);
			}

			return result;
		}
	}
</script>