<template>
	<div>
		<v-card class="my-3">
			<v-card-title>Employee Task Donuts</v-card-title>
			<v-card-subtitle class="d-flex align-center">
				Legend:
				<div class="legend ma-2">
					<v-chip dark small color="overdue" class="mx-1" label>Overdue</v-chip>
					<v-chip dark small color="today" class="mx-1" label>Today</v-chip>
					<v-chip dark small color="soon" class="mx-1" label>Soon</v-chip>
					<v-chip dark small color="later" class="mx-1" label>Later</v-chip>
				</div>
			</v-card-subtitle>
		</v-card>

		<div v-if="taskCounts && pendingTaskCounts" class="grid">
			<div v-for="item in taskCounts" :key="item.user.id">
				<v-card>
					<div class="position-relative pie-container">
						<!-- <user-avataaar class="avatar" :user="item.user"></user-avataaar> -->
						<svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
							<circle class="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="transparent"></circle>
							<circle class="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#2b2b2b" stroke-width="6"></circle>

							<!-- overdue -->
							<circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="var(--v-overdue-base)" stroke-width="3"
									:stroke-dasharray="`${item.overdue_percent} ${100 - item.overdue_percent}`" stroke-dashoffset="0"></circle>

							<!-- today -->
							<circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="var(--v-today-base)" stroke-width="3"
									:stroke-dasharray="`${item.today_percent} ${100 - item.today_percent}`"
									:stroke-dashoffset="`${-item.overdue_percent}`"></circle>
							<!-- soon -->
							<circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="var(--v-soon-base)" stroke-width="3"
									:stroke-dasharray="`${item.soon_percent} ${100 - item.soon_percent}`"
									:stroke-dashoffset="`${-item.overdue_percent - item.today_percent}`"></circle>
							<!-- later -->
							<circle class="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="var(--v-later-base)" stroke-width="3"
									:stroke-dasharray="`${item.later_percent} ${100 - item.later_percent}`"
									:stroke-dashoffset="`${-item.overdue_percent - item.today_percent - item.soon_percent}`"></circle>

							<g class="chart-text" style="fill:red" :style="`fill: ${item.user.department}`">
								<text x="50%" y="50%" class="chart-name">{{item.user.full_name}}</text>
								<text x="50%" y="50%" class="chart-total">{{item.total}}</text>
								<text x="50%" y="50%" class="chart-pending">{{pendingTaskCounts[item.user.id]}} Pending</text>
							</g>
						</svg>
					</div>

					<!-- <v-card-text>
						<div class="text-center">
							<div class="body-1">{{item.user.full_name}}</div>
							<div class="text-uppercase text-h3 font-weight-bold">{{item.total}} Active</div>
							<div class="body-1">{{pendingTaskCounts[item.user.id] || 0}} Pending</div>
						</div>
					</v-card-text> -->

				</v-card>
			</div>
		</div>

	</div>
</template>

<script>

	import UserAvataaar from "@/components/avataaars/user-avataaar";

	export default {
		name: "staffDonuts",
		components: { UserAvataaar },
		data() {
			return {};
		},

		apollo: {

			pendingTaskCounts: {
				query: gql`
					query getUserTaskCounts($filterParam: QueryFilter!) {
						pendingTaskCounts: tasks @filter(param: $filterParam)
							@groupBy(fields: ["assigned_to.id"])
						{
							nodes
							{
								group_total
								node {
									status
									assigned_to {
										id
										full_name
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "=",
									value: "pending",
								},
								{
									field: "project.status",
									op: "!=",
									value: "closed",
								},
								{
									field: "assigned_to.id",
									op: "!=",
									value: null,
								},
							],
						},
					};
				},
				update(serverData) {
					return Linq.from(serverData.pendingTaskCounts.nodes)
						.toObject(i => i.node.assigned_to.id, i => i.group_total);
				},
			},

			taskCounts: {
				query: gql`
					query getUserTaskCounts($filterParam: QueryFilter!) {
						taskCounts: tasks @filter(param: $filterParam)
							@groupBy(fields: ["due_status", "assigned_to.id"])
						{
							nodes
							{
								group_total
								node {
									due_status
									assigned_to {
										id
										full_name
										department @lowercase
										meta_avatar
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "=",
									value: "current",
								},
								{
									field: "project.status",
									op: "!=",
									value: "closed",
								},
								{
									field: "assigned_to.id",
									op: "!=",
									value: null,
								},
							],
						},
					};
				},
				update(serverData) {
					return Linq.from(serverData.taskCounts.nodes)
						.groupBy(
							(g) => g.node.assigned_to.id,
							(user, data) => {
								let result = {
									user: data.first().node.assigned_to,
									total: 0, // data.aggregate(),
									data: {
										overdue: 0,
										today: 0,
										soon: 0,
										later: 0,

										...data.toObject(i => i.node.due_status, i => i.group_total)
									}
								};

								result.total = result.data.overdue + result.data.today + result.data.soon + result.data.later;

								result.overdue_percent = (result.data.overdue / result.total) * 100;
								result.today_percent = (result.data.today / result.total) * 100;
								result.soon_percent = (result.data.soon / result.total) * 100;
								result.later_percent = (result.data.later / result.total) * 100;
								return result;
							}
						)
						.orderBy((u) => u.user.department)
						.thenBy((u) => u.user.full_name)
						.toArray();
				},
			},

		},

		computed: {
			usersTotalTasks() {
				if(!this.pendingTaskCounts || !this.pendingTaskCounts.nodes || !this.taskCounts) {
					return [];
				}

				let pending = Linq.from(this.pendingTaskCounts.nodes)
					.groupBy(
						(g) => g.node.assigned_to.id,
						(user, data) => {
							return {
								[data.first().node.assigned_to.id] : data.first().group_total
							}
						}
					)
					.toArray()

				

				return pending;
			}
		},

	};
</script>

<style scoped lang="scss" scoped>
	.pie-container {
		display: flex;
		justify-content: center;
	}
	.avatar {
		position: absolute;
		width: 75%;
		top: 4.2%;
	}

	.grid {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 10px;

		@media (min-width: 600px) {
			grid-template-columns: repeat(2, 1fr);
		}
		@media (min-width: 960px) {
			grid-template-columns: repeat(3, 1fr);
		}
		@media (min-width: 1400px) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media (min-width: 1904px) {
			grid-template-columns: repeat(5, 1fr);
		}
		@media (min-width: 2500px) {
			grid-template-columns: repeat(7, 1fr);
		}
	}

	.chart-text {
		font-size: 16px;
		fill: white;
		transform: translateY(0.25em);
	}

	.chart-name {
		font-size: 0.15em;
		font-weight: 900;
		line-height: 1;
		text-anchor: middle;
		transform: translateY(0.7em);
	}

	.chart-total {
		font-size: 0.6em;
		line-height: 1;
		text-anchor: middle;
		transform: translateY(-0.6em);
	}

	.chart-pending {
		font-size: 0.15em;
		text-transform: uppercase;
		text-anchor: middle;
		transform: translateY(-0.8em);
	}
</style>
