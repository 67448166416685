$.fn.removeClassPattern = function(regex) {
	return this.each(function() {
		let that = $(this),
			classes = (that.attr('class') || '').split(' ');

		classes = classes.filter((Class) => {
			if (!Class)
				return false;

			return !regex.test(Class);
		});

		that.attr('class', classes.join(' '));
	});
};