<!-- 
* modified and assigned the task
* assigned the task
* started a timer for the task
* started a quick timer
* completed the task
* modified the project
* added a note to the project
* modified a note in the project
* modified the task
* modified the client
* added a note to the client?
* deleted a note in the project
* deleted a deadline in the project
never - added a deadline and deleted a deadline in the project
* created the new project
* added the new client
* closed the project

-->

<template>
	<v-sheet class="activity-log-item mb-1 d-flex" :data-id="log.id">
		<div class="col-sm d-flex flex-wrap align-center">
			<!-- <v-chip label x-small :color="user.department.toLowerCase()" class="mr-2 px-2 hide-on-panel" min-width="0"></v-chip> -->
			<user-avataaar :user="user" style="max-width:30px;" class="mr-2 hide-on-panel"></user-avataaar>
			<!-- <v-chip label x-small color="white" light v-if="this.$store.getters.userData.user_access ===  'Developer'" class="hide-on-panel mr-2">{{ log.id }}</v-chip> -->

			<router-link :to="userDetails" target="_blank" class="mr-1">{{ user | FullName }}</router-link>
			&nbsp;
			<template v-if="isQuickTimer">started a quick timer.</template>
			<task-project-client-link v-else-if="isTaskTimer" :log="log">
				started a timer for &nbsp;
				<template v-if="timerForSomeoneElse">{{ timerForSomeoneElse | FullName }} for &nbsp;</template>
			</task-project-client-link>
			<task-project-client-link v-else-if="is(':Task')" :log="log">
				{{ taskModificationText }} 
			</task-project-client-link>
			<task-project-client-link v-else-if="is(':Note')" :log="log">
				{{ noteModificationText }} 
			</task-project-client-link>
			<task-project-client-link v-else-if="isClosedProject" :log="log">
				closed 
			</task-project-client-link>

			<template v-else-if="person">
				<template v-if="removedPerson"> removed </template>
				<template v-else> updated information for </template>
				<router-link :to="personDetails" target="_blank" class="ml-1">
					{{ person | FullName }}</router-link
				>.
			</template>

			<task-project-client-link v-else :log="log">
				{{ verb }}
			</task-project-client-link>
		</div>
		<div class="col-sm-auto text-right">
			{{ log.modified_date | formatDate("MM-DD-YYYY h:mm A", false) }}
		</div>
	</v-sheet>
</template>

<script>
	import taskProjectClientLink from "@/components/taskProjectClientLink";
	import UserAvataaar from './avataaars/user-avataaar'

	export default {
		name: "activity-log-item",
		props: {
			log: Object,
		},
		components: {
			taskProjectClientLink,
			UserAvataaar
		},

		data() {
			return {};
		},
		methods: {
			is(what) {
				return this.log.action.indexOf(what) >= 0;
			},
			changed(field, mustEqual) {
				if (typeof mustEqual !== "undefined") {
					return (
						this.log.new_data &&
						this.log.new_data[field] &&
						this.log.new_data[field] == mustEqual
					);
				}
				return this.log.new_data && this.log.new_data[field];
			},
		},
		computed: {
			isClosedProject() {
				return (
					this.is("update:Project") && this.log.data.status === "closed"
				);
			},
			verb() {
				let [action, noun] = this.log.action.split(":");
				if (action === "update") {
					return " modified ";
				}
				return `${action}d`;
			},
			noteModificationText() {
				if (this.typeOfNote === "status") {
					return " updated the status of ";
				}

				return this.verb + " a " + this.typeOfNote + " for ";
			},
			taskModificationText() {
				if (this.log.action === "create:Task") {
					if (!this.log.new_data) {
						this.log.new_data = {};
					}
					this.log.new_data.assigned_to_user_id = this.log.data.assigned_to_user_id;
					return this.log.new_data.assigned_to_user_id > 0
						? " created and assigned "
						: " created ";
				}
				if (this.changed("status", "complete")) {
					return " completed ";
				} else if (this.changed("assigned_to_user_id")) {
					return Object.values(this.log.new_data).length > 1
						? " modified and assigned "
						: " assigned ";
				} else if (this.log.action === "delete:Task") {
					return " deleted ";
				} else {
					return " modified ";
				}
			},
			isQuickTimer() {
				return this.is("create:Timer") && !this.project;
			},
			timerForSomeoneElse() {
				let isTimer = this.project && this.is("create:Timer");
				if (!isTimer) return null;
				if (this.log.data.user_id != this.log.user_id) {
					return this.$db.getModel("person", this.log.data.user_id);
				} else {
					return null;
				}
			},
			isTaskTimer() {
				return (
					this.project &&
					this.is(
						"create:Timer"
					) /* || (this.is('update:Timer') && this.changed('project_id')) */
				);
			},
			personDetails() {
				return {
					name: "employee.details",
					params: { id: this.person.id },
				};
			},
			userDetails() {
				return {
					name: "employee.details",
					params: { id: this.user.id },
				};
			},
			removedPerson() {
				return this.log.new_data && this.log.new_data.inactive == 1;
			},
			person() {
				if (this.is(":Person")) {
					let person = this.$db.getModel("person", this.log.data.id);
					if (this.log.data.id && !person) {
						this.$xhrRequest
							.send("get", `/api/person/${this.log.data.id}`)
							.then((model) => {
								this.$db.addModels("person", model, "users");
							});
					}
					return person;
				} else {
					return null;
				}
			},
			user() {
				return this.$db.getModel("person", this.log.user_id);
			},
			project() {
				return this.$db.getModel("project", this.log.data.project_id);
			},

			task() {
				if (
					this.log.data &&
					this.log.data.task_id &&
					this.log.data.task_name
				) {
					return {
						id: this.log.data.task_id,
						name: this.log.data.task_name,
					};
				} else {
					return null;
				}
			},
			typeOfNote() {
				if (/deadline|reminder|status/i.test(this.log.data.note_type)) {
					return this.log.data.note_type;
				}
				return "note";
			},
		},
	};
</script>