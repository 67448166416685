<template>
	<div>
		<v-system-bar :color="hoursOvertime >= 0 ? 'overtime' : 'system-bar'" app dark>

			<template>
				<div class="text-center">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn text x-small dark color="system-bar darken-3" v-bind="attrs" v-on="on">
								<v-icon>fas fa-cog</v-icon>
							</v-btn>
						</template>
						<v-card min-width="300">
							<v-card-title>Settings</v-card-title>
							<v-card-text>
								<strong>Select Theme</strong>
								<v-radio-group v-model="theme" mandatory row>
									<v-radio v-for="option in themeOptions" :key="option.value" :label="option.text" :value="option.value"></v-radio>
								</v-radio-group>
								<v-divider></v-divider>

								<v-switch label="Animations" v-model="animations"></v-switch>
							</v-card-text>
						</v-card>
					</v-menu>
				</div>
			</template>

			<span class="d-none d-sm-inline-block ml-2">
				Greetings,
				<router-link style="color:white" :to="{ name: 'employee.details', params: { id: $store.getters.userId } }">
					{{ $store.getters.userFirstName }}
				</router-link>
				<span v-if="loggedInUser && !loggedInUser.meta_avatar" class="blink">
					&lt;- Set an avatar here!
				</span>
			</span>

			<v-spacer></v-spacer>
			<div class="header-meta header-meta-total mx-2 d-inline-block">
				Total:
				<timer-clock :timers="$store.getters.myTimers"></timer-clock>
			</div>
			<div class="header-meta header-meta-current mx-2 d-inline-block">
				Current:
				<timer-clock :timer="$store.getters.currentTimer"></timer-clock>
			</div>
			<div class="header-meta header-meta-out mx-2 d-none d-sm-inline-block">
				<span v-if="$store.getters.timersOutTime.stopped">Stopped at</span>
				<span v-else>Out:</span>
				<span class="number header-meta-number-out-number">
					{{ $store.getters.timersOutTime.time.format("hh:mm A") }}
				</span>
			</div>
			<v-btn dark color="primary" x-small @click="logout()">Logout</v-btn>
		</v-system-bar>

		<v-app-bar app clipped-left>
			<v-app-bar-nav-icon class="d-block d-md-none" @click.stop="drawerOpen = !drawerOpen"></v-app-bar-nav-icon>
			<v-toolbar-title class="pl-0">
				<router-link :to="$store.getters.userCustomHome">
					<v-img v-if="$vuetify.theme.dark" src="@/assets/images/logo-planet.png" max-height="60" max-width="300" contain></v-img>
					<v-img v-else src="@/assets/images/logo-planet-light.png" max-height="60" max-width="300" contain></v-img>
				</router-link>
			</v-toolbar-title>

			<!-- <v-spacer></v-spacer> -->
			<quick-jump class="ml-3" />
			<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

			<div v-if="$vuetify.breakpoint.mdAndUp">
				<template v-for="(menu, key) in menuItems">
					<v-divider v-if="menu == 'divider'" :key="key" class="d-none"></v-divider>
					<v-btn v-else-if="menu.length == 1" :key="key" :to="menu[0].to" class="ml-3" small text plain>
						<v-icon small class="mr-2 text-none">{{menu[0].icon}}</v-icon>
						{{menu[0].mobileTitleOnly ? '' : menu[0].title}}
					</v-btn>
					<v-menu v-else :key="key" offset-y open-on-hover>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" :to="menu[0].to" class="ml-3" small text plain>
								<v-icon small class="mr-2 text-none">{{menu[0].icon}}</v-icon>
								{{menu[0].mobileTitleOnly ? '' : menu[0].title}}
							</v-btn>
						</template>
						<v-list nav dense>
							<template v-for="index in menu.length -1">
								<v-list-item v-if="!menu[index].condition || menu[index].condition()" :key="index" :to="menu[index].to">
									<v-list-item-icon>
										<v-icon small>{{menu[index].icon}}</v-icon>
									</v-list-item-icon>
									<v-list-item-title>{{menu[index].title}}</v-list-item-title>
								</v-list-item>
							</template>
						</v-list>
					</v-menu>
				</template>
			</div>

			<extras-menu />
		</v-app-bar>

		<v-navigation-drawer v-if="$vuetify.breakpoint.smAndDown" v-model="drawerOpen" app clipped :color="hoursOvertime >= 0 ? 'overtime' : ''">
			<mobile-nav :items="menuItems" />
		</v-navigation-drawer>
	</div>
</template>

<script>
	import Vue from "vue";
	import announcements from "@/components/announcements";
	import TimerClock from "./timerClock";
	import moment from "moment";
	import settings from "@/utils/settings";

	import projectCounts from "@/components/projectCounts";
	import extrasMenu from "./header/extrasMenu.vue";
	import mobileNav from "./header/mobileNav.vue";
	import quickJump from "./header/quickJump.vue";

	import { EventBus } from "../utils/EventBus";

	export default {
		name: "globalHeader",

		components: {
			TimerClock,
			announcements,
			projectCounts,
			extrasMenu,
			quickJump,
			mobileNav,
		},

		data() {
			return {
				drawerOpen: false,
				customHome: { name: "home" },
				themeOptions: [
					{
						value: "space-dark",
						text: "Space",
					},
					{
						value: "space-light",
						text: "Light",
					},
					{
						value: "simple-dark",
						text: "Dark",
					},
					
				],
				menuItems: [
					[
						{
							title: "Dashboard",
							mobileTitleOnly: true,
							icon: "fad fa-tachometer-fast",
							to: { name: "dashboard" },
						},
					],
					'divider',
					[
						{
							title: "Task/Timers",
							icon: "fad fa-user-clock",
							to: { name: "tasks" },
						},
						{
							title: "Running Timers",
							icon: "fad fa-clock",
							to: { name: "timers.overview" },
						},
						{
							title: "Project Timers",
							icon: "fad fa-history",
							to: { name: "project-timers" },
						},
						{
							title: "Weekly Timers",
							icon: "fad fa-calendar-week",
							to: { name: "timers.weekly" },
						},
						{
							title: "Task Cards",
							icon: "fad fa-grip-vertical",
							to: { name: "tasks.cards" },
						},
					],
					"divider",
					[
						{
							title: "Projects",
							icon: "fad fa-id-card",
							to: { name: "clients-and-projects" },
						},
						{
							title: "Clients",
							icon: "fad fa-id-card-alt",
							to: { name: "clients" },
						},
						{
							title: "Company Timeline",
							icon: "fal fa-stream",
							// iconColor: 'grey darken-2',
							to: { name: "timeline" },
						},
						{
							title: "Calendar",
							icon: "fad fa-calendar",
							// iconColor: 'grey darken-2',
							to: { name: "calendar" },
						},
						{
							title: "Monthly Retainers",
							icon: "fad fa-stopwatch",
							// iconColor: 'grey darken-2',
							to: { name: "retainers" },
						},
						{
							condition: () =>
								this.$store.getters.hasPermission(
									"manage_project_alerts"
								),
							title: "Project Alerts",
							icon: "fad fa-bell-on",
							to: { name: "project-alerts" },
						},
					],
					"divider",
					[
						{
							title: "Contacts",
							icon: "fad fa-user-friends",
							to: { name: "contacts" },
						},
						{
							title: "Employees",
							icon: "fad fa-house-user",
							// iconColor: 'grey darken-2',
							to: { name: "user.list" },
						},
					],
				],
			};
		},

		created() {
			this.menuUnsubscribe = EventBus.$on(/^app\/menu\//, this.handleMenu);
		},

		destroyed() {
			this.menuUnsubscribe();
		},

		computed: {
			loggedInUser() {
				return this.$store.getters.userData;
			},
			permanent() {
				return this.$vuetify.breakpoint.mdAndUp;
			},
			expanded() {
				return this.$vuetify.breakpoint.mdAndUp;
			},
			isDeveloper() {
				let user = this.$store.getters.userData;
				if (!user) {
					return false;
				}
				return /developer|it|/i.test(user.department);
			},

			/**
			 *
			 * @returns {number} >=0 indicates overtime hours. -1 indicates not overtime.
			 */
			hoursOvertime() {
				let hours = -1;
				let secondsOvertime =
					this.$store.getters.timersTotalTime - 8 * 3600;
				if (secondsOvertime > 0) {
					hours = Math.floor(secondsOvertime / 3600);
				}
				return hours;
			},
			departments() {
				return this.$insight.users.departments().map((opt) => {
					return opt.value;
				});
			},
			theme: {
				get() {
					let dbTheme = this.$store.getters.userData.theme;

					// theme is empty, set a default
					if(!dbTheme) {
						return 'space-dark'
					} else if(dbTheme == 'light' || dbTheme == 'dark') {
						return 'space-dark'
					} 
					
					return this.$store.getters.userData.theme;
				},
				set(theme) {
					this.$store.dispatch("setTheme", theme);
				},
			},
			animations: {
				get() {
					return this.$store.getters.userData.animations;
				},
				set(status) {
					this.$store.dispatch("setAnimationStatus", status);
				},
			},
		},

		methods: {
			handleMenu(event, ...params) {
				event =
					"menu_" +
					event
						.split("/", 3)[2]
						.replace(/-[a-z]/, (m) => m[1].toUpperCase());

				console.log("menu event", event);
				if (this[event]) {
					this[event](...params);
				}
			},

			// TODO: create one of these for employeeAddEdit
			menu_addContact({
				projectLinks = [],
				clientLinks = [],
				personId = 0,
				redirect = true
			} = {}) {
				this.$modalService
					.create("contactAddEdit", {
						populate: {
							project_links: projectLinks,
							client_links: clientLinks,
						},
						personId,
					})
					.on("save", (e, person, isUpdate) => {
						this.$db.addModels("person", person, "people-list");
						this.$snotify.success("Success!", `${isUpdate ? 'Updated' : 'Created'} contact.`);

						if (redirect) {
							this.$router.push({
								name: "contact.details",
								params: { id: person.id },
							});
						}
					});
			},

			menu_addEmployee({
				projectLinks = [],
				clientLinks = [],
				personId = 0,
			} = {}) {
				this.$modalService
					.create("employeeAddEdit", {
						populate: {
							project_links: projectLinks,
							client_links: clientLinks,
						},
						personId,
					})
					.on("save", (e, person) => {
						this.$db.addModels("person", person, "people-list");
						this.$snotify.success("Success!", `Created employee.`);
						this.$router.push({
							name: "employee.details",
							params: { id: person.id },
						});
					});
			},

			menu_addProject({ lockedClientId = undefined, project = null } = {}) {
				if (project == null && this.$route.name == "client.details") {
					project = {
						client_id: this.$route.params.id,
					};
				}

				this.$modalService
					.create("projectAddEdit", {
						lockedClientId,
						project,
					})
					.on("save", (e, project) => {
						EventBus.$emit('project:new', project);
						this.$db.addModels("project", project, "projects");
						this.$snotify.success("Project created!", "Add Project");
						this.$router.push({
							name: "project.details",
							params: { id: project.id },
						});
					});
			},

			menu_addClient({ client = {} } = {}) {
				this.$modalService
					.create("clientAddEdit", {
						client,
					})
					.on("save", (e, client) => {
						this.$db.addModels("client", client, "clients");
						this.$snotify.success("Client created!", "Add Client");
						this.$router.push({
							name: "client.details",
							params: { id: client.id },
						});
					});
			},

			menu_showDb() {
				this.$modalService.create("debug", {
					value: {
						localDb: this.$db.tables,
						getters: this.$store.getters,
					},
					root: "$store",
				});
			},

			menu_addTask({ populate = {}, lockedFields = {} } = {}) {
				if (this.$route.name.indexOf('project.') == 0) {
					populate.project_id = this.$route.params.id;
				}

				this.$modalService
					.create("taskAddEdit", {
						populate,
						lockedFields
					})
					.on("save", (e, task) => {
						this.$db.addModels("task", task, "tasks");
					});
			},

			logout() {
				this.$store.dispatch("logoutUser").then(() => {
					// top.location.reload(true);
				});
			},

			updateMiniUpdate(val) {
				console.log(val);
			},

			clearCache() {
				this.$xhrRequest.send("get", `/clear-cache`).then(() => {
					this.$snotify.success("Cache cleared");
				});
			},

			formatMoment(time) {
				return time.format("hh:mm A");
			},

			overtimeAlert() {
				if (this.hoursOvertime >= 0) {
					let hoursWorked = 8 + this.hoursOvertime;
					$("body").addClass("overtime");
					const router = this.$router;
					const notification = {
						title: "You are working overtime!",
						options: {
							body: `You've worked ${hoursWorked} hours, time to call it a day.`,
							sticky: true,
							icon: settings.url + "/favicon.ico",
						},
						events: {
							onclick() {
								window.focus();
							},
							onclose: function () {},
						},
					};
					this.$notification.show(
						notification.title,
						notification.options,
						notification.events
					);
				} else {
					$("body").removeClass("overtime");
				}
			},

			// onMobileMenuClick() {
			// 	$(this.$refs.mobileMenu).collapse("hide");
			// },
		},

		watch: {
			hoursOvertime: {
				handler(hours) {
					this.overtimeAlert();
				},
				immediate: true,
			},
			theme: {
				immediate: true,
				handler(theme) {
					this.setUserSetting("theme", theme);
					$("body").attr("data-theme", theme);

					this.$vuetify.theme.themes.dark =
						this.$vuetify.theme.framework.theme.defaults[theme];
					this.$vuetify.theme.themes.light =
						this.$vuetify.theme.framework.theme.defaults[theme];
					this.$vuetify.theme.isDark = /dark/i.test(theme);
				},
			},
		},
	};
</script>

<style lang="scss" scoped>
	.v-btn--floating {
		position: relative;
	}
	::v-deep
		.v-application--is-ltr
		.v-toolbar__content
		> .v-btn.v-btn--icon:first-child
		+ .v-toolbar__title,
	::v-deep.v-application--is-ltr
		.v-toolbar__extension
		> .v-btn.v-btn--icon:first-child
		+ .v-toolbar__title {
		padding-left: 0px;
	}
</style>