<template>
	<v-row dense>

		<v-col cols="12" md="6" lg="5" xl="4">
			<project-info-view-summary-card :project="project"></project-info-view-summary-card>
		</v-col>

		<v-col cols="12" md="6" lg="7" xl="8">
			<v-row dense>

				<v-col cols="12" md="6">
					<v-card class="fill-height">
						<v-card-title>
							Project Description
							<div class="ml-auto">
								<v-btn small min-width="0" title="Edit Project" @click="$emit('editProject')">
									<v-icon small>fad fa-pencil</v-icon>
								</v-btn>
							</div>
						</v-card-title>
						<v-card-text v-html="project.description" class="text-pre-line mb-2"></v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12" md="6">
					<v-card class="fill-height">
						<v-card-title class="card-header d-flex align-items-stretch">
							Status
							<div class="ml-auto init">
								<add-note-button objType="project" :objId="project.id" noteType="status" :showType="false" db="project-details" @save="$emitBus('project:new_status')"></add-note-button>
							</div>
						</v-card-title>
						<v-card-text v-html="lastStatusHTML || 'There is no status message yet'"></v-card-text>
					</v-card>
				</v-col>

				<v-col cols="12" xl="6">
					<contact-list caption="Project Contacts" :uid="project.uid" class="fill-height"></contact-list>
				</v-col>

				<v-col cols="12" xl="6">
					<note-list v-if="deadlinesAndReminders" caption="Deadlines & Reminders" :notes="deadlinesAndReminders.nodes" @save="$apollo.queries.deadlinesAndReminders.refetch()" class="fill-height"></note-list>
				</v-col>

			</v-row>

			<check-list-tree v-if="projectExtra" :items="projectExtra.meta_project_checklist" @save="saveProjectChecklist" class="my-2"></check-list-tree>

		</v-col>

	</v-row>
</template>

<script>
	import projectInfoViewSummaryCard from "@/components/projectInfoViewSummaryCard";
	import addNoteButton from "@/components/addNoteButton";
	import Insight from "@/utils/Insight";
	import ContactList from "@/components/contactList";
	import CheckListTree from "@/components/checkListTree/checkListTree";
	import NoteList from "@/components/noteList";

	export default {
		name: "projectInfoView",
		components: {
			projectInfoViewSummaryCard,
			addNoteButton,
			ContactList,
			CheckListTree,
			NoteList,
		},
		inject: ["projectContext"],

		apollo: {
			deadlinesAndReminders: {
				query: gql`
					query getDeadlinesAndReminders($filterParam: QueryFilter!) {
						deadlinesAndReminders: notes
							@filter(param: $filterParam)
							@sort(param: [{ field: "date", order: desc }]) {
							total
							nodes {
								node {
									id
									content
									modified_date
									date
									hidden
									note_type
									author_id
									project {
										id
										name
										account_executive_user_id
										account_executive_user {
											id
											full_name
										}
										client {
											id
											name
										}
									}
									author {
										id
										full_name
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "project.id",
							op: "=",
							value: this.project.id,
						},
						{
							field: "note_type",
							op: "in",
							value: ["deadline", "reminder"],
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				skip() {
					return !this.project;
				},
			},
			projectExtra: {
				query: gql`
					query GetProject_InfoView($id: ID!) {
						projectExtra: project(id: $id) {
							id
							meta_project_checklist
							last_status {
								id
								content
							}
						}
					}
				`,
				variables() {
					return {
						id: this.project.id,
					};
				},
				skip() {
					return !this.project;
				},
				refetchEvents: ['project:new_status']
			},
		},

		methods: {
			saveProjectChecklist(items) {
				let api = `/api/project/${this.project.id}`,
					data = {
						meta_project_checklist: items
					};
				this.$xhrRequest.send("put", api, data).then(
					(project) => {
						this.$apollo.queries.projectExtra.refetch();
					},
					(xhr) => {
						// todo handle errors
					}
				);
			},
		},
		computed: {
			project() {
				return this.projectContext.project;
			},
			globalProject() {
				return this.$db.getModel("project", this.project.id);
			},
			lastStatusHTML() {
				if (this.projectExtra && this.projectExtra.last_status) {
					return this.projectExtra.last_status.content;
				}
				return null;
			},
		},
	};
</script>

<style scoped>
</style>
