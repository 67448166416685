<template>
	<v-container>
		<v-card class="mb-4">
			<v-card-title style="flex-direction: row-reverse;">
				<v-btn small class="ml-3" @click="edit(null)" title="Add a new meta data field">
					<v-icon small>fas fa-plus</v-icon>
					<span class="sr-only">Add a new meta data field</span>
				</v-btn>
				<v-switch v-show="isAllowed('chuckit_dev')" v-model="showProtected" label="Show Protected" class="mr-3 mt-0" hide-details></v-switch>
				<span class="mr-auto">Meta Data Fields</span>
			</v-card-title>
				<!-- <b-table v-for="item in groupedItems" :key="item.table" :items="item.fields" :fields="fields" stacked="md" v-model="displayedItems" :no-provider-sorting="true" :no-provider-filtering="true" :no-provider-paging="true" ref="table" small hover striped caption-top fixed>
					<div slot="table-caption" class="lead">{{item.table}}</div>

					<template #cell(name)="data">
						<a href="javascript:void(0)" @click="edit(data.item)" title="Click to edit">{{data.value}}</a>
					</template>
					<template #cell(options)="data">
						<div v-html="badgers(data.value)"></div>
					</template>
					<template #cell(commands)="data">
						<div class="btn btn-link btn-xs float-right" @click="remove(data.item)">remove</div>
					</template>
				</b-table> -->
		</v-card>

		<v-card v-for="item in groupedItems" :key="item.table" class="mb-4">
			<v-card-title>{{item.table}}</v-card-title>
			<v-card-text>
				<v-data-table :items="item.fields" :headers="fields" v-model="displayedItems" dense disable-pagination hide-default-footer>

					<template v-slot:item.name="{ item }">
						<a href="javascript:void(0)" @click="edit(item)" title="Click to edit">{{item.name}}</a>
					</template>

					<template v-slot:item.options="{ item }">
						<div v-html="badgers(item.options)"></div>
					</template>

					<template v-slot:item.protected="{ item }">
						<div v-if="item.protected" class="text-center">
							<v-icon color="error">fad fa-shield-check</v-icon>
						</div>
					</template>

					<template v-slot:item.commands="{ item }">
						<v-btn small text color="primary" class="btn btn-link btn-xs float-right" @click="remove(item)">
							<v-icon small>fad fa-trash-alt</v-icon>
						</v-btn>
					</template>

				</v-data-table>
			</v-card-text>
		</v-card>

	</v-container>
</template>

<script>
	import _ from "lodash";
	import moment from "moment";

	export default {
		name: "metaList",

		data() {
			return {
				showProtected: false,
				displayedItems: [],
			};
		},

		apollo: {
			metaFields: {
				query: gql`
					query GetAllMetaFields($filterParam: QueryFilter!) {
						metaFields
							@filter(param: $filterParam)
							@sort(param: [{ field: "creation_date" order: asc }])
						{
							nodes {
								node {
									id
									uid
									active
									object_type
									name
									machine_name
									type
									options
									order
									protected
									creation_date
									modified_date
								}
							}
						}
					}
				`,
				variables() {
					let filters = [{
						field: 'active',
						op: '=',
						value: true
					}];

					if (!this.showProtected) {
						filters.push({
							field: 'protected',
							op: '=',
							value: false
						});
					}

					return {
						filterParam: {
							joinOp: 'and',
							filters
						}
					};
				}
			}
		},

		computed: {
			fields() {
				let result = [
					{ value: "name", text: "Name", width: "25%" },
					{ value: "type", text: "Type", width: 80 },
					{ value: "options", text: "Options", width: "50%" },
					{ value: "order", text: "Order", width: "40px" },
					{
						value: "commands",
						text: "",
						width: 40
					},
				];

				if (this.showProtected) {
					result.splice(4, 0, { value: 'protected', text: 'Protected', width: 40 });
				}

				return result;
			},

			items() {
				if (!this.metaFields) {
					return [];
				}

				return this.metaFields.nodes
					.map(row => row.node);
			},
			groupedItems() {
				return Linq.from(this.items)
					.groupBy(
						m => m.object_type,
						(object_type, metaItems) => ({
							table: object_type,
							fields: metaItems.toArray()
						})
					)
					.orderBy(g => g.table)
					.toArray();
			},
		},

		methods: {
			remove(item) {
				this.$swal({
					title: "Remove Meta Field",
					text: "Are you sure you want to remove this metadata field?",
					icon: "warning",
					buttons: true,
					dangerMode: true,
				}).then((willDelete) => {
					if (willDelete) {
						this.$xhrRequest
							.send("delete", `/api/meta/${item.id}`)
							.then(() => {
								this.$snotify.success("Deleted metadata field");
								this.$db.removeModels("meta", item);
							});
					}
				});
			},

			edit(item) {
				let options = {};
				if (item) {
					options.metaField = item;
				}
				this.$modalService
					.create("metaAddEdit", options)
					.on("save", (e, meta) => {});
			},
			badgers(values) {
				if (values instanceof Array) {
					return values
						.map(
							(item) =>
								`<span class="badge badge-secondary">${item}</span>`
						)
						.join("");
				} else {
					return values;
				}
			},
			getItems(ctx) {
				let request = this.$xhrRequest.send("get", `/api/meta/list`);
				return request.then(
					(data) => {
						return data.map((row) => {
							return {
								name: row.name,
								type: row.type,
								options: row.options,
							};
						});
					},
					(xhr) => {
						return [];
					}
				);
			},
		},
	};
</script>