<template>
	<div class="d-flex">
		<v-divider v-if="divider && (staffBirthday.length || staffAnniversary.length)" vertical class="mr-3"></v-divider>
		<v-carousel cycle height="20" hide-delimiters :show-arrows="false" interval="5000" vertical :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark"> 
			<v-carousel-item v-for="item in staffAnniversary" :key="item">
				<v-icon x-small color="primary" class="mr-1">fad fa-glass-cheers</v-icon> Work Anniversary: {{item}}
			</v-carousel-item>
			<v-carousel-item v-for="item in staffBirthday" :key="item">
				<v-icon x-small color="primary" class="mr-1">fas fa-birthday-cake</v-icon> Happy Birthday {{item}}!
			</v-carousel-item>
		</v-carousel>
	</div>
</template>
<script>
	import moment from "moment";

	export default {
		name: "announcements",
		props: {
			divider: {
				type: Boolean,
				default: true
			}
		},

		computed: {
			staffAnniversary() {
				return this.$db
					.findModels("person", (person) => {
						return (
							person.employee &&
							person.inactive == 0 && 
							moment(person.hired_date).isValid() &&
							moment(person.hired_date).month() ===
								this.$store.getters.currentDay.month()
						);
					})
					.map((person) => {
						let years = Math.round(
							this.$store.getters.currentDay.diff(
								person.hired_date,
								"year",
								true
							)
						);
						if (years >= 1) {
							let yearText = years == 1 ? 'yr' : 'yrs';
							return `${person.first_name} (${years} ${yearText})`;
						}
					})
					.filter((t) => t);
			},
			staffBirthday() {
				return this.$db
					.findModels("person", (person) => {
						let userBirthday = moment(person.meta_birthday);

						if (
							person.employee &&
							person.inactive == 0 && 
							person.meta_birthday &&
							userBirthday.isValid() &&
							userBirthday.format("MM/DD") ===
								moment().format("MM/DD")
						) {
							return true;
						}
					})
					.map((person) => {
						return person.first_name;
					});
			},
		}
	};
</script>