<template>
	<div>
		This is your start page. You can customize where it takes you at
		<router-link :to="{name: 'employee.details', params: {id: $store.getters.userId}}">your account page</router-link>.
	</div>
</template>
<script>
    // Home is more of a dispatcher
    // It will forward users on to whatever they want their home to be
    export default {
		name: 'home',
		
		computed: {
			me() {
				return this.$store.getters.userData;
			}
		},

		watch: {
			me: {
				handler(to) {
					if (to && to.custom_home) {
						this.$router.replace(to.custom_home);
					}
				},
				immediate: true
			}
		}
    }
</script>