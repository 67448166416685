var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.$apollo.queries.persons.loading}},[_c('v-card-title',[_vm._v(" Staff "),_c('v-text-field',{staticClass:"ml-10 mt-0",attrs:{"label":"Search","dense":"","hide-details":"","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-switch',{staticClass:"ml-3",attrs:{"label":"Show Past Employees"},model:{value:(_vm.showPastEmployees),callback:function ($$v) {_vm.showPastEmployees=$$v},expression:"showPastEmployees"}}),_c('v-btn',{staticClass:"ml-10",attrs:{"small":""},on:{"click":function($event){return _vm.$apollo.queries.persons.refetch()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-sync")])],1)],1),(_vm.employees)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.employees,"search":_vm.filter,"dense":"","calculate-widths":"","disable-pagination":"","hide-default-footer":"","sort-by":"first_name"},scopedSlots:_vm._u([{key:"item.meta_out_today",fn:function(ref){
var item = ref.item;
return [(!item.inactive)?_c('v-switch',{staticClass:"mb-1 my-0",attrs:{"input-value":item.meta_out_today,"hide-details":"","color":"toggle"},on:{"change":function($event){return _vm.updateUser(item, $event)}}}):_vm._e()]}},{key:"item.meta_avatar",fn:function(ref){
var item = ref.item;
return [_c('user-avataaar',{attrs:{"user":item}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","title":"Edit Person","min-width":"0"},on:{"click":function($event){return _vm.editPerson(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-pencil")]),_c('span',{staticClass:"sr-only"},[_vm._v("Edit")])],1),(item.id)?_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","to":{ name: 'employee.details', params: { id: item.id } },"min-width":"0"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-link")])],1):_vm._e()]}},{key:"item.hired_date",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'primary--text': _vm.isThisMonth(item.hired_date)}},[_vm._v(_vm._s(_vm._f("shortDate")(item.hired_date))+" ")])]}},{key:"item.meta_birthday",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'primary--text': item.meta_birthday != '' && _vm.isToday(item.meta_birthday)}},[_vm._v(_vm._s(_vm._f("birthDate")(item.meta_birthday)))])]}}],null,false,2704926360)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }