<template>
	<div class="d-inline-block">
		<slot></slot>
		<template v-if="task"> the {{taskOrSubtask}} <a @click="showTask" href="javascript:void(0)">{{task.name}}</a> 
			<template v-if="assignedToUser"> to <router-link :to="assignedToUser">{{ assignedToUser | FullName }}</router-link></template> for 
		</template>
		<template v-if="project">the project <router-link :to="{name: 'project.details', params: {id: project.id}}" v-html="project.name" target="_blank"></router-link> for </template>
		<template v-if="client"> the client <router-link :to="{name: 'client.details', params: {id: client.id}}" v-html="client.name"  target="_blank"></router-link></template>.
	</div>
</template>
<script>

    export default {
        name: 'task-project-client-link',
        components: {
        },
        props: ['log'],
        computed: {
            taskOrSubtask() {
              return this.log.data.parent_id>0?'subtask':'task';
			},
            task() {
                if (this.log.data && this.log.data.task_id && this.log.data.task_name) {
                    return {
                        id:   this.log.data.task_id,
                        name: this.log.data.task_name
                    };
                } else {
                    return null;
                }
            },
            project() {
                let project= this.$db.getModel('project', this.log.data.project_id);
                if (this.log.data.project_id && !project){
                    this.$xhrRequest.send('get', `/api/project/${this.log.data.project_id}`).then((model)=>{
                        this.$db.addModels('project', model, 'projects');
                    })
                }
                return project
            },

            client() {
                if (!this.log.data.client_id && this.project) {
                    this.log.data.client_id = this.project.client_id;
                }
                let client = this.$db.getModel('client', this.log.data.client_id);
                if (this.log.data.client_id && !client){
                    this.$xhrRequest.send('get', `/api/client/${this.log.data.client_id}`).then((model)=>{
                        this.$db.addModels('client', model, 'clients');
                    })
                }
                return client;
            },
            assignedToUser() {
                if (this.log.new_data && this.log.new_data.assigned_to_user_id > 0) {
                    return this.$db.getModel('person', this.log.new_data.assigned_to_user_id);
                } else {
                    return null;
                }
            }
        },
        methods: {
            getTask() {
                return new Promise((resolve, reject) => {
                    let taskObj = this.$db.getModel('task', this.task.id);
                    if (!taskObj) {
                        console.log('fetching', this.task.id);
                        this.$xhrRequest.send('get', `/api/task/${this.task.id}`).then((data) => {
                            resolve(data);
                        });
                    } else {
                        // haha, not likely
                        resolve(taskObj);
                    }
                });
            },
            showTask() {
                this.getTask().then((taskObj) => {
                    this.$modalService.create('taskAddEdit', {
                        taskId: taskObj.id
                    });
                });
            }
        },
    }
</script>
