<template>
	<v-card :class="{[`note-${note.note_type}`]: true, 'font-italic grey--text': hidden}" class="note mb-1" :data-note-id="note.id" :data-past="past" :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark">
		<v-card-title>
			<div v-if="compact" class="body-1">{{note.date | shortDate}} - {{note.content}}</div>
			<template v-else>
				<div class="grey--text text--lighten-1 caption mr-2" title="Note Created">
					{{note.date | shortDate }} {{note.date | shortTime}}
				</div>
				<!--client_from, client_to, internal, status, ...-->
				<v-chip v-if="note.note_type == 'timer'" dark label x-small class="mr-2" :title="noteTypeHint">{{ noteTypeName }} {{ note.time }}</v-chip>
				<v-chip v-else label x-small class="mr-2" dark :title="noteTypeHint">{{ noteTypeName }}</v-chip>
				<v-chip v-if="note.author" label x-small dark :color="note.author.department" title="Note Author">{{note.author.full_name}}</v-chip>
			</template>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn small min-width="0" v-bind="attrs" v-on="on" class="ml-auto">
						<v-icon small>fas fa-ellipsis-h</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item @click="editNote()" :disabled="!canEdit">
						<v-list-item-title>Edit {{noteType}}</v-list-item-title>
					</v-list-item>
					<template v-if="hideable">
						<v-list-item @click="setHidden(!hidden)" :disabled="!canEdit">
							<v-list-item-title>{{hidden?'Unhide':'Hide'}} {{noteType}}</v-list-item-title>
						</v-list-item>
					</template>
					<v-list-item @click="deleteNote">
						<v-list-item-title>Delete {{noteType}}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-card-title>

		<v-card-text v-if="!compact">
			<a v-if="note.task" href="javascript:void(0)" @click="editLinkedTask(note)">{{note.task.name}}</a>
			<div v-html="content" class="new-lines"></div>
			<div v-if="note.mailto_users.length" class="mt-1">
				Emailed to: <v-chip v-for="i in note.mailto_users" :key="i.id" label x-small class="ma-1">{{i.full_name}}</v-chip>
			</div>
		</v-card-text>
	</v-card>

</template>

<script>
	import NoteModel from "@/models/note";
	import moment from "moment";
	import settings from "../utils/settings";

	export default {
		name: "noteListItem",

		components: {},
		props: {
			note: Object,
			compact: {
				type: Boolean,
				default: false,
			},
		},

		computed: {
			noteTypeHint() {
				if (this.note.note_type === "client_from") {
					return "Note From Client";
				}
				if (this.note.note_type === "client_to") {
					return "Note To Client";
				}
				if (this.note.note_type === "task") {
					return "Task Note";
				}
				if (this.note.note_type === "task_completed") {
					return "Task Completed Note";
				}
				if (
					this.note.note_type === "deadline" ||
					this.note.note_type === "reminder"
				) {
					return this.$options.filters.capitalize(this.note.note_type);
				}

				return (
					this.$options.filters.capitalize(this.note.note_type) + " Note"
				);
			},
			noteTypeName() {
				if (this.note.note_type === "client_from") {
					return "From Client";
				}
				if (this.note.note_type === "client_to") {
					return "To Client";
				}
				if (this.note.note_type === "task_completed") {
					return "Task";
				}
				return this.$options.filters.capitalize(this.note.note_type);
			},
			/**
			 * not to be confused with noteTypeName...
			 * @returns {string}
			 */
			noteType() {
				if (this.note.note_type == "deadline") {
					return "Deadline";
				}
				if (this.note.note_type == "reminder") {
					return "Reminder";
				}
				return "Note";
			},
			hideable() {
				return "deadline reminder".indexOf(this.note.note_type) >= 0;
			},
			hidden() {
				return this.note.hidden == 1;
			},
			past() {
				return moment().isSameOrAfter(this.note.date);
			},
			canEdit() {
				return (
					this.note.author.id == this.$store.getters.userId ||
					this.$store.getters.hasPermission("chuckit_dev")
				);
			},
			canDelete() {
				return this.note.author.id == this.$store.getters.userId;
			},
			content() {
				// this.note.content = this.$options.filters.cleanHtml(
				// 	this.note.content
				// );
				return this.note.content;
			},
		},
		methods: {
			editLinkedTask(note) {
				this.$modalService
					.create("taskAddEdit", {
						taskId: note.task.id,
					})
					.on("save", (e, task) => {
						// this.$db.addModels("task", task);
						this.$emit("update");
						// update shadow copy too
						this.note.task = task;
					});
			},
			deleteNote() {
				this.$swal({
					title: `Delete this ${this.noteType.toLowerCase()}?`,
					text: "You cannot undo this!",
					icon: "warning",
					buttons: true,
					dangerMode: true,
				}).then((willDelete) => {
					if (willDelete) {
						this.$apollo
							.mutate({
								mutation: gql`
									mutation ($id: ID!) {
										deleteNote(id: $id)
									}
								`,
								variables: {
									id: this.note.id,
								},
								update: (store, { data: { deleteNote } }) => {
									this.$snotify.success(
										`${this.noteType} deleted.`,
										"Success"
									);

									this.$emit("update", {
										id: this.note.id,
										note: null,
									});
								},
							})
							.catch((error) => {
								console.error("error deleting note", error);
								this.$snotify.error("Error deleting note", "Error");
							});
					}
				});
			},
			editNote() {
				this.$modalService
					.create("noteAddEdit", {
						noteId: this.note.id,
						// showType: this.note.note_type.indexOf("task") < 0,
					})
					.on("save", (e, note) => {
						// this.$db.addModels("note", note);
						this.$emit("update", {
							id: this.note.id,
							note,
						});
					});
			},
			setHidden(isHidden) {
				this.$apollo
					.mutate({
						mutation: gql`
							mutation ($data: UpdateNoteArgs!) {
								updateNote(data: $data) {
									id
									hidden
								}
							}
						`,
						variables: {
							data: {
								id: this.note.id,
								hidden: isHidden
							}
						},
						update: (store, { data: { updateNote } }) => {
							this.$snotify.success(
								`The ${this.note.note_type} has been hidden.`,
								"Success!"
							);
						},
					})
					.catch((error) => {
						console.error("error updating note", error);
						this.$snotify.error("Note cannot be hidden", "Error");
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.note {
		border-left-width: 5px;
		border-left-style: solid;
		border-left-color: var(--v-accent-base);
		&.note-status {
			border-left-color: var(--v-tertiary-base);
		}
		&.note-reminder {
			border-left-color: var(--v-reminder-base);
		}
		&.note-deadline {
			border-left-color: var(--v-deadline-base);
		}
		&.note-programming {
			border-left-style: double;
			border-left-color: var(--v-developer-base);
		}
	}
</style>
