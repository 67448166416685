import settings from '../utils/settings';

export default function (query, variables) {
	let sessionId = localStorage.getItem(settings.sessionIdCacheKey) || '';

	return fetch(`${settings.url}/graphql`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-Auth': sessionId
		},
		body: JSON.stringify({
			query,
			variables
		})
	})
	.then(r => {
		let resp = r.json();
		if (resp.errors) {
			resp.errors = resp.errors.map(e => {
				return {
					type: e.extensions.category,
					message: e.message,
					path: e.path
				};
			});
		}

		return resp;
	});
};