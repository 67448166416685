<template>
	<div>
		<v-card class="my-3">
			<v-card-title>Tasks by Employee</v-card-title>
			<v-card-subtitle class="d-flex align-center">
				Legend:
				<div class="legend ma-2">
					<v-chip dark small color="overdue" class="mx-1" label>Overdue</v-chip>
					<v-chip dark small color="today" class="mx-1" label>Today</v-chip>
					<v-chip dark small color="soon" class="mx-1" label>Soon</v-chip>
					<v-chip dark small color="later" class="mx-1" label>Later</v-chip>
				</div>
			</v-card-subtitle>
		</v-card>
		<!-- TODO: include staff with no tasks -->
		<v-row v-if="sizedStaffChartData" class="justify-center">
			<v-col v-for="depData in sizedStaffChartData" :key="depData.dep" :cols="depData.colSize">
				<v-card dark :color="depData.dep.toLowerCase()" outlined>
					<v-card-title>{{depData.dep}}</v-card-title>
					<v-card-text>
						<GChart type="ColumnChart" :data="depData.data" :options="chartOptions" class="google-charts" />
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>

	export default {
		name: "staffTaskAnalytics",
		data() {
			return {
				refresh: 0,
				chartOptions: {
					chartArea: {
						width: "70%",
						height: "80%",
					},
					backgroundColor: {
						fill: this.$vuetify.theme.currentTheme["secondary"],
						fillOpacity: 0, //full transparent
					},
					isStacked: true,
					height: 500,
					width: "100%",
					legend: {
						position: "none",
						// maxLines: 3,
					},
					// bar: { groupWidth: "90%" },
					bar: { groupWidth: this.$vuetify.breakpoint.mdAndUp ? '50%' : 30 },
					hAxis: {
						textPosition: "out",
						textStyle: {
							color: "#fdf9ff",
							bold: true,
						},
					},
					vAxis: {
						textPosition: "out",
						minValue: 2,
						gridlines: {
							minSpacing: 25,
							color: this.$vuetify.theme.currentTheme["anchor"],
						},
						minorGridlines: {
							// deprecated but will force gridlines to disappear
							count: 0,
							color: this.$vuetify.theme.currentTheme["tertiary"],
						},
						textStyle: {
							color: "#fdf9ff",
							bold: true,
						},
					},
					series: {
						0: { color: this.$vuetify.theme.currentTheme["overdue"] },
						1: { color: this.$vuetify.theme.currentTheme["today"] },
						2: { color: this.$vuetify.theme.currentTheme["soon"] },
						3: { color: this.$vuetify.theme.currentTheme["later"] },
						// 4: { color: this.$vuetify.theme.currentTheme['pending'] }, // hold off on pending. It makes the useful data hard to read
					},
				},
			};
		},

		apollo: {
			tasks: {
				query: gql`
					query getDepartmentTaskCounts($filterParam: QueryFilter!) {
						tasks
							@filter(param: $filterParam)
							@sort(
								param: [
									{ field: "assigned_to_user_id", order: asc }
									{ field: "timeline_due_date", order: asc }
								]
							) {
							# @groupBy(fields: ["assigned_to_user_id"])
							# total
							nodes {
								node {
									# id
									# name
									due_status
									assigned_to_user_id
									assigned_to {
										full_name
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "=",
									value: "current",
								},
								{
									field: "project.status",
									op: "!=",
									value: "closed",
								},
								{
									field: "assigned_to.department",
									op: "!=",
									value: null,
								},
							],
						},
					};
				},
			},
		},

		computed: {
			groupedTasks() {
				if (!this.tasks || !this.tasks.nodes) {
					return [];
				}

				return Linq.from(this.tasks.nodes)
					.select(row => row.node)
					.groupBy(
						(g) => g.assigned_to.department,
						(dep, data) => {
							return {
								dep,
								people: data.count(),
								data: data
									.groupBy(
										(g) => g.assigned_to_user_id,
										(uid, data) => {
											return {
												user: data.first().assigned_to,
												data: data.aggregate((r, acc) => {
													acc[r.due_status] = (acc[r.due_status] || 0) + 1;
													return acc;
													}, {}),
											};
										}
									)
									.select((item) => [
										item.user.full_name,
										item.data.overdue || 0,
										item.data.today || 0,
										item.data.soon || 0,
										item.data.later || 0,
									])
									.prepend(['Staff Tasks', 'Overdue', 'Today', 'Soon', 'Later'])
									.toArray(),
							};
						}
					)
					.orderByDescending((i) => i.people)
					.toArray();
			},

			sizedStaffChartData() {
				if (!Array.isArray(this.groupedTasks)) {
					return false;
				}

				let sorted = this.groupedTasks
					.map((depData) => {
						let neededSize,
							records = depData.data.length - 1;

						switch (this.$vuetify.breakpoint.name) {
							case "xs":
								neededSize = 12;
								break;
							case "sm":
								if (records < 4) {
									neededSize = 6;
								} else {
									neededSize = 12;
								}
								break;
							case "xl":
								if (records < 4) {
									neededSize = 4;
								} else {
									neededSize = 8;
								}
								break;
							default:
								if (records > 6) {
									neededSize = 12;
								} else {
									neededSize = 6;
								}
								break;
						}

						return {
							...depData,
							colSize: neededSize,
						};
					})
					.sort((a, b) => b.colSize - a.colSize);

				return sorted;
			},
		},
	};
</script>