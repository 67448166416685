import AjaxResource from './base'
import settings from '../utils/settings'

export default new AjaxResource(
	settings.url + '/api/timer{/id}',
	{
		new: {
			url: settings.url + '/api/user/{userId}/timer',
			method: 'POST'
		},

		list: {
			url: settings.url + '/api/person/{userId}/timer',
			method: 'GET'
		},
        listForWeek: {
            url: settings.url + '/api/person/{userId}/timer?week={week}&fields=*,task',
            method: 'GET'
        },
		getAll: {
			url: settings.url + '/api/timer/list',
			method: 'GET'
		}
	}
);