<template>
	<div v-if="project">
		<v-card :loading="$apollo.queries.communication.loading">
			<v-card-title>
				Project Communication
				<v-btn @click="$apollo.queries.communication.refetch()" small class="ml-auto mr-3" min-width="0">
					<v-icon small>fad fa-sync</v-icon>
				</v-btn>
				<add-note-button obj-type="project" :obj-id="project.id" :showType="true" @save="$apollo.queries.communication.refetch()" class="btn-flat"></add-note-button>
			</v-card-title>
			<v-card-text v-if="notes.length">
				<v-timeline :dense="$vuetify.breakpoint.smAndDown">
					<v-timeline-item v-for="item in notes" :key="item.id" :color="dotColor(item.note_type)" :left="item.note_type == 'client_to'" :right="item.note_type != 'client_to'">
						<v-card :color="dotColor(item.note_type)" outlined>
							<v-card-title class="d-flex justify-space-between body-2 pt-0 white--text">
								<div>
									<strong>{{formatType(item.note_type)}}</strong>
								</div>
								<div class="d-flex align-center">

									{{item.date | shortDate}}
									<user-avataaar :uid="item.author.uid" style="width:40px;height:50px;" class="ml-2" :title="item.author.full_name"></user-avataaar>
								</div>
							</v-card-title>
							<v-card-subtitle class="background pt-2">
								{{item.author.full_name}}
							</v-card-subtitle>
							<v-card-text class="background" v-html="item.content"></v-card-text>
						</v-card>
					</v-timeline-item>
				</v-timeline>

			</v-card-text>
			<v-card-text v-else>
				<p>There is no communication yet</p>
			</v-card-text>
		</v-card>
	</div>
	<div v-else>
		no data
	</div>
</template>

<script>
	import addNoteButton from "@/components/addNoteButton";
	import UserAvataaar from "@/components/avataaars/user-avataaar";
	export default {
		name: "projectComms",
		components: { addNoteButton, UserAvataaar },
		inject: ["projectContext"],

		data() {
			return {};
		},
		apollo: {
			communication: {
				query: gql`
					query getCommunicationNotes($filterParam: QueryFilter!) {
						communication: notes 
						@filter(param: $filterParam) 
						@sort(param: [{field: "date" order: desc}])
						{
							nodes {
								node {
									id
									content
									modified_date
									date
									note_type
									author {
										id
										uid
										full_name
										meta_avatar
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "linked_object.uid",
									op: "=",
									value: this.project.uid,
								},
								{
									field: "note_type",
									op: "in",
									value: ["client_from", "client_to"],
								},
							],
						},
					};
				},
				skip() {
					return !this.project;
				},
			},
		},
		computed: {
			project() {
				return this.projectContext.project;
			},
			notes() {
				if (!this.communication) {
					return [];
				}
				let notes = Linq.from(this.communication.nodes)
					.select((row) => row.node)
					.toArray();

				return notes;
			},
		},
		methods: {
			dotColor(type) {
				if (type == "client_from") {
					return "primary";
				} else {
					return "secondary";
				}
			},
			formatType(type) {
				if (type == "client_from") {
					return "From Client";
				} else {
					return "To Client";
				}
			},
		},
	};
</script>