<template>

	<div>

		<v-row class="mb-8">
			<v-col v-if="otByYears && yearChartData" cols="12" md="4">
				<div class="text-h6 text-center">Yearly Overtime</div>
				<GChart type="ColumnChart" :data="yearChartData(otByYears)" :options="yearChartOptions" />
				<div v-if="timers" class="mt-2 caption text-center">{{timers.total.toLocaleString()}} timers were processed into these charts.</div>
			</v-col>
			<v-col v-if="otByMonths" cols="12" md="8">
				<div class="text-h6 text-center" style="z-index: 1">Monthly Overtime</div>
				<GChart type="ColumnChart" :data="monthChartData" :options="monthChartOptions" />
			</v-col>
		</v-row>
		
		
		<v-card class="mb-4">
			<v-card-title class="d-flex">
				Weekly Overtime
				<v-btn-toggle mandatory v-model="dataRange" class="ml-auto mr-3">
					<v-btn v-for="option in $options.dateRangeOptions" :key="option.value" small :value="option.value" class="text-none">
						{{option.text}}
					</v-btn>
				</v-btn-toggle>

				<div v-if="dataRange == 'custom'">
					<v-menu ref="rangeMenu" v-model="rangeMenu" :close-on-content-click="false" :return-value.sync="selectedDateRange" offset-y min-width="auto" bottom left>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="selectedDateRange" label="Select Week(s)" hide-details dense class="mx-4" prepend-icon="fad fa-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
						</template>
						<v-date-picker v-model="selectedDateRange" no-title range scrollable color="primary" :max="maxDate">
							<v-spacer></v-spacer>
							<v-btn text color="primary" @click="rangeMenu = false">
								Cancel
							</v-btn>
							<v-btn text color="primary" @click="$refs.rangeMenu.save(selectedDateRange)">
								OK
							</v-btn>
						</v-date-picker>
					</v-menu>
				</div>
			</v-card-title>
		</v-card>

		<v-row v-if="weeklyData" class="mb-8">
			<v-col cols="12" md="6" lg="4" xl="3" v-for="(item, key) in weeklyData" :key="key">
				<v-card class="fill-height">
					<v-card-title>
						<user-avataaar class="avatar mr-3" :user="item.user" style="max-width:50px;"></user-avataaar>
						{{item.user.first_name}}
						<v-chip label small class="ml-auto">Total Overtime: {{item.total}}</v-chip>
					</v-card-title>
					<GChart v-if="dataRange != 'week'" type="LineChart" :data="getValuesGoogle(item.ot)" :options="chartOptions" class="google-charts" />
					<v-card-text>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">Week</th>
										<th class="text-left">OT Hours</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(week, key) in item.ot" :key="key">
										<td>{{weekOf(week.date)}}</td>
										<td>
											{{week.ot_time}}
											<!-- if over 4 hours, alert for doubletime -->
											<v-icon v-if="week.ot_time >= 4 " small right color="error">fas fa-fire</v-icon>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

	</div>

</template>

<script>
	
	import moment from "moment";
	import UserAvataaar from "@/components/avataaars/user-avataaar";

	export default {
		name: "overtime",
		components: { UserAvataaar },

		data() {
			return {
				dataRange: "month",
				rangeMenu: false,
				maxDate: moment().endOf('week').subtract(1, 'weeks').format("YYYY-MM-DD"),
				selectedDateRange: [
					moment().startOf("week").subtract(8, "weeks").format("YYYY-MM-DD"),
					moment().startOf("week").subtract(1, 'days').format("YYYY-MM-DD"),
				],
				gradient: [
					"var(--v-overdue-base)",
					"var(--v-today-base)",
					"var(--v-soon-base)",
				],
				yearChartOptions: {
					/* chartArea: {
									width: "85%",
									height: "95%",
									top: 5,
								}, */
					backgroundColor: {
						fill: this.$vuetify.theme.currentTheme["cards"],
						fillOpacity: 0.6,
					},

					//isStacked = true|percent|relative
					// isStacked: true,
					height: 300,
					// width: 887,
					legend: { position: "none" },
					/* title: "Yearly OT",
								titleTextStyle: {
									color: this.$vuetify.theme.currentTheme["text-color"]
								}, */
					// bar: {groupWidth: '90%'},
					hAxis: {
						textPosition: "out",
						textStyle: {
							color: this.$vuetify.theme.currentTheme["text-color"],
							bold: true,
						},
					},
					vAxis: {
						baselineColor: this.$vuetify.theme.currentTheme["dividers"],
						// textPosition: "none",
						gridlines: {
							minSpacing: 25,
							color: this.$vuetify.theme.currentTheme["dividers"],
						},
						minorGridlines: {
							// deprecated but will force gridlines to disappear
							count: 0,
							color: this.$vuetify.theme.currentTheme["dividers"],
						},
						textStyle: {
							color: this.$vuetify.theme.currentTheme["text-color"],
							// bold: true,
						},
					},
					series: {
						0: {
							color: this.$vuetify.theme.currentTheme["today"],
						},
					},
				},
				monthChartOptions: {
					/* chartArea: {
									width: "85%",
									height: "80%",
									top: 50,
								}, */
					/* title: "Monthly OT",
								titleTextStyle: {
									color: this.$vuetify.theme.currentTheme["text-color"]
								}, */
					backgroundColor: {
						fill: this.$vuetify.theme.currentTheme["cards"],
						fillOpacity: 0.6,
					},
					height: 300,
					// width: 887,
					legend: {
						position: "right",
						textStyle: {
							color: this.$vuetify.theme.currentTheme["text-color"],
							fontSize: 16,
						},
					},
					// bar: {groupWidth: '90%'},
					hAxis: {
						textPosition: "out",
						textStyle: {
							color: this.$vuetify.theme.currentTheme["text-color"],
							bold: true,
							fontSize: 14,
						},
					},
					vAxis: {
						baselineColor: this.$vuetify.theme.currentTheme["dividers"],
						// textPosition: "none",
						gridlines: {
							minSpacing: 25,
							color: this.$vuetify.theme.currentTheme["dividers"],
						},
						minorGridlines: {
							// deprecated but will force gridlines to disappear
							count: 0,
							color: this.$vuetify.theme.currentTheme["dividers"],
						},
						textStyle: {
							color: this.$vuetify.theme.currentTheme["text-color"],
							// bold: true,
						},
					},
					series: {
						0: { color: this.$vuetify.theme.currentTheme["dividers"] }, //fix for space-dark theme. maybe use "odd" for it only
						1: { color: this.$vuetify.theme.currentTheme["today"] },
					},
				},
			};
		},

		dateRangeOptions: [
			{
				value: "week",
				text: "Last Week",
			},
			{
				value: "month",
				text: "Month",
			},
			{
				value: "custom",
				text: "Custom",
			},
		],

		apollo: {
		/* 	overtimeHours: {
				query: gql`
					query getEmployeeOvertime($start: DateTime!, $end: DateTime!) {
						overtimeHours: dailyOvertimeHours(
							start: $start
							end: $end
						) {
							user {
								id
								first_name
								department @lowercase
								meta_avatar
							}
							user_id
							date
							total_time
							ot_time
							# sql
						}
					}
				`,
				variables() {
					return {
						start: moment().startOf("week").format("YYYY-MM-DD"),
						end: moment().format("YYYY-MM-DD"),
					};
				},
			}, */
			weeklyOvertimeHours: {
				query: gql`
					query getOvertimeByWeek($start: DateTime!, $end: DateTime!) {
						weeklyOvertimeHours(after: $start, before: $end) {
							user {
								id
								first_name
								department @lowercase
								meta_avatar
							}
							date
							ot_time @formatDuration(format: "H.2", number: true)
						}
					}
				`,
				variables() {
					return {
						start: this.start_date,
						end: this.end_date,
					};
				},
			},
			otByYears: {
				query: gql`
					query getOvertimeByYears($start: DateTime!, $end: DateTime!) {
						otByYears: periodOvertimeHours(
							after: $start
							before: $end
							period: year
						) {
							date
							ot_time @formatDuration(format: "H.2", number: true)
						}
					}
				`,
				variables() {
					return {
						start: "2017-01-01",
						end: moment().endOf("year").format("YYYY-MM-DD"),
					};
				},
			},
			otByMonths: {
				query: gql`
					query getOvertimeByMonths($start: DateTime!, $end: DateTime!) {
						otByMonths: periodOvertimeHours(
							after: $start
							before: $end
							period: month
						) {
							date
							ot_time @formatDuration(format: "H.2", number: true)
						}
					}
				`,
				variables() {
					return {
						start: moment()
							.startOf("year")
							.subtract(1, "years")
							.format("YYYY-MM-DD"),
						end: moment().endOf("year").format("YYYY-MM-DD"),
					};
				},
			},
			timers: {
				query: gql`
					query getNumberOfTimers {
						timers {
							total
						}
					}
				`,
			},
		},

		computed: {
			correctSelectedDateRange() {
				if (this.selectedDateRange[0] <= this.selectedDateRange[1]) {
					return this.selectedDateRange;
				}
				else {
					return this.selectedDateRange.slice(0).reverse();
				}
			},
			start_date() {
				let date;

				if (this.dataRange == "custom") {
					date = moment(this.correctSelectedDateRange[0]).startOf('week');
				} else if(this.dataRange == "month") {
					date =  moment().startOf('week').subtract(4, 'weeks')
				} else {
					date = moment().startOf("week").subtract(1, "weeks")
				}

				return date.format("YYYY-MM-DD");
			},

			end_date() {
				let date;

				if (this.dataRange == "custom") {
					date = moment(this.correctSelectedDateRange[1]).startOf('week').add(1, 'weeks');
				} else if(this.dataRange == "month") {
					date = moment().startOf('week');
				}
				else {
					date = moment().startOf("week");
				}

				return date.format("YYYY-MM-DD");
			},

			users() {
				if (!this.overtimeHours) {
					return [];
				}

				let users = Linq.from(this.overtimeHours)
					.groupBy(
						(item) => item.user.id,
						(id, data) => {
							return {
								user: data.first().user,
								total: data.sum((i) => i.ot_time),
								data: data
									.select((item) => {
										return {
											date: item.date,
											total_time: item.total_time,
											ot_time: item.ot_time,
										};
									})
									.toArray(),
							};
						}
					)
					.orderByDescending((i) => i.total)
					.aggregate((r, acc) => {
						acc[r.user.id] = r;
						return acc;
					}, {});

				return users;
			},

			min() {
				if (!this.weeklyOvertimeHours) {
					return 0;
				}

				return Linq.from(this.weeklyOvertimeHours).min((record) => record.ot_time)
					.ot_time;
			},

			max() {
				if (!this.weeklyOvertimeHours) {
					return 10000;
				}
				return Linq.from(this.weeklyOvertimeHours).max((record) => record.ot_time)
					.ot_time;
			},

			chartOptions() {
				return {
					chartArea: {
						width: "100%",
						height: "80%",
						animation: {
							startup: true,
							duration: 600,
							easing: "in",
						},
					},
					backgroundColor: {
						fill: this.$vuetify.theme.currentTheme["secondary"],
						fillOpacity: 0, //full transparent
					},
					// isStacked: true,
					height: 100,
					width: "100%",
					legend: {
						position: "none",
						// maxLines: 3,
					},
					// bar: { groupWidth: "90%" },
					hAxis: {
						textPosition: "none",
					},
					vAxis: {
						textPosition: "none",
						minValue: 0,
						viewWindow: {
							max: this.max,
							min: this.min,
						},
						gridlines: {
							minSpacing: 25,
							color: this.$vuetify.theme.currentTheme["odd"],
							// interval: 0,
						},
						minorGridlines: {
							// deprecated but will force gridlines to disappear
							count: 0,
							color: this.$vuetify.theme.currentTheme["tertiary"],
						},
						textStyle: {
							color: "#fdf9ff",
							bold: true,
						},
					},
					series: {
						0: { color: this.$vuetify.theme.currentTheme["error"] },
					},
				};
			},

			monthChartData() {
				if (!this.otByMonths) {
					return null;
				}

				let years = Linq.from(this.otByMonths)
					.select((r) => moment(r.date).format("YYYY"))
					.distinct()
					.orderBy((r) => r)
					.toArray();

				return Linq.from(this.otByMonths)
					.groupBy(
						(r) => moment(r.date).format("MMMM"),
						(month, items) => {
							return items
								.orderBy((r) => r.date)
								.select((r) => r.ot_time)
								.prepend(month)
								.concat(
									new Array(years.length - items.count()).fill(0)
								)
								.toArray();
						}
					)
					.prepend(["Year"].concat(years))
					.toArray();
			},

			weeklyData() {
				if (!this.weeklyOvertimeHours) {
					return [];
				}
				return Linq.from(this.weeklyOvertimeHours)
					.groupBy(
						(i) => i.user.id,
						(id, ot) => {
							return {
								id,
								user: ot.first().user,
								total: ot.sum((i) => i.ot_time).toFixed(2),
								ot: ot
									.select((r) => ({
										date: r.date,
										ot_time: r.ot_time,
									}))
									.toArray(),
								// daily: this.users[id] ? this.users[id].data : [],
							};
						}
					)
					.orderByDescending((i) => i.total)
					.toArray();
			},
		},

		methods: {
			weekOf(date) {
				let start = moment(date).startOf('week').format('MMM Do');
				let end =  moment(date).endOf('week').format('MMM Do');
				return start + " - " + end;
			},

			getValuesGoogle(data) {
				let temp = [];

				temp.push(["date", "ot"]);

				data.forEach((item) => {
					temp.push([item.date, item.ot_time]);
				});

				return temp;
			},

			yearChartData(data) {
				let temp = [];

				temp.push(["Year", "Overtime Hours"]);

				data.forEach((item) => {
					temp.push([moment(item.date).format("Y"), item.ot_time]);
				});

				return temp;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.v-btn--disabled {
		opacity: 0.3;
	}
</style>
