import moment from 'moment';
import globalMixins from './global-mixin'

export default {
    computed: {
        project() {
            let model = this.task || this.timer;
            return this.$db.getModel('project', model && model.project_id);
        },

        client() {
            return this.$db.getModel('client', this.project && this.project.client_id);
        },

        subtasks() {
            if (!this.task) {
                return [];
            }

            return this.$db.findModels('task', (task) => {
                return this.task.id == task.parent_id;
            });
        },

        subtasksComplete() {
            if (!this.subtasks) {
                return null;
            }

            let complete = true;

            this.subtasks.forEach((task) => {
                complete = complete && task.status == 'complete';
            });

            return complete;
        },

        completeable() {
            if (!this.task) {
                return false;
            }

            let timer = this.$db.findFirstModel("timer", timer => {
                return timer.task_id == this.task.id && !timer.submit_date;
            });

            return !timer;
        },

        starred() {
            if (this.task && this.task.status == 'current' && this.project) {
                return this.project.meta_starred == 'yes';
            }

            return false;
        },

        pastDue() {
            let days = this.$insight.tasks.taskOverdueDays(this.task);
            return days > 0 ? `Past due ${days + (days === 1 ? ' day' : ' days')}` : null;
        }
    },

    methods: {
        unPinFromCurrent() {
            this.setTaskOptions(this.task, {pinToCurrent: false});
            this.$snotify.success(`Task returned to your pending list.`)
        },
        /**
         * Force a pending task to appear in current tasks.
         */
        pinToCurrent() {
            this.setTaskOptions(this.task, {pinToCurrent: true});
            this.$snotify.success(`Task pinned`);
        },
        unSnoozeTask() {
            this.setTaskOptions(this.task, {
                timeline_due_date: null,
                hideUntil:         null
            });

            this.$snotify.success(`Task returned to your current list.`)
        },
        snoozeTask() {
            this.$modalService.create('snoozeTask', {
                task: this.task
            }).on('save', (event, task, date) => {
                console.log('snoozeTask:', task, date);
                this.setTaskOptions(task, {
                    hideUntil:         date,
                    timeline_due_date: task.timeline_due_date
                });
                this.$snotify.success(`Task snoozed until ${date}`)
            });
        },
        onTaskClick(e) {
            if (e.ctrlKey) {
                this.$router.push({
                    to:     'task.details',
                    params: {id: this.task.id}
                });
            } else {
                this.$modalService
                    .create('taskAddEdit', {
                        taskId: this.task.id
                    })
                    .on('save', (e, task) => {
                        this.$db.addModels('task', task);
                    });
            }
        },

        getSubtaskStatus(subtask) {
            if (subtask.status == 'current') {
                let timer = this.$db.findFirstModel('timer', (timer) => {
                    return timer.task_id == subtask.id && !timer.submit_date;
                });
                return timer ? 'in progress' : 'current';
            } else {
                return subtask.status;
            }
        },

        reAssignTask() {
            this.$modalService
                .create('reAssignTask', {
                    taskId: this.task.id
                });
        },

        completeTask() {
            // Add note
            this.$modalService.create("noteAddEdit", {
                objType:     "task",
                objId:       this.task.id,
                // showType:    false,
                noteType:    'task_completed',
                title:       `Complete Task: ${this.task.name}`,
                saveBtnText: 'Submit',
                // skipBtn:     true
            }).on('skip save', () => {
                let completeRequest = this.$xhrRequest.chainStart();

                if (this.task.current_timer) {
                    completeRequest.send('put', `/api/timer/${this.timer.id}`, {submit: 1}).then((timer) => {
                        this.$db.addModels('timer', timer);
                    });
                }

                completeRequest.send('put', `/api/task/${this.task.id}?fields=*,project,client,current_timer`, {status: 'complete'}).then((task) => {
                    this.$db.addModels('task', task);
                    this.$snotify.success(
                        `The task '${
                            task.name
                            }' has been marked as completed!`,
                        'Task Completed'
                    );
                });

                completeRequest.chainEnd().catch(() => {
                    this.$snotify.error("Error!", "Complete Task");
                });
            });
        },
    }
};