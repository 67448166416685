<template>
	<v-card class="active-timers" elevation="4">
		<v-card-title>
			<v-icon class="mr-3" color="grey darken-1">fad fa-clock</v-icon>Active Timers
		</v-card-title>
		<v-card-text>
			<template v-if="activeTimers">
				<div v-for="timer in activeTimers" :key="timer.uid" class="d-flex justify-space-between mb-1">
					<div>
						<strong class="mr-2">{{timer.user.full_name || ''}}</strong>
						<span v-if="timer.client" class="font-weight-light">{{timer.client.name}}</span>
						<template v-else>[NO CLIENT]</template>
						:
						<span v-if="timer.project" class="font-weight-light">{{timer.project.name}}</span>
						<template v-else>[NO PROJECT]</template>
					</div>
					<div>
						<timer-clock :timer="timer"></timer-clock>
					</div>
				</div>
			</template>
			<template v-if="activeTimers && !activeTimers.length">
				No active timers at this time.
			</template>
		</v-card-text>
	</v-card>
</template>

<script>
	import TimerClock from "@/components/timerClock";

	export default {
		name: "active-timers",
		components: { TimerClock },
		data() {
			return {};
		},
		apollo: {
			activeTimers: {
				query: gql`
					query getActiveTimers($filterParam: QueryFilter!) {
						activeTimers: timers @filter(param: $filterParam) {
							nodes {
								node {
									id
									uid
									start
									user {
										id
										full_name
									}
									client {
										id
										name
									}
									project {
										id
										name
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "=",
									value: "Active",
								},
							],
						},
					};
				},
				update(serverData) {
					return Linq.from(serverData.activeTimers.nodes)
						.select((row) => row.node)
						.toArray()
				},
				pollInterval: timeInterval('2 minutes'),
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>