<script>
	import VAutocomplete from 'vuetify/lib/components/VAutocomplete';

	function objectWithoutKeys(obj, keys) {
		let result = {};
		for (let i in obj) {
			if (keys.indexOf(i) < 0) {
				result[i] = obj[i];
			}
		}

		return result;
	}
	
	export default {
		model: {
			prop: 'value',
			event: 'input'
		},

		props: {
			...objectWithoutKeys(VAutocomplete.options.props, ['items', 'item-text', 'item-value', 'item-disabled']),

			statuses: {
				type: Array,
				default: () => ['active', 'hidden']
			},

			filter: {
				type: Function,
				default: null
			}
		},

		apollo: {
			clientProjects: {
				query: gql`
					query GetClientProjects($clientFilter: QueryFilter!, $projectFilter: QueryFilter!, $clientSort: [SortField]!, $projectSort: [SortField]!) {
						clientProjects: clients
							@filter(param: $clientFilter)
							@sort(param: $clientSort)
						{
							nodes {
								node {
									id
									name
									meta_status
									projects
										@filter(param: $projectFilter)
										@sort(param: $projectSort)
									{
										id
										name
										status
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						clientFilter: {
							field: 'projects.status',
							op: 'in',
							value: this.statuses
						},
						clientSort: [{
							field: 'name',
							order: 'asc'
						}],
						projectFilter: {
							field: 'status',
							op: 'in',
							value: this.statuses
						},
						projectSort: [{
							field: 'name',
							order: 'asc'
						}]
					}
				}
			}
		},

		computed: {
			items() {
				if (!this.clientProjects) {
					return [];
				}

				return Linq.from(this.clientProjects.nodes)
					.select(row => row.node)
					.selectMany(c => {
						return [
							{ header: c.name, projects: c.projects },
							...Linq.from(c.projects)
								.select(p => ({ ...p, _clientName: c.name }))
								.toArray(),
							{ divider: true }
						];
					})
					.toArray();
			}
		},

		render(h) {
			return h(
				VAutocomplete,
				{
					props: {
						...this.$props,
						items: this.items,
						filter: this.filter || this.searchClientProjects,
						itemText: 'name',
						itemValue: 'id',
						loading: this.loading || this.$apollo.queries.clientProjects.loading
					},
					scopedSlots: this.$scopedSlots,
					on: this.$listeners
				}
			);
		}
	}
</script>