var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-speed-dial',{staticClass:"ml-sm-4",attrs:{"direction":"bottom","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","dark":"","fab":"","x-small":_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndUp}},[(_vm.fab)?_c('v-icon',{attrs:{"x-small":_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("fal fa-times")]):_c('v-icon',{attrs:{"x-small":_vm.$vuetify.breakpoint.smAndDown,"small":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("fal fa-plus")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.emitToEventBus('app/menu/add-client')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-user")])],1)]}}])},[_c('span',[_vm._v("Add Client")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.emitToEventBus('app/menu/add-project')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-folders")])],1)]}}])},[_c('span',[_vm._v("Add Project")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.emitToEventBus('app/menu/add-task')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-sticky-note")])],1)]}}])},[_c('span',[_vm._v("Add Task")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.emitToEventBus('app/menu/add-contact')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-address-book")])],1)]}}])},[_c('span',[_vm._v("Add Contact")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"","to":{ name: 'meta.list' }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fab fa-wpforms")])],1)]}}])},[_c('span',[_vm._v("Metadata")])]),(_vm.$store.getters.hasPermission('chuckit_dev'))?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"","to":{ name: 'permissions' }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-key")])],1)]}}],null,false,2433178543)},[_c('span',[_vm._v("Permissions")])]):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":""},on:{"click":function($event){return _vm.emitToEventBus('app/menu/show-db')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-brackets-curly")])],1)]}}])},[_c('span',[_vm._v("Debug $Store")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }