<template>
	<v-card class="mb-2" :loading="$apollo.queries.projectStats.loading">
		<v-card-text v-if="projectStats">
			<v-row class="totals text-center">
				<v-col>
					<!-- todo: time_spent is returning string. -->
					<div class="text-h3 font-weight-bold">{{projectStats.time_spent | thousandSeparator}}</div>
					<div class="label">Hours Spent</div>
				</v-col>
				<template v-if="projectStats.hours_allocated">
				<v-divider vertical></v-divider>
				<v-col class="pa-3">
					<div class="text-h3 font-weight-bold">{{projectStats.hours_allocated | thousandSeparator}}</div>
					<div class="label">Hours Budgeted</div>
				</v-col>
				<v-divider vertical></v-divider>
				<v-col class="pa-3">
					<div class="text-h3 font-weight-bold">{{projectStats.budget_percentage | thousandSeparator}}%</div>
					<div class="label">Of Total Hours</div>
				</v-col>
				</template>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
	import { ProjectHourStats } from "@/graphql/queries";
	import { IdType } from "../../../utils/IdType";

	export default {
		name: "ProjectStatusHours",

		props: {
			pid: IdType,
		},

		apollo: {
			projectStats: {
				query() {
					return ProjectHourStats(this.pid);
				},
				pollInterval: timeInterval("5 minutes + 1 minute - 1 tup"),
				skip() {
					return !this.pid;
				},
			},
		},
	};
</script>