import Vue from 'vue';

// Inject functionality to default eventbus to support regex event names
class EventBusFactory {
	static create() {
		return EventBusFactory.hijack(new Vue());
	}

	static hijack(bus) {
		const regexListeners = {};

		const orig$emit = bus.$emit;
		bus.$emit = (event, ...args) => {
			for (let key in regexListeners) {
				if (regexListeners[key].regex.test(event)) {
					regexListeners[key].cbs.forEach(fn => fn(event, ...args));
				}
			}

			orig$emit.call(bus, event, ...args);
		};

		const orig$on = bus.$on;
		bus.$on = (event, fn) => {
			if (event instanceof RegExp) {
				let key = regexToString(event);
				if (!regexListeners[key]) {
					regexListeners[key] = {
						regex: event,
						cbs: []
					};
				}
				regexListeners[key].cbs.push(fn);
			}
			else {
				orig$on.call(bus, event, fn);
			}

			return () => {
				bus.$off(event, fn);
			};
		};

		const orig$off = bus.$off;
		bus.$off = function (event, fn) {
			if (event instanceof RegExp) {
				let key = regexToString(event);

				if (regexListeners[key]) {
					if (fn) {
						let ndx = regexListeners[key].cbs.findIndex(i => i === fn);

						if (ndx >= 0) {
							regexListeners[key].cbs.splice(ndx, 1);
						}
					}
					else {
						delete regexListeners[key];
					}
				}
			}
			else {
				return orig$off.apply(bus, Array.prototype.slice.call(arguments));
			}

		};

		return bus;
	}
}

function regexToString(regex) {
	return `/${regex.source}/${regex.flags}`;
}

export const EventBus = EventBusFactory.create();