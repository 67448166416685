export class IdType {
	value = null;

	constructor(val) {
		if (!IdType[Symbol.hasInstance](val)) {
			throw 'value is not a valid Id type';
		}
		
		this.value = val;
	}

	static [Symbol.hasInstance](instance) {
		if (instance == null || instance === undefined) {
			return true;
		}
		
		return instance.constructor === IdType || typeof instance == 'string' || typeof instance == 'number';
	}

	static [Symbol.toPrimitive](hint) {
		if (hint == 'number') {
			return parseInt(this.value);
		}
		if (hint == 'string') {
			return '' + this.value;
		}

		return this.value;
	}
}