<template>
	<v-container fluid>

		<v-card tile class="controls">
			<v-card-text class="d-flex flex-column flex-sm-row">
				<v-autocomplete dense hide-details v-model="user" :items="users" class="mr-sm-6"></v-autocomplete>
				<v-autocomplete dense hide-details v-model="week" :items="weeks"></v-autocomplete>
			</v-card-text>
		</v-card>

		<div v-if="timerNodes" class="table-responsive mb-10">
			<v-simple-table v-slot:default class="my-4" fixed-header>
				<thead>
					<tr>
						<th style="width:58%; min-width: 250px;">Client:Project - Service, Description</th>
						<th v-for="day in days" :key="day" class="day-name text-center" :data-day="day">{{name(day)}}<br>{{daysFormatted[day]}}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="timer in timerNodes" :key="timer.id">
						<td>
							<v-btn v-if="canEdit" x-small color="primary darken-1" class="mr-3" @click="toggleEdit(timer)">Edit</v-btn>
							<span v-if="timer.client">{{timer.client.name}}</span><span v-else>[NO CLIENT]</span>:
							<span v-if="timer.project">{{timer.project.name}}</span><span v-else>[NO PROJECT]</span> -
							<em class="text-caption">{{timer.description}}</em>
							<p></p>
							<timer-card v-if="editTimer[timer.id]" :timer="timer" @timerUpdated="timerUpdated"></timer-card>
						</td>
						<td v-for="day in days" :key="day" class="day-time" :data-day="day">
							<timer-clock :timer="timer" v-if="sameDay(day,timer)"></timer-clock>
						</td>
					</tr>
					<!-- summary -->
					<tr>
						<td><strong>{{Object.values(timerNodes).length}} Timers</strong></td>
						<td v-for="day in days" :key="day" class="day-time" :data-day="day">
							<timer-clock v-if="isCurrentDayAndUser(day)" :timers="$store.getters.myTimers"></timer-clock>
							<timer-clock v-else-if="dayTimers[day]" :timers="dayTimers[day]"></timer-clock>
						</td>
					</tr>
					<!-- reconcile -->
					<!-- <tr v-if="$store.getters.isAdminUser">
						<td><strong>Reconcile Time</strong></td>
						<td v-for="day in days" :key="day" class="day-reconcile text-center" :data-day="day">
							<v-btn small color="primary" v-if="dayTimers[day] && (week > 0 || (week == 0 && day < todayDay))" :disabled="!canReconcile(day)" @click="reconcileDay(day)">Reconcile</v-btn>
						</td>
					</tr> -->
				</tbody>
				<tfoot>
					<tr>
						<th style="width:58%; min-width: 250px;">Client:Project - Service, Description</th>
						<th v-for="day in days" :key="day" class="day-name text-center" :data-day="day">{{name(day)}}<br>{{daysFormatted[day]}}</th>
					</tr>
					<tr class="weekly-summary pr-2 odd">
						<td class="text-right">Total Week Time:</td>
						<td colspan="7">
							<timer-clock :timers="timerNodes"></timer-clock>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
			<div v-if="$store.getters.isAdminUser" class="text-center">
				<v-btn small color="button" @click="createTimer">Create Timer for {{ $db.getModel('person', user) | FullName }}</v-btn>
			</div>
		</div>

		<v-container v-else class="fill-height flex-column justify-center align-center">
			<p class="text-h6">Loading Weekly Timers</p>
			<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
		</v-container>
	</v-container>
</template>

<script>
	import moment from "moment";
	import TimerCard from "@/components/timerCard";
	import TimerClock from "@/components/timerClock";

	//TODO: Re-add this when caching is enabled
	// import { getActiveEmployees } from '@/graphql/queries';

	export default {
		name: "weeklyTimers",

		components: {
			TimerClock,
			TimerCard,
		},

		data() {
			return {
				days: [0, 1, 2, 3, 4, 5, 6],
				week: 0,
				user: this.$store.getters.userId,
				editTimer: {},
			};
		},

		apollo: {
			/* getActiveEmployees: {
					query() {
						return ActiveEmployees;
					},
				}, */
			activeEmployees: {
				query: gql`
					query GetPersons($filterParam: QueryFilter!) {
						activeEmployees: persons
							@filter(param: $filterParam)
							@sort(param: [{ field: "first_name", order: asc }]) {
							nodes {
								node {
									id
									full_name
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "is_employee",
							op: "=",
							value: true,
						},
						{
							field: "inactive",
							op: "=",
							value: false,
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
			weeklyTimers: {
				query: gql`
					query getWeeklyTimers($filterParam: QueryFilter!) {
						weeklyTimers: timers
							@filter(param: $filterParam)
							@sort(param: [{ field: "creation_date", order: asc }]) {
							nodes {
								node {
									id
									uid
									description
									job
									time_spent
									project_id
									client_id
									creation_date
									submit_date
									stop
									start
									client {
										id
										name
									}
									project {
										id
										name
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "user_id",
									op: "=",
									value: this.user,
								},
								{
									field: "creation_date",
									op: ">=",
									value: moment(this.weekStart).format(
										"YYYY-MM-DD"
									),
								},
								{
									field: "creation_date",
									op: "<",
									value: moment(this.weekStart)
										.add(1, "weeks")
										.format("YYYY-MM-DD"),
								},
							],
						},
					};
				},
			},
		},

		computed: {
			canEdit() {
				return (
					this.$store.getters.isAdminUser ||
					this.user == this.$store.getters.userId
				);
			},

			todayDay() {
				return this.$store.getters.currentDay.day();
			},

			weeks() {
				let m = moment().day(0),
					end = m.clone().subtract(52, "week"),
					options = [],
					ndx = 0;

				while (m >= end) {
					let weekStart = this.$options.filters.shortDate(m),
						weekEnd = this.$options.filters.shortDate(m.clone().day(6));

					options.push({
						value: ndx,
						weekStart,
						text: `${weekStart} - ${weekEnd}`,
						// selected: ndx == 0,
					});

					m.subtract(1, "week");
					ndx++;
				}

				return options;
			},

			weekStart() {
				return this.weeks[this.week].weekStart;
			},

			dayTimers() {
				if (!this.weeklyTimers) {
					return [];
				}
				let dailyTime = {};

				this.weeklyTimers.nodes
					.map((row) => row.node)
					.forEach((timer) => {
						let day = moment(timer.creation_date).day();
						if (typeof dailyTime[day] === "undefined") {
							dailyTime[day] = [];
						}

						dailyTime[day].push(timer);
					});

				return dailyTime;
			},

			users() {
				if (!this.activeEmployees) {
					return [];
				}

				if (!this.$store.getters.isAdminUser) {
					return [
						{
							value: this.$store.getters.userId,
							text: this.activeEmployees.nodes
								.map((row) => row.node)
								.find(
									(user) => user.id == this.$store.getters.userId
								).full_name,
						},
					];
				}

				return this.activeEmployees.nodes
					.map((row) => row.node)
					.map((person) => {
						return {
							value: person.id,
							text: person.full_name,
						};
					});
			},

			daysFormatted() {
				return this.days.map((num) =>
					moment(this.weekStart).add(num, "days").format("MM/DD")
				);
			},

			timerNodes() {
				if(!this.weeklyTimers) {
					return [];
				}

				return this.weeklyTimers.nodes
					.map(row => row.node)
			}
		},

		destroyed() {
			this.$db.removeHandle("weekly");
		},

		methods: {
			// TODO graphql mutations
			timerUpdated(timer) {
				this.$snotify.success(`Timer Updated`);
				this.$apollo.queries.weeklyTimers.refetch();

				// let weekStart = moment()
				// 	.startOf("week")
				// 	.subtract(this.week, "weeks");
				// let weekEnd = moment().endOf("week").subtract(this.week, "weeks");
				// let created = moment(timer.creation_date);
				// let belongs =
				// 	created.isSameOrAfter(weekStart) &&
				// 	created.isSameOrBefore(weekEnd);
				
				// if (!belongs) {
					// TODO: does this ever happen??
					/* this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: UpdateTimerArgs!) {
									updateTimer(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: {
									id: timer.id,
								},
							},
							update: (store, { data: { updateTimer } }) => {
								this.$snotify.success(
									`Timer moved to a different week.`
								);
							},
						})
						.catch((error) => {
							console.error("error updating avatar", error);
							this.modalErrorMessage = "There was a problem updating the timer.";
						}); */
					/* this.$xhrRequest
							.send(
								"get",
								`/api/person/${this.user}/timer?week=${this.weekStart}`,
								{ fields: "*,task" }
							)
							.then((timers) => {
								this.$db.removeHandle("weekly");
								this.$db.addModels("timer", timers, "weekly");
								this.$snotify.success(
									`Timer moved to a different week.`
								);
								
								return true;
							}); */
				// }
			},
			/**
			 * Create a timer on behalf of another person.
			 * By default will create the timer for yesterday (the most common use).
			 * If not viewing the current week, then the timer will be created for this same day during the currently-viewed week.
			 * @see timers.js action createTimer
			 */
			// TODO replace with graphql mutation
			createTimer() {
				let opts = {
					creation_date: moment().subtract(1, "days"),
					duration: "00:00:00",
					submit: 1,
					active: 0,
					description: `${
						this.$store.getters.userFullName
					} created on ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
				};
				if (this.week !== 0) {
					opts.creation_date = moment().subtract(this.week, "weeks");
				}
				opts.creation_date = opts.creation_date.format(
					"YYYY-MM-DD HH:mm:ss"
				);

				this.$xhrRequest
					.send("post", `/api/user/${this.user}/timer`, opts)
					.then((newTimer) => {
						this.$db.addModels("timer", newTimer, "weekly");
						let day = moment(opts.creation_date).format(
							"dddd, MMMM Do YYYY"
						);
						this.$snotify.success(
							`Created new timer for ${day}`,
							"Created timer"
						);
						this.$apollo.queries.weeklyTimers.refetch();
					})
					.catch(() => {
						this.$snotify.error("Error!", "Create Timer");
					});
			},
			toggleEdit(timer) {
				let show = this.editTimer[timer.id];
				this.$set(this.editTimer, timer.id, !show);
			},

			isCurrentDayAndUser(day) {
				return (
					this.week === 0 &&
					moment().day() === day &&
					this.user === this.$store.getters.userId
				);
			},

			name(day) {
				return moment().day(day).format("ddd");
			},

			// 0=sunday,6=saturday
			sameDay(day, timer) {
				return day === moment(timer.creation_date).day();
			},

			/** ! Temp deprecated. Might bring back if we go back to guardian system.
			canReconcile(day) {
				let runningTimer = this.dayTimers[day].find((timer) => {
					return timer.status == "Active";
				});

				return !runningTimer;
			}, 
			
			reconcileDay(day) {
				this.$modalService
					.create("reconcileTimers", {
						timers: this.dayTimers[day],
					})
					.on("save", (e) => {
						this.$apollo.queries.weeklyTimers.refetch();
					});
			}, */
		},
	};
</script>



<style lang="scss" scoped>
	.table-responsive {
		overflow-x: auto;
	}
	tbody {
		tr:nth(odd) {
			background-color: grey;
		}
	}
	.day-name,
	.day-time {
		min-width: 75px;
		text-align: center;
	}
</style>