<template>
	<div v-if="project" class="position-relative">
		<ckeditor v-if="projectNotepad" :editor="ckEditor.editor" :config="ckEditor.config" :value="projectNotepad.meta_notes" @ready="ckEditorReady" @input="ckeditorInput"></ckeditor>
		<v-btn class="save-btn-bottom primary" :disabled="!needsSaving" :loading="saving" @click="saveNotes">{{ needsSaving ? 'Save' : 'Up to date' }}</v-btn>
		<v-btn v-if="outOfDate" class="reload-btn-bottom primary" @click="reloadContent">Reload (Out of date!)</v-btn>
	</div>
	<div v-else>
		no data
	</div>
</template>

<script>
	import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
	import { addEventListenerMixin } from '../../../utils/AddEventListener';
	import { EventBus } from '../../../utils/EventBus';

	export default {
		name: "ProjectNotepad",
		mixins: [ addEventListenerMixin ],

		components: {},

		inject: ['projectContext'],
		
		data() {
			return {
				ckEditor: {
					editor: DecoupledEditor,
					config: {},
				},
				origContent: '',
				content: '',
				outOfDate: false,
				saving: false
			};
		},

		apollo: {
			projectNotepad: {
				query: gql`
					query GetProject_Notepad($id: ID!) {
						projectNotepad: project(id: $id) {
							id
							meta_notes
						}
					}
				`,
				variables() {
					return {
						id: this.project.id,
					};
				},
				skip() {
					return !this.project;
				},
			},
		},

		computed: {
			// content() {
			// 	if(!this.projectNotepad) {
			// 		return "";
			// 	}

			// 	return this.projectNotepad.meta_notes
			// },
			// origContent() {
			// 	if(!this.projectNotepad) {
			// 		return "";
			// 	}

			// 	return this.projectNotepad.meta_notes
			// },
			project() {
				return this.projectContext.project;
			},
			needsSaving() {
				return this.content != this.origContent;
			}
		},

		created() {
			this.addEventListener(document, 'keydown', this.documentKeyDown.bind(this));
		},

		methods: {
			documentKeyDown(e) {
				if (e.key == 's' && e.ctrlKey) {
					e.preventDefault();

					if (this.needsSaving) {
						this.saveNotes();
					}
				}
			},
			ckeditorInput(val) {
				this.content = val;
			},
			ckEditorReady(editor) {
				editor.ui
					.getEditableElement()
					.parentElement.insertBefore(
						editor.ui.view.toolbar.element,
						editor.ui.getEditableElement()
					);
			},

			saveNotes() {
				return new Promise((resolve, reject) => {
					let payload = { meta_notes: this.content };
	
					this.saving = true;

					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: UpdateProjectArgs!) {
									updateProject(data: $data) {
										id
										meta_notes
									}
								}
							`,
							variables: {
								data: {
									id: this.project.id,
									meta_notes: this.content
								}
							},
							update: (store, { data: { updateProject } }) => {
								this.origContent = this.content = updateProject.meta_notes;
	
								this.$snotify.success(
									"Notes saved!",
									"Project Updated"
								);

								resolve();
							},
						})
						.catch((error) => {
							this.$snotify.warning(
								"There was a problem updating the notes",
								"Updating failed"
							);
							reject();
						})
						.then(() => this.saving = false);
	
					/* // TODO: Use mutation
					this.$xhrRequest
						.send("put", `/api/project/${this.project.id}?fields=meta_notes`, payload)
						.then((project) => {
							this.origContent = this.content = project.meta_notes;
	
							this.$snotify.success(
								"Notes saved!",
								"Project Updated"
							);
						})
						.catch(() => {
							this.$snotify.warning(
								"There was a problem updating the notes",
								"Updating failed"
							);
							reject();
						})
						.then(() => {
							this.saving = false;
						}); */
				});
			},

			reloadContent() {
				let doWork = () => {
					this.content = this.project.meta_notes;
					this.origContent = this.project.meta_notes;
					this.outOfDate = false;
				};

				if (this.needsSaving) {
					this.$swal({
						title: "Reload notes?",
						text: `This will reload the notes. Any changes you have made will be discarded!`,
						icon: "warning",
						buttons: true,
						dangerMode: true,
					}).then((willReload) => {
						if (willReload) {
							doWork();
						}
					});
				}
				else {
					doWork();
				}
			}
		},

		watch: {
			project(to) {
				if (this.needsSaving) {
					this.outOfDate = to.meta_notes != this.origContent;
				}
				else {
					this.reloadContent();
				}
			},
			needsSaving: {
				immediate: true,
				handler(to) {
					if (to) {
						this._unsub = this.addEventListener(window, 'beforeunload', (e) => {
							e.preventDefault();
							e.returnValue = '';
							console.log('prevented?');
						});
					}
					else if (this._unsub) {
						this._unsub();
						this._unsub = false;
					}

					EventBus.$emit('projectNotepad/needs-saving', to ? this.saveNotes.bind(this) : false);

				}
			},
			projectNotepad(to) {
				this.origContent = this.content = to.meta_notes;
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .ck-content {
		color: map-get($grey, "darken-4");
		background-color: white;
		a {
			color: map-get($deep_purple, "base");
			font-weight: bold;
		}
		h1, h2, h3, h4, h5, h6 {
			margin-top: 20px;
			margin-bottom: 15px;
		}
	}
	::v-deep .ck-toolbar {
		position: sticky!important;
		top: 150px;
		z-index: 3;
	}
	.save-btn-bottom {
		position: absolute;
		bottom: 10px;
		right: 10px;
		z-index: 2;
	}
</style>