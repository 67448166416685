<template>
	<v-card>
		<v-card-title>
			<div v-html="caption"></div>
			<v-btn small title="Add Contact" min-width="0" @click="addContact" class="ml-auto">
				<div class="fa-stack">
					<v-icon small>fad fa-address-card</v-icon>
					<v-icon small color="primary">fal fa-plus</v-icon>
				</div>
			</v-btn>
		</v-card-title>
		<v-card-text>

			<div v-if="!items.length">There are no contacts.</div>
			<v-data-table v-else :headers="fields" :items="items" dense disable-pagination disable-sort hide-default-footer>

				<template v-slot:item.name="{ item }">
					<div>{{item.full_name}}</div>
				</template>

				<template v-slot:item.phone_primary="{ item }">
					<div v-if="item.phone_primary" class="text-no-wrap phone-number-wrap">
						Cell: <a v-if="item.phone_primary" :href="item.phone_primary | getPhoneLink" class="text-no-wrap">{{ item.phone_primary }}</a>
					</div>
					<div v-if="item.phone_secondary" class="text-no-wrap phone-number-wrap">
						Phone: <a :href="item.phone_secondary | getPhoneLink" class="text-no-wrap">{{ item.phone_secondary }}</a>
					</div>
					<div v-if="item.phone_office" class="text-no-wrap phone-number-wrap">
						Office: <a :href="item.phone_office | getPhoneLink" class="text-no-wrap">{{ item.phone_office }}</a>
					</div>
					<div v-if="item.phone_fax" class="text-no-wrap phone-number-wrap">
						Fax: <a :href="item.phone_fax | getPhoneLink" class="text-no-wrap">{{ item.phone_fax }}</a>
					</div>
				</template>

				<template v-slot:item.email_primary="{ item }">
					<div v-if="item.email_primary" class="text-no-wrap">
						<a :href="`mailto:${item.email_primary}`" class="text-no-wrap">{{ item.email_primary }}</a>
					</div>
					<div v-if="item.email_secondary" class="text-no-wrap">
						<a :href="`mailto:${item.email_secondary}`" class="text-no-wrap">{{ item.email_secondary }}</a>
					</div>
				</template>

				<template v-slot:item.view_user="{ item }">
					<div class="text-right d-flex" :data-id="item.id">
						<v-btn v-if="item.meta_person_hubspot_link" :href="item.meta_person_hubspot_link" target="_blank" small min-width="0" title="View on Hubspot">
							<v-icon small>fab fa-hubspot</v-icon>
						</v-btn>
						<v-btn small min-width="0" class="mx-1" @click="editUser(item)" title="Edit Contact">
							<v-icon small>fad fa-pencil-alt</v-icon>
						</v-btn>
						<!-- <v-btn small min-width="0" @click="removeUser(item)" title="Remove Contact">
							<v-icon small>fad fa-trash-alt</v-icon>
						</v-btn> -->
					</div>
				</template>

			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
	import { decomposeNodeId, triggerUidEditAction } from '../router/uid';
	import { EventBus } from "../utils/EventBus";
	import { IdType } from '../utils/IdType';
	export default {
		name: "ContactList",
		data() {
			return {};
		},
		props: {
			caption: {
				type: String,
				default: "Contacts",
			},
			uid: {
				type: IdType,
				required: true
			}
		},

		apollo: {
			contacts: {
				query: gql`
					query GetPersons($filterParam: QueryFilter!) {
						contacts: persons
							@sort(param: [{ field: "first_name", order: asc }])
							@filter(param: $filterParam)
						{
							sql
							nodes {
								node {
									id
									uid
									full_name
									phone_primary
									email_primary
									phone_secondary
									phone_office
									phone_fax
									email_secondary
									meta_person_hubspot_link
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							field: 'object_links.linked_object.uid',
							op: '=',
							value: this.uid
						}
					};
				},
			},
		},

		computed: {
			uidDetails() {
				return decomposeNodeId(this.uid);
			},
			items() {
				if (!this.contacts) {
					return [];
				}

				return this.contacts.nodes
					.map(row => row.node);
			},

			fields() {
				return [
					{
						value: "name",
						text: "Name",
					},
					{
						value: "phone_primary",
						text: "Phone",
					},
					{
						value: "email_primary",
						text: "Email",
					},
					{
						value: "view_user",
						text: "",
					},
				];
			}
		},
		methods: {
			addContact() {
				EventBus.$emit("app/menu/add-contact", {
					[`${this.uidDetails.object}Links`]: [this.uidDetails.objectId]
				});
			},
			removeUser(person) {
				throw 'not implemented';
				// this.$swal({
				// 	title: "Remove Contact",
				// 	text: `This will remove the contact`,
				// 	icon: "warning",
				// 	buttons: true,
				// 	dangerMode: true,
				// }).then((willDelete) => {
				// 	if (willDelete) {
				// 		this.$xhrRequest
				// 			.send(
				// 				"delete",
				// 				`/api/contact/${person.id}/project/${this.project.id}`
				// 			)
				// 			.then(() => {
				// 				let name = this.$options.filters.FullName(person);
				// 				this.$snotify.success(
				// 					`${name} has been removed from this project.`
				// 				);
				// 				this.$db.removeModels("person", person.id);
				// 				// issue the message from the top down, rather than bubbling up
				// 				// ideally we could just emit an event that would bubble upwards until a component consumed it.
				// 				// alternatively we could use an event bus
				// 				// this.$root.$children[0].handleMenu('refresh-project-contacts');
				// 			});
				// 	}
				// });
			},
			editUser(person) {
				triggerUidEditAction(person.uid)
					.on('save', (e) => {
						this.$apollo.queries.contacts.refetch();
					});
			},
		},
	};
</script>

<style scoped lang="scss">
</style>