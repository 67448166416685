<template>
	<!-- TODO: Add item slots -->
	<v-autocomplete v-if="items" :value="internalValue" @input="onChange" multiple chips deletable-chips small-chips :items="items" label="Preceding Tasks" placeholder="None. Can be started now" :disabled="disabled" dense outlined hide-details></v-autocomplete>
	<v-text-field v-else placeholder="Loading..." :disabled="true"></v-text-field>
</template>

<script>
	import { IdType } from '../../utils/IdType';

	export default {
		model: {
			prop: 'value',
			event: 'input'
		},

		props: {
			taskId: {
				type: IdType,
				required: true
			},
			value: {
				type: Array,
				default: () => []
			},
			disabled: {
				type: Boolean,
				default: false
			},

			taskGroupId: {
				type: IdType
			},
			allTasks: {
				type: Array,
				default: () => []
			}
		},

		data() {
			return {
				internalValue: this.value
			};
		},

		apollo: {
			tasks: {
				query: gql`
					query GetAllTaskGroupTasks($filter: QueryFilter!, $sort: [SortField]!) {
						tasks
							@filter(param: $filter)
							@sort(param: $sort)
						{
							nodes {
								node {
									id
									name
									completion_order
									parent_id
									previous_task_ids
									status
									description
									due_date
									required_days
									assigned_by_user_id
									assigned_to_user_id
									creation_date
									_meta_values
								}
							}
						}
					}
				`,
				variables() {
					return {
						filter: {
							field: 'task_group_id',
							op: '=',
							value: this.taskGroupId
						},
						sort: [{
							field: 'completion_order',
							order: 'asc'
						}]
					}
				},
				skip() {
					return this.allTasks.length > 0 || !this.taskGroupId;
				}
			}
		},

		computed: {
			resolvedAllTasks() {
				if (this.allTasks.length == 0) {
					if (!this.taskGroupId) {
						return [];
					}

					if (this.$apollo.queries.tasks.loading) {
						return null;
					}

					return this.tasks.nodes
						.map(row => row.node);
				}

				return this.allTasks;
			},

			taskMap() {
				if (!this.resolvedAllTasks) {
					return null;
				}

				let result = {};
				for (let task of this.resolvedAllTasks) {
					result[task.id] = task;
				}

				return result;
			},

			// Overpowered for our needs, reign this in
			taskAncestors() {
				if (!this.taskMap) {
					return null;
				}

				let result = {};
				for (let i = 0; i < this.resolvedAllTasks.length; i++) {
					let taskId = this.resolvedAllTasks[i].id;

					// Depth First Search optimizes this process
					let fringe = [taskId];
					while (fringe.length) {
						let id = fringe.pop();
						if (result[id]) {
							Object.assign(result[taskId], result[id]);
						}
						else {
							result[id] = {};
							for (let preId of (this.taskMap[id].previous_task_ids || [])) {
								if (this.taskMap[preId]) {
									fringe.push(preId);
									result[taskId][preId] = true;
								}
							}
						}
					}
				}

				return result;
			},

			items() {
				if (!this.taskAncestors) {
					return null;
				}

				return Linq.from(this.resolvedAllTasks)
					.where(t => t.id != this.taskId)
					.where(t => !this.taskAncestors[t.id][this.taskId])
					.groupBy(
						t => t.status == 'complete',
						(key, items) => ({
							key,
							items: items
								.orderBy(t => t.completion_order)
								.select(t => ({ text: t.name, value: t.id }))
								.toArray()
						})
					)
					.orderBy(t => t.key)
					.selectMany(r => {
						return [
							{ header: r.key ? 'Complete' : 'Current / Pending' },
							...r.items,
							{ divider: true }
						];
					})
					.toArray();
			}
		},

		methods: {
			onChange(val) {
				this.$emit('input', val);
			}
		},

		watch: {
			value(to) {
				this.internalValue = to;
			}
		}
	}
</script>