<template>
	<v-row>
		<v-col cols="12" sm="5" md="6">
			<user-avataaar :user="user" :avatar-data="curValue"></user-avataaar>
		</v-col>
		<v-col cols="12" sm="">
			<v-expansion-panels accordion popout :value="0">
				<v-expansion-panel class="mb-1">
					<v-expansion-panel-header :color="color">Top/Head</v-expansion-panel-header>
					<v-expansion-panel-content>
						<type-picker label="Top" field="topTypes" v-model="curValue.topType"></type-picker>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel class="mb-1">
					<v-expansion-panel-header :color="color">Shirt</v-expansion-panel-header>
					<v-expansion-panel-content>
						<type-picker label="Clothes" field="clothesType" v-model="curValue.clotheType"></type-picker>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel class="mb-1">
					<v-expansion-panel-header :color="color">Face</v-expansion-panel-header>
					<v-expansion-panel-content>
						<type-picker label="Facial Hair" field="facialHairTypes" v-model="curValue.facialHairType"></type-picker>
						<type-picker label="Eyes" field="eyeTypes" v-model="curValue.eyeType"></type-picker>
						<type-picker label="Eyebrows" field="eyebrowTypes" v-model="curValue.eyebrowType"></type-picker>
						<type-picker label="Mouth" field="mouthTypes" v-model="curValue.mouthType"></type-picker>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<v-expansion-panel>
					<v-expansion-panel-header :color="color">Body</v-expansion-panel-header>
					<v-expansion-panel-content>
						<type-picker label="Skin Color" field="skinColors" v-model="curValue.skinColor"></type-picker>
						<type-picker label="Accessory" field="accessoriesTypes" v-model="curValue.accessoriesType"></type-picker>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<p class="caption mt-4">Pro Tip: Once you select an item use your arrow keys to keep cycling through the list.</p>

		</v-col>
	</v-row>

</template>

<script>
	import UserAvataaar from "./user-avataaar.vue";
	import TypePicker from './type-picker.vue';
	import { generateUserAvatar } from './user-avataaar';

	export default {
		components: {
			UserAvataaar,
			TypePicker
		},

		props: {
			user: {
				required: true,
			},

			value: null,
		},

		data() {
			let user = this.user;
			if (typeof user != "object") {
				user = this.$db.getModel("person", user);
			}

			return {
				color: this.$vuetify.theme.currentTheme[user.department.toLowerCase()],
				curValue: {},
			};
		},

		methods: {},

		watch: {
			user: {
				deep: true,
				handler(to) {
					let user = to;
					if (typeof user != "object") {
						user = this.$db.getModel("person", user);
					}

					let value = this.value;
					try {
						value = JSON.parse(value);
					} catch (e) {}

					if (!value) {
						value = generateUserAvatar(user);
					}

					this.color = this.$vuetify.theme.currentTheme[user.department.toLowerCase()];
					this.curValue = value;
					this.$emit("input", value);
				},
			},
			value: {
				immediate: true,
				handler(to) {
					let value = to;
					try {
						value = JSON.parse(value);
					} catch (e) {}

					if (!value) {
						let user = this.user;
						if (typeof user != "object") {
							user = this.$db.getModel("person", user);
						}

						value = generateUserAvatar(user);
						this.$emit('input', value);
					}

					this.curValue = value;
				},
			},
		},
	};
</script>