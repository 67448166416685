import Vue from 'vue';
import {dbInst} from "../db/simple-db";
import {XHRRequest} from '../utils/xhr-request';
import {ModalService} from '../utils/ModalService';
import settings from '../utils/settings';
import swal from 'sweetalert';
import Insight from '../utils/Insight';
import { EventBus } from '../utils/EventBus';

import AppMixin from '../mixins/global-mixin';

Vue.mixin(AppMixin);

/**
 * @see https://github.com/gromo/jquery.scrollbar/
 */
Vue.directive('scrollable', {
	inserted(el) {
		$(el).scrollbar();
	}
});

if (settings.url.match(/https?:\/\//)) {
	XHRRequest.baseUrl = settings.url;
}
else {
	XHRRequest.baseUrl = location.protocol + settings.url;
}

XHRRequest.on('xhrFields', (e, xhrFields) => {
	xhrFields.withCredentials = true;
});

Object.assign(Vue.prototype, {
	$db: dbInst,
	$swal: swal,
    $xhrRequest: XHRRequest,
    $insight: Insight,
	$modalService: ModalService,
	$notify: ['success', 'error', 'warning', 'info'].reduce((acc, cur) => {
		acc[cur] = function (msg, title, timeout = 0) {
			let id = (new Date()).getTime();

			if (typeof msg == 'object') {
				EventBus.$emit('new-notification', {
					id,
					timeout,
					...msg,
					props: {
						type: cur,
						dismissible: true,
						...msg.props
					}
				});
			}
			else {
				EventBus.$emit('new-notification', {
					id,
					timeout,
					msg,
					title,
					props: {
						type: cur,
						dismissible: true,
						...msg.props
					}
				});
			}

			return id;
		};

		return acc;
	}, {
		hide: function (id) {
			EventBus.$emit('hide-notification', id);
		}
	}),
	$emitBus: (event, ...args) => {
		EventBus.$emit(event, ...args);
	}
});