import moment from 'moment';

moment.fn.dayForwards = function (input) {
	if (!this.isValid()) {
		return input != null
			? this
			: NaN;
	}

	let day = this._isUTC
		? this._d.getUTCDay()
		: this._d.getDay();
	
	if (input == null) {
		return day;
	}
	
	if (day > input) {
		input += 7;
	}

	return this.add(input - day, 'd');
};

moment.fn.dayBackwards = function (input) {
	if (!this.isValid()) {
		return input != null
			? this
			: NaN;
	}

	let day = this._isUTC
		? this._d.getUTCDay()
		: this._d.getDay();
	
	if (input == null) {
		return day;
	}
	
	if (day < input) {
		input -= 7;
	}

	return this.add(input - day, 'd');
};