<script>
	import Vue from 'vue';
	import { IdType } from '../utils/IdType';
	import { getUidRoute, triggerUidEditAction } from '../router/uid';
	import VBtn from 'vuetify/lib/components/VBtn'
	import { ModalInstance } from '../utils/ModalService';

	export default {
		props: {
			uid: IdType,
			title: String,
			edit: {
				type: Boolean,
				default: false
			},
			vbtn: {
				type: Boolean,
				default: false
			},
			fetch: {
				type: Boolean,
				default: false
			}
		},

		apollo: {
			node: {
				query: gql`
					query getNodeName($uid: ID!) {
						node(uid: $uid) {
							...on OldNode {
								id
							}

							...on NamedObject {
								name
							}
							...on Person {
								full_name
							}
							...on ProjectTaskCounts {
								project {
									name
								}
							}
							...on PersonLink {
								person {
									full_name
								}
							}
							...on Note {
								content
							}
						}
					}
				`,
				variables() {
					return {
						uid: this.uid
					};
				},
				skip: true
			},
		},

		computed: {
			uidRoute() {
				if (!this.uid) {
					return () => {};
				}

				if (this.edit) {
					return () => {
						let resp = triggerUidEditAction(this.uid);
						this.handleResponse(resp);
					};
				}
				else {
					let handler = getUidRoute(this.uid);
					if (typeof handler == 'function') {
						return () => {
							let resp = handler();
							this.handleResponse(resp);
						};
					}

					return handler;
				}
			},

			isFunction() {
				if (!this.uid) {
					return true;
				}

				return typeof this.uidRoute == 'function';
			},

			nodeName() {
				if (typeof this.title == 'string') {
					return this.title;
				}
				else if (!this.node) {
					return 'No Node';
				}

				if (this.node.project) {
					return this.node.project.name;
				}
				else if (this.node.person) {
					return this.node.person.full_name;
				}
				else if (this.node.content) {
					let content = this.node.content;
					if (content.length > 50) {
						content = content.substr(0, 47) + '...';
					}

					return 'Note: ' + content;
				}
				else if (this.node.full_name) {
					return this.node.full_name;
				}

				return this.node.name;
			}
		},

		methods: {
			needsFetch() {
				if (this.fetch) {
					return true;
				}

				if (!this.$scopedSlots.default) {
					return true;
				}

				return false;
			},
			handleResponse(resp) {
				if (resp instanceof ModalInstance) {
					resp.on('save', (e, node) => {
						this.$emit('save', node, e);
					});
				}
			}
		},

		mounted() {
			if (this.needsFetch()) {
				this.$apollo.queries.node.skip = false;
			}
		},

		render(h) {
			if (this.$scopedSlots.default) {
				return this.$scopedSlots.default({
					isFunction: this.isFunction,
					handler: this.uidRoute,
					title: this.nodeName
				});
			}

			let content;
			if (this.$scopedSlots.content) {
				content = this.$scopedSlots.content({
					title: this.nodeName
				});
			}
			else {
				content = [];
				if (this.node) {
					content.push(h('span', [this.nodeName]));
				}
			}

			if (this.isFunction) {
				if (this.vbtn) {
					return h(
						VBtn,
						{
							attrs: {
								title: this.nodeName
							},
							on: {
								click: this.uidRoute
							}
						},
						content
					);
				}

				return h(
					'a',
					{
						attrs: {
							title: this.nodeName,
							href: 'javascript:void(0)'
						},
						on: {
							click: this.uidRoute
						}
					},
					content
				)
			}
			else {
				if (this.vbtn) {
					return h(
						VBtn,
						{
							props: {
								to: this.uidRoute,
							},
							attrs: {
								title: this.nodeName
							}
						},
						content
					);
				}

				let routerLink = Vue.component('RouterLink');

				return h(
					routerLink,
					{
						props: {
							to: this.uidRoute,
							title: this.nodeName
						}
					},
					content
				);
			}
		}
	}
</script>