<template>
	<div class="task-tree-view" :style="`width: ${width}; height: ${height};`">
		<div v-html="`<style>${highlightStyles} ${errorStyles}</style>`"></div>
		<template v-if="ready">
			<div v-if="emptyGraph" class="pa-5 mx-auto" style="width:250px;">There are no trees</div>
			<template v-else>
				<svg class="screen">
					<defs>
						<marker id="arrow-end" markerWidth="13" markerHeight="20" refX="9" refY="6" orient="auto">
							<path d="M2,2 L2,11 L10,6 L2,2"/>
						</marker>
						<marker id="arrow-start" markerWidth="13" markerHeight="13" refX="9" refY="6" orient="auto">
							<path d="M2,2 L2,11 L10,6 L2,2"/>
						</marker>
						<filter x="0" y="0" width="1" height="1" id="insert-btn-bg">
							<feFlood :flood-color="$vuetify.theme.currentTheme['cards']" result="bg" />
							<feMerge>
								<feMergeNode in="bg"/>
								<feMergeNode in="SourceGraphic"/>
							</feMerge>
						</filter>
					</defs>
					<g class="canvas" :style="`--x: ${translateX}px; --y: ${translateY}px`">
						<edge v-for="(edge, ndx) in edges" :key="ndx" :from="nodeMap[edge.from]" :to="nodeMap[edge.to]"></edge>
						<!-- <text v-for="edge in edges" :key="`insert-${edge.from}-${edge.to}`" filter="url(#insert-btn-bg)"> -->
						<text v-for="edge in edges" :key="`insert-${edge.from}-${edge.to}`" filter="url(#insert-btn-bg)">
							<textPath v-if="!redundantEdges[edge.from] || !redundantEdges[edge.from][edge.to]" class="insert-btn fad" startOffset="50%" text-anchor="middle" :href="`#edge-${edge.from}-${edge.to}`" @click="$emit('insertTask', edge)">
								<tspan dy="6px">&#xf0fe;</tspan>
							</textPath>
						</text>
						<node v-for="(node, ndx) in nodeMap" :key="ndx" :node="node">
							<slot name="task" :task="node.data" :node="node" :mouseenter="() => mouseEnter(node)" :mouseleave="mouseLeave"></slot>
						</node>
						<template v-for="(tos, from) in redundantEdges">
							<text v-for="(_, to) in tos" :key="`${from}-${to}`">
								<textPath class="redundant" startOffset="50%" text-anchor="middle" :href="`#edge-${from}-${to}`">
									Redundant
								</textPath>
							</text>
						</template>
					</g>
				</svg>
			</template>
		</template>
		<slot v-else name="loading">
			<div class="mt-5 ml-3">Loading</div>
		</slot>
	</div>
</template>

<script>
	import moment from 'moment';
	import node from './node';
	import edge from './edge';
	import dagre from 'dagre';

	export default {
		components: {
			node, edge
		},

		props: {
			tasks: {
				type: Array,
				required: true
			},
			taskWidth: {
				type: Number,
				default: 140
			},
			taskHeight: {
				type: Number,
				default: 42
			},
			taskSpacing: {
				type: Number,
				default: 20
			},
			pathDelay: {
				type: Boolean,
				default: true
			}
		},

		data() {
			return {
				emptyGraph: true,
				ready: true,
				nodeMap: null,
				edges: null,
				translateX: 0,
				translateY: 0,
				width: '100%',
				height: '100%',
				redundantEdges: {},
				highlightStyles: '',
				errorStyles: '',
				
			};
		},

		mounted() {
			$(this.$refs.viewport).css({
				width: `${this.width}px`,
				height: `${this.height}px`
			});
		},

		methods: {

			buildNewNode() {
				return {
					width: this.taskWidth,
					height: this.taskHeight,
					children: [],
					parents: [],
					size: [this.taskWidth + this.taskSpacing, this.taskHeight + this.taskSpacing]
				};
			},
			analyzeNodes(nodeArray) {
				let map = {};

				nodeArray.forEach(t => {
					if (!map[t.id]) {
						map[t.id] = this.buildNewNode();
					}

					Object.assign(map[t.id], {
						data: t,
						day: moment(t.estimated_completion_date).format('YYYY/MM/DD')
					});

					t.previous_task_ids.forEach(pid => {
						if (!map[pid]) {
							map[pid] = this.buildNewNode();
						}

						map[pid].children.push(map[t.id]);
						map[t.id].parents.push(map[pid]);
					});
				});

				// Find roots and make virtual root
				map[0] = {children: [], parents: [], size: [1, 1]};
				for (let id in map) {
					if (id == 0) {
						continue;
					}
					else if (!map[id].data) {
						// I'm a parent and I don't exist
						// Clean up my children's parents to remove myself
						map[id].children.forEach(c => c.parents = c.parents.filter(p => p != map[id]))
						delete map[id];
						continue;
					}

					if (map[id].parents.length == 0 && map[id].children.length > 0) {
						map[0].children.push(map[id]);
						map[id].parents.push(map[0]);
					}
				}

				return map;
			},

			cullNodes() {
				let newMap = {};

				for (let id in this.nodeMap) {
					if (this.nodeMap[id].parents.length > 0 || this.nodeMap[id].children.length > 0) {
						newMap[id] = this.nodeMap[id];
					}
				}

				return newMap;
			},

			buildGraph() {
				let g = new dagre.graphlib.Graph().setGraph({});

				for (let id in this.nodeMap) {
					if (this.nodeMap[id].parents.length > 0 || this.nodeMap[id].children.length > 0) {
						g.setNode(id, {width: 140, height: 30});
						this.nodeMap[id].children.forEach(c => g.setEdge(id, c.data.id, { label: '' }));
					}
				}

				dagre.layout(g);

				return g;
			},

			buildEdges() {
				let edges = [];

				for (let from in this.nodeMap) {
					edges.push(...this.nodeMap[from].children.map(to => ({from, to: to.data.id})));
				}

				return edges;
			},

			mouseEnter(node) {
				let delay = {[node.data.id]: 0},
					reached = {},
					selectors = '';

				let frontier = [{node, depth: 0}];
				while (frontier.length) {
					let {node, depth} = frontier.shift();

					let needsSorting = false;
					node.parents.forEach(p => {
						let selKey = `${p.data.id}-${node.data.id}`;
						if (!reached[selKey]) {
							if (selectors) {
								selectors += ', ';
							}

							selectors += `.edge.edge-${selKey}[${this.$options._scopeId}]`;
							reached[selKey] = true;
						}

						if (!delay[p.data.id] || (this.pathDelay && depth + 1 > delay[p.data.id])) {
							delay[p.data.id] = depth + 1;

							let existing;
							if (this.pathDelay) {
								existing = frontier.find(f => f.node.id == p.data.id);
							}
							
							if (existing) {
								existing.depth = depth + 1;
								needsSorting = true;
							}
							else {
								frontier.push({node: p, depth: depth + 1});
							}
						}
					});

					if (needsSorting) {
						frontier.sort((a, b) => a.depth - b.depth);
					}
				}

				if (selectors) {
					if (!this.pathDelay) {
						this.highlightStyles = `
						${selectors} {
							stroke: var(--v-info-base);
						}
						`;
						return;
					}

					let delayTransitions = '';
					for (let id in delay) {
						this.nodeMap[id].parents.forEach(p => {
							delayTransitions += `
								.edge-${p.data.id}-${id}[${this.$options._scopeId}] {
									transition-delay: ${delay[id] * 100}ms;
								}
							`;
						});
					}
					
					this.highlightStyles = `
					${delayTransitions}
					`;

					setTimeout(() => {
						this.highlightStyles = `
						${delayTransitions}
						${selectors} {
							stroke: var(--v-primary-base);
						}
						`;
					}, 20);
				}
			},

			mouseLeave(node) {
				this.highlightStyles = '';
			},

			detectRedundantEdges() {
				let result = {};

				for (let id in this.nodeMap) {
					if (this.nodeMap[id].parents.length < 2) {
						continue;
					}

					this.nodeMap[id].parents.forEach(p => {
						let frontier = [...p.children];

						while (frontier.length) {
							let child = frontier.shift();

							if (this.nodeMap[id].parents.includes(child)) {
								if (!result[p.data.id]) {
									result[p.data.id] = {};
								}

								result[p.data.id][id] = true;
							}

							child.children.forEach(c => {
								if (c.data.id != id) {
									frontier.push(c);
								}
							});
						}
					});
				}

				this.redundantEdges = result;
				
				this.errorStyles = '';
				for (let from in result) {
					for (let to in result[from]) {
						this.errorStyles += `
						.edge-${from}-${to} {
							stroke: var(--v-error-base) !important;
						}
						`;
					}
				}
			}
		},

		watch: {
			tasks: {
				handler(to) {
					this.emptyGraph = true;
					if (!to.length) {
						return;
					}

					this.ready = false;

					this.nodeMap = this.analyzeNodes(to);
					this.nodeMap = this.cullNodes();
					this.emptyGraph = Object.keys(this.nodeMap).length == 0;

					this.detectRedundantEdges();

					let g = this.buildGraph();

					this.translateX = 0
					this.translateY = 0;
					this.width = 0;
					this.height = 0;
					let minHeight = Number.MAX_SAFE_INTEGER;
					g.nodes().forEach(id => {
						if (id == 0) {
							return;
						}

						let node = g.node(id);
						Object.assign(this.nodeMap[id], {
							x: node.x,
							y: node.y
						});

						if (this.nodeMap[id].y < minHeight) {
							minHeight = this.nodeMap[id].y;
						}

						if (this.nodeMap[id].x < this.translateX) {
							this.translateX = this.nodeMap[id].x;
						}

						if (this.nodeMap[id].x + this.nodeMap[id].width > this.width) {
							this.width = this.nodeMap[id].x + this.nodeMap[id].width;
						}

						if (this.nodeMap[id].y + this.nodeMap[id].height > this.height) {
							this.height = this.nodeMap[id].y + this.nodeMap[id].height;
						}
					});

					if (minHeight == Number.MAX_SAFE_INTEGER) {
						minHeight = 0;
					}

					this.translateX = this.translateX * -1;
					this.width = `${this.width + this.translateX}px`;
					this.height = `${this.height + minHeight}px`;

					// TODO: this.nodeMap is undefined: localdata http://localhost:8080/#/project-tree/1265
					if(!this.emptyGraph) {
						this.nodeMap[0].children.forEach(c => c.parents.pop());
						delete this.nodeMap[0];
					}

					this.edges = this.buildEdges();
					this.ready = true;
				},
				immediate: true
			}
		}
	}
</script>

<style lang="scss" scoped>
	svg {
		width: 100%;
		height: 100%;
	}

	.canvas {
		transform: translate(var(--x), var(--y));
	}
	
	.edge {
		stroke: var(--v-text-over-bg-base);
		transition: stroke 100ms;
		transition-delay: 0;
		/* .theme--light & {
			stroke: #797979;
		} */
	}

	.task-node {
		height: 100%;
		// background-color: #64c864;
	}

	.redundant {
		fill: var(--v-error-base);
		text-shadow: 0 0 1px black;
	}

	.insert-btn {
		fill: var(--v-primary-base);
		cursor: pointer;
		font-size: 125%;
	}
</style>