<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title v-html="modalTitle"></v-card-title>

			<v-card-text v-if="projectData">

				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<!--Client-->
				<client-picker :disabled="saving || !!realProjectId" v-model="projectData.client_id" dense hide-details outlined class="my-3" placeholder="Select One..."></client-picker>
				<!-- <v-select label="Client *" :items="clients" v-model="client_id" :disabled="saving || !!lockedClientId" dense hide-details outlined class="my-3" placeholder="Select One..."></v-select> -->

				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="Project Name *" v-model="projectData.name" :disabled="saving" required dense hide-details outlined class="mt-3" placeholder="Project Name"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field label="Job / Project Number" v-model="projectData.job" :disabled="saving" dense hide-details outlined class="mt-3" placeholder="Project Name"></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-autocomplete label='Type: to "Quickbooks - DO NOT CHANGE' :items="$options.typeOptions" v-model="projectData.type" :disabled="saving" dense hide-details outlined class="mt-3"></v-autocomplete>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<v-select label="Category *" :items="getEnumValues('ProjectCategory')" v-model="projectData.category" :disabled="saving" required dense hide-details outlined class="mt-3"></v-select>
					</v-col>
					<v-col cols="12" sm="6">
						<v-select label="Status *" :items="statusOptions" v-model="projectData.status" :disabled="saving" required dense hide-details outlined class="mt-3"></v-select>
					</v-col>
					<!-- <v-col v-if="deletingProject" cols="12">
						<project-picker v-model="transferProjectDataToProjectId" label="Transfer timers and contacts to: " :disabled="saving"  required dense hide-details outlined></project-picker>
					</v-col> -->
				</v-row>

				<v-row>
					<v-col cols="12">
						<employee-picker label="Account Executive" :disabled="saving" v-model="projectData.account_executive_user_id" dense hide-details outlined class="mt-3"></employee-picker>
						<!-- <v-autocomplete label="Project Manager *" :items="users" v-model="projectData.account_executive_user_id" :disabled="saving" dense hide-details outlined class="mt-3"></v-autocomplete> -->
					</v-col>
					
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<v-menu v-model="projectStartedMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y ref="projectStartedMenu" min-width="200px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="projectData.project_started" type="date" label="Project Started" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details outlined dense class="my-3" :disabled="saving"></v-text-field>
							</template>
							<v-date-picker v-model="projectData.project_started" @input="projectStartedMenu = false" header-color="primary" color="primary" show-adjacent-months no-title></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="12" sm="6">
						<v-select label="Progress" :items="getEnumValues('ProjectProgress')" v-model="projectData.progress" :disabled="saving" dense hide-details outlined class="mt-3"></v-select>
					</v-col>
					<!-- <v-col cols="12" sm="6">
						<v-menu v-model="projectDueMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y ref="projectDueMenu" min-width="200px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="projectData.due_date" type="date" label="Project Due" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details outlined dense class="my-3" :disabled="saving"></v-text-field>
							</template>
							<v-date-picker v-model="projectData.due_date" @input="projectDueMenu = false" header-color="primary" color="primary" show-adjacent-months no-title></v-date-picker>
						</v-menu>
					</v-col> -->
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="Hours Allocated" v-model="projectData.hours_allocated" :disabled="saving" dense hide-details outlined class="mt-3" type="number" placeholder="Project Name"></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-switch v-model="projectData.budget_alerts" :false-value="false" :true-value="true" :disabled="saving" label="Budget Alerts" dense class="mt-3" hint="Emails PM at 50%, 75% and 100% of hours used" persistent-hint></v-switch>
					</v-col>
				</v-row>

				<v-textarea label="Project Description" v-model="projectData.description" :disabled="saving" rows="3" auto-grow outlined dense class="mt-5"></v-textarea>

				<v-radio-group v-if="!isUpdate" v-model="projectData.task_group_type" dense class="my-0">
					<template v-slot:label>
						<div>Default Task Group Type:</div>
					</template>
					<v-radio v-for="i in getEnumValues('TaskGroupTypeEnum')" :key="i.value" :label="i.text" :value="i.value" :disabled="i.value == 'kanban'">
						<template v-slot:label>
							<div>
								<v-icon left right>{{$insight.helpers.getTaskGroupIcon(i.value)}}</v-icon>
								{{i.text}} <em v-if="i.value == 'kanban'">coming soon &trade;</em>
							</div>
						</template>
					</v-radio>
				</v-radio-group>

				<v-divider></v-divider>
				<h2 class="my-3">Meta Fields</h2>

				<meta-input-list ref="metaFieldList" :disabled="saving" :model="projectData" model-type="Project" class="input-group input-group-sm"></meta-input-list>
			</v-card-text>

			<v-card-actions>
				<v-btn @click="modal.trigger('close')" :disabled="saving" class="ml-auto">Close</v-btn>
				<v-btn color="primary" @click="submit" :loading="saving">{{submitBtnText}}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import MetaInputList from "../metaInputList";
	import mixin from '../../mixins/add-edit-mixin';
	import ClientPicker from '../fields/clientPicker.vue';
	import ProjectPicker from "@/components/fields/projectPicker.vue";
	import { IdType } from '../../utils/IdType';
	import EmployeePicker from '../fields/employeePicker.vue';
	import { EventBus } from '../../utils/EventBus';

	const PROJECT_FRAGMENT = `
			id
			name
			client_id
			type
			job
			category
			status
			account_executive_user {
				id
				full_name
				meta_avatar
			}
			account_executive_user_id
			progress
			project_started @formatDate(format: "Y-m-d")
			due_date @formatDate(format: "Y-m-d")
			hours_allocated
			budget_alerts
			description
			_meta_values
		`;

	export default {
		name: "projectAddEdit",
		mixins: [mixin("project")],

		components: { MetaInputList, ProjectPicker, ClientPicker, EmployeePicker },

		typeOptions: [
			"Digital Attic",
			"Web Dev",
			"Web Support",
			"Video & Photo",
			"Software",
			"Agency",
			"Save Mart",
			"Grocery Outlet",
			"CPS",
			"Gallo",
			"Design",
			"Hosting",
			"Studio",
		],

		// progressOptions: [
		// 	{
		// 		value: "new",
		// 		text: "New",
		// 	},
		// 	{
		// 		value: "client",
		// 		text: "Client",
		// 	},
		// 	{
		// 		value: "inprogress",
		// 		text: "In Progress",
		// 	},
		// ],

		props: {
			modal: {
				type: Object,
				required: true,
			},

			projectId: {
				type: IdType,
				default: 0
			},
			
			populate: {
				type: Object,
				default: () => ({}),
			},

			duplicateProjectId: {
				type: IdType
			}
		},

		apollo: {
			// progressEnum: {
			// 	query: gql`
			// 		query GetProjectProgressValues {
			// 			__type(name: "ProjectProgress") {
			// 				name
			// 				enumValues {
			// 					name
			// 				}
			// 			}
			// 		}
			// 	`
			// },

			projectDetails: {
				query: gql`
					query GetSingleProject($projectId: ID!) {
						queries
						projectDetails: project(id: $projectId) {
							${PROJECT_FRAGMENT}
						}
					}
				`,
				variables() {
					return {
						projectId: this.projectId || this.duplicateProjectId
					};
				},
				skip() {
					return !this.realProjectId && !this.duplicateProjectId;
				},
			},
		},

		data() {
			return {
				modalErrorMessage: false,
				saving: false,
				projectStartedMenu: false,
				projectDueMenu: false,
				realProjectId: this.projectId,
				projectData: null







				// transferProjectDataToProjectId: null,
				// modalErrorMessage: false,
				// saving: false,
				// projectStartedMenu: false,
				// projectDueMenu: false,

				// client_id:
				// 	this.getObjectProp("client_id") || this.lockedClientId || "",
				// // fields: fields,

				// name: this.getObjectProp("name"),
				// job: this.getObjectProp("job"),
				// category: this.getObjectProp("category") || null,
				// categoryOptions: [
				// 	"Audit",
				// 	"Custom",
				// 	"Design",
				// 	"Dubs",
				// 	"Hosting",
				// 	"Internal",
				// 	"Mobile",
				// 	"PPC",
				// 	"Web",
				// 	"Video",
				// ],
				// status: this.getObjectProp("status") || "active",

				// account_executive_user: this.getObjectProp(
				// 	"account_executive_user"
				// ),
				// progress: this.getObjectProp("progress"),
				// progressOptions: [
				// 	{
				// 		value: "new",
				// 		text: "New",
				// 	},
				// 	{
				// 		value: "client",
				// 		text: "Client",
				// 	},
				// 	{
				// 		value: "inprogress",
				// 		text: "In Progress",
				// 	},
				// ],
				// project_started: this.$options.filters.clientDate(
				// 	this.getObjectProp("project_started")
				// ),
				// due_date: this.getObjectProp("due_date")
				// 	? this.$options.filters.clientDate(
				// 			this.getObjectProp("due_date")
				// 	  )
				// 	: "",
				// hours_allocated: this.getObjectProp("hours_allocated"),
				// budget_alerts: this.getObjectProp("budget_alerts") || '1',
				// description: this.getObjectProp("description"),
			};
		},

		computed: {
			isUpdate() {
				return this.realProjectId;
			},

			// deletingProject() {
			// 	return this.projectData && this.projectData.status === "removed";
			// 	// && this.project.status !== 'removed'
			// },
			submitBtnText() {
				// if (this.deletingProject) {
				// 	return 'Delete';
				// }

				return this.projectId
					? 'Update'
					: 'Create';
			},
			// TODO: delete_projects permission?
			statusOptions() {
				let opts = ["active", "closed", "hidden"];

				// if (this.$store.getters.hasPermission('delete_projects')) {
				// 	opts.push('removed');
				// }

				return opts;
			},
			modalTitle() {
				let title = this.projectId ? "Edit Project" : "Add Project";
				if (this.duplicateProjectId) {
					title = "Add Project";
				}
				return title;
			},

			// clients() {
			// 	return this.$db.getModels("client").map((client) => {
			// 		return {
			// 			value: client.id,
			// 			text: client.name
			// 		};
			// 	});
			// },

			// users() {
			// 	let users = this.$db
			// 		.findModels("person", (person) => {
			// 			return person.employee && person.inactive == 0;
			// 		})
			// 		.map((person) => {
			// 			return {
			// 				value: person.id,
			// 				text: this.$options.filters.FullName(person),
			// 			};
			// 		});

			// 	users.sort((a, b) => {
			// 		return a.text.localeCompare(b.text);
			// 	});

			// 	return users;
			// },
		},

		methods: {
			copyData(graphQlData = {}) {
				let copyManyFn = (props, subKey = "") => {
					let source = graphQlData;
					if (subKey) {
						source = source[subKey] || {};
					}

					let result = {};
					for (let i of props) {
						result[i] = source.hasOwnProperty(i) ? source[i] : null;
					}

					return result;
				};

				this.projectData = copyManyFn([
					"id",
					"name",
					"client_id",
					"type",
					"job",
					"category",
					"status",
					"account_executive_user_id",
					"progress",
					"project_started",
					"due_date",
					"hours_allocated",
					"budget_alerts",
					"description",
					"_meta_values",
					"task_group_type"
				]);
			},

			formModel() {
				let model = {
					client_id: this.projectData.client_id,
					type: this.projectData.type,
					name: this.projectData.name,
					job: this.projectData.job,
					category: this.projectData.category,
					status: this.projectData.status,
					account_executive_user_id: this.projectData.account_executive_user_id,
					progress: this.projectData.progress,
					project_started: this.projectData.project_started,
					due_date: this.projectData.due_date,
					hours_allocated: parseFloat(this.projectData.hours_allocated),
					budget_alerts: this.projectData.budget_alerts,
					description: this.projectData.description,
				};

				if (this.realProjectId) {
					model.id = this.realProjectId;
				}
				else {
					model.task_group_type = this.projectData.task_group_type;
				}

				Object.assign(model, this.$refs.metaFieldList.getFields());

				// if (this.lockedClientId) {
				// 	model.client_id = this.lockedClientId;
				// }
				// if (this.duplicateProject) {
				// 	model.duplicate_project_id = this.duplicateProject.id;
				// }
				// if (this.deletingProject && this.transferProjectDataToProjectId) {
				// 	model.transferProjectDataToProjectId = this.transferProjectDataToProjectId;
				// }
				return model;
			},

			submit() {
				this.saving = true;

				// let method = "post",
				// 	api = "/api/project";

				// if (this.project && this.project.id) {
				// 	method = "put";
				// 	api += `/${this.project.id}`;
				// }

				// if (!this.project) {
				// 	api +=
				// 		"?fields=name,client_id,hours_allocated,status,confirmed_time,meta_starred";
				// }

				// this.$xhrRequest.send(method, api, this.formModel()).then(
				// 	(project) => {
				// 		this.modal.trigger("save close", project);
				// 	},
				// 	(xhr) => {
				// 		this.saving = false;

				// 		let errors = this.$insight.helpers.getRequestErrors(xhr);
				// 		errors = this.$insight.helpers.flattenRequestErrors(errors);

				// 		this.modalErrorMessage = errors.join("<br>");
				// 	}
				// );

				let payload = {
					variables: {
						data: this.formModel()
					},
					update: (store, { data: { result } }) => {
						this.saving = false;
						this.modal.trigger("save close", result);

						if (!this.isUpdate) {
							EventBus.$emit('project:new');
						}
					}
				};



				if (this.isUpdate) {
					payload.mutation = gql`
						mutation ($data: UpdateProjectArgs!) {
							result: updateProject(data: $data) {
								${PROJECT_FRAGMENT}
							}
						}
					`;
				}
				else if (this.duplicateProjectId) {
					payload.mutation = gql`
						mutation ($sourceId: ID!, $data: CreateProjectArgs!) {
							result: duplicateProject(sourceId: $sourceId, data: $data) {
								${PROJECT_FRAGMENT}
							}
						}
					`;
					payload.variables.sourceId = this.duplicateProjectId;
				}
				else {
					payload.mutation = gql`
						mutation ($data: CreateProjectArgs!) {
							result: createProject(data: $data) {
								${PROJECT_FRAGMENT}
							}
						}
					`;
				}

				this.$apollo
					.mutate(payload)
					.catch((error) => {
						console.error("error saving project", error);
						this.modalErrorMessage = "There was a problem saving the data.";
						this.saving = false;
					});
			},
		},

		watch: {
			projectDetails(to) {
				this.copyData(to);
			},
			projectId: {
				immediate: true,
				handler(to) {
					if (!to) {
						this.copyData({
							task_group_type: 'waterfall',
							status: 'active',
							...this.populate
						});
					}
				},
			},
		},
	};
</script>

<style scoped lang="scss">
</style>
