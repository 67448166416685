<!--
This template shows on timers
timers are magically adjusted to pacific (or other?) time zone
-->
<template>
<!--  :color="'status-' . timer.status.toLowerCase()" -->
	<v-card class="card d-flex flex-column justify-space-between mb-2" :class="{
			'card-timer-active': timerActive,
			'card-quick-timer': !task,
			'subtasks-pending': !subtasksComplete,
			// 'time-not-submitted': !isToday(timer.creation_date) && !timer.submit_date,
			'highlight': highlightUnCompletable && !submitable
		 }" :data-task-id="task ? task.id : 0" elevation="4">

		<!-- <div v-if="projectStats" class="progress align-self-stretch" :title="projectStats.totalTime + ' / ' + projectStats.allocated">
			<div class="progress-bar progress-bar-orange" :style="progressBarStyles" style="width:100%"></div>
			<v-progress-linear :value="progressBarStyles.value" color="grey darken-3"></v-progress-linear>
		</div> -->

		<div> <!-- don't delete this div. it's for flex purposes -->
			<!--region Card Header-->
			<v-card-title class="d-flex justify-space-between">
				<template v-if="task">
					<i v-if="starred" class="far fa-star"></i>
					<a href="javascript:void(0)" @click="onTaskClick($event)" :title="task.name" class="text-h6 text-decoration-none">{{task.name}}</a>
				</template>
				<template v-else><span class="text-h6">Quick Timer</span></template>

				<div class="d-flex">
					<!-- checkbox that marks item as complete-->
					<v-tooltip v-if="task" top>
						<template v-slot:activator="{ on }">
							<span v-on="on">
								<v-checkbox :id="'markComplete-' + timer.id" v-model="complete" class="ma-0 pa-0" hide-details dense></v-checkbox>
							</span>
						</template>
						<span>Mark as complete</span>
					</v-tooltip>

					<!--Timer card ellipsis dropdown options-->
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-btn small v-bind="attrs" v-on="on" min-width="0">
								<v-icon small>fa fa-ellipsis-h</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-if="!task && project" @click="createTask">
								<v-list-item-title>Create Task</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="task" @click="addNote">
								<v-list-item-title>Add Note</v-list-item-title>
							</v-list-item>
							<v-list-item v-if="task && !complete" @click="reAssignTask">
								<v-list-item-title>Assign To</v-list-item-title>
							</v-list-item>
							<v-list-item @click="deleteTimer">
								<v-list-item-title>Delete Timer</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</v-card-title>
			<!--endregion-->

			<!--region Card Body-->
			<v-card-text class="pb-1">

				<!--If task -->
				<template v-if="task">
					<div class="d-flex flex-row justify-space-between flex-wrap">
						<v-btn text v-if="client" label small :to="{name: 'client.details', params: {id: client.id}}" class="pa-0" height="auto">
							{{client.name}}
						</v-btn>
						<v-btn text v-if="project" label small :to="{name: 'project.details', params: {id: project.id}}" class="pa-0" height="auto">
							{{project.name}}
						</v-btn>
					</div>

					<v-expansion-panels v-if="task.description" accordion flat>
						<v-expansion-panel>
							<v-expansion-panel-header color="px-0 py-3">Description</v-expansion-panel-header>
							<v-expansion-panel-content class="new-lines">{{task.description}}</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</template>

				<!--Else -->
				<template v-else>
					<v-autocomplete outlined dense filled full-width hide-details class="body-2" :value="timer.project_id" :items="projectListArray()" placeholder="Pick Project" @input="updateProject"></v-autocomplete>
				</template>

				<div class="timer-job-container my-2">
					<timer-job-picker :value="timer.job" @input="updateJob" :project-id="timer.project_id || null" outlined dense filled full-width hide-details class="body-2"></timer-job-picker>
				</div>

				<debounce-events :events="{ change: 1000 }" v-slot="{ change }">
					<v-textarea outlined hide-details filled dense auto-grow :value="description" @input="change(() => updateDesc($event))" key="desc" ref="desc" rows="1" placeholder="Task Description" class="body-2">
					</v-textarea>
				</debounce-events>

			</v-card-text>

			<v-card v-if="complete" tile class="mt-5" color="odd">
				<v-card-title>Task Complete</v-card-title>
				<v-card-text>
					<v-textarea hide-details outlined dense filled flat auto-grow v-model="taskCompleteComment" placeholder="Task completed message... (optional)" rows="1" class="body-2 mb-3"></v-textarea>
					<email-picker v-model="taskCompleteEmailRecipients" label="Email User(s)" :search-input.sync="search" small-chips deletable-chips outlined dense filled multiple hide-details></email-picker>
				</v-card-text>
			</v-card>
			<!--endregion-->
		</div>

		<!--region Card Footer-->
		<v-card-actions class="d-block align-self-end" style="width:100%">

			<div class="d-flex align-center">

				<v-tooltip top v-if="task && task.timeline_due_date">
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" class="body-2">{{task.estimated_completion_date | shortDate}}</span>
					</template>
					<span>Task Due</span>
				</v-tooltip>

				<div class="ml-auto d-flex">
					<!-- edit time -->
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on">
								<v-btn small tile elevation="0" @click="editTime" min-width="0" color="odd">
									<v-icon small>fas fa-clock</v-icon>
								</v-btn>
							</span>
						</template>
						<span>Edit Time</span>
					</v-tooltip>
					<!-- current time -->
					<timer-clock :timer="timer" class="px-3"></timer-clock>
                    <template v-if="!hideStartSubmitButtons">
                        <!-- Start Timer -->
                        <v-tooltip top v-if="!timerActive">
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" >
                                    <v-btn small tile elevation="0" min-width="0" :disabled="impersonating" @click="startTimer">
                                        <v-icon small>fas fa-play</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Start Timer</span>
                        </v-tooltip>
                        <!-- Pause Timer -->
                        <v-tooltip v-else top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-btn small tile elevation="0" min-width="0" @click="stopTimer" dark color="magenta">
                                        <v-icon small>fas fa-pause</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Pause Timer</span>
                        </v-tooltip>
                        <!-- Submit Timer -->
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-btn :disabled="!submitable" small tile elevation="0" min-width="0" @click="submitTimer()" color="primary">
                                        <v-icon small>fas fa-external-link</v-icon>
                                    </v-btn>
                                </span>
                            </template>
                            <span>Submit Timer</span>
                        </v-tooltip>
                    </template>
				</div>

			</div>

            <div v-if="task && task.assigned_to_user_id != $store.getters.userId" class="teal darken-2 px-2 py-1">
				<i class="far fa-exclamation-circle mr-1"></i> This task is currently assigned to {{ $db.getModel('person', task.assigned_to_user_id) | FullName }}
			</div>

			<!-- <div v-if="!isToday(timer.creation_date) && !timer.submit_date" class="red darken-4 px-2 py-1 mt-2">
				<i class="far fa-exclamation-circle mr-1"></i> This timer doesn't look like it was submitted
			</div> -->

		</v-card-actions>
		<!--endregion-->
	</v-card>
</template>

<script>
	import TimerClock from './timerClock';
	import TimerModel from '../models/timer';

	import ModelCardMixin from '../mixins/model-card-mixin';
	import TaskCardMixin from '../mixins/task-card-mixin';
	
	import EmailPicker from "@/components/fields/emailPicker";
	import TimerJobPicker from './fields/timerJobPicker.vue';

	export default {
		name: 'timerCard',

		mixins: [
			ModelCardMixin,
			TaskCardMixin
		],

		components: { TimerClock, EmailPicker, TimerJobPicker },

		props: {
			timer: {
				type: Object,
				required: true
			},

			highlightUnCompletable: {
				type: Boolean,
				default: false
			},

            hideStartSubmitButtons: Boolean
		},

		data() {
			let completeData = this.getUserSetting(`timer-complete-${this.timer.id}`, {
				complete: false,
				taskCompleteComment: '',
				taskCompleteEmailRecipients: []
			}, 'session');

			return {
				description: this.timer.description,
				complete: completeData.complete,
				taskCompleteComment: completeData.taskCompleteComment,
				taskCompleteEmailRecipients: completeData.taskCompleteEmailRecipients,
				search: null,
			};
		},

		computed: {
			taskCompleteEmailRecipientOptions() {
				let users = this.$db
					.findModels('person', (person) => {
						return person.employee && person.inactive == 0;
					})
					.map((person) => {
						return {
							value: person.id,
							text: this.$options.filters.FullName(person)
						};
					});

				users.sort((a, b) => {
					return a.text.localeCompare(b.text);
				});

				return users;
			},

			timerActive() {
				return !this.timer.stop;
			},

			task() {
				return this.timer.task ?? this.$db.getModel('task', this.timer.task_id);
			},

			impersonating() {
				return this.$store.getters.userId != this.timer.user_id;
			},

			submitable() {
				return !this.timer.submit_date && !!this.timer.description && !!this.project && !!this.client && !!this.timer.job;
			},

			progressBarStyles() {
    			if (!this.projectStats) {
    				return false;
    			}

    			let percent = Math.round(this.projectStats.totalTime / this.projectStats.allocated * 100);

    			return {
    				value: percent,
    				color: this.$insight.helpers.interpolateColorGreenToRed(
    					percent
    				).style
    			};
            },
		},

		methods: {
			assignToTitle() {
				if (this.submitable) {
					return 'Submit this timer and reassign the task to another person';
				} else {
					return 'Before you can reassign this task, you must either enter a description for this timer, or delete the timer';
				}
			},
			deleteTimer() {
				this.$swal({
					title: 'Are you sure?',
					text: 'You cannot undo this!',
					icon: 'warning',
					buttons: true,
					dangerMode: true
				}).then((willDelete) => {
					if (willDelete) {
						this.$xhrRequest
							.send('delete', `/api/timer/${this.timer.id}`)
							.then(() => {
								this.$db.removeModels('timer', this.timer.id);
								this.setUserSetting(`timer-complete-${this.timer.id}`, null, 'session');
							});
					}
				});
			},

			projectListArray() {
				let projects = [];

				this.$db
					.findModels('project', (project) => {
						return project.status !== 'closed' && project.client_id > 0; //&& (!this.timer.client_id || this.timer.client_id == project.client_id);
					})
					.forEach((project) => {
						/*if (this.timer.client_id) {
							projects.push({
								value: project.id,
								text: project.name
							});
						}
						else {*/
						let client = this.$db.getModel('client', project.client_id);
						if (!client) {
							// console.error('No client found for project',project);
						} else {
							projects.push({
								value: project.id,
								text: client.name + ' : ' + project.name
							});
						}
						// }
					});

				return projects;
			},

			updateProject(newProjectId) {
				// let newProjectId = this.$refs.projectId.getValue();

				TimerModel.update(
					{ id: this.timer.id },
					{ project_id: newProjectId, job: '' }
				).then(
					(response) => {
						this.$db.addModels('timer', response.json);
						this.$emit('timerUpdated', response.json);
					},
					(err) => {
						this.$snotify.error('Error!', 'Update Timer Project');
					}
				);
			},
			updateJob(newJob) {
				// let newJob = this.$refs.job.getValue();

				TimerModel.update({ id: this.timer.id }, { job: newJob }).then(
					(response) => {
						console.log(response.json);
						this.$db.addModels('timer', response.json);
						this.$emit('timerUpdated', response.json);
					},
					(err) => {
						this.$snotify.error('Error!', 'Update Timer Job');
					}
				);
			},
			updateDesc(desc) {
				this.description = desc;
				
				TimerModel.update(
					{ id: this.timer.id },
					{ description: desc }
				).then(
					(response) => {
						this.$db.addModels('timer', response.json);
						this.$emit('timerUpdated', response.json);
						// this.description = response.json.description;
					},
					(err) => {
						this.$snotify.error('Error!', 'Update Timer Description');
					}
				);
			},

			startTimer() {
				this.$store.dispatch('startTimer', this.timer.id).catch(() => {
					this.$snotify.error('Error!', 'Start Timer');
				});
			},

			stopTimer() {
				this.$store.dispatch('stopTimer', this.timer.id).catch(() => {
					this.$snotify.error('Error!', 'Stop Timer');
				});
			},

			submitTimer(xhrRequest) {
				let timerRequest = xhrRequest || this.$xhrRequest.chainStart();

				timerRequest
					.send('put', `/api/timer/${this.timer.id}`, {
						submit: 1,
						description: this.description
					})
					.then((timer) => {
						this.$db.addModels('timer', timer);
						this.$emit('timerUpdated', timer);
					});

				if (this.task) {
					if (this.complete) {
						timerRequest
							.send('put', `/api/task/${this.task.id}`, {
								status: 'complete'
							})
							.then((thisTask) => {
								this.$db.addModels('task', thisTask);
								this.setUserSetting(`timer-complete-${this.timer.id}`, null, 'session');
							});

						if (this.taskCompleteComment) {
							timerRequest
								.send(
									'post',
									`/api/task/${this.task.id}/notes`,
									{
										content: this.taskCompleteComment,
										note_type: 'task_completed',
										mailto: this.taskCompleteEmailRecipients
									}
								)
								.then((note) => {
									this.$db.addModels('note', note);

									if (!xhrRequest) {
										this.$snotify.success(
											`The task '${
												this.task.name
											}' has been marked as completed!`,
											'Task Completed'
										);
									}
								});
						}
					} else {
						timerRequest
							.send('get', `/api/task/${this.task.id}`)
							.then((thisTask) => {
								this.$db.addModels('task', thisTask);
							});
					}
				}

				if (!xhrRequest) {
					timerRequest.chainEnd().catch(() => {
						this.$snotify.error('Error!', 'Submit Timer');
					});
				}
			},

			createTask() {
				this.$modalService
					.create('taskAddEdit', {
						populate: {
							project_id: this.project.id
						}
					})
					.on('save', (e, task) => {
						this.$db.addModels(
							'task',
							task,
							task.assigned_to_user_id == this.$store.getters.userId
								? 'timers'
								: 'tasks'
						);
						this.$xhrRequest
							.send('put', `/api/timer/${this.timer.id}`, {
								task_id: task.id
							})
							.then((timer) => {
								this.$db.addModels('timer', timer);
								this.$emit('timerUpdated', timer);
							});
					});
			},

			editTime() {
				this.$modalService
					.create('timerDurationEdit', {
						timer: this.timer
					})
					.on('save', (e, timer) => {
						this.$emit('timerUpdated', timer);
					});
			},

			updateCompleteStorage() {
				this.setUserSetting(`timer-complete-${this.timer.id}`, {
					complete: this.complete,
					taskCompleteComment: this.taskCompleteComment,
					taskCompleteEmailRecipients: this.taskCompleteEmailRecipients
				}, 'session');
			}
		},

		watch: {
			complete(to) {
				if (to) {
					this.taskCompleteComment = this.description;
				}

				this.updateCompleteStorage();
			},

			taskCompleteComment() {
				this.updateCompleteStorage();
			},

			taskCompleteEmailRecipients() {
				this.updateCompleteStorage();
				this.search = "";
			},


		}
	};
</script>

<style scoped lang="scss">

	.duration-time, .current-time {
		line-height:2;
		background-color: var(--v-odd-base);
	}

	::v-deep .v-card__title {
		padding: 8px 16px;
	}

	:v-deep .v-input .v-text-field {
		min-height: 30px!important;
	}

	::v-deep .v-input .v-label {
		font-size: 12px;
	}

	.card {
		transition: border 300ms, box-shadow 300ms;
		margin: 10px 0 0;
		flex-basis: 100%;
		&.layout-grid {
			@media (min-width: 768px) {
				margin: 10px 10px 0 0;
				flex-basis: calc(50% - 10px);
			}
			&.layout-grid-sm {
				@media (min-width: 1200px) {
					flex-basis: calc(33.3333% - 10px);
				}
			}
		}

		&.highlight {
			box-shadow: 0 0 10px 1px map-get($blue, "base");
		}
	}

	.card-timer-active {
		box-shadow: 0 0 5px 1px var(--v-primary-base) !important;
	}
</style>
