<template>
	<v-container fluid v-if="person.id">
		<v-row>
			<v-col cols="12" md="6">

				<!-- region person Info -->
				<v-card>

					<!--Card Header-->
					<v-card-title>
						{{person | FullName}}
						<span v-if="person.inactive" class="error--text ml-2">Inactive</span>
						<v-btn @click="editPerson" small class="ml-auto">
							<v-icon small>fad fa-pencil</v-icon>
						</v-btn>
						<v-btn v-if="person.inactive" @click="restorePerson" title="Restore Person" small class="ml-2">
							<v-icon small>fad fa-undo</v-icon>
						</v-btn>
						<v-btn v-else @click="removePerson" title="Remove Person" small class="ml-2">
							<v-icon small>fad fa-trash-alt</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-subtitle>
						<span v-if="person.title" class="ml-2 text-muted small font-weight-normal">{{person.title}}</span>
					</v-card-subtitle>

					<!--Card Body-->
					<v-card-text>
						<v-row>
							<v-col cols="3" class="mb-3 position-relative">
								<template v-if="person.department">
									<user-avataaar :user="person"></user-avataaar>
									<!-- you wanna play this, antonio? muhaha -->
									<v-btn v-if="isMe || $store.getters.userId == 7" @click="editAvatar" small min-width="0" class="edit-avatar-btn" color="accent">
										<v-icon small>fad fa-pencil</v-icon>
									</v-btn>
								</template>
								<v-avatar v-else color="primary" :size="avatarSize">
									<span class="white--text headline">{{person | initials}}</span>
								</v-avatar>
							</v-col>
							<v-col>

								<p v-if="person.phone_primary" class="mb-2">
									<v-icon x-small class="mr-2">fad fa-phone</v-icon> Primary/Cell: <a :href="`tel:${person.phone_primary}`">{{person.phone_primary}}</a>
								</p>

								<p v-if="person.phone_secondary" class="mb-2">
									<v-icon x-small class="mr-2">fad fa-phone</v-icon> Secondary: <a :href="`tel:${person.phone_secondary}`">{{person.phone_secondary}}</a>
								</p>

								<p v-if="person.phone_office" class="mb-2">
									<v-icon x-small class="mr-2">fad fa-building</v-icon> Office: <a :href="`tel:${person.phone_office}`">{{person.phone_office}}</a>
								</p>

								<p v-if="person.phone_fax" class="mb-2">
									<v-icon x-small class="mr-2">fad fa-fax</v-icon> Fax: <a :href="`tel:${person.phone_fax}`">{{person.phone_fax}}</a>
								</p>

								<p v-if="person.email_primary" class="mb-2">
									<v-icon small class="mr-2">fad fa-envelope</v-icon> Primary: <a :href="`mailto:${person.email_primary}`">{{person.email_primary || 'No Email'}}</a>
								</p>

								<p v-if="person.email_secondary" class="mb-2">
									<v-icon small class="mr-2">fad fa-envelope</v-icon> Secondary <a :href="`mailto:${person.email_secondary}`">{{person.email_secondary}}</a>
								</p>

							</v-col>

						</v-row>

						<template v-if="person.note">
							<v-divider class="mb-4"></v-divider>
							<div v-html="person.note"></div>
						</template>

						<v-divider class="my-4"></v-divider>
						<meta-list :model="person"></meta-list>

					</v-card-text>

				</v-card>

				<!--Employee Info-->
				<v-card v-if="person.used_jobs" class="mt-4">
					<v-card-title class="card-title">Employee Information</v-card-title>
					<v-card-text>
						<p v-if="person.department">Department: {{person.department}}</p>
						<p v-if="person.hired_date">Hired: {{ person.hired_date | shortDate}} ({{ person.hired_date | timeFromNow }})</p>
						<p v-if="person.exit_date">Left: {{ person.exit_date | shortDate}} ({{ person.exit_date | timeFromNow }})</p>
						<p v-if="person.custom_home">Custom Home Page: {{person.custom_home}}</p>
						<div v-if="isMe" class="mt-8">
							<h2>Preferred Job Items</h2>
							<v-autocomplete v-model="preferredJobs" :items="jobs" multiple chips small-chips deletable-chips clearable></v-autocomplete>
						</div>
					</v-card-text>
				</v-card>

				<!-- region Employee Projects -->
				<v-card v-if="person.projects_worked_on && person.projects_worked_on.length" class="mt-4">
					<v-card-title>Projects Worked On: {{person.projects_worked_on.length}}</v-card-title>
					<v-card-text class="card-body p-0">
						<template v-if="person.projects_worked_on.length">
							<v-data-table :items="person.projects_worked_on" :headers="projectFields" :search="projectFilter" sort-by="creation_date" sort-desc>
								<template v-slot:top>
									<v-text-field v-model="projectFilter" label="Search" class="mx-4"></v-text-field>
								</template>

								<template v-slot:item.creation_date="{ item }">
									{{item.creation_date | shortDate}}
								</template>

								<template v-slot:item.name="{ item }">
									<router-link :to="{name:'project.details', params:{ id: item.id }}" class="item-submeta item-submeta-item-title">{{item.name}}</router-link>
								</template>
							</v-data-table>
						</template>
					</v-card-text>
				</v-card>

			</v-col>

			<v-col cols="12" md="6">

				<v-card class="mb-4">
					<v-card-title>Last Pay Period</v-card-title>
					<v-card-text>
						<div v-if="lastPayPeriod">{{lastPayPeriod.pretty_start}} - {{lastPayPeriod.pretty_end}}</div>
						<timer-totals :timers="periodTimers" v-slot="{ duration }">
							<div><strong>Total: </strong> {{duration | formatDuration}} / {{lastPayPeriod.hours}}</div>
						</timer-totals>
						<!-- <pre class="yellow--text">{{lastPayPeriod}}</pre> -->
					</v-card-text>
				</v-card>

				<!--Notes by Employee-->
				<v-card v-if="noteNodes">
					<v-card-title>Notes By Employee</v-card-title>
					<v-card-text>
						<div v-if="!noteNodes">
							User has not created any notes.
						</div>
						<template v-else>

							<v-data-iterator :items="noteNodes" :headers="fields" :search="filter" sort-by="creation_date" sort-desc class="notes-table">

								<template v-slot:header>
									<v-toolbar class="mb-2" flat>
										<v-text-field v-model="filter" label="Search" class="mx-4" hide-details></v-text-field>
									</v-toolbar>
								</template>

								<template v-slot:default="props">
									<v-row>
										<v-col v-for="(item, index) in props.items" :key="index" cols="12">
											<v-card class="px-4" tile>
												<v-card-text>
													<div class="body-2" v-html="item.content"></div>
												</v-card-text>

												<v-divider></v-divider>
												<v-card-text class="d-flex justify-space-between flex-wrap caption">

													{{item.date | shortDate}}

													<div class="referenced-object">
														<router-link v-if="item.object_type !== 'Task'" target="_blank" :to="{name: item.linked_object.__typename.toLowerCase()+'.details', params:{id: item.linked_object.id}}">
															{{ item.linked_object.name }}
														</router-link>
														<router-link v-else target="_blank" :to="{name: 'project.details', params:{id: item.linked_object.id}}">
															{{ item.linked_object.name }} <span class="primary">TASK</span>
														</router-link>

														<div class="object-type" :class="`type-${item.linked_object.__typename.toLowerCase()}`">
															{{item.linked_object.__typename}}
														</div>

														<div class="note-type" :class="`type-${item.note_type.toLowerCase()}`">
															<template v-if="item.note_type.toLowerCase() == 'task_completed'">
																complete
															</template>
															<template v-else-if="item.note_type.toLowerCase() == 'client_to'">
																to client
															</template>
															<template v-else-if="item.note_type.toLowerCase() == 'client_from'">
																from client
															</template>
															<template v-else>
																{{item.note_type}}
															</template>
														</div>

													</div>
												</v-card-text>
											</v-card>
										</v-col>
									</v-row>
								</template>

							</v-data-iterator>

						</template>
					</v-card-text>
				</v-card>

			</v-col>

		</v-row>
	</v-container>
	<v-container v-else class="fill-height flex-column justify-center align-center">
		<p class="text-h6">Loading Employee</p>
		<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
	</v-container>
</template>

<script>
	import employeeAddEdit from "../components/modals/employeeAddEdit";
	import AvatarEdit from "../components/modals/avatarEdit";
	import settings from "@/utils/settings";
	import metaList from "../components/metaList";
	import UserAvataaar from "../components/avataaars/user-avataaar";

	import AvataaarBuilder from "../components/avataaars/avataaar-builder.vue";
	import moment from "moment";
	import TimerTotals from '../components/renderless/timerTotals.vue';

	export default {
		name: "employeeDetails",

		components: {
			employeeAddEdit,
			metaList,
			AvataaarBuilder,
			UserAvataaar,
			AvatarEdit,
			TimerTotals,
		},

		apollo: {
			simplePerson: {
				query: gql`
					query GetSinglePerson($personId: ID!) {
						simplePerson: person(id: $personId) {
							id
							first_name
							last_name
							title
							is_employee
							note
							_meta_values
							_meta_types
							phone
							phone_office
							phone_fax
							phone_primary
							phone_secondary
							email_primary
							email_secondary
							... on Employee {
								inactive
								meta_avatar
								department
								custom_home
								hired_date
								exit_date
								used_jobs
								projects_worked_on {
									client {
										id
										name
									}
									name
									id
									creation_date
								}
							}
						}
					}
				`,
				variables() {
					return {
						personId: this.$route.params.id,
					};
				},
			},
			personNotes: {
				query: gql`
					query GetSinglePersonNotes($filterParam: QueryFilter!) {
						personNotes: notes
							@filter(param: $filterParam)
							@page(limit: 200)
							@sort(param: [{ field: "date", order: desc }]) {
							nodes {
								node {
									content
									note_type
									date
									# mailto_users {
									# 	id
									# }
									creation_date
									creation_date
									linked_object {
										__typename
										... on NamedObject {
											id
											name
										}
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "author_id",
							op: "=",
							value: this.$route.params.id,
						},
						{
							field: "object_type",
							op: "!=",
							value: "Person",
						},
					];

					return {
						personId: this.$route.params.id,
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				skip() {
					return !this.person || !this.person.is_employee;
				},
			},
			periodTimers: {
				query: gql`
					query getPeriodTimers($filterParam: QueryFilter!) {
						periodTimers: timers
						@filter(param: $filterParam)
							@sort(param: [{ field: "creation_date", order: asc }]) {
							nodes {
								node {
									project_id
									time_spent
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "user_id",
									op: "=",
									value: this.person.id,
								},
								{
									field: "creation_date",
									op: ">=",
									value: this.lastPayPeriod.start
								},
								{
									field: "creation_date",
									op: "<",
									value: this.lastPayPeriod.end
								},
								{
									field: "project.id",
									op: 'not in',
									value: this.$insight.projects.offTheClockProjects
								}
							],
						},
					};
				},
				update(serverData) {
					return Linq.from(serverData.periodTimers.nodes)
						.select((row) => row.node)
						.toArray();
				},
			}
		},

		data() {
			return {
				filter: null,
				projectFilter: null,
				// notes: null,
				projectFields: [
					{
						value: "client.name",
						text: "Client",
						sortable: true,
					},
					{
						value: "name",
						text: "Project",
						sortable: true,
					},
					{
						value: "creation_date",
						text: "Started",
						sortable: true,
					},
				],
				fields: [
					{
						value: "content",
						text: "Note",
					},
					{
						value: "object_id",
						text: "Object",
					},
					{
						value: "date",
						text: "Date",
						sortable: true,
					},
				],
			};
		},

		computed: {
			lastPayPeriod() {
				let last_period_start;
				let last_period_end;

				if (moment().format("DD") <= 15) {
					last_period_start = moment().subtract(1, "month").date(16).startOf("day");
					last_period_end = moment().subtract(1, "month").endOf('month');
				} else if (moment().format("DD") > 15) {
					last_period_start = moment().startOf('month');
					last_period_end = moment().date(15).endOf('day');
				}

				return {
					start: last_period_start.toISOString(), //.format('YYYY-MM-DD'),
					end: last_period_end.toISOString(), //.format('YYYY-MM-DD'),
					pretty_start: last_period_start.format('MMMM, DD Y'),
					pretty_end: last_period_end.format('MMMM, DD Y'),
					hours: this.getBusinessDays(last_period_start, last_period_end) * 8
				};
			},

			person() {
				return {
					...this.simplePerson,
					...this.personNotes,
				};
			},
			preferredJobs: {
				get() {
					let myJobs =
						this.getUserSetting("preferred-jobs") ||
						this.person.used_jobs ||
						[];
					myJobs = myJobs.filter((s) => s);

					return myJobs;
				},
				set(val) {
					this.$insight.helpers._timerJobsDep = null;
					if (val === null) {
						this.setUserSetting("preferred-jobs", null);
						// this.$snotify.success(`Preferred jobs reset`);
					} else {
						this.setUserSetting("preferred-jobs", Object.values(val));
						this.$snotify.success(`Preferred jobs updated`);
					}
				},
			},
			avatarSize() {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return 70;
					case "sm":
						return 120;
					default:
						return 150;
				}
			},
			jobs() {
				return settings.quickbooksJobs;
			},
			isMe() {
				return this.$route.params.id == this.$store.getters.userId;
			},
			noteNodes() {
				if (!this.personNotes) {
					return [];
				}

				return this.personNotes.nodes.map((row) => row.node);
			},
		},

		destroyed() {},

		methods: {
			getBusinessDays(startDate, endDate){
				
				let holidays = [
 					// New Years Day
					"01/01",
					// Independence Day
					"07/04",
					// Memorial Day last monday in may
					moment().month('May').endOf('month').startOf('day').dayBackwards(1).format("MM/DD"),
					// Labor Day first monday in sep
					moment().month('Sep').startOf('month').dayForwards(1).format("MM/DD"),
					// Thanksgiving 4th thursday in nov
					moment().month('Nov').startOf('month').dayForwards(4).day(4 + 21).format("MM/DD"),
					// Christmas
					"12/25",
				];

				let days = 0 //endDate.diff(startDate, 'days');
				for (var m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
					if(m.isoWeekday() != 6 && m.isoWeekday() != 7 && !holidays.includes(m.format('MM/DD'))) {
						days += 1;
					}
				}
				return days;
			},

			removePerson() {
				this.$swal({
					title: "Are you sure?",
					text: "You cannot undo this!",
					icon: "warning",
					buttons: true,
					dangerMode: true,
				}).then((willDelete) => {
					if (willDelete) {
						this.$apollo
							.mutate({
								mutation: gql`
									mutation ($data: UpdateEmployeeArgs!) {
										updateEmployee(data: $data) {
											id
											inactive
										}
									}
								`,
								variables: {
									data: {
										id: this.$route.params.id,
										inactive: true,
									},
								},
								update: (store, { data: { updateEmployee } }) => {
									this.$snotify.success(
										`${this.person.first_name} has been marked as inactive.`
									);
								},
							})
							.catch((error) => {
								console.error("error removing person", error);
								this.modalErrorMessage =
									"There was a problem updating the data.";
							});
					}
				});
			},
			restorePerson() {
				this.$apollo
					.mutate({
						mutation: gql`
							mutation ($data: UpdateEmployeeArgs!) {
								updateEmployee(data: $data) {
									id
									inactive
								}
							}
						`,
						variables: {
							data: { id: this.$route.params.id, inactive: false },
						},
						update: (store, { data: { updateEmployee } }) => {
							this.$snotify.success(
								`${this.person.first_name} has been marked as active.`
							);
						},
					})
					.catch((error) => {
						console.error("error restoring person", error);
						this.modalErrorMessage =
							"There was a problem updating the data.";
					});
			},
			editPerson() {
				this.$modalService
					.create("employeeAddEdit", {
						personId: this.$route.params.id,
					})
					.on("save", (e, person) => {
						this.$snotify.success("Employee updated", "Success");
						this.$apollo.queries.simplePerson.refetch();
					});
			},
			editAvatar() {
				this.$modalService
					.create("avatarEdit", {
						personId: this.$route.params.id,
					})
					.on("save", (e, person) => {
						this.$snotify.success("Avatar updated", "Success");
						this.$apollo.queries.simplePerson.refetch();
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .v-data-table-header__icon {
		margin-left: 8px;
	}

	.object-type,
	.note-type {
		position: absolute;
		top: 0;
		bottom: 0;
		writing-mode: vertical-rl;
		text-align: center;
		background-color: var(--v-secondary-base);
		font-weight: 700;
		color: white;
		/* &.type-project {
					background-color: var(--v-secondary-base);
				} */
	}
	.object-type {
		left: 0;
		transform: scaleX(-1) scaleY(-1);
	}
	.note-type {
		right: 0;
		transform: scaleX(1) scaleY(1);
		color: var(--v-anchor-base);
		background-color: var(--v-background-darken1);
		&.type-deadline {
			background-color: var(--v-deadline-base);
		}
		&.type-internal {
			background-color: var(--v-background-darken1);
		}
		&.type-task_completed {
			background-color: var(--v-completed-base);
		}
		&.type-client_to,
		&.type-client_from {
			background-color: var(--v-background-darken1);
		}
	}

	.edit-avatar-btn {
		position: absolute;
		bottom: 5%;
		left: 15px;
	}
</style>
