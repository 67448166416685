<template>
	<div>
		<v-card :loading="$apollo.queries.projects.loading" class="mb-4">
			<v-card-title>
				<template v-if="projects">{{projects.total}}</template> Closed Web Projects
			</v-card-title>
			<v-card-subtitle>Older projects do not have the category, allocated time, or start date set. For better results please update old projects.</v-card-subtitle>

			<v-card-text>
				<v-row>
					<v-col cols="6" md="3">
						<v-menu ref="startmenu" v-model="startmenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="start_date" label="Start date" prepend-icon="fad fa-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="start_date" :active-picker.sync="startActivePicker" :max="maxDate" min="2016-01-01" @change="saveStart"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col cols="6" md="3">
						<v-menu ref="endmenu" v-model="endmenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="end_date" label="End date" prepend-icon="fad fa-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="end_date" :active-picker.sync="endActivePicker" :max="maxDate" min="2016-01-01" @change="saveEnd"></v-date-picker>
						</v-menu>
					</v-col>
				</v-row>

				<v-data-table v-if="projectList" :headers="headers" :items="projectList" mobile-breakpoint="1100" hide-default-footer disable-pagination dense>
					<template v-slot:body.prepend="">
						<tr>
							<td colspan="6" class="caption text-right-" style="border-bottom-width:3px;">Project Department Totals:</td>
							<td v-for="(row, ndx) in projectTotals" :key="ndx"
								:class="`${row.department}${cellShade}`"
								class="caption text-center"
								style="border-bottom-width:3px;"
							>{{row.percent}}%</td>
						</tr>
					</template>
					<template v-slot:item.hours_allocated="{ item }">
						<span v-if="item.hours_allocated == 0" class="text--disabled">N/A</span>
						<span v-else>{{item.hours_allocated}}</span>
					</template>
					<template v-slot:item.budget_percentage="{ item }">
						<span v-if="item.hours_allocated == 0" class="text--disabled">N/A</span>
						<span v-else>{{item.budget_percentage}}%</span>
					</template>
					<template v-slot:item.account_executive_user.id="{ item }">
						<div>{{item.account_executive_user.full_name}}</div>
					</template>
					<template v-slot:item.project_started="{ item }">
						<div>{{item.project_started | shortDate}}</div>
					</template>
					<template v-slot:item.closed_status_date="{ item }">
						<div>{{item.closed_status_date | shortDate}}</div>
					</template>

					<template v-slot:item.admin_hours="{ item }">
						{{item.admin_hours}}%
					</template>
					<template v-slot:item.designer_hours="{ item }">
						{{item.designer_hours}}%
					</template>
					<template v-slot:item.developer_hours="{ item }">
						{{item.developer_hours}}%
					</template>
					<template v-slot:item.it_hours="{ item }">
						{{item.it_hours}}%
					</template>
					<template v-slot:item.video_hours="{ item }">
						{{item.video_hours}}%
					</template>

				</v-data-table>

			</v-card-text>

		</v-card>
	</div>
</template>

<script>
	import { gql } from "graphql-tag";
	import moment from "moment";
	import settings from "../../../utils/settings";

	export default {
		components: {},
		data() {
			return {
				startmenu: false,
				start_date: moment("2016-01-01").format("YYYY-MM-DD"),

				endmenu: false,
				end_date: moment().format("YYYY-MM-DD"),

				startActivePicker: null,
				endActivePicker: null,
				maxDate: moment().format("YYYY-MM-DD"),

				search: "",
			};
		},
		apollo: {
			projects: {
				query: gql`
					query getWebProjects($filterParam: QueryFilter!) {
						projects
							@filter(param: $filterParam)
							@sort(param: [{ field: "creation_date", order: asc }]) {
							total
							nodes {
								node {
									id
									name
									creation_date
									time_spent
										@formatDuration(format: "H.2", number: true)
									hours_allocated
									budget_percentage
									account_executive_user {
										id
										full_name
									}
									client {
										id
										name
									}
									project_started
									closed_status_date
									analytics {
										time_spent_by_department {
											department
											time_spent
												@formatDuration(
													format: "H.2"
													number: true
												)
										}
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "status",
							op: "=",
							value: "closed",
						},
						{
							field: "category",
							op: "=",
							value: "Web",
						},
						{
							field: "time_spent",
							op: ">",
							value: 0,
						},
						{
							field: "project_started",
							op: "!=",
							value: null,
						},
						{
							field: "closed_status_date",
							op: "!=",
							value: null,
						},
						{
							field: "project_started",
							op: "<",
							value: this.end_date,
						},
						{
							field: "closed_status_date",
							op: ">=",
							value: this.start_date,
						},
					];
					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
		},

		computed: {
			cellShade() {
				return this.$vuetify.theme.isDark ? " darken-1" : "--text";
			},
			headers() {
				let cellshade = this.cellShade;
				return [
					// { text: "AE", value: "account_executive_user.id" },
					{ text: "Client", value: "client.name" },
					{ text: "Project", value: "name" },
					{ text: "Total Time", value: "time_spent" },
					{ text: "Allocated Time", value: "hours_allocated" },
					{ text: "Budget Used", value: "budget_percentage" },
					{ text: "Weeks", value: "weeks" },
					// { text: "Started", value: "project_started" },
					// { text: "Ended", value: "closed_status_date" },
					{
						text: "Admin %",
						value: "admin_hours",
						class: `admin${cellshade}`,
						cellClass: `admin${cellshade}`,
						align: "center",
						width: 100,
					},
					{
						text: "Design %",
						value: "designer_hours",
						class: `designer${cellshade}`,
						cellClass: `designer${cellshade}`,
						align: "center",
						width: 100,
					},
					{
						text: "Dev %",
						value: "developer_hours",
						class: `developer${cellshade}`,
						cellClass: `developer${cellshade}`,
						align: "center",
						width: 100,
					},
					{
						text: "IT %",
						value: "it_hours",
						class: `it${cellshade}`,
						cellClass: `it${cellshade}`,
						align: "center",
						width: 100,
					},
					{
						text: "Video %",
						value: "video_hours",
						class: `video${cellshade}`,
						cellClass: `video${cellshade}`,
						align: "center",
						width: 100,
					},
				];
			},

			projectTotals() {
				if (!this.projects) {
					return [];
				}

				let departmentTotals = {};
				settings.userDepartments.forEach((d) => {
					departmentTotals[d] = 0;
				});
				let total = 0;

				departmentTotals = Linq.from(this.projects.nodes)
					.select((row) => row.node)
					.aggregate((item, acc) => {
						item.analytics.time_spent_by_department
							.forEach(({ department, time_spent }) => acc[department] += time_spent);

						// side effect
						total += item.time_spent;

						return acc;
					}, departmentTotals);

				return settings.userDepartments
					.map(d => ({
						percent: Math.round(departmentTotals[d] / total * 100),
						department: d.toLowerCase()
					}));
			},

			projectList() {
				if (!this.projects) {
					return [];
				}

				return Linq.from(this.projects.nodes)
					.select((row) => row.node)
					.select((item) => {
						// set project week length
						let start = moment(item.project_started);
						let end = moment(item.closed_status_date);

						return {
							weeks: Math.abs(start.diff(end, "weeks")),
							...item,
						};
					})
					.select((item) => {
						let analytics = item.analytics.time_spent_by_department;

						settings.userDepartments.forEach((d) => {
							item[`${d.toLowerCase()}_hours`] = "0";
						});

						analytics.forEach((a) => {
							item[a.department.toLowerCase() + "_hours"] =
								Math.round((a.time_spent / item.time_spent) * 100);
						});

						return item;
					})
					.orderBy((item) => item.project_started)
					.toArray();
			},
		},

		methods: {
			saveStart(start_date) {
				this.$refs.startmenu.save(start_date);
			},
			saveEnd(end_date) {
				this.$refs.endmenu.save(end_date);
			},
		},

		watch: {
			startmenu(val) {
				val && setTimeout(() => (this.startActivePicker = "YEAR"));
			},
			endmenu(val) {
				val && setTimeout(() => (this.endActivePicker = "YEAR"));
			},
		},
	};
</script>

<style lang="scss" scoped>
	// ::v-deep {
	// 	text {
	// 		fill: var(--v-anchor-base);
	// 	}
	// }

	// ::v-deep .v-tabs-items {
	// 	background-color: transparent !important;
	// }
</style>
