<script>
	import settings from '../../utils/settings';

	export default {
		props: {
			tag: String,

			href: String,
			state: {
				type: Object,
				default() {
					return {};
				}
			},
			method: {
				type: String,
				default: 'GET'
			},
			data: Object,
			dataType: {
				type: String,
				default: 'json'
			},

			input: {
				type: undefined
			},

			transform: {
				type: Function,
				default: d => d
			},

			errorHandler: {
				type: Boolean,
				default: true
			}
		},

		data() {
			return {
				curRefresh: false,
				version: 0,
				status: 'idle',
				payload: undefined,
				error: undefined,
				lastHref: ''
			};
		},

		computed: {
			filledHref() {
				if (!this.href) {
					return this.href;
				}

				let href = this.href.replace(/\{([^}]+)\}/g, k => this.state[k.substr(1, k.length - 2)]);

				if (!href.match(/^https?:\/\//)) {
					href = settings.url + '/' + href.replace(/^\/+/, '');
				}

				return href;
			},
			getter() {
				this.curRefresh;

				if (this.filledHref) {
					if (this.lastHref == this.filledHref) {
						return null;
					}
					this.lastHref = this.filledHref;

					return new Promise((resolve, reject) => {
						$.ajax({
							method: this.method || 'GET',
							url: this.filledHref,
							data: this.data,
							dataType: this.dataType,

							success: function(resp) {
								resolve(resp);
							},
							error: function(jqXHR) {
								reject(jqXHR);
							}
						});
					});
				}
				else if (this.input instanceof Promise) {
					return this.input;
				}
				else if (typeof this.input == 'function') {
					let resp = this.input();
					if (resp instanceof Promise) {
						return resp;
					}
					
					return Promise.resolve(resp);
				}
				else {
					return Promise.resolve(this.input);
				}
			},

			value() {
				if (this.payload === undefined) {
					return undefined;
				}

				if (this.transform) {
					return this.transform(this.payload);
				}

				return this.payload;
			}
		},

		methods: {
			refresh() {
				this.lastHref = '';
				this.curRefresh = !this.curRefresh;
			}
		},

		render(h) {
			let result = this.$scopedSlots.default({
				status: this.status,
				isLoading: this.status == 'idle',
				isResolved: this.status == 'resolved',
				isError: this.status == 'rejected',
				value: this.value,
				error: this.error,
				refresh: this.refresh
			});

			if (this.tag) {
				result = h(this.tag, result);
			}

			return result;
		},

		watch: {
			getter: {
				handler(to) {
					if (!to) {
						return;
					}

					let version = ++this.version;
					this.status = 'idle';
					this.payload = undefined;
					this.error = undefined;

					to
						.then(p => {
							if (version == this.version) {
								this.status = 'resolved';
								this.payload = p;
							}
						})
						.catch(e => {
							if (version == this.version) {
								this.status = 'rejected';
								this.error = e;
								this.$emit('error', this.error);

								if (this.errorHandler) {
									this.$snotify.error(`Status code ${e.status}`, "Request Failed")

									if (e.status == 0) {
										console.error('Request returned status 0. This could indicate either a server side 500 or 447.');
									}
								}
							}
						});
				},
				immediate: true
			},

			value(to) {
				if (to !== undefined) {
					this.$emit('data', to);
				}
			}
		}
	}
</script>