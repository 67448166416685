import { render, staticRenderFns } from "./contactDetails.vue?vue&type=template&id=b706d9f6&scoped=true&"
import script from "./contactDetails.vue?vue&type=script&lang=js&"
export * from "./contactDetails.vue?vue&type=script&lang=js&"
import style0 from "./contactDetails.vue?vue&type=style&index=0&id=b706d9f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b706d9f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_0f7bc6efe1949d16b55ed303eb0ec848/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VCol,VContainer,VDivider,VIcon,VList,VListItem,VListItemGroup,VListItemSubtitle,VListItemTitle,VRow})
