<template>
<v-lazy :options="{threshold: 0.2}" :min-height="60">
	<v-card :data-task-id="task.id" class="task mb-1"
		:class="{['task-' + (task.assigned_to_user_id ? '' : 'un') + 'assigned']: true, ['task-status-' + task.status]: true}">
		<v-card-title>
			<small class="grey--text caption mr-1" title="Estimated Completion Date">{{task.estimated_completion_date | shortDate }}</small>

			<!-- REQUIRED DAYS -->
			<v-text-field type="number" ref="required_days" @change="updateTask" :value="task.required_days" dense hide-details min="0" style="max-width:40px;font-size:12px;"></v-text-field>
			<required-days-picker
				:value="task.required_days"
				:timeline-due-date="task.timeline_due_date"
				:previous-task-ids="task.previous_task_ids"
				:project-id="task.project_id"
				@input="updateTask"
				v-slot="{ trigger }"
			>
				<v-btn small min-width="0" class="mr-1" @click="trigger" plain><v-icon small>fad fa-calendar-alt</v-icon></v-btn>
			</required-days-picker>

			<template v-if="task.status == 'current'">
				<v-chip :color="'task-' + task.status" dark label x-small class="mx-1">Current</v-chip>
			</template>

			<template v-if="task.status == 'complete'">
				<v-chip :color="'task-' + task.status" dark label x-small class="mx-1">Completed Task</v-chip>
			</template>

			<template v-if="!task.assigned_to_user_id">
				<v-chip color="error" dark label x-small class="mx-1">Unassigned Task</v-chip>
			</template>

			<v-chip :color="assignedTo.department.toLowerCase()" dark label x-small v-if="assignedTo" :class="`badge-${assignedTo.department.toLowerCase()}`">{{assignedTo | FullName}}</v-chip>

			<div class="mx-3 body-1" v-if="pastDue">
				<v-icon small color="overdue">fas fa-exclamation</v-icon> {{ pastDue }}
			</div>

			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="button" small min-width="0" v-bind="attrs" v-on="on" class="ml-auto">
						<v-icon small>fas fa-ellipsis-h</v-icon>
					</v-btn>
				</template>
				<v-list>
					<template v-if="task.status !== 'complete'">
						<v-list-item @click="completeTask">
							<v-list-item-title>Complete</v-list-item-title>
						</v-list-item>
					</template>
					<v-list-item @click="editItem">
						<v-list-item-title>Edit {{task.itemType}}</v-list-item-title>
					</v-list-item>
					<v-list-item @click="deleteItem">
						<v-list-item-title>Delete {{task.itemType}}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

		</v-card-title>

		<v-card-text>
			<a href="javascript:void(0)" class="task-link" @click="editItem">{{task.name}}</a>
			<div v-if="task.description && task.description.length>0 && task.name!=task.description" v-html="task.description" class="new-lines"></div>
			<div v-if="task.meta_milestone" class="mt-3"><v-icon small class="mr-3" color="primary">fad fa-flag</v-icon>{{task.meta_milestone}}</div>
		</v-card-text>

		<!-- <v-btn small icon class="add-task-above" @click="$emit('insert-task', task)">
			<v-icon small>fas fa-plus</v-icon>
		</v-btn> -->

	</v-card>
</v-lazy>
</template>

<script>

	import TaskModel from "@/models/task";
	import moment from 'moment';
	import { EventBus } from '../utils/EventBus';
	import requiredDaysPicker from './fields/requiredDaysPicker.vue';

	export default {
		components: { requiredDaysPicker },
		name: "taskListItem",

		props: {
			task: Object
		},
		computed: {
            pastDue() {
                let days = this.$insight.tasks.taskOverdueDays(this.task);
                return days>0?`Past due ${days + (days===1?' day':' days')}`:null;
            },
			past() {
				return moment().isSameOrAfter(this.task.estimated_completion_date);
			},
			assignedTo() {
				return this.$db.getModel("person", this.task.assigned_to_user_id);
			},
		},
		methods: {
			completeTask() {
				// Add note
				this.$modalService.create("noteAddEdit", {
					objType: "task",
					objId: this.task.id,
					// showType: false,
					// showDate: false,
					noteType:'task_completed',
					title: `Complete Task: ${this.task.name}`,
					saveBtnText: 'Submit',
					// skipBtn: true
				}).on('skip save', () => {
					this.$xhrRequest.send('put', `/api/task/${this.task.id}?fields=*,project,client,current_timer`, {status: 'complete'}).then((task) => {
						this.$db.addModels('task', task);
						this.$snotify.success("Success!", "Complete Task");
						// this.$emit('update');
						EventBus.$emit('task/update');
					}, () => {
						this.$snotify.error("Error!", "Complete Task");
					});
				});
			},
			editItem() {
				this.$modalService
					.create("taskAddEdit", {
						taskId: this.task.id,
					})
					.on("save", (e, task) => {
						this.$db.addModels("task", task);
						EventBus.$emit('task/update');
					});
			},
			deleteItem() {
				this.$xhrRequest
					.send("get", `/api/task/${this.task.id}`, {fields: "timers"})
					.then(taskdata => {
						if (taskdata.timers.length > 0) {
							this.$swal(
								"Cannot delete this task",
								`This task has one or more timers associated with it.`
							);
							return;
						}
					});
				this.$swal({
					title: "Delete this task?",
					text: "You cannot undo this!",
					icon: "warning",
					buttons: true,
					dangerMode: true
				}).then(willDelete => {
					if (willDelete) {
						this.$xhrRequest
							.send('delete', `/api/task/${this.task.id}`)
							.then((resp) => {
								this.$db.removeModels("task", this.task);
								this.$snotify.success("Task deleted", "Success");
								EventBus.$emit('task/delete');
							}, (xhr) => {
								this.$snotify.error('Error deleting task', "Error");
							});
					}
				});
			},
			updateTask(requiredDays) {
				TaskModel.update(
					{id: this.task.id},
					{required_days: requiredDays}
				).then(response => {
					this.$emit("update");
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.add-task-above {
		position: absolute;
		top: -15px;
		right: 8px;
		z-index: 2;
	}

	.task {
		border-right-width: 5px;
		border-right-style: solid;
		border-right-color: map-get($grey, "darken-2");
		&.task-unassigned {
			border-right-color:map-get($red, "base");
		}
		&.task-status-complete {
			border-right-color:var(--v-task-complete-base);
		}
		&.task-status-pending {
			border-right-color:var(--v-pending-base);
		}
	}
</style>
