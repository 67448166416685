script/* SH-30 'Start-Up' hosting plan and get 30 minutes of free maintenance a month.  EH-60 is the 'Enhanced' plan, with 60 minutes of maintenance

and AH-120 is 'Advanced' plan with 2 hrs. free maintenance.  */

<template>
	<v-container fluid>
		<v-card :loading="$apollo.queries.timers.loading" class="mb-4">
			<v-card-title>
				Project Timers
				<v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y ref="menu" min-width="200px">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="date" type="date" label="Date" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" hide-details dense class="mx-5"></v-text-field>
					</template>
					<v-date-picker v-model="date" @input="menu = false" header-color="primary" color="primary" show-adjacent-months no-title :max="maxDate"></v-date-picker>
				</v-menu>

				<v-btn small @click="$apollo.queries.timers.refetch()" class="ml-3">
					<v-icon small>fad fa-sync</v-icon>
				</v-btn>
			</v-card-title>
		</v-card>

		<template v-if="timers">
			<v-sheet v-for="item in groupedTimers" :key="item.id" class="mb-6">
				<v-sheet color="background darken-1" class="subtitle-1 px-3 py-1 d-flex justify-space-between">
					<span>{{item.client.name}}</span>
					<timer-clock :timers="item.timers"></timer-clock>
				</v-sheet>
					<v-divider class="mb-1"></v-divider>
				<div v-for="projects in item.projects" :key="projects.project.name">
					<div class="subtitle-2 px-3 pt-1 pb-2 d-flex justify-space-between"
						 :class="$vuetify.theme.dark ? 'grey--text text--lighten-2' : 'grey--text text--darken-2'">
						<span>{{projects.project.name}}</span>
						<timer-clock :timers="projects.timers"></timer-clock>
					</div>
					<div class="striped ml-sm-10 body-2">
						<div v-for="timer in projects.timers" :key="timer.id" class="d-flex flex-column flex-sm-row py-1 pl-2">
							<div style="width:250px;">{{timer.user.full_name}}</div>
							<div style="flex-basis: 100%;" class="d-flex align-center">
								<v-tooltip v-if="timer.task" top>
									<template v-slot:activator="{ on, attrs }">
										<span v-bind="attrs" v-on="on">
											<v-icon left small>fad fa-info-square</v-icon>
										</span>
									</template>
									<span>TASK: {{timer.task.name}}</span>
								</v-tooltip>
								<span v-if="timer.description">
									{{timer.description}}
								</span>
								<span v-else class="grey--text">[No Description]</span>
							</div>
							<div style="flex-basis: 15%" class="d-flex align-center justify-end text-right pr-3">
								<timer-clock :timer="timer"></timer-clock>
								<v-icon v-if="timer.stop" color="grey darken-2" x-small right>fas fa-circle</v-icon>
								<v-icon v-else color="status-active" class="active" x-small right>fas fa-circle</v-icon>
							</div>
						</div>
					</div>
					<v-divider class="my-2"></v-divider>
				</div>
			</v-sheet>
		</template>

	</v-container>
</template>

<script>
	
	import UserAvataaar from "../components/avataaars/user-avataaar";
	import moment from "moment";
	import TimerClock from "@/components/timerClock";
	import { timeInterval } from '../utils/timeInterval';

	export default {
		name: "projectTimers",
		components: { UserAvataaar, TimerClock },
		data() {
			return {
				menu: false,
				// date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
				date: moment().startOf('day').format('YYYY-MM-DD'),
				maxDate: moment().format("YYYY-MM-DD")
			};
		},
		apollo: {
			timers: {
				query: gql`
					query getProjectTimersList($filterParam: QueryFilter!) {
						timers
							@filter(param: $filterParam)
							@sort(
								param: [
									{ field: "client.name", order: asc }
									{ field: "project.name", order: asc }
								]
							) {
							sql
							total
							nodes {
								node {
									client {
										id
										name
									}
									project {
										id
										name
									}
									task {
										id
										name
									}
									id
									client_id
									project_id
									creation_date
									start
									stop
									description
									user {
										id
										full_name
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "creation_date",
							op: ">=",
							value: this.start_date,
						},
						{
							field: "creation_date",
							op: "<=",
							value: this.end_date,
						},
					];
					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				pollInterval: timeInterval('2 minutes'),
			},
		},
		computed: {
			groupedTimers() {
				if (!this.timers || !this.timers.nodes) {
					return [];
				}

				let grouped = Linq.from(this.timers.nodes)
					.select(row => row.node)
					.groupBy(
						(c) => c.client_id,
						(_, items) => ({
							client: items.first().client || {
								name: "[NO CLIENT]",
							},
							timers: items.toArray(),
							projects: items
								.groupBy(
									(g) => g.project_id,
									(_, projectItems) => ({
										project: projectItems.first().project || {
											name: "[NO PROJECT]",
										},
										timers: projectItems.toArray(),
									})
								)
								.toArray(),
						})
					)
					.toArray();

				return grouped;
			},
			start_date() {
				return moment(this.date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
			},
			end_date() {
				return  moment(this.date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
			}
		},
	};
</script>

<style lang="scss" scoped>
	.striped {
		> div {
			&:nth-child(odd) {
				background-color: var(--v-odd-base);
			}
		}
	}
	i.active {
		border-radius: 100%;
		background-color: var(--v-status-active-base);
		box-shadow: 0 0 5px 1px var(--v-status-active-base);
	}
</style>
