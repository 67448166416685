var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',{attrs:{"options":{threshold: 0.2},"min-height":60}},[_c('v-card',{staticClass:"task mb-1",class:( _obj = {}, _obj['task-' + (_vm.task.assigned_to_user_id ? '' : 'un') + 'assigned'] = true, _obj['task-status-' + _vm.task.status] = true, _obj ),attrs:{"data-task-id":_vm.task.id}},[_c('v-card-title',[_c('small',{staticClass:"grey--text caption mr-1",attrs:{"title":"Estimated Completion Date"}},[_vm._v(_vm._s(_vm._f("shortDate")(_vm.task.estimated_completion_date)))]),_c('v-text-field',{ref:"required_days",staticStyle:{"max-width":"40px","font-size":"12px"},attrs:{"type":"number","value":_vm.task.required_days,"dense":"","hide-details":"","min":"0"},on:{"change":_vm.updateTask}}),_c('required-days-picker',{attrs:{"value":_vm.task.required_days,"timeline-due-date":_vm.task.timeline_due_date,"previous-task-ids":_vm.task.previous_task_ids,"project-id":_vm.task.project_id},on:{"input":_vm.updateTask},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var trigger = ref.trigger;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","min-width":"0","plain":""},on:{"click":trigger}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-calendar-alt")])],1)]}}])}),(_vm.task.status == 'current')?[_c('v-chip',{staticClass:"mx-1",attrs:{"color":'task-' + _vm.task.status,"dark":"","label":"","x-small":""}},[_vm._v("Current")])]:_vm._e(),(_vm.task.status == 'complete')?[_c('v-chip',{staticClass:"mx-1",attrs:{"color":'task-' + _vm.task.status,"dark":"","label":"","x-small":""}},[_vm._v("Completed Task")])]:_vm._e(),(!_vm.task.assigned_to_user_id)?[_c('v-chip',{staticClass:"mx-1",attrs:{"color":"error","dark":"","label":"","x-small":""}},[_vm._v("Unassigned Task")])]:_vm._e(),(_vm.assignedTo)?_c('v-chip',{class:("badge-" + (_vm.assignedTo.department.toLowerCase())),attrs:{"color":_vm.assignedTo.department.toLowerCase(),"dark":"","label":"","x-small":""}},[_vm._v(_vm._s(_vm._f("FullName")(_vm.assignedTo)))]):_vm._e(),(_vm.pastDue)?_c('div',{staticClass:"mx-3 body-1"},[_c('v-icon',{attrs:{"small":"","color":"overdue"}},[_vm._v("fas fa-exclamation")]),_vm._v(" "+_vm._s(_vm.pastDue)+" ")],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"button","small":"","min-width":"0"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-ellipsis-h")])],1)]}}])},[_c('v-list',[(_vm.task.status !== 'complete')?[_c('v-list-item',{on:{"click":_vm.completeTask}},[_c('v-list-item-title',[_vm._v("Complete")])],1)]:_vm._e(),_c('v-list-item',{on:{"click":_vm.editItem}},[_c('v-list-item-title',[_vm._v("Edit "+_vm._s(_vm.task.itemType))])],1),_c('v-list-item',{on:{"click":_vm.deleteItem}},[_c('v-list-item-title',[_vm._v("Delete "+_vm._s(_vm.task.itemType))])],1)],2)],1)],2),_c('v-card-text',[_c('a',{staticClass:"task-link",attrs:{"href":"javascript:void(0)"},on:{"click":_vm.editItem}},[_vm._v(_vm._s(_vm.task.name))]),(_vm.task.description && _vm.task.description.length>0 && _vm.task.name!=_vm.task.description)?_c('div',{staticClass:"new-lines",domProps:{"innerHTML":_vm._s(_vm.task.description)}}):_vm._e(),(_vm.task.meta_milestone)?_c('div',{staticClass:"mt-3"},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"primary"}},[_vm._v("fad fa-flag")]),_vm._v(_vm._s(_vm.task.meta_milestone))],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }