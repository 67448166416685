<script>
	import { getDuration } from '../../helpers/timers';

	export default {
		props: {
			tag: String,

			time_spent: {
				type: Number
			},
			time_since: {
				type: Object,
			},

			timer: {
				type: Object
			},

			timers: {
				type: Array
			},

			rateLimited: {
				type: Boolean,
				default: false,
			},
			ignoreLunch: {
				type: Boolean,
				default: true,
			},
		},

		computed: {
			timerList() {
				let timers = [];

				if (this.time_spent) {
					let fakeTimer = {
						time_spent: this.time_spent
					};

					if (this.time_since) {
						fakeTimer.time_since = this.time_since;
					}

					timers.push(fakeTimer);
				}

				if (this.timer) {
					timers.push(this.timer);
				}

				if (this.timers) {
					let toAppend = this.timers;

					if (this.ignoreLunch) {
						toAppend = toAppend.filter(t => !this.$insight.projects.offTheClockProjects.includes(t.project_id));
					}

					timers.push(...toAppend);
				}

				return timers;
			},

			now() {
				let now;
				if (this.rateLimited) {
					now = this.$store.state.app.currentMinute;
				}
				else {
					now = this.$store.state.app.currentTime;
				}

				return now;
			},

			totalDuration() {
				return Linq.from(this.timerList)
					.sum(t => {
						//TODO: t.project_id ONLY needed because of REST
						if (t.__typename == 'Timer' || t.hasOwnProperty('project_id')) {
							return getDuration(t, this.now);
						}
						else {
							let duration = t.time_spent;

							if (t.time_since) {
								duration += this.now.diff(moment(t.time_since.date), 'seconds');
							}

							return duration;
						}
					});
			},
			classes() {
				return this.timerList.some(t => this.isTimerActive(t))
					? 'active'
					: 'inactive';
			}
		},

		methods: {
			isTimerActive(timer) {
				if (timer.time_since) {
					return true;
				}

				return timer.status == 'Active' || !timer.stop;
			}
		},

		render(h) {
			let result = this.$scopedSlots.default({
				duration: this.totalDuration,
				classes: this.classes
			});

			if (this.tag) {
				result = h(this.tag, result);
			}

			return result;
		}
	}
</script>