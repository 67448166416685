import colors from 'vuetify/lib/util/colors';

const themeColors = {
	light: "#fdf9ff",
	dark: "#1e1e1e",
	purple: "#4b1590",
	darkestPurple: "#1e0146",
	teal: "#33BBCC",
	darkTeal: "#2ea8b8",
	magenta: "#E524C5",
	green: "#28df87",
};


export default {
	options: {
		customProperties: true,
	},

	primary: themeColors.darkTeal,
	secondary: themeColors.purple,
	tertiary: '#757575',
	accent: colors.deepPurple.lighten2,

	magenta: themeColors.magenta,
	error: themeColors.magenta,

	anchor: themeColors.light,

	odd: "#2b2b2b",
	background: "#121212",

	// departments
	admin: "#4C1396",
	it: "#0097a7",
	video: "#283593",
	designer: "#6e48d0",
	developer: "#3673ce",

	// status
	pending: "#1e0146",
	overdue: themeColors.magenta,
	today: "#B7159C",
	soon: "#800F6D",
	later: "#49093E",

	current: colors.lightBlue.darken1,

	//timer
	"status-active": themeColors.green,
	disabled: colors.grey.darken4,

	"task-complete": colors.teal.base,
	"task-current": "#c643c8",
	"task-pending": colors.deepPurple.base,

	// task status:
	unassigned: themeColors.magenta,
	assigned: themeColors.purple,
	completed: colors.teal.base,

	overtime: themeColors.magenta,

	deadline: themeColors.magenta,
	reminder: colors.blue.darken2,

	"text-color": themeColors.light,
	"cards": "#1e1e1e",
	"app-bar": "#272727",
	"toolbar": "#272727",
	"chips": "#292929",
	"dividers": "#2b2b2b",
	"navigation-drawer": "#363636",
	"system-bar": "#000",

	// calendar
	"calendar-background": "#2b2b2b",
	"outside-background-color": "#202020",
	"weeknumber-background-color": "#202020",
	"line-color": "#9e9e9e",
	"interval-color": "#9e9e9e",
	"interval-line-color": "#9e9e9e",
	"past-color": themeColors.light,
	
	// table
	"table-active": themeColors.teal,
	"table-hover": "#181818",
	"table-group": "#181818",
	
	// picker
	"picker-body": "#2b2b2b",
	"picker-clock": "#2b2b2b",
	"picker-indeterminateTime": "#2b2b2b",
	"picker-title": "#2b2b2b",
	
	// kbd
	"kbd-bg": themeColors.magenta,
	"kbd-color": themeColors.light,
	
	"toggle": themeColors.magenta,

	// text over background
	"text-over-bg": colors.grey.lighten1,
	"ot-text-over-bg": "#ff4ce1",
	"working-text-over-bg": themeColors.green,
	
}