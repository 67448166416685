import './startup/momentjs-addons';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Snotify, { SnotifyPosition } from 'vue-snotify'
import vuetify from './plugins/vuetify/vuetify';
import AsyncComputed from './utils/AsyncComputed';
import VueGoogleCharts from 'vue-google-charts';
import VueClipboard from 'vue-clipboard2';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import VueNativeNotification from 'vue-native-notification';
import VueApollo from 'vue-apollo';
import { apolloProvider } from './graphql/provider';
import { VueMasonryPlugin } from 'vue-masonry';
import ReactiveProvide from 'vue-reactive-provide';
import { install as Debug } from './plugins/debug';


import './startup/vue-addons';
import './startup/vue-filters';
import './startup/global-components';

// window.Vue = Vue;
// window.$ = $;

Vue.config.productionTip = false;

Vue.use(Snotify, { toast: { position: SnotifyPosition.rightTop } });
Vue.use(AsyncComputed);
Vue.use(VueGoogleCharts);
Vue.use(VueClipboard);
Vue.use(CKEditor);
Vue.use(VueNativeNotification, {
	requestOnNotify: true
});
Vue.use(VueApollo);
Vue.use(VueMasonryPlugin)
Vue.use(ReactiveProvide);
Vue.use(Debug);

new Vue({
	router,
	store,
	vuetify,
	apolloProvider,
	render: h => h(App)
}).$mount('#app')
