import AjaxResource from './base'
import settings from '../utils/settings'

export default new AjaxResource(
	settings.url + '/api/note{/id}',
	{
        list: {
            method: 'GET',
            url: settings.url + '/api/note/list'
        },
        newClientNote: {
            method: 'POST',
            url: settings.url + '/api/client/{id}/notes'
        },
        newProjectNote: {
            method: 'POST',
            url: settings.url + '/api/project/{id}/notes'
        },
        newPersonNote: {
            method: 'POST',
            url: settings.url + '/api/user/{id}/notes'
        },
        listClientNotes: {
            method: 'GET',
            url: settings.url + '/api/client/{id}/list'
        },
        listPersonNotes: {
            method: 'GET',
            url: settings.url + '/api/user/{id}/list'
        },
        listProjectNotes: {
            method: 'GET',
            url: settings.url + '/api/project/{id}/list'
        }
    }
);