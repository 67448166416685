var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project)?_c('div',{staticClass:"fill-height d-flex flex-column"},[_c('paginator',{staticClass:"fill-height",attrs:{"tag":"div","params":{ limit: 200, search: '', noteTypes: [] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var params = ref.params;
var setParam = ref.setParam;
return [_c('ApolloQuery',_vm._b({attrs:{"tag":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
var query = ref.query;
return [_c('ApolloQuery',_vm._b({staticClass:"fill-height d-flex flex-column",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var noteTypesData = ref.result.data;
var noteTypesQuery = ref.query;
return [_c('v-card',{staticClass:"mb-1",attrs:{"loading":query.loading}},[_c('v-card-title',[_vm._v(" Notes "),(data)?_c('v-chip',{staticClass:"ml-1"},[_vm._v(_vm._s(data.notes.total))]):_vm._e(),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){query.refetch(); noteTypesQuery.refetch();}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-sync")])],1),_c('add-note-button',{attrs:{"obj-type":"project","obj-id":_vm.project.id},on:{"save":function($event){query.refetch(); noteTypesQuery.refetch();}}})],1)],1)],1),(noteTypesData)?_c('v-sheet',{staticClass:"mb-1 py-2 px-4"},[_c('debounce-events',{attrs:{"events":{ change: 500 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var change = ref.change;
return [_c('v-text-field',{staticClass:"my-0 mb-3",attrs:{"value":params.search,"clearable":"","hide-details":"","label":"Search Notes"},on:{"input":function($event){change(function () { return setParam('search', $event == null ? '' : $event); })}}})]}}],null,true)}),_c('v-btn-toggle',{staticClass:"flex-wrap",attrs:{"value":params.noteTypes,"dense":"","multiple":"","group":"","color":"primary"},on:{"change":function($event){return setParam('noteTypes', $event)}}},[_vm._l((noteTypesData.notes.nodes),function(row){return [_c('v-btn',{key:row.node.note_type,attrs:{"small":"","value":row.node.note_type}},[_vm._v(" "+_vm._s(_vm.noteTypeName(row.node.note_type))+" ("+_vm._s(row.group_total)+") ")])]})],2)],1):_vm._e(),(data)?_c('div',{staticClass:"flex-grow-1",staticStyle:{"position":"relative"}},[_c('v-data-iterator',{staticClass:"desktop-fit",attrs:{"items":data.notes.nodes,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props.items),function(item,ndx){return _c('note-list-item',{key:ndx,attrs:{"note":item.node},on:{"update":function($event){query.refetch(); noteTypesQuery.refetch();}}})})}}],null,true)})],1):_vm._e()]}}],null,true)},'ApolloQuery',_vm.getProjectNoteTypesQuery(params),false))]}}],null,true)},'ApolloQuery',_vm.getProjectNotesQuery(params),false))]}}],null,false,2270543427)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }