import store from '../store'
import moment from 'moment'
import {dbInst} from "../db/simple-db";
import Vue from 'vue';
import settings from './settings';
import globalMixins from '../mixins/global-mixin'
import { XHRRequest } from './xhr-request';

class Color {
	constructor(r, g, b) {
		this.r = r;
		this.g = g;
		this.b = b;
	}

	get style() {
		return `rgb(${this.r}, ${this.g}, ${this.b})`;
	}

	get brightness() {
		return 0.2126 * this.r + 0.7152 * this.g + 0.0722 * this.b;
	}

	get isBright() {
		return this.brightness > 155;
	}
}

const Insight = {
    projects: {
        offTheClockProjects: ['359'],
        breakProject:        1161,
        breaktimeProjects:[359,1161],
        // todo maybe put clientList.currentTask() here?

        unassignedVsAssigned(project) {
            if (!project) {
                return {
                    totalOpen:       0,
                    totalUnassigned: 0
                };
            }

            let totalOpen       = 0,
                totalUnassigned = 0;

            dbInst.findModels('task', (task) => {
                return task.project_id == project.id && !task.complete_date;
            }).forEach((task) => {
                totalOpen++;
                if (!task.assigned_to_user_id) {
                    totalUnassigned++;
                }
            });

            return {
                totalOpen,
                totalUnassigned
            };
        },

        totalTime(project, rateLimited) {
            let time = 0;

            if ($.isNumeric(project.confirmed_time)) {
                time = parseInt(project.confirmed_time);
            }

            dbInst.findModels('timer', (timer) => {
                return timer.project_id == project.id && timer.status !== 'Submitted';
            }).forEach((timer) => {
                time += Insight.timers.getDuration(timer, rateLimited);
            });

            return time;
        },

        hoursByDepartment(project, range, rateLimited) {
            let departments        = {},
                userDepartmentList = store.getters.userDepartmentList;

            for (let id in project.confirmed_time_by_user[range]) {
                if (!departments[userDepartmentList[id]]) {
                    departments[userDepartmentList[id]] = 0;
                }

                departments[userDepartmentList[id]] += parseInt(project.confirmed_time_by_user[range][id]);
            }

            dbInst.findModels('timer', (timer) => {
                return timer.project_id == project.id && timer.status !== 'Submitted';
            }).forEach((timer) => {
                let person = dbInst.getModel('person', timer.user_id);

                if (!departments[person.department]) {
                    departments[person.department] = 0;
                }

                departments[person.department] += Insight.timers.getDuration(timer, rateLimited);
            });

            for (let i in departments) {
                departments[i] = Math.round(departments[i] / 36) / 100;	// /3600 keeping last 2 decimal points
            }

            return departments;
        },

        hoursByUser(project, range, rateLimited) {
            let users = {};

            for (let id in project.confirmed_time_by_user[range]) {
                if (!users[id]) {
                    users[id] = 0;
                }

                users[id] += parseInt(project.confirmed_time_by_user[range][id]);
            }

            dbInst.findModels('timer', (timer) => {
                return timer.project_id == project.id && timer.status !== 'Submitted';
            }).forEach((timer) => {
                if (!users[timer.user_id]) {
                    users[timer.user_id] = 0;
                }

                users[timer.user_id] += Insight.timers.getDuration(timer, rateLimited);
            });

            for (let i in users) {
                users[i] = Math.round(users[i] / 36) / 100;	// /3600 keeping last 2 decimal points
            }

            return users;
        },

        toggleProjectStarred(project) {
            let newVal = project.meta_starred == 'yes' ? 'no' : 'yes';

            let toast = Vue.prototype.$snotify.create({
                config: {
                    html:         `
						<div class="snotifyToast__title">
							<b>Working...</b>
						</div>
						<div class="snotifyToast__body">
							<i class="fa fa-cog fa-spin"></i> ${newVal == 'yes' ? '' : 'Un-'}Starring ${project.name}
						</div>
					`,
                    type:         'info',
                    position:     'rightTop',
                    timeout:      0,
                    closeOnClick: false
                }
            });

            XHRRequest.send('put', `/api/project/${project.id}`, {meta_starred: newVal}).then((proj) => {
                Vue.prototype.$snotify.mergeToast(toast, {
                    body:   (newVal == 'yes' ? '' : 'Un-') + 'Starred ' + project.name,
                    title:  'Edit Project',
                    config: {
                        html:    false,
                        timeout: 2000
                    }
                }, 'success');

                dbInst.addModels('project', proj);
            });
        },
    },

    clients: {
        hoursByDepartment(client, range, rateLimited) {
            let departments        = {},
                userDepartmentList = store.getters.userDepartmentList;

            for (let id in client.confirmed_time_by_user) {
                if (!departments[userDepartmentList[id]]) {
                    departments[userDepartmentList[id]] = 0;
                }

                departments[userDepartmentList[id]] += client.confirmed_time_by_user[range][id];
            }

            dbInst.findModels('timer', (timer) => {
                return timer.client_id == client.id && timer.status !== 'Submitted';
            }).forEach((timer) => {
                let person = dbInst.getModel('person', timer.user_id);

                if (!departments[person.department]) {
                    departments[person.department] = 0;
                }

                departments[person.department] += Insight.timers.getDuration(timer, rateLimited);
            });

            for (let i in departments) {
                departments[i] = Math.round(departments[i] / 36) / 100;	// /3600 keeping last 2 decimal points
            }

            return departments;
        },

        hoursByUser(client, range, rateLimited) {
            let users = {};

            for (let id in client.confirmed_time_by_user[range]) {
                if (!users[id]) {
                    users[id] = 0;
                }

                users[id] += parseInt(client.confirmed_time_by_user[range][id]);
            }

            dbInst.findModels('timer', (timer) => {
                return timer.client_id == client.id && timer.status !== 'Submitted';
            }).forEach((timer) => {
                if (!users[timer.user_id]) {
                    users[timer.user_id] = 0;
                }

                users[timer.user_id] += Insight.timers.getDuration(timer, rateLimited);
            });

            for (let i in users) {
                users[i] = Math.round(users[i] / 36) / 100;	// /3600 keeping last 2 decimal points
            }

            return users;
        }
    },

    tasks: {
        totalTime(task, rateLimited) {
            let time = 0;

            if ($.isNumeric(task.confirmed_time)) {
                time = task.confirmed_time;
            }

            dbInst.findModels('timer', (timer) => {
                return timer.task_id == task.id;
            }).forEach((timer) => {
                time += Insight.timers.getDuration(timer, rateLimited);
            });

            return time;
        },

        taskOverdueDays(task) {
            if (task.status !== 'current') {
                return false;
            }
            let timelineDate = moment(task.timeline_due_date).startOf('day'),
                diff         = timelineDate.diff(store.getters.currentDay, 'day'),
                today        = store.getters.currentDay;

            if (timelineDate.isBefore(today)) {
                return Math.abs(diff);
            }
        },

        taskStatus(task) {
            let timelineDate = moment(task.timeline_due_date).startOf('day'),
                diff         = timelineDate.diff(store.getters.currentDay, 'day'),
                today        = store.getters.currentDay;

            if (timelineDate.isBefore(today)) {
                return 'overdue';
            }
            else if (timelineDate.isSame(today, 'day')) {
                return 'today';
            }
            else if (diff <= 7) {
                return 'soon';
            }
            else {
                return 'later';
            }
        },

        getAllPreviousTasks(task, includeSelf = true) {
            let ancestors = [task.id], i = 0, nextTask = Object.assign({}, task);

            while (i < ancestors.length) {
                nextTask = dbInst.getModel('task', ancestors[i]);
                if (nextTask) {
                    nextTask.previous_task_ids.filter((taskId) => !ancestors.includes(taskId)).forEach((id) => {
                        ancestors.push(id);
                    });
                }
                i++;
            }
            if (!includeSelf) {
                ancestors.shift();
            }
            return ancestors;
        },

        /**
         * Should the task be hidden from the Current tasks list?
         * @param task
         * @returns {*}
         */
        hideFromCurrentTaskList(task) {
            if (!task) {
                return false;
            }

            let taskOptions = globalMixins.methods.getTaskOptions(task);
            if (!taskOptions.hideUntil) {
                return false;
            }
            let currentDueDate = task.timeline_due_date?task.timeline_due_date.split(' ')[0]:null;
            let lastDueDate = taskOptions.timeline_due_date?taskOptions.timeline_due_date.split(' ')[0]:null;
            // when due date of task changes we must un-snooze the task:
            if (currentDueDate !== lastDueDate) {
                globalMixins.methods.setTaskOptions(task, {
                    timeline_due_date: null,
                    hideUntil:         null
                });
                return false;
            }

            return moment(taskOptions.hideUntil).isSameOrAfter(new moment());
        },

        pinToCurrentTaskList(task) {
            return globalMixins.methods.getTaskOptions(task).pinToCurrent;
        }
    },

    timers: {
        getDuration(timer, rateLimited) {
            if (!timer) {
                return moment.duration(0, 'seconds');
			}
			
			if (['Inactive', 'Submitted'].includes(timer.status) && timer.time_spent) {
				return timer.time_spent;
			}

			let stop;
			if (timer.stop) {
				stop = moment(timer.stop);
			}
			else if (rateLimited) {
				stop = store.state.app.currentMinute;
			}
			else {
				stop = store.state.app.currentTime;
			}

			return stop.diff(moment(timer.start), 'seconds');
        },

        timerDurationsSum(timers, ignoreLunch = true, rateLimited = false) {
			let total = 0;

			for (let timer of timers) {
				if (ignoreLunch && Insight.projects.offTheClockProjects.includes(timer.project_id)) {
					continue;
                }

                total += Insight.timers.getDuration(timer, rateLimited);
			}

            return total;
        },

        formatDuration(time) {
            if (!moment.isDuration(time)) {
                time = moment.duration(time, 'seconds');
            }

            let hours   = '0' + Math.floor(time.asHours()),
                minutes = time.minutes(),
                seconds = time.seconds();

            hours = hours.substr(Math.max(2, hours.length - 1) * -1);

            return hours + ':' + ('0' + minutes).substr(-2) + ':' + ('0' + seconds).substr(-2);
        },

        getDurationFromString(timeStr, format = 'HH:mm:ss') {
            let a = moment().startOf('day'),
                b = moment(timeStr, format);

            return moment.duration(b.diff(a)).asSeconds();
        }
    },

    notes: {
        /* getLastStatusNote(notes) {
            let date       = null,
                newestNote = null;

            (notes || []).forEach((note) => {
                if (note.note_type !== 'status') {
                    return;
                }

                if (!date || date.isBefore(note.date)) {
                    date = moment(note.date);
                    newestNote = note;
                }
            });

            return newestNote;
		}, */
		
		getLastStatusNote(project) {
			if (!project) {
				return '';
			}

			let notes = dbInst.findModels("note", (note) => {
				return (
					note.note_type == 'status' &&
					note.object_type.toLowerCase() === "project" &&
					note.object_id == project.id
				);
			});

			if (project.last_status_note) {
				let content = project.last_status_note.replace(/^\d{1,2}\/\d{1,2}\/\d{2}: /, '');
				
				notes.push({
					date: project.modified_date,
					content
				});
			}

			if (!notes.length) {
				return '';
			}

			let newestNote = notes[0],
				newestDate = moment(notes[0].date);
			for (let i = 1; i < notes.length; i++) {
				if (newestDate.isBefore(notes[i].date)) {
					newestDate = moment(notes[i].date);
					newestNote = notes[i];
				}
			}

			return `${Vue.options.filters.shortDate(newestNote.date)}<br>${newestNote.content}`;
		}
    },

	helpers: {
		
		select2ToVuetify(data) {
			// 		label:   this.$db.getModel('client', this.project.client_id).name,
			// 		options: [
			// 			{
			// 				value: this.project.id,
			// 				text:  this.project.name + (this.project.status === 'hidden' ? ' (hidden)' : '')
			// 			}
			// 		]

			return data.reduce((acc, cur) => {
				if (acc.length) {
					acc.push({ divider: true });
				}

				acc.push({ header: cur.label });
				acc.push(
					...cur.options
				);

				return acc;
			}, []);
		},

		// todo: kill it with fire!
        /* getClientProjectList() {
            let clientProjectMap = {};
            dbInst.getModels('project').forEach((project) => {
                if (!clientProjectMap[project.client_id]) {
                    clientProjectMap[project.client_id] = {
                        client:   dbInst.getModel('client', project.client_id),
                        projects: []
                    };
                }

                clientProjectMap[project.client_id].projects.push(project);
            });
            dbInst.getModels('client').filter((client) => {
                return !clientProjectMap[client.id];
            }).forEach((client) => {
                clientProjectMap[client.id] = {
                    client:   client,
                    projects: []
                };
            });

            return clientProjectMap;
		}, */
		
		// Used for v-autocomplete
		// todo: kill it with fire!
		/* getClientProjectListFlat({ includeClient = false, allClients = false, allProjects = false } = {}) {
			let clientProjects = Insight.helpers.getClientProjectList();
			clientProjects = Object.values(clientProjects)
				.filter(v => v.client)
				.map(v => {
					v.projects.sort((a, b) => a.name.localeCompare(b.name));

					return v;
				})
				.sort((a, b) => a.client.name.localeCompare(b.client.name));
			
			let flatMap = [];

			for (const value of clientProjects) {
				let projects = value.projects;
				if (!allProjects) {
					projects = projects.filter(p => !['closed', 'removed'].includes(p.status));
				}
				projects = projects.map(p => ({ ...p, _id: `project_${p.id}`, _clientName: value.client.name }));
				
				if (allClients || projects.length) {
					let divider = { divider: true };
					if (flatMap.length) {
						flatMap.push(divider);
					}
					
					divider._nextEl = { header: value.client.name, projects: projects };
					flatMap.push(divider._nextEl);
				
					if (includeClient) {
						flatMap.push(
							{ ...value.client, _id: `client_${value.client.id}`, _clientName: value.client.name }
						);
					}
					
					flatMap.push(...projects);
				}
			}

            return flatMap;
		}, */

        interpolateColor(map, percent) {
            percent = Math.max(0, percent);

            let keys = Object.keys(map);
            keys = keys.map(parseFloat);
			keys.sort((a, b) => {
				return a - b;
			});

			for (let i = 1; i < keys.length; i++) {
                if (percent < keys[i]) {
                    percent = (percent - keys[i - 1]) / (keys[i] - keys[i - 1]);
                    let newColor = [];
                    for (let j = 0; j < 3; j++) {
                        newColor.push(
                            Math.round(map[keys[i - 1]][j] + ((map[keys[i]][j] - map[keys[i - 1]][j]) * percent))
                        );
					}
					
					return new Color(...newColor);
				}
				else if (percent == keys[i]) {
					return new Color(...map[keys[i]]);
				}
			}
			
			return new Color(...map[keys[keys.length - 1]]);
        },

        interpolateColorGreenToRed(percent) {
            let map = {
                0:   [46, 204, 113],
                0.8: [255, 255, 0],
                1:   [200, 0, 0]
            };

            return Insight.helpers.interpolateColor(map, percent);
		},
		
		getColorBrightness(color) {

		},

        getRequestErrors(xhr, def = 'There was an error processing the request') {
            let result = {};

            if (xhr.responseJSON && xhr.responseJSON.errors) {
                let errors = xhr.responseJSON.errors;

                if (typeof errors === 'string') {
                    result._form = [errors];
                }
                else if (Array.isArray(errors)) {
                    result._form = errors;
                }
                else {
                    for (let field in errors) {
                        let msgs = errors[field];
                        if (!Array.isArray(msgs)) {
                            msgs = [msgs];
                        }

                        result[field] = msgs;
                    }
                }

                return result;
            }
            else {
                if (xhr.status > 400) {
                    def = [def, `the server responded with error ${xhr.status}`];
                }
                result._form = def;
            }

            return result;
        },

        flattenRequestErrors(errors) {
            let msgs = [];

            for (let field in errors) {
                let prefix = field == '_form' ? '' : `${field}: `;
                if (typeof errors[field] === 'array') {
                    for (let i = 0; i < errors[field].length; i++) {
                        msgs.push(`${prefix}${errors[field][i]}`);
                    }
                } else {
                    msgs.push(`${prefix}${errors[field]}`);
                }
            }

            return msgs;
        },

        _timerJobs:    false,
        _timerJobsDep: false,
		getTimerJobs(type) {
			if (type) {
				let matchingJobs = settings.quickbooksJobs.filter(j => j.indexOf(type) === 0);
				if (matchingJobs.length) {
					return matchingJobs;
				}
			}
			
            if (store.getters.userId !== Insight.helpers._timerJobsDep) {
                Insight.helpers._timerJobsDep = store.getters.userId;

				let myJobs = globalMixins.methods.getUserSetting('preferred-jobs') || store.getters.userData.used_jobs || [];
				
				//TODO: PHP side returning invalid jobs... filter out falsy values for now
				myJobs = myJobs.filter(s => s);
				
				Insight.helpers._timerJobs = [
					{ header: 'My Jobs' },
					...myJobs.map(value => {
						return {value, text:value}
					}),
					{ divider: true},
					{ header: 'Other Jobs' },
					...settings.quickbooksJobs.filter(job => !myJobs.includes(job)).map(value => {
						return {value, text:value}
					})
				];
			}

            return Insight.helpers._timerJobs;
        },

        getTaskStatusClass(task) {
            let status = Insight.tasks.taskStatus(task);

            switch (status) {
                case 'overdue':
                    return 'bg-red';
                case 'today':
                    return 'bg-orange';
                case 'soon':
                    return 'bg-green';
                default:
                    return 'bg-blue';
            }
		},

		getProjectIcon(cat) {
			switch (cat.toLowerCase()) {
				case "audit":
					return "fad fa-universal-access";
				case "custom":
					return "fad fa-code";
				case "design":
					return "fad fa-pencil-paintbrush";
				case "hosting":
					return "fad fa-clouds";
				case "internal":
					return "fad fa-home-heart";
				case "mobile":
					return "fad fa-mobile-android-alt";
				case "marketing":
					return "fad fa-bullhorn";
				case "video":
					return "fad fa-film";
				case "web":
					return "fad fa-laptop-code";
				case "all":
					return "fas fa-asterisk";
				default:
					return "fad fa-question-circle";
			}
		},

		getTaskGroupIcon(type) {
			switch (type.toLowerCase()) {
				case "waterfall":
					return "fad fa-diagram-subtask";
				case "kanban":
					return "fas fa-square-kanban";
				default:
					return "fad fa-question-circle";
			}
		},
		

    },

    users: {
        /**
         * Return sorted array of users' departments, suitable for use with select2 component.
         * eg [ {text: 'Thomas Kahn', value: '11'}, ... ]
         */
        departments() {
            let users = dbInst.findModels('person', (person) => {
				return person.employee && person.inactive == 0;
			});

			users = Array.from(new Set(users.map(u => u.department))).sort();

			return users.map(d => ({ value: d, text: d }));
        },
        /**
         * Return sorted array of employees suitable for use with select2 component.
         */
        users() {
            let users = dbInst.findModels('person', (person) => {
                return person.employee;
            }).map((person) => {
                return {
                    value: person.id,
                    text:  Vue.options.filters.FullName(person)
                };
            });

            users.sort((a, b) => {
                return a.text.localeCompare(b.text);
            });

            return users;
        },

	    activeEmployees() {
		    let users = dbInst.findModels('person', (person) => {
			    return person.employee && person.inactive==0;
		    }).map((person) => {
			    return {
				    value: person.id,
				    text:  Vue.options.filters.FullName(person)
			    };
		    });

		    users.sort((a, b) => {
			    return a.text.localeCompare(b.text);
		    });

		    return users;
	    },

        validUsers() {
            let users = dbInst
                .findModels('person', (person) => {
                    return person.employee;
                })
                .filter(p => !p.inactive)
                .map((person) => {
                    return {
                        value: person.id,
                        text:  Vue.options.filters.FullName(person)
                    };
                });

            users.sort((a, b) => {
                return a.text.localeCompare(b.tdext);
            });

            return users;
        },

	}
};

window.insight = Insight;

export default Insight;
