<template>
	<v-card>
		<v-card-title>
			Gantt
		</v-card-title>
		<v-card-text>
			<GChart v-if="ganttData && taskCount" :settings="{ packages: ['gantt'] }" type="Gantt" :data="ganttData" :options="chartOptions" />
			<div v-if="!taskCount">There are no tasks for this project.</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import { IdType } from "../../../utils/IdType";
	export default {
		name: "chartProjectGantt",
		data() {
			return {
				taskCount: 0,
			};
		},
		props: {
			pid: IdType,
		},
		apollo: {
			projectTasks: {
				query: gql`
					query getProjectTasks($filterParam: QueryFilter!) {
						projectTasks: tasks
							@filter(param: $filterParam)
							@sort(
								param: [{ field: "completion_order", order: asc }]
							) {
							total
							nodes {
								node {
									id
									name
									start_date
									estimated_completion_date
									previous_task_ids
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "project.id",
							op: "=",
							value: this.pid,
						},
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				skip() {
					return !this.pid;
				},
				pollInterval: timeInterval("5 minutes"),
			},
		},

		computed: {
			chartOptions() {
				return {
					chart: {},
					backgroundColor: {
						fill: this.$vuetify.theme.currentTheme["background"],
						opacity: 0.5,
					},
					width: "100%",
					// height: '330',
					height: this.taskCount * 30 + 50,
					gantt: {
						trackHeight: 30,
						barHeight: 15,
						barCornerRadius: "1",
						criticalPathEnabled: false,
						criticalPathStyle: {
							strokeWidth: "2",
							stroke: this.$vuetify.theme.currentTheme["odd"],
						},
						arrow: {
							color: this.$vuetify.theme.currentTheme["accent"],
							width: 2,
							angle: 100,
							radius: 0,
						},
						innerGridHorizLine: {
							stroke: this.$vuetify.theme.currentTheme["divider"],
							strokeWidth: "1",
						},
						innerGridTrack: {
							fill: this.$vuetify.theme.currentTheme["background"],
						},
						innerGridDarkTrack: {
							fill: this.$vuetify.theme.currentTheme["odd"],
						},
						// labelStyle: {
						// 	fontSize: 14,
						// 	color: "#757575",
						// },
						percentEnabled: false,
						percentStyle: {
							fill: this.$vuetify.theme.currentTheme["primary"],
						},
						// Specifies that the tasks should sorted topologically, if true; otherwise use the same order as the corresponding rows of the DataTable.
						sortTasks: true,
					},
				};
			},
			ganttData() {
				let headers = [
					[
						{ type: "string", label: "Task ID" },
						{ type: "string", label: "Task Name" },
						{ type: "date", label: "Start Date" },
						{ type: "date", label: "End Date" },
						{ type: "number", label: "Duration" },
						{ type: "number", label: "Percent Complete" },
						{ type: "string", label: "Dependencies" },
					],
				];
				let tasks = this.getTasks;
				return headers.concat(tasks);
			},
			getTasks() {
				if (!this.projectTasks) {
					return [];
				}

				let tasks = Linq.from(this.projectTasks.nodes)
					.select((row) => row.node)
					.select(task => [
						String(task.id),
						task.name,
						new Date(task.start_date),
						new Date(task.estimated_completion_date),
						null, // duration
						null, // percentage complete
						task.previous_task_ids.toString()
					])
					.toArray();

				this.taskCount = this.projectTasks.total;
				return tasks;
			},
		},
	};
</script>

<style lang="scss" scoped>
	// vertical labels
	::v-deep g:nth-child(9) {
		text {
			fill: var(--v-anchor-base);
		}
	}

	// Horizontal Labels
	::v-deep g:nth-child(3) {
		text {
			fill: var(--v-anchor-base);
		}
	}

	// Bars
	::v-deep g:nth-child(7) {
		rect {
			fill: var(--v-primary-base);
		}
	}

	// Percent Complete
	::v-deep g:nth-child(8) {
		path {
			fill: var(--v-task-complete-base);
		}
	}

	// Tooltips
	::v-deep g:nth-child(10) {
		rect {
			stroke: var(--v-background-darken1);
			fill: var(--v-background-darken1);
		}
		text {
			fill: var(--v-anchor-base);
		}
		text:nth-child(2) {
			fill: var(--v-primary-base);
		}
	}
</style>