<template>

	<div>

		<v-card :loading="$apollo.queries.projectStats.loading" class="mb-4">
			<v-card-title>
				Overall Hours
				<v-btn @click="$apollo.queries.projectStats.refetch()" small min-width="0" class="ml-auto">
					<v-icon small>fad fa-sync</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-4"></v-divider>
			<v-row v-if="projectStats" class="totals text-center">
				<v-col>
					<div class="text-h4 font-weight-bold">{{projectStats.time_spent}}</div>
					<div class="label">Hours Spent</div>
				</v-col>
				<v-divider vertical></v-divider>
				<v-col class="pa-3">
					<div class="text-h4 font-weight-bold">{{projectStats.hours_allocated}}</div>
					<div class="label">Hours Budgeted</div>
				</v-col>
				<v-divider vertical></v-divider>
				<v-col class="pa-3">
					<div v-if="projectStats.budget_percentage" class="text-h4 font-weight-bold">{{projectStats.budget_percentage}}%</div>
					<div v-else class="text-h4 font-weight-bold">
						<v-icon large>fad fa-infinity</v-icon>
					</div>
					<div class="label">Of Total Hours</div>
				</v-col>
			</v-row>
		</v-card>

		<v-card :loading="$apollo.queries.timers.loading" class="mb-4">
			<v-card-title class="align-start">
				Project Timesheet
				<v-btn-toggle mandatory v-model="dataRange" class="ml-auto mr-3">
					<v-btn v-for="option in $options.dateRangeOptions" :key="option.value" small :value="option.value" class="text-none">
						{{option.text}}
					</v-btn>
				</v-btn-toggle>

				<div v-if="dataRange == 'custom'">
					<template-data :data="{ value: false }" v-slot="showMenu">
						<v-menu ref="rangeMenu" v-model="showMenu.value" :close-on-content-click="false" :return-value.sync="selectedDateRange" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="selectedDateRange" label="Select Range" hide-details dense class="mx-4" prepend-icon="fad fa-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="selectedDateRange" no-title range scrollable color="primary" :max="maxDate">
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="rangeMenu = false">
									Cancel
								</v-btn>
								<v-btn text color="primary" @click="$refs.rangeMenu.save(selectedDateRange)">
									OK
								</v-btn>
							</v-date-picker>
						</v-menu>
					</template-data>
				</div>

				<v-btn @click="$apollo.queries.timers.refetch()" small min-width="0">
					<v-icon small>fad fa-sync</v-icon>
				</v-btn>
			</v-card-title>

			<div v-if="!$apollo.queries.timers.loading" class="d-flex justify-center flex-wrap mb-4 pb-5">
				<v-sheet v-for="item in timeByEmployee" :key="item.key" dark height="100" min-width="200" class="text-center pa-4 ma-1 d-flex align-center justify-center flex-column" :color="item.user.department | lowercase">
					<div class="overline caption">{{item.user.full_name}}</div>
					<div class="text-h5">{{item.total_timers | formatDuration}}</div>
				</v-sheet>
			</div>
		</v-card>


		<div v-if="!$apollo.queries.timers.loading">
			<v-tabs v-model="tab" fixed-tabs class="mb-4">
				<v-tab>
					Employee
				</v-tab>
				<v-tab>
					Timeline
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab" class="mb-4" style="background-color:transparent">
				<v-tab-item>
					<div v-if="!timeByEmployee.length">
						<v-alert dark color="error" border="top">No data. Try expanding the timeline</v-alert>
					</div>
					<template v-if="timeByEmployee.length">
						<v-card v-for="group in timeByEmployee" :key="group.user.id" :color="group.user.department | lowercase" class="mb-6" >
							<v-card-title class="pa-2 body-1 white--text">
								{{group.user.full_name}}
								<div class="ml-auto">Total: {{group.total_timers | formatDuration}}</div>
							</v-card-title>
							<div class="grey darken-4 fill-height">
								<v-data-table :headers="headers" :items="group.timers" dense>
									<template v-slot:item.time_spent="{ item }">
										{{ item.time_spent | formatDuration }}
									</template>
									<template v-slot:item.creation_date="{ item }">
										{{ item.creation_date | shortDate }}
									</template>
									<template v-slot:item.task="{ item }">
										<template v-if="item.task">{{item.task.name}}</template>
										<template v-else>Quick Timer</template>
									</template>
									<template v-slot:item.description="{ item }">
										<div v-if="!item.submit_date" class="primary--text">Not Submitted Yet</div>
										{{item.description}}
									</template>
								</v-data-table>
							</div>
						</v-card>
					</template>
				</v-tab-item>
				<v-tab-item>
					<div v-if="!timerNodes.length">
						<v-alert dark color="error" border="top">No data. Try expanding the timeline</v-alert>
					</div>
					<v-data-table v-if="timerNodes.length" :headers="timelineHeaders" :items="timerNodes" sort-by="date" sort-desc disable-pagination hide-default-footer>
						<template v-slot:item.user="{ item }">
							<div class="d-flex align-center">
								<user-avataaar class="avatar mr-2" :uid="item.user.uid" style="max-width:35px;"></user-avataaar>
								{{ item.user.full_name }}
							</div>
						</template>
						<template v-slot:item.time_spent="{ item }">
							{{ item.time_spent | formatDuration }}
						</template>
						<template v-slot:item.creation_date="{ item }">
							{{ item.creation_date | shortDate }}
						</template>
						<template v-slot:item.task="{ item }">
							<template v-if="item.task">{{item.task.name}}</template>
							<template v-else>Quick Timer</template>
						</template>
						<template v-slot:item.description="{ item }">
							<div v-if="!item.submit_date" class="primary--text">Not Submitted Yet</div>
							{{item.description}}
						</template>
					</v-data-table>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
</template>

<script>
	import { ProjectHourStats } from "@/graphql/queries";
	import moment from "moment";
	import UserAvataaar from "../../../components/avataaars/user-avataaar.vue";
	import { timeInterval } from '../../../utils/timeInterval';

	export default {
		name: "projectTimesheet",
		components: { UserAvataaar },
		inject: ['projectContext'],
		data() {
			return {
				dataRange: 7,
				selectedDateRange: [
					moment().startOf('day').subtract(3, "d").format("YYYY-MM-DD"),
					moment().endOf('day').format("YYYY-MM-DD"),
				],
				headers: [
					{ text: "Time Spent", value: "time_spent", width: 100 },
					{ text: "Date", value: "creation_date", width: 150 },
					{ text: "Task", value: "task", width: 200 },
					{ text: "Note", value: "description" },
				],
				timelineHeaders: [
					{ text: "Date", value: "creation_date", width: 150 },
					{ text: "User", value: "user", width: 200 },
					{ text: "Time Spent", value: "time_spent", width: 100 },
					{ text: "Task", value: "task" },
					{ text: "Note", value: "description" },
				],
				tab: null,
				maxDate: moment().format("YYYY-MM-DD"),
			};
		},

		apollo: {
			projectStats: {
				query() {
					return ProjectHourStats(this.project.id);
				},
				pollInterval: timeInterval('5 minutes + 1 minute - 1 tup'),
				skip() {
					return !this.project;
				},
			},
			timers: {
				query: gql`
					query getProjectTimers($filterParam: QueryFilter!) {
						timers
							@filter(param: $filterParam)
							@sort(
								param: [{ field: "creation_date", order: desc }]
							) {
							total
							nodes {
								node {
									id
									job
									project_id
									creation_date
									submit_date
									time_spent
									description
									task {
										name
									}
									user {
										uid
										id
										full_name
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "project_id",
							op: "=",
							value: this.project.id,
						},
					];

					if (this.dataRange) {
						if (this.dataRange == "custom") {
							filters.push({
								field: "creation_date",
								op: ">=",
								value: moment(this.selectedDateRange[0]).startOf('day'),
							});
							if (this.selectedDateRange[1]) {
								filters.push({
									field: "creation_date",
									op: "<=",
									value: moment(this.selectedDateRange[1]).endOf('day'),
								});
							}
						} else {
							let pastDate = moment().startOf('day').subtract(this.dataRange, "d"); // TODO use reactive dat?
							filters.push({
								field: "creation_date",
								op: ">=",
								value: pastDate,
							});
						}
					}

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				pollInterval: timeInterval('5 minutes sucka'),
				skip() {
					return !this.project;
				},
			},
		},

		dateRangeOptions: [
			{
				value: 7,
				text: "Week",
			},
			{
				value: 30,
				text: "Month",
			},
			{
				value: 0,
				text: "All Time",
			},
			{
				value: "custom",
				text: "Custom",
			},
		],

		computed: {
			project() {
				return this.projectContext.project;
			},
			timeByEmployee() {
				if (!this.timers) {
					return [];
				}

				let data = Linq.from(this.timers.nodes);

				data = data
				.select(row => row.node)
				.groupBy(
					(id) => id.user.id,
					(id, timers) => {
						return {
							id: id,
							user: timers.first().user,
							total_timers: timers.sum(
								(row, total) => row.time_spent,
								0
							),
							timers: timers.toArray(),
						};
					}
				).orderByDescending((t) => t.total_timers)
				.toArray();

				return data;
			},
			timerNodes() {
				if(!this.timers) {
					return [];
				}

				return this.timers.nodes
					.map(row => row.node)
			}
		}
	};
</script>

<style lang="scss" scoped>
	::v-deep .v-data-table .v-row-group__header,
	::v-deep .v-data-table .v-row-group__summary {
		background-color: var(--v-background-base) !important;
	}
</style>