<template>
<svg
	v-if="ad"
    viewBox='0 0 264 280'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlns:xlink='http://www.w3.org/1999/xlink'>
    <desc>Created with getavataaars.com</desc>
    <defs>
      <circle id='path-1' cx='120' cy='120' r='120'></circle>
      <path
        d='M12,160 C12,226.27417 65.72583,280 132,280 C198.27417,280 252,226.27417 252,160 L264,160 L264,-1.42108547e-14 L-3.19744231e-14,-1.42108547e-14 L-3.19744231e-14,160 L12,160 Z'
        id='path-2'></path>
      <path
        d='M124,144.610951 L124,163 L128,163 L128,163 C167.764502,163 200,195.235498 200,235 L200,244 L0,244 L0,235 C-4.86974701e-15,195.235498 32.235498,163 72,163 L72,163 L76,163 L76,144.610951 C58.7626345,136.422372 46.3722246,119.687011 44.3051388,99.8812385 C38.4803105,99.0577866 34,94.0521096 34,88 L34,74 C34,68.0540074 38.3245733,63.1180731 44,62.1659169 L44,56 L44,56 C44,25.072054 69.072054,5.68137151e-15 100,0 L100,0 L100,0 C130.927946,-5.68137151e-15 156,25.072054 156,56 L156,62.1659169 C161.675427,63.1180731 166,68.0540074 166,74 L166,88 C166,94.0521096 161.51969,99.0577866 155.694861,99.8812385 C153.627775,119.687011 141.237365,136.422372 124,144.610951 Z'
        id='path-silhouette'></path>
    </defs>
    <g
      id='Avataaar'
      stroke='none'
      stroke-width='1'
      fill='none'
      fill-rule='evenodd'>
      <g
        transform='translate(-825.000000, -1100.000000)'
        id='Avataaar/Circle'>
        <g transform='translate(825.000000, 1100.000000)'>
		  <g
			id='Circle'
			stroke-width='1'
			fill-rule='evenodd'
			transform='translate(12.000000, 40.000000)'>
			<mask id='mask-1' fill='white'>
			<use xlink:href='#path-1'></use>
			</mask>
			<use
			id='Circle-Background'
			fill='transparent'
			xlink:href='#path-1'></use>
			<g
			id='Color/Palette/Blue-01'
			mask='url(#mask-1)'
			:fill="$vuetify.theme.currentTheme[realDepartment]">
			<rect id='🖍Color' x='0' y='0' width='240' height='240'></rect>
			</g>
		  </g>
		  <mask id='mask-2' fill='white'>
			<use xlink:href='#path-2' />
		  </mask>
          <g id='Mask' />
          <g
            id='Avataaar'
            stroke-width='1'
            fill-rule='evenodd'
            fill='black'
            mask='url(#mask-2)'>
            <g id='Body' transform='translate(32.000000, 36.000000)'>
              <mask id='mask-silhouette' fill='white'>
                <use xlink:href='#path-silhouette'></use>
              </mask>
              <use :fill="$options.dataTypes.skinColors[ad.binding.skinColor]" xlink:href='#path-silhouette'></use>
              <path
                d='M156,79 L156,102 C156,132.927946 130.927946,158 100,158 C69.072054,158 44,132.927946 44,102 L44,79 L44,94 C44,124.927946 69.072054,150 100,150 C130.927946,150 156,124.927946 156,94 L156,79 Z'
                id='Neck-Shadow'
                fill-opacity="0.100000001"
                fill='#000000'
                mask='url(#mask-silhouette)'></path>
            </g>
            <svg :style="ad.cssVars" v-html="$options.dataTypes.clothesType[ad.binding.clotheType]"></svg>
            <svg v-if="ad.binding.clotheType === 'GraphicShirt'" :style="ad.cssVars" v-html="$options.dataTypes.GraphicShirtTypes[ad.binding.graphicType]"></svg>
            <svg v-html="$options.dataTypes.eyeTypes[ad.binding.eyeType]"></svg>
            <svg v-html="$options.dataTypes.mouthTypes[ad.binding.mouthType]"></svg>
            <svg v-html="$options.dataTypes.eyebrowTypes[ad.binding.eyebrowType]"></svg>
            <svg>
                <g fill='black' transform='translate(76.000000, 82.000000)'>
                    <g
                      id='Nose/Default'
                      transform='translate(28.000000, 40.000000)'
                      opacity='0.16'>
                      <path
                        d='M16,8 C16,12.418278 21.372583,16 28,16 L28,16 C34.627417,16 40,12.418278 40,8'
                        id='Nose'>

                      </path>
                    </g>
                </g>
            </svg>
            <svg :style="ad.cssVars" v-html="$options.dataTypes.topTypes[ad.binding.topType]"></svg>
            <svg :style="ad.cssVars" v-html="$options.dataTypes.facialHairTypes[ad.binding.facialHairType]"></svg>
            <svg v-html="$options.dataTypes.accessoriesTypes[ad.binding.accessoriesType]"></svg>
          </g>
        </g>
      </g>
    </g>
</svg>
<v-progress-circular
	v-else
	indeterminate
	color="primary"
></v-progress-circular>
</template>

<script>
	import { getRand } from '../../utils/random';
	import * as dataTypes from './index';
	import colors from 'vuetify/src/util/colors.ts';
	import { IdType } from '../../utils/IdType';

	function getRandomKeyFromObject(rand, obj) {
		let keys = Object.keys(obj);
		return keys[Math.floor(rand() * keys.length)];
	}

	function getRandomValueFromObject(rand, obj) {
		return obj[getRandomKeyFromObject(rand, obj)];
	}

	function getRandomOptions(rand, value, type) {
		let result = {};

		if (dataTypes[`${type}Options`]) {
			let options = dataTypes[`${type}Options`][value] || dataTypes[`${type}Options`]._default;
			for (let i in options) {
				switch (options[i]) {
					case 'color':
						result[i] = getRandomValueFromObject(rand, getRandomValueFromObject(rand, colors));
						break;
					case 'graphic':
						// skip
						break;
					default:
						console.error(`Unknown option type: ${options[i]}`);
						break;
				}
			}
		}

		return result;
	}

	function getRandomType(rand, type) {
		let result = {
			value: getRandomKeyFromObject(rand, dataTypes[type])
		};

		Object.assign(result, getRandomOptions(rand, result.value, type));

		return result;
	}

	export function generateUserAvatar(user) {
		if (typeof user == 'object') {
			user = user.id;
		}

		let rand = getRand(user);

		let result = {
			topType: 			getRandomType(rand, 'topTypes'),
			accessoriesType: 	getRandomType(rand, 'accessoriesTypes'),
			facialHairType: 	getRandomType(rand, 'facialHairTypes'),
			clotheType: 		getRandomType(rand, 'clothesType'),
			eyeType: 			getRandomType(rand, 'eyeTypes'),
			eyebrowType: 		getRandomType(rand, 'eyebrowTypes'),
			mouthType: 			getRandomType(rand, 'mouthTypes'),
			skinColor: 			getRandomType(rand, 'skinColors')
		};

		result.clotheType.Graphic = getRandomKeyFromObject(rand, dataTypes.GraphicShirtTypes);

		return result;
	}

	export default {
		dataTypes,

		props: {
			uid: {
				type: IdType
			},

			user: {
				type: Object
			},

			department: {
				type: String
			},

			avatarData: {
				type: Object
			}
		},

		apollo: {
			apolloUser: {
				query: gql`
					query GetUserMetaAvatar ($uid: ID!) {
						apolloUser: node (uid: $uid) {
							...on Person {
								id
								meta_avatar
							}
							...on Employee {
								department
							}
						}
					}
				`,
				variables() {
					return {
						uid: this.uid
					};
				},
				skip() {
					return !this.uid || !!this.user;
				}
			}
		},

		computed: {
			curUser() {
				if (this.user) {
					return this.user;
				}
				else {
					if (!this.apolloUser) {
						return null;
					}

					return this.apolloUser;
				}
			},

			realDepartment() {
				if (this.department) {
					return this.department;
				}

				if (this.curUser && this.curUser.department) {
					return this.curUser.department.toLowerCase();
				}

				return 'black';
			},
			userAvatarData() {
				if (!this.curUser) {
					return null;
				}

				let avatarData = this.curUser.meta_avatar;
				try {
					avatarData = JSON.parse(this.curUser.meta_avatar);
				}
				catch (e) {}

				if (!avatarData) {
					try {
						avatarData = generateUserAvatar(this.curUser.id);
					}
					catch (e) {
						console.error(e);
						return null;
					}
				}

				return avatarData;
			},

			ad() {
				let data = this.avatarData;
				if (!this.avatarData) {
					data = this.userAvatarData;
				}

				if (!data) {
					return null;
				}

				let cssVars = {},
					binding = {};

				for (let i in data) {
					binding[i] = data[i].value;

					if (i == 'clotheType' && binding[i] == 'GraphicShirt') {
						binding.graphicType = data[i].Graphic;
					}

					// Grab all options
					for (let j in data[i]) {
						if (j == 'value' || j == 'Graphic') {
							continue;
						}

						let varName = (`--avataaar-${i}-${j}`).replace(/\s/g, '-').toLowerCase();
						cssVars[varName] = data[i][j];

						if (! /^#[0-9a-f]{6}$/i.test(cssVars[varName])) {
							cssVars[varName] = `'${cssVars[varName]}'`;
						}
					}
				}

				return {
					cssVars,
					binding
				};
			}
		}
	}
</script>