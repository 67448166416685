<template>
	<path class="edge" :id="`edge-${from.data.id}-${to.data.id}`" :class="`edge-${from.data.id}-${to.data.id}`" :d="path"></path>
</template>

<script>
	export class Point {
		constructor(x, y) {
			this.x = x || 0;
			this.y = y || this.x;
		}

		toString() {
			return `${this.x}, ${this.y}`;
		}
	}

	export default {
		props: {
			from: {
				type: Object,
				required: true
			},
			to: {
				type: Object,
				required: true
			},
			pathFn: {
				type: Function,
				default: function (start, end) {
					if (end.x < start.x) {
						// swap start and end
						let tmp = start;
						start = end;
						end = tmp;
					}
					
					const distY = start.y - end.y;
					const c1 = new Point(start.x, start.y - distY / 2);
					const c2 = new Point(end.x, end.y + distY / 2);

					return `M ${start}  C ${c1} ${c2}  ${end}`;
				}
			}
		},

		computed: {
			path() {
				const start = new Point(this.from.x + this.from.width * 0.5, this.from.y + this.from.height);
				const end = new Point(this.to.x + this.to.width * 0.5, this.to.y);

				return this.pathFn(start, end);
			}
		}
	}
</script>

<style lang="scss" scoped>
	path {
		fill: transparent;
	}
</style>