<template>
	<v-card>
		<v-card-title>Project Overview</v-card-title>
		<v-card-subtitle>Current tasks by department</v-card-subtitle>
		<v-card-text>
			<div v-if="taskPercentages" class="progress">
				<template v-for="item in taskPercentages">
					<v-tooltip top :key="item.department" :color="item.department | lowercase">
						<template v-slot:activator="{ on, attrs }">
							<v-sheet dark class="d-flex align-center pl-1 text-truncate"
									 :style="`background:var(--v-${item.department.toLowerCase()}-base); width:${item.percent}%;`"
									 role="progressbar"
									 v-bind="attrs" v-on="on"
									 >
								{{item.department}} ({{item.total}})
							</v-sheet>
						</template>
						<span>{{item.department}} ({{item.total}})</span>
					</v-tooltip>
				</template>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	export default {
		name: "projectProgressBar",
		data() {
			return {};
		},
		apollo: {
			departmentTaskCounts: {
				query: gql`
					query getDepartmentTaskCounts($filterParam: QueryFilter!) {
						departmentTaskCounts: tasks
							@filter(param: $filterParam)
							@groupBy(fields: ["assigned_to.department"]) {
							sql
							nodes {
								group_total
								node {
									assigned_to {
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "=",
									value: "current",
								},
								{
									field: "assigned_to.department",
									op: "!=",
									value: null,
								},
								{
									field: "project.status",
									op: "!=",
									value: "closed",
								},
							],
						},
					};
				},
			},
		},
		computed: {
			taskPercentages() {
				if (!this.departmentTaskCounts) {
					return [];
				}

				let total = Linq.from(this.departmentTaskCounts.nodes).sum(
					(r) => r.group_total
				);

				return Linq.from(this.departmentTaskCounts.nodes)
					.select((r) => ({
						department: r.node.assigned_to.department,
						total: r.group_total,
						percent: Math.round((r.group_total / total) * 10000) / 100,
					}))
					.toArray();
			},
		},
	};
</script>

<style scoped lang="scss">
	.progress {
		display: flex;
		> div {
			font-size: 10px;
			height: 30px;
		}
	}
</style>