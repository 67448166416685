<template>
	<div class="quick-jump d-flex ml-auto align-end">

		<project-client-picker
							   v-model="quickJumpModel"
							   class="ml-2 mr-1 quick-jump-select body-2"
							   :placeholder="$vuetify.breakpoint.smAndDown ? 'Quick Jump' : 'Quick Jump to Client/Project'"
							   hide-details dense :statuses="statuses">
			<template v-slot:item="{item}">
				<span class="mr-1">{{item.name}}</span><v-chip small v-if="item.status != 'active' && item.__typename == 'Project'" class="ml-auto">{{item.status}}</v-chip>
			</template>
			<template v-slot:selection="{item}">
				<span>{{item.name}}</span>
			</template>
		</project-client-picker>

		<v-checkbox hide-details v-model="showClientsInQuickJumpList" :label="$vuetify.breakpoint.smAndDown ? '' : 'All Clients/Projects'" dense></v-checkbox>
	</div>
</template>

<script>
	import ProjectClientPicker from "../fields/projectClientPicker.vue";

	export default {
		name: "quickJump",

		components: { ProjectClientPicker },

		data() {
			return {
				quickJumpModel: ""
			};
		},

		computed: {
			statuses() {
				let allClients = this.showClientsInQuickJumpList;

				if (allClients) {
					return ["active", "hidden", "closed"];
				} else {
					return ["active", "hidden"];
				}
			},
			showClientsInQuickJumpList: {
				get() {
					return this.getUserSetting(`showClientsInQuickJumpList`, false);
				},
				set(newVal) {
					this.setUserSetting("showClientsInQuickJumpList", newVal);
				},
			},
		},

		watch: {
			quickJumpModel(to) {
				if (!to) {
					return;
				}

				const [type, id] = to.split("_");

				let route;
				if (type == "client") {
					route = { name: "client.details", params: { id } };
				} else if (type == "project") {
					route = {
						name: "project.details",
						params: { id }
					};
				}

				if (this.$store.state.app.ctrlKeyHeld) {
					let { href } = this.$router.resolve(route);
					window.open(`${location.origin}/${href}`);
				} else {
					this.$router.push(route);
				}

				this.$nextTick(() => {
					this.quickJumpModel = null;
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .v-input .v-label {
		font-size: 12px;
	}
</style>