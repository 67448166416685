var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.$apollo.queries.users.loading}},[_c('v-card-title',[_vm._v("Changelog")]),_c('v-card-subtitle',[_vm._v("Features & Bug Fixes")]),_c('v-card-text',[_c('p',[_vm._v("Not all commits are shown. Trust us. You don't care about everything we do.")]),_c('v-data-table',{attrs:{"headers":_vm.$options.headers,"items":_vm.data,"disable-pagination":"","hide-default-footer":"","group-by":"date","group-desc":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{class:'icon-' + item.type,attrs:{"title":item.type}},[_vm._v(_vm._s(_vm.getIcon(item.type)))])]}},{key:"item.hash",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://git.digitalattic.com/phalcon/chuck-it/commit/" + (item.hash)),"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fab fa-gitlab")])],1)]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.time)+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('object-link',{attrs:{"uid":item.user.uid,"title":item.user.full_name}},[_c('user-avataaar',{staticClass:"avatar mr-2",staticStyle:{"max-width":"50px"},attrs:{"uid":item.user.uid}})],1):_c('span',[_vm._v("Unknown")])]}}])}),_c('p',{staticClass:"body-2 mt-5"},[_vm._v("We devs, do solemnly swear, to create better commit messages from here on out. ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }