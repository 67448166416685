<template>
	<!-- TODO: Add item slots -->
	<v-autocomplete
		v-if="items"
		:value="internalValue"
		@input="onChange"
		:items="items"
		item-text="name"
		item-value="id"
		label="Selected Task Group"
		:disabled="disabled"
		dense
		outlined
		hide-details
	>
		<template v-slot:selection="data">
			<v-icon left small>{{$insight.helpers.getTaskGroupIcon(data.item.type)}}</v-icon>
			{{data.item.name}} <span v-if="data.item.is_default" class="ml-2">*</span>
			<em v-if="data.item.is_archived" class="ml-2 text--disabled">(archived)</em>
		</template>
		<template v-slot:item="data">
			<v-list-item-avatar>
				<v-icon>{{$insight.helpers.getTaskGroupIcon(data.item.type)}}</v-icon>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title>{{data.item.name}}<em v-if="data.item.is_default"> - default</em> <em v-if="data.item.is_archived" class="ml-1 text--disabled">(archived)</em></v-list-item-title>
				<v-list-item-subtitle>{{data.item.start_date | shortDate}}</v-list-item-subtitle>
			</v-list-item-content>
		</template>
	</v-autocomplete>
	<v-text-field v-else placeholder="Loading..." :disabled="true"></v-text-field>
</template>

<script>
	import { IdType } from '../../utils/IdType';

	export default {
		model: {
			prop: 'value',
			event: 'input'
		},

		props: {
			projectId: {
				type: IdType,
				required: true
			},
			value: {
				type: IdType,
				required: true
			},
			disabled: {
				type: Boolean,
				default: false
			},
			showArchived: {
				type: Boolean,
				default: false
			}
		},

		data() {
			return {
				internalValue: this.value
			};
		},

		apollo: {
			taskGroups: {
				query: gql`
					query taskTreeTaskGroups($projectId: ID! $showArchived: Boolean) {
						project (id: $projectId) {
							id
							task_groups(show_archived: $showArchived) {
								id
								name
								is_default
								is_archived
								type
							}
						}
					}
				`,
				variables() {
					return {
						projectId: this.projectId,
						showArchived: this.showArchived
					};
				},
				update(serverData) {
					return serverData.project.task_groups;
				}
			},
		},

		computed: {
			items() {
				return this.taskGroups || [];
			}
		},

		methods: {
			onChange(val) {
				this.$emit('input', val);
			}
		},

		watch: {
			value(to) {
				this.internalValue = to;
			},
			taskGroups(to) {
				if (to.every(group => group.id != this.value)) {
					let defaultGroup = to.find(group => group.is_default);
					if (defaultGroup) {
						this.onChange(defaultGroup.id);
					}
				}
			}
		}
	}
</script>