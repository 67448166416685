<template>
	<v-dialog :value="true" min-width="300" max-width="1100" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>Edit Avatar</v-card-title>
			<v-card-text v-if="avatar">
				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>
				<avataaar-builder :user="person" v-model="avatar"></avataaar-builder>
			</v-card-text>

			<v-card-actions>
				<div class="ml-auto">
					<v-btn @click="modal.trigger('close')" :disabled="saving" class="mr-3">Close</v-btn>
					<v-btn color="primary" @click="submit" :loading="saving">Update</v-btn>
				</div>
			</v-card-actions>
		</v-card>

	</v-dialog>
</template>

<script>
	import { IdType } from "../../utils/IdType";
	import AvataaarBuilder from "../avataaars/avataaar-builder.vue";

	export default {
		name: "AvatarEdit",
		components: { AvataaarBuilder },
		props: {
			modal: {
				type: Object,
				required: true,
			},
			personId: {
				type: IdType,
				default: 0,
			},
		},

		data() {
			return {
				modalErrorMessage: false,
				avatar: null,
				saving: false,
			};
		},

		apollo: {
			person: {
				query: gql`
					query GetSinglePerson($personId: ID!) {
						person(id: $personId) {
							... on Employee {
								id
								meta_avatar
								department
							}
						}
					}
				`,
				variables() {
					return {
						personId: this.personId,
					};
				},
			},
		},

		watch: {
			person(to) {
				// meta_avatar is object of objects. make a deep clone so it doesn't affect the avatar in the profile until saved
				this.avatar = JSON.parse(JSON.stringify(to.meta_avatar));
			},
		},

		methods: {
			submit() {
				this.saving = true;

				this.$apollo
					.mutate({
						mutation: gql`
							mutation ($data: UpdateEmployeeArgs!) {
								updateEmployee(data: $data) {
									id
									meta_avatar
								}
							}
						`,
						variables: {
							data: {
								id: this.personId,
								meta_avatar: this.avatar,
							},
						},
						update: (store, { data: { updateEmployee } }) => {
							this.saving = false;
							this.modal.trigger("save close"); // or this.modal.trigger("save close", person);
						},
					})
					.catch((error) => {
						console.error("error updating avatar", error);
						this.modalErrorMessage =
							"There was a problem updating the data.";
					});
			},
		},
	};
</script>