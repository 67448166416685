export default {
	computed: {
		projectStats() {
			if (this.project && this.project.hours_allocated > 0) {
				let projectTime = Math.round(this.$insight.projects.totalTime(this.project, true) / 36) / 100;

				return {
					totalTime: projectTime,
					allocated: this.project.hours_allocated
				};
			}

			return false;
		},

		progressBarStyles() {
			if (!this.projectStats) {
				return false;
			}

			let percent =
				this.projectStats.totalTime / this.projectStats.allocated * 100;

			return {
				width: percent + "%",
				backgroundColor: this.$insight.helpers.interpolateColorGreenToRed(
					percent
				).style
			};
		}
	},

	methods: {
		addNote() {
			this.$modalService.create('noteAddEdit', {
				objType: 'task',
				objId: this.task.id,
				noteType: 'task',
				// showType:false
			});
		}
	}
};
