/*  AsyncComputed - By Daniel Flynn

	The hoops I jump through because Vue does not expose any handles to their dependency tracking system...
    I sincerly appologize for the illegibility of the following code.
	
	Usage: (in component)
	export default {
		{...}
		asyncComputed: {
			asyncComp1() {	// function
				return new Promise((resolve, reject) => {
					// if success
					resolve({...});
					// if failure
					reject({...});
				});
			},

			asyncComp2: {	// object
				lazy: true,	// optional - Computes only the first time it's used and when dependencies change
				preventDuplicates: true,	// optional - only updates if the value is different
				get() {		// 'get' is required in object syntax
					return new Promise({...});
				},
				watch() {	// optional
					// Extra dependencies for the computation
					// Simply use the variables and they will be tracked
				},
				error(...) {// optional
					// Called with whatever was passed to the 'reject' function in 'get'
				}
			},

			asyncComp3() {
				return "Returning a promise is not required... but why are we here if you aren't going to use them?"
			}
		}
		{...}
	}
*/

import Vue from 'vue';

const suffix = '$as_comp',
	suffixMeta = '$as_comp_meta',
	suffixLazy = '$as_comp_lazy';

const AsyncComputed = {
	install (Vue, pluginOptions) {
		pluginOptions = pluginOptions || {};

		Vue.config
			.optionMergeStrategies
			.asyncComputed = Vue.config.optionMergeStrategies.computed;

		Vue.mixin({
			beforeCreate() {
				this.$options.computed = this.$options.computed || {};

				for (const key in this.$options.asyncComputed || {}) {
					this.$options.computed[key + suffix] = AsyncComputed.makeComputedFunction(key, this.$options.asyncComputed[key]);
				}

				let origData = this.$options.data;

				this.$options.data = function() {
					let data = (typeof origData === 'function') ? origData.call(this) : origData;
					data = data || {};

					for (const key in this.$options.asyncComputed || {}) {
						let item = this.$options.asyncComputed[key];

						data[key + suffixMeta] = {
							status: 'idle',
							error: false,
							refresher: false
						};

						if (item.lazy) {
							data[key + suffixMeta].active = false;
							data[key + suffixLazy] = null;

							this.$options.computed[key] = AsyncComputed.makeLazyComputed(key);
						}
						else {
							data[key] = null;
						}
					}

					return data;
				};

				/* for (const key in this.$options.asyncComputed || {}) {
					let item = this.$options.asyncComputed[key],
						itemDef = {
							status: 'idle',
							error: false,
							refresher: false
						};

					if (item.lazy) {
						itemDef.active = false;
						Vue.util.defineReactive(this, key + suffixLazy, null);

						this.$options.computed[key] = AsyncComputed.makeLazyComputed(key);
					}
					else {
						Vue.util.defineReactive(this, key, null);
					}

					Vue.util.defineReactive(this, key + suffixMeta, {
						status: 'idle',
						error: false,
						refresher: false
					});
				} */
			},

			created() {
				for (const key in this.$options.asyncComputed || {}) {
                    let promiseId = 0,
                        lastValStr = null;

					this.$watch(key + suffix, promise => {
						const thisPromise = ++promiseId;

						if (!(promise instanceof Promise)) {
							promise = Promise.resolve(promise);
						}

						promise.then(value => {
							if (thisPromise !== promiseId) {
                                return;
                            }

							if (this[key + suffixMeta].active !== false) {
								this[key + suffixMeta].status = 'resolved';
                            }
                            this[key + suffixMeta].error = false;
                            
                            let currentValStr = false;
                            if (this.$options.asyncComputed[key].preventDuplicates) {
                                currentValStr = JSON.stringify(value);
                                if (lastValStr === currentValStr) {
                                    return;
                                }
                            }

                            this[key] = value;
                            if (this.$options.asyncComputed[key].preventDuplicates) {
                                lastValStr = currentValStr;
                            }
						}).catch(err => {
							if (thisPromise !== promiseId)
								return;

							this[key + suffixMeta].status = 'rejected';

							if (this.$options.asyncComputed[key].error) {
								this[key + suffixMeta].error = this.$options.asyncComputed[key].error.call(this, err) || err;
							}
							else {
								this[key + suffixMeta].error = err;
							}
						});
					}, {
						immediate: true
					});
				}
			},

			destroyed() {
				for (const key in this.$options.asyncComputed || {}) {
					if (this[key + suffix] instanceof Promise) {
						this[key + suffix].catch(e => e);
					}
				}
			},

			methods: {
				computedStatus(key) {
					return this[key + suffixMeta].status;
				},

				computedError(key) {
					return this[key + suffixMeta].error;
				},

				computedRefresh(key) {
					this[key + suffixMeta].refresher = !this[key + suffixMeta].refresher;
				},

				//computedIsResolved
				//computedIsRejected
				//computedIsLoading
				...['resolved', 'rejected', 'loading'].reduce((acc, cur) => {
					acc['computedIs' + cur[0].toUpperCase() + cur.substr(1)] = function (key) {
						return this[key + suffixMeta].status == cur;
					};

					return acc;
				}, {})
			}
		});
	},

	makeComputedFunction(key, opt) {
		if (typeof opt === 'function') {
			opt = {
				get: opt
			};
		}

		let getter = function() {
            this[key + suffixMeta].status = 'loading';
			return this[key + suffixMeta].refresher, opt.get.call(this);
		};

		if (opt.watch) {
			getter = function() {
				this[key + suffixMeta].status = 'loading';
				opt.watch.call(this);
				return opt.get.call(this);
			};
		}

		if (opt.lazy) {
			let nonLazy = getter;

			getter = function() {
				if (this[key + suffixMeta].active) {
					return nonLazy.call(this);
				}
				else {
					return this[key + suffixLazy];
				}
			};
        }
        
        return getter;
	},

	makeLazyComputed(key) {
		return {
			get() {
				this[key + suffixMeta].active = true;
				return this[key + suffixLazy];
			},

			set(value) {
				this[key + suffixLazy] = value;
			}
		};
	}
};

export default AsyncComputed;