<template>
	<v-container fluid class="align-start fill-height pb-0">

		<v-row class="fill-height">
			<v-col cols="12" class="fill-height d-flex flex-column pb-0">

				<v-progress-linear v-if="$apollo.queries.apollotasks.loading" :indeterminate="$apollo.queries.apollotasks.loading"></v-progress-linear>

				<!-- Filters -->
				<v-card dense class="d-flex align-center justify-center flex-column flex-sm-row mb-4">
					<div class="d-flex align-center mb-1 mb-sm-0">
						<v-select v-model="department" :items="$insight.users.departments()" placeholder="Pick Department"></v-select>
						<v-btn-toggle v-model="visibleTaskStatuses" dense multiple class="mx-5 my-3 my-sm-0">
							<v-btn small value="current">
								Current
							</v-btn>
							<v-btn small value="pending">
								Pending
							</v-btn>
						</v-btn-toggle>
					</div>

					<v-btn-toggle v-model="taskFilters" dense multiple dark>
						<v-btn dark small v-for="item in taskLegendItems" :key="item.value" :color="item.value" :value="item.value">
							{{ item.name }}
						</v-btn>
					</v-btn-toggle>
				</v-card>

				<template v-if="!$apollo.queries.apollotasks.loading">

					<!-- Mobile Only -->
					<v-tabs v-if="$vuetify.breakpoint.smAndDown && userTasks" v-model="tab" show-arrows>
						<v-tab v-for="item in userTasks" :key="item.user.id">
							{{item.user.full_name}} 
							(<span v-html="item.current.length + item.pending.length"></span>)
						</v-tab>
						<v-tabs-items v-model="tab">
							<v-tab-item v-for="(item, k) in userTasks" :key="k">
								<v-card flat>
									<v-card-text>
										<div v-if="visibleTaskStatuses.includes('current')">
											<v-card-subtitle color="primary">Current (<span class="current-count" v-html="item.current.length"></span>)</v-card-subtitle>
											<task-card v-for="task in item.current" :key="task.id" :task="task"></task-card>
										</div>

										<template v-if="visibleTaskStatuses.includes('pending')">
											<v-divider></v-divider>
											<v-card-subtitle>Pending (<span class="pending-count" v-html="item.pending.length"></span>)</v-card-subtitle>
											<task-card v-for="task in item.pending" :key="task.id" :task="task"></task-card>
										</template>
									</v-card-text>
								</v-card>
							</v-tab-item>
						</v-tabs-items>
					</v-tabs>

					<!-- Desktop -->
					<div v-if="$vuetify.breakpoint.mdAndUp && userTasks" class="overflow-y-auto flex-grow-1">
						<v-row v-if="userTasks" no-gutters class="flex-nowrap fill-height">
							<v-col cols="12" sm="4" md="4" lg="4" xl="2" v-for="(person, k) in userTasks" :key="k" class="d-flex flex-column fill-height pb-4 px-2">
								<v-card dense class="fill-height d-flex flex-column">

									<v-card-title class="pt-1 pb-2 body-1 px-2">
										<user-avataaar class="avatar mr-2" :user="person.user" style="max-width:50px;"></user-avataaar>
										{{person.user.full_name}}
									</v-card-title>

									<div class="tasks-container flex-grow-1">
										<div class="inner background">

											<template v-if="visibleTaskStatuses.includes('current')">
												<v-card-subtitle color="primary">Current (<span class="current-count" v-html="person.current.length"></span>)</v-card-subtitle>
												<template v-if="person.current.length">
													<task-card v-for="task in person.current" :key="task.id" :task="task" class="mb-3"></task-card>
												</template>
											</template>

											<template v-if="visibleTaskStatuses.includes('pending')">
												<v-card-subtitle color="primary">Pending (<span class="current-count" v-html="person.pending.length"></span>)</v-card-subtitle>
												<template v-if="person.pending.length">
													<task-card v-for="task in person.pending" :key="task.id" :task="task" class="mb-3"></task-card>
												</template>
											</template>

										</div>
									</div>

								</v-card>
							</v-col>
						</v-row>

						<div v-if="!userTasks" class="d-flex justify-center align-center fill-height">
							<div v-if="department" class="text-center white--text">
								<p>Loading {{ department }} Tasks</p>
								<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
							</div>
						</div>
					</div>

				</template>

			</v-col>
		</v-row>

	</v-container>
</template>


<script>
	
	import TaskCard from "../components/taskCards/taskCardMini";
	import UserAvataaar from "../components/avataaars/user-avataaar";
	//TODO: Re-add this when caching is enabled
	// import { ActiveEmployees } from '@/graphql/queries';

	import moment from "moment";

	export default {
		name: "taskCards",

		components: {
			TaskCard,
			UserAvataaar,
		},

		apollo: {
			activeEmployees: {
				query: gql`
					query GetPersons ($filterParam: QueryFilter!)  {
						activeEmployees: persons 
						@filter(param: $filterParam) 
						{
							nodes {
								node {
									id
									full_name
									meta_avatar
								}
							}
						}
					}
				`,
				variables() {

					let filters = [
						{
							field: "is_employee",
							op: "=",
							value: true
						},
						{
							field: "inactive",
							op: "=",
							value: false
						},
						{
							field: "department",
							op: "=",
							value: this.department
						},
					];


					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				}
			},
			apollotasks: {
				query: gql`
					query getTasks($filterParam: QueryFilter!) {
						apollotasks: tasks
							@filter(param: $filterParam)
							@sort(
								param: [{ field: "timeline_due_date", order: asc }]
							) {
							nodes {
								node {
									id
									uid
									name
									description
									timeline_due_date
									estimated_completion_date
									status
									due_status
									assigned_to {
										id
										full_name
										department @lowercase
										meta_avatar
									}
									project {
										id
										uid
										name
										client {
											id
											uid
											name
										}
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "assigned_to.is_employee",
							op: "=",
							value: true
						},
						{
							field: "assigned_to.inactive",
							op: "=",
							value: false
						},
						{
							field: "project.status",
							op: "!=",
							value: "closed",
						},
						{
							field: "complete_date",
							op: "=",
							value: null,
						},
						{
							field: "status",
							op: "in",
							value: this.visibleTaskStatuses,
						},
						{
							field: "assigned_to.department",
							op: "=",
							value: this.department,
						},
						{
							field: "due_status",
							op: "in",
							value: this.taskFilters,
						}
					];

					return {
						filterParam: {
							joinOp: "and",
							filters,
						}
					};
				},
			},
		},

		data() {
			return {
				search: "",
				department: this.$route.params.department || "",
				visibleTaskStatuses: ["current"],
				taskFilters: ["overdue", "today", "soon", "later"],
				tab: null,
			};
		},

		mounted() {
			$("html").addClass("height100");
		},

		destroyed() {
			$("html").removeClass("height100");

			this.releaseData();

			// if (this.drake) {
			// 	this.drake.destroy();
			// }
		},

		asyncComputed: {
			/* departmentTasksLoaded() {
				return new Promise((resolve, reject) => {
					if (this.$store.getters.isNotLoggedIn || !this.department) {
						resolve(false);
						return;
					}

					this.$xhrRequest
						.send("get", `/api/task/department/${this.department}`, {
							fields: "*,ProjectHoursAllocated,current_timer,confirmed_time",
						})
						.then(
							(tasks) => {
								this.releaseData();
								this.$db.addModels(
									"task",
									tasks,
									"department-tasks"
								);
								resolve(true);
							},
							() => {
								console.error("Error fetching department tasks");
								reject(false);
							}
						);
				});
			}, */
		},

		computed: {
			userTasks() {
				if (!this.apollotasks || !this.apollotasks.nodes || !this.activeEmployees) {
					return [];
				}

				let data = Linq.from(this.apollotasks.nodes)
					.select(row => row.node)
					.groupBy(
						(user) => user.assigned_to.id,
						(_, items) => ({
							user: items.first().assigned_to,
							current: items
								.where((i) => i.status == "current")
								.toArray(),
							pending: items
								.where((i) => i.status == "pending")
								.toArray(),
						})
					)
					.toArray();

				if (data.length != this.activeEmployees.length) {
					let missingUsers = this.activeEmployees.nodes
						.map(row => row.node)
						.filter(u => !data.find(e => e.user.id == u.id))
						.map(u => ({
							user: u,
							current: [],
							pending: []
						}));

					data.push(...missingUsers);
				}

				data = Linq.from(data)
					.orderBy((p) => p.user.full_name)
					.toArray();

				return data;
			},

			taskLegendItems() {
				return [
					{ name: "Overdue", value: "overdue" },
					{ name: "Today", value: "today" },
					{ name: "Soon", value: "soon" },
					{ name: "Later", value: "later" },
				];
			},
		},

		methods: {
			releaseData() {
				this.$db.removeHandle("department-tasks");
			},
		},

		watch: {
			"$route.params.department"(to) {
				this.department = to;
			},
			department(to) {
				if (this.$route.params.department != to) {
					this.$router.push({
						name: "tasks.cards",
						params: { department: to },
					});
					return;
				}
			},
		},
	};
</script>


<style lang="scss" scoped>
	.almost-fill-height {
		height: calc(100% - 88px);
	}
	.tasks-container {
		max-height: 100%;
		position: relative;
		.inner {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow-y: auto;
		}
	}
	// .task-overflow {
	// 	@media (min-width: 600px) {
	// 		max-height: 400px;
	// 		overflow-y: auto;
	// 	}
	// 	@media (min-width: 1904px) {
	// 		max-height: unset;
	// 	}
	// }
</style>