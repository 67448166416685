<template>
	<v-footer padless app>
		<!-- <pre v-if="computedError('rawActivityItems')">{{computedError('rawActivityItems')}}</pre> -->
		<v-col class="py-0 d-flex align-center" cols="12" sm="6">
			<who-is-out @users="setWhoIsOut" />
			<announcements class="ml-3" :divider="haveUsersOut" />
		</v-col>
		<v-col class="py-0 text-sm-right" cols="12" sm="6">

			<v-btn text small class="mr-2" @click.stop="activityOpen = !activityOpen">
				Activity
			</v-btn>

			<v-navigation-drawer v-model="activityOpen" bottom app right width="200" max-width="200" hide-overlay stateless>

				<v-list-item class="text-left">
					<v-list-item-content>
						<v-list-item-title class="title">
							Activity
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-toolbar class="d-flex justify-center">
				<v-btn-toggle v-model="showActivity">
					<v-btn small value="me">Me</v-btn>
					<v-btn small value="all">All</v-btn>
				</v-btn-toggle>
				</v-toolbar>

				<activity v-if="showActivity == 'all'" :max="10" class="mt-4"></activity>

				<ul v-if="showActivity == 'me' && computedIsResolved('rawActivityItems')" class="my-activity text-left list-style-none caption">
					<li v-for="(item, ndx) in combinedData" :key="ndx">
						<v-sheet :color="item.color">
							<v-icon x-small class="mr-2">{{item.icon}}</v-icon>
							{{item.title}}
							<div v-if="item.content" class="mt-1"><em>&ldquo;{{item.content}}&rdquo;</em></div>
						</v-sheet>
					</li>
				</ul>
				<p v-if="combinedData.length == 0" class="px-2 text-left mt-3">You have no activity in the last 24 hours</p>
			</v-navigation-drawer>

            <v-btn small text @click="$router.push({name:'exports'})" v-if="$store.getters.hasPermission('export_timers')" >
                Timers <v-icon small class="ml-2">fad fa-file-export</v-icon>
            </v-btn>

			<v-menu top offset-y open-on-hover>
				<template v-slot:activator="{ on, attrs }">
					<v-btn small text v-bind="attrs" v-on="on">
						Analytics <v-icon small class="ml-2">fad fa-analytics</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item :to="{ name: 'activity' }">
						<v-list-item-icon>
							<v-icon small>fad fa-analytics</v-icon>
						</v-list-item-icon>
						<v-list-item-title>All Activity</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{ name: 'analytics.dashboard' }">
						<v-list-item-icon>
							<v-icon small>fad fa-analytics</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Analytics Dashboard</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{ name: 'analytics.tasks' }">
						<v-list-item-icon>
							<v-icon small>fad fa-analytics</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Tasks Analytics</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{ name: 'analytics.projects' }">
						<v-list-item-icon>
							<v-icon small>fad fa-analytics</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Project Analytics</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{ name: 'analytics.comms' }">
						<v-list-item-icon>
							<v-icon small>fad fa-comments-alt</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Comms Analytics</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{ name: 'analytics.staff' }">
						<v-list-item-icon>
							<v-icon small>fad fa-diagnoses</v-icon>
						</v-list-item-icon>
						<v-list-item-title>Staff Analytics</v-list-item-title>
					</v-list-item>
                    <v-list-item :to="{ name: 'payroll' }" v-if="$store.getters.isAdminUser" >
                        <v-list-item-icon>
                            <v-icon small>fal fa-file-invoice-dollar</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Payroll</v-list-item-title>
                    </v-list-item>
				</v-list>
			</v-menu>
		</v-col>
		
		<!-- <pre>{{this.$vuetify.theme.currentTheme}}</pre> -->
	</v-footer>
</template>


<script>
	import announcements from "./announcements.vue";
	import whoIsOut from "./whoIsOut.vue";
	import activity from "../views/activity";

	export default {
		name: "globalFooter",

		components: {
			announcements,
			whoIsOut,
			activity,
		},

		data() {
			return {
				intervalId: 0,
				activityOpen: false,
				showActivity: "me",
				haveUsersOut: false
			};
		},

		created() {
			this.$watch(() => this.getUserSetting('_theme'), (to) => {
				// if our values differ, re-fetch the user object
				if (to != this.$store.getters.userData.theme) {
					const api = `/api/person/${this.$store.getters.userId}`;
					this.$xhrRequest.send('get', api).then(
						(person) => {
							this.$db.addModels('person', person);
						},
						(xhr) => {
							let errors = this.$insight.helpers.getRequestErrors(xhr);
							errors = this.$insight.helpers.flattenRequestErrors(errors);
							console.error(errors);
						}
					);
				}
			});
		},

		computed: {
			/* theme: {
				get() {
					return this.$store.getters.userData.theme == 'dark';
				},
				set(val) {
					const theme = val
						? 'dark'
						: 'default';

					this.$store.dispatch('setTheme', theme);
				}
			}, */
			combinedData() {
				let result = [];

				if (this.rawActivityItems) {
					this.rawActivityItems.taskNotes
						.map((n) => {
							let author = this.$db.getModel(
									"person",
									n.note.author_id
								),
								referencedModel = this.$db.getModel(
									"project",
									n.project_id
								);

							return {
								date: n.note.creation_date,
								// color: n.note.note_type == "task_completed" ? "completed" : "grey darken-4",
								color: "button",
								icon: "fad fa-envelope",
								title: `"${author.first_name} sent you an email regarding the project "${referencedModel.name}"`,
								content: this.truncateText(n.note.content),
								route: {
									name: `project.details`,
									params: { id: n.note.object_id },
								},
							};
						})
						.forEach((i) => result.push(i));

					this.rawActivityItems.generalNotes
						.map((n) => {
							let author = this.$db.getModel("person", n.author_id),
								referencedModelType = n.object_type.toLowerCase(),
								referencedModel = this.$db.getModel(
									referencedModelType,
									n.object_id
								);

							return {
								date: n.creation_date,
								color: "button",
								icon: "fad fa-envelope",
								title: `"${author.first_name} sent you an email regarding the ${referencedModelType} "${referencedModel.name}"`,
								content: this.truncateText(n.content),
								route: {
									name: `${referencedModelType}.details`,
									params: { id: n.object_id },
								},
							};
						})
						.forEach((i) => result.push(i));

					this.rawActivityItems.myProjects
						.map((t) => {
							let completer = this.$db.getModel(
									"person",
									t.assigned_to_user_id
								),
								referencedProject = this.$db.getModel(
									"project",
									t.project_id
								);

							return {
								date: t.complete_date,
								color: "completed",
								icon: "fad fa-check",
								title: `${completer.first_name} completed the task "${t.name}" for project "${referencedProject.name}"`,
								route: {
									name: "project.details",
									params: { id: referencedProject.id },
								},
							};
						})
						.forEach((i) => result.push(i));

					result.sort((a, b) => {
						return b.date.localeCompare(a.date);
					});
				}

				return result;
			},
		},
		asyncComputed: {
			rawActivityItems() {
				return new Promise((resolve, reject) => {
					if (this.$store.getters.isNotLoggedIn || !this.activityOpen) {
						reject();
						return;
					}

					let userid = this.$store.getters.userId;

					return this.$xhrRequest
						.send("get", `/api/activity/${userid}`)
						.then(
							(data) => {
								resolve(data);
							},
							(xhr) => {
								reject("There was an error downloading the notes");
							}
						);
				});
			},
		},
		methods: {
			truncateText(text) {
				if (text.length > 40) {
					return text.slice(0, 40) + "...";
				}
				return text;
			},

			setWhoIsOut(users) {
				this.haveUsersOut = !!users.length;
			}
		},
		watch: {
			activityOpen(to) {
				if (to) {
					this.intervalId = setInterval(() => {
						this.computedRefresh("rawActivityItems");
					}, 2 * 60 * 1000);
				} else {
					clearInterval(this.intervalId);
				}
			},

			/* theme: {
				immediate: true,
				handler(to) {
					this.$vuetify.theme.dark = to;
				}
			} */
		},
	};
</script>

<style lang="scss" scoped>
	.my-activity {
		list-style: none;
		padding: 0;
		.v-sheet {
			padding: 8px 8px;
			margin-bottom: 4px;
		}
	}
	// ::v-deep .v-navigation-drawer--bottom {
	// 	min-width: unset;
	// }

	::v-deep .activity-list {
		padding-top: 0;
		font-size: 11px;
		.hide-on-panel {
			display: none!important;
		}
		.activity-log-item {
			display: flex;
			flex-direction: column;
			> div {
				padding: 0;
				text-align: left !important;
			}
		}
		.activity-log-item {
			padding: 5px 5px;
		}
		.btn,
		.loading-row {
			display: none;
		}
		.department-indicator {
			top: 2px;
			position: relative;
		}
	}
</style>
