import { topTypes as OriginalTopTypes } from 'vuejs-avataaars/src/assetsTypes/top'
import { standardReplacements } from './utils'

OriginalTopTypes.Eyepatch = OriginalTopTypes.Eyepatch.replace('#28354B', 'var(--avataaar-hat-color)');

standardReplacements(OriginalTopTypes, 'toptype');

export const topTypes = OriginalTopTypes;
export const topTypesOptions = {
	_default: {
		Color: 'color'
	},

	NoHair: {}
}