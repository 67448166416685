/* SH-30 'Start-Up' hosting plan and get 30 minutes of free maintenance a month.  EH-60 is the 'Enhanced' plan, with 60 minutes of maintenance

and AH-120 is 'Advanced' plan with 2 hrs. free maintenance.  */

<template>
	<v-container fluid>
		<v-card :loading="$apollo.queries.projects.loading">
			<v-card-title>
				Retainers
				<v-menu v-if="aes" offset-y open-on-hover :close-on-content-click="false" bottom left>
					<template v-slot:activator="{ on, attrs }">
						<v-btn small v-bind="attrs" v-on="on" class="ml-auto mr-3" :color="ae.length ? 'primary' : 'button'">
							AE <v-icon small class="ml-3">fad fa-caret-down</v-icon>
						</v-btn>
					</template>
					<v-list v-if="aes">
						<v-list-item v-for="item in aes" :key="item.person.id">
							<v-checkbox dense hide-details :value="item.person.id" v-model="ae">
								<div slot="label">
									{{ item.person ? item.person.full_name : 'Not Set' }} <v-chip x-small class="ml-2">{{ item.total }}</v-chip>
								</div>
							</v-checkbox>
						</v-list-item>
					</v-list>
				</v-menu>
				<v-btn @click="$apollo.queries.projects.refetch()" small min-width="0">
					<v-icon small>fad fa-sync</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-subtitle>
				<p>Shows time ran for this month only.</p>
				<ul>
					<li>SH-30 - "Start-Up" hosting plan w/ 30 minutes maintenance a month</li>
					<li>EH-60 - "Enhanced" hosting plan w/ 1 hour maintenance a month</li>
					<li>AH-120 - "Advanced" hosting plan w/ 2 hours maintenance a month</li>
				</ul>
			</v-card-subtitle>

			<v-card-text v-if="projects">
				<v-data-table :headers="headers" :items="projects.nodes" sort-by="percent" sort-desc hide-default-footer disable-pagination>
					<template v-slot:item.node.time_spent="{ item }">
						<v-chip label dark :color="parseInt(item.node.time_spent) > parseInt(retainerInfo(item.node.name)[2]) ? 'magenta' : ''" class="w-100 text-center justify-center">
							<span title="Minutes used this month">{{item.node.time_spent}}</span> <span class="mx-2">/</span> <span title="Free minutes per month">{{retainerInfo(item.node.name)[2]}}</span><br>
						</v-chip>
					</template>
					<!-- TODO: handle via graphql instead -->
					<!-- <template v-slot:item.percent="{ item }">
						<v-chip label :style="getColorStyles(item.time_spent, item.name)">
							<span>{{Math.round(item.time_spent / retainerInfo(item.name)[2] * 100)}}%</span>
						</v-chip>
					</template> -->
					<template v-slot:item.node.account_executive_user.full_name="{ item }">
						<div class="d-flex align-center">
							<user-avataaar class="avatar mr-2" :uid="item.node.account_executive_user.uid" style="max-width:35px;"></user-avataaar>
							{{item.node.account_executive_user.full_name }}
						</div>
					</template>
				</v-data-table>
			</v-card-text>

		</v-card>
	</v-container>
</template>

<script>
	
	import { FactoryAEQuery, EnumValue } from "@/graphql/queries";
	import UserAvataaar from "../components/avataaars/user-avataaar";
	import moment from "moment";
	import { timeInterval } from '../utils/timeInterval';

	const ColorMap = {
		0: [31, 0, 69],
		0.9: [31, 0, 69],
		1: [73, 9, 62],
		1.5: [183, 21, 156],
		2: [229, 36, 197],
		2.5: [229, 36, 197],
	};

	export default {
		name: "retainerHours",
		components: { UserAvataaar },
		data() {
			return {
				ae: [],
				headers: [
					{ text: "Client", value: "node.client.name" },
					{ text: "Project", value: "node.name" },
					// { text: "Percent", value: "percent" },
					{ text: "Minutes", value: "node.time_spent", width: 50 },
					{ text: "AE", value: "node.account_executive_user.full_name" },
				],
			};
		},
		apollo: {
			projects: {
				query: gql`
					query getRetainerProjects(
						$filterParam: QueryFilter!
						$thisMonth: DateTime
					) 
					{
						projects @filter(param: $filterParam) @sort(param: [{ field: "client.name", order: asc }]) {
							total
							nodes {
								node {
									id
									name
									account_executive_user {
										uid
										id
										full_name
										meta_avatar
									}
									client {
										uid
										id
										name
									}
									time_spent(after: $thisMonth) @formatDuration(format: "M")
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "name",
							op: "regexp",
							value: "^Website\\s*\\((?:[a-z]+-)?[1-9][0-9]*\\).*$",
						},
						{
							field: "status",
							op: "in",
							value: ["active", "hidden"],
						},
						{
							field: "time_spent_this_month",
							op: ">",
							value: 0,
						},
					];

					if (this.ae.length) {
						filters.push({
							field: "account_executive_user.id",
							op: "in",
							value: this.ae,
						});
					}

					return {
						thisMonth: moment().startOf("month").format("YYYY-MM-DD"),
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				pollInterval: timeInterval('5 minutes'),
			},
			aes: {
				query: gql`
					query GetRetainerAEs {
						queries
						aes: retainerProjectAccountExecutives {
							person {
								id
								full_name
							}
							total
						}
					}
				`,
			},
		},
		methods: {
			retainerInfo(name) {
				return name.match(/\((?:([a-zA-Z]+)-)?([1-9][0-9]*)\)/);
			},

			getColorStyles(spent, name) {
				let percentage = (spent / this.retainerInfo(name)[2]);
				let color = this.$insight.helpers.interpolateColor(
					ColorMap,
					percentage
				);

				return {
					backgroundColor: color.style,
					color: (color.isBright ? "#000000" : "#FFFFFF") + " !important",
				};
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>
