<script>
	import { objectWithoutKeys } from '../../helpers';
	import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
	import { IdType } from '../../utils/IdType';

	export default {
		model: {
			prop: 'value',
			event: 'input'
		},

		props: {
			...objectWithoutKeys(VAutocomplete.options.props, ['items', 'item-text', 'item-value', 'item-disabled']),

			projectType: String,
			projectId: IdType,

			activeOnly: {
				type: Boolean,
				default: true
			}
		},

		apollo: {
			jobs: {
				query: gql`
					query GetTimerJobs($activeOnly: Boolean, $projectType: String $projectId: ID) {
						jobs: quickbooksJobs(active_only: $activeOnly for_project_type: $projectType for_project_id: $projectId)
					}
				`,
				variables() {
					return {
						activeOnly: this.activeOnly,
						projectType: this.projectType,
						projectId: this.projectId
					}
				}
			}
		},

		computed: {
			items() {
				return this.jobs || [];
			}
		},

		render(h) {
			return h(
				VAutocomplete,
				{
					props: {
						...this.$props,
						items: this.items,
						loading: this.loading || this.$apollo.queries.jobs.loading
					},
					scopedSlots: this.$scopedSlots,
					on: this.$listeners
				}
			);
		}
	}
</script>