import { render, staticRenderFns } from "./projectAnalytics.vue?vue&type=template&id=50566bf2&scoped=true&"
import script from "./projectAnalytics.vue?vue&type=script&lang=js&"
export * from "./projectAnalytics.vue?vue&type=script&lang=js&"
import style0 from "./projectAnalytics.vue?vue&type=style&index=0&id=50566bf2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50566bf2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_0f7bc6efe1949d16b55ed303eb0ec848/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VContainer,VTab,VTabs,VToolbar})
