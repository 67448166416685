<!--not the same as noteList.vue-->
<template>
	<div v-if="project" class="fill-height d-flex flex-column">
		<paginator tag="div" :params="{ limit: 200, search: '', noteTypes: [] }" v-slot="{ params, setParam }" class="fill-height">
			<ApolloQuery v-bind="getProjectNotesQuery(params)" tag="">
				<template v-slot="{ result: { data }, query }">
					<ApolloQuery v-bind="getProjectNoteTypesQuery(params)" class="fill-height d-flex flex-column">
						<template v-slot="{ result: { data: noteTypesData }, query: noteTypesQuery }">
							<v-card :loading="query.loading" class="mb-1">
								<v-card-title>
									Notes <v-chip v-if="data" class="ml-1">{{data.notes.total}}</v-chip>
									<div class="ml-auto">
										<v-btn small class="mr-2" @click="query.refetch(); noteTypesQuery.refetch();">
											<v-icon small>fad fa-sync</v-icon>
										</v-btn>
										<add-note-button obj-type="project" :obj-id="project.id" @save="query.refetch(); noteTypesQuery.refetch();"></add-note-button>
									</div>
								</v-card-title>
							</v-card>

							<v-sheet v-if="noteTypesData" class="mb-1 py-2 px-4">
								<debounce-events :events="{ change: 500 }" v-slot="{ change }">
									<v-text-field :value="params.search" @input="change(() => setParam('search', $event == null ? '' : $event))" clearable hide-details label="Search Notes" class="my-0 mb-3"></v-text-field>
								</debounce-events>
								<v-btn-toggle :value="params.noteTypes" @change="setParam('noteTypes', $event)" dense multiple group class="flex-wrap" color="primary">
									<template v-for="row in noteTypesData.notes.nodes">
										<v-btn :key="row.node.note_type" small :value="row.node.note_type">
											{{noteTypeName(row.node.note_type)}} ({{row.group_total}})
										</v-btn>
									</template>
								</v-btn-toggle>
							</v-sheet>

							<div v-if="data" class="flex-grow-1" style="position:relative;">
								<v-data-iterator class="desktop-fit" :items="data.notes.nodes" hide-default-footer :items-per-page="-1">
									<template v-slot:default="props">
										<note-list-item v-for="(item, ndx) in props.items" :key="ndx" :note="item.node" @update="query.refetch(); noteTypesQuery.refetch();"></note-list-item>
									</template>
								</v-data-iterator>
							</div>

						</template>
					</ApolloQuery>
				</template>
			</ApolloQuery>
		</paginator>
	</div>
</template>

<script>
	import addNoteButton from "@/components/addNoteButton";
	import noteListItem from "@/components/noteListItem";
	import taskListItem from "@/components/taskListItem";
	import promiseResolver from "../../../components/renderless/promiseResolver.vue";
	import paginator from "../../../components/renderless/paginator.vue";

	export default {
		name: "taskListView",

		components: {
			noteListItem,
			taskListItem,
			addNoteButton,
			paginator,
			promiseResolver,
		},
		inject: ["projectContext"],

		data() {
			return {
			};
		},

		computed: {
			project() {
				return this.projectContext.project;
			},
			// page() {
			// 	return this.offset / this.limit + 1;
			// },
		},

		methods: {
			getProjectNotesQuery({ search, noteTypes, limit }) {
				let filters = [
					{
						joinOp: 'or',
						filters: [
							{
								field: "linked_object.uid",
								op: "=",
								value: this.project.uid,
							},
							{
								field: 'task.project.uid',
								op: '=',
								value: this.project.uid
							}
						]
					}
				];

				if (search) {
					filters.push(
						{
							joinOp: 'or',
							filters: [
								{
									field: 'content',
									op: 'like',
									value: `%${search.replace(/%/g, '%%')}%`
								},
								{
									field: 'author.full_name',
									op: 'like',
									value: `%${search.replace(/%/g, '%%')}%`
								}
							]
						}
					);
				}

				if (noteTypes && noteTypes.length) {
					filters.push({
						field: 'note_type',
						op: 'in',
						value: noteTypes
					});
				}

				return {
					query: gql`
						query getProjectNotes($filterParam: QueryFilter! $limit: Int) {
							notes
								@sort(param: [{field: "date", order: desc}])
								@filter(param: $filterParam)
								@page(limit: $limit)
							{
								total
								nodes
								{
									node {
										id
										content
										modified_date
										date
										note_type
										author {
											id
											uid
											full_name
											department @lowercase
										}
										mailto_users {
											id
											full_name
										}
									}
								}
							}
						}
					`,
					variables: {
						filterParam: {
							joinOp: "and",
							filters
						},
						limit
					}
				}
			},

			getProjectNoteTypesQuery({ search }) {
				let filters = [
					{
						joinOp: 'or',
						filters: [
							{
								field: "linked_object.uid",
								op: "=",
								value: this.project.uid,
							},
							{
								field: 'task.project.uid',
								op: '=',
								value: this.project.uid
							}
						]
					}
				];

				if (search) {
					filters.push(
						{
							joinOp: 'or',
							filters: [
								{
									field: 'content',
									op: 'like',
									value: `%${search.replace(/%/g, '%%')}%`
								},
								{
									field: 'author.full_name',
									op: 'like',
									value: `%${search.replace(/%/g, '%%')}%`
								}
							]
						}
					);
				}

				return {
					query: gql`
						query getProjectNoteTypes($filterParam: QueryFilter!) {
							notes
								@filter(param: $filterParam)
								@groupBy(fields: ["note_type"])
							{
								sql
								nodes
								{
									group_total
									node {
										id
										note_type
									}
								}
							}
						}
					`,
					variables: {
						filterParam: {
							joinOp: "and",
							filters
						}
					}
				}
			},

			getTotalItems(noteTypes, totals) {
				if (noteTypes.length) {
					let sum = 0;
					noteTypes.forEach((t) => (sum += parseInt(totals[t]) || 0));
					return sum;
				} else {
					return parseInt(totals.all);
				}
			},
			noteTypeName(type) {
				if (type === "client_from") {
					return "From Client";
				}
				if (type === "client_to") {
					return "To Client";
				}
				if (type === "task_completed") {
					return "Task";
				}
				return type;
			},
		},
	};
</script>

<style scoped lang="scss">
	@media (min-width: 960px) {
		.desktop-fit {
			overflow-y: auto;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
</style>