<template>
	<div v-if="!$apollo.loading" class="meta-data-fields">
		<!-- <pre>{{model._meta_types}}</pre> -->
		<!-- <pre>{{model._meta_values}}</pre> -->
		<meta-input v-for="fieldDef in metaTypes" :key="fieldDef.machine_name" v-model="fieldData[fieldDef.machine_name]" :field="fieldDef" :disabled="disabled" layout="modal" class="input-group input-group-sm"></meta-input>
	</div>
</template>
<script>
	import metaInput from "./metaInput";
	import _ from "lodash";
	import moment from "moment";

	export default {
		name: "metaInputList",
		components: { metaInput },
		props: {
			modelType: {
				type: String,
				required: true,
			},

			model: Object,

			disabled: {
				type: Boolean,
				default: false,
			},
		},

		apollo: {
			metaTypes: {
				query: gql`
					query GetMetaTypes($model: MetaContainer!) {
						metaTypes: metaTypesByModel(model: $model)
							@sort(
								param: [
									{ field: "order", order: asc }
									{ field: "creation_date", order: asc }
								]
							) {
							name
							machine_name
							options
							type
							order
							creation_date
						}
					}
				`,
				variables() {
					return {
						model: this.modelType
					}
				}
			},
		},

		data() {
			return {
				fieldData: {}
			};
		},

		/* computed: {
			metaFields() {
				let fields = [],
					metaTypeMap = this.$db.getMetaTypeMap(this.modelType);

				if (!metaTypeMap) return [];
				for (let field in metaTypeMap.types) {
					if (metaTypeMap.types[field].protected < 1) {
						fields.push(metaTypeMap.types[field]);
					}
				}

				fields.sort((a, b) => {
					let sort = a.order - b.order;
					if (sort === 0) {
						sort =
							moment(a.creation_date).valueOf() -
							moment(b.creation_date).valueOf();
					}

					return sort;
				});

				return fields;
			},
		}, */

		methods: {
			/**
			 * Returns the fields' data.
			 */
			getFields() {
				let data = {};
				_.forEach(this.fieldData, (field, idx) => {
					if (field instanceof Array && field.length === 0) {
						// xhrrequest won't send an empty array
						field = null;
					}
					data[idx] = field;
				});
				return data;
			},
			/**
			 * Initializer to set internal field data values to the ones passed in through the fields property
			 */
			metaFieldValues() {
				let values = {},
					modelMeta = this.model;

				// TODO cleanup after graphql fully implemented
				if (modelMeta && modelMeta._meta_values) {
					modelMeta = modelMeta._meta_values;
				}

				for (let field of this.metaTypes) {
					values[field.machine_name] = modelMeta
						? modelMeta[field.machine_name]
						: null;
				}

				return values;
			},
		},

		watch: {
			metaTypes: {
				handler(to) {
					this.fieldData = this.metaFieldValues();
				},
			},

			model() {
				if (this.metaTypes) {
					this.fieldData = this.metaFieldValues();
				}
			}
		},
	};
</script>