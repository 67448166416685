<template>
	<v-card>
		<v-card-title>
			<div v-html="caption"></div>
			<div class="ml-auto d-flex align-center">
				<template v-if="project">
					<add-note-button db="notes" objType="project" :objId="project.id" noteType="reminder" class="mx-1" color="reminder" tinyColor="grey darken-4" @save="$emit('save')"></add-note-button>
					<add-note-button db="notes" objType="project" :objId="project.id" noteType="deadline" class="mx-1" color="deadline" tinyColor="grey darken-4" @save="$emit('save')"></add-note-button>
					<v-switch v-model="showHidden" dense label="Show Hidden" class="ml-3"></v-switch>
				</template>
			</div>
		</v-card-title>
		<v-card-text>
			<div data-filter="all">
				<template v-for="(note, ndx) in notes">
					<note-list-item v-if="showNote(note.node)" :key="ndx" :note="note.node" :compact="true" @update="$emit('save')"></note-list-item>
				</template>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import addNoteButton from "@/components/addNoteButton";
	import noteListItem from "@/components/noteListItem";

	export default {
		name: "deadlines",
		components: {
			addNoteButton,
			noteListItem,
		},

		inject: ['projectContext'],

		data() {
			return {
				showHidden: false,
			};
		},

		props: {
			notes: {
				type: [Array, Object],
			},
			caption: {
				type: String,
				default: "Notes",
			},
		},
		asyncComputed: {},
		computed: {
			project() {
				return this.projectContext.project;
			},
		},
		methods: {
			showNote(note) {

				if(this.showHidden) {
					return true;
				}

				return !note.hidden;
			}
		},
	};
</script>
