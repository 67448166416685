<template>
	<timer-totals
		:time_spent="time_spent"
		:time_since="time_since"
		:timer="timer"
		:timers="timers"
		:rateLimited="rateLimited"
		:ignoreLunch="ignoreLunch"
		v-slot="{ duration, classes }"
	>
		<span class="number" :class="classes">{{duration | formatDuration}}</span>
	</timer-totals>
</template>

<script>
	import { objectWithoutKeys } from '../helpers';
	import TimerTotals from './renderless/timerTotals.vue';

	let timer_1second = false;

	export default {
		name: "timerClock",

		components: {
			TimerTotals
		},

		props: objectWithoutKeys(TimerTotals.props, ['tag']),

		created() {
			if (!timer_1second) {
				timer_1second = setInterval(() => {
					this.$store.commit("updateNow");
				}, 1000);
			}
		},
	};
</script>
