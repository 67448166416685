<template>

	<v-card :loading="$apollo.queries.projects.loading">
		<v-card-title class="mb-3">
			<div>Project Profitability <span v-if="projects">({{projects.total}})</span></div>

			<v-btn-toggle v-model="projectStatusSelected" dense mandatory class="ml-sm-auto mr-3 my-4 my-sm-0">
				<v-btn small value="active">
					active
				</v-btn>
				<v-btn small value="closed">
					Closed within 90 days
				</v-btn>
			</v-btn-toggle>

			<v-menu offset-y open-on-hover :close-on-content-click="false" bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn small v-bind="attrs" v-on="on" class="mr-3" :color="ae.length ? 'primary' : 'button'">
						AE <v-icon small class="ml-3">fad fa-caret-down</v-icon>
					</v-btn>
				</template>
				<v-list v-if="aes">
					<v-list-item v-for="item in aes.nodes" :key="item.node.account_executive_user.id">
						<v-checkbox dense hide-details :value="item.node.account_executive_user.id" v-model="ae">
							<div slot="label">
								{{ item.node.account_executive_user.full_name || 'Not Set' }} <v-chip x-small class="ml-2">{{ item.group_total }}</v-chip>
							</div>
						</v-checkbox>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-btn small class="" @click="$apollo.queries.projects.refetch()">
				<v-icon small>fad fa-sync</v-icon>
			</v-btn>
		</v-card-title>

		<v-card-text v-if="projects">

			<v-row v-if="averageByType" class="my-5 justify-center text-center">
				<v-col cols="auto" v-for="(item, i) in averageByType" :key="i">
					<div class="mb-3 text-h5">
						<div v-if="item.cat" class="d-flex flex-column">
							<v-icon large class="mb-2">{{$insight.helpers.getProjectIcon(item.cat)}}</v-icon>
							<div>{{item.cat}}</div>
						</div>
						<div v-else class="d-flex flex-column">
							<v-icon large class="mb-2">fad fa-question-circle</v-icon>
							<div>N/A</div>
						</div>
					</div>
					<v-chip label class="d-block text-center" small :style="getColorStyles(item.avg)">
						Average: {{ item.avg }}%
					</v-chip>
				</v-col>
			</v-row>

			<v-row v-if="aesAverageBudgetUsed" class="my-5 justify-center">
				<v-col cols="auto" v-for="item in aesAverageBudgetUsed" :key="item.id" class="d-flex flex-column align-center text-center">
					<user-avataaar class="avatar mr-2" :uid="item.uid" style="max-width:100px;"></user-avataaar>
					<div class="my-3 text-truncate">{{item.ae.full_name}}</div>
					<v-chip label class="d-block text-center" :style="getColorStyles(item.avg)">
						Average: {{ item.avg }}%
					</v-chip>
				</v-col>
			</v-row>

			<v-text-field v-model="search" label="Search" single-line hide-details dense outlined class="mb-4"></v-text-field>

			<p>Tip: Sort by clicking on the column names. Try clicking on "group" next to a column name.</p>

			<v-data-table v-if="projectNodes.length" :headers="headers" :items="projectNodes" group-desc sort-by="budget_percentage" show-group-by disable-pagination hide-default-footer sort-desc :search="search" :item-class="rowClasses">
				<template v-slot:item.name="{ item }">
					<v-icon v-if="item.status == 'closed'" small left>fal fa-ban</v-icon>
					{{item.client.name}}: {{item.name}}
				</template>
				<template v-slot:item.budget_percentage="{ item }">
					<v-chip label class="d-block text-center" small :style="getColorStyles(item.budget_percentage)">
						{{ item.budget_percentage }}%
					</v-chip>
				</template>
				<template v-slot:item.time_spent="{ item }">
					{{item.time_spent | formatDuration}}
				</template>
				<template v-slot:item.account_executive_user.full_name="{ item }">
					<div class="d-flex align-center">
						<user-avataaar class="avatar mr-2" :uid="item.account_executive_user.uid" style="max-width:35px;"></user-avataaar>
						{{item.account_executive_user.full_name}}
					</div>
				</template>
			</v-data-table>

		</v-card-text>
	</v-card>

</template>

<script>
	
	import { FactoryAEQuery, EnumValue } from "@/graphql/queries";
	import moment from "moment";
	import UserAvataaar from "@/components/avataaars/user-avataaar";
	import { parseParams, stringifyParams } from '../../../utils/advancedRouteParams';

	/* const ColorMapSimple = {
		0: [66, 66, 66],
		0.9: [66, 66, 66],
		1: [251, 192, 45],
		1.5: [245, 124, 0],
		2: [208, 51, 58],
		1000: [208, 51, 58],
	}; */

	const ColorMapSimple = {
		0: [66, 66, 66],
		0.9: [66, 66, 66],
		// 1: [245, 172, 233],
		// 1.5: [183, 21, 156],
		2: [229, 36, 197],
		// 2.5: [229, 36, 197],
	};

	const ColorMapSpace = {
		0: [31, 0, 69],
		0.9: [31, 0, 69],
		1: [73, 9, 62],
		1.5: [183, 21, 156],
		2: [229, 36, 197],
		2.5: [229, 36, 197],
	};

	export default {
		name: "project-profitability",
		components: { UserAvataaar },
		data() {
			return {
				ae: [],
				search: "",
				// projectStatusSelected: ["active"],
				projectStatuses: ["active", "recently closed"],
				headers: [
					{ text: "Project", value: "name" },
					{ text: "Category", value: "category" },
					{ text: "Hours Spent", value: "time_spent" },
					{ text: "Hours Budgeted", value: "hours_allocated" },
					{ text: "Budget Used", value: "budget_percentage" },
					{ text: "AE", value: "account_executive_user.full_name" },
				],
			};
		},
		apollo: {
			projects: {
				query: gql`
					query getProjectProfitability($filterParam: QueryFilter!) {
						projects @filter(param: $filterParam) {
							total
							nodes {
								node {
									id
									name
									hours_allocated
									time_spent
									budget_percentage
									status
									category
									closed_status_date
									account_executive_user {
										id
										uid
										full_name
										meta_avatar
									}
									client {
										id
										name
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "hours_allocated",
							op: ">",
							value: 0,
						},
					];

					if (this.ae.length) {
						filters.push({
							field: "account_executive_user.id",
							op: "in",
							value: this.ae,
						});
					}

					let statusFilters = [];

					if (this.projectStatusSelected.includes("active")) {
						statusFilters.push({
							field: "status",
							op: "=",
							value: "active",
						});
					}

					if (this.projectStatusSelected.includes("closed")) {
						statusFilters.push({
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "=",
									value: "closed",
								},
								{
									field: "closed_status_date",
									op: ">=",
									value: moment()
										.startOf("day")
										.add(-90, "days")
										.format("YYYY-MM-DD HH:mm:ss"),
								},
							],
						});
					}

					filters.push({
						joinOp: "or",
						filters: statusFilters,
					});

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
			aes: {
				query() {
					let filters = [
						{
							field: "hours_allocated",
							op: ">",
							value: 0,
						},
					];

					let statusFilters = [];

					if (this.projectStatusSelected.includes("active")) {
						statusFilters.push({
							field: "status",
							op: "=",
							value: "active",
						});
					}

					if (this.projectStatusSelected.includes("closed")) {
						statusFilters.push({
							joinOp: new EnumValue("and"),
							filters: [
								{
									field: "status",
									op: "=",
									value: "closed",
								},
								{
									field: "closed_status_date",
									op: ">=",
									value: moment()
										.startOf("day")
										.add(-90, "days")
										.format("YYYY-MM-DD HH:mm:ss"),
								},
							],
						});
					}

					filters.push({
						joinOp: new EnumValue("or"),
						filters: statusFilters,
					});

					return FactoryAEQuery({
						filters: {
							joinOp: new EnumValue("and"),
							filters: filters,
						},
					});
				},
			},
		},

		computed: {
			projectNodes() {
				if(!this.projects) {
					return [];
				}

				return this.projects.nodes
					.map(row => row.node)
			},

			routeParams: {
				get() {
					return parseParams(this.$route.params);
				},
				set(v) {
					this.$router.replace({ params: stringifyParams(v) });
				}
			},

			projectStatusSelected: {
				get() {
					return this.routeParams.options.closed
						? 'closed'
						: 'active';
				},
				set(v) {
					let newParams = JSON.parse(JSON.stringify(this.routeParams));
					newParams.options.closed = v == 'closed';

					this.routeParams = newParams;
				}
			},

			aesAverageBudgetUsed() {
				if (!this.projects) {
					return [];
				}

				let projects = Linq.from(this.projects.nodes),
					aeBudget = {};

				aeBudget = projects
					.select(row => row.node)
					.where((p) => p.budget_percentage > 0)
					.groupBy(
						(ae) => ae.account_executive_user.uid,
						(uid, group) => {
							return {
								uid,
								ae: group.first().account_executive_user,
								avg: group
									.average((p) => p.budget_percentage)
									.toFixed(2),
							};
						}
					)
					.orderByDescending((item) => parseInt(item.avg));

				return aeBudget;
			},

			averageByType() {
				if (!this.projects) {
					return [];
				}

				let projects = Linq.from(this.projects.nodes),
					avgType = {};

				avgType = projects
					.select(row => row.node)
					.where((p) => p.budget_percentage > 0)
					.groupBy(
						(item) => item.category,
						(cat, group) => {
							return {
								cat,
								avg: group
									.average((p) => p.budget_percentage)
									.toFixed(2),
								// group: group
							};
						}
					)
					.orderByDescending((item) => parseInt(item.avg));

				return avgType;
			},
		},

		methods: {
			rowClasses(item) {
				if (item.status == "closed") {
					return "closed"; //can also return multiple classes e.g ["orange","disabled"]
				}
			},

			getColorStyles(percentage) {

				let theme = this.$store.getters.userData.theme;
				let color = "";

				if(/space/i.test(theme)) {
					color = this.$insight.helpers.interpolateColor(
						ColorMapSpace,
						percentage / 100
					);
				} else {
					color = this.$insight.helpers.interpolateColor(
						ColorMapSimple,
						percentage / 100
					);
				}


				return {
					backgroundColor: color.style + '!important',
					color: (color.isBright ? "#000000" : "#FFFFFF") + " !important",
				};
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .v-data-table th.sortable {
		span:last-child {
			color: var(--v-primary-base);
		}
	}
</style>
