<template>
	<div>
		<slot name="header">
			<div class="mr-4 text-over-bg--text">
				Page {{ page }} of {{ numberOfPages }}
			</div>
		</slot>
		<v-btn :small="iconSmall" color="primary" class="mr-1" @click="changePage(-1)" min-width="0" :disabled="page == 1">
			<v-icon :small="iconSmall">{{iconLeft}}</v-icon>
		</v-btn>
		<v-btn small color="primary" class="ml-1" @click="changePage(1)" min-width="0" :disabled="page == numberOfPages">
			<v-icon :small="iconSmall">{{iconRight}}</v-icon>
		</v-btn>
		<slot name="footer"></slot>
	</div>
</template>

<script>
	export default {
		model: {
			prop: 'page',
			event: 'input'
		},

		props: {
			page: {
				type: Number,
				validator(v) {
					return v >= 1 && v == Math.floor(v);
				}
			},

			numberOfPages: {
				type: Number,
				validator(v) {
					return v >= 1 && v == Math.floor(v);
				}
			},

			iconLeft: {
				type: String,
				default: 'fad fa-chevron-left'
			},

			iconRight: {
				type: String,
				default: 'fad fa-chevron-right'
			},

			iconSmall: {
				type: Boolean,
				default: true
			}
		},

		methods: {
			changePage(amount) {
				let page = this.page + amount;
				page = Math.max(1, Math.min(this.numberOfPages, page));

				this.$emit('input', page);
			}
		}
	}
</script>