<template>
	<v-card class="mb-2">
		<v-card-title>
			Project Checklist
			<span class="not-saved ml-3" v-if="notSaved"><v-icon small class="mr-2">fad fa-spinner fa-spin</v-icon>Saving... </span>
		</v-card-title>
		<v-card-text>
			<check-list-tree-item v-if="rawItems" :node="rawItems" :depth="0" @changed="changed"></check-list-tree-item>
		</v-card-text>
	</v-card>
</template>
<script>
	import checkListTreeItem from "./checkListTreeItem";

	export default {
		name: "checkListTree",

		components: {
			checkListTreeItem,
		},

		inject: ["projectContext"],

		props: ['items'],

		data() {
			return {
				notSaved: false,
				origItems: null,
				rawItems: null
			};
		},

		computed: {
			project() {
				return this.projectContext.project;
			},
		},

		methods: {
			changed() {
				if (JSON.stringify(this.rawItems) != JSON.stringify(this.origItems)) {
					this.notSaved = true;
					if (this.debounce) {
						clearTimeout(this.debounce);
					}

					this.debounce = setTimeout(() => {
						this.$emit('save', this.rawItems);
						this.debounce = false;
					}, 500);
				}
			},
		},

		watch: {
			items: {
				deep: true,
				immediate: true,
				handler(to) {
					if (!to || typeof to != 'object' || !to.children) {
						to = {
							children: []
						};
					}

					this.origItems = to;
					this.rawItems = JSON.parse(JSON.stringify(to));
					this.notSaved = false;

					// Remove deleted
					let filteredChildren = this.rawItems.children.filter(n => !n.deleted);
					if (filteredChildren.length != this.rawItems.children.length) {
						Vue.set(this.rawItems, 'children', filteredChildren);
						this.changed();
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.not-saved {
		color: #555;
	}
</style>