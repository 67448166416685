import { facialHairTypes as OriginalFacialHairTypes } from 'vuejs-avataaars/src/assetsTypes/facial-hair'
import { standardReplacements } from './utils';

standardReplacements(OriginalFacialHairTypes, 'facialhairtype');

export const facialHairTypes = OriginalFacialHairTypes;
export const facialHairTypesOptions = {
	_default: {
		Color: 'color'
	},

	Blank: {}
};