<template>
	<v-container fluid class="activity-list">
		<v-sheet class="filter-bar mb-3 hide-on-panel d-flex flex-column flex-sm-row">
			<div class="col-sm d-flex align-center">
				<v-btn color="primary" @click="getLogs()" v-if="$store.getters.hasPermission('chuckit_dev')" small class="mr-3 hide-on-panel">
					<v-icon small>fal fa-sync</v-icon>
				</v-btn>
				<v-menu v-model="calMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="200px">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="filters.date" label="Date" prepend-icon="fad fa-calendar" readonly v-bind="attrs" v-on="on" hide-details clearable class="pt-0"></v-text-field>
					</template>
					<v-date-picker v-model="filters.date" @input="calMenu = false" color="primary" no-title show-adjacent-months next-icon="fad fa-chevron-right" prev-icon="fad fa-chevron-left" year-icon=""></v-date-picker>
				</v-menu>
			</div>
			<!-- <v-spacer></v-spacer> -->
			<div class="col-sm-auto d-flex align-end">
				<v-btn-toggle v-model="filters.department" dark multiple class="flex-wrap">
					<v-btn small :color="item.toLowerCase()" v-for="item in departmentLegendItems" :key="item" :value="item" min-width="0">
						{{ item }}
					</v-btn>
				</v-btn-toggle>
			</div>
		</v-sheet>

		<template v-if="logs.length > 0">
			<activity-log-item v-for="log in logs" :log="log" :key="log.id"></activity-log-item>
		</template>
		<template v-else-if="logs.length == 0 && !loading">
			<div class="error--text text-center lead">
				No activity found
			</div>
		</template>

		<div class="row loading-row white--text">
			<div class="col-12 lead text-center">
				<div v-if="loading && max == 0">
					<i class="fa fa-cog fa-spin"></i> Loading...
				</div>
				<v-btn small v-else-if="manual && !loading" @click="getMoreLogs">
					Load more
				</v-btn>
				<div v-else class="invisible">-</div>
			</div>
		</div>
	</v-container>
</template>
<script>
	import activityLogItem from "@/components/activityLogItem";

	export default {
		name: "activity",
		props: {
			max: {
				type: Number,
				default: 0,
			},
		},
		components: { activityLogItem },
		data() {
			return {
				calMenu: false,

				logs: [],
				loading: null,
				manual: false,
				lastRefresh: null,
				filters: {
					department: this.$insight.users
						.departments()
						.map((dept) => dept.text),
					date: null,
				},
			};
		},
		computed: {
			requestOptions() {
				let options = {};
				if (this.filters.date) {
					options.date = this.filters.date;
				}
				if (this.filters.department) {
					options.departments = this.filters.department;
				}
				return options;
			},
			departmentLegendItems() {
				let options = this.$insight.users
					.departments()
					.map((department) => {
						return department.text;
					});

				return options;
			},
		},
		methods: {
			getLogs(options = {}) {
				if (this.loading) {
					return;
				}
				if (this.filters.date) {
					options.date = this.filters.date;
				}
				if (Object.values(this.filters.department).length > 0) {
					options.departments = Object.values(
						this.filters.department
					).join(",");
				}

				clearTimeout(this.lastRefresh);

				this.loading = this.$xhrRequest
					.send("get", "/api/activity-log", options)
					.then((response) => {
						if (options.after) {
							this.logs = response.concat(this.logs);
						} else if (options.before) {
							this.logs = this.logs.concat(response);
						} else {
							this.logs = response;
						}

						if (this.max && this.max > 0) {
							this.logs = this.logs.slice(0, this.max);
						}

						this.loading = null;
						this.lastRefresh = setTimeout(
							() => {
								this.refresh();
							},
							30000,
							this
						);
					})
					.catch((err) => {
						this.manual = true;
						$(window).off("scroll");
						this.loading = null;
					});
			},

			// get fresh data by requesting records newer than our current data
			refresh() {
				let options = {};

				if (this.logs[0]) {
					options.after = this.logs[0].id;
				}
				this.getLogs(options);
			},
			getMoreLogs() {
				let options = {};
				if (this.logs.length > 0) {
					options.before = this.logs[this.logs.length - 1].id;
				}
				this.getLogs(options);
			},
		},

		mounted() {
			this.getLogs();
			let $window = $(window);
			$window.on("scroll", (e) => {
				if (
					$window.scrollTop() + $window.height() ===
					$(document).height()
				) {
					clearTimeout(this.lastRefresh);
					this.lastRefresh = setTimeout(() => {
						this.getMoreLogs();
					}, 1);
				}
			});
		},
		destroyed() {
			$(window).off("scroll");
			clearTimeout(this.lastRefresh);
		},
		watch: {
			"filters.date"(to) {
				this.getLogs();
			},
			"filters.department"(to) {
				this.getLogs();
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>
