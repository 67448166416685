var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.$apollo.queries.persons.loading}},[_c('v-card-title',{staticClass:"d-flex flex-wraps"},[_vm._v(" People / Contacts "),(_vm.persons)?[_vm._v("("+_vm._s(_vm.persons.total)+")")]:_vm._e(),_c('v-btn',{staticClass:"ml-auto",attrs:{"small":""},on:{"click":function($event){return _vm.$apollo.queries.persons.refetch()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-sync")])],1)],2),_c('v-card-text',[_c('debounce-events',{attrs:{"events":{ input: 500 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var input = ref.input;
return [_c('v-text-field',{attrs:{"value":_vm.searchTxt,"label":"Search","dense":"","single-line":"","hide-details":"","clearable":""},on:{"input":function($event){input(function () { return _vm.searchTxt = $event; })}}})]}}])})],1)],1),(_vm.persons)?_c('v-data-table',{staticClass:"user-table",attrs:{"headers":_vm.headers,"hide-default-footer":"","items":_vm.personNodes,"disable-pagination":"","mobile-breakpoint":"1200"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-auto",attrs:{"small":"","to":{ name: 'contact.details', params: { id: item.id } },"min-width":"0"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-link")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","title":"Edit Person","min-width":"0"},on:{"click":function($event){return _vm.editPerson(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fad fa-pencil")])],1)]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.phoneTypes),function(type){return [(item[type.key])?_c('div',{key:type.key,staticClass:"d-flex align-center mb-2"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(_vm._s(type.icon))]),_c('a',{staticClass:"d-block",attrs:{"href":_vm._f("getPhoneLink")(item[type.key])}},[_vm._v(" "+_vm._s(item[type.key])+" ")])],1):_vm._e()]})]}},{key:"item.meta_person_hubspot_link",fn:function(ref){
var item = ref.item;
return [(item.meta_person_hubspot_link)?_c('v-btn',{attrs:{"href":item.meta_person_hubspot_link,"target":"_blank","small":"","min-width":"0","title":"View on Hubspot"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fab fa-hubspot")])],1):_vm._e()]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.emailTypes),function(type){return [(item[type.key])?_c('div',{key:type.key,staticClass:"d-flex align-center mb-2"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(_vm._s(type.icon))]),_c('a',{staticClass:"text-truncate",attrs:{"href":("mailto:" + (item[type.key]))}},[_vm._v(" "+_vm._s(item[type.key])+" ")]),_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item[type.key]),expression:"item[type.key]",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copiedToClipboard),expression:"copiedToClipboard",arg:"success"}],staticClass:"ml-2",attrs:{"x-small":"","min-width":"0"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fad fa-copy")])],1)],1):_vm._e()]})]}},{key:"item.associations",fn:function(ref){
var item = ref.item;
return [_vm._l((item.client_links),function(client){return _c('div',{key:client.id,staticClass:"mb-1 d-flex"},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("fas fa-user")]),_vm._v(" Client: "),_c('router-link',{staticClass:"ml-1 text-truncate",attrs:{"to":{ name: 'client.details', params: { id: client.id } }}},[_vm._v(" "+_vm._s(client.linked_object.name)+" ")])],1)}),_vm._l((item.project_links),function(project){return _c('div',{key:project.id,staticClass:"ml-6 mb-1 d-flex"},[_vm._v(" Project: "),_c('router-link',{staticClass:"ml-1 text-truncate",attrs:{"to":{ name: 'project.details', params: { id: project.id } }}},[_vm._v(" "+_vm._s(project.linked_object.name)+" ")])],1)})]}}],null,false,1988341045)}):_vm._e(),(_vm.persons && _vm.persons.nodes.length)?_c('pager-buttons',{staticClass:"my-3 mr-3 d-flex align-center justify-end",attrs:{"numberOfPages":_vm.numberOfPages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }