var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.$apollo.queries.projects.loading}},[_c('v-card-title',[(_vm.projects)?[_vm._v(_vm._s(_vm.projects.total))]:_vm._e(),_vm._v(" Closed Web Projects ")],2),_c('v-card-subtitle',[_vm._v("Older projects do not have the category, allocated time, or start date set. For better results please update old projects.")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"startmenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start date","prepend-icon":"fad fa-calendar","readonly":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startmenu),callback:function ($$v) {_vm.startmenu=$$v},expression:"startmenu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.startActivePicker,"max":_vm.maxDate,"min":"2016-01-01"},on:{"update:activePicker":function($event){_vm.startActivePicker=$event},"update:active-picker":function($event){_vm.startActivePicker=$event},"change":_vm.saveStart},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{ref:"endmenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End date","prepend-icon":"fad fa-calendar","readonly":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endmenu),callback:function ($$v) {_vm.endmenu=$$v},expression:"endmenu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.endActivePicker,"max":_vm.maxDate,"min":"2016-01-01"},on:{"update:activePicker":function($event){_vm.endActivePicker=$event},"update:active-picker":function($event){_vm.endActivePicker=$event},"change":_vm.saveEnd},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)],1),(_vm.projectList)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projectList,"mobile-breakpoint":"1100","hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{staticClass:"caption text-right-",staticStyle:{"border-bottom-width":"3px"},attrs:{"colspan":"6"}},[_vm._v("Project Department Totals:")]),_vm._l((_vm.projectTotals),function(row,ndx){return _c('td',{key:ndx,staticClass:"caption text-center",class:("" + (row.department) + _vm.cellShade),staticStyle:{"border-bottom-width":"3px"}},[_vm._v(_vm._s(row.percent)+"%")])})],2)]},proxy:true},{key:"item.hours_allocated",fn:function(ref){
var item = ref.item;
return [(item.hours_allocated == 0)?_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")]):_c('span',[_vm._v(_vm._s(item.hours_allocated))])]}},{key:"item.budget_percentage",fn:function(ref){
var item = ref.item;
return [(item.hours_allocated == 0)?_c('span',{staticClass:"text--disabled"},[_vm._v("N/A")]):_c('span',[_vm._v(_vm._s(item.budget_percentage)+"%")])]}},{key:"item.account_executive_user.id",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.account_executive_user.full_name))])]}},{key:"item.project_started",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("shortDate")(item.project_started)))])]}},{key:"item.closed_status_date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("shortDate")(item.closed_status_date)))])]}},{key:"item.admin_hours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.admin_hours)+"% ")]}},{key:"item.designer_hours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.designer_hours)+"% ")]}},{key:"item.developer_hours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.developer_hours)+"% ")]}},{key:"item.it_hours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.it_hours)+"% ")]}},{key:"item.video_hours",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.video_hours)+"% ")]}}],null,false,1882198519)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }