<template>
	<v-container fluid>

		<!-- 
		Employee type: full-time vs. part-time.
		Location would be fun if we put in everyone’s address. (maybe just zip)
		 -->

		<v-toolbar flat dense class="mb-4">
			<v-tabs v-model="tabs">
				<v-tab>
					Overview
				</v-tab>
				<v-tab>
					Overtime
				</v-tab>
                <v-tab>
                    Utilization
                </v-tab>
			</v-tabs>
		</v-toolbar>

		<v-tabs-items v-model="tabs">
			<v-tab-item>
				<overview />
			</v-tab-item>
			<v-tab-item>
				<overtime />
			</v-tab-item>
            <v-tab-item>
                <utilization />
            </v-tab-item>
		</v-tabs-items>
	</v-container>
</template>

<script>
	import overview from "@/views/analytics/staff/overview.vue";
	import overtime from "@/views/analytics/staff/overtime.vue";
	import utilization from "@/views/analytics/staff/utilization.vue";
	export default {
		components: { overview, overtime, utilization},
		name: "staff-analytics",
		data() {
			return {
				tabs: null,
			};
		},
	};
</script>


<style lang="scss" scoped>
	.v-tabs-items {
		background-color: transparent!important;
	}
</style>
