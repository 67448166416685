<template>
	<v-speed-dial v-model="fab" direction="bottom" transition="slide-y-reverse-transition" class="ml-sm-4">
		<template v-slot:activator>
			<v-btn color="primary" dark fab :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.smAndUp">
				<v-icon :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.smAndUp" v-if="fab">fal fa-times</v-icon>
				<v-icon :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.smAndUp" v-else>fal fa-plus</v-icon>
			</v-btn>
		</template>

		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab small color="primary" v-bind="attrs" v-on="on"
					   @click="emitToEventBus('app/menu/add-client')">
					<v-icon small>fal fa-user</v-icon>
				</v-btn>
			</template>
			<span>Add Client</span>
		</v-tooltip>

		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab small color="primary" v-bind="attrs" v-on="on"
					   @click="emitToEventBus('app/menu/add-project')">
					<v-icon small>fal fa-folders</v-icon>
				</v-btn>
			</template>
			<span>Add Project</span>
		</v-tooltip>

		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab small color="primary" v-bind="attrs" v-on="on"
					   @click="emitToEventBus('app/menu/add-task')">
					<v-icon small>fal fa-sticky-note</v-icon>
				</v-btn>
			</template>
			<span>Add Task</span>
		</v-tooltip>

		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab small color="primary" v-bind="attrs" v-on="on"
					   @click="emitToEventBus('app/menu/add-contact')">
					<v-icon small>fal fa-address-book</v-icon>
				</v-btn>
			</template>
			<span>Add Contact</span>
		</v-tooltip>

		<!-- <v-tooltip v-if="$store.getters.isAdminUser" left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab small color="primary" v-bind="attrs" v-on="on"
					   @click="emitToEventBus('app/menu/add-employee')">
					<v-icon small>fas fa-user-plus</v-icon>
				</v-btn>
			</template>
			<span>Add Employee</span>
		</v-tooltip> -->

		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab small color="" v-bind="attrs" v-on="on"
					   :to="{ name: 'meta.list' }">
					<v-icon small>fab fa-wpforms</v-icon>
				</v-btn>
			</template>
			<span>Metadata</span>
		</v-tooltip>

		<v-tooltip v-if="$store.getters.hasPermission('chuckit_dev')" left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab small color="" v-bind="attrs" v-on="on"
					   :to="{ name: 'permissions' }">
					<v-icon small>fal fa-key</v-icon>
				</v-btn>
			</template>
			<span>Permissions</span>
		</v-tooltip>

		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn fab small color="" v-bind="attrs" v-on="on"
					   @click="emitToEventBus('app/menu/show-db')">
					<v-icon small>fal fa-brackets-curly</v-icon>
				</v-btn>
			</template>
			<span>Debug $Store</span>
		</v-tooltip>

	</v-speed-dial>
</template>

<script>
	import { EventBus } from "../../utils/EventBus";

	export default {
		name: "extrasMenu",

		data() {
			return {
				fab: false,
				seePermissions: false,
			};
		},

		methods: {
			emitToEventBus(evt, ...args) {
				EventBus.$emit(evt, ...args);
			},
		},
	};
</script>