import { render, staticRenderFns } from "./tasksNotes.vue?vue&type=template&id=1fcf92f6&scoped=true&"
import script from "./tasksNotes.vue?vue&type=script&lang=js&"
export * from "./tasksNotes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fcf92f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/.pnpm/vuetify-loader@1.7.3_0f7bc6efe1949d16b55ed303eb0ec848/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VRow,VTab,VTabItem,VTabs,VTabsItems})
