var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',{staticClass:"mb-4",attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Communication Analytics")])],1),(_vm.myTotalProjects && _vm.myTotalProjects.total > 0)?[_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.$apollo.queries.myProjects.loading}},[_c('v-card-title',[_vm._v("My Projects "),_c('v-chip',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.myTotalProjects.total))])],1),_c('v-card-subtitle',[_vm._v("For on-going projects that don't need frequent communication, consider setting the project status to hidden.")]),(!_vm.$apollo.queries.myProjects.loading)?[(_vm.myProjects && !_vm.myProjects.nodes.length)?_c('v-card-text',[_vm._v(" Congrats, you are all caught up! ")]):_vm._e(),(_vm.myProjects && _vm.myProjects.nodes.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.myProjects.nodes,"dense":"","sort-by":"node.last_communication","sort-desc":true,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.node.last_communication",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.node.last_communication || "never")+" ")]}},{key:"item.node.account_executive_user.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"title":item.node.account_executive_user.full_name}},[_c('user-avataaar',{staticClass:"avatar mr-2",staticStyle:{"max-width":"35px"},attrs:{"uid":item.node.account_executive_user.uid}})],1)]}},{key:"item.node.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'project.details', params:{id: item.node.id}},"target":"_blank"},domProps:{"innerHTML":_vm._s(item.node.name)}})]}},{key:"item.node.client.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'client.details', params:{id: item.node.client.id}},"target":"_blank"},domProps:{"innerHTML":_vm._s(item.node.client.name)}})]}}],null,false,1817098040)}):_vm._e()]:_vm._e()],2)]:_vm._e(),_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.$apollo.queries.otherProjects.loading}},[_c('v-card-title',[(_vm.myTotalProjects && _vm.myTotalProjects.total > 0)?_c('span',[_vm._v("Other")]):_vm._e(),_vm._v(" Projects "),(_vm.otherProjects && _vm.otherProjects.nodes.length)?_c('v-chip',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.otherProjects.nodes.length))]):_vm._e()],1),(!_vm.$apollo.queries.otherProjects.loading)?[(_vm.otherProjects && !_vm.otherProjects.nodes.length)?_c('v-card-text',[_vm._v(" All is well. ")]):_vm._e(),(_vm.otherProjects && _vm.otherProjects.nodes.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.otherProjects.nodes,"dense":"","sortBy":"node.last_communication","sortDesc":true,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.node.last_communication",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.node.last_communication || "never")+" ")]}},{key:"item.node.account_executive_user.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"title":item.node.account_executive_user.full_name}},[_c('user-avataaar',{staticClass:"avatar mr-2",staticStyle:{"max-width":"35px"},attrs:{"uid":item.node.account_executive_user.uid}})],1)]}},{key:"item.node.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'project.details', params:{id: item.node.id}},"target":"_blank"},domProps:{"innerHTML":_vm._s(item.node.name)}})]}},{key:"item.node.client.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'client.details', params:{id: item.node.client.id}},"target":"_blank"},domProps:{"innerHTML":_vm._s(item.node.client.name)}})]}}],null,false,3196452658)}):_vm._e()]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }