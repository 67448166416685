<template>
	<div class="tree-item" :style="{'--depth': depth}">

		<div v-if="depth > 0 && !node.deleted" class="d-flex align-end underline checkbox-line">
			<v-checkbox dense v-model="node.checked" @change="$emit('changed')" class="ma-0" hide-details></v-checkbox>
			<debounce-events :events="{ change: 500 }" v-slot="{ change }">
				<v-text-field color="green accent-3" class="no-underline" dense type="text" v-model="label" hide-details @change="change(() => updateLabel($event))"></v-text-field>
			</debounce-events>
			<v-btn text x-small color="primary" class="ml-auto text-none" @click.prevent.stop.capture="confirmDelete(node)">
				<v-icon small>fad fa-trash-alt</v-icon>
			</v-btn>
		</div>

		<!-- items displayed here -->
		<div v-if="node.children && node.children.length" class="tree">
			<check-list-tree-item v-for="(child, index) in node.children" :key="index" :node="child" :depth="depth + 1" @changed="$emit('changed')" @delete="onDelete"></check-list-tree-item>
		</div>

		<!-- new item added to bottom / appended to .children -->
		<div class="my-1 add-item underline" v-if="depth < 2">
			<v-text-field color="green accent-3" prepend-icon="fas fa-plus" dense class="pt-0 mt-0" v-model="newItem" @keyup.enter="appendNewItem" hide-details :placeholder="'Type here to add a new ' + (depth ? 'subitem' : 'item')"></v-text-field>
		</div>

	</div>
</template>

<script>
	/**
	 * This component displays a single tree item and then all of its children.
	 * Events from child nodes are forwarded to their parents.
	 * Data modifications are made directly against the 'node' property.
	 * node should look like this:
	 * {
	 *   label: String, // the label
	 *   children: [
	 *     <node>, <node>, ...
	 *   ],
	 *   checked: Boolean
	 * }
	 */
	export default {
		name: "checkListTreeItem",
		props: ['node', 'depth'],
		data() {
			return {
				label: this.node.label,
				newItem: ''
			};
		},
		computed: {

		},
		methods: {
			confirmDelete() {
				if (confirm("Delete this entry?")) {
					this.$emit('delete', this.node);
					return true;
				}

				return false;
			},

			onDelete(childNode) {
				let ndx = this.node.children.indexOf(childNode);
				this.node.children.splice(ndx, 1);
				this.$emit('changed');
			},
			updateLabel(newLabel) {
				if (!newLabel) {
					if (this.confirmDelete()) {
						return;
					}

					this.label = this.node.label;
					return;
				}

				this.node.label = newLabel;
				this.$emit('changed');
			},
			appendNewItem() {
				if (this.newItem) {
					let newItem = {
						label: this.newItem
					};

					if (!this.node.children) {
						Vue.set(this.node, 'children', []);
					}

					this.node.children.push(newItem);
					this.newItem = '';
					this.$emit("changed");
				}
			},
		},

		watch: {
			node(to) {
				this.label = to.label;

				if (to.children) {
					// Remove deleted
					let filteredChildren = to.children.filter(n => !n.deleted);
					if (filteredChildren.length != to.children.length) {
						Vue.set(this.node, 'children', filteredChildren);
						this.$emit('changed');
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.add-item {
		::v-deep .v-icon {
			color: map-get($green, "accent-3");
			font-size: 12px;
		}
	}
	::v-deep .v-text-field .v-input__slot::before {
		display: none !important;
	}
	.tree-item {
		> .underline {
			padding-bottom: 5px;
			border-bottom: 1px solid map-get( $grey, "darken-3" );
		}
	}

	.checkbox-line {
		padding-left: calc(var(--depth) * 1.5rem);
	}

	.add-item {
		padding-left: calc((var(--depth) + 1) * 1.5rem);
	}
</style>