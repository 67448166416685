<script>
	export default {
		props: {
			tag: String,

			page: {
				type: Number,
				default: 1
			},
			pageSize: {
				type: Number,
				default: 10
			},
			totalItems: Number,

			params: {
				type: Object,
				default() {
					return {};
				}
			},

			rawParams: {
				type: Boolean,
				default: false
			}
		},

		data() {
			let curParams = {};
			if (this.params) {
				for (let i in this.params) {
					this.$set(curParams, i, this.params[i]);
				}
			}

			return {
				curTotalItems: this.totalItems,
				curPage: this.page,
				curPageSize: this.pageSize,
				curParams
			};
		},

		computed: {
			curOffset() {
				return (this.curPage - 1) * this.curPageSize;
			},
			numPages() {
				return Math.ceil((this.curTotalItems || 0) / this.curPageSize);
			}
		},

		methods: {
			setPageSize(limit, allowAll = false) {
				if (!allowAll && limit < 1) {
					limit = this.pageSize;
				}

				this.curPageSize = limit;
				this.page = 1;
			},
			incPage() {
				this.curPage++;
			},
			decPage() {
				this.curPage--;
			},
			setPage(p) {
				p = Math.max(1, p);
				if (this.numPages) {
					p = Math.min(this.numPages, p);
				}

				this.curPage = p;
			},
			setTotalItems(total) {
				this.curTotalItems = total;
			},
			setParam(name, value, resetPage = true) {
				/* if (!this.rawParams) {
					if (value == null) {
						value = '';
					}
					else if (typeof value != 'string') {
						value = '' + value;
					}
				} */

				this.$set(this.curParams, name, value);

				if (resetPage) {
					this.curPage = 1;
				}
			}
		},

		render(h) {
			let result = this.$scopedSlots.default({
				page: this.curPage,
				offset: this.curOffset,
				pageSize: this.curPageSize,
				setPageSize: this.setPageSize,
				params: this.curParams,
				setParam: this.setParam,
				numPages: this.numPages,
				incPage: this.incPage,
				decPage: this.decPage,
				setPage: this.setPage,
				setTotalItems: this.setTotalItems
			});

			if (this.tag) {
				result = h(this.tag, result);
			}

			return result;
		},

		watch: {
			pageSize(limit) {
				this.curPageSize = limit;
			},

			curPageSize(to) {
				if (to != this.curPageSize) {
					this.$emit('update:page-size', to);
				}
			},

			page(page) {
				this.curPage = page;
			},

			curPage(to) {
				if (to != this.page) {
					this.$emit('update:page', to);
				}
			},

			totalItems(total) {
				this.curTotalItems = total;
			},

			curTotalItems(to) {
				if (to != this.totalItems) {
					this.$emit('update:total-items', to);
				}
			},

			params: {
				deep: true,
				immediate: true,
				handler(to) {
					if (to) {
						for (let i in to) {
							this.$set(this.curParams, i, to[i]);
						}
					}
				}
			}
		}
	}
</script>