export const ModelMap = {
	client: {
		projects: 'project',
		notes: 'note',
		contacts: 'person',
		meta: 'meta_value'
	},
	project: {
		client: 'client',
		account_executive: 'person',
		tasks: 'task',
		openTasks: 'task',
		notes: 'note',
		contacts: 'person',
		meta: 'meta_value',
		deadlinesAndReminders: 'note'
	},
	person: {
		notes: 'note',
		timers: 'timer',
		delegatedTasks: 'task',
		tasks: 'task',
		clients: 'client',
		projects: 'project',
		meta: 'meta_value'
	},
	task: {
		parent: 'task',
		assignedToUser: 'person',
		assignedByUser: 'person',
		project: 'project',
		client: 'client',
		subtasks: 'task',
		timers: 'timer',
		current_timer: 'timer',
		meta: 'meta_value'
	},
	note: {
		Client: 'client',
		Project: 'project',
		Person: 'person',
		authorPerson: 'person',
		author: 'person',
		meta: 'meta_value'
	},
	timer: {
		person: 'person',
		task: 'task',
		project: 'project',
		client: 'client',
		meta: 'meta_value'
	}
};