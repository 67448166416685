<template>
	<v-card :loading="$apollo.queries.hours.loading">
		<v-card-title>
			Activity Timeline

			<div class="mx-auto my-1 d-flex align-center">
				<div v-for="(name, key) in departments" :key="key" class="d-flex align-center caption ml-3">
					<v-sheet width="10" height="10" class="mr-2" :color="name | lowercase"></v-sheet> {{name}}
				</div>
			</div>

			<v-btn-toggle mandatory v-model="displayOptions.type" class="ma-1">
				<v-btn x-small value="daily" class="text-none">Daily</v-btn>
				<v-btn x-small value="monthly" class="text-none">Monthly</v-btn>
			</v-btn-toggle>

			<div class="d-flex">
				<v-menu ref="dateFromMenu" v-model="dateFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="displayOptions.dateRange.after" label="Date From" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" clearable dense hide-details class="mx-1 caption" readonly></v-text-field>
					</template>
					<v-date-picker v-model="displayOptions.dateRange.after" no-title scrollable color="primary" @input="dateFromMenu = false"></v-date-picker>
				</v-menu>

				<v-menu ref="dateToMenu" v-model="dateToMenu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="displayOptions.dateRange.before" label="Date To" prepend-icon="fad fa-calendar" v-bind="attrs" v-on="on" clearable dense hide-details class="mx-1 caption" readonly></v-text-field>
					</template>
					<v-date-picker v-model="displayOptions.dateRange.before" no-title scrollable color="primary" @input="dateToMenu = false"></v-date-picker>
				</v-menu>
			</div>
		</v-card-title>
		<v-card-subtitle>The following data is inaccurate and will be replaced in the future</v-card-subtitle>
		<v-card-text>
			<GChart v-if="chartData && chartData.length > 1" type="ColumnChart" :data="chartData" :options="chartOptions" class="chart" :class="{busy: $apollo.queries.hours.loading}" />
			<v-alert v-else color="error">Not enough data. Please expand your dates</v-alert>
		</v-card-text>
	</v-card>
</template>

<script>
	import qs from "qs";
	import { IdType } from "../../../utils/IdType";
	import moment from "moment";

	export default {
		// @see https://developers-dot-devsite-v2-prod.appspot.com/chart/interactive/docs/gallery/columnchart.html#configuration-options
		name: "chartProjectActivityTimeline",
		data() {
			return {
				dateFromMenu: false,
				dateToMenu: false,
				displayOptions: {
					projectId: this.pid,
					type: "daily",

					dateRange: {
						after: moment().startOf("day").subtract(3, "month").format("YYYY-MM-DD"),
						before: moment().endOf("day").format("YYYY-MM-DD"),
					},
				},

				numChartPoints: 0,
				// displayedDepartments: {},
			};
		},
		props: {
			pid: IdType,
		},
		apollo: {
			hours: {
				query: gql`
					query getHoursByDepartmentByPeriod(
						$id: ID!
						$period: ProjectAnalyticsGroupPeriod!
						$format: String
						$before: DateTime!
						$after: DateTime!
					) {
						hours: project(id: $id) {
							id
							analytics {
								time_spent_by_department_by_period(
									period: $period
									before: $before
									after: $after
								) {
									date @formatDate(format: $format)
									department
									time_spent
										@formatDuration(format: "H.2", number: true)
								}
							}
						}
					}
				`,
				variables() {
					return {
						id: this.pid,
						period: this.displayOptions.type,
						after:
							this.displayOptions.dateRange.after,
						before:
							this.displayOptions.dateRange.before,
						format:
							this.displayOptions.type == "daily" ? "Y/m/d" : "Y/m",
					};
				},
				skip() {
					return !this.pid;
				},
			},
		},
		computed: {
			departments() {
				if (!this.hours) {
					return [];
				}

				return Linq.from(
					this.hours.analytics.time_spent_by_department_by_period
				)
					.select((i) => i.department)
					.distinct()
					.orderBy((i) => i)
					.toArray();
			},
			chartData() {
				if (!this.hours) {
					return null;
				}

				return Linq.from(
					this.hours.analytics.time_spent_by_department_by_period
				)
					.groupBy(
						(i) => i.date,
						(date, hours) => {
							let dayCounts = new Array(this.departments.length).fill(
								0
							);
							for (let i of hours) {
								let key = this.departments.indexOf(i.department);
								dayCounts[key] = i.time_spent;
							}

							return [date, ...dayCounts];
						}
					)
					.prepend(["Date", ...this.departments])
					.toArray();
			},
			chartOptions() {
				let series = Linq.from(this.departments)
					.select((d) => ({
						color: this.$vuetify.theme.currentTheme[d.toLowerCase()],
					}))
					.toObject(
						(c, i) => i,
						(c, i) => c
					);

				return {
					backgroundColor: { fillOpacity: 0 },
					chartArea: {
						width: "100%",
						// width:  this.numChartPoints * 60,
						height: "80%",
						left: 60,
						top: 10,
					},
					bar: {
						groupWidth: '50%',
					},
					legend: { position: "none" },
					hAxis: {
						textPosition: "out",
						textStyle: {
							color: this.$vuetify.theme.currentTheme["anchor"],
							bold: true,
						},
					},
					vAxis: {
						title: "Hours",
						baselineColor: this.$vuetify.theme.currentTheme["dividers"],
						textPosition: "out",
						gridlines: {
							// minSpacing: 25,
							color: this.$vuetify.theme.currentTheme["dividers"],
						},
						textStyle: {
							color: this.$vuetify.theme.currentTheme["anchor"],
							bold: true,
						},
						minorGridlines: {
							// deprecated but will force gridlines to disappear
							count: 0,
							color: this.$vuetify.theme.currentTheme["dividers"],
						},
					},
					isStacked: false,
					series: series,
				};
			},
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
	.busy {
		opacity: 0.25;
	}

	.spinner {
		position: absolute;
		width: 100%;
		text-align: center;
		top: 50%;
	}
</style>
