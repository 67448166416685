export function parseParams(params) {
	let result = {};
	if (params) {
		Object.assign(result, params);
		result.options = Linq.from((result.options || '').split('&'))
			.where(i => i != '')
			.select(i => i.split('='))
			.toObject(i => i[0], i => decodeURIComponent(i[1]));
	}

	return result;
}

export function stringifyParams(params, preserveEmpty = false) {
	let result = {};

	if (params) {
		Object.assign(result, params);
		result.options = Linq.from(Object.entries(result.options));

		if (!preserveEmpty) {
			result.options = result.options
				.where(i => i[1]);
		}

		result.options = result.options
			.select(i => {
				if (typeof i[1] == 'boolean') {
					i[1] = i[1] ? 1 : 0;
				}

				return i;
			})
			.select(i => `${i[0]}=${encodeURIComponent(i[1])}`)
			.joinString('&');
		
		if (result.options == '') {
			result.options = null;
		}
	}

	return result;
}