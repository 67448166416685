<script>
	export default {
		props: {
			tag: String,

			data: {
				type: [Object, Array]
			}
		},

		data() {
			let realData = {};
			if (Array.isArray(this.data)) {
				for (let key of this.data) {
					realData[key] = undefined;
				}
			}
			else {
				for (let i in this.data) {
					realData[i] = this.data[i];
				}
			}

			return {
				realData
			};
		},

		render(h) {
			let result = this.$scopedSlots.default(this.realData);

			if (this.tag) {
				result = h(this.tag, result);
			}

			return result;
		}
	}
</script>