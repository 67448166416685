<template>
    <div class="d-flex">
        <slot></slot>
        <v-btn-toggle mandatory v-model="presetDateRange">
            <v-btn v-for="option in displayedPresetOptions" :key="option.text" small :value="option.value" class="text-none">
                {{ option.text }}
            </v-btn>
        </v-btn-toggle>
        <v-menu ref="rangeMenu" v-model="rangeMenu" :close-on-content-click="false" :return-value.sync="selectedDateRange" offset-y min-width="auto" bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="displaySelectedDateRange"
                              hide-details
                              dense
                              class="mx-4 mt-0"
                              prepend-icon="fad fa-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"></v-text-field>
            </template>

            <v-date-picker v-model="calDates"   no-title range scrollable color="primary" :max="maxDate">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="cancel">
                    Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.rangeMenu.save(calDates)">
                    OK
                </v-btn>
            </v-date-picker>
        </v-menu>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name:             "dateRangePicker",
    props:            {
        value:   Array,
        maxDate: {
            type:    String,
            default: moment().format("YYYY-MM-DD")
        },
        presets: {
            type:    Array,
            default: () => ['Last Year', 'Last Month', 'Last Week', 'Custom']
        }
    },
    dateRangeOptions: [
        {
            text:  "Last Year",
            value: [moment().subtract(1, 'years').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        },
        {
            text:  "Last Month",
            value: [moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        },
        {
            text:  "Last Week",
            value: [moment().subtract(1, "weeks").format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
        },
        {
            text:  "Custom",
            value: "custom",
        },
    ],
    data() {
        return {
            presetDateRange:   "month",
            rangeMenu:         false,
            selectedDateRange: [],
            calDates:          [],
            oldPresetRange:    null,
        }
    },
    computed: {
        displayedPresetOptions() {
            return this.presets.map(name => this.$options.dateRangeOptions.find(i => i.text === name));
        },
        displaySelectedDateRange() {
            return this.calDates.join(' to ');
        },
    },
    methods:  {

        cancel() {
            this.rangeMenu = false;
            this.calDates = this.selectedDateRange;
            this.presetDateRange = this.oldPresetRange;
        }
    },
    watch:    {
        presetDateRange:   function (to) {
            if (to !== 'custom') {
                this.selectedDateRange = to;
            }
        },
        calDates:          function (to) {
            if (to.length === 2 && to[0] > to[1]) {
                to.reverse();
            }
        },
        rangeMenu:         function (to) {
            if (to) {
                this.oldPresetRange = this.presetDateRange;
                this.presetDateRange = 'custom';
            }
        },
        selectedDateRange: function (to) {
            this.calDates = to;
            this.$emit('input', to)
        },
    }
}
</script>

<style scoped lang="scss">
.calendar-title {
    border-bottom: none;
}
</style>
