<template>
	<div v-if="project">
		<tasks-summary v-if="projectExtra" :taskCounts="projectExtra.task_counts" class="mb-2"></tasks-summary>
		<v-row dense>
			<v-col cols="12" sm="6">
				<chart-assigned-tasks-by-department :pid="project.id" class="fill-height"></chart-assigned-tasks-by-department>
			</v-col>

			<v-col cols="12" sm="6" class="d-flex flex-column">
				<project-status-hours :pid="project.id" class="mb-2"></project-status-hours>
				<hours class="flex-grow-1"></hours>
			</v-col>
		</v-row>
		<chart-project-milestone :pid="project.id" class="my-2"></chart-project-milestone>
		<chart-project-activity-timeline :pid="project.id" class="mb-2"></chart-project-activity-timeline>
		<chart-project-gantt :pid="project.id" class="mb-2"></chart-project-gantt>
	</div>
	<div v-else>no data</div>
</template>

<script>
	import tasksSummary from "../components/tasksSummary";
	import chartAssignedTasksByDepartment from "../components/chartAssignedTasksByDepartment";
	import chartProjectGantt from "../components/chartProjectGantt";
	import chartProjectMilestone from "../components/chartProjectMilestone";
	import chartProjectActivityTimeline from "../components/chartProjectActivityTimeline";
	import ProjectStatusHours from "../components/projectStatusHours";
	import hours from "../components/hours";

	export default {
		inject: ["projectContext"],
		components: {
			tasksSummary,
			chartAssignedTasksByDepartment,
			chartProjectActivityTimeline,
			chartProjectGantt,
			chartProjectMilestone,
			ProjectStatusHours,
			hours,
		},
		apollo: {
			projectExtra: {
				query: gql`
					query GetProject_LegacyView($id: ID!) {
						projectExtra: project(id: $id) {
							id
							task_counts {
								total
								assigned
								unassigned
								completed
							}
							_meta_types
							_meta_values
							current_tasks {
								assigned_to {
									full_name
									department @lowercase
								}
							}
						}
					}
				`,
				variables() {
					return {
						id: this.project.id,
					};
				},
				skip() {
					return !this.project;
				},
			},
		},
		computed: {
			project() {
				return this.projectContext.project;
			}
		},
	};
</script>
