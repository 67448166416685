import { render, staticRenderFns } from "./clientsAndProjects.vue?vue&type=template&id=7b410db0&scoped=true&"
import script from "./clientsAndProjects.vue?vue&type=script&lang=js&"
export * from "./clientsAndProjects.vue?vue&type=script&lang=js&"
import style0 from "./clientsAndProjects.vue?vue&type=style&index=0&id=7b410db0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b410db0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_0f7bc6efe1949d16b55ed303eb0ec848/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardText,VCardTitle,VCheckbox,VChip,VContainer,VDivider,VIcon,VList,VListItem,VListItemTitle,VMenu,VSpacer,VTab,VTabs,VTextField,VToolbar,VToolbarTitle,VTooltip})
