<template>
	<v-sheet>
		<debounce-events :events="{ change: 500 }" v-slot="{ change }">
			<v-text-field
				:value="filters.search"
				@input="change(() => changeSearch($event))"
				clearable hide-details
				label="Search Notes"
				class="my-0 mb-3"></v-text-field>
		</debounce-events>

		<v-btn-toggle
			:value="filters.types"
			@change="changeTypes($event)"
			dense multiple group
			class="flex-wrap"
			color="primary"
		>
			<v-btn v-for="row in totals" :key="row.note_type" :disabled="loading" small :value="row.note_type">
				{{noteTypeName(row.note_type)}} ({{row.total}})
			</v-btn>
		</v-btn-toggle>
	</v-sheet>
</template>

<script>
	export default {
		model: {
			prop: 'filters',
			event: 'input'
		},

		props: {
			loading: {
				type: Boolean,
				default: false
			},

			totals: {
				required: true
			},

			filters: {
				required: true,
				validator(v) {
					if (typeof v != 'object') {
						return false;
					}

					if (typeof v.search != 'string') {
						return false;
					}

					if (!Array.isArray(v.types)) {
						return false;
					}

					if (v.types.some(i => typeof i != 'string')) {
						return false;
					}

					return true;
				}
			}
		},

		methods: {
			changeSearch(search) {
				this.$emit('input', {
					search,
					types: this.filters.types.slice(0)
				});
			},

			changeTypes(types) {
				this.$emit('input', {
					search: this.filters.search,
					types
				});
			},

			noteTypeName(type) {
				switch (type) {
					case 'client_from':
						return "From Client";
					case 'client_to':
						return "To Client";
					case 'task_completed':
						return 'Task';

					default:
						return type;	
				}
			}
		}
	}
</script>