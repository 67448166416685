/*!
	JS Helpeful Keydown by Daniel Flynn
	v 1.0.0
*/

(function($) {
	var map = {
		192: [96, 126, 'Grave Accent', 'Tilde'],
		49: [49, 33, 'One', 'Exclamation Mark'],
		50: [50, 64, 'Two', 'At Symbol'],
		51: [51, 35, 'Three', 'Number'],
		52: [52, 36, 'Four', 'Dollar'],
		53: [53, 37, 'Five', 'Percent'],
		54: [54, 94, 'Six', 'Caret'],
		55: [55, 38, 'Seven', 'Ampersand'],
		56: [56, 42, 'Eight', 'Asterisk'],
		57: [57, 40, 'Nine', 'Open Parenthesis'],
		48: [48, 41, 'Zero', 'Close Parenthesis'],
		189: [45, 95, 'Dash', 'Underscore'],
		187: [61, 43, 'Equals', 'Plus'],
		219: [91, 123, 'Open Bracket', 'Open Brace'],
		221: [93, 125, 'Close Bracket', 'Close Brace'],
		220: [92, 124, 'Back Slash', 'Vertical Bar'],
		186: [59, 58, 'Semicolon', 'Colon'],
		222: [39, 34, 'Single Quote', 'Double Quote'],
		188: [44, 60, 'Comma', 'Less Than'],
		190: [46, 62, 'Period', 'Greater Than'],
		191: [47, 63, 'Forward Slash', 'Question Mark'],
		32: [' ', 'Space', 32],

		27: 'Escape',
		112: 'F1',
		113: 'F2',
		114: 'F3',
		115: 'F4',
		116: 'F5',
		117: 'F6',
		118: 'F7',
		119: 'F8',
		120: 'F9',
		121: 'F10',
		122: 'F11',
		123: 'F12',
		145: 'Scroll Lock',
		19: 'Pause/Break',

		8: ['', 'Backspace', 8],
		9: ['', 'Tab', 9],
		13: ['', 'Enter', 13],
		16: 'Shift',
		17: 'Ctrl',
		18: 'Alt',
		20: 'Caps Lock',
		91: 'Windows Key',
		93: 'Select Key',

		45: 'Insert',
		46: 'Delete',
		36: 'Home',
		35: 'End',
		33: 'Page Up',
		34: 'Page Down',

		37: 'Left Arrow',
		38: 'Up Arrow',
		39: 'Right Arrow',
		40: 'Down Arrow',

		144: 'Num Lock',
		12: 'Clear',
		111: ['/', 'Forward Slash (Num Pad)', 47],
		106: ['*', 'Asterisk (Num Pad)', 42],
		109: ['-', 'Dash (Num Pad)', 45],
		107: ['+', 'Plus (Num pad)', 43],
		110: ['.', 'Period (Num Pad)', 46],
		96: ['0', 'Zero (Num Pad)', 48],
		97: ['1', 'One (Num Pad)', 49],
		98: ['2', 'Two (Num Pad)', 50],
		99: ['3', 'Three (Num Pad)', 51],
		100: ['4', 'Four (Num Pad)', 52],
		101: ['5', 'Five (Num Pad)', 53],
		102: ['6', 'Six (Num Pad)', 54],
		103: ['7', 'Seven (Num Pad)', 55],
		104: ['8', 'Eight (Num Pad)', 56],
		105: ['9', 'Nine (Num Pad)', 57]
	};

	$.fn.helpfulKeypress = function(func) {
		$(this).each(function() {
			var that = $(this),
				key = 0,
				ignorekeypress = false;

			that.on('keydown.hk', function(e) {
				// Not a letter key
				if (e.keyCode < 65 || e.keyCode > 90)
				{
					ignorekeypress = true;
					var rules = map[e.keyCode];

					if (typeof rules == 'string')
					{
						e.keyName = rules;
						e.ascii = false;
						e.key = '';
					}
					else
					{
						if (rules.length == 4)
						{
							e.keyName = rules[2 + (e.shiftKey ? 1 : 0)];
							e.ascii = rules[e.shiftKey ? 1 : 0];
							e.key = String.fromCharCode(e.ascii)
						}
						else
						{
							e.keyName = rules[1];
							e.ascii = rules[2];
							e.key = rules[0];
						}
					}

					delete e.which;
					delete e.charCode;
					func.call(this, e);
				}
				// Letter key - needs a keypress event to really know
				else
				{
					ignorekeypress = false;
					key = e.keyCode;
				}
			});
			that.on('keypress.hk', function(e) {
				if (ignorekeypress)
					return;

				// Only letter keys make it this far
				e.ascii = e.keyCode || e.which;
				e.keyCode = key;
				e.key = String.fromCharCode(e.ascii);
				e.keyName = e.key.toUpperCase();

				delete e.which;
				delete e.charCode;
				func.call(this, e);
			});
		});
	};
})(jQuery);