import { render, staticRenderFns } from "./node.vue?vue&type=template&id=b6407e12&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.6_fb85a9817f99bdf862e5e3a442a1fb91/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports