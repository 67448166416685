<template>

	<v-container v-if="ready" fluid class="fill-height align-content-start">

		<v-row class="justify-start align-content-start" dense>

			<!-- region Timers - In Progress Tasks Column -->
			<div :class="timersSectionClasses" class="col-12 card-col-progress order-lg-2">
				<div class="sticky-header d-flex align-center justify-space-between flex-wrap">
					<div class="my-2 d-flex justify-center">
						<h2>In Progress</h2>
						<v-btn-toggle v-model="timerLayoutSelected" dense group class="ml-3" color="primary">
							<v-btn small value="grid layout-grid-sm">
								<v-icon small>fas fa-th</v-icon>
							</v-btn>
							<v-btn small value="grid">
								<v-icon small>fas fa-th-large</v-icon>
							</v-btn>
							<v-btn small value="rows">
								<v-icon small>fas fa-bars</v-icon>
							</v-btn>
						</v-btn-toggle>
					</div>

					<div class="d-flex">
						<div class="btn-group">
							<v-btn small @click.exact="createQuickTimer()">Add Quicktimer</v-btn>
							<v-menu offset-y bottom left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn small v-bind="attrs" v-on="on" min-width="0" class="mr-3">
										<v-icon>fal fa-angle-down</v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item v-for="(opt, index) in quickTimerOptions" :key="index">
										<v-btn tile small class="js-action flex-grow-1" @click="createQuickTimer(opt)">{{opt.name}}</v-btn>
										<v-btn tile small color="primary" @click.prevent.stop="deleteQuickTimerOption(index)" min-width="0"><i class="fas fa-trash"></i></v-btn>
									</v-list-item>
									<v-list-item>
										<v-btn small block @click="createQuickTimerOption" min-width="0">Add New</v-btn>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
						<v-btn small light color="primary" class="btn-submit-all" title="Submit All Timers" @click="submitAllTimers" :class="[!canSubmitAll ? 'disabled' : '']">
							<span class="d-none d-md-inline">Submit All</span>
							<i class="fad fa-external-link ml-1"></i>
						</v-btn>
					</div>
				</div>

				<div class="d-flex flex-row align-content-start justify-start flex-wrap">
					<timer-card v-for="timer in currentTimers" :key="timer.id" :timer="timer" :class="`layout-${timerLayoutSelected}`" :highlight-un-completable="highlightUnCompletableTimers" ref="timerCards"></timer-card>
					<no-timers v-if="!$store.getters.hasTimers"></no-timers>
				</div>
			</div>
			<!--endregion-->

			<!-- region Tasks - Current / Pending Tasks Column -->
			<div :class="tasksSectionClasses" class="col order-lg-1">
				
				<div class="sticky-header d-flex align-center justify-space-between flex-wrap">
					<debounce-events :events="{ change: 1000 }" v-slot="{ change }">
						<v-text-field hide-details clearable dense placeholder="Search" :value="searchTxt" @input="change(() => searchTxt = $event)" class="mt-0 pt-0 mr-md-3 mr-lg-0 mb-3 mb-lg-0"></v-text-field>
					</debounce-events>
					<div class="mt-3 d-flex">
						<div class="side-filter d-flex align-center">
							<v-btn small min-width="0" :class="{active: !sidebarNarrowLayoutEnabled}" @click="sidebarNarrowLayoutEnabled = !sidebarNarrowLayoutEnabled" class="mx-3">
								<v-icon v-if="sidebarNarrowLayoutEnabled" small>fad fa-arrow-to-right</v-icon>
								<v-icon v-else small>fad fa-arrow-to-left</v-icon>
							</v-btn>
							<!-- sidebar layout options, only visible when sidebar is not narrow -->
							<v-btn-toggle v-if="!sidebarNarrowLayoutEnabled" mandatory v-model="tasksLayoutSelected" dense group color="primary" class="mr-3">
								<v-btn small value="grid">
									<v-icon x-small>fas fa-th-large</v-icon>
								</v-btn>

								<v-btn small value="list">
									<v-icon x-small>fas fa-line-height</v-icon>
								</v-btn>

								<v-btn small value="rows">
									<v-icon x-small>fas fa-bars</v-icon>
								</v-btn>
							</v-btn-toggle>
						</div>
					</div>

					<div v-if="tasks && taskLegendItems" class="mt-3">
						<v-btn-toggle v-model="taskFilters" dense multiple dark>
							<v-btn small v-for="item in taskLegendItems" :key="item.value" :color="item.value"  :value="item.value">
								{{item.name}} <span class="ml-2 ">{{item.count}}</span>
							</v-btn>
						</v-btn-toggle>
					</div>
				</div>

				<v-tabs v-model="selectedTab" fixed-tabs class="mt-4">
					<v-tab>Current ({{currentTasks.length}})</v-tab>
					<v-tab>Pending ({{pendingTasks.length}})</v-tab>
					<!-- <v-tab>KB</v-tab> -->
				</v-tabs>

				<v-tabs-items v-model="selectedTab" class="mt-1">

					<!-- current -->
					<v-tab-item>
						<!-- standard -->
						<div class="task-card-view-regular" v-if="!isTaskCardLayoutSlim">
							<div class="d-flex align-stretch flex-wrap">
								<template v-for="task in currentTasks">
									<task-card :key="task.id" :task="task" :class="taskCardClasses"></task-card>
								</template>
							</div>
						</div>
						<!-- slim -->
						<div class="task-card-view-slim container-fluid" v-if="isTaskCardLayoutSlim">
							<!-- "table" headers -->
							<div class="row task-list-header font-weight-bold pb-2">
								<div class="col-2 col-client">Client</div>
								<div class="col-2 col-project">Project</div>
								<div class="col-4 col-task">Task</div>
								<div class="col-2 col-due-date">Due Date</div>
								<div class="col-2 col-assigned-by">Assigned by</div>
							</div>
							<template v-for="task in currentTasks">
								<task-card-slim :key="`slim-${task.id}`" :task="task"></task-card-slim>
							</template>
						</div>
					</v-tab-item>

					<!-- pending -->
					<v-tab-item>
						<!-- standard -->
						<div class="task-card-view-regular" v-if="!isTaskCardLayoutSlim">
							<div class="d-flex flex-row align-stretch flex-wrap">
								<template v-for="task in pendingTasks">
									<task-card :key="task.id" :task="task" :class="taskCardClasses"></task-card>
								</template>
							</div>
						</div>
						<!-- slim -->
						<div class="task-card-view-slim container-fluid" v-if="isTaskCardLayoutSlim">
							<!-- "table" headers -->
							<div class="row task-list-header font-weight-bold pb-2">
								<div class="col-2 col-client">Client</div>
								<div class="col-2 col-project">Project</div>
								<div class="col-4 col-task">Task</div>
								<div class="col-2 col-due-date">Due Date</div>
								<div class="col-2 col-assigned-by">Assigned by</div>
							</div>
							<template v-for="task in pendingTasks">
								<task-card-slim :key="`slim-${task.id}`" :task="task"></task-card-slim>
							</template>
						</div>
					</v-tab-item>
				</v-tabs-items>

			</div>
			<!--endregion-->

		</v-row>

	</v-container>
	<v-container v-else class="fill-height flex-column justify-center align-center">
		<p class="text-h6">Loading Tasks</p>
		<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
	</v-container>

</template>

<script>
	import taskCard from "../components/taskCards/taskCard-1";
	import taskCardSlim from "../components/taskCards/taskCard-slim";
	import timerCard from "../components/timerCard";
	import UserModel from "../models/user";
	import noTimers from "../components/noTimers";

	import moment from "moment";

	export default {
		name: "tasks",

		components: {
			taskCard,
			timerCard,
			taskCardSlim,
			noTimers,
		},

		data() {
			return {
				ready: false,
				addNoteTaskId: false,
				// refreshTasks: false,
				tasksOverdue: true,
				taskFilters: this.getUserSetting("taskFilters", [
					"overdue",
					"today",
					"soon",
					"later",
				]),
				quickTimerHover: false,

				// timer/inprogress layout settings
				timerLayoutSelected: this.getUserSetting("layout", "grid"),

				// tasks current/pending layout options
				tasksLayoutSelected: this.getUserSetting("tasks-layout", "rows"),

				// tasks current/pending column width settings
				sidebarNarrowLayoutEnabled: this.getUserSetting(
					"sidebar-layout",
					true
				),

				tasksCol: "col-lg-3",
				timerCol: "col-lg-9",

				highlightUnCompletableTimers: false,

				searchTxt: "",
				selectedTab: null
			};
		},

		asyncComputed: {
			tasksLoaded: {
				get() {
					return new Promise((resolve, reject) => {
						let params = {
							userId: this.$store.getters.userData.id,
						};
						console.info("[tasksLoaded get] begin");
						UserModel.getTasks(
							params,
							"*,meta,ProjectHoursAllocated,confirmed_time,timeline_due_date"
						).then(
							(payload) => {
								this.$db.addModels("task", payload.json, "tasks");
								console.info("[tasksLoaded] done");
								this.ready = true;
								resolve(true);
							},
							(payload) => {
								reject("Error retrieving tasks");
							}
						);
					});
				},

				// watch() {
				// 	return this.refreshTasks;
				// },
			},
		},

		computed: {
			taskCardClasses() {
				return "layout-" + this.tasksLayoutSelected;
			},

			isTaskCardLayoutSlim() {
				return this.tasksLayoutSelected === "list";
			},
			// For the caching ability
			tasks() {
				// console.info('[tasks] begin ');
				let tasks = this.$db.findModels("task", (task) => {
					if (
						task.assigned_to_user_id === this.$store.getters.userId &&
						task.status !== "complete"
					) {
						let project = this.$db.getModel("project", task.project_id);
						return project && project.status !== "closed";
					}

					return false;
				});

				tasks.sort((a, b) => {
					return (
						moment(a.timeline_due_date).valueOf() -
						moment(b.timeline_due_date).valueOf()
					);
				});
				// console.info('[tasks] end ', tasks.length);
				return tasks;
			},

			tasksWithTimers() {
				return this.$store.getters.myTimers.reduce((acc, cur) => {
					if (cur.status === 'Submitted' || !cur.task_id) {
						return acc;
					}

					acc[cur.task_id] = true;
					return acc;
				}, {});
			},

			pendingTasks() {
				// console.info('[pendingTasks] begin ', this.tasks.length);
				if (this.tasks) {
					let value = this.tasks.filter((task) => {
						// include snoozed current tasks in Pending list iff not being timed
						if (
							task.status === "current" &&
							this.$insight.tasks.hideFromCurrentTaskList(task)
						) {
							return !this.tasksWithTimers[task.id];
						}
						else if (this.$insight.tasks.pinToCurrentTaskList(task)) {
							return false;
						}

						if (task.status !== "pending") {
							return false;
						}

						return this.filteredTasks(task);
					});
					// console.info('[pendingTasks] end ', value.length);
					return value;
				}
			},

			currentTasks() {
				// console.info('[currentTasks] begin ', this.tasks.length);
				if (this.tasks && this.$store.getters.myTimers) {
					let value = this.tasks.filter((task) => {
						if (
							task.status === "current" ||
							this.$insight.tasks.pinToCurrentTaskList(task)
						) {
							if (!this.filteredTasks(task)) {
								return false;
							}

							return (
								!this.tasksWithTimers[task.id] &&
								!this.$insight.tasks.hideFromCurrentTaskList(task)
							);
						}

						return false;
					});
					// console.info('[currentTasks] done ', value.length);
					return value;
				}
			},

			currentTimers() {
				let timers = this.$store.getters.myTimers.filter((timer) => {
					return !timer.submit_date;
				});

				timers.sort((a, b) => {
					return b.id - a.id;
				});

				return timers;
			},

			canSubmitAll() {
				let haveTimers = false;

				for (let i = this.currentTimers.length - 1; i >= 0; i--) {
					let timer = this.currentTimers[i];

					if (
						!timer.description ||
						!timer.client_id ||
						!timer.project_id ||
						!timer.job
					) {
						return false;
					} else {
						haveTimers = true;
					}
				}

				return haveTimers;
			},

			taskStats() {
				let stats = {
					overdue: 0,
					today: 0,
					soon: 0,
					later: 0,
				};

				this.tasks.forEach((task) => {
					let status = this.$insight.tasks.taskStatus(task);
					stats[status]++;
				});

				return stats;
			},

			taskLegendItems() {
				if (!this.taskStats) {
					return {};
				}

				let options = [
					{
						name: "Late",
						value: "overdue",
						count: this.taskStats.overdue,
					},
					{
						name: "Today",
						value: "today",
						count: this.taskStats.today,
					},
					{
						name: "Soon",
						value: "soon",
						count: this.taskStats.soon,
					},
					{
						name: "Later",
						value: "later",
						count: this.taskStats.later,
					},
				];

				return options;
			},

			quickTimerOptions() {
				let me = this.$store.getters.userData,
					quickTimerOptions = me.meta_quicktimer_options || [{
						name: "Lunch",
						project_id: 359,
						job: "Digital Attic:Break",
						description: "Lunch",
					}];

				if (typeof quickTimerOptions == 'string') {
					quickTimerOptions = JSON.parse(quickTimerOptions);
				}

				return quickTimerOptions;
			},

			tasksSectionClasses() {
				// default: narrow sidebar layout
				let className = "col-lg-3 sidebar-narrow";
				if (!this.sidebarNarrowLayoutEnabled) {
					if (this.tasksLayoutSelected === "list") {
						className = "col-lg-9 sidebar-extra-wide";
					} else {
						className = "col-lg-6 sidebar-wide";
					}
				}
				this.tasksCol = className;
				return this.tasksCol;
			},

			timersSectionClasses() {
				// default: narrow sidebar layout
				let className = "col-lg-9";
				if (!this.sidebarNarrowLayoutEnabled) {
					if (this.tasksLayoutSelected === "list") {
						className = "col-lg-3 timers-section-narrow";
					} else {
						className = "col-lg-6";
					}
				}
				this.timerCol = className;
				return this.timerCol;
			},
		},

		mounted() {
			this.$modalService.on("save", this.updateMyProjectTasks);

			this.refreshTimer = setInterval(() => {
				this.computedRefresh('tasksLoaded');
			}, 5 * 60 * 1000);
		},

		destroyed() {
			this.$db.removeHandle("tasks");
			this.$modalService.off("save", this.updateMyProjectTasks);
			clearInterval(this.refreshTimer);
		},

		methods: {
			createQuickTimerOption() {
				this.$modalService.create("quickTimer");
			},

			deleteQuickTimerOption(ndx) {
				//TODO: Switch to graphql
				let meId = this.$store.getters.userId,
					copyOptions = this.quickTimerOptions.slice(0);
				copyOptions.splice(ndx, 1);

				this.$xhrRequest
					.send("put", `/api/person/${meId}`, {
						meta_quicktimer_options: copyOptions,
					})
					.then(
						(person) => {
							this.$db.addModels("person", person);
							
							this.$store.commit('updateUser', {
								meta_quicktimer_options: person.meta_quicktimer_options
							})
						},
						(xhr) => {
							this.$snotify.error(
								"Error!",
								"Remove Custom Quicktimer"
							);
						}
					);
			},

			createQuickTimer(custom) {
				let opt = Object.assign({}, custom || {});
				if (opt.name) {
					delete opt.name;
				}

				this.$store.dispatch("createTimer", opt).catch(() => {
					this.$snotify.error("Error!", "Create Quick Timer");
				});
			},

			submitAllTimers() {
				if (!this.canSubmitAll) {
					this.highlightUnCompletableTimers = !this
						.highlightUnCompletableTimers;
					return;
				}

				let allTimersRequest = this.$xhrRequest.chainStart();

				this.$refs.timerCards.forEach((timerCardComp) => {
					timerCardComp.submitTimer(allTimersRequest);
				});

				allTimersRequest
					.chainEnd()
					.then(
						() => {
							this.$snotify.success("Success!", "Submit All Timers");
						},
						() => {
							this.$snotify.error("Error!", "Submit All Timers");
						}
					)
					.then(() => {
						this.highlightUnCompletableTimers = false;
					});
			},

			updateMyProjectTasks(e, changedTask) {
				if (e.modal.modalType === "taskAddEdit") {
					let tasksToUpdate = this.tasks.filter((task) => {
						return (
							task.project_id == changedTask.project_id &&
							task.id != changedTask.id
						);
					});

					if (tasksToUpdate.length) {
						let taskRequest = this.$xhrRequest.chainStart();

						for (let i = 0; i < tasksToUpdate.length; i++) {
							taskRequest
								.send("get", `/api/task/${tasksToUpdate[i].id}`, {
									fields:
										"timeline_due_date,estimated_completion_date",
								})
								.then((task) => {
									this.$db.addModels("task", task);
								});
						}

						taskRequest.chainEnd();
					}
				}
			},

			/**
			 * Decides whether or not task card is visible. Uses taskFilters & searchTxt
			 * @param {object} task
			 * @returns {boolean} Whether to show task card
			 */
			filteredTasks(task) {
				let taskStatus = this.$insight.tasks.taskStatus(task);

				if (this.searchTxt) {
					let project = this.$db.getModel("project", task.project_id),
						client = this.$db.getModel("client", project.client_id) || {
							name: "",
						},
						regex = new RegExp(
							`\\b${this.searchTxt.replace(
								/[.*+?^${}()|[\]\\]/g,
								"\\$&"
							)}`,
							"i"
						);

					if (
						!regex.test(`${task.name} ${project.name} ${client.name}`)
					) {
						return false;
					}
				}

				if (!this.taskFilters.includes(taskStatus)) {
					return false;
				}

				return true;
			}
		},

		watch: {
			timerLayoutSelected(to) {
				this.setUserSetting("layout", to);
			},
			tasksLayoutSelected(to) {
				this.setUserSetting("tasks-layout", to);
				if (to === "list") {
					// force the timers into a list view
					this.timerLayoutSelected = "rows";
				}
			},
			sidebarNarrowLayoutEnabled(to) {
				this.setUserSetting("sidebar-layout", to);

				if (this.sidebarNarrowLayoutEnabled) {
					this.tasksLayoutSelected = "rows";
				}

				if (
					!this.sidebarNarrowLayoutEnabled &&
					this.timerLayoutSelected == "grid layout-grid-sm"
				) {
					this.timerLayoutSelected = "grid";
				}
			},

			canSubmitAll(to) {
				if (to) {
					this.highlightUnCompletableTimers = false;
				}
			},

			taskFilters(to) {
				this.setUserSetting("taskFilters", to);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.btn-submit-all {
		&.disabled {
			// background-color: map-get($grey, "darken-3") !important;
			// color: map-get($grey, "lighten-1");
			opacity: 0.5;
		}
	}

	.sticky-header {
		position: sticky;
		top: 80px;
		z-index: 2;
	}

	::v-deep .v-expansion-panel-content__wrap {
		padding-left: 0;
		padding-right: 0;
	}
	::v-deep .v-expansion-panel-header {
		min-height: unset;
	}

	::v-deep .v-tabs-items {
		background-color: transparent!important;
	}
</style>
