<template>
	<v-container fluid>

		<v-toolbar flat dense class="mb-4">
			<v-tabs>
				<v-tab :to="{name: 'project.profitability'}">
					Project Profitability
				</v-tab>
				<v-tab :to="{name: 'open.projects'}">
					Open Projects
				</v-tab>
				<v-tab :to="{name: 'web.projects'}">
					Web Projects
				</v-tab>
			</v-tabs>
		</v-toolbar>

		<router-view></router-view>

	</v-container>
</template>

<script>
	export default {
		name: "analytics-projects",
		data() {
			return {};
		},
	};
</script>

<style scoped>
	.v-tabs-items {
		background-color: transparent !important;
	}
</style>