<template>
	<div>
		<v-card :loading="$apollo.queries.projects.loading" class="mb-4">
			<v-card-title>
				<template v-if="projects">{{projects.total}}</template> Active Projects
			</v-card-title>
			<v-card-subtitle>Exluding long-term projects in the following categories: Internal, Hosting, Marketing and Audit</v-card-subtitle>

			<v-card-text v-if="projects">
				<v-row class="mt-4" dense justify="center" align-content="space-around">
					<v-col v-for="i in grouped" :key="i.cat" cols="12" sm="4" md="3" lg="2">
						<h2 class="mb-1">
							<v-icon left small>{{$insight.helpers.getProjectIcon(i.cat)}}</v-icon> {{i.cat}}
							<v-chip small class="ml-1" color="">{{i.count}}</v-chip>
						</h2>
						<p>Average Length in Months: {{i.avg_months}}</p>
					</v-col>
				</v-row>
				<GChart :settings="{ packages: ['timeline'] }" type="Timeline" :data="chartData" :options="chartOptions" />
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	
	import moment from "moment";

	export default {
		components: {},
		data() {
			return {
				search: "",
			};
		},
		apollo: {
			projects: {
				query: gql`
					query getProjectProfitability($filterParam: QueryFilter!) {
						projects
							@filter(param: $filterParam)
							@sort(param: [{ field: "creation_date", order: asc }]) {
							total
							nodes {
								node {
									id
									name
									creation_date
									category
									client {
										id
										name
									}
								}
							}
						}
					}
				`,
				variables() {
					let filters = [
						{
							field: "status",
							op: "=",
							value: "active",
						},
						{
							field: "category",
							op: "not in",
							value: ["Internal", "Hosting", "Marketing", "Audit"],
						},
					];
					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
			},
		},

		computed: {
			grouped() {
				if (!this.projects) {
					return [];
				}

				return Linq.from(this.projects.nodes)
					.select(row => row.node)
					.select((item) => {
						return {
							category: item.category,
							length: moment().diff(
								moment(item.creation_date),
								"months"
							),
							data: item,
						};
					})
					.groupBy(
						(item) => item.category,
						(cat, data) => {
							return {
								cat,
								avg_months: data
									.average((p) => p.length)
									.toFixed(2),
								count: data.count(),
							};
						}
					)
					.orderByDescending((item) => parseInt(item.avg_months))
					.toArray();
			},
			chartOptions() {
				return {
					chart: {
						title: "Timeline",
					},
					colors: [this.$vuetify.theme.currentTheme["primary"]],
					backgroundColor: {
						fill: this.$vuetify.theme.currentTheme["background"],
						opacity: 0.5,
					},
					height: 400,
					timeline: {
						colorByRowLabel: true,
						rowLabelStyle: {
							color: this.$vuetify.theme.currentTheme["anchor"],
						},
					},
				};
			},
			chartData() {
				if (!this.projects) {
					return [];
				}
				let headers = [
					[
						{ type: "string", label: "Project" },
						{ type: "string", label: "Category" },
						{ type: "date", label: "Start" },
						{ type: "date", label: "End" },
					],
				];

				let data = Linq.from(this.projects.nodes)
					.select(row => row.node)
					.select((item) => {
						return [
							item.client.name + " " + item.name,
							item.category,
							new Date(item.creation_date),
							new Date(moment()),
						];
					})
					.toArray();

				return headers.concat(data);
			},
		},

		methods: {},
	};
</script>

<style lang="scss" scoped>
	::v-deep {
		text {
			fill: var(--v-anchor-base);
		}
	}

	::v-deep .v-tabs-items {
		background-color: transparent!important;
	}
</style>
