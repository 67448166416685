import Vue from 'vue';
import {ModelMap} from "./model-map";
import moment from 'moment';

export class SimpleDB {
	tables = {};
	handleMap = {};
	defR = null;
	_transactionLog = null;

	constructor() {
		window.db = this;
		this.defR = Vue.util.defineReactive;

		this.defR(this, 'metaTypeMap', {});
	}

	_getTable(type) {
		if (this.tables[type]) {
			return this.tables[type];
		}

		this.defR(this.tables, type, {
			models: {}
		});
		this.tables[type].handles = {};

		return this.tables[type];
	}

	_getModelMap(type) {
		if (ModelMap[type]) {
			return ModelMap[type];
		}

		return ModelMap[type] = {};
	}

	_rebuildMetaTypeMap() {
		let newMap = {};
		
		this.getModels('meta').forEach((field) => {
			let object_type = field.object_type.toLowerCase();
			
			if (!newMap[object_type]) {
				newMap[object_type] = {
					types: {},
					cnt: 0
				};
			}

			newMap[object_type].types[field.machine_name] = field;
			newMap[object_type].cnt++;
		});

		if (JSON.stringify(this.metaTypeMap) != JSON.stringify(newMap)) {
			Vue.set(this, 'metaTypeMap', newMap);
		}
	}

	getMetaTypeMap(type) {
		return this.metaTypeMap[type];
	}

	_findSubModels(type, model) {
		let map = this._getModelMap(type),
			metaTypes = this.getMetaTypeMap(type),
			fields = {};
		
		for (let field in model) {
			if (map[field]) {
				fields[field] = map[field];
			}
			else if (metaTypes && field.indexOf('meta_') === 0 && field.substr(-4) == '_ref') {
				let realName = field.substr(0, field.length - 4);
				fields[field] = metaTypes.types[realName].type;
			}
		}

		return fields;
    }
    
    _setDeepProp(model, path, value) {
        let obj = model;
        path = path.split('__');
        
        for (let i = 0; i < path.length; i++) {
            if (i != path.length - 1) {
                if (obj[path[i]] === null || obj[path[i]] === undefined) {
                    obj[path[i]] = {};
                }
                obj = obj[path[i]];
            }
            else {
                obj[path[i]] = value;
            }
        }

        return value;
	}
	
	_resetModelTracking() {
		this._transactionLog = {};
	}

	_trackModel(type, model) {
		if (this._transactionLog === null) {
			return;
		}

		if (!this._transactionLog[type]) {
			this._transactionLog[type] = {};
		}

		this._transactionLog[type][model.id] = true;
	}

	_endModelTracking() {
		this._transactionLog = null;
	}

	transformModel(model, toClient) {
        if (model === undefined) {
            return model;
        }

		let copy = {};
		for (let i in model) {
			if (typeof model[i] === 'string' && /^\d{4}-\d{2}-\d{2}(?: \d{2}:\d{2}:\d{2})?$/.test(model[i])) {
				if (toClient) {
					copy[i] = moment.utc(model[i]).local().format('YYYY-MM-DD HH:mm:ss');
				}
				else {
					copy[i] = moment(model[i]).utc().format('YYYY-MM-DD HH:mm:ss');
				}
				// prevent deprecation warnings from moment
                if (copy[i] === 'Invalid date') {
                    copy[i] = null;
                }
            }
			else {
				copy[i] = model[i];
			}
		}

		return copy;
	}

	// TODO Method getting too large?
	/**
	 * The only entry point for model data to enter the client side db.
     * @param type		The type of model data. Pick from 'client', 'project', 'task', 'timer', 'note'
     * @param models	An object or array of object model data
	 * @param handle	A string name of who sourced this data. If this is falsy, only updates of existing models and their current fields can be made
	 * @param replace	Whether the data given should completely replace the existing data set for the given handle
     */
	addModels(type, models, handle = false, replace = false) {
		replace = replace && handle;
		if (!Array.isArray(models)) {
			models = [models];
		}

		// Initialize the model tracker
		if (replace) {
			this._resetModelTracking();
		}

		models.forEach((model) => {
            if (typeof model !== 'object' || model === null) {
                return;
            }

            if (typeof model[type] === 'object' && model[type] !== null) {
                // probably the result of a custom query... because models don't directly contain models of their own type
                let realModel = model[type];
                for (let field in model) {
                    if (field === type) {
                        continue;
                    }

                    this._setDeepProp(realModel, field, model[field]);
                }

                model = realModel;
            }

			if (!model.hasOwnProperty('id')) {
				return;
			}

			let table = this._getTable(type);

			// Convert server dates
			model = this.transformModel(model, true);
            
            let patchedModel = table.models[model.id] || {};

			if (handle) {
                for (let field in model) {
                    if (!patchedModel.hasOwnProperty(field) || patchedModel[field] != model[field]) {
                        Vue.set(patchedModel, field, model[field]);
                    }
                }
			}
			else {
				for (let field in patchedModel) {
					if (model.hasOwnProperty(field)) {
						patchedModel[field] = model[field];
					}
				}
			}

			if (Object.keys(patchedModel).length === 0) {
				return;
			}

			this._trackModel(type, model);

            // Process sub-models
            let fields = Object.keys(model),
			    subModelFields = this._findSubModels(type, patchedModel);
			for (let field in subModelFields) {
				this.addModels(subModelFields[field], patchedModel[field], handle);
				
				let refField = `${field}_ref`;
				if (Array.isArray(patchedModel[field])) {
					refField += 's';
					patchedModel[refField] = patchedModel[field].map(m => (m && m.id > 0) ? m.id : null);
				}
				else {
					patchedModel[refField] = (patchedModel[field] && patchedModel[field].id > 0)
						? patchedModel[field].id
						: null;
				}

				delete patchedModel[field];
				fields.splice(fields.indexOf(field), 1, refField);
            }

            if (!table.models[model.id]) {
                // Insert instance
                Vue.set(table.models, model.id, patchedModel);
			}
			
			if (!table.handles[model.id]) {
				Vue.set(table.handles, model.id, {});
			}

			if (handle) {
				Vue.set(table.handles[model.id], handle, fields);

				if (!this.handleMap[handle]) {
					this.handleMap[handle] = {};
				}
				if (!this.handleMap[handle][type]) {
					this.handleMap[handle][type] = new Set();
				}

				this.handleMap[handle][type].add(parseInt(patchedModel.id));
			}
		});

		if (replace) {
			// Look in the handleMap for ids that are not in the transaction log
			for (let type in this.handleMap[handle]) {
				this.handleMap[handle][type].forEach((modelId) => {
					if (!this._transactionLog[type] || !this._transactionLog[type][modelId]) {
						this._removeModelHandle(type, modelId, handle);
					}
				});
			}

			this._endModelTracking();
		}

		if (type == 'meta') {
			this._rebuildMetaTypeMap();
		}
	}

	_getModel(type, id) {
		let table = this._getTable(type),
			model = Object.assign({}, table.models[id]);
		
		if (type != 'meta') {
			let metaTypes = this.getMetaTypeMap(type);
			if (metaTypes && metaTypes.cnt) {
				model._meta_types = metaTypes.types;
			}
		}

		return model;
	}

	findModels(type, cb) {
		let results = [],
			table = this._getTable(type);

		for (let id in table.models) {
			let model = this._getModel(type, id);
			if (cb(model, table.handles[id])) {
				results.push(Object.assign({}, table.models[id]));
			}
		}

		return results;
    }

	findFirstModel(type, cb) {
		let table = this._getTable(type);

		for (let id in table.models) {
			let model = this._getModel(type, id);
			if (cb(model, table.handles[id])) {
				return Object.assign({}, table.models[id]);
			}
		}

		return null;
	}

	getModel(type, id) {
		let table = this._getTable(type);

		if (table.models[id]) {
			return this._getModel(type, id);
		}

		return null;
	}

	getModels(type) {
		let table = this._getTable(type),
			result = [];

		for (let id in table.models) {
			result.push(this._getModel(type, id));
		}

		return result;
	}

	removeModels(type, models) {
		if (!Array.isArray(models)) {
			models = [models];
		}

		let table = this._getTable(type);

		models.forEach((model) => {
			let id = typeof model === 'object' ? model.id : model;
			Vue.delete(table.models, id);

			for (let handle in table.handles[id]) {
				this.handleMap[handle][type].delete(parseInt(id));
				if (!this.handleMap[handle][type].size) {
					delete this.handleMap[handle][type];
					if (!Object.keys(this.handleMap[handle]).length) {
						delete this.handleMap[handle];
					}
				}
			}
			
			Vue.delete(table.handles, id);
		});
	}

	_removeModelHandle(type, modelId, handle) {
		let table = this._getTable(type);

		if (table.handles[modelId][handle]) {
			Vue.delete(table.handles[modelId], handle);
			
			this.handleMap[handle][type].delete(modelId);
			if (!this.handleMap[handle][type].size) {
				delete this.handleMap[handle][type];
				if (!Object.keys(this.handleMap[handle]).length) {
					delete this.handleMap[handle];
				}
			}

			let fields = new Set();
			for (let h in table.handles[modelId]) {
				table.handles[modelId][h].forEach((field) => {
					fields.add(field);
				});
			}

			if (fields.size) {
				for (let field in table.models[modelId]) {
					if (!fields.has(field)) {
						Vue.delete(table.models[modelId], field);
					}
				}
			}
			else {
				this.removeModels(type, modelId);
			}
		}
	}

	removeHandle(handle) {
		if (this.handleMap[handle]) {
			for (let type in this.handleMap[handle]) {
				this.handleMap[handle][type].forEach((modelId) => {
					this._removeModelHandle(type, modelId, handle);
				});
			}
		}
	}
}

export const dbInst = new SimpleDB();