import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import spaceDark from './space-dark';
import spaceLight from './space-light';
import simpleDark from './theme-dark';
import simpleLight from './theme-light';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
// import * as faPro from '@fortawesome/pro-light-svg-icons'
import { faBars } from '@fortawesome/pro-light-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

// for (let i in faPro) {
// 	if (i.indexOf('fa') == 0) {
// 		console.log(i, faPro[i]);
// 		library.add(faPro[i]);
// 	}
// }
library.add(faBars);

Vue.use(Vuetify);

export default new Vuetify({
	// options: {
	// 	customProperties: true
	// },
	icons: {
		values: {
			menu: {
				component: FontAwesomeIcon,
				props: {
					icon: ['fal', 'bars'],
				},
			},
			vuejs: {
				component: FontAwesomeIcon,
				props: {
					icon: ['fab', 'vuejs'],
				},
			},
			checkboxOn: 'fal fa-check-square',
			checkboxOff: 'fal fa-square',
			radioOn: 'fal fa-check-circle',
			radioOff: 'fal fa-circle',
			expand: 'fal fa-angle-down',
			dropdown: 'fal fa-angle-down',
			clear: 'fal fa-times',
			delete: 'fal fa-times-circle',
			prev: 'fal fa-chevron-left',
			next: 'fal fa-chevron-right',
			sort: 'fal fa-long-arrow-down',
			first: 'fal fa-arrow-to-left',
			last: 'fal fa-arrow-to-right',
			// complete: 'fal fa-times',
			// cancel: VuetifyIcon
			close: 'fal fa-times',
			success: 'fal fa-check-circle',
			// info: VuetifyIcon
			// warning: VuetifyIcon
			// error: VuetifyIcon
			// checkboxIndeterminate: VuetifyIcon
			// delimiter: VuetifyIcon
			// menu: VuetifyIcon
			// subgroup: VuetifyIcon
			// edit: VuetifyIcon
			// ratingEmpty: VuetifyIcon
			// ratingFull: VuetifyIcon
			// ratingHalf: VuetifyIcon
			// loading: VuetifyIcon
			// unfold: VuetifyIcon
			// file: VuetifyIcon
			// plus: VuetifyIcon
			minus: 'fal fa-minus'
		},
	},
	theme: {
		dark: true,
		options: {
			customProperties: true
		},
		themes: { 
			"space-light": spaceLight,
			"simple-dark": simpleDark,
			"space-dark": spaceDark
		 },
	},
})