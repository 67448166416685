<template>
	<div class="d-flex flex-wrap user-statuses">
		<div v-for="item in userData" :key="item.user.id" class="user mr-5 body-2" :class="userClass(item)" :title="userTooltip(item)">
			{{item.user.full_name}}
			<!-- <pre>{{item.info}}</pre> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: "userOnlineStatus",
		props: {
			userData: Array,
		},
		methods: {
			userClass(item) {
				// clocked out and done for the day (all their timers are submitted):
				// clocked in working (at least one unsubmitted active timer)
				// overtime
				// clocked into break or lunch
				// clocked out no active timers and at least one unsubmitted timer
				let classes = [];

				if (item.timers.length) {
					if (item.info.isOT) {
						classes.push("status-OT");
					}
					if (item.info.numSubmitted === item.timers.length) {
						classes.push("status-done");
					}
					if (item.info.isOnBreak) {
						classes.push("status-break");
					}
					classes.push(
						item.info.working ? "status-working" : "status-not-working"
					);
				}
				return classes.join(" ");
			},

			userTooltip(item) {
				let descriptions = {
						"status-OT": "working overtime",
						"status-break": "break and unavailable",
						"status-done": "done for the day",
						"status-working": "on the clock",
						"status-not-working": "not working",
						"status-no-timers": "not working today",
					},
					classes = this.userClass(item);
				for (var className in descriptions) {
					classes = classes.replace(className, descriptions[className]);
				}
				return classes;
			},
		},
	};
</script>

<style scoped lang="scss">
	.status-done {
		color: var(--v-text-over-bg-base);
	}
	.status-no-timers {
		display: none;
	}

	.status-working {
		font-weight: bold;
		color: var(--v-working-text-over-bg-base);
	}

	.status-break,
	.status-not-working {
		color: var(--v-text-over-bg-base);
	}

	.status-OT {
		color: var(--v-ot-text-over-bg-base);
	}
</style>