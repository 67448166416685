<template>
	<div class="no-timers" :class="classified">
		<v-sheet v-if="classified == 'weekend'" class="pa-10 text-center">
			<p class="text-h4">It's the weekend. Go do something fun!</p>
			<p class="text-h6"></p>
			<v-icon large class="ma-2">fad fa-puzzle-piece</v-icon>
			<v-icon large class="ma-2">fad fa-joystick</v-icon>
			<v-icon large class="ma-2">fad fa-alien-monster</v-icon>
			<v-icon large class="ma-2">fad fa-gamepad-alt</v-icon>
			<v-icon large class="ma-2">fad fa-ghost</v-icon>
			<v-icon large class="ma-2">fad fa-dice</v-icon>
			<v-icon large class="ma-2">fad fa-dice-d20</v-icon>
			<v-icon large class="ma-2">fab fa-steam</v-icon>
			<v-list-item class="mt-5">
				<v-list-item-content>
					<v-list-item-title><a href="https://dwmkerr.github.io/spaceinvaders/#" target="_blank">Play Space Invaders <v-icon right x-small>fad fa-external-link</v-icon></a></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-sheet>
		<v-sheet v-if="classified == 'default'" class="pa-10 mb-1 text-center">
			<p class="text-h4">Clean up your planet</p>
			<v-icon left>fad fa-broom</v-icon>
			<v-icon large>fad fa-planet-ringed</v-icon>
			<v-icon right>fad fa-sparkles</v-icon>
		</v-sheet>
	</div>
</template>

<script>
	import moment from "moment";

	export default {
		name: "noTimers",

		computed: {
			classified() {
				let minute = this.$store.state.app.currentMinute;

				switch (true) {
					case ["Sun", "Sat"].includes(minute.format("ddd")):
						return "weekend";
					// no longer valid with overseas peeps
					/* case minute.isBetween(moment().startOf('day'), moment({hour: 8, minute: 0, second: 0}), 'minute'):
	                        return 'beforeWork'; */
					/* case minute.isBetween(moment({hour: 17, minute: 0, second: 0}), moment().endOf('day'), 'minute'):
	                        if (this.$store.getters.timersTotalTime > 0) {
	                            return 'afterWork';
	                        }
	                        else {
	                            return 'missedWork';
	                        } */
					default:
						return "default";
				}
			},
		},
	};
</script>

<style scoped>
	.no-timers {
		flex-basis: 100%;
	}
</style>