<template>
	<v-container fluid v-if="simpleClient && simpleClient.id">

		<v-card class="mb-4">
			<v-card-title>
				Client: {{simpleClient.name || "[No Name Set]"}}
				<v-btn class="ml-auto" min-width="0" small @click="updateClient(simpleClient.id)">
					<v-icon small>fad fa-pencil</v-icon>
				</v-btn>
			</v-card-title>
		</v-card>

		<v-row>
			<v-col cols="12" sm="6">

				<v-card class="mb-4">
					<v-card-title>Client Info</v-card-title>
					<v-card-text>
						<div>Description: {{simpleClient.description}}</div>
						<div :class="`field-${field}`" :key="field" v-for="(field, title) in dataFields">
							{{ title }}: {{ simpleClient[field] }}
						</div>
						<v-divider class="my-2"></v-divider>
						<div class="text-h6">Meta</div>
						<meta-list :model="simpleClient"></meta-list>
					</v-card-text>
				</v-card>

				<v-card class="my-4">
					<v-card-title>
						Status
						<add-note-button objType="client" :objId="simpleClient.id" noteType="status" :showType="false" @save="addStatusNote" class="ml-auto"></add-note-button>
					</v-card-title>
					<v-card-text v-html="lastStatusHTML">

					</v-card-text>
				</v-card>

				<v-card class="my-4">
					<v-card-title>
						Projects
						<v-btn small title="Add Project" min-width="0" @click="addProject" class="ml-auto">
							<div class="fa-stack">
								<v-icon small>fad fa-tasks-alt</v-icon>
								<v-icon small color="primary" class="tiny">fal fa-plus</v-icon>
							</div>
						</v-btn>
					</v-card-title>

					<v-card-text>
						<v-list-item-group data-filter="all">
							<template v-for="(project, idx) in simpleClient.projects">
								<v-list-item :key="idx" three-line :selectable="true">
									<v-list-item-content>
										<v-list-item-title>
											<v-chip v-if="project.status!='active'" label x-small v-html="project.status" color="primary" class="mr-2"></v-chip>
											<router-link :to="{name: 'project.details', params: {id: project.id}}">{{project.name}}</router-link>
										</v-list-item-title>
										<v-list-item-subtitle v-if="project.last_status">
											{{project.last_status.date | shortDate}}: {{project.last_status.content | cleanHtml}}
										</v-list-item-subtitle>
										<div class="d-flex flex-wrap justify-space-between">
											<router-link v-if="project.account_executive_user" :to="{name:'contact.details', params:{id: project.account_executive_user.id}}">
												{{project.account_executive_user.first_name}} {{project.account_executive_user.last_name}}
											</router-link>
											<span v-else class="text-black-50">[No AE]</span>
											<div v-if="project.task_counts" title="unassigned/total">
												<v-icon x-small :color="project.task_counts.unassigned>0 ? 'primary' : 'dark'" class="mr-1">fas fa-fire</v-icon>
												{{project.task_counts.unassigned || 0}}/{{project.task_counts.total}}
											</div>
											<div title="due">{{project.due_date | shortDate('[No Due Date]')}}</div>
										</div>
									</v-list-item-content>
								</v-list-item>
								<v-divider :key="`${idx}-divider`"></v-divider>
							</template>
						</v-list-item-group>
					</v-card-text>
				</v-card>

			</v-col>

			<v-col cols="12" sm="6">
				<client-contact-list :id="simpleClient.id" class="mb-4" />
				<client-notes :uid="simpleClient.uid"></client-notes>
			</v-col>
		</v-row>
	</v-container>
	<v-container v-else class="fill-height flex-column justify-center align-center">
		<p class="text-h6">Loading Client</p>
		<v-icon large color="primary">fad fa-cog fa-spin</v-icon>
	</v-container>
</template>

<script>
	import ClientNotes from "../components/client/clientNotes";
	import metaList from "../components/metaList";
	import ClientContactList from "@/components/client/clientContactList";
	import addNoteButton from "../components/addNoteButton";
	import { timeInterval } from '../utils/timeInterval';


	export default {
		name: "clientDetails",

		components: {
			ClientNotes,
			addNoteButton,
			metaList,
			ClientContactList
		},

		apollo: {
			simpleClient: {
				query: gql`
					query GetSingleClient($clientId: ID!) {
						queries
						simpleClient: client(id: $clientId) {
							id
							uid
							name
							description
							location_phone
							location_fax
							location_address
							location_city
							location_state
							location_zip
							last_status {
								date
								content
							}
							projects {
								id
								name
								due_date
								status
								last_status {
									date
									content
								}
								account_executive_user {
									id
									first_name
									last_name
								}
								task_counts {
									unassigned
									total
								}
							}
							_meta_values
							_meta_types
						}
					}
				`,
				pollInterval: timeInterval('5 minutes'),
				variables() {
					return {
						clientId: this.$route.params.id,
					};
				},
			},
		},

		data() {
			return {};
		},

		computed: {
			dataFields() {
				let defaults = {
					Phone: "location_phone",
					Fax: "location_fax",
					Address: "location_address",
					City: "location_city",
					State: "location_state",
					ZIP: "location_zip",
				};
				return defaults;
			},
			lastStatusHTML() {
				if (!this.simpleClient.last_status) {
					return "There is no status message yet";
				}

				let date = this.$options.filters.shortDate(
					this.simpleClient.last_status.date
				);
				let content = this.$options.filters.cleanHtml(
					this.simpleClient.last_status.content
				);
				return `${date}<br>${content}`;
			},
		},

		methods: {
			addStatusNote(note) {
				this.$apollo.queries.simpleClient.refetch();
			},
			addProject() {
				this.$modalService
					.create("projectAddEdit", {
						lockedClientId: this.simpleClient.id,
					})
					.on("save", (e, project) => {
						this.$snotify.success("Project created!", "Create Project");
						this.$apollo.queries.simpleClient.refetch();
					});
			},
			updateClient(id) {
				this.$modalService
					.create("clientAddEdit", {
						clientId: id,
					})
					.on("save", (e, client) => {
						this.$snotify.success("Success!", "Updated Client");
						this.$apollo.queries.simpleClient.refetch();
					});
			},
		},
	};
</script>
