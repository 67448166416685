<template>
	<v-card class="mb-3">
		<v-card-title>Current Task Totals for <v-chip v-if="projectTotals" class="mx-1">{{projectTotals.total}}</v-chip> Projects</v-card-title>
		<div v-if="departmentTaskCounts && departmentTaskCounts.nodes.length" class="d-flex flex-rows justify-content-arounds flex-wrap">
			<template v-for="item in departmentTaskCounts.nodes">
				<v-card v-if="item.node.assigned_to" dark :key="item.node.assigned_to.department" :color="item.node.assigned_to.department.toLowerCase() || ''" class="item ma-2 text-center">
					<v-card-title class="text-uppercase justify-center">{{item.node.assigned_to.department}}</v-card-title>
					<v-card-text class="text-h4">{{item.group_total}}</v-card-text>
				</v-card>
			</template>
		</div>
	</v-card>
</template>

<script>
	export default {
		name: "projectAnalytics",

		apollo: {
			projectTotals: {
				query: gql`
					query getTotalProjects($filterParam: QueryFilter!) {
						projectTotals: projects @filter(param: $filterParam) {
							total
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "=",
									value: "active",
								},
							],
						},
					};
				},
			},
			departmentTaskCounts: {
				query: gql`
					query getDepartmentTaskCounts($filterParam: QueryFilter!) {
						departmentTaskCounts: tasks
							@filter(param: $filterParam)
							@groupBy(fields: ["assigned_to.department"]) {
							nodes {
								group_total
								node {
									assigned_to {
										department
									}
								}
							}
						}
					}
				`,
				variables() {
					return {
						filterParam: {
							joinOp: "and",
							filters: [
								{
									field: "status",
									op: "=",
									value: "current",
								},
								{
									field: "assigned_to.department",
									op: "!=",
									value: null,
								},
								{
									field: "project.status",
									op: "!=",
									value: "closed",
								},
							],
						},
					};
				},
			},
		},
	};
</script>

<style scoped lang="scss">
	.item {
		flex-basis: 33%;
		flex-grow: 1;
		max-width: 100%;
		@media (min-width: 960px) {
			flex-basis: 0;
		}
	}
</style>