import Vue from 'vue';
import Insight from "../utils/Insight";
import moment from 'moment'
import store from '../store';
import { dbInst } from '../db/simple-db'

window.Vue = Vue;

Vue.filter('thousandSeparator', function (value) {
	return value.toLocaleString();
});

// all times retrieved from and provided to the server are UTC
Vue.filter('formatDate', function (value, format, isLocal = true) {
	if (value && value!=='Invalid date') {
		// you can also moment.utc(..).local()...
		return moment(String(value) + (isLocal ? '' : 'Z')).format(format || 'MM/DD/YYYY h:mm:ss a');
	}
});

/**
 * For sending UTC dates to the server
 */
Vue.filter('serverDate', function (localDate, format) {
	if (localDate) {
		// you can also moment.utc(..).local()...
		return moment(String(localDate)).utc().format(format || 'YYYY-MM-DD hh:mm:ss');
	}
});

/**
 * for input type=date fields
 */
Vue.filter('clientDate', function (value, defaultValue) {
	if (value && value!=='Invalid date') {
		return moment(String(value)).format('YYYY-MM-DD');
	} else {
		if (defaultValue === 'today') {
			return moment().format('YYYY-MM-DD')
		}
		return defaultValue;
	}
});

Vue.filter('shortDate', function (value, defaultValue) {
	if (value && value!=='Invalid date') {
		if (!moment.isMoment(value)) {
			value = moment(value);
		}
		return value.format('MM/DD/YYYY');
	} else {
		return defaultValue;
	}
});

Vue.filter('birthDate', function (value, defaultValue) {
	if (value && value!=='Invalid date') {
		if (!moment.isMoment(value)) {
			value = moment(value);
		}
		return value.format('MMM Do');
	} else {
		return defaultValue;
	}
});

Vue.filter('shortTime', function (value, defaultValue) {
	if (value && value!=='Invalid date') {
		if (!moment.isMoment(value)) {
			value = moment(value);
		}
		return value.format('hh:mm A');
	} else {
		return defaultValue;
	}
});

Vue.filter('timeFromNow', function (value) {
	if (value && value!=='Invalid date') {
		if (!moment.isMoment(value)) {
			value = moment(value);
		}

		return value.calendar(store.getters.currentDay, {
			sameDay:  '[Today]',
			nextDay:  '[Tomorrow]',
			nextWeek: '[Next] dddd',
			lastDay:  '[Yesterday]',
			lastWeek: '[Last] dddd',
			sameElse: '[' + value.fromNow() + ']'
		});
	}
});

Vue.filter('fromNow', function (value) {
	if (value && value!=='Invalid date') {
		if (!moment.isMoment(value)) {
			value = moment(value);
		}

		return value.from(store.getters.currentMinute);
	}
});

Vue.filter('getPhoneLink', function (value) {
	if (value) {
		return 'tel:' + value.replace(/[^0-9]+/g, '');
	}
});

// replace characters with non-breaking characters
Vue.filter('getPhoneNumber', function (value) {
	if (value) {
		return value.replace(/-/g, '‑').replace(/ /g, '\xa0').replace(/\(/g, '\x28').replace(/\)/g, '\x29');
	}
});

Vue.filter('FullName', function (user, defaultValue) {
	defaultValue = defaultValue || '';

	if (typeof user != 'object') {
		user = dbInst.getModel('person', user);
	}
	
	if (!user) {
		return defaultValue;
	}

	return (user.first_name + ' ' + user.last_name).trim();
});

Vue.filter('Department', function (user, defaultValue) {
	defaultValue = defaultValue || '';

	if (typeof user != 'object') {
		user = dbInst.getModel('person', user);
	}
	
	if (!user) {
		return defaultValue;
	}

	return user.department.trim();
});

Vue.filter('ClientName', function (client, defaultValue) {
	defaultValue = defaultValue || '';
	if (!client) {
		return defaultValue;
	}

	return (client.name).trim();
});

Vue.filter('ProjectName', function (project, defaultValue) {
	defaultValue = defaultValue || '';
	if (!project) {
		return defaultValue;
	}

	return (project.name).trim();
});

Vue.filter('Initials', function (user, defaultValue) {
	defaultValue = defaultValue || '';
	
	if (typeof user != 'object') {
		user = dbInst.getModel('person', user);
	}

	if (!user) {
		return defaultValue;
	}

	return [user.first_name && user.first_name[0], user.last_name && user.last_name[0]].filter((item) => {
		return item;
	}).join('');
});

Vue.filter('capitalize', function (word) {
	if (word) {
		return word[0].toUpperCase() + word.substr(1)
	}
});

Vue.filter('lowercase', function (value) {
	if (value) {
		return value.toLowerCase();
	}
});

Vue.filter('formatDuration', function(time) {
	return Insight.timers.formatDuration(time);
});

Vue.filter('percent', function(amount, total, suffix='%',decimals=0){
	let percent = 0;
	if (total !==0) {
		percent = 100*parseFloat(amount) / parseFloat(total) ;
	}
	percent.toFixed(decimals);
	return percent + suffix;
});

/**
 * Remove garbage from note contents.
 */
Vue.filter('cleanHtml', function(content) {
    let $text = $('<span>'+content+'</span>');
    $text.find('[style]').removeAttr('style');
    $text.find(':not(span,p,div,li,ul,ol,a)').remove();
    let text = $text.html();
    return text.replace(new RegExp('<span>&nbsp;</span>|<!--.*-->', 'gs'), '');
});

Vue.filter('fromDb', function (input, type) {
	if (typeof input != 'object') {
		input = dbInst.getModel(type, input);
	}

	return input;
});

/**
 * Returns HTML-wrapped hours minutes duration, rounded to nearest minute.
 */
Vue.filter('hoursMinutes', function (seconds) {
	let hours          = Math.floor(seconds / 3600),
	    minutes        = Math.round((seconds - (hours * 3600)) / 60),
	    displayHours   = hours ? (hours == 1 ? '1 hr' : hours + ' hrs') : '',
	    displayMinutes = isNaN(minutes)?'':(minutes == 1 ? '1 min' : minutes + ' min'),
	    result         = `<div class="hr-min"><div>${displayHours}</div><div>${displayMinutes}</div></div>`;
	return result;
})
