<template>
    <transition-group
        tag="div"
        class="modal-service-container"
        @enter="modalAppear"
        @leave="modalDisappear">
        <component v-for="(modal, ndx) in modalsVisible" v-if="$modalService._getModalComponent(modal.modalType)" :key="modal.id" :is="$modalService._getModalComponent(modal.modalType)" v-bind="modal.opts" :modal="modal"></component>
    </transition-group>
</template>

<script>
	import {ModalService} from '@/utils/ModalService';

	export default {
        name: 'ModalServiceComponent',

        computed: {
            modalsVisible() {
                return ModalService.getVisibleModals();
            }
        },

        methods: {
            modalAppear(el, done) {
                let $el = $(el);
                $('body').addClass('modal-open');

                setTimeout(done, 350);
            },

            modalDisappear(el, done) {
                let $el = $(el);

                setTimeout(() => {
                    if (!this.modalsVisible.length) {
                        $('body').removeClass('modal-open');
                    }
                    done();
                }, 350);
            }
        }
    };
</script>
