<template>
	<v-row no-gutters style="border-bottom: 1px solid var(--v-dividers-base);" class="py-1">
		<v-col cols="3" class="font-weight-bold" v-html="label"></v-col>
		<v-col cols="9" class="item-body">
			<slot>
				<div v-if="value" v-html="value"></div>
			</slot>
		</v-col>
	</v-row>
</template>

<script>
	export default {
		name: "dataListItem",
		props: {
			label: String,
			value: String,
		}
	};
</script>