<template>
    <v-chip dark label x-small :color="userData.department.toLowerCase()">
        <template v-if="fullName">
            {{userData | FullName}}
        </template>
        <template v-else>
            {{userData | Initials}}
        </template>
    </v-chip>
</template>

<script>
    export default {
        name: 'departmentBadge',

        props: {
            user: {
                type: [Number, String, Object],
                required: true
            },

            fullName: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            userData() {
                if (typeof this.user == 'object') {
                    return this.user;
                }

                return this.$db.getModel('person', this.user);
            }
        }
    }
</script>
