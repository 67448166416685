<template>
	<v-dialog :value="true" min-width="350" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card v-if="personData">
			<v-card-title>{{ isUpdate ? 'Edit' : 'Add' }} Contact</v-card-title>
			<v-card-text>

				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<div v-if="!realPersonId && duplicateCheck && duplicateCheck.nodes.length">
					<div class="font-weight-bold error--text">Some contacts were found with the same email address.</div>
					<!--
					when adding a contact
					and I enter an email belonging to another contact in the system,
					a list of matches appears in a table.
					when I choose that other existing contact, then replace the form information with the chosen contact including its id
					-->
					<p>Click a contact to choose it.</p>
					<v-data-table :items="duplicateCheck.nodes" :headers="existingContactsFields" dense @click:row="existingContactSelected">
						<template v-slot:item.node.id="{ item }" @click.stop>
							<v-btn x-small :to="{name: 'contact.details', params: {id: item.node.id}}" target="_blank">View</v-btn>
						</template>
					</v-data-table>
				</div>

				<v-row>
					<v-col>
						<v-text-field label="Title" v-model="personData.title" :disabled="saving" dense hide-details outlined class="mt-3"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="First Name" v-model="personData.first_name" :disabled="saving" required dense hide-details outlined></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field label="Last Name" v-model="personData.last_name" :disabled="saving" dense hide-details outlined></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="Cell Phone" v-model="personData.phone_primary" :disabled="saving" dense hide-details outlined></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field label="Phone Secondary" v-model="personData.phone_secondary" :disabled="saving" dense hide-details outlined></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field label="Office Phone" v-model="personData.phone_office" :disabled="saving" dense hide-details outlined></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field label="Fax" v-model="personData.phone_fax" :disabled="saving" dense hide-details outlined></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field type="email" label="Email" v-model="personData.email_primary" :disabled="saving" dense hide-details outlined></v-text-field>
						<div class="search-indicator pr-2" :class="{searching:searching}">
							<v-icon class="my-2">fad fa-cog fa-spin</v-icon>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field type="email" label="Email Secondary" v-model="personData.email_secondary" :disabled="saving" dense hide-details outlined></v-text-field>
					</v-col>
				</v-row>

				<!-- todo someday: - repeating linked clients & note -->
				<client-picker v-model="personData.client_links" label="Linked Clients" placeholder="Select One..." :disabled="saving" dense class="mt-6" hide-details outlined multiple chips small-chips deletable-chips></client-picker>

				<project-picker v-model="personData.project_links" label="Linked Projects" dense class="mt-6" hide-details outlined multiple chips small-chips deletable-chips :disabled="saving" placeholder="Select One...">
					<template v-slot:item="{item}">
						<span class="mr-2">{{item.name}}</span><v-chip small v-if="item.status != 'active'" class="ml-auto">{{item.status}}</v-chip>
					</template>
				</project-picker>

				<v-textarea label="Note" v-model="personData.note" :disabled="saving" rows="3" auto-grow outlined dense hide-details class="mt-6"></v-textarea>

				<v-divider></v-divider>
				<h2 class="my-6">Meta Fields</h2>
				<meta-input-list ref="metaFieldList" :model="personData" model-type="Person" :disabled="saving"></meta-input-list>

			</v-card-text>

			<v-card-actions>
				<div>Required field <sup class="error--text">*</sup></div>
				<div class="ml-auto">
					<v-btn @click="modal.trigger('close')" :disabled="saving" class="mr-3">Close</v-btn>
					<v-btn color="primary" @click="submit" :loading="saving">{{ isUpdate ? 'Update' : 'Create' }}</v-btn>
				</div>
			</v-card-actions>
		</v-card>

	</v-dialog>
</template>

<script>
	import MetaInputList from "../metaInputList";
	import { IdType } from "../../utils/IdType";
	import ProjectPicker from "@/components/fields/projectPicker.vue";
	import ClientPicker from "@/components/fields/clientPicker.vue";

	const getSinglePersonQuery = gql`
		query ContactAddEdit($personId: ID!) {
			personDetails: person(id: $personId) {
				id
				first_name
				last_name
				title
				is_employee
				note
				_meta_values
				phone
				phone_office
				phone_fax
				phone_primary
				phone_secondary
				email_primary
				email_secondary
				... on Contact {
					client_links {
						note
						client {
							id
							name
						}
					}
					project_links {
						note
						project {
							id
							name
							client {
								id
								name
							}
						}
					}
				}
			}
		}
	`;

	export default {
		name: "contactAddEdit",
		components: { MetaInputList, ProjectPicker, ClientPicker },
		props: {
			modal: {
				type: Object,
				required: true,
			},
			personId: {
				type: IdType,
				default: 0,
			},
			populate: {
				type: Object,
				default: () => ({}),
			},
		},
		apollo: {
			personDetails: {
				query: getSinglePersonQuery,
				variables() {
					return this.getQueryVariables();
				},
				skip() {
					return !this.realPersonId;
				},
			},
			duplicateCheck: {
				query: gql`
					query CheckForDuplicate($filterParam: QueryFilter!) {
						duplicateCheck: persons @filter(param: $filterParam) {
							nodes {
								node {
									id
									full_name
									phone_primary
									phone_office
									email_primary
								}
							}
						}
					}
				`,
				variables() {
					let filters = [];

					filters.push({
						field: "email_primary",
						op: "=",
						value: this.personData.email_primary,
					});

					return {
						filterParam: {
							joinOp: "and",
							filters,
						},
					};
				},
				skip() {
					return (
						this.updateContact ||
						!this.personData ||
						!this.personData.email_primary
					);
				},
				throttle: 2000,
			},
		},

		data() {
			return {
				modalErrorMessage: false,
				saving: false,
				existingContacts: [],
				searching: false,
				realPersonId: this.personId,
				personData: null,
			};
		},

		computed: {
			isUpdate() {
				return this.realPersonId;
			},
			existingContactsFields() {
				return [
					{ text: "Full Name", value: "node.full_name" },
					{ text: "Phone", value: "node.phone_primary" },
					{ text: "Office Phone", value: "node.phone_office" },
					{ text: "", value: "node.id" },
				];
			},
		},
		
		watch: {
			personDetails(to) {
				this.copyData(to);
			},
			personId: {
				immediate: true,
				handler(to) {
					if (!to) {
						this.copyData(this.populate);
					}
				},
			},
		},

		methods: {
			copyData(graphQlData = {}) {
				let copyManyFn = (props, subKey = "") => {
					let source = graphQlData;
					if (subKey) {
						source = source[subKey] || {};
					}

					let result = {};
					for (let i of props) {
						result[i] = source.hasOwnProperty(i) ? source[i] : null;
					}

					return result;
				};

				let extraInfo = {
					client_links: (graphQlData.client_links || []).map((link) =>
						link.client ? link.client.id : link
					),
					project_links: (graphQlData.project_links || []).map((link) =>
						link.project ? link.project.id : link
					),
				};

				this.personData = {
					...copyManyFn([
						"first_name",
						"last_name",
						"title",
						"is_employee",
						"note",
						"_meta_values",
						"phone",
						"phone_office",
						"phone_fax",
						"phone_primary",
						"phone_secondary",
						"email_primary",
						"email_secondary",
					]),
					...extraInfo,
				};
			},

			formModel() {
				let model = {
					title: this.personData.title,
					first_name: this.personData.first_name.trim(),
					last_name: this.personData.last_name.trim(),
					phone_office: this.personData.phone_office,
					phone_fax: this.personData.phone_fax,
					phone_primary: this.personData.phone_primary,
					phone_secondary: this.personData.phone_secondary,
					email_primary: this.personData.email_primary,
					email_secondary: this.personData.email_secondary,
					note: this.personData.note,
					custom_home: this.personData.custom_home,
					department: this.personData.department,
					hired_date: this.personData.hired_date,
					exit_date: this.personData.exit_date,
					gender: this.personData.gender,
					client_links: this.personData.client_links.map((num) => ({
						client_id: num,
					})),
					project_links: this.personData.project_links.map((num) => ({
						project_id: num,
					})),
					quickbooks_name: this.personData.quickbooks_name,
					quickbooks_vendor_name: this.personData.quickbooks_vendor_name,
				};

				if (this.isUpdate) {
					Object.assign(model, { id: this.realPersonId });
				}

				Object.assign(model, this.$refs.metaFieldList.getFields());

				return model;
			},

			getQueryVariables() {
				return {
					personId: this.realPersonId,
				};
			},

			submit() {
				this.saving = true;

				if (this.isUpdate) {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: UpdateContactArgs!) {
									updateContact(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: this.formModel(),
							},
							update: (store, { data: { updateContact } }) => {
								this.saving = false;
								this.modal.trigger("save close", updateContact, this.isUpdate);
							},
						})
						.catch((error) => {
							console.error("error updating contact", error);
							this.saving = false;
							this.modalErrorMessage =
								"There was a problem updating the data.";
						});
				} else {
					this.$apollo
						.mutate({
							mutation: gql`
								mutation ($data: CreateContactArgs!) {
									createContact(data: $data) {
										id
									}
								}
							`,
							variables: {
								data: this.formModel(),
							},
							update: (store, { data: { createContact } }) => {
								this.saving = false;
								this.modal.trigger("save close", createContact);
							},
						})
						.catch((error) => {
							console.error("error adding contact", error);
							this.saving = false;
							this.modalErrorMessage =
								"There was a problem creating the contact.";
						});
				}
			},

			existingContactSelected(row) {
				this.realPersonId = row.node.id;
			},
		},
	};
</script>
<style scoped lang="scss">
	.search-indicator {
		position: absolute;
		z-index: 9999;
		top: 0;
		bottom: 0;
		right: 0;

		i {
			visibility: hidden;
		}

		&.searching {
			i {
				visibility: visible;
			}
		}
	}
</style>
